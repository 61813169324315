import * as React from 'react';
import { Header } from '../_components/Header';
import { Sidebar } from '../_components/Sidebar';
import { history } from '../_helpers';
import "moment/min/locales";
import { DocumentCard, DocumentCardLogo, DocumentCardTitle, IDocumentCardStyles, mergeStyles } from '@fluentui/react';
import i18n from "i18next";


export interface Props {
  userData: any;
  appPlatform: string,
  isOnline: boolean,
}

export interface HomeState { 
  userData: any;
  breadcrumbPath: any[];
  searchQuery: any;
  syncStatus: any;
  update: boolean
}

export class HomePage extends React.Component<Props, HomeState> {
  private _isMounted: boolean;
  private sidebarRef:any = React.createRef();

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this.state = {
      userData: null,
      breadcrumbPath: [{
        text: i18n.t('app:home'),
        key: 'home',
        onClick: ()=>{history.push('/home')}
      }],
      searchQuery: [],
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      update: false
    }
  }

  public componentDidMount() {
    this._isMounted = true
    this._isMounted && this.setState({update: true})
  }

  private cardStyles: IDocumentCardStyles = {
    root: { marginRight: 20, maxWidth: 480 },
  };

  private conversationTileClass = mergeStyles({ height: 240 });

  public render() {
    return(
      <div>
        <Header
          userData={ this.props.userData }
          breadcrumbPath={ this.state.breadcrumbPath }
          sidebarRef={ this.sidebarRef }
          page="home"
          actions="home"
          getActivity={ null }
          syncStatus={this.state.syncStatus}
        />

        <div className="content-wrap d-flex flex-row">
          <Sidebar
            ref={(instance:any) => { this.sidebarRef = instance; }}
            userData={ this.props.userData }
            page='home'
          />

          <div className="list pt-3 flex-grow-1 justify-content-center" style={{textAlign: 'left', backgroundColor: 'rgb(250, 249, 248)'}}>
              <span style={{fontSize: 18}} className='m-4 pl-2 pb-4'>{this.props.userData ? this.props.userData.repository.name : ""}</span>
              <div className='row ml-4 mr-0' style={{maxWidth: '1200px'}}>
                <DocumentCard
                  className='mr-4 mt-4 home-card info-card'
                  aria-label='Files'
                  styles={this.cardStyles}
                  onClick={()=>this.props.userData && history.push('/files/' + this.props.userData.repository.id)} >
                  <div className={this.conversationTileClass}>
                    <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                      <DocumentCardLogo className='p-0' {...{logoIcon: 'FabricFolder'}} />
                      <DocumentCardTitle title="Files" shouldTruncate />
                    </div>
                    <hr className='my-2 separator'/>
                    <DocumentCardTitle
                      title="Access your repository files."
                      shouldTruncate
                      showAsSecondaryTitle
                    />
                  </div>
                </DocumentCard>
                <DocumentCard
                  className='mr-4 mt-4 home-card info-card'
                  aria-label='Search'
                  styles={this.cardStyles}
                  onClick={()=>history.push('/search')}>
                  <div className={this.conversationTileClass}>
                    <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                      <DocumentCardLogo className='p-0' {...{logoIcon: 'Search'}} />
                      <DocumentCardTitle title="Search" shouldTruncate />
                    </div>
                    <hr className='my-2 separator'/>
                    <DocumentCardTitle
                      title="Search documents and folders."
                      shouldTruncate
                      showAsSecondaryTitle
                    />
                  </div>
                </DocumentCard>
                <DocumentCard
                  className='mr-4 mt-4 home-card info-card'
                  aria-label='Projects'
                  styles={this.cardStyles}
                  onClick={()=>history.push('/projects/list')} >
                  <div className={this.conversationTileClass}>
                    <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                      <DocumentCardLogo className='p-0' {...{logoIcon: 'Work'}} />
                      <DocumentCardTitle title="Projects" shouldTruncate />
                    </div>
                    <hr className='my-2 separator'/>
                    <DocumentCardTitle
                      title="List of projects."
                      shouldTruncate
                      showAsSecondaryTitle
                    />
                  </div>
                </DocumentCard>
                <DocumentCard
                  className='mr-4 mt-4 home-card info-card'
                  aria-label='Tasks'
                  styles={this.cardStyles}
                  onClick={()=>history.push('/tasks')}>
                  <div className={this.conversationTileClass}>
                    <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                      <DocumentCardLogo className='p-0' {...{logoIcon: 'TaskLogo'}} />
                      <DocumentCardTitle title="Tasks" shouldTruncate />
                    </div>
                    <hr className='my-2 separator'/>
                    <DocumentCardTitle
                      title="List of tasks."
                      shouldTruncate
                      showAsSecondaryTitle
                    />
                  </div>
                </DocumentCard>
                <DocumentCard
                  className='mr-4 mt-4 home-card info-card'
                  aria-label='Tasks'
                  styles={this.cardStyles}
                  onClick={()=>history.push('/time-sheet')}>
                  <div className={this.conversationTileClass}>
                    <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                      <DocumentCardLogo className='p-0' {...{logoIcon: 'Clock'}} />
                      <DocumentCardTitle title="Time tracker" shouldTruncate />
                    </div>
                    <hr className='my-2 separator'/>
                    <DocumentCardTitle
                      title="Track work hours across projects."
                      shouldTruncate
                      showAsSecondaryTitle
                    />
                  </div>
                </DocumentCard>
                {/*<DocumentCard
                  className='mr-4 mt-4 home-card info-card'
                  aria-label='Time tracking'
                  styles={this.cardStyles}
                  onClick={()=>history.push('/time-sheet')}>
                  <div className={this.conversationTileClass}>
                    <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                      <DocumentCardLogo className='p-0' {...{logoIcon: 'Clock'}} />
                      <DocumentCardTitle title="Time tracking" shouldTruncate />
                    </div>
                    <hr className='my-2 separator'/>
                    <DocumentCardTitle
                      title="Track your time."
                      shouldTruncate
                      showAsSecondaryTitle
                    />
                  </div>
                </DocumentCard>*/}
            </div>
          </div>
        </div>
      </div>
    )
  }
}