import * as React from 'react';
import "moment/min/locales";
import { Resizable } from 're-resizable';
import { Icon } from '@fluentui/react/lib/Icon';
import { PivotItem, Pivot } from '@fluentui/react/lib/Pivot';
import { getFileTypeIconProps, FileIconType } from '@fluentui/react-file-type-icons';
import i18n from "i18next";

export interface Props {
  match: {
    url: string
  }
}

export interface DetailsPanelState {
  repoData: any;
  showRightPanel: boolean;
  panelHeight: any;
  panelWidth: any;
  selItem: any;
  toolbarHeight: number;
  activeTab: string;
}

export class DetailsPanel extends React.Component<Props, DetailsPanelState> {
  private _isMounted: boolean;
  private _windowWidth: number;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this._windowWidth = window.innerWidth;

    this.props = props

   var panelWidth = "420px"
   if (typeof(localStorage.getItem("detailsPanelPrefs")) === "string") {
      var panelPrefs = JSON.parse(localStorage.getItem("detailsPanelPrefs") || "[]");
      panelWidth = panelPrefs.width
   }

    this.state = {
      repoData: props.repoData,
      showRightPanel: props.showRightPanel,
      panelHeight: 'auto',
      panelWidth: panelWidth,
      selItem: props.selItem,
      toolbarHeight: 89,
      activeTab: 'info'
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    window.addEventListener('resize', this._onWindowResize);
  }

  public componentDidUpdate(prevProps, prevState) {
    if(this.props.repoData !== prevProps.repoData) {
      this._isMounted && this.setState({repoData: this.props.repoData})
    }

    if(this.props.selItem !== prevProps.selItem) {
      var savedActiveTab = localStorage.getItem("detailsPanelActiveTab")
      var activeTab = savedActiveTab ? savedActiveTab : "info"
      this._isMounted && this.setState({
        activeTab: activeTab,
        selItem: this.props.selItem
      })
    }

    if(this.props.showRightPanel !== prevProps.showRightPanel) {
      localStorage.setItem('showRightPanel', this.props.showRightPanel)
      this._isMounted && this.props.callbackFunction({showRightPanel: this.props.showRightPanel})
      this._isMounted && this.setState({showRightPanel:  this.props.showRightPanel});
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this._onWindowResize, false);
  }

  private _onWindowResize = () => {
    var toolbar : HTMLDivElement | null  = document.querySelector('.toolbar');
    this.setState({toolbarHeight: toolbar!.offsetHeight})
    if (window.innerWidth <= 980 && window.innerWidth < this._windowWidth) {
      this._isMounted && this.props.callbackFunction({showRightPanel: false})
      this._isMounted && this.setState({showRightPanel: false});
    }
    this._windowWidth = window.innerWidth;
  }

  public render() {
    const { showRightPanel, selItem, activeTab } = this.state;

    var repoAdmin = selItem && selItem.fileType === "repo" && this.props.repoData.filter(repo => {
      return (repo.id === selItem.id && repo.role === "ADMIN")
    })
    
    var isRepoAdmin = repoAdmin && repoAdmin.length > 0 ? true : false

    return(
      showRightPanel ?
        <Resizable
          size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
          onResizeStop={(e, direction, ref, d) => {
            var currentWidth = this.state.panelWidth.match(/\d+/)[0];
            this.setState({
              panelWidth: +currentWidth + +d.width + "px"
            });
            this._saveResizeState();
          }}
          enable={{ top:false, right:false, bottom:false, left:true, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
          minWidth={360}
          maxWidth={480}
          handleStyles= {{
            left: {
              "left": "0px",
              "width": "6px"
            }
          }}
          handleClasses={{"left": "resize-handle"}}
        >
          <div className="details-panel pt-0 pb-3 px-3">
          { (selItem !== null && this.props.listExplorerRef && this.props.listExplorerRef._selection.getSelectedCount() === 1) 
            || (this.props.detailsPanelSection === "explorer" &&  selItem && this.props.listExplorerRef._selection && this.props.listExplorerRef._selection.getSelectedCount() === 0) ?
              <div>
                <div className="tabs-wrap sticky-top">
                  <Pivot selectedKey={activeTab} onLinkClick={ this._handleLinkClick } headersOnly={true} getTabId={this._getTabId} className="pivot-item-icon-only">
                    <PivotItem itemIcon="Info" itemKey="info"></PivotItem>
                  </Pivot>
                </div>

                <div
                  aria-labelledby={this._getTabId(this.state.activeTab)}
                  role="tabpanel" className="position-relative"
                >
                  { activeTab === "info" ?
                    <div>
                      <div className="tab-title text-center py-3 pb-2 sticky-top">
                        <p className="lead font-weight-bold m-0">{i18n.t('app:info')}</p>
                      </div>
                      <div className="d-flex my-3">
                        <div className="file-name">
                          <div className="lead d-inline-block font-weight-bold">
                            { selItem['Company name'] || selItem.name }    
                          </div>
                        </div>
                      </div>
                      
                      <ul className="p-0 my-3">
                        { selItem['Company Domain Name'] &&<li className="mb-2">
                          <div className="text-secondary mr-2">{i18n.t('Company Domain Name')}:</div>
                          <div className="username font-weight-bold">{ selItem['Company Domain Name'] || "" }</div>
                        </li>}
                        { selItem['ompany owner'] &&<li className="mb-2">
                          <div className="text-secondary mr-2">{i18n.t('Company owner')}:</div>
                          <div className="username font-weight-bold">{ selItem['Company owner'] || "" }</div>
                        </li>}
                        { selItem['Industry'] &&<li className="mb-2">
                          <div className="text-secondary mr-2">{i18n.t('Industry')}:</div>
                          <div className="username font-weight-bold">{ selItem['Industry'] || "" }</div>
                        </li>}
                        { selItem['City'] &&<li className="mb-2">
                          <div className="text-secondary mr-2">{i18n.t('City')}:</div>
                          <div className="username font-weight-bold">{ selItem['City'] || "" }</div>
                        </li>}
                        { selItem['State/Region'] &&<li className="mb-2">
                          <div className="text-secondary mr-2">{i18n.t('State/Region')}:</div>
                          <div className="username font-weight-bold">{ selItem['State/Region'] || "" }</div>
                        </li>}
                      </ul>
                    </div>
                  : null}
                </div>
              </div>
            : this.props.listExplorerRef && this.props.listExplorerRef._selection && this.props.listExplorerRef._selection.getSelectedCount() > 1 ?
              <p className="lead font-weight-bold text-center my-2">{ this.props.listExplorerRef._selection.getSelectedCount() } {i18n.t('app:itemsSelected')}</p>
            :
              <p className="lead font-weight-bold text-center my-2">{i18n.t('app:noItemSelected')}</p>
            }
          </div>

        </Resizable>

      : null
    )
  }

  private _saveResizeState() {
    const { panelHeight, panelWidth } = this.state;
    
    var prefs = {
      height: panelHeight,
      width: panelWidth
    }

    localStorage.setItem("detailsPanelPrefs", JSON.stringify(prefs))
  }

  private _handleLinkClick = (item: any): void => {
    this.setState({
      activeTab: item.props.itemKey
    }, () => {
      localStorage.setItem("detailsPanelActiveTab", this.state.activeTab)
    });
  };

  private _getTabId = (itemKey: string): string => {
    return `${itemKey}`;
  };

}