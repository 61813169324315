import * as React from 'react';
import "moment/min/locales";
import { IconButton, Persona, PrimaryButton, ThemeProvider, TooltipHost } from '@fluentui/react';
import { DetailsListLayoutMode, Selection, SelectionMode, IColumn, ConstrainMode } from '@fluentui/react/lib/DetailsList';
import { Icon } from '@fluentui/react/lib/Icon';
import i18n from "i18next";
import { Sidebar } from '../_components/Sidebar';
import { Header } from '../_components/Header';
import { DetailsPanel } from '../_components/DetailsPanel';
import { ColumnView } from '../_components/ColumnView';
import { ListExplorer } from '../_components/ListExplorer';
import { history } from '../_helpers';
import { useParams } from 'react-router-dom';

var repo = 'Repo-bbfea307-ad84-4436-928d-40e148880981';
const fakeItems = [
  {id: 'Project-ashdgya123', key: 'Project-ashdgya123', name: 'Project Alpha: Merger', owner:'Jaime Rivas', company: '3M', fileType: 'dir', parent_id: repo, milestone: 'Qualified', milestoneIndex: '1', amount: '15,000', pipeline: 'Investments'},
  {id: 'Project-asdasdasd6', key: 'Project-asdasdasd6', name: 'Project Omega: M&A', owner:'Jaime Rivas', company: 'Boeing', fileType: 'dir', parent_id: repo , milestone: 'Piched', milestoneIndex: '2', amount: '35,000', pipeline: 'Investments'},
  {id: 'Project-9923i94j8g', key: 'Project-9923i94j8g', name: 'Project Bravo: Divesture', owner:'Francisco Fontinha', company: 'Caterpillar', fileType: 'dir', parent_id: repo , milestone: 'Proposal', milestoneIndex: '3', amount: '20,000', pipeline: 'Investments'},
  {id: 'Project-89asud8asu', key: 'Project-89asud8asu', name: 'Project Darwin: Pitch', owner:'Manuel Marcos', company: 'Intel', fileType: 'dir', parent_id: repo , milestone: 'Execution', milestoneIndex: '4', amount: '25,000', pipeline: 'Investments'},
  {id: 'Project-ashdgyaysd', key: 'Project-ashdgyaysd', name: 'Project Galactica: Buybacks', owner:'Xavier Campillo', company: 'Cisco', fileType: 'dir', parent_id: repo , milestone: 'Diligence', milestoneIndex: '5', amount: '45,000', pipeline: 'Investments'},
  {id: 'Project-jio56jiosa', key: 'Project-jio56jiosa', name: 'Project Apollo: M&A opportunities', owner:'Michael Bommers', company: 'Nike', fileType: 'dir', parent_id: repo , milestone: 'Closing', milestoneIndex: '6', amount: '50,000', pipeline: 'Investments'},
  {id: 'Project-n345cv86bv', key: 'Project-n345cv86bv', name: 'Project Eagle: Merger', owner:'Michael Bommers', company: 'Pfizer', fileType: 'dir', parent_id: repo , milestone: 'Piched', milestoneIndex: '2', amount: '30,000', pipeline: 'Investments'},
  {id: 'Project-xc7v67xc6v', key: 'Project-xc7v67xc6v', name: 'Project Hydra: Pitch', owner:'Jaime Rivas', company: 'Procter & Gamble', fileType: 'dir', parent_id: repo , milestone: 'Execution', milestoneIndex: '4', amount: '40,000', pipeline: 'Investments'},
  {id: 'Project-zc7v67xc61', key: 'Project-zc7v67xc61', name: 'Project Charlie: Merger', owner:'Jaime Rivas', company: 'Caterpillar Inc', milestone: 'Pre-Marketing', milestoneIndex: '1', amount: '12,000', pipeline: 'Divestments'},
  {id: 'Project-zc7v67xc62', key: 'Project-zc7v67xc62', name: 'Project Delta: M&A', owner:'Jaime Rivas', company: 'United Technologies', milestone: 'Marketing', milestoneIndex: '2', amount: '25,000', pipeline: 'Divestments'},
  {id: 'Project-zc7v67xc63', key: 'Project-zc7v67xc63', name: 'Project Echo: Pitch', owner:'Jaime Rivas', company: 'Intel', milestone: 'First Bids', milestoneIndex: '3', amount: '60,000', pipeline: 'Divestments'},
  {id: 'Project-zc7v67xc64', key: 'Project-zc7v67xc64', name: 'Project Foxtrot: Merger', owner:'Jaime Rivas', company: 'IBM', milestone: 'Due Diligence', milestoneIndex: '4', amount: '125,000', pipeline: 'Divestments'},
  {id: 'Project-zc7v67xc65', key: 'Project-zc7v67xc65', name: 'Project Kilo: Buybacks', owner:'Jaime Rivas', company: '', milestone: 'Final Bids', milestoneIndex: '5', amount: '115,000', pipeline: 'Divestments'},
  {id: 'Project-zc7v67xc66', key: 'Project-zc7v67xc66', name: 'Project Lima: Divesture', owner:'Jaime Rivas', company: 'Boeing', milestone: 'Closing', milestoneIndex: '6', amount: '45,000', pipeline: 'Divestments'},
  {id: 'Project-zc7v67xc67', key: 'Project-zc7v67xc67', name: 'Project Golf: Pitch', owner:'Jaime Rivas', company: 'Walmart', milestone: 'Closing', milestoneIndex: '6', amount: '35,000', pipeline: 'Divestments'},
]

const fakePipelines = [
  {
    name: "Investments", 
    milestones: [
      {name: 'Qualified', color: 'rgb(196, 196, 196)'},
      {name: 'Piched', color: 'blue'},
      {name: 'Proposal', color: 'rgb(254, 192, 110)'},
      {name: 'Execution', color: 'yellow'},
      {name: 'Diligence', color: 'limegreen'},
      {name: 'Closing', color: 'green'}
    ]
  },
  {
    name: "Divestments", 
    milestones: [
      {name: 'Pre-Marketing', color: 'rgb(196, 196, 196)'},
      {name: 'Marketing', color: 'lightblue'},
      {name: 'First Bids', color: 'blue'},
      {name: 'Due Diligence', color: 'yellow'},
      {name: 'Final Bids', color: 'rgb(254, 192, 110)'},
      {name: 'Closing', color: 'rgb(7, 200, 115)'}
    ]
  }
]

declare global {
  interface Window {
      require: any;
      sreader: any;
  }
}

export interface Props {
  appPlatform: string,
  database: any,
  isOnline: boolean,
  maintenance: boolean,
  socket: any,
  pusher: any,
  page: string,
  userData: any,
  foldersList: any,
  newFolder: any,
  repoUsers: any,
  params:any
}

export interface State {
  userData?: any;
  repoData?: any;
  goBack: number;
  isLoading: boolean;
  blankLoading: boolean;
  savingTag: boolean;
  savingComment: boolean;
  syncStatus: any;
  columns: IColumn[];
  items: any[];
  fullItemsResponse: any;
  showFilters: boolean;
  filterByNameText: string;
  fullItemsResponseByName: any;
  filterByTypeText: string;
  fullItemsResponseByType: any;
  draggedItem: any;
  selItem: any;
  showRightPanel: boolean;
  selectionDetails: string;
  isModalSelection: boolean;
  isCompactMode: boolean;
  newTag: string;
  newComment: string;
  newFolder: string;
  savingFolder: boolean;
  showTagBulkDialog: boolean;
  hideDialog: boolean;
  activeTab: string;
  language: string;
  filterToggle: boolean;
  toolbarHeight: number;
  searchQuery: string;
  breadcrumbPath: any[];
  pipelineId: string;
  pipeline:any;
}

export interface IDocument {
  key: string;
  fileName: string;
  name: string;
  fileExtension: string;
  accessGranted: boolean;
  value: string;
  icon: string;
  fileType: string;
  path: string;
  isFolderInPath: boolean;
  lastOpened: string;
  createdBy: any;
  modifiedBy: any;
  dateCreated: string;
  dateDeleted: string;
  dateModified: string;
  dateModifiedValue: number;
  fileSize: string;
  fileSizeRaw: number;
  author: string;
  date: number;
  revisionsCount: number;
  currentVersion: string;
  draft: boolean;
  savingDraft: boolean;
  lock: any;
  wopiLock: any;
  lockingDocument: boolean;
  msg: string;
  hash: string;
  stored: string;
  company: string;
  milestone: string;
  owner:string ;
  amount: string;
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class ProjectsPage extends React.Component<Props, State> {
  private _isMounted: boolean;
  private _selection: Selection;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();
  private listExplorerRef:any = React.createRef();
  private detailsPanelRef:any = React.createRef();

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this._selection = new Selection({
      onSelectionChanged: () => {
        this._isMounted && this.setState({
          selItem: this._selection.getSelection()[this._selection.getSelection().length - 1] || []
        });
      }
    });

    const query = new URLSearchParams(document.location.search)

    this.state = {
      userData: null,
      repoData: null,
      goBack: 0,
      isLoading: true,
      blankLoading: false,
      savingTag: false,
      savingComment: false,
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      items: fakeItems,
      showFilters: false,
      fullItemsResponse: [],
      filterByNameText: "",
      fullItemsResponseByName: [],
      filterByTypeText: "",
      fullItemsResponseByType: [],
      draggedItem: null,
      columns: [],
      selItem: null,
      showRightPanel: localStorage.showRightPanel === 'true',
      selectionDetails: '',
      isModalSelection: false,
      isCompactMode: true,
      newTag: '',
      newComment: '',
      newFolder: '',
      savingFolder: false,
      showTagBulkDialog: false,
      hideDialog: true,
      activeTab: 'details',
      language: i18n.language,
      filterToggle: true,
      toolbarHeight: 89,
      searchQuery: document.location.search,
      breadcrumbPath: [{
        text: i18n.t('app:projects'),
        key: 'projects',
        onClick: ()=>{history.push('/projects/list')}
      }],
      pipelineId: query.get('pipeline') || 'all',
      pipeline: null
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this._isMounted && this._getColumns();
    this._isMounted && this.props.userData && this._getRepoData();
    let items:any = fakeItems
    let pipeline: any = fakePipelines.filter((item)=>{
      return item.name === this.state.pipelineId ;
    })[0]

    const params = new URLSearchParams(document.location.search);
    params.forEach((value, key)=>{
      let field:string = params.get(key) || "";
      let fields:string[] = field.toLowerCase().split(';');
      if (field !== 'noFilter') {
        if (field === 'all') {
          items = items.filter((item:any)=>{
            return (item[key] && item[key] !== '')
          })
        } else if (field === 'none') {
          items = items.filter((item:any)=>{
            return (!item[key] || item[key] === '')
          })
        } else if (fields && fields.length > 0) {
          items = items.filter((item:any)=>{
            return (item[key] && fields.includes(item[key].toLowerCase()))
          })
        }
      }
    })
    
    if (this.props.page === "clientsExplorer") {
      var newItems = _copyAndSort(items, 'company', false);
      this._isMounted && this.setState({
        items: newItems,
        pipeline: pipeline
      });
    }
    else if (this.props.page === "projectsExplorer") {
      var newItems = _copyAndSort(items, 'name', false);
      this._isMounted && this.setState({
        items: newItems,
        pipeline: pipeline
      });
    }
    else if (this.props.page === "milestonesExplorer") {
      var newItems = _copyAndSort(items, 'milestoneIndex', false);
      this._isMounted && this.setState({
        items: newItems,
        pipeline: pipeline
      });
    }
    else if (this.props.page === "boardExplorer") {
      var newItems = _copyAndSort(items, 'milestone', false);
      this._isMounted && this.setState({
        items: newItems,
        pipeline: pipeline
      });
    }
    else if (this.props.page === "pipelinesExplorer") {
      var newItems = _copyAndSort(items, 'pipeline');
      this._isMounted && this.setState({
        items: newItems,
        pipeline: pipeline
      });
    }
  }

  public componentDidUpdate(prevProps: any, prevState: any) {
    
    if(this.state.language !== i18n.language) {
      this.setState({language: i18n.language});
    }

    if(this.props.userData !== prevProps.userData) {
      this.props.userData && this._getRepoData();
    }
    
    if(prevState.searchQuery !== document.location.search) {
      let query = new URLSearchParams(document.location.search);
      let pipeline = query.get('pipeline') || 'all';
      
      this._isMounted && this.setState({
        pipelineId: pipeline,
        searchQuery: document.location.search
      });
    }

    if ((this.props.page !== prevProps.page || prevState.searchQuery !== document.location.search)) {
      let items:any = fakeItems;
      let pipeline:any = fakePipelines.filter((item)=>{
        return item.name === this.state.pipelineId ;
      })[0]

      const params = new URLSearchParams(document.location.search);
      params.forEach((value, key)=>{
        let field:string = params.get(key) || "";
        let fields:string[] = field.toLowerCase().split(';');
        if (field !== 'noFilter') {
          if (field === 'all') {
            items = items.filter((item:any)=>{
              return (item[key] && item[key] !== '')
            })
          } else if (field === 'none') {
            items = items.filter((item:any)=>{
              return (!item[key] || item[key] === '')
            })
          } else if (fields && fields.length > 0) {
            items = items.filter((item:any)=>{
              return (item[key] && fields.includes(item[key].toLowerCase()))
            })
          }
        }
      })

      if (this.props.page === "projectsExplorer") {
        this._isMounted && this._getColumns();
        var newItems = _copyAndSort(items, 'name', false);
        this._isMounted && this.setState({
          items: newItems,
          pipeline: pipeline,
          searchQuery: document.location.search
        });
      }
  
      if (this.props.page === "clientsExplorer") {
        this._isMounted && this._getColumns();
        var newItems = _copyAndSort(items, 'company', false);
        this._isMounted && this.setState({
          items: newItems,
          pipeline: pipeline,
          searchQuery: document.location.search
        });
      }
  
      else if (this.props.page === "milestonesExplorer") {
        this._isMounted && this._getColumns();
        var newItems = _copyAndSort(items, 'milestone', false);
        this._isMounted && this.setState({
          items: newItems,
          pipeline: pipeline,
          searchQuery: document.location.search
        });
      }
  
      else if (this.props.page === "boardExplorer") {
        this._isMounted && this._getColumns();
        var newItems = _copyAndSort(items, 'milestone', false);
        this._isMounted && this.setState({
          items: newItems,
          pipeline: pipeline,
          searchQuery: document.location.search
        });
      }
  
      else if (this.props.page === "pipelinesExplorer") {
        this._isMounted && this._getColumns();
        var newItems = _copyAndSort(items, 'milestoneIndex', false, 'pipeline');
        this._isMounted && this.setState({
          items: newItems,
          pipeline: pipeline,
          searchQuery: document.location.search
        });
      }
    } 
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  public render() {

    const { columns, items, isCompactMode, searchQuery, showRightPanel } = this.state;

    return (
      <ThemeProvider>
            <Header
              ref={instance => { this.headerRef = instance; }}
              userData={ this.props.userData }
              listExplorerRef={ this.listExplorerRef }
              detailsPanelRef={ this.detailsPanelRef }
              page={this.props.page}
              sidebarRef={ this.sidebarRef }
              breadcrumbPath= {this.state.breadcrumbPath}
              syncStatus = {this.state.syncStatus}
              items={items}
              callbackFunction={this.callbackFunction}
              showRightPanel={showRightPanel}
            />
          <div className="content-wrap d-flex flex-row" >
            <Sidebar
              ref={(instance:any) => { this.sidebarRef = instance; }}
              headerRef={ this.headerRef }
              listExplorerRef={ this.listExplorerRef }
              detailsPanelRef={ this.detailsPanelRef }
              userData={ this.props.userData }
              page={this.props.page}
              items={items}
              searchQuery={searchQuery}
              callbackFunction={this.callbackFunction}
              pipelineId={this.state.pipelineId}
              pipeline={ this.state.pipeline }

            /> 

            <div className="list mr-auto flex-grow-1">
              
              { this.props.page === "boardExplorer" ?
              <ColumnView
                ref={instance => { this.listExplorerRef = instance; }}
                appPlatform={ this.props.appPlatform }
                isOnline={ this.props.isOnline }
                maintenance={ this.props.maintenance }
                socket={this.props.socket}
                pusher={this.props.pusher}
                userData={this.props.userData}
                foldersList={ this.props.foldersList }
                items={items}
                selItem={ this.state.selItem }
                isCompactMode={isCompactMode}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                //enableShimmer={isLoading}
                constrainMode={ConstrainMode.unconstrained}
                //onFileDrop={this.onFileDrop.bind(this)}
                //getCurrentContent={console.log('Get Content')}
                //getOfflineFiles={this._getOfflineFiles.bind(this)}
                //isLoading={isLoading}
                //blankLoading={blankLoading}
                //emptyMessage={emptyMessage}
                headerRef={ this.headerRef }
                detailsPanelRef={ this.detailsPanelRef }
                callbackFunction={ this.callbackFunction }
                page={this.props.page}
                //updateItem={ this.updateItem }
                sidebarRef={ this.sidebarRef }
                pipelineId={ this.state.pipelineId }
                pipeline={ this.state.pipeline }
              /> 
              :
              <ListExplorer
                ref={instance => { this.listExplorerRef = instance; }}
                appPlatform={ this.props.appPlatform }
                isOnline={ this.props.isOnline }
                maintenance={ this.props.maintenance }
                socket={this.props.socket}
                pusher={this.props.pusher}
                userData={this.props.userData}
                foldersList={ this.props.foldersList }
                items={items}
                selItem={ this.state.selItem }
                isCompactMode={isCompactMode}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                //enableShimmer={isLoading}
                constrainMode={ConstrainMode.unconstrained}
                //onFileDrop={this.onFileDrop.bind(this)}
                //getCurrentContent={console.log('Get Content')}
                //getOfflineFiles={this._getOfflineFiles.bind(this)}
                //isLoading={isLoading}
                //blankLoading={blankLoading}
                //emptyMessage={emptyMessage}
                headerRef={ this.headerRef }
                detailsPanelRef={ this.detailsPanelRef }
                callbackFunction={ this.callbackFunction }
                page={this.props.page}
                //updateItem={ this.updateItem }
                sidebarRef={ this.sidebarRef }
                pipelineId={this.state.pipelineId}
              /> 
              }    
            </div>
            <DetailsPanel
              ref={instance => { this.detailsPanelRef = instance; }}
              appPlatform={ this.props.appPlatform }
              userData={ this.props.userData }
              repoData={ this.state.repoData }
              foldersList={ this.props.foldersList }
              repoUsers={this.props.repoUsers}
              newFolder={ this.props.newFolder }
              items={ items }
              showRightPanel={ this.state.showRightPanel }
              selection={ this._selection }
              selItem={ this.state.selItem }
              //getCurrentContent={(this._getCurrentContent.bind(this))}
              headerRef={ this.headerRef }
              listExplorerRef={ this.listExplorerRef }
              page={ this.props.page }
              detailsPanelSection="explorer"
              callbackFunction={ this.callbackFunction }
            />
          </div>  
      </ThemeProvider>
    );
  }

  private getMilestone(item:any) {
    let pipeline = fakePipelines.filter((pipeline)=>{
      return item.pipeline === pipeline.name;
    })[0];
    let nStages = pipeline.milestones.length;
    let index = 0;
    return (
      pipeline.milestones.map((milestone)=>{
        index++;
        if (milestone.name === item.milestone) {
          return (
            <div key={item.milestone} className='d-flex align-items-center'>
              <Icon 
                iconName="CircleShapeSolid" 
                className="tag mr-2" 
                style={{
                  fontSize:12,
                  textAlign:'center',
                  color: 'rgb(16, 110, 190)',
                  opacity: index/nStages
                }} 
              />
              <span style={{paddingBottom: '2px'}}>{item.milestone}</span>
            </div> 
          )
        }
      })
    )
  }

  private getStagesArrows(item:any) {
    let selected = false
    let pipeline = fakePipelines.filter((pipeline)=>{
      return item.pipeline === pipeline.name;
    })[0];
    let nStages = pipeline.milestones.length;
    let index = 0;
    let colors = ['rgba(192,0,0,.8)', 'rgba(200,50,0,.8)', 'rgba(255,155,0,.8)', 'rgba(255,255,0,.8)', 'rgba(200,255,55,.8)', 'rgba(100,255,50,.8)', 'rgba(0,200,0,.8)']
    return (
      <div className='d-flex align-items-center align-self-center pt-1'>
        { true ?
          pipeline.milestones.map((milestone)=>{
            index++;
            if (!selected && milestone.name !== item.milestone) {
              return (
                <TooltipHost key={milestone.name}  content={pipeline.name + " - " + milestone.name} setAriaDescribedBy={false}>
                  <img className='selected' src={process.env.PUBLIC_URL + '/img/arrow.svg'} style={{opacity: index/nStages, width: '32px', height: '20px'}}/>
                </TooltipHost>
              )
            }
            else if (!selected && milestone.name === item.milestone) {
              selected = true
              return (
                <TooltipHost key={milestone.name}  content={pipeline.name + " - " + milestone.name} setAriaDescribedBy={false}>
                  <img className='selected' src={process.env.PUBLIC_URL + '/img/arrow.svg'} style={{opacity: index/nStages, width: '32px', height: '20px'}}/>
                </TooltipHost>
              )
            }
            else if (selected && milestone.name !== item.milestone) {
              return (
                <TooltipHost key={milestone.name}  content={pipeline.name + " - " + milestone.name} setAriaDescribedBy={false}>
                  <img src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{width: '32px', height: '20px'}}/>
                </TooltipHost>
              )
            }
          })
          :
          pipeline.milestones.map((milestone)=>{
            index++;
            if (!selected && milestone.name !== item.milestone) {
              return (
                <TooltipHost key={milestone.name}  content={pipeline.name + " - " + milestone.name} setAriaDescribedBy={false}>
                  <div style={{background: colors[Math.ceil((index/nStages)*colors.length)-1], borderColor: colors[Math.ceil((index/nStages)*colors.length)-1]}} className='arrow-pointer selected'></div>
                </TooltipHost>
              )
            }
            else if (!selected && milestone.name === item.milestone) {
              selected = true
              return (
                <TooltipHost key={milestone.name}  content={pipeline.name + " - " + milestone.name} setAriaDescribedBy={false}>
                  <div style={{background: colors[Math.ceil((index/nStages)*colors.length)-1], borderColor: colors[Math.ceil((index/nStages)*colors.length)-1]}} className='arrow-pointer selected'></div>
                </TooltipHost>
              )
            }
            else if (selected && milestone.name !== item.milestone) {
              return (
                <TooltipHost key={milestone.name}  content={pipeline.name + " - " + milestone.name} setAriaDescribedBy={false}>
                  <div className='arrow-pointer'></div>
                </TooltipHost>
              )
            }
          })
        }
      </div>  
    )
  }

  private _getColumns() {
    var columns: any = []

    if (this.props.page === "projectsExplorer") {
      columns = [
        {
          key: 'fakeColumn',
          name: 'fakeColumn',
          fieldName: 'conflict',
          minWidth: 0,
          maxWidth: 0,
          isRowHeader: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          data: 'string',
          isPadded: true,
          styles: { root: { display: "none" } },
          className: "d-none"
        },
        {
          key: 'name',
          name: 'Project name',
          fieldName: 'name',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          isSorted: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: (item: IDocument) => {
            return (
                !item.lock && !item.wopiLock ?
                  <span title={ item.fileName } style={{verticalAlign:'middle'}}>{ item.name }</span>
                : item.wopiLock ?
                  <span title={ item.fileName }><Icon iconName="OfficeLogo" className="mr-2 my-auto align-self-center small" />{ item.name }</span>
                :
                  <span title={ item.fileName }><Icon iconName="Lock" className={"mr-2 small" + (item.lock.id === this.props.userData.user.id ? " text-primary" : "")} />{ item.name }</span>
              )
          }
        },
        {
          key: 'milestone',
          name: i18n.t('app:Stage'),
          fieldName: 'company',
          minWidth: 150,
          isRowHeader: true,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: this.getMilestone
        },
        {
          key: 'milestonearrows',
          name: i18n.t('app:Progress'),
          fieldName: 'milestone',
          minWidth: 200,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'string',
          onRender: this.getStagesArrows,
          isPadded: true
        },
        {
          key: 'company',
          name: i18n.t('app:Company'),
          fieldName: 'company',
          minWidth: 150,
          isRowHeader: true,
          isResizable: true,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: (item: IDocument) => {
            return (
              <span title={ item.company }>{ item.company }</span>
            )
          }
        },
        {
          key: 'currency',
          name: i18n.t('app:Currency'),
          fieldName: 'currency',
          minWidth: 50,
          maxWidth: 50,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'string',
          onRender: (item: IDocument) => {
            return (
              <div style={{marginRight: 0, marginLeft: 'auto'}}>
                <span>{'USD $'}</span>
              </div>
            )
          },
          isPadded: true
        },
        {
          key: 'amount',
          name: i18n.t('app:Amount'),
          fieldName: 'amount',
          minWidth: 50,
          maxWidth: 50,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'number',
          onRender: (item: IDocument) => {
            return (
              <div style={{marginRight: 0, marginLeft: 'auto'}}>
                  <span>{item.amount}</span>
                </div>
              )
          },
          isPadded: true
        },
        {
          key: 'owner',
          name: i18n.t('app:Owner'),
          fieldName: 'owner',
          minWidth: 64,
          maxWidth: 128,
          isResizable: true,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this._onColumnClick,
          data: 'string',
          onRender: (item: IDocument) => {
            return (
              <div className='d-flex align-items-center'>
                  <span><Persona className="tag" size={10} text={item.owner}/></span>
              </div>  
              )
          },
          isPadded: true
        },
      ]
    }
    else if (this.props.page === "clientsExplorer") {
      columns = [
        {
          key: 'fakeColumn',
          name: 'fakeColumn',
          fieldName: 'conflict',
          minWidth: 0,
          maxWidth: 0,
          isRowHeader: true,
          data: 'string',
          isPadded: true,
          styles: { root: { display: "none" } },
          className: "d-none"
        },
        {
          key: 'name',
          name: 'Project name',
          fieldName: 'name',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: (item: IDocument) => {
            return (
                !item.lock && !item.wopiLock ?
                  <span title={ item.fileName }>{ item.name }</span>
                : item.wopiLock ?
                  <span title={ item.fileName }><Icon iconName="OfficeLogo" className="mr-2 small" />{ item.name }</span>
                :
                  <span title={ item.fileName }><Icon iconName="Lock" className={"mr-2 small" + (item.lock.id === this.props.userData.user.id ? " text-primary" : "")} />{ item.name }</span>
              )
          }
        },
        {
          key: 'milestone',
          name: i18n.t('app:Stage'),
          fieldName: 'company',
          minWidth: 150,
          isRowHeader: true,
          isResizable: true,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: this.getMilestone
        },
        {
          key: 'milestonearrows',
          name: i18n.t('app:Progress'),
          fieldName: 'milestone',
          minWidth: 200,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'string',
          onRender: this.getStagesArrows,
          isPadded: true
        },
        {
          key: 'company',
          name: i18n.t('app:Company'),
          fieldName: 'company',
          minWidth: 150,
          isRowHeader: true,
          isResizable: true,
          isSorted: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: (item: IDocument) => {
            return (
              <span title={ item.company }>{ item.company }</span>
            )
          }
        },
        {
          key: 'currency',
          name: i18n.t('app:Currency'),
          fieldName: 'currency',
          minWidth: 50,
          maxWidth: 50,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'string',
          onRender: (item: IDocument) => {
            return (
              <div style={{marginRight: 0, marginLeft: 'auto'}}>
                <span>{'USD $'}</span>
              </div>
            )
          },
          isPadded: true
        },
        {
          key: 'amount',
          name: i18n.t('app:Amount'),
          fieldName: 'amount',
          minWidth: 50,
          maxWidth: 50,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'number',
          onRender: (item: IDocument) => {
            return (
              <div style={{marginRight: 0, marginLeft: 'auto'}}>
                  <span>{item.amount}</span>
                </div>
              )
          },
          isPadded: true
        },
        {
          key: 'owner',
          name: i18n.t('app:Owner'),
          fieldName: 'milestone',
          minWidth: 64,
          maxWidth: 128,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'string',
          onRender: (item: IDocument) => {
            return (
              <div className='d-flex align-items-center'>
                  <span><Persona className="tag" size={10} text={item.owner}/></span>
              </div>  
              )
          },
          isPadded: true
        },
      ]
    }
    else if (this.props.page === "milestonesExplorer") {
      columns = [
        {
          key: 'fakeColumn',
          name: 'fakeColumn',
          fieldName: 'conflict',
          minWidth: 0,
          maxWidth: 0,
          isRowHeader: true,
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          styles: { root: { display: "none" } },
          className: "d-none"
        },
        {
          key: 'name',
          name: 'Project name',
          fieldName: 'name',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: (item: IDocument) => {
            return (
                !item.lock && !item.wopiLock ?
                  <span title={ item.fileName }>{ item.name }</span>
                : item.wopiLock ?
                  <span title={ item.fileName }><Icon iconName="OfficeLogo" className="mr-2 small" />{ item.name }</span>
                :
                  <span title={ item.fileName }><Icon iconName="Lock" className={"mr-2 small" + (item.lock.id === this.props.userData.user.id ? " text-primary" : "")} />{ item.name }</span>
              )
          }
        },
        {
          key: 'milestone',
          name: i18n.t('app:Stage'),
          fieldName: 'company',
          minWidth: 150,
          isRowHeader: true,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: this.getMilestone
        },
        {
          key: 'milestonearrows',
          name: i18n.t('app:Progress'),
          fieldName: 'milestone',
          minWidth: 200,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'string',
          onRender: this.getStagesArrows,
          isPadded: true
        },
        {
          key: 'company',
          name: i18n.t('app:Company'),
          fieldName: 'company',
          minWidth: 150,
          isRowHeader: true,
          isResizable: true,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: (item: IDocument) => {
            return (
              <span title={ item.company }>{ item.company }</span>
            )
          }
        },
        {
          key: 'currency',
          name: i18n.t('app:Currency'),
          fieldName: 'currency',
          minWidth: 50,
          maxWidth: 50,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'string',
          onRender: (item: IDocument) => {
            return (
              <div style={{marginRight: 0, marginLeft: 'auto'}}>
                <span>{'USD $'}</span>
              </div>
            )
          },
          isPadded: true
        },
        {
          key: 'amount',
          name: i18n.t('app:Amount'),
          fieldName: 'amount',
          minWidth: 50,
          maxWidth: 50,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'number',
          onRender: (item: IDocument) => {
            return (
              <div style={{marginRight: 0, marginLeft: 'auto'}}>
                  <span>{item.amount}</span>
                </div>
              )
          },
          isPadded: true
        },
        {
          key: 'owner',
          name: i18n.t('app:Owner'),
          fieldName: 'milestone',
          minWidth: 64,
          maxWidth: 128,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'string',
          onRender: (item: IDocument) => {
            return (
              <div className='d-flex align-items-center'>
                  <span><Persona className="tag" size={10} text={item.owner}/></span>
              </div>  
              )
          },
          isPadded: true
        },
      ]
    }
    else if (this.props.page === "pipelinesExplorer") {
      columns = [
        {
          key: 'fakeColumn',
          name: 'fakeColumn',
          fieldName: 'conflict',
          minWidth: 0,
          maxWidth: 0,
          isRowHeader: true,
          data: 'string',
          isPadded: true,
          styles: { root: { display: "none" } },
          className: "d-none"
        },
        {
          key: 'name',
          name: 'Project name',
          fieldName: 'name',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: (item: IDocument) => {
            return (
                !item.lock && !item.wopiLock ?
                  <span title={ item.fileName }>{ item.name }</span>
                : item.wopiLock ?
                  <span title={ item.fileName }><Icon iconName="OfficeLogo" className="mr-2 small" />{ item.name }</span>
                :
                  <span title={ item.fileName }><Icon iconName="Lock" className={"mr-2 small" + (item.lock.id === this.props.userData.user.id ? " text-primary" : "")} />{ item.name }</span>
              )
          }
        },
        {
          key: 'milestone',
          name: i18n.t('app:Stage'),
          fieldName: 'company',
          minWidth: 150,
          isRowHeader: true,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: this.getMilestone
        },
        {
          key: 'milestonearrows',
          name: i18n.t('app:Progress'),
          fieldName: 'milestone',
          minWidth: 200,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'string',
          onRender: this.getStagesArrows,
          isPadded: true
        },
        {
          key: 'company',
          name: i18n.t('app:Company'),
          fieldName: 'company',
          minWidth: 150,
          isRowHeader: true,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: (item: IDocument) => {
            return (
              <span title={ item.company }>{ item.company }</span>
            )
          }
        },
        {
          key: 'currency',
          name: i18n.t('app:Currency'),
          fieldName: 'currency',
          minWidth: 50,
          maxWidth: 50,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'string',
          onRender: (item: IDocument) => {
            return (
              <div style={{marginRight: 0, marginLeft: 'auto'}}>
                <span>{'USD $'}</span>
              </div>
            )
          },
          isPadded: true
        },
        {
          key: 'amount',
          name: i18n.t('app:Amount'),
          fieldName: 'amount',
          minWidth: 50,
          maxWidth: 50,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'number',
          onRender: (item: IDocument) => {
            return (
              <div style={{marginRight: 0, marginLeft: 'auto'}}>
                  <span>{item.amount}</span>
                </div>
              )
          },
          isPadded: true
        },
        {
          key: 'owner',
          name: i18n.t('app:Owner'),
          fieldName: 'milestone',
          minWidth: 64,
          maxWidth: 128,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'string',
          onRender: (item: IDocument) => {
            return (
              <div className='d-flex align-items-center'>
                  <span><Persona className="tag" size={10} text={item.owner}/></span>
              </div>  
              )
          },
          isPadded: true
        },
      ]
    }
    this._isMounted && this.setState({
      columns: columns
    })
  }

  onDragOver = (e) => {
    e.dataTransfer.dropEffect = 'copy'
    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onDragEnter = (e) => {
    e.dataTransfer.dropEffect = 'copy'
    if(this.listExplorerRef
      && !this.listExplorerRef.state.draggedItem) {
        
      setTimeout(() => {
        var el: any = document.getElementsByClassName("ms-ScrollablePane")
        for(var i = 0; i < el.length; i++) {
          if (!el[i].classList.contains("dragOverList")) {
            el[i].classList.add('dragOverList');
          }
        }
      })
    }

    let event:any = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onDragLeave = (e) => {
    if (e.target.className.indexOf("ms-ScrollablePane") !== -1) {
      var el: any = document.getElementsByClassName("ms-ScrollablePane")
      for(var i = 0; i < el.length; i++) {
        el[i].classList.remove('dragOverList');
      }
    }

    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onFileDrop = (e) => {
    var el: any = document.getElementsByClassName("ms-ScrollablePane")
    for(var i = 0; i < el.length; i++) {
      el[i].classList.remove('dragOverList');
    }
    
    let event:any = e as Event;
    event.stopPropagation();
    event.preventDefault();

    if(!this.state.draggedItem) {
      var sourceItems = event.dataTransfer.items
      var sourceFiles = event.dataTransfer.files

      /*this.getAllFileEntries(sourceItems).then(response => {
        this._setItemsToUpload(response, sourceFiles)
      });*/
    }

  }

  private _getRepoData() {
    var userData = this.props.userData
    var repo = userData && userData.repository;
    var repoData: any = [];

    repoData.push({
      id: repo.id,
      name: repo.name,
      role: userData.role
    })

    this._isMounted && this.setState({
      repoData: repoData
    }, () => {
      //this._getCurrentContent();
    })
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        newCol.isSortedDescending = !currColumn.isSortedDescending;
        newCol.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    let newItems;
    let mainColumn;
    if (this.props.page === 'projectsExplorer') mainColumn = null
    if (this.props.page === 'clientsExplorer') mainColumn = 'company'
    if (this.props.page === 'milestonesExplorer') mainColumn = 'milestoneIndex'
    if (this.props.page === 'pipelinesExplorer') mainColumn = 'pipeline'
    //if (column.key === "name") {
      //newItems = _copyAndSortName(items, currColumn.fieldName!, currColumn.isSortedDescending);
    if (column.key === "milestone" || column.key === "milestonearrows") {
      newItems = _copyAndSort(items, 'milestoneIndex', currColumn.isSortedDescending, mainColumn);
    } else {
      newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending, mainColumn);
    }

    this._isMounted && this.setState({
      columns: newColumns,
      items: newItems
    });
  };


}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean, secondColumnKey?: string): T[] {
  const key = columnKey as keyof any;
  const key2 = secondColumnKey as keyof any;
  if (key === 'amount') {
    if (key2 && key2 !== key) return items.slice(0).sort((a: T, b: T) => {
      if (a[key2] < b[key2]) return -1;
      if (a[key2] > b[key2]) return 1;
      else if (parseFloat(a[key]) < parseFloat(b[key])) return isSortedDescending ? 1 : -1;
      else if (parseFloat(a[key]) > parseFloat(b[key])) return isSortedDescending ? -1 : 1;
      else return 0;
    })
    else return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? parseFloat(a[key]) < parseFloat(b[key]) : parseFloat(a[key]) > parseFloat(b[key])) ? 1 : -1));
  } else {
    if (key2 && key2 !== key) return items.slice(0).sort((a: T, b: T) => {
      if (a[key2] < b[key2]) return -1;
      if (a[key2] > b[key2]) return 1;
      else if (a[key] < b[key]) return isSortedDescending ? 1 : -1;
      else if (a[key] > b[key]) return isSortedDescending ? -1 : 1;
      else return 0;
    })
    else return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
  }
}

function _copyAndSortName<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  return items.slice(0).sort((a,b) => {
    if (!isSortedDescending) {
      if(a["fileType"] < b["fileType"])
          return -1;
      if(b["fileType"] < a["fileType"])
          return 1;

      return a["name"].localeCompare(b["name"]);
    } else {
      if(a["fileType"] > b["fileType"])
          return -1;
      if(b["fileType"] > a["fileType"])
          return 1;

      return b["name"].localeCompare(a["name"]);
    }

  });
}

export default withParams(ProjectsPage);