import * as React from 'react';
import { history } from '../_helpers';
import { Fabric } from '@fluentui/react/lib/Fabric';
import Moment from 'moment';
import "moment/min/locales";
import { DetailsListLayoutMode, Selection, SelectionMode, IColumn, ConstrainMode } from '@fluentui/react/lib/DetailsList';
import { Icon } from '@fluentui/react/lib/Icon';
import { getFileTypeIconProps, FileIconType } from '@fluentui/react-file-type-icons';
import { Header } from '../_components/HeaderDrive';
import { Sidebar } from '../_components/SidebarDrive';
import { FileExplorer } from '../_components/FileExplorer';
import { DetailsPanel } from '../_components/DetailsPanelDrive';
import { ISearchBox } from '@fluentui/react/lib/SearchBox';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import { TextField } from '@fluentui/react/lib/TextField';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ActionButton, Dropdown, IBasePicker, IconButton, IDropdownOption, IIconProps, ITag, TagPicker } from '@fluentui/react';
import { Breadcrumb } from '@fluentui/react/lib/Breadcrumb';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { ContextualMenu, IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { userService } from '../_services';

import i18n from "i18next";
import isElectron from 'is-electron';

const detectMob = () => {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}

function pickTextColorBasedOnBgColor(bgColor, lightColor, darkColor) {
  var color = bgColor.replace('rgb(', '').replace(')', '').split(',');
  var r = color[0]
  var g = color[1]
  var b = color[2]
  return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
    darkColor : lightColor;
}

declare global {
  interface Window {
      require: any;
      sreader: any;
  }
}

export interface Props {
  appPlatform: string,
  isOnline: boolean,
  maintenance: boolean,
  socket: any,
  pusher: any,
  appUpdateAvailable: boolean,
  webUpdateAvailable: boolean,
  fileExplorerType: string,
  searchType: string,
  userData: any,
  foldersList: any,
  repoUsers: any,
  clipboard: any,
  setClipboard: any
}

export interface SearchPageState {
  userData?: any;
  repoData?: any;
  fileExplorerView: string;
  columns: IColumn[];
  items: IDocument[];
  uploadsQueue: any;
  storedFiles: any;
  selFile: any;
  showLeftPanel: boolean;
  showRightPanel: boolean;
  syncStatus: any;
  advancedSearchEnabled: boolean;
  searchInit: boolean;
  searchQuery: string;
  newSearchTerms: string;
  searchType: string;
  searchFolder: boolean;
  advancedSearchName: string;
  advancedSearchContent: string;
  advancedSearchId: string;
  nameOnly: boolean;
  nameOnlyDisabled: boolean;
  showSmartFolderDialog: boolean;
  newSmartFolder: string;
  isLoading: boolean;
  isLoadingMoreResults: boolean;
  startIndex: number;
  indexSize: number;
  canLoadMore: boolean;
  isSaving: boolean;
  isSaved: boolean;
  downloadingFiles: any;
  goBack: number;
  mobileView: boolean;
  toolbarHeight: number;
  tagsList: any;
  foldersList: any;
  typesList: any;
  searchOptions: string;
  searchOptionsFolders: string;
  pickerTypeEmpty: boolean;
  pickerTagEmpty: boolean;
  pickerFolderEmpty: boolean;
  searchInside: boolean;
  nameType: string;
  showContextualMenu: boolean;
  contextMenuId: string;
}

export interface IDocument {
  key: string;
  fileName: string;
  name: string;
  fileExtension: string;
  path: string;
  value: string;
  icon: string;
  fileType: string;
  createdBy: any;
  modifiedBy: any;
  dateCreated: string;
  dateModified: string;
  dateModifiedValue: number;
  fileSize: string;
  fileSizeRaw: number;
  author: string;
  date: number;
  currentVersion: string;
  revisionsCount: number;
  draft: boolean;
  savingDraft: boolean;
  lock: any;
  lockInstanceId: string;
  wopiLock: any;
  lockingDocument: boolean;
  msg: string;
  hash: string;
  stored: string;
  highlights: any;
  scoring: number;
}

export class SearchPage extends React.Component<Props, SearchPageState> {
  private _isMounted: boolean;
  private _selection: Selection;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();
  private fileExplorerRef:any = React.createRef();
  private detailsPanelRef:any = React.createRef();
  private choiceGroupRef: any = React.createRef();
  private picker:any = React.createRef<IBasePicker<ITag>>();
  private folderPicker:any = React.createRef<IBasePicker<ITag>>();
  private typePicker:any = React.createRef<IBasePicker<ITag>>();

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this._selection = new Selection({
      onSelectionChanged: () => {
        this._isMounted && this.setState({
          selFile: this._selection.getSelection()[this._selection.getSelection().length - 1] || []
        });
      }
    });

    this.state = {
      userData: null,
      repoData: null,
      fileExplorerView: localStorage.fileExplorerView || "list",
      columns: this._getColumns(),
      items: [],
      uploadsQueue: this._getImports(),
      storedFiles: [],
      selFile: null,
      showLeftPanel: true,
      showRightPanel: true,
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      advancedSearchEnabled: false,
      searchInit: false,
      searchQuery: "",
      newSearchTerms: "",
      searchType: "ALL",
      searchFolder: false,
      advancedSearchName: "",
      advancedSearchContent: "",
      advancedSearchId: "",
      nameOnly: false,
      nameOnlyDisabled: false,
      showSmartFolderDialog: false,
      newSmartFolder: '',
      isLoading: false,
      isLoadingMoreResults: false,
      startIndex: 1,
      indexSize: 50,
      canLoadMore: false,
      isSaving: false,
      isSaved: false,
      downloadingFiles: [],
      goBack: 0,
      mobileView: false,
      toolbarHeight: 89,
      tagsList: [],
      foldersList: [],
      typesList: [],
      searchOptions: "searchByName",
      searchOptionsFolders: "filesByTextInclSubfolders",
      pickerTypeEmpty: true,
      pickerTagEmpty: true,      
      pickerFolderEmpty: true,
      searchInside: true,
      nameType: "searchByName",
      showContextualMenu: false,
      contextMenuId: ''
    }
  }

  public componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.props.userData && this._getRepoData();
    
    if (this.props.searchType === "fullSearch" && this._searchBoxRef.current) this._searchBoxRef.current.focus();

    const newSearchQuery = window.location.search;
    if (newSearchQuery.length > 0 && this.props.foldersList) {
      this._isMounted && this.setState({
        selFile: null,
        searchQuery: newSearchQuery,
        isLoading: true
      }, () => {
        this._isMounted && this._search(newSearchQuery)
      });
     }
    
     this._onWindowResize();
     window.addEventListener('resize', this._onWindowResize);
  }

  public componentDidUpdate(prevProps: any, previousState: SearchPageState, nextProps) {
    if(this.props.userData !== prevProps.userData) {
      this.props.userData && this._getRepoData();
    }

    if (previousState.searchQuery !== window.location.search
      || this.props.foldersList !== prevProps.foldersList) {
      if(this.props.foldersList) {

        this._isMounted && this.setState({
          searchQuery: '',
          searchInit: false,
          items: [],
          startIndex: 1,
          columns: this._getColumns()
        })

        const searchQuery = window.location.search;
        if (searchQuery.length > 0) {
          this._isMounted && this.setState({
            selFile: null,
            searchQuery: searchQuery,
            isLoading: true,
            isSaved: false
          }, () => {      
            this._isMounted && this._search(searchQuery)
          });
        }
      }
    }

    if (prevProps.searchType !== this.props.searchType) {
      this._isMounted && this.setState({
        newSearchTerms: "",
        advancedSearchName: "",
        advancedSearchContent: "",
        advancedSearchId: "",
        columns: this._getColumns()
      })
    }

    if (previousState.advancedSearchEnabled === false && this.state.advancedSearchEnabled === true) {
      if (this.folderPicker.current) {
        setTimeout(()=>{
          if (this.folderPicker.current && this.folderPicker.current.selection._items.length === 0) {
            let repo = this.state.repoData[0];
            this.addFolder({key: repo.id, name: repo.name, search: repo.id, type: 'filterFolder'})
          }
        },50)
      }
    }

    // Custom titlebar content height 100vh fix
    if (this.props.searchType === "smartFolder") {
      let titlebar : HTMLDivElement | null  = document.querySelector('#top-titlebar');
      let titlebarHeight = titlebar ? titlebar.offsetHeight : 0;
      let toolbar : HTMLDivElement | null  = document.querySelector('.toolbar');
      if(toolbar instanceof HTMLDivElement) {
        let listTitlebarHeightFix : HTMLDivElement | null = document.querySelector('.list');
        if (listTitlebarHeightFix) {
          listTitlebarHeightFix.style.height = 'calc(100vh - ' + this.state.toolbarHeight + 'px - '  + titlebarHeight + 'px - 41px - ' + (isElectron() ? '34px)' : '0px)');
        }
      }
    } else {
      let toolbar : HTMLDivElement | null  = document.querySelector('.toolbar');
      if(toolbar instanceof HTMLDivElement) {

        let searchBox : HTMLDivElement | null  = document.querySelector('.searchBox');
        let listTitlebarHeightFix : HTMLDivElement | null = document.querySelector('.list');
        if(searchBox instanceof HTMLDivElement) {
          let searchBoxHeight = searchBox.offsetHeight + 64 + (isElectron() ? 34 : 0);

          if(listTitlebarHeightFix instanceof HTMLDivElement) {
            listTitlebarHeightFix.style.height = 'calc(100vh - ' + this.state.toolbarHeight + 'px - '  + searchBoxHeight + 'px  - 1rem - 1px)';
          }
        } else {
          if(listTitlebarHeightFix instanceof HTMLDivElement) {
            listTitlebarHeightFix.style.height = 'calc(100vh - ' + this.state.toolbarHeight + 'px  - 1rem - 1px)';
          }
        }
      }
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this._onWindowResize, false);
  }

  private _onWindowResize = () => {
    // Custom titlebar content height 100vh fix
    if (this.props.searchType === "smartFolder") {
      let titlebar : HTMLDivElement | null  = document.querySelector('#top-titlebar');
      let titlebarHeight = titlebar ? titlebar.offsetHeight : 0;
      let toolbar : HTMLDivElement | null  = document.querySelector('.toolbar');
      if(toolbar instanceof HTMLDivElement) {
        let listTitlebarHeightFix : HTMLDivElement | null = document.querySelector('.list');
        if (listTitlebarHeightFix) {
          listTitlebarHeightFix.style.height = 'calc(100vh - ' + this.state.toolbarHeight + 'px - '  + titlebarHeight + 'px - 41px - ' + (isElectron() ? '34px)' : '0px)');
        }
      }
    } else {
      let toolbar : HTMLDivElement | null  = document.querySelector('.toolbar');
      if(toolbar instanceof HTMLDivElement) {

        let searchBox : HTMLDivElement | null  = document.querySelector('.searchBox');
        let listTitlebarHeightFix : HTMLDivElement | null = document.querySelector('.list');
        if(searchBox instanceof HTMLDivElement) {
          let searchBoxHeight = searchBox.offsetHeight + 64 + (isElectron() ? 34 : 0);

          if(listTitlebarHeightFix instanceof HTMLDivElement) {
            listTitlebarHeightFix.style.height = 'calc(100vh - ' + this.state.toolbarHeight + 'px - '  + searchBoxHeight + 'px  - 1rem - 1px)';
          }
        } else {
          if(listTitlebarHeightFix instanceof HTMLDivElement) {
            listTitlebarHeightFix.style.height = 'calc(100vh - ' + this.state.toolbarHeight + 'px  - 1rem - 1px)';
          }
        }
      }
    }

    if (window.innerWidth <= 980 && !isElectron() && detectMob()) {
      this._isMounted && this.setState({mobileView: true})
    }
    else {
      this._isMounted && this.setState({mobileView: false})
    }
  }

  private updateItem = (id, name, value) => {
    var items:any = this.state.items
    
    if (items && items.length) {
      for (let i = 0; i < items.length; i++) {
        if (items[i] && items[i].id === id) {
          this._isMounted && this.setState(prevState => {
            const items = [...prevState.items];
            items[i] = { ...items[i], [name]: value};
            return { items };
          });   
        }
      }
    }
  }

  private updateCopiedItems = (items, contentIds, name) => {
    for (let i=0; i<items.length; i++) {
      if(items[i].clipboard && !contentIds.includes(items[i].id)) items[i].clipboard = false;
      else if (contentIds.includes(items[i].id)) items[i].clipboard = true;
    }
    this._isMounted && this.setState(prevState => {
      const items = [...prevState.items];
      setTimeout(()=>this.headerRef.forceUpdate(),100)
      return { items };
    }); 
  }

  callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  private _searchBoxRef:any = React.createRef<ISearchBox>();

  private getVersionColor(item:any) {
    if (item.revisions) {
      let revision = item.revisions[0]
      if (revision.version === "draft") return '#ffc107'
      else if (revision.minor !== "00" && revision.external) return '#cbf5c8'
      else if (revision.minor === "00" && revision.external) return '#28a745'
      else if (revision.minor !== "00" && !revision.external) return '#C7E0F4'
      else return '#007bff'
    } else return ''
  }

  private _getColumns = (): any[] => {
    var searchQuery = window.location.search;
    var params = new URLSearchParams(searchQuery);
    var searchFolder = params.get("searchType") === "FOLDER";

    return [
      {
        key: 'icon',
        name: <div><Icon style={{fontSize: '40%', marginRight: '27px', marginLeft: 0, color: 'transparent'}} iconName="StatusCircleRing" className="offline-status small my-auto" />#</div>,
        ariaLabel: 'Column operations for File type, Press to sort on File type',
        fieldName: 'fileType',
        minWidth: window.innerWidth > 980 || isElectron() || !detectMob() ? 50 : 48,
        maxWidth: window.innerWidth > 980 || isElectron() || !detectMob() ? 50 : 48,
        //isSortedDescending: false,
        //onColumnClick: this._onColumnClick,
        isPadded: false,
        onRender: (item: IDocument) => {
            if (window.innerWidth > 980 || isElectron() || !detectMob()) {
              return (<>
              <Icon style={{fontSize: '50%', marginRight: '22px', marginLeft: 0, color: 'transparent' || this.getVersionColor(item)}} iconName="CircleFill" className="offline-status small my-auto" />
              { item.fileType === 'dir' ?
                <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } />
              : item.fileType === 'file' ?
                <Icon {...getFileTypeIconProps({ extension: item.icon, size: 20, imageFileType: 'svg' }) } />
              : null }
              </>)
            }
            else {
              return (
                item.fileType === 'dir' ?
                  <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 48, imageFileType: 'svg' }) } />
                : item.fileType === 'file' ?
                  <Icon {...getFileTypeIconProps({ extension: item.icon, size: 48, imageFileType: 'svg' }) } />
                : null 
                )
            }
        }
      },
      {
        key: 'name',
        name: i18n.t('app:name'),
        fieldName: 'name',
        minWidth: 220,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
              <div>
                { item.savingDraft ?
                  <span title={ "Saving..." }><Icon iconName="Save" className="saving mr-2 small" /></span>
                : null }
                { item.lock && !item.wopiLock && !item.lockingDocument ?
                  <Icon iconName="Lock" className={"mr-2 small" + (item.lock.id === this.props.userData.user.id ? " text-primary" : "")} />
                : item.wopiLock && !item.lockingDocument ?
                  <Icon iconName="OfficeLogo" className="mr-2 small" />
                : item.lockingDocument ?
                  <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
                :
                  null
                }
                <span title={ item.fileName }>{ item.name }</span>
              </div>
            )
        }
      },
      {
        key: 'type',
        name: i18n.t('app:type'),
        fieldName: 'fileExtension',
        minWidth: 32,
        maxWidth: 32,
        isResizable: true,
        data: 'string',
        onColumnClick: this._onColumnClick,
        onRender: (item: IDocument) => {
          return (
              item.fileType === "dir" ?
                <span>folder</span>
              : item.fileType === "file" ?
                <span>{'.' + item.fileExtension}</span>
              : null
            )
        },
        isPadded: true
      },
      {
        key: 'path',
        name: i18n.t('app:path'),
        fieldName: 'path',
        minWidth: 170,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
              <span title={item.path}>{item.path}</span>
            )
        }
      },
      {
        key: 'currentVersion',
        name: i18n.t('app:version'),
        fieldName: 'currentVersion',
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        data: 'number',
        headerClassName: 'headerToRight',
        onColumnClick: this._onColumnClick,
        onRender: (item: IDocument) => {
          return <div style={{marginRight: 0, marginLeft: 'auto'}}>
            {item.fileType === "file" ? <span style={{display: 'flex'}}>{item.currentVersion}<Icon style={{fontSize: '40%', position: 'relative', top: '1px', marginLeft: '10px', color: this.getVersionColor(item)}} iconName="CircleFill" className="offline-status small my-auto" /></span> : null}
          </div>
        },
        styles: searchFolder ? { root: { display: "none" } } : {},
        className: searchFolder ? "d-none" : "",
      },
      {
        key: 'fileSize',
        name: i18n.t('app:size'),
        fieldName: 'fileSizeRaw',
        minWidth: 64,
        maxWidth: 64,
        isResizable: true,
        data: 'number',
        headerClassName: 'headerToRight',
        onColumnClick: this._onColumnClick,
        onRender: (item: IDocument) => {
          return (
            item.fileType === "file" ?
            <div style={{marginRight: 0, marginLeft: 'auto'}}>
              <span>{item.fileSize}</span>
            </div>
            : null
          )
        },
        styles: searchFolder ? { root: { display: "none" } } : {},
        className: searchFolder ? "d-none" : ""
      },
      {
        key: 'stored',
        name: i18n.t('app:stored'),
        isIconOnly: true,
        iconName: "Download",
        ariaLabel: 'Column operations for stored files, Press to sort on stored files',
        fieldName: 'stored',
        minWidth: 12,
        maxWidth: 12,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
        isPadded: false,
        onRender: (item: IDocument) => {
          return (<div className='d-flex align-items-center justify-content-center'>
            {item.stored === "same" ?
              <Icon style={{fontSize: 10, fontWeight: 600}} iconName="CheckMark" className="text-success" />
            : item.stored === "different" ?
              <Icon style={{fontSize: 10, fontWeight: 600}} iconName="SwitcherStartEnd" className="text-primary" />
            :  item.stored === "downloading" ?
              <Icon style={{fontSize: 10, fontWeight: 600}} iconName="Sync" className="text-primary small" />
            :  item.stored === "removing" ?
              <Icon style={{fontSize: 10, fontWeight: 600}} iconName="Sync" className="text-danger small" />
            :
              null}
          </div>)
        },
        styles: searchFolder ? { root: { display: "none" } } : {},
        className: searchFolder ? "d-none" : ""
      },
      {
        key: 'dateModified',
        name: i18n.t('app:modified'),
        fieldName: 'dateModified',
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'number',
        onRender: (item: IDocument) => {
          return <span>{ Moment(item.dateModified).format('Y-MM-DD HH:mm') }</span>;
        },
        isPadded: true
      },
      {
        key: 'modifiedBy',
        name: i18n.t('app:modifiedBy'),
        fieldName: 'modifiedBy',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        data: 'string',
        onColumnClick: this._onColumnClick,
        onRender: (item: IDocument) => {
          if(item.createdBy) {
            return <span className="author">{item.modifiedBy.name}</span>;
          } else {
            return null
          }
        },
        isPadded: true
      }
    ];
  }

  private _getColumnSize(column) {
    var dashboardColumnsWidth = JSON.parse(localStorage.getItem("dashboardColumnsWidth") || "[]") || []
    var columnSetting = dashboardColumnsWidth.filter(item => {
      return item.key === column
    })
    columnSetting = columnSetting[0]
    if(columnSetting) return columnSetting.width
    return null;
  }

  private listContainsTagList(tag: ITag, tagList?: ITag[]) {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some(compareTag => compareTag.key === tag.key);
  };

  private filterSuggestedTags(filter: string, tagList: any) {
    return filter
      ? this.state.tagsList.filter(
          tag => tag.name.toLowerCase().indexOf(filter.toLowerCase()) === 0 && !this.listContainsTagList(tag, tagList),
        )
      : [];
  };

  private filterSuggestedFolders(filter: string, tagList: any) {
    return filter
      ? this.state.foldersList.filter(
          tag => tag.name.toLowerCase().indexOf(filter.toLowerCase()) === 0 && !this.listContainsTagList(tag, tagList),
        )
      : [];
  };

  private filterSuggestedTypes(filter: string, tagList: any) {
    return filter
      ? this.state.typesList.filter(
          tag => tag.name.toLowerCase().indexOf(filter.toLowerCase()) === 0 && !this.listContainsTagList(tag, tagList),
        )
      : [];
  };

  private addTag(tag:any) {
    if (!this.state.advancedSearchEnabled) {
      this._isMounted && this.setState({advancedSearchEnabled: true})
    }
    setTimeout(()=> {
      if (this.picker.current) {
        let tagType:string = tag.key.split(': ')[0]
        let currentItems = this.picker.current.selection._items;
        let cantAdd = false;
        for (var i=0; i<currentItems.length; i++) {
          if (currentItems[i].key.split(': ')[0] === tagType) {
            if (currentItems[i].key === tag.key) {
              cantAdd = true;
            }
          }
        }
        if (!cantAdd) {
          this.picker.current.addItem(tag);   
        }
      }
      this.checkTagPickerEmpty()
    },50)
    
  }

  private addType(tag:any) {
    if (!this.state.advancedSearchEnabled) {
      this._isMounted && this.setState({advancedSearchEnabled: true})
    }
    setTimeout(()=> {
      if (this.typePicker.current) {
        let tagType:string = tag.key.split(': ')[0]
        let currentItems = this.typePicker.current.selection._items;
        let cantAdd = false;
        for (var i=0; i<currentItems.length; i++) {
          if (currentItems[i].key.split(': ')[0] === tagType) {
            if (currentItems[i].key === tag.key) {
              cantAdd = true;
            }
          }
        }
        if (!cantAdd) {
          this.typePicker.current.addItem(tag);   
        }
      }
      this.checkTypePickerEmpty()
    },50)
  }

  private addFolder(tag:any, open = true) {
    if (!this.state.advancedSearchEnabled && open) {
      this._isMounted && this.setState({advancedSearchEnabled: true})
    }
    setTimeout(()=> {
      if (this.folderPicker.current && this.folderPicker.current.selection._items.length === 0) {
        let tagType:string = tag.key.split(': ')[0]
        let currentItems = this.folderPicker.current.selection._items;
        let cantAdd = false;
        for (var i=0; i<currentItems.length; i++) {
          if (currentItems[i].key.split(': ')[0] === tagType) {
            if (currentItems[i].key === tag.key) {
              cantAdd = true;
            }
          }
        }
        if (!cantAdd) {
          this.folderPicker.current.addItem(tag);   
        }
      }
      else if (this.folderPicker.current && this.folderPicker.current.selection._items[0].key !== tag.key) {
        this.folderPicker.current.removeItems(this.folderPicker.current.selection._items);
        setTimeout(()=>{
          this.addFolder(tag)
        },50)
      }
      this.checkFolderPickerEmpty()
    },50)
  }

  private clearSearch() {
    history.push('/search'); 
    if (this.picker.current) this.picker.current.removeItems(this.picker.current.selection._items); 
    if (this.typePicker.current) this.typePicker.current.removeItems(this.typePicker.current.selection._items);
    if (this.folderPicker.current) this.folderPicker.current.removeItems(this.folderPicker.current.selection._items); 
    this._isMounted && this.setState({
      newSearchTerms: "",
      advancedSearchName: "",
      advancedSearchContent: "",
      advancedSearchId: "",
      pickerFolderEmpty: true,
      pickerTagEmpty: true,
      pickerTypeEmpty: true,
      searchOptions: 'searchByName',
      searchType: 'ALL',
      nameType: 'searchByName',
      searchOptionsFolders: 'filesByTextInclSubfolders',
    });
  }

  /*private handleSearchTypeChange = (ev: any, newValue?: IDropdownOption) => {
    if(newValue && newValue.key && typeof newValue.key === "string") {
      if (newValue.key === "searchFiles") {
        this._isMounted && this.setState({
          searchOptions: newValue.key,
          searchType: 'FILE',
          advancedSearchId : '',
          pickerFolderEmpty: true
        });
      }
      else if (newValue.key === "searchFolders") {
        this._isMounted && this.setState({
          searchOptions: newValue.key,
          searchType: 'FOLDER',
          advancedSearchId : '',
          pickerFolderEmpty: true,
          searchInside: false,
          searchOptionsFolders: 'filesByTextInclSubfolders'
        });
      }
      else {
        this._isMounted && this.setState({
          searchOptions: newValue.key,
          searchType: 'ALL',
          advancedSearchId : '',
          pickerFolderEmpty: true
        });
      }
    }
  }*/

  private handleNameTypeChange = (ev: any, newValue?: IDropdownOption) => {
    if(newValue && newValue.key && typeof newValue.key === "string") {
      if (newValue.key === "searchByName") {
        this._isMounted && this.setState({
          nameType: newValue.key,
          advancedSearchId : '',
          pickerFolderEmpty: true
        });
      }
      else if (newValue.key === "searchByID") {
        this._isMounted && this.setState({
          nameType: newValue.key,
          advancedSearchId : 'true',
          pickerFolderEmpty: true
        });
      }
    }
  }

  private handleSearchInFolderChange = (ev: any, newValue?: IDropdownOption) => {
    if(newValue && newValue.key && typeof newValue.key === "string") {
      if (newValue.key === "filesByTextInclSubfolders") {
        this._isMounted && this.setState({
          searchInside: true,
          searchOptionsFolders:newValue.key
        });
      }
      else if (newValue.key === "filesByTextInThisFolder"){
        this._isMounted && this.setState({
          searchInside: false,
          searchOptionsFolders:newValue.key
        });
      }
    }
  }

  private handleSearch(params) {
    let tags:any = this.picker.current.selection._items
    let items:any = tags.concat(this.typePicker.current.selection._items)
    for (var i=0; i< items.length; i++) {
      var tagsQuery = params.get('tags');
      var docTypeQuery: any = params.get('docType');
      let item = items[i]
      if (item && item.type === "tag") {
        params.set('tags', "")
        if (!tagsQuery) params.set('tags', item.key);
        else {
          var newTagQuery: any = tagsQuery.split(',')

          var repeated = newTagQuery.filter(tag => {
            return tag === item.key;
          })

          if(repeated.length === 0) {
            newTagQuery.push(item.key)
          } else {
            newTagQuery = newTagQuery.filter(tag => {
                return tag !== item.key;
            })
          }

          newTagQuery = newTagQuery.join(',')
          params.set('tags', newTagQuery);
          
        }
      }
      else if ((item && item.type === "filterPdf")
      || (item && item.type === "filterWord")
      || (item && item.type === "filterExcel")
      || (item && item.type === "filterPowerpoint")
      || (item && item.type === "filterEmail")) {

        if (docTypeQuery && docTypeQuery.indexOf(item.search) !== -1) {
          docTypeQuery = docTypeQuery.replace(item.search, "")
  
          // Remove duplicated commas
          docTypeQuery = docTypeQuery.replace(/,+/g,',');
    
          // Remove first comma
          if(docTypeQuery.startsWith(",")) docTypeQuery = docTypeQuery.substr(1)
  
          // Remove last comma
          if(docTypeQuery.startsWith(",")) docTypeQuery.substring(0, docTypeQuery.length - 1);
  
          params.set('docType', docTypeQuery);
        } else {
          if (docTypeQuery && docTypeQuery.length > 0) {
            docTypeQuery = docTypeQuery + "," + item.search;
          } else {
            docTypeQuery = item.search
          }
  
          // Remove duplicated commas
          docTypeQuery = docTypeQuery.replace(/,+/g,',');
    
          // Remove first comma
          if(docTypeQuery.startsWith(",")) docTypeQuery = docTypeQuery.substr(1)
  
          // Remove last comma
          if(docTypeQuery.startsWith(",")) docTypeQuery.substring(0, docTypeQuery.length - 1);
  
          params.set('docType', docTypeQuery); 
        }
      }
    }
    return params.toString()
    
  }

  private checkTagPickerEmpty() {
    if (this.picker.current && this.picker.current.selection._items.length === 0) {
      this._isMounted && this.setState({pickerTagEmpty: true})
    }
    else {
      this._isMounted && this.setState({pickerTagEmpty: false})
    }
  }

  private checkTypePickerEmpty() {
    if (this.typePicker.current && this.typePicker.current.selection._items.length === 0) {
      this._isMounted && this.setState({pickerTypeEmpty: true})
    }
    else {
      this._isMounted && this.setState({pickerTypeEmpty: false})
    }
  }

  private checkFolderPickerEmpty() {
    if (this.folderPicker.current && this.folderPicker.current.selection._items.length === 0) {
      this._isMounted && this.setState({pickerFolderEmpty: true})
    }
    else {
      this._isMounted && this.setState({pickerFolderEmpty: false})
    }
  }

  public render() {
    const { nameType, pickerTagEmpty, pickerTypeEmpty, advancedSearchEnabled, searchInit, showSmartFolderDialog, newSmartFolder, searchQuery, searchOptionsFolders, isLoading,  isLoadingMoreResults, canLoadMore, isSaving, isSaved, columns, items, selFile } = this.state;

    const folderSearchIcon: IIconProps = { iconName: 'FolderSearch' };

    const params = new URLSearchParams(searchQuery);
    var textQuery = params.get("text");
    //var tagsQuery = params.get("tags");
    var nameQuery = params.get("name");
    var idQuery = params.get("docId");
    var docTypeQuery = params.get("docType");

    const selectedCount = this.fileExplorerRef._selection && this.fileExplorerRef._selection.getSelectedCount()

    var selFilePathIds = selFile && selFile.pathIds && selFile.pathIds.split("/").slice(1)
    var selFilePathNames = selFile && selFile.pathIds && selFile.pathNames.split("/").slice(1)
    var itemBreadcrumb = selFilePathIds && selFilePathNames && selFilePathIds.map((e, i) => {
      return {
        key: e,
        text: selFilePathNames[i],
        onRender: ()=>{return (
          <div id={'crumb-'+e} className='breadcrumb-button' onClick={() => history.push('/files/' + e)} 
            onContextMenu={(event)=>{event.preventDefault(); this.setState({showContextualMenu: true, contextMenuId: e})}}>
            <span className='noselect'>{selFilePathNames[i]}</span>
          </div>
        )},

      }
    });
    if (!itemBreadcrumb && selFile && selFile.parentId) {
      itemBreadcrumb = [{key: selFile.parentId,
        text: selFile.path.replace('/',''),
        onRender: ()=>{return (
          <div id={'crumb-'+selFile.parentId} className='breadcrumb-button' onClick={() => history.push('/files/' +  (selFile ? selFile.parentId : ''))} 
            onContextMenu={(event)=>{event.preventDefault(); this.setState({showContextualMenu: true, contextMenuId: selFile.parentId})}}>
            <span className='noselect'>{selFile.path.replace('/','')}</span>
          </div>
        )},
      }]
    }

    const contextualMenuItems: IContextualMenuItem[] = [
      {
        key: 'openFolder',
        name: i18n.t('app:openFolder'),
        onClick: () => history.push('/files/' + this.state.contextMenuId),
        iconProps: { iconName: 'FabricOpenFolderHorizontal' },
        hidden: !this.props.isOnline || this.props.maintenance
      },
      {
        key: 'openFolderInNewWindow',
        name: i18n.t('app:openFolderInNewWindow'),
        onClick: () => this.fileExplorerRef._openFolderInNewWindow(this.state.contextMenuId),
        iconProps: { iconName: 'OpenInNewWindow' },
        hidden: !this.props.isOnline || this.props.maintenance || !isElectron()
      },
    ]

    return(
      <Fabric>
        <Header
          ref={instance => { this.headerRef = instance; }}
          appPlatform={ this.props.appPlatform }
          isOnline={ this.props.isOnline }
          maintenance={ this.props.maintenance }
          pusher={this.props.pusher}
          repoData={ this.state.repoData }
          userData={ this.props.userData }
          foldersList={ this.props.foldersList }
          fileExplorerView={ this.state.fileExplorerView }
          selFile={ this.state.selFile }
          searchQuery={ window.location.search }
          showRightPanel={ this.state.showRightPanel }
          syncStatus={ this.state.syncStatus }
          getCurrentContent={ this._getCurrentContent.bind(this) }
          refreshCurrentContent={ this._refreshCurrentContent.bind(this) }
          downloadingFiles={ this.state.downloadingFiles }
          uploadsQueue={ this.state.uploadsQueue }
          detailsPanelRef={ this.detailsPanelRef }
          callbackFunction={ this.callbackFunction }
          updateItem={ this.updateItem }
          headerSection="search"
          searchType={ this.props.searchType }
          fileExplorerRef={ this.fileExplorerRef }
          fileExplorerType={this.props.fileExplorerType}
          sidebarRef={ this.sidebarRef }
          clearSearch={this.clearSearch.bind(this)}
        />

        <div className="d-flex flex-row">

          <Sidebar
            ref={instance => { this.sidebarRef = instance; }}
            appPlatform={ this.props.appPlatform }
            isOnline={ this.props.isOnline }
            maintenance={ this.props.maintenance }
            appUpdateAvailable={ this.props.appUpdateAvailable }
            webUpdateAvailable={ this.props.webUpdateAvailable }
            userData={ this.props.userData }
            foldersList={ this.props.foldersList }
            searchType={ this.props.searchType }
            searchQuery={ this.state.searchQuery }
            headerSection="search"
            headerRef={ this.headerRef }
            fileExplorerRef={ this.fileExplorerRef }
            callbackFunction={ this.callbackFunction }
            addTag={this.addTag.bind(this)}
            addType={this.addType.bind(this)}
            addFolder={this.addFolder.bind(this)}
            repoUsers={ this.props.repoUsers }
          />

          <div className="flex-grow-1">

            { this.props.searchType === "fullSearch" ?
              <div className="searchBox searchBox-top pt-3 pb-0 px-3">
                <div className="searchBox-wrap mx-auto">
                  { advancedSearchEnabled ?
                    <div className="mb-2">
                      <div className='row'>
                        <p className="title" style={{width:'230px', paddingLeft: '16px'}}>{i18n.t('app:advancedSearch')}</p>
                        <ChoiceGroup selectedKey={this.state.searchType} className="inlineFlex"
                          onChange={ this.handleSearchFolderChange }
                          options={[
                            { key: 'ALL', text: i18n.t('app:files') + " & " + i18n.t('app:folders') },
                            { key: 'FILE', text: i18n.t('app:files') },
                            { key: 'FOLDER', text: i18n.t('app:folders') }
                          ]}
                          id='choice-group'
                          componentRef={this.choiceGroupRef}
                          style={{display: 'inline', position: 'relative', top: '0px', paddingRight:'50px', paddingLeft:'16px'}}
                        />
                      </div>
                      
                        <div className='filter-name'>
                          <Dropdown
                            placeholder={"Select an option"}
                            defaultSelectedKey={nameType}
                            options={[
                              { key: 'searchByName', text:i18n.t('app:name')},
                              { key: 'searchByID', text: i18n.t('app:id')}
                            ]}
                            dropdownWidth={205}
                            onChange={ this.handleNameTypeChange }
                            style={{top:0, position:'absolute', zIndex:2, width:'205px'}}
                            
                          />
                          <form name="advancedSearch" onSubmit={ this._goAdvancedSearch.bind(this) } >
                          <TextField className='d-inline mt-3' underlined name={i18n.t('app:name')} value={ this.state.advancedSearchName } onChange={ this.handleAdvancedSearchName } autoFocus />
                          </form>
                        </div>
                        <div className='mt-3 filter-content'>
                          <span className='filter-span' style={{paddingTop:'6px', fontWeight:500, color: this.state.searchType !== 'FILE' ? 'rgb(161, 159, 157)' : 'black'}}>{i18n.t('app:fileContent')}</span>
                          <form name="advancedSearch" onSubmit={ this._goAdvancedSearch.bind(this) } >
                          <TextField className='d-inline mt-3' underlined value={ this.state.advancedSearchContent } onChange={ this.handleAdvancedSearchContent } disabled={this.state.searchType !== "FILE"} />
                          </form>
                        </div>
                        {/*<TextField className='mt-3' label={i18n.t('app:id')+":"} underlined placeholder={i18n.t('app:id')} value={ this.state.advancedSearchId } onChange={ this.handleAdvancedSearchId } />*/}
                        <div className='mt-3 filter-folder'>
                          <Dropdown
                            placeholder={i18n.t('app:searchByContent')}
                            selectedKey={searchOptionsFolders}
                            options={[
                              { key: 'filesByTextInclSubfolders', text: i18n.t('app:folderAndSubfolders') },
                              { key: 'filesByTextInThisFolder', text: i18n.t('app:onlyThisFolder') }
                            ]}
                            dropdownWidth={205}
                            onChange={ this.handleSearchInFolderChange }
                            style={{top:'0', position:'absolute', zIndex:2, width:'205px'}}
                          />
                          <form name="advancedSearch" onSubmit={ this._goAdvancedSearch.bind(this) } >
                          <TagPicker 
                            className='d-inline'
                            componentRef={this.folderPicker}
                            onResolveSuggestions={this.filterSuggestedFolders.bind(this)}
                            inputProps={{
                              id: 'picker1',
                            }}
                            onRenderItem={(item:any)=> {
                              return(
                                <div key={item.key} className='d-flex align-items-center tag-badge'>
                                  <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } className='mr-2' style={{height:'26px'}}/>
                                  <span>{item.item.name}</span>
                                  <IconButton onClick={()=>{this.folderPicker.current.removeItem(item.item);setTimeout(this.checkFolderPickerEmpty.bind(this),50)}} className='remove-tag-button' iconProps={{iconName:'Cancel', style:{fontSize:12}}}/>
                                </div>
                              )
                            }}
                            onChange={this.checkFolderPickerEmpty.bind(this)}
                            //disabled={this.state.searchType !== 'FILE'}
                          />
                          </form>
                          {/*
                            !pickerFolderEmpty && this.folderPicker.current && <IconButton onClick={()=>{
                              this.folderPicker.current.removeItems(this.folderPicker.current.selection._items);
                              setTimeout(this.checkFolderPickerEmpty.bind(this),50)
                            }} iconProps={{iconName: "Cancel"}} className='reset-search' style={{position:'absolute', bottom: '2px', margin: 0, right: 0}}/>
                          */}
                         
                        </div> 
                        <div className='mt-3 filter-tags'>
                          <span className='filter-span' style={{paddingTop:'4px', fontWeight:500, zIndex:2}}>{i18n.t('app:tags')}</span>
                          <form name="advancedSearch" onSubmit={ this._goAdvancedSearch.bind(this) } >
                          <TagPicker
                            className='d-inline'
                            componentRef={this.picker}
                            onResolveSuggestions={this.filterSuggestedTags.bind(this)}
                            inputProps={{
                              id: 'picker1',
                            }}
                            disabled={this.state.searchType === "ID"}
                            onRenderItem={(item:any)=> {
                              return(
                                <div key={item.key} className='d-flex align-items-center tag-badge font-weight-bold mt-1' style={{backgroundColor: item.item.color || 'rgb(169, 169, 169,.7)', color: pickTextColorBasedOnBgColor(item.item.color || 'rgb(169, 169, 169,.7)', '#ffffff', '#000000') , borderRadius: '4px', height: '20px'}}>
                                  <span>{item.item.name}</span>
                                  <IconButton style={{color: pickTextColorBasedOnBgColor(item.item.color || 'rgb(169, 169, 169,.7)', '#ffffff', '#000000')}} onClick={()=>{this.picker.current.removeItem(item.item);setTimeout(this.checkTagPickerEmpty.bind(this),50)}} className='remove-tag-button' iconProps={{iconName:'Cancel', style:{fontSize:12}}}/>
                                </div>
                              )
                            }}
                            onChange={this.checkTagPickerEmpty.bind(this)}
                          />
                          </form>
                          {
                            !pickerTagEmpty && <IconButton onClick={()=>{
                              this.picker.current.removeItems(this.picker.current.selection._items);
                              setTimeout(this.checkTagPickerEmpty.bind(this),50)
                            }} iconProps={{iconName: "Cancel"}} className='reset-search' style={{position:'absolute', bottom: '2px', margin: 0, right: 0}}/>
                          }
                        </div>
                        <div className='mt-3 filter-type'>
                          <span className='filter-span' style={{ paddingTop:'4px', fontWeight:500}}>{i18n.t('app:fileType')}</span>
                          <form name="advancedSearch" onSubmit={ this._goAdvancedSearch.bind(this) } >
                          <TagPicker
                            className='d-inline'
                            componentRef={this.typePicker}
                            onResolveSuggestions={this.filterSuggestedTypes.bind(this)}
                            inputProps={{
                              id: 'picker1',
                            }}
                            //disabled={this.state.nameType === "searchByID"}
                            onRenderItem={(item:any)=> {
                              let type = item.item.search.split(',')[0]
                              var icon = type === 'doc' ? 'WordDocument' : type === 'ppt' ? 'PowerPointDocument' : type === 'xls' ? 'excelDocument' : type === 'pdf' ? 'PDF' : 'Mail'
                              return(
                                <div key={item.key} className='d-flex align-items-center tag-badge'>
                                  <Icon  iconName={icon} className={type + " mr-1"} />
                                  <span>{item.item.name}</span>
                                  <IconButton onClick={()=>{this.typePicker.current.removeItem(item.item);setTimeout(this.checkTypePickerEmpty.bind(this),50)}} className='remove-tag-button' iconProps={{iconName:'Cancel', style:{fontSize:12}}}/>
                                </div>
                              )
                            }}
                            onChange={this.checkTypePickerEmpty.bind(this)}
                          />
                          </form>
                          {
                            !pickerTypeEmpty && <IconButton onClick={()=>{
                              this.typePicker.current.removeItems(this.typePicker.current.selection._items);
                              setTimeout(this.checkTypePickerEmpty.bind(this),50)
                            }} iconProps={{iconName: "Cancel"}} className='reset-search' style={{position:'absolute', bottom: '2px', margin: 0, right: 0}}/>
                          }
                        </div>
                    </div>
                  : 
                  <>
                  {/*<SearchBox
                      componentRef={this._searchBoxRef}
                      placeholder={i18n.t('app:search')}
                      onSearch={newValue => this._goSearch(newValue)}
                      value={ newSearchTerms }
                      onChange={ this.handleSearchTermsChange }
                      autoFocus
                    />*/
                    <div className="mb-2">
                      <div className='row'>
                        <p className="title" style={{width:'230px', paddingLeft: '16px'}}>{i18n.t('app:search')}</p>
                        <ChoiceGroup selectedKey={this.state.searchType} className="inlineFlex"
                          onChange={ this.handleSearchFolderChange }
                          options={[
                            { key: 'ALL', text: i18n.t('app:files') + " & " + i18n.t('app:folders') },
                            { key: 'FILE', text: i18n.t('app:files') },
                            { key: 'FOLDER', text: i18n.t('app:folders') }
                          ]}
                          id='choice-group'
                          componentRef={this.choiceGroupRef}
                          style={{display: 'inline', position: 'relative', top: '0px', paddingRight:'50px', paddingLeft:'16px'}}
                        />
                      </div>
                      <form onSubmit={this._goAdvancedSearch.bind(this)}>
                        <div className='filter-name'>
                          <Dropdown
                            placeholder={"Select an option"}
                            defaultSelectedKey={nameType}
                            options={[
                              { key: 'searchByName', text:i18n.t('app:name')},
                              { key: 'searchByID', text: i18n.t('app:id')}
                            ]}
                            dropdownWidth={205}
                            onChange={ this.handleNameTypeChange }
                            style={{top:0, position:'absolute', zIndex:2, width:'205px'}}
                            
                          />
                          <TextField className='d-inline mt-3' underlined name={i18n.t('app:name')} value={ this.state.advancedSearchName } onChange={ this.handleAdvancedSearchName } autoFocus />
                        </div>
                    </form>
                    </div>
                    
                    }
                  </>
                  }

                  <div className="advanced-search-trigger">
                    <IconButton onClick={ () => this._isMounted && this.setState({newSearchTerms: "", advancedSearchContent: "", advancedSearchEnabled: !this.state.advancedSearchEnabled}) } iconProps={{ iconName: 'ListMirrored' }} title={i18n.t('app:advancedSearch')} ariaLabel="Advanced search" className="advanced-search" />
                  </div>

                  <div className="d-flex">
                    <div className="flex-grow-1 mr-auto">
                      <ActionButton onClick={ () => this._isMounted && this.setState({showSmartFolderDialog: true}) } iconProps={ folderSearchIcon } disabled={!items.length} className="save-smart-folder">

                        <span>{i18n.t('app:saveAsSmartFolder')}</span>

                        { isSaved ?
                          <Icon iconName="CheckMark" title={i18n.t('app:saveAsSmartFolder')} ariaLabel="Saved as Smart Folder" className="text-success ml-2" />
                        : null }
                      </ActionButton>
                    </div>

                    <div className="my-2 text-right">
                      { advancedSearchEnabled ?
                        <>
                          <ActionButton onClick={this.clearSearch.bind(this)} iconProps={{iconName: 'Cancel'}} ariaLabel="Reset search" className="clear-search mb-2 ml-2 align-top" >
                            <span className='align-items-center' style={{fontWeight:500, padding:'0 4px'}}>{i18n.t('app:resetSearch')}</span>
                          </ActionButton>
                          <PrimaryButton onClick={this._goAdvancedSearch.bind(this)} text={i18n.t('app:search')} disabled={this.state.syncStatus.type !== "success"} className="align-top mb-2 ml-2"/>
                        </>
                      :
                        <>
                          <ActionButton onClick={this.clearSearch.bind(this)} iconProps={{iconName: 'Cancel'}} ariaLabel="Reset search" className="clear-search mb-2 ml-2 align-top" >
                            <span className='align-items-center' style={{fontWeight:500, padding:'0 4px'}}>{i18n.t('app:resetSearch')}</span>
                          </ActionButton>
                          <PrimaryButton onClick={this._goAdvancedSearch.bind(this)} text={i18n.t('app:search')} disabled={this.state.syncStatus.type !== "success"} className="align-top mb-2 ml-2"/>
                        </>
                      }
                    </div>
                  </div>
                </div>

                { showSmartFolderDialog ?
                  <Dialog
                    hidden={!showSmartFolderDialog}
                    onDismiss={this._closeDialog}
                    dialogContentProps={{
                      type: DialogType.normal,
                      title: i18n.t('app:createSmartFolder')
                    }}
                    modalProps={{
                      titleAriaId: "Create Smart Folder",
                      isBlocking: isSaving,
                      dragOptions: true ? this._dragOptions : undefined,
                      className: "smart-folder-dialog"
                    }}
                  >
                    <form name="form" onSubmit={this._saveSmartFolder} >
                      <TextField underlined autoFocus placeholder={i18n.t('app:smartFolderName')} name="newFolder" value={ newSmartFolder } onChange={ this.handleSmartFolderChange } label="Title" disabled={ isSaving }required />
                    </form>

                    <div className="search-data my-3"> 
                    <p className="label font-weight-bold mb-1">{i18n.t('app:search')}:</p>
                    <ul>
                      <li>
                        <span className="mr-1">{i18n.t('app:name')}:</span>
                        { nameQuery ?
                          <span className="font-weight-bold">{nameQuery}</span>
                        :
                          <span>{i18n.t('app:none')}</span>
                        }
                      </li>
                      <li>
                        <span className="mr-1">{i18n.t('app:content')}:</span>
                        { textQuery ?
                          <span className="font-weight-bold">{textQuery}</span>
                        :
                          <span>{i18n.t('app:none')}</span>
                        }
                      </li>
                      {/*<li>
                        <span className="mr-1">{i18n.t('app:tags')}:</span>
                        { tagsQuery ?
                          <span className="font-weight-bold">{tagsQuery}</span>
                        :
                          <span>{i18n.t('app:none')}</span>
                        }
                      </li>*/}
                      { docTypeQuery ?
                        <li>
                            <>
                            <span className="mr-1">{i18n.t('app:fileType')}:</span>
                            <span className="font-weight-bold">{docTypeQuery}</span>
                            </>
                        </li>  
                      : null }
                      { idQuery ?
                        <li>
                            <>
                            <span className="mr-1">{i18n.t('app:id')}:</span>
                            <span className="font-weight-bold">{idQuery}</span>
                            </>
                        </li>  
                      : null }
                    </ul>
                    </div>

                    <DialogFooter>

                      { isSaving ?
                        <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-text-top" />
                      : null }
                      { isSaved ?
                        <Icon iconName="CheckMark" title={i18n.t('app:savedAsSmartFolder')} ariaLabel="Saved as Smart Folder" className="text-success d-inline-block align-text-top" />
                      : null }

                      <DefaultButton onClick={this._closeDialog} text={i18n.t('app:cancel')} disabled={isSaving}/>
                      <PrimaryButton onClick={this._saveSmartFolder} text={i18n.t('app:add')} disabled={isSaving || !newSmartFolder.length} />                   
                    </DialogFooter>
                  </Dialog>
                : null }
              </div>
            : null }

            <hr className='m-0'/>

            <div className="folders-breadcrumb-search-results flex-grow-1" style={{minWidth:'60px'}}>
              <div className="row m-0 p-0">
                <div className="breadcrumb-icon">
                  <Icon iconName="FabricFolder" className="repoIcon" />
                </div>

                <div className="col m-0 p-0" style={{minWidth:'30px'}}>
                  { selectedCount === 1 && itemBreadcrumb ?
                    <Breadcrumb
                      items={itemBreadcrumb}
                      ariaLabel="Breadcrumb"
                      overflowAriaLabel="Breadcrumb"
                      overflowIndex={ 0 }
                    />
                  : selectedCount > 1 ?
                    <Breadcrumb
                      items={[{
                        text: selectedCount + ' items selected',
                        key: 'multipleItemsBreadcrumb',
                      }]}
                      ariaLabel="Breadcrumb"
                      overflowAriaLabel="More folders"
                      overflowIndex={ 0 }
                    />
                  :
                    <Breadcrumb
                      items={[{
                        text: 'Select an item to show its path',
                        key: 'emptyBreadcrumb',
                      }]}
                      ariaLabel="Breadcrumb"
                      overflowAriaLabel="Breadcrumb"
                      overflowIndex={ 0 }
                    />
                  }
                </div>
              </div>
            </div>

            <hr className='m-0'/>

            <div className={ (searchInit || this.props.searchType === "tag" ? "d-flex flex-row" : "d-none") }>
              <div className={"list search mr-auto flex-grow-1 " + this.props.appPlatform}>
                  <FileExplorer
                    ref={instance => { this.fileExplorerRef = instance; }}
                    appPlatform={ this.props.appPlatform }
                    isOnline={ this.props.isOnline }
                    maintenance={ this.props.maintenance }
                    socket={this.props.socket}
                    pusher={this.props.pusher}
                    userData={this.props.userData}
                    foldersList={ this.props.foldersList }
                    fileExplorerView={ this.state.fileExplorerView }
                    items={this.props.searchType === "tag" ? this.state.tagsList : items}
                    searchType={this.props.searchType}
                    searchQuery={ this.state.searchQuery }
                    selFile={ this.state.selFile }
                    isCompactMode={true}
                    columns={columns}
                    selectionMode={SelectionMode.multiple}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    enableShimmer={isLoading}
                    constrainMode={ConstrainMode.unconstrained}
                    getCurrentContent={ this._getCurrentContent.bind(this) }
                    refreshCurrentContent={ this._refreshCurrentContent.bind(this) }
                    isLoading={isLoading}
                    canLoadMore={canLoadMore}
                    isLoadingMoreResults={isLoadingMoreResults}
                    resultsType="search"
                    emptyMessage={i18n.t('app:noItemsSearch')}
                    headerRef={ this.headerRef }
                    detailsPanelRef={ this.detailsPanelRef }
                    callbackFunction={ this.callbackFunction }
                    updateItem={ this.updateItem }
                    updateCopiedItems={ this.updateCopiedItems }
                    clipboard={this.props.clipboard}
                    setClipboard={this.props.setClipboard}
                    showLeftPanel={this.state.showLeftPanel}
                    showRightPanel={this.state.showRightPanel}
                  >
                    
                  </FileExplorer>
              </div>
            </div>
              
          </div>

          {!this.state.mobileView ?
          <DetailsPanel
            ref={instance => { this.detailsPanelRef = instance; }}
            appPlatform={ this.props.appPlatform }
            userData={ this.props.userData }
            repoData={ this.state.repoData }
            repoUsers={ this.props.repoUsers }
            showRightPanel={ this.state.showRightPanel }
            selection={ this._selection }
            selFile={ this.state.selFile }
            searchQuery={ this.state.searchQuery }
            searchType={this.props.searchType}
            getCurrentContent={ this._getCurrentContent.bind(this) }
            fileId={ window.location.pathname.replace('/files/', '').replace('/search/', '') }
            fileExplorerRef={ this.fileExplorerRef }
          />
        :
          <DetailsPanel
            ref={instance => { this.detailsPanelRef = instance; }}
            appPlatform={ this.props.appPlatform }
            userData={ this.props.userData }
            repoData={ this.state.repoData }
            repoUsers={ this.props.repoUsers }
            showRightPanel={ false }
            selection={ this._selection }
            selFile={ this.state.selFile }
            searchQuery={ this.state.searchQuery }
            searchType={this.props.searchType}
            getCurrentContent={ this._getCurrentContent.bind(this) }
            fileId={ window.location.pathname.replace('/files/', '').replace('/search/', '') }
            fileExplorerRef={ this.fileExplorerRef }
          />}
        </div>
        <ContextualMenu
          items={contextualMenuItems}
          hidden={!this.state.showContextualMenu}
          target={this.state.contextMenuId ? "#crumb-" + this.state.contextMenuId : undefined}
          onItemClick={() => this.setState({showContextualMenu: false})}
          onDismiss={() => this.setState({showContextualMenu: false})}
        />
      </Fabric>
    )
  }

  private _getRepoData() {
    var userData = this.props.userData
    var repo = userData && userData.repository;
    var repoData: any = [];

    repoData.push({
      id: repo.id,
      name: repo.name,
      role: userData.role
    })

    this._isMounted && this.setState({
      repoData: repoData
    }, () => {
      const { searchQuery, repoData } = this.state;

       const params = new URLSearchParams(searchQuery);
       var repoQuery = params.get('parent');

      if(!repoQuery) {
          params.set('parent', repoData[0].id)
          this._isMounted && this.setState({
            searchQuery: params.toString()
          });
       }
    })
  }

  private _goSearch(terms) {
    const { searchQuery, nameOnly} = this.state;

    const params = new URLSearchParams(searchQuery); 

    // Search by id
    if(terms.indexOf("id:") !== -1) {
      let termsSplit = terms.split("id:")
      let str = termsSplit[termsSplit.length - 1];
      let idSplit = str.split(" ")
      let id = idSplit[0]
      terms = terms.replace("id:" + id, "")
      params.set('docId', id);
    }

    if (nameOnly) {
      params.set('name', terms);
      params.delete('text');
    } else {
      if (terms && terms.match( /(name=)/ )) {
        let termsSplit = terms.split("name=")
        let str = termsSplit[termsSplit.length - 1];

        let name;
        let newTerms = terms;

        if(str.startsWith("\"") ) {
          name = str.split('"')[1]
          newTerms = terms.replace("name=" + JSON.stringify(name), "")
        } else {
          name = str.split(" ")[0];
          newTerms = terms.replace("name=" + name, "")
        }

        if(newTerms.startsWith(" ")) newTerms = newTerms.replace(/^\s+/g, '');

        params.set('name', name);
        params.set('text', newTerms);
      } else {
        params.set('name', terms);
      }
    }

    var parent = params.get("parent")
    if(!parent) params.set("parent", this.state.repoData[0].id)

    history.push("/search/?" + params.toString());
  }

  private _goAdvancedSearch: any = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { searchQuery, searchType, searchFolder, advancedSearchName, advancedSearchContent, advancedSearchId, searchInside} = this.state;

    const params = new URLSearchParams(searchQuery);


    // Search by name
    if(advancedSearchName !== "") params.set('name', advancedSearchName);
    else params.delete('name')

    // Search by content
    if(advancedSearchContent !== "") params.set('text', advancedSearchContent);
    else params.delete('text')

    // Search by id
    if(advancedSearchId !== "")  {
      params.set('docId', advancedSearchName);
      params.delete('name');
    }
    else params.delete('docId')

    var parent = params.get("parent")
    if(!parent) params.set("parent", this.state.repoData[0].id)

    if (this.folderPicker.current && this.folderPicker.current.selection._items.length !== 0 ) {
      params.set('parent', this.folderPicker.current.selection._items[0].key)
    }
    else {
      params.set("parent", this.state.repoData[0].id)
    }

    params.set("searchType", searchType)

    if(searchFolder) {
      params.delete("text")
      params.set("tags", "")
    }

    if (searchInside === true) {
      params.set('searchInside', 'true')
    }
    else {
      params.set('searchInside', 'false')
    }

    params.delete("tags")
    params.delete("docType")
    if (this.picker.current) {
      let fullParams:any = this.handleSearch(params)
      history.push("/search/?" + fullParams.toString());
    }
    else {
      history.push("/search/?" + params.toString());
    }

  }

  private handleSearchTermsChange = (ev: any, newValue?: string) => {
    this.setState({
      isSaved: false,
      newSearchTerms: newValue || ''
    });

    if(newValue && newValue.match( /(name=)/ )) {
      this.setState({
        nameOnly: false,
        nameOnlyDisabled: true
      })
    } else {
      this.setState({ nameOnlyDisabled: false })
    }
  }

  private handleSearchFolderChange = (ev: any, option?: IChoiceGroupOption) => {
    var searchFolder = option && (option.key === "ALL" || option.key === "FOLDER")

    if(searchFolder !== undefined && option) {
      this._isMounted && this.setState({
        isSaved: false,
        searchType: option.key,
        searchFolder: searchFolder
      });
    }
  }

  private handleAdvancedSearchName = (ev: any, newValue?: string) => {
    this._isMounted && this.setState({
      isSaved: false,
      advancedSearchName: newValue || ""
    });
  }

  private handleAdvancedSearchContent = (ev: any, newValue?: string) => {
    this._isMounted && this.setState({
      isSaved: false,
      advancedSearchContent: newValue || ""
    });
  }

  private handleAdvancedSearchId = (ev: any, newValue?: string) => {
    this.setState({
      isSaved: false,
      advancedSearchId: newValue || ""
    });
  }

  private _search(terms) {
    var { searchQuery } = this.state;

    const params = new URLSearchParams(searchQuery);
    var textQuery = params.get('text');
    var tagsQuery:any = params.get('tags');
    var nameQuery = params.get('name');
    var parentQuery = params.get('parent') || "";
    var idQuery = params.get('docId') || "";
    var docTypeQuery = params.get('docType') || "";
    var searchTypeQuery = params.get('searchType') || "";
    var searchInsideQuery = params.get('searchInside') || "";
    var paginationResultFrom = this.state.startIndex;
    var paginationResultSize = this.state.indexSize;


    if (searchTypeQuery && searchTypeQuery.length > 0) {
      setTimeout(()=>{
        this._isMounted && this.setState({searchType: searchTypeQuery || ''})
      },50)
    }
    if (nameQuery && nameQuery.length > 0) {
      setTimeout(()=>{
        this._isMounted && this.setState({advancedSearchName: nameQuery || ''})
      },50)
    }
    if (idQuery && idQuery.length > 0) {
      setTimeout(()=>{
        this._isMounted && this.setState({
          advancedSearchName: idQuery || '',
          nameType: 'searchByID',
          advancedSearchId : 'true'
        })
      },50)
    }
    if (textQuery && textQuery.length > 0) {
      if (!this.state.advancedSearchEnabled) this._isMounted && this.setState({advancedSearchEnabled: true})
      setTimeout(()=>{
        this._isMounted && this.setState({
          advancedSearchContent: textQuery || '',
        })
      },50)
    }

    if (tagsQuery && tagsQuery.length > 0) {
      if (!this.state.advancedSearchEnabled) this._isMounted && this.setState({advancedSearchEnabled: true})
      setTimeout(()=>{
        var newTagQuery: any = tagsQuery.split(',')
        for (let i=0; i<newTagQuery.length; i++) {
          let tag = newTagQuery[i];
          const {tagsList} = this.state;
          for (let i=0; i<tagsList.length; i++) {
            if (tagsList[i].id === tag) this.addTag({key: tagsList[i].id, name:tagsList[i].name, search: tagsList[i].id, type: tagsList[i].type, color: tagsList[i].color})
          }
        }
      },50)
    }
    if (docTypeQuery && docTypeQuery.length > 0) {
      if (!this.state.advancedSearchEnabled) this._isMounted && this.setState({advancedSearchEnabled: true})
      setTimeout(()=>{
        var newTypeQuery: any = docTypeQuery.split(',')
        for (let i=0; i<newTypeQuery.length; i++) {
          let tag = newTypeQuery[i];
          let type =  tag === 'doc' ? 'filterWord' : tag === 'docx' ? 'filterWord' 
                    : tag === 'xls' ? 'filterExcel' : tag === 'xlsx' ? 'filterExcel' 
                    : tag === 'ppt' ? 'filterPowerpoint' : tag === 'pptx' ? 'filterPowerpoint' 
                    : tag === 'pdf' ? 'filterPdf' : tag === 'msg' ? 'filterEmail' : null
          let name =  tag === 'doc' ? 'Word' : tag === 'docx' ? 'Word' 
                    : tag === 'xls' ? 'Excel' : tag === 'xlsx' ? 'Excel' 
                    : tag === 'ppt' ? 'PowerPoint' : tag === 'pptx' ? 'PowerPoint' 
                    : tag === 'pdf' ? 'PDF' : tag === 'msg' ? 'Email' : null
          let search = tag === 'doc' || tag === 'docx' ? "doc,docx" : tag === 'xls' || tag === 'xlsx' ? "xls,xlsx" : tag === 'ppt' || tag === 'pptx' ? "ppt,pptx" : tag
          if (name && type) this.addType({key: name, name: name, search: search, type: type})
        }
      },50)
    }

    if (searchInsideQuery && searchInsideQuery.length > 0) {
      if (searchInsideQuery === 'false') {
        if (!this.state.advancedSearchEnabled) this._isMounted && this.setState({advancedSearchEnabled: true})
        setTimeout(()=>{
          this._isMounted && this.setState({
            searchOptionsFolders: 'filesByTextInThisFolder',
          })
        },50)
      }
      else if (searchInsideQuery === 'true') {
        setTimeout(()=>{
          this._isMounted && this.setState({
            searchOptionsFolders: 'filesByTextInclSubfolders',
          })
        },50)
      }
    }
   
    if (parentQuery && parentQuery.length > 0) {
      if (parentQuery.includes('Repo-')) {
        let repo = this.state.repoData[0];
        this.addFolder({key: repo.id, name: repo.name, search: repo.id, type: 'filterFolder'}, false)
      }
      else {
        if (!this.state.advancedSearchEnabled) this._isMounted && this.setState({advancedSearchEnabled: true})
        setTimeout(()=>{
          userService.getFolderDetails(parentQuery).then((response)=>{
            let item = response.data
            item.key = item.id
            this.addFolder(item)
          })   
        },50)
      }
    }

    if ((textQuery && textQuery.length > 0)
      || (tagsQuery && tagsQuery.length > 0)
      || (nameQuery && nameQuery.length > 0)
      || (parentQuery && parentQuery.length > 0)
      || (idQuery && idQuery.length > 0)
      || (docTypeQuery && docTypeQuery.length > 0)) {

      var smartFolderName = (textQuery ? textQuery : '') + " " /*+ (tagsQuery ? tagsQuery : '') + " "*/ + (docTypeQuery ? docTypeQuery : '') + " " + (idQuery ? idQuery : '');
      if(textQuery !== nameQuery) {
        smartFolderName = (nameQuery ? nameQuery : '') + " " + (textQuery ? textQuery : '') + " " /*+ (tagsQuery ? tagsQuery : '') + " "*/ + (docTypeQuery ? docTypeQuery : '') + " " + (idQuery ? idQuery : '');
      }
      if(smartFolderName.startsWith(" ")) smartFolderName = smartFolderName.replace(/^\s+/g, '');
      if(smartFolderName.endsWith(" ")) smartFolderName = smartFolderName.replace(/\s*$/,"");

      this._isMounted && this.setState({
        syncStatus: {
          message: "Syncing",
          type: "info",
          icon: "spinner"
        },
        searchInit: true,
        newSmartFolder: smartFolderName
      });
      this._isMounted && this.state.items.length > 0 && this.setState({
        isLoadingMoreResults: true
      })
      
      var newSearchQuery = searchQuery
      if(parentQuery === "") newSearchQuery = newSearchQuery + this.state.repoData[0].id

      newSearchQuery = newSearchQuery + "&page=" + paginationResultFrom

      userService.search(newSearchQuery)
      .then((response:any) => {

        function getReadableFileSizeString(fileSizeInBytes) {
          var i = -1;
          var byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
          do {
              fileSizeInBytes = fileSizeInBytes / 1024;
              i++;
          } while (fileSizeInBytes > 1024);

          return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
        };

        var documents = response && response.data && response.data.documents;
        var folders = response && response.data && response.data.folders;

        if (documents || folders) {
          documents = documents && documents.filter(doc => {
            return !doc.trash
          })
          var searchResults: any = [];
          //this._isMounted && this.setState({items: []});

          if (this._isMounted && folders && folders.length) {
            var userGroups = this.props.userData.groups;

            folders = folders.filter(folder => {
              return folder.path_id
            })
            
            for (let i = 0; i < folders.length; i++) {
              let folder = folders[i];
              folder.kind = "dir"
              const fileType = this._getFileType(folder, "");
              let dateCreated = new Date(folder.created_at).toString();
              let dateDeleted = folder.deleted_at && folder.deleted_at !== 0 ? new Date(folder.deleted_at).toString(): null;
              let dateModified = new Date(folder.modified_at).toString()

              let fullPath = folder.pathNames;
              if(!fullPath) {
                let pathInfo = this.getPathInfo(folder.parent_id)
                fullPath = pathInfo && pathInfo.map(item => {
                  return item.text
                })
                fullPath = fullPath && fullPath.join("/")
                fullPath = "/" + fullPath
              }
              folder.path = fullPath;

              let bookmark: any = folder.bookmarks && folder.bookmarks.filter(bookmarkData => {
                return bookmarkData.user_id === this.props.userData.user.id
              })[0] !== undefined;

              var accessGranted: boolean = false;

              var isUser = folder.users.filter(user => {
                return user.id === this.props.userData.user.id
              })

              var isAdmin = folder.admins.filter(admin => {
                return admin === this.props.userData.user.id
              })

              var groupAccess = folder.groups.filter(group => {
                return userGroups.includes(group.id)
              })

              var isExternal = folder.externals.filter(externalUser => {
                return externalUser === this.props.userData.user.id
              })

              if(isAdmin.length > 0
                || isUser.length > 0
                || groupAccess.length > 0
                || isExternal.length > 0)
                accessGranted = true

              searchResults.push({
                key: folder.id,
                id: folder.id,
                accessGranted: accessGranted,
                name: folder.name,
                fileName: folder.name,
                repo: folder.repo,
                parentId: folder.parent_id,
                path: folder.path,
                pathIds: folder.pathIds,
                pathNames: folder.pathNames,
                isFolderInPath: folder.isFolderInPath,
                icon: fileType.icon,
                fileType: fileType.docType,
                fileExtension: "folder",
                createdBy: folder.created_by,
                modifiedBy: folder.modified_by,
                dateCreated: new Date(dateCreated),
                dateDeleted: dateDeleted ? new Date(dateDeleted) : null,
                dateModified: new Date(dateModified),
                dateModifiedValue: new Date(dateModified),
                fileSize: 0,
                fileSizeRaw: 0,
                bookmark: bookmark,
                highlights: [],
                scoring: 100,
                admins: folder.admins,
                adminsData: [],
                users: folder.users,
                usersData: [],
                externals: folder.externals,
                externalsData: [],
                groups: folder.groups,
                revision: "HEAD",
                kind: folder.kind,
                tags: folder.tags
              });
            }
          }

          if (this._isMounted && documents && documents.length) {
            for (let i = 0; i < documents.length; i++) {
              let doc = documents[i];
              var fileExtension = doc.type;
              const fileType = this._getFileType(doc, fileExtension);
              let dateCreated= new Date(doc.created_at).toString();
              let dateModified = new Date(doc.modified_at).toString();

              let fullPath = doc.pathNames;
              if(!fullPath) {
                let pathInfo = this.getPathInfo(doc.parent_id)
                fullPath = pathInfo && pathInfo.map(item => {
                  return item.text
                })
                fullPath = fullPath && fullPath.join("/")
                fullPath = "/" + fullPath
              }
              
              var fileSize = "";
              if (doc.size) {
                fileSize = getReadableFileSizeString(doc.size)
              }

              var stored: string;
              var checkStored = this.state.storedFiles.filter(storedFile => {
                return storedFile.id === doc.id
              })
              stored = checkStored.length > 0 ? "same" : "no"

              if(!doc.highlights) {
                doc.highlights = [];
              }

              var tags = doc.tags;

              if (tags) {
                tags.sort((a, b) => {
                    if(a.toLowerCase() < b.toLowerCase()) { return -1; }
                    if(a.toLowerCase() > b.toLowerCase()) { return 1; }
                    return 0;
                })
              } else {
                tags = [];
              }

              doc.revisions.map(revision => {
                return revision.version = revision.major + "." + revision.minor
              })

              var currentVersion = (doc.draft ? (i18n.t('app:draftPost')  + "-") : "") + doc.activeMajor + "." + doc.activeMinor 

              var revisions = doc.revisions.filter(revision => {
                return !revision.deleted
              });

              revisions.sort((a, b) => {
                  if(a.timestamp > b.timestamp) { return -1; }
                  if(a.timestamp < b.timestamp) { return 1; }
                  return 0;
              })

              for (let i = 0; i < revisions.length; i++) {
                var version = revisions[i];
                version.isActiveRevision = version.fileId === doc.activeRevision;
              }

              if (doc.draft) {
                revisions.unshift({
                  author: doc.modified_by,
                  fileId: doc.id + "-draft",
                  message: "Draft",
                  previousVersion: null,
                  size: doc.size,
                  timestamp: doc.modified_at,
                  version: "draft"
                })
              }

              let bookmark: any = doc.bookmarks && doc.bookmarks.filter(bookmarkData => {
                return bookmarkData.user_id === this.props.userData.user.id
              })[0] !== undefined;

              searchResults.push({
                key: doc.id,
                id: doc.id,
                hash: doc.hash,
                fileName: doc.name,
                name: doc.title,
                fileExtension: doc.type,
                repo: doc.repo,
                path: fullPath,
                pathIds: doc.pathIds,
                pathNames: doc.pathNames,
                parent_id: doc.parent_id,
                parentId: doc.parent_id,
                icon: fileType.icon,
                fileType: fileType.docType,
                createdBy: doc.created_by,
                modifiedBy: doc.modified_by,
                dateCreated: new Date(dateCreated),
                dateModified: new Date(dateModified),
                dateModifiedValue: new Date(dateModified),
                fileSize: fileSize,
                fileSizeRaw: doc.size,
                tags: tags,
                bookmark: bookmark,
                revisions: revisions,
                activeMajor: doc.activeMajor,
                activeMinor: doc.activeMinor,
                highlights: doc.highlights,
                scoring: doc.scoring,
                activeRevisionId:  doc.activeRevision,
                currentVersion: currentVersion,
                revisionsCount: doc.revisionsCount,
                draft: doc.draft,
                lock: doc.lock,
                lockInstanceId: doc.lockInstanceId,
                wopiLock: doc.wopiLock,
                thumbnailCreated: doc.thumbnailCreated,
                stored: stored,
                kind: "file",
                trash: doc.trash
              });
            };
          }

          searchResults.sort(function(a, b){
            if(a.scoring > b.scoring) { return -1; }
            if(a.scoring < b.scoring) { return 1; }
            return 0;
          })

          // const { items } = this.state;
          // const savedHeight = document.body.offsetHeight
          // this.setState({
          //   items: [...items, ...searchResults]
          // }, () => {
          //   setTimeout(()=> {
          //     window.scrollTo(0, savedHeight)
          //   }, 1000)
          // })
          var isDuplicate = (entry, arr)  => {
            return arr.some(x => (entry.PID === x.PID) && (entry.Week === x.Week))
          }

          if(this.state.startIndex === 1) {
            this.setState({
              items: searchResults
            })
          } else {
            let newArray: any = searchResults
            for (const entry of searchResults) {
              if (!isDuplicate(entry, newArray)) { newArray.push(entry) }
            }

            this.state.items.push(...newArray)

            var objDiv = document.getElementsByClassName("listEl")[0]
            if(objDiv) {
              objDiv.scrollTop = objDiv.scrollHeight;
            }
          }

          var canLoadMore = (response.data.documents && response.data.documents.length) >= paginationResultSize || (response.data.folders && response.data.folders.length) === paginationResultSize

          this._isMounted && this.setState({
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            },
            isLoading: false,
            isLoadingMoreResults: false,
            startIndex: paginationResultFrom + 1,
            canLoadMore: canLoadMore
          }, () => {
            // if (typeof(localStorage.getItem("searchColumns")) === "string") {
            //     var searchColumns: IColumn[] = JSON.parse(localStorage.getItem("searchColumns") || "[]");

            //     for (let i = 0; i < this.state.columns.length; i++) {
            //       let column = this.state.columns[i]

            //       let savedColumn: any = searchColumns.filter(savedColumn => {
            //         return column.key === savedColumn.key
            //       })[0]

            //       if(savedColumn) {
            //         column.isSorted = savedColumn.isSorted;
            //         column.isSortedDescending = savedColumn.isSortedDescending
            //       }

            //       if(column.isSorted) {
            //         this._sortBySavedColumn(column)
            //       }
            //     }
            //   }
          });
        } else {
          this._isMounted && this.setState({
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            },
            isLoading: false,
            searchInit: false,
            newSmartFolder: terms,
            selFile: null,
            items: []
          });
        }
      }).catch(error => {
        console.log(error)
        this._isMounted && this.setState({
          syncStatus: {
            message: "Synced",
            type: "success",
            icon: "SkypeCircleCheck"
          },
          isLoading: false,
          searchInit: false,
          newSmartFolder: 'terms',
          selFile: null,
          items: []
        });
      })
    } else {
      this._isMounted && this.setState({
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        },
        isLoading: false,
        searchInit: false,
        newSmartFolder: terms,
        selFile: null,
        items: []
      });
    }
  }

  private _refreshCurrentContent() {
    this._isMounted && this.setState({
      items: [],
      startIndex: 1,
      isLoading: true,
      canLoadMore: false,
      searchInit: false
    }, () => {
      this._getCurrentContent()
    })
  }

  private _getCurrentContent() {
    var searchQuery = window.location.search;
    this._isMounted && this._search(searchQuery)
  }

  private _saveSmartFolder = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { newSmartFolder, searchQuery, repoData } = this.state;
    const params = new URLSearchParams(searchQuery);
    var repo = params.get("parent") || ""

    if(!repo) {
      repo = repoData[0].id
    }

    var parentIsRepo = repoData.filter(item => {
      return repo === item.id
    })
    parentIsRepo = parentIsRepo.length > 0

    if(!parentIsRepo) {
      repo = this.getPathInfo(repo)[0].key
    }

    this._isMounted && this.setState({ isSaving: true })

    userService.saveSmartFolder(repo, newSmartFolder, searchQuery)
    .then(response => {
      this._isMounted && this.setState({
        isSaving: false,
        isSaved: true,
        showSmartFolderDialog: false
      })
    })
  }

  private _getFileType(doc, fileExtension): { docType: string; icon: string } {
    var kind = doc.kind;

    if (kind === "dir") {
      const docType: string = "dir"
      return {
        docType,
        icon: "folder"
      }
    } else {
      const docType: string = "file"
      if (fileExtension === "mhtml") {
        fileExtension = "html";
      }

      return {
        docType,
        icon: fileExtension
      }
    }
  }

  private getPathInfo(id) {
    var folders = this.props.foldersList;

    if(folders) {

      var folderId = id;
      let foldersData = folders
      let repos = this.state.repoData

      var currentFolder = foldersData.filter(folder => {
        return folder.id === folderId
      })
      currentFolder = currentFolder[0]


      var breadcrumb: any = [];

      if(currentFolder && currentFolder.path_id) {
        
        breadcrumb.push({
            text: currentFolder.name,
            key: currentFolder.id
          })

        var getParentData = (parentId) => {
          var parentData;

          parentData = foldersData.filter(data => {
            return parentId === data.id
          })

          if(!parentData.length) {
            parentData = repos.filter(data => {
              return parentId === data.id
            })
          }

          parentData = parentData[0]

          if(parentData) {
            breadcrumb.unshift({
              text: parentData.name,
              key: parentData.id
            })
          }

          if(parentData && parentData.parent_id)
            getParentData(parentData.parent_id)
        }

        getParentData(currentFolder.parent_id)
      } else {
        // Check if root folder
        let folder = repos.filter(data => {
          return data.id === folderId
        })
        folder = folder[0]

        if (folder)
          breadcrumb.unshift({
              text: folder.name,
              key: folder.id
            })
      }
    }

    return breadcrumb
  }

  private _dragOptions = {
    moveMenuItemText: i18n.t('app:move'),
    closeMenuItemText: i18n.t('app:close'),
    menu: ContextualMenu
  };

  private handleSmartFolderChange = (ev: any, newValue?: string) => {
    this._isMounted && this.setState({ newSmartFolder: newValue || ''});
  }

  private _closeDialog = (): void => {
    this._isMounted && this.setState({ showSmartFolderDialog: false });
  };

  private _getImports() {
    if(localStorage.getItem("uploadsQueue")) {
      return JSON.parse(localStorage.getItem("uploadsQueue") || "{}");
    } else {
      return [];
    }
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    var newItems;
    if (column.key === "name") {
      newItems = _copyAndSortName(items, currColumn.fieldName!, currColumn.isSortedDescending);
    } else {
      newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    }

    localStorage.setItem("searchColumns", JSON.stringify(newColumns))

    this._isMounted && this.setState({
      columns: newColumns,
      items: newItems
    });
  };

  private _sortBySavedColumn = (column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];

    var newItems;
    if (column.key === "name") {
      newItems = _copyAndSortName(items, currColumn.fieldName!, currColumn.isSortedDescending);
    } else {
      newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    }

    this._isMounted && this.setState({
      columns: newColumns,
      items: newItems
    });
  };
};

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  var key:any = columnKey as keyof T;
  if (key === "coincidences") {
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a["highlights"].length < b["highlights"].length : a["highlights"].length > b["highlights"].length) ? 1 : -1));
  } else {
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
  }
}

function _copyAndSortName<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  return items.slice(0).sort((a,b) => {
    if (!isSortedDescending) {
      if(a["fileType"] < b["fileType"])
          return -1;
      if(b["fileType"] < a["fileType"])
          return 1;

      return a["name"].localeCompare(b["name"]);
    } else {
      if(a["fileType"] > b["fileType"])
          return -1;
      if(b["fileType"] > a["fileType"])
          return 1;

      return b["name"].localeCompare(a["name"]);
    }

  });
}