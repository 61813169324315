import * as React from 'react';
import Moment from 'moment';
import i18n from "i18next";
import { userService } from '../_services';

export interface Props {
}

export interface SidebarState {
}

export class ActivityDetails extends React.Component<Props, SidebarState> {
  props: any;

  public render() {
    const { item } = this.props;

    var model_objects: any = item.model_objects
    var old_2nd_model_objects: any = item.old_2nd_model_objects
    
    var object = model_objects && model_objects[0]
    var secondaryObject = old_2nd_model_objects && old_2nd_model_objects[0]

    var activityDetails: string = ""

    if(item.type === "COMPANY") {
      if(item.operation === "ADD_INTERNAL" && object) {
        activityDetails = "email: " + object.email
        return(
          <span title={ activityDetails }>
            email: <a href={"mailto:" + object.email }>{ object.email }</a>
          </span>
        )
      }
    }

    if(item.type === "GROUP") {
      if((item.operation === "CREATE" || item.operation === "UPDATE")
        && secondaryObject) {
        activityDetails = i18n.t('app:name')+': "' + secondaryObject.name + '"'
      }
    }

    if(item.operation === "SEARCH" && item.message) {
      var message = item.message.substring(1, item.message.length-1);
      message = message.split(", ")
      message = message.filter(term => {return !term.startsWith("parentId")})
      message = message.join(", ")
      activityDetails = message
    }

    if(model_objects && model_objects.length === 1) {
      if ((item.type === "FILE" && item.operation === "OPEN")
        || (item.type === "FILE" && item.operation === "OPENREADONLY")
        || (item.type === "FILE" && item.operation === "EDIT_OFFICE_ONLINE")
        || (item.type === "FILE" && item.operation === "VIEW_OFFICE_ONLINE")
        || (item.type === "FILE" && item.operation === "PREVIEW_OFFICE_ONLINE")
        || (item.type === "FILE" && item.operation === "PREVIEW")
        || (item.type === "FILE" && item.operation === "READER")
        || (item.type === "FILE" && item.operation === "REFERENCE")
        || (item.type === "FILE" && item.operation === "DOWNLOAD")
        || (item.type === "FILE" && item.operation === "ATTACH")
        || (item.type === "FILE" && item.operation === "OFFLINE")
        || (item.type === "FILE" && item.operation === "SAVEASPDF")
        || (item.type === "DOCUMENT" && item.operation === "TRASH")
        || (item.type === "DOCUMENT" && item.operation === "RESTORE")
        || (item.type === "DOCUMENT" && item.operation === "FORCE_UNLOCK")
        || item.type === "REVISION"
        || (item.operation && item.operation.startsWith("DRAFT"))) {
          activityDetails = 'v' + object.version
      }

    }

    if(item.type === "FILE" && item.operation === "COMPARE") {
      var file1 = model_objects[0]
      var file2 = model_objects[1]
      if(file1 && file2 && file1.id === file2.id) {
        activityDetails = file1.name + ' ' + file1.version + ' vs. ' + file2.version
      } else {
        activityDetails = file1.name + ' ' + file1.version + ' vs. ' + file2.name + ' ' + file2.version
      }
    }

    if(object && secondaryObject && 
      (item.operation === "MOVE"
      || item.operation === "COPY_PASTE"
      || item.operation === "INSERT_TEMPLATE")) {
      activityDetails = i18n.t('app:from')+' "' + (secondaryObject.name || secondaryObject.op_related_names) + '" '+i18n.t('app:to')+' "' + object.parentName + '"'
      return(
        <span title={ activityDetails }>
          {i18n.t('app:from')} "<a href={process.env.REACT_APP_DRIVE_PROTOCOL + "://folder?" + secondaryObject.id}>{ secondaryObject.name || secondaryObject.op_related_names }</a>" {i18n.t('app:to')} "<a href={process.env.REACT_APP_DRIVE_PROTOCOL + "://folder?" + object.parentID}>{ object.parentName }</a>"
        </span>
      )
    }

    if((item.type === "DOCUMENT" || item.type === "FILE" || item.type === "FOLDER") && item.operation === "RENAME") {
      activityDetails = i18n.t('app:previousName')+' "' + secondaryObject.name + '"'
    }

    if(item.type === "DRAGNDROP" && item.operation === "CREATE_BULK") {
      if(object) {
        activityDetails = i18n.t('app:parentFolder')+' "' + object.parentName + '"'
        return(
          <span title={ activityDetails }>
            {i18n.t('app:parentFolder')}: "<a href={process.env.REACT_APP_DRIVE_PROTOCOL + "://folder?" + object.parentId}>{ object.parentName }</a>"
          </span>
        )
      } else if (secondaryObject) {
        activityDetails = 'Parent folder "' + secondaryObject.parentName + '"'
        return(
          <span title={ activityDetails }>
            {i18n.t('app:name')}: "<a href={process.env.REACT_APP_DRIVE_PROTOCOL + "://folder?" + secondaryObject.parentId}>{ secondaryObject.parentName }</a>"
          </span>
        )
      }
    }

    if(item.type === "REVISION") {
      if (item.operation === "CREATE") activityDetails = 'v' + object.version + ' "' + item.message + '"' 
      else if(item.operation === "RENAME") activityDetails = 'v' + object.version + ' "' + object.op_related_names + '"'
      else if(item.operation === "OUTLOOK_APPEND") activityDetails = 'v' + object.version + ' "' + item.message + '"'
      else if(item.operation === "APPEND") activityDetails = 'v' + object.version + ' "' + item.message + '"'
    }

    if(item.type === "TAG") {
      if(item.operation === "UPDATE") {
        activityDetails = i18n.t('app:previousName')+': "' + secondaryObject.name + '"'
      } else {
        activityDetails = '"' + object.op_related_names + '"'
      }
    }

    if(item.type === "COMMENT") {
      activityDetails = '"' + object.op_related_names + '"'
    }

    if(item.type === "ANNOTATION") {
      if (item.operation === "CREATE") activityDetails = '"' + secondaryObject.name + (secondaryObject.op_related_names ? '" '+i18n.t('app:withComment')+' "' + secondaryObject.op_related_names + '"' : '"')
      if (item.operation === "UPDATE") activityDetails = i18n.t('app:newComment')+': "' + secondaryObject.op_related_names + '"'
      if (item.operation === "DELETE") activityDetails = '"' + secondaryObject.name + '"'
    }

    // References
    if(item.type === "REFERENCE") activityDetails = '"' + secondaryObject.op_related_names + '"'

    if(item.type === "SHARE_FOLDER_SINGLE") {
      if(item.operation === "SHARE_INTERNAL") activityDetails = "Internal permissions added."
      if(item.operation === "REMOVE_SHARE_INTERNAL") activityDetails = "Internal permissions removed."
      if(item.operation === "SHARE_EXTERNAL") activityDetails = "External permissions added."
      if(item.operation === "REMOVE_SHARE_EXTERNAL") activityDetails = "External permissions removed."
      if(item.operation === "SHARE_GROUP") activityDetails = "Group permissions added."
      if(item.operation === "REMOVE_SHARE_GROUP") activityDetails = "Group permissions removed."
    }

    if(item.type === "SHARE_FOLDER_CASCADE") {
      if(item.operation === "SHARE_INTERNAL") activityDetails = "Internal permissions applied to all subfolders."
      if(item.operation === "REMOVE_SHARE_INTERNAL") activityDetails = "Internal permissions removed."
      if(item.operation === "SHARE_EXTERNAL") activityDetails = "External permissions applied to all subfolders."
      if(item.operation === "REMOVE_SHARE_EXTERNAL") activityDetails = "External permissions removed."
      if(item.operation === "SHARE_GROUP") activityDetails = "Group permissions applied to all subfolders."
      if(item.operation === "REMOVE_SHARE_GROUP") activityDetails = "Group permissions removed."
    }

    if(item.type === "CONTENT") {
      if(item.operation === "CREATE_SHARE_BY_LINK") {
        activityDetails = i18n.t('app:viewPublicLink')
        return(
          <span title={ activityDetails }>
            <a href={"#"/*userService.getPublicLinkUrl(item.message)*/} target="_blank" rel="noopener noreferrer">{i18n.t('app:view')}</a>
          </span>
        )
      }
      if(item.operation === "DELETE_SHARE_BY_LINK") {
        return (
          <span>{i18n.t('app:dateCreated')}: { Moment(parseInt(object.op_related_names)).format(i18n.t('app:dateFormat')) }</span>
        )
      }
    }

    return(
        <span title={ activityDetails }>{ activityDetails }</span>
    )
  }
}