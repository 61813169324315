import * as React from 'react';
import UAParser from 'ua-parser-js';
import Moment from 'moment';
import "moment/min/locales";
import { Fabric, IBreadcrumbItem, DetailsListLayoutMode, Selection, SelectionMode, IColumn, ConstrainMode, Icon, Spinner, SpinnerSize, IconButton, IIconProps, Breadcrumb, TextField, PrimaryButton, ScrollablePane, ShimmeredDetailsList, ScrollbarVisibility, IRenderFunction, IDetailsHeaderProps, Sticky, StickyPositionType, IDetailsList, CommandBar, ICommandBarItemProps, SearchBox, INavLink, IDropdownOption, Dropdown } from '@fluentui/react';
import { getFileTypeIconProps, FileIconType } from '@fluentui/react-file-type-icons';
import { userService } from '../_services';
import i18n from "i18next";
import Tree from 'react-virtualized-tree';
import {createSelector} from 'reselect';

declare global {
  interface Window {
      require: any;
      sreader: any;
  }
}

export interface Props {
  match: {
    url: string
  },
  location: any,
  database: any,
  isOnline: boolean,
  maintenance: boolean,
  fileExplorerType: string,
  userData: any,
  foldersList: any,
  newFolder: any,
  repoUsers: any,
  appPlatform: any,
  repoData: any,
  callback: any,
  startFolder: any
}

export interface IDetailsListFilePickerState {
  userData?: any;
  repoData?: any;
  goBack: number;
  isLoading: boolean;
  savingTag: boolean;
  savingComment: boolean;
  syncStatus: any;
  downloadingFiles: any;
  creatingFoldersStructure: boolean;
  acceptedFiles: any;
  acceptedFolders: any;
  currentPath: string;
  breadcrumbPath: any[];
  columns: IColumn[];
  versionsColumns: IColumn[];
  items: IDocument[];
  fullItemsResponse: any;
  filterByNameText: string;
  fullItemsResponseByName: any;
  filterByTypeText: string;
  fullItemsResponseByType: any;
  storedFiles: any;
  draggedItem: any;
  selFile: any;
  showRightPanel: boolean;
  selectionDetails: string;
  isModalSelection: boolean;
  isCompactMode: boolean;
  newTag: string;
  newComment: string;
  newFolder: string;
  savingFolder: boolean;
  showTagBulkDialog: boolean;
  hideDialog: boolean;
  activeTab: string;
  language: string;
  filterToggle: boolean;
  url: string;
  folderTree: any;
  fileExplorerType: string;
  panelWidth: any;
  documentName: string;
  foldersList: any;
  folders: any;
  history: any;
  historyIndex: number;
  nodesTree: any[];
  folderTreeFocusId: string;
  searchWord: string;
  loadMoreFolders: boolean;
  loadingMoreFolders: boolean;
  filter: string;
  folderFilter: string;
  searching: boolean;
  folderId: string;
  currentFilter: string;
  searchIndex: number;
  filteredFolders: any;
  loadingFolders: boolean;
  searchInfolder: string;
  currentType: string;
  searchOptions: string;
}

export interface IDocument {
  key: string;
  fileName: string;
  name: string;
  fileExtension: string;
  accessGranted: boolean;
  value: string;
  icon: string;
  fileType: string;
  path: string;
  isFolderInPath: boolean;
  lastOpened: string;
  createdBy: any;
  modifiedBy: any;
  dateCreated: string;
  dateDeleted: string;
  dateModified: string;
  dateModifiedValue: number;
  dateRaw: any,
  fileSize: string;
  fileSizeRaw: number;
  author: string;
  date: number;
  revisionsCount: number;
  currentVersion: string;
  draft: boolean;
  savingDraft: boolean;
  lock: any;
  lockInstanceId: string;
  wopiLock: any;
  lockingDocument: boolean;
  msg: string;
  hash: string;
  stored: string;
}

export const getNodeRenderOptions = createSelector(
  (node: any) => (node.state || {}).expanded,
  node => (node.state || {}).favorite,
  node => (node.state || {}).deletable,
  node => node.hasSubFolders,
  node => node.id,
  node => node.type,
  node => node.hidden,
  (expanded, favorite, deletable, hasSubFolders, id, type, hidden = []) => ({
    hasChildren: !!hasSubFolders,
    isExpanded: !!expanded,
    isFavorite: !!favorite,
    isDeletable: !!deletable,
    id: id,
    type: type,
    hidden: !!hidden
  }),
);

export const updateNode = (originalNode, newState: any) =>
  ({
  
  node: {
    ...originalNode,
    state: {
      ...originalNode.state,
      ...newState,
    },
  },
  type: "2" //UPDATE
});

function getReadableFileSizeString(fileSizeInBytes) {
  var i = -1;
  var byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
      fileSizeInBytes = fileSizeInBytes / 1024;
      i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};

export class FilePicker extends React.Component<Props, IDetailsListFilePickerState> {
  private _isMounted: boolean;
  private _selection: Selection;
  private _root:any = React.createRef<IDetailsList>();  
  private headerRef:any = React.createRef();
  private fileExplorerRef:any = React.createRef();
  private filterNameRef:any = React.createRef();
  private filteredNested:any;

  constructor(props: any) {
    super(props);
    this._root = React.createRef<IDetailsList>(); 

    this._isMounted = false;
    var panelWidth = "240px"

    const versionsColumns: IColumn[] = [
      {
        key: 'date',
        name: i18n.t('app:versionsByDate'),
        fieldName: 'dateModifiedValue',
        isSorted: true,
        minWidth: 120,
        isResizable: false,
        isMultiline: true,
        onColumnClick: this._onVersionsColumnClick,
        data: 'number',
        onRender: (item: IDocument) => {
          return (
            <div>
              <p className="font-weight-bold m-0 p-0">
                { Moment(item.date).format(i18n.t('app:dateFormat')) } by <span className="author">{item.author}</span>
              </p>
              <p className="m-0 ml-2 p-0">
                { item.msg }
              </p>
            </div>
          )
        },
        isPadded: false
      }
    ];

    this._selection = new Selection({
      onSelectionChanged: () => {
        var selFile: any = this._selection.getSelection()[this._selection.getSelection().length - 1]
        if (selFile && this._selection.getSelectedCount() === 1) {
          this._isMounted && this.setState({
            selFile: selFile || [],
            documentName: selFile.fileName
          });
        }
        else {
          this._isMounted && this.setState({
            selFile: selFile || [],
            documentName: ""
          })
        }
      }
    });
    
    this.state = {
      userData: null,
      repoData: null,
      goBack: 0,
      isLoading: true,
      savingTag: false,
      savingComment: false,
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      downloadingFiles: [],
      creatingFoldersStructure: false,
      acceptedFiles: [],
      acceptedFolders: [],
      currentPath: "/files/" + (this.props.startFolder || this.props.repoData[0].id),
      breadcrumbPath: [],
      items: [],
      fullItemsResponse: [],
      filterByNameText: "",
      fullItemsResponseByName: [],
      filterByTypeText: "",
      fullItemsResponseByType: [],
      storedFiles: [],
      draggedItem: null,
      columns: [],
      versionsColumns: versionsColumns,
      selFile: null,
      showRightPanel: true,
      selectionDetails: '',
      isModalSelection: false,
      isCompactMode: true,
      newTag: '',
      newComment: '',
      newFolder: '',
      savingFolder: false,
      showTagBulkDialog: false,
      hideDialog: true,
      activeTab: 'details',
      language: i18n.language,
      filterToggle: true,
      url: this.props.startFolder || this.props.repoData[0].id,
      folderTree: [],
      fileExplorerType: this.props.fileExplorerType,
      panelWidth: panelWidth,
      documentName: "",
      foldersList: this.props.foldersList,
      folders: [],
      history: [this.props.startFolder || this.props.repoData[0].id],
      historyIndex: 0,
      nodesTree: [],
      folderTreeFocusId: "",
      searchWord: "",
      loadMoreFolders: false,
      loadingMoreFolders: false,
      filter: "",
      folderFilter: "",
      searching: false,
      folderId: "",
      currentFilter: "",
      searchIndex: 1,
      filteredFolders: null,
      loadingFolders: false,
      searchInfolder: "",
      currentType: "",
      searchOptions: "searchFolders"
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.props.userData && this._getRepoData();
    this._isMounted && this._getColumns();
    this._isMounted && this._getInitialBreadcrumb();
    this._isMounted && this._getFolderTree();
    setTimeout(()=> {this._isMounted && this._getBreadcrumbItems()},200);

    document.addEventListener("keydown", this._handleKeyDown);
    var listEl:any = document.getElementsByClassName("listEl-picker")[0]
    if(listEl) listEl.addEventListener("keydown", this._handleListKeyDown);

    var explorerEl = document.getElementsByClassName("explorerEl-picker")[0]
    if(explorerEl) explorerEl.addEventListener("click", this._handleClickOutside.bind(this));
    if (!this.props.startFolder.startsWith("Repo-")) userService.getFolderDetails(this.props.startFolder).then((response)=>{
      this._findInFolderTree(response.data)
    })
  }

  public componentDidUpdate(prevProps: any, prevState: IDetailsListFilePickerState) {
    if(this.state.language !== i18n.language) {
      this.setState({language: i18n.language});
      this._getCurrentContent();
      this._getColumns();
    }

    if (this.state.searching !== prevState.searching && !this.state.searching) {
      this._isMounted && this.setState({ selFile: null });
      this._getCurrentContent();
      this._getBreadcrumbItems();
      this._isMounted && this.setState({
        isLoading: true,
        items: []
      })
    }

    if (this.state.searching !== prevState.searching ) {
      this._getColumns();
    }

    if (document && document.activeElement && (document.activeElement.tagName === "BODY" || document.activeElement.tagName === "BUTTON") && !this.state.searching) {
      this._setFocusFirstItem();
    }

    if (prevState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
      this._selection.setAllSelected(false);
    }

    if(this.props.userData !== prevProps.userData) {
      this.props.userData && this._getRepoData();
    }

    if (prevState.url !== this.state.url) {
      this._isMounted && this.setState({ selFile: null, fullItemsResponse: [], items: [], isLoading: true, folderTreeFocusId: "" });
      this._getCurrentContent();
      this._getColumns();
    }

    if (this.state.foldersList !== prevState.foldersList) {
      if(!prevState.foldersList && this.state.fileExplorerType !== "openFiles") {
        this._getCurrentContent()
        this._getFolderTree();
      }
    }

    if (this.state.fileExplorerType !== prevState.fileExplorerType) {
      this._isMounted && this.setState({ isLoading: true });
    }

    if((this.state.url !== prevState.url) && this.state.fileExplorerType === "fileExplorer") {
      this._getBreadcrumbItems();
    }

    if(this.state.folders !== prevState.folders) {
      this._expandFolderWhileBrowsing(this.state.url, this.state.folders)
    }

    if (this.state.foldersList !== prevState.foldersList && !prevState.foldersList) {
      userService.getFolderDetails(this.props.startFolder).then((response)=>{
        this._findInFolderTree(response.data)
      })
    }

    // Custom titlebar content height 100vh fix
    var titlebar : HTMLDivElement | null  = document.querySelector('.titlebar');
    if(titlebar instanceof HTMLDivElement) {
      var titlebarHeight = titlebar.offsetHeight;
      var toolbar : HTMLDivElement | null  = document.querySelector('.toolbar');
      if(toolbar instanceof HTMLDivElement) {
        var toolbarHeight = toolbar.offsetHeight;

        var filtersBox : HTMLDivElement | null  = document.querySelector('.filtersBox');
        var listTitlebarHeightFix : HTMLDivElement | null = document.querySelector('.list');
        if(filtersBox instanceof HTMLDivElement) {
          var filtersBoxHeight = filtersBox.offsetHeight;

          if(listTitlebarHeightFix instanceof HTMLDivElement) {
            listTitlebarHeightFix.style.height = 'calc(100vh - ' + toolbarHeight + 'px - '  + filtersBoxHeight + 'px - ' + titlebarHeight + 'px)';
          }
        } else {
          if(listTitlebarHeightFix instanceof HTMLDivElement) {
            listTitlebarHeightFix.style.height = 'calc(100vh - ' + toolbarHeight + 'px - '  + titlebarHeight + 'px)';
          }
        }
      }
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("keydown", this._handleKeyDown, false);
    var listEl = document.getElementsByClassName("listEl-picker")[0]
    if(listEl) listEl.removeEventListener("keydown", this._handleListKeyDown, false);
    var explorerEl = document.getElementsByClassName("explorerEl-picker")[0]
    if(explorerEl) explorerEl.removeEventListener("click", this._handleClickOutside.bind(this), false);
  }

  private _getColumnSize(column) {
    var dashboardColumnsWidth = JSON.parse(localStorage.getItem("dashboardColumnsWidth") || "[]") || []
    var columnSetting = dashboardColumnsWidth.filter(item => {
      return item.key === column
    })
    columnSetting = columnSetting[0]
    if(columnSetting) return columnSetting.width
    return null;
  }

  private updateItem = (id, name, value) => {
    var items:any = this.state.items
    
    if (items && items.length) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === id) {
          this._isMounted && this.setState(prevState => {
            const items = [...prevState.items];
            items[i] = { ...items[i], [name]: value};
            return { items };
          });   
        }
      }
    }
  }

  callbackFunction = (childData) => {
      this._isMounted && this.setState(childData)
  }

  private _handleListKeyDown = (event) => {
    if (event.repeat) { return }
    if(event.keyCode === 8 && this.state.historyIndex !== 0) {
      this._goBack()
    }
    else if(!event.metaKey
      && !event.ctrlKey
      && !event.altKey
      && !event.shiftKey
      && event.returnValue ) {
        var key = event.key || "";
        this._handleListFocusOnKeyDown(key)
    }
  }

  private _pressedKey: any = undefined;
  private _focusItems: any = null;
  private _focusItemIndex: number = 0;

  private _handleListFocusOnKeyDown(key) {
    let currentWord:string;
    if(key.length === 1) {
      if (this._pressedKey === key && this._focusItems && this._focusItems.length > 0 && this.state.searchWord.length === 0) {
        currentWord = this.state.searchWord + key;
        this._isMounted && this.setState({searchWord: this.state.searchWord + key}, ()=> {
          this._focusItemIndex = this._focusItemIndex + 1
          var focusItem = this._focusItems[this._focusItemIndex]
          if(focusItem) {
            this.setListFocus(focusItem)
          } else {
            this._focusItemIndex = 0
            focusItem = this._focusItems[this._focusItemIndex]
            if(focusItem !== this._selection.getSelection()[0]) this.setListFocus(focusItem)
          }
        })
      } else {
        if (this.state.searchWord.length === 0) this._pressedKey = key;
        currentWord = this.state.searchWord + key;
        this._isMounted && this.setState({searchWord: this.state.searchWord + key}, ()=> {
          if (this.state.searchWord.length === 1)  {
            this._focusItems = this.state.items && this.state.items.filter(item => {
              return item.name.toLowerCase().startsWith(this.state.searchWord.toLowerCase())
            })
          }
          this._focusItemIndex = 0
    
          let focusItem = this.state.items && this.state.items.filter(item => {
            return item.name.toLowerCase().startsWith(this.state.searchWord.toLowerCase())
          })[this._focusItemIndex]
          if(focusItem !== this._selection.getSelection()[0]) this.setListFocus(focusItem)
        })
      }
      setTimeout(()=>{
        if (this.state.searchWord === currentWord) {
          this._isMounted && this.setState({searchWord: ''})
        }
      },1000)
    }
    else {
      this._isMounted && this.setState({searchWord: ''})
    }
  }

  private setListFocus(focusItem) {
    //Reselect multiple times to correctly select item in long lists
    if(focusItem) {
      setTimeout(() => {
        this._selection.setAllSelected(false)
        this._selection.setKeySelected(focusItem.key, true, true)  
        var selIndex = this._selection.getSelectedIndices()[this._selection.getSelectedIndices().length-1]
        this._selection.setIndexSelected(selIndex, true, true);
        this._root.current && this._root.current.focusIndex(selIndex);
        setTimeout(() => {
          var selIndex = this._selection.getSelectedIndices()[this._selection.getSelectedIndices().length-1]
          this._selection.setIndexSelected(selIndex, true, true);
          this._root.current && this._root.current.focusIndex(selIndex);
        },200)
      },1)
    }
  }

  private _setFocusFirstItem() {
    if(this._selection.count === 0)
    this._root.current && this._root.current.focusIndex(0)
  }

  private setListFocusFirst() {
    if(this.state.items[0]) {
      this._selection.setAllSelected(false)
      setTimeout(() => {
        if(this.state.items[0]) {
          this._selection.setKeySelected(this.state.items[0].key, true, true)
          var selIndex = this._selection.getSelectedIndices()[0]
          this._root.current && this._root.current.focusIndex(selIndex);
        }
      }, 200)
    }
  }

  private _handleClickOutside(event) {
    if (event.repeat) { return }
    if (document && document.activeElement && document.activeElement.tagName === "BODY" && event.target.tagName !== "SPAN") {
      this._setFocusFirstItem();
    }
  }

  private _onChangeNameFilter: any = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
    var fullItemsResponse = this.state.fullItemsResponse
    this.setState({filter: text || "", items: text ? fullItemsResponse.filter(i => i.name.toLowerCase().indexOf(text.toLowerCase()) > -1) : fullItemsResponse})
  };

  private _handleKeyDown = (event) => {
    var parser = new UAParser.UAParser();
    var os = parser.getResult().os;
    if ((os.name === "Windows" && event.ctrlKey && event.keyCode === 70)
      || (os.name === "Mac OS" && event.metaKey && event.keyCode === 70)) {
      event.preventDefault();
      this.filterNameRef.focus();
    }
}

  private _updateNewFolder(folder: string) {
    this.setState({newFolder: folder})
  }

  private _goBack() {
    var { history, historyIndex } = this.state;
    historyIndex -= 1;
    this._isMounted && this.setState({
      historyIndex: historyIndex,
      url: history[historyIndex],
      searching:false,
      loadMoreFolders: false,
      folderFilter: "",
      filter: ""
    })
  }

  private _goForward() {
    var { history, historyIndex } = this.state;
    historyIndex += 1;
    this._isMounted && this.setState({
      historyIndex: historyIndex,
      url: history[historyIndex],
      searching:false,
      loadMoreFolders: false,
      folderFilter: "",
      filter: ""
    })
  }

  private _onRenderGroupHeader = (group: any): JSX.Element | null => {
    return(
      <p className="nav-repo-group m-0">{ group.name }</p>
    );
  }

  private handleSearchTypeChange = (ev: any, newValue?: IDropdownOption) => {
    if(newValue && newValue.key && typeof newValue.key === "string") {
      this.setState({
        searchOptions: newValue.key
      });
    }
  }

  public render() {
    const { syncStatus, breadcrumbPath } = this.state;

    const backIcon: IIconProps = { iconName: 'Back' };
    const forwardIcon: IIconProps = { iconName: 'Forward' };
    const refreshIcon: IIconProps = { iconName: 'Refresh' };

    const selectedCount = this._selection && this._selection.getSelectedCount();

    if (this.state.searching || this.state.url.startsWith('recents-') || this.state.url.startsWith('bookmarks-')) {
      var selFilePathIds = this.state.selFile && this.state.selFile.pathIds && this.state.selFile.pathIds.split("/").slice(1)
      var selFilePathNames = this.state.selFile && this.state.selFile.pathNames && this.state.selFile.pathNames.split("/").slice(1)
      var itemBreadcrumb = selFilePathIds && selFilePathNames && selFilePathIds.map((e, i) => {
        return {
          key: e,
          text: selFilePathNames[i],  
          onClick: () => {
            var {history, historyIndex} = this.state;
            history = history.slice(0,historyIndex+1);
            history.push(e)
            historyIndex += 1;
            this._isMounted && this.setState({url: e, history: history, historyIndex: historyIndex, searching:false,loadMoreFolders:false, folderFilter: "", filter: ""})
          }
        }
      });

      if (!itemBreadcrumb && this.state.selFile && this.state.selFile.path) {
        itemBreadcrumb = [{key: this.state.selFile.parentId,
          text: this.state.selFile.path.replace('/',''),
          onClick: () => {
            var {history, historyIndex} = this.state;
            history = history.slice(0,historyIndex+1);
            history.push(this.state.selFile.parentId)
            historyIndex += 1;
            this._isMounted && this.setState({url: this.state.selFile.parentId, history: history, historyIndex: historyIndex, searching:false, loadMoreFolders:false, folderFilter: "", filter: ""})
          }
        }]
      }
    }

    var emptyMessage = ""

    if (this.state.fileExplorerType === "fileExplorer") {
      if(this.state.url.indexOf("-trash") !== -1) {
        emptyMessage = i18n.t('app:trashIsEmpty')
      } else {
        emptyMessage= this.state.searching ? i18n.t('app:noItemsSearch') : i18n.t('app:empty')
      }
    } else if (this.state.url.includes('bookmarks-') && !this.state.searching) {
      emptyMessage = "No quick access files"
    } else if (this.state.url.includes('recents-') && !this.state.searching) {
      emptyMessage = i18n.t('app:noRecentFiles')
    }

    const farItems: ICommandBarItemProps[] = [
      {
        key: 'filter',
        title: i18n.t('app:toggleRightPanel'), 
        iconProps: { iconName: 'SidePanelMirrored' },
        className: 'sidePanel-button',
        onRender: ()=> {
            return(
              <div className={"filtersBox content-wrap d-flex flex-row justify-content-center mx-2"}>
                <div className="filter">
                  <SearchBox disabled={this.state.searching} placeholder={i18n.t('app:filterByName')} value={this.state.filter} onChange={this._onChangeNameFilter.bind(this)} iconProps={{iconName: "Filter"}} />
                </div>
              </div>
            )
        }
      },
    ]

    return (
      <Fabric>
        <div className="toolbar">
          <div className="d-flex flex-row">
            <div className={ "history-actions" + (this.state.fileExplorerType !== "fileExplorer" ? " mr-auto" : "")}>
                <IconButton iconProps={backIcon} title={i18n.t('app:back')} ariaLabel="Back" disabled={!this.props.isOnline || this.props.maintenance || this.state.historyIndex === 0} onClick={ this._goBack.bind(this) }  />
                <IconButton iconProps={forwardIcon} title={i18n.t('app:forward')} ariaLabel="Forward" disabled={ !this.props.isOnline || this.props.maintenance || this.state.historyIndex === this.state.history.length-1} onClick={ this._goForward.bind(this) } />
            </div>
            { this.state.fileExplorerType === "fileExplorer" || this.state.url.includes('bookmarks-') ?
            <>
                <div className="refresh-action">
                {syncStatus.message === "Synced" ?
                    <IconButton iconProps={refreshIcon} title={i18n.t('app:refresh')} ariaLabel="Refresh" onClick={this._goBack.bind(this)} />
                :
                    <div className="ms-Spinner-wrap">
                    <Spinner size={SpinnerSize.small} />
                    </div>
                }
                </div>
                <div className={"folders-breadcrumb flex-grow-1" + ((this.props.isOnline && !this.props.maintenance) ? "" : " mr-3") } style={{marginRight:'8px'}}>
                <div className="row m-0 p-0">
                  <div className="breadcrumb-icon">
                  { this.state.url.startsWith('bookmarks-') ?
                      <Icon iconName="FavoriteStar" className="repoIcon" />
                  : this.state.url.startsWith('recents-') ?
                      <Icon iconName="Recent" className="repoIcon" />
                    :
                      <Icon iconName="FabricFolder" className="repoIcon" />
                  }
                  </div>

                  <div className="col m-0 p-0">
                      <Breadcrumb
                      items={ this.state.searching && this.state.url.includes("bookmarks-") ? [{
                        text: i18n.t('app:search') +" "+ i18n.t('app:inFolder') + " \"" + this.props.repoData[0].name + "\"",
                        key: 'search',
                      }]: this.state.searching && this.state.url.includes("recents-") ? [{
                        text: i18n.t('app:search') +" "+ i18n.t('app:inFolder') + " \"" + this.props.repoData[0].name + "\"",
                        key: 'search',
                      }] : this.state.searching ? [{
                        text: i18n.t('app:search') +" "+ i18n.t('app:inFolder') + " \"" + this.state.breadcrumbPath[this.state.breadcrumbPath.length-1].text + "\"",
                        key: 'search',
                      }] : breadcrumbPath }
                      ariaLabel="Breadcrumb"
                      overflowAriaLabel="More folders"
                      overflowIndex={ 0 }
                      />
                  </div>
                </div>
              </div>
              <div className="filtersBox d-flex mr-2" style={{marginTop: '2px'}}>
                <form onSubmit={(e)=>this.filterFolders(e,1)} className="filter">
                  <SearchBox className='search' placeholder={ i18n.t('app:search') +" "+ (this.state.searchOptions === 'searchFolders' ? i18n.t('app:lowerFolders') : i18n.t('app:lowerFiles')) } value={this.state.folderFilter} onChange={(e,v)=>{this.setState({folderFilter: v || ''})}} onClear={ ()=>this._goBack() } iconProps={{iconName: "Search"}} />
                  <Dropdown
                      selectedKey={this.state.searchOptions}
                      options={[
                        { key: 'searchFolders', text: i18n.t('app:folders') },
                        { key: 'searchFiles', text:  i18n.t('app:files')  },
                      ]}
                      dropdownWidth="auto"
                      onChange={ this.handleSearchTypeChange }
                      className="search-options"
                      onRenderTitle={ () => {
                        return (
                          <Icon iconName={'FilterSettings'} aria-hidden="true" />
                        );
                      }}
                    />
                </form>
              </div>
            </>
            : null }
            </div>
            <CommandBar
              items={[]}
              farItems={farItems}
              ariaLabel="Use left and right arrow keys to navigate between commands"
              className="commandBar"
            />
        </div>

        <div className="content-wrap d-flex flex-row">

          <div id="picker-nav" className=" d-flex flex-row">
          <nav className="tree">
                <p className="title mb-0">{i18n.t('app:fileExplorer')}</p>
                <ul className="ms-Nav-navItems h-100 m-0 pl-0">
                <Tree
                  nodes={this.state.nodesTree}
                  onChange={this.handleNodesChange}>
                  {({onChange, style, node, index, ...rest}) => {

                    const {hasChildren, isExpanded, type} = getNodeRenderOptions(node);
                    const handleChange = () =>{
                      onChange({...updateNode(node, {expanded: !isExpanded}) })
                    }
                    if(typeof(style.marginLeft) === "number") style.paddingLeft = style.marginLeft / 2
                    if(typeof(style.marginLeft) === "number") style.marginLeft = 0

                    let selected = false;
                    let item:any = node;
                    if (item && (item.type === 'repo' || item.type === 'folder')) selected = item.id === this.state.url ;
                    else if (item && item.type === 'recentFiles') selected = this.state.url.includes('recents-')
                    else if (item && item.type === 'bookmarks') selected = this.state.url.includes('bookmarks-')
                    else if (item && item.type === 'bookmarkFolder') selected = item.id === this.state.url;
                    
                    return(
                      <li className={"ms-Nav-navItem " + (selected ? 'bg-selected' : '')} style={style} tabIndex={index} onClick={() => {this._goToFolder(node); if(!isExpanded) handleChange()}}>
                            <div className={ "ms-Nav-compositeLink"} >
                          
                              <button className="chevronButton">
                                { hasChildren && !isExpanded?
                                  <Icon iconName="ChevronRight"  onClick={(e) => {e.stopPropagation(); handleChange();
                                    if(type === "repo"
                                    || type === "folder"
                                    || type === "templates")
                                      this._handleExpandLinkClick(e, node)}} className="ms-Nav-chevron d-inline-block" />
                                : hasChildren && isExpanded?
                                  <Icon iconName="ChevronDown"  onClick={(e) => {e.stopPropagation();handleChange()}} className="ms-Nav-chevron d-inline-block" />
                                :
                                  <div className="noChevron"></div>
                                }
                              </button>
                              
                              <button className="ms-Button ms-Button--action ms-Button--command ms-Nav-link pl-0">
                                { this._onRenderLink(node) }
                              </button>
                        
                        </div>
                          </li>
                    )}
                  }
                </Tree>
                </ul>
              </nav>
        </div>
          <div className="list-wrap flex-grow-1">
          {(this.state.searching || this.state.url.startsWith('recents-') || this.state.url.startsWith('bookmarks-')) && 
            <div className="folders-breadcrumb-search-results flex-grow-1 " style={{minWidth:'60px'}}>
              <div className="row m-0 p-0">
                <div className="breadcrumb-icon">
                  <Icon iconName="FabricFolder" className="repoIcon" />
                </div>

                <div className="col m-0 p-0" style={{minWidth:'30px'}}>
                  { selectedCount === 1 && itemBreadcrumb ?
                    <Breadcrumb
                      items={itemBreadcrumb}
                      ariaLabel="Breadcrumb"
                      overflowAriaLabel="Breadcrumb"
                      overflowIndex={ 0 }
                    />
                  : selectedCount > 1 ?
                    <Breadcrumb
                      items={[{
                        text: selectedCount + ' items selected',
                        key: 'multipleItemsBreadcrumb',
                      }]}
                      ariaLabel="Breadcrumb"
                      overflowAriaLabel="More folders"
                      overflowIndex={ 0 }
                    />
                  :
                    <Breadcrumb
                      items={[{
                        text: 'Select an item to show its path',
                        key: 'emptyBreadcrumb',
                      }]}
                      ariaLabel="Breadcrumb"
                      overflowAriaLabel="Breadcrumb"
                      overflowIndex={ 0 }
                    />
                  }
                </div>
              </div>
            </div>}
            <div className={"list mr-auto flex-grow-1" + (this.state.searching || this.state.url.startsWith('recents-') || this.state.url.startsWith('bookmarks-') ? " search" : "")}>
                <div id="dropzone" className="h-100">
                 <div>
                    <ScrollablePane className='explorerEl-picker' scrollbarVisibility={ScrollbarVisibility.auto}>
                      <ShimmeredDetailsList
                          componentRef={this._root}
                          className={"listEl-picker noselect"}
                          items={this.state.items}
                          compact={true}
                          columns={this.state.columns}
                          onRenderDetailsHeader={onRenderDetailsHeader}
                          selectionMode={SelectionMode.single}
                          setKey="set"
                          layoutMode={DetailsListLayoutMode.fixedColumns}
                          isHeaderVisible={true}
                          selection={this._selection}
                          selectionPreservedOnEmptyClick={false}
                          onItemInvoked={this._onItemInvoked.bind(this)}
                          ariaLabelForShimmer="Content is being fetched"
                          enterModalSelectionOnTouch={true}
                          ariaLabelForSelectionColumn="Toggle selection"
                          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                          checkButtonAriaLabel="Row checkbox"
                          //shimmerLines={12}
                          constrainMode={ConstrainMode.unconstrained}
                          listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
                      />
                      { this.state.loadMoreFolders && 
                        <div className='d-flex'>
                          { this.state.loadingMoreFolders ?
                            <Spinner size={SpinnerSize.small} className="my-1 mx-auto" />
                          :
                            <PrimaryButton id='load-more' text={i18n.t('app:loadMoreResults')} onClick={(e)=>{e.preventDefault();this.filterFolders()}} disabled={this.state.loadingMoreFolders} className='text-center mx-auto my-1' />
                          }
                        </div>
                      }
                    </ScrollablePane>

                    { (this.state.items.length === 0 && this.state.isLoading) ?
                      <div className="empty-folder-msg p-3">
                          <Spinner size={SpinnerSize.small} className="mt-5 mx-auto" />
                      </div>
                    : null }

                    { this.state.items.length === 0 && !this.state.isLoading ?
                      <div className="empty-folder-msg p-3">
                        <p className="text-center text-secondary mt-5 mx-auto px-3">{emptyMessage}</p>
                      </div>
                    : null }

                    <div style={{opacity: this.state.searchWord.length > 0 ? 1 : 0 ,position: 'absolute', bottom: '16px', width: '100%', transitionDuration: '.2s'}}>
                      <div style={{display: this.state.searchWord.length > 0 ? 'flex' : 'none', width: '200px', height: '80px', margin: 'auto', padding:'10px', borderRadius: '2px', color:'white', fontSize: '20px', fontWeight: 600, backgroundColor: 'rgba(0,0,0,0.6)'}}>
                        <div className='m-auto' style={{textAlign: 'center', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{this.state.searchWord.toLowerCase()}</div>
                      </div>
                    </div>

                  </div>
                </div>
            </div>
          </div>
        </div>
        <div className="footer d-flex" style={{ padding:'10px', paddingTop: 0}}>
            <div className="d-flex flex-row" style={{width:'100%'}}>
                <form name="form" style={{width:'100%', paddingRight:'10px'}}>
                  <TextField value={this.state.documentName} readOnly label={i18n.t('app:filename')} name="newDocument"/>
                </form>
                <PrimaryButton style={{ top:'31px', right:'0px'}} disabled={ this.state.savingFolder || this._checkDisabled() } onClick={()=>this._onItemInvoked(this.state.selFile)} text={/*this._checkSaveToFolder() ? i18n.t('app:OK') : */i18n.t('app:open')} /> 
            </div>
          </div>
      </Fabric>
    );
  }

  private handleNodesChange = nodesTree => {
    this.setState({nodesTree});
  }

  private _onBreadcrumbItemClicked(ev: React.MouseEvent<HTMLElement> | undefined, item: IBreadcrumbItem | undefined): void {
    if (item) {
      var {history, historyIndex} = this.state;
      history = history.slice(0,historyIndex+1);
      history.push(item.key)
      historyIndex += 1;
      this._isMounted && this.setState({
        url: item.key, 
        history: history,
        historyIndex: historyIndex,
        searching:false,
        loadMoreFolders: false,
        folderFilter: "",
        filter: ""
      })
      
    }
  }

  private _getRepoData() {
    var userData = this.props.userData
    var repo = userData && userData.repository;
    var repoData: any = [];

    repoData.push({
      id: repo.id,
      name: repo.name,
      role: userData.role
    })

    this._isMounted && this.setState({
      repoData: repoData
    }, () => {
      this._getCurrentContent();
    })
  }

  private _getCurrentContent() {
    var setItems = (items, childrenFolders) => {
      childrenFolders.sort((a,b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase())
            return -1;
        if(b.name.toLowerCase() < a.name.toLowerCase())
            return 1;

        return 0;
      });

      let folders: any = []
      var userGroups = this.props.userData.userGroups.map(userGroup => {
        return userGroup.id
      })

      for (let i = 0; i < childrenFolders.length; i++) {
        let folder = childrenFolders[i];
        folder.kind = "dir"
        const fileType = this._getFileType(folder, "");
        var dateCreated = new Date(folder.created_at).toString();
        var dateDeleted = folder.deleted_at && folder.deleted_at !== 0 ? new Date(folder.deleted_at).toString(): null;
        var dateModified = new Date(folder.modified_at).toString()

        var fullPath = folder.pathNames;
        if(!fullPath) {
          var pathInfo = this.getPathInfo(folder.parent_id)
          fullPath = pathInfo && pathInfo.map(item => {
            return item.text
          })
          fullPath = fullPath && fullPath.join("/")
          fullPath = "/" + fullPath
        }
        folder.path = fullPath;

        var bookmark: any = folder.bookmarks && folder.bookmarks.filter(bookmarkData => {
          return bookmarkData.user_id === this.props.userData.user.id
        })[0] !== undefined;

        var accessGranted: boolean = false;

        var isUser = folder.users.filter(user => {
          return user.id === this.props.userData.user.id
        })

        var isAdmin = folder.admins.filter(admin => {
          return admin === this.props.userData.user.id
        })

        var groupAccess = folder.groups.filter(group => {
          return userGroups.includes(group.id)
        })

        var isExternal = folder.externals.filter(externalUser => {
          return externalUser === this.props.userData.user.id
        })

        if(isAdmin.length > 0
          || isUser.length > 0
          || groupAccess.length > 0
          || isExternal.length > 0)
          accessGranted = true

          folders.push({
            key: folder.id,
            id: folder.id,
            accessGranted: accessGranted,
            name: folder.name,
            fileName: folder.name,
            repo: folder.repo,
            parentId: folder.parent_id,
            path: folder.path,
            pathIds: folder.pathIds,
            pathNames: folder.pathNames,
            hasSubFolders: folder.hasSubFolders,
            links: folder.hasSubFolders ? [{}] : [],
            isFolderInPath: folder.isFolderInPath,
            //icon: fileType.icon,
            fileType: fileType.docType,
            fileExtension: "folder",
            createdBy: folder.created_by,
            modifiedBy: folder.modified_by,
            dateCreated: new Date(dateCreated),
            dateDeleted: dateDeleted ? new Date(dateDeleted) : null,
            dateModified: new Date(dateModified),
            dateModifiedValue: new Date(dateModified),
            fileSize: 0,
            fileSizeRaw: 0,
            bookmark: bookmark,
            admins: folder.admins,
            adminsData: [],
            users: folder.users,
            usersData: [],
            externals: folder.externals,
            externalsData: [],
            groups: folder.groups,
            revision: "HEAD",
            kind: folder.kind,
            tags: folder.tags,
            type: "folder"
          });
      }

      var path = this.state.url;
      const newItems: any = folders.concat(this.sortAndMapItems(items, path))

      this._isMounted && this.setState({
        fullItemsResponse: newItems,
        filterByNameText: "",
        fullItemsResponseByName: newItems,
        filterByTypeText: "",
        fullItemsResponseByType: newItems,
        folders: folders
      })

      var links = childrenFolders
      var linksId = links.map(link => {
          return link.id
      })
      var foldersList = this.state.foldersList && this.state.foldersList.filter(folder => {
          return !linksId.includes(folder.id)
      })

      if(foldersList) this.setState({foldersList: foldersList.concat(links)})

      // Update items list
      var prevItemsString: any = this.state.items.map((item: any) => {
        var compItem = {
          id: item.id
        }
        return compItem
      });
      prevItemsString.sort((a,b) => {
        if(a.id.toLowerCase() < b.id.toLowerCase())
            return -1;
        if(b.id.toLowerCase() < a.id.toLowerCase())
            return 1;

        return 0;
      });
      prevItemsString = JSON.stringify(prevItemsString)
      
      var newItemsString: any = newItems.map(item => {
        var compItem = {
          id: item.id
        }
        return compItem
      });
      newItemsString.sort((a,b) => {
        if(a.id.toLowerCase() < b.id.toLowerCase())
            return -1;
        if(b.id.toLowerCase() < a.id.toLowerCase())
            return 1;

        return 0;
      });
      newItemsString = JSON.stringify(newItemsString)
      
      // if(prevItemsString !== newItemsString) {
      //   userService.list().subscribe();
      // }

      // Update state
      this._isMounted && this.setState({
        isLoading: false,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        }
      })

      this._isMounted && this.setState({items: newItems})
    }

    if (!this.state.url.includes('bookmarks-') && !this.state.url.includes('recents-')) {
      var id = this.state.url;

      this._isMounted && this.setState({
        syncStatus: {
          message: "Syncing",
          type: "info",
          icon: "spinner"
        }
      })

      if(id.indexOf("-trash") !== -1) {
        //userService.list().subscribe();
        var repo = id.replace("-trash", "")
        userService.getTrash(repo).then(response => {
          var items: any = []
          items = response.data.documents.filter(document => {
            return document.trash;
          })

          // Folders in trash
          var childrenFolders: any = []
          childrenFolders = response.data.folders.filter(folder => {
            return folder.trash;
          })

          setItems(items, childrenFolders)
        }).catch(error => {
          console.log(error)
        })
      } else {
        userService.getFolderContent(id).then((response: any) => {
          var items: any = response.data.documents;
          var childrenFolders: any = response.data.folders;
          
          setItems(items, childrenFolders)
        }).catch(error => {
          console.log(error)
          // Update state
          this._isMounted && this.setState({
            isLoading: false,
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            }
          })
        });
      }
    } else if (this.state.url.includes('bookmarks-')) {
      this._isMounted && this.setState({
        isLoading: true,
        syncStatus: {
          message: "Syncing",
          type: "info",
          icon: "spinner"
        }
      })

      let repos = this.props.userData && this.props.userData.repositoryDetailsList.map(repo => {
        return repo.repository.id
      })
      userService.getBookmarks(repos)
      .then((response:any) => {
        var items: any = response.data.documents;
        var childrenFolders: any = response.data.folders;
        if(items.length > 0 || childrenFolders.length > 0) {
          items = items && items.filter(item => {
            return !item.trash
          })

          childrenFolders = childrenFolders && childrenFolders.filter(folder => {
            return !folder.trash
          })

          setItems(items, childrenFolders)
        } else {
          this._isMounted && this.setState({
            isLoading: false,
            items: [],
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            }
          })
        }
      })
    } else if (this.state.url.startsWith('recents-')) {
      this._isMounted && this.setState({
        isLoading: true,
        syncStatus: {
          message: "Syncing",
          type: "info",
          icon: "spinner"
        }
      })

      let repos = this.props.userData && this.props.userData.repositoryDetailsList.map(repo => {
        return repo.repository.id
      })
      userService.getRecentFiles()
      .then((response:any) => {
        var items: any = response.data
        if(response !== undefined && response.data) {
          let list: any = [];

          var mergedItems = Object.entries(items).map((item: any) => {
            var lastOpened = parseInt(item[0]);
            return {...{"lastOpened": lastOpened}, ...item[1]}
          })
          
          mergedItems = mergedItems.filter(item => {
            return !item.trash
          })


          list = this.sortAndMapRecentFiles(mergedItems);
            this._isMounted && this.setState({
              isLoading: false,
              syncStatus: {
                message: "Synced",
                type: "success",
                icon: "SkypeCircleCheck"
              }
            })

          this._isMounted && this.setState({
            items: list,
            fullItemsResponse: list,
            fullItemsResponseByName: list,
            fullItemsResponseByType: list,
            filterByNameText: "",
            filterByTypeText: ""
          })
        } else {
          this._isMounted && this.setState({
            isLoading: false,
            items: [],
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            }
          })
        }
      }).catch((error)=>{
        console.log(error)
      })
    }
  }

  private sortAndMapRecentFiles(items) {

    function getReadableFileSizeString(fileSizeInBytes) {
      var i = -1;
      var byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
      do {
          fileSizeInBytes = fileSizeInBytes / 1024;
          i++;
      } while (fileSizeInBytes > 1024);

      return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    };

    var documents = items;
    if (items && !items.length && items !== "") {
      documents = [items]
    }

    documents = documents.filter(document => {
      return document.id
    })

    var list : any = [];

    if (documents && documents.length) {
      documents.sort((a,b) => {
        if(a.lastOpened < b.lastOpened)
            return 1;
        if(b.lastOpened < a.lastOpened)
            return -1;

        return 0;
      });
      
      for (let i = 0; i < documents.length; i++) {
        let doc = documents[i];
        var fileExtension = doc.type;
        const fileType = this._getFileType(doc, fileExtension);
        var dateCreated = new Date(doc.created_at).toString();
        var dateModified = new Date(doc.modified_at).toString();

        var currentVersion = doc.activeMajor + "." + doc.activeMinor + (doc.draft ? " Draft" : "")

        var fullPath = doc.pathNames;
        if(!fullPath) {
          var pathInfo = this.getPathInfo(doc.parent_id)
          fullPath = pathInfo && pathInfo.map(item => {
            return item.text
          })
          fullPath = fullPath && fullPath.join("/")
          fullPath = "/" + fullPath
        }

        var fileSize = "";
        if (doc.size) {
          fileSize = getReadableFileSizeString(doc.size)
        }

        var stored: string;
        var checkStored = this.state.storedFiles && this.state.storedFiles.filter(storedFile => {
          return storedFile.id === doc.id
        })
        stored = checkStored.length > 0 ? "same" : "no"

        var tags = doc.tags;

        if (tags) {
          tags.sort((a, b) => {
              if(a.toLowerCase() < b.toLowerCase()) { return -1; }
              if(a.toLowerCase() > b.toLowerCase()) { return 1; }
              return 0;
          })
        } else {
          tags = [];
        }

        doc.revisions && doc.revisions.map(revision => {
          return revision.version = revision.major + "." + revision.minor
        })

        var revisions = doc.revisions && doc.revisions.filter(revision => {
          return !revision.deleted
        });

        if (revisions) {
          revisions.sort((a, b) => {
            if(a.timestamp > b.timestamp) { return -1; }
            if(a.timestamp < b.timestamp) { return 1; }
            return 0;
          })

          for (let i = 0; i < revisions.length; i++) {
            var version = revisions[i];
            version.isActiveRevision = version.fileId === doc.activeRevision;
          }

          if (doc.draft) {
            revisions.unshift({
              author: doc.modified_by,
              fileId: doc.id + "-draft",
              message: "Draft",
              previousVersion: null,
              size: doc.size,
              timestamp: doc.modified_at,
              version: "draft"
            })
          }
        }

        var bookmark: any = doc.bookmarks && doc.bookmarks.filter(bookmarkData => {
          return bookmarkData.user_id === this.props.userData.user.id
        })[0] !== undefined;

        list.push({
            key: doc.id,
            id: doc.id,
            hash: doc.hash,
            fileName: doc.name,
            name: doc.title,
            fileExtension: fileExtension,
            parentId: doc.parent_id,
            repo: doc.repo,
            parent_id: doc.parent_id,
            path: fullPath,
            pathIds: doc.pathIds,
            pathNames: doc.pathNames,
            icon: fileType.icon,
            fileType: fileType.docType,
            createdBy: doc.created_by,
            modifiedBy: doc.modified_by,
            lastOpened: doc.lastOpened,
            dateCreated: new Date(dateCreated),
            dateModified: new Date(dateModified),
            dateModifiedValue: new Date(dateModified),
            dateRaw: doc.modified_at,
            fileSize: fileSize,
            fileSizeRaw: doc.size,
            status: doc.status,
            tags: tags,
            bookmark: bookmark,
            revisions: revisions,
            activeMajor: doc.activeMajor,
            activeMinor: doc.activeMinor,
            activeRevisionId:  doc.activeRevision,
            currentVersion: currentVersion,
            revisionsCount: doc.revisionsCount,
            draft: doc.draft,
            lock: doc.lock,
            lockInstanceId: doc.lockInstanceId,
            wopiLock: doc.wopiLock,
            stored: stored,
            kind: "file",
            trash: doc.trash
        });
      };
    }

    return list;
  }

  private sortAndMapItems(items, path) {

    var documents = items;
    var list : any = [];

    if (documents && documents.length) {
      var folders = this.state.foldersList;
      var currentFolder: any;
      if(folders){
        currentFolder = folders.filter(folder => {
          return folder.id === path
        })[0]
      }

      if((path && path.indexOf("-trash") !== -1) 
        || (currentFolder && currentFolder.trash)) {
        documents = documents.filter(item => {
          return item.trash;
        })
      } else if (path && path.indexOf("-trash") === -1) {
        documents = documents.filter(item => {
          return (item.parent_id === path && !item.trash);
        })
      }

      documents.sort((a,b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase())
            return -1;
        if(b.name.toLowerCase() < a.name.toLowerCase())
            return 1;

        return 0;
      });

      for (let i = 0; i < documents.length; i++) {
        let doc = documents[i];
        var fileExtension = doc.type;
        const fileType = this._getFileType(doc, fileExtension);
        var dateCreated = new Date(doc.created_at).toString();
        var dateDeleted = doc.deleted_at && doc.deleted_at !== 0 ? new Date(doc.deleted_at).toString() : null;
        var dateModified = new Date(doc.modified_at).toString();

        var fileSize = "";
        if (doc.size) {
          fileSize = getReadableFileSizeString(doc.size)
        }

        var currentVersion = doc.activeMajor + "." + doc.activeMinor + (doc.draft ? " Draft" : "")

        var stored: string;
        var checkStored = this.state.storedFiles && this.state.storedFiles.filter(storedFile => {
          return storedFile.id === doc.id
        })
        stored = checkStored.length > 0 ? "same" : "no"

        var fullPath = doc.path;
        if(!fullPath) {
          var pathInfo = this.getPathInfo(doc.parent_id)
          fullPath = pathInfo && pathInfo.map(item => {
            return item.text
          })
          fullPath = fullPath && fullPath.join("/")
          fullPath = "/" + fullPath
        }
        doc.path = fullPath;

        var tags = doc.tags;

        if (tags) {
          tags.sort((a, b) => {
              if(a.toLowerCase() < b.toLowerCase()) { return -1; }
              if(a.toLowerCase() > b.toLowerCase()) { return 1; }
              return 0;
          })
        } else {
          tags = [];
        }

        doc.revisions.map(revision => {
          return revision.version = revision.major + "." + revision.minor
        })

        var revisions = doc.revisions.filter(revision => {
          return !revision.deleted
        });

        revisions.sort((a, b) => {
            if(a.timestamp > b.timestamp) { return -1; }
            if(a.timestamp < b.timestamp) { return 1; }
            return 0;
        })

        for (let i = 0; i < revisions.length; i++) {
          var version = revisions[i];
          version.isActiveRevision = version.fileId === doc.activeRevision;
        }

        if (doc.draft) {
          revisions.unshift({
            author: doc.modified_by,
            fileId: doc.id + "-draft",
            message: "Draft",
            previousVersion: null,
            size: doc.size,
            timestamp: doc.modified_at,
            version: "draft"
          })
        }

        var bookmark: any = doc.bookmarks && doc.bookmarks.filter(bookmarkData => {
          return bookmarkData.user_id === this.props.userData.user.id
        })[0] !== undefined;

        list.push({
          key: doc.id,
          id: doc.id,
          hash: doc.hash,
          fileName: doc.name,
          name: doc.title,
          fileExtension: fileExtension,
          parentId: doc.parent_id,
          repo: doc.repo,
          path: doc.path,
          pathIds: doc.pathIds,
          pathNames: doc.pathNames,
          icon: fileType.icon,
          fileType: fileType.docType,
          createdBy: doc.created_by,
          modifiedBy: doc.modified_by,
          dateCreated: new Date(dateCreated),
          dateDeleted: dateDeleted ? new Date(dateDeleted) : null,
          dateModified: new Date(dateModified),
          dateModifiedValue: new Date(dateModified),
          dateRaw: doc.modified_at,
          fileSize: fileSize,
          fileSizeRaw: doc.size,
          status: doc.status,
          tags: tags,
          bookmark: bookmark,
          revisions: revisions,
          activeMajor: doc.activeMajor,
          activeMinor: doc.activeMinor,
          activeRevisionId:  doc.activeRevision,
          currentVersion: currentVersion,
          draft: doc.draft,
          lock: doc.lock,
          lockInstanceId: doc.lockInstanceId,
          wopiLock: doc.wopiLock,
          stored: stored,
          kind: "file",
          trash: doc.trash
        });
      };
    }
    return list;
  }

  private sortAndMapOpenFiles(items) {

    function getReadableFileSizeString(fileSizeInBytes) {
      var i = -1;
      var byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
      do {
          fileSizeInBytes = fileSizeInBytes / 1024;
          i++;
      } while (fileSizeInBytes > 1024);

      return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    };

    var documents = items;
    if (items && !items.length && items !== "") {
      documents = [items]
    }

    var list : any = [];

    if (documents && documents.length) {
      documents.sort((a,b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase())
            return -1;
        if(b.name.toLowerCase() < a.name.toLowerCase())
            return 1;

        return 0;
      });
      
      for (let i = 0; i < documents.length; i++) {
        var doc = documents[i];
        var fileExtension = doc.type;
        const fileType = this._getFileType(doc, fileExtension);

        var fileSize = "";
        if (doc.stats && doc.stats.size) {
          fileSize = getReadableFileSizeString(doc.stats.size)
        }

        list.push({
          key: doc.id,
          id: doc.id,
          fileName: doc.fileName,
          name: doc.name,
          fileExtension: fileExtension,
          parentId: doc.parentId,
          icon: fileType.icon,
          fileType: fileType.docType,
          fileSize: fileSize,
          fileSizeRaw: doc.stats ? doc.stats.size : 0,
          kind: "file"
        });
      };
    }

    return list;
  }

  private getPathInfo(id) {
    var folders = this.state.foldersList;

    if(folders) {

      var folderId = id;
      let foldersData = folders
      let repos = this.state.repoData

      var currentFolder = foldersData.filter(folder => {
        return folder.id === folderId
      })
      currentFolder = currentFolder[0]


      var breadcrumb: any = [];

      if(currentFolder && currentFolder.path_id) {
        
        breadcrumb.push({
            text: currentFolder.name,
            key: currentFolder.id
          })

        var getParentData = (parentId) => {
          var parentData;

          parentData = foldersData.filter(data => {
            return parentId === data.id
          })

          if(!parentData.length) {
            parentData = repos && repos.filter(data => {
              return parentId === data.id
            })
          }

          parentData = parentData[0]

          if(parentData) {
            breadcrumb.unshift({
              text: parentData.name,
              key: parentData.id
            })
          }

          if(parentData && parentData.parent_id)
            getParentData(parentData.parent_id)
        }

        getParentData(currentFolder.parent_id)
      } else {
        // Check if root folder
        let folder = repos && repos.filter(data => {
          return data.id === folderId
        })[0]

        if (folder)
          breadcrumb.unshift({
              text: folder.name,
              key: folder.id
            })
      }
    }

    return breadcrumb
  }

  private _onRenderLink = (link: any): JSX.Element | null => {
    return (
      link.type === "repo" ?
        <div className={"folderLink" + (link.id === this.state.url ? " selFolder":"")}  onClick={ evt => {
        evt.preventDefault(); this.setState({goBack: 0}) } }>
            <Icon   iconName="OfflineStorage" className="repoIcon" />
            <span>{ link.name }</span>
        </div>
      : link.type === "folder" ?
        <div className={"folderLink dir " + (link.id === this.state.url ? " selFolder":"")} onClick={ evt => { evt.preventDefault(); this.setState({goBack: 0}) } }>
            <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } />
            <span>{ link.name }</span>
        </div>
      : link.type === "recentFiles" ?
        <div className={"folderLink " + (this.state.url.includes('recents-') ? "selFolder":"")}>
          <Icon iconName="Recent" className="recentFiles align-top" />
          <span>{ link.name }</span>
        </div>
      : link.type === "bookmarks" ?
        <div className={"folderLink " + (this.state.url.includes('bookmarks-') ? " selFolder":"")}>
          <Icon iconName="FavoriteStar" className="bookmarks align-top" />
          <span>{ link.name }</span>
        </div>
      : link.type === "folder" || link.type === "bookmarkFolder"?
        <div ref={() => link.id} className={"folderLink dir" + (link.id === this.state.url ? " selFolder":"") + " " + ((link.type === "folder" && this.state.folderTreeFocusId === link.id) ? " scrolledFolder" : "")} >
          <div className="icon-wrap">
            <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } className="child-elements" />
            { link.isFolderInPath ?
              <Icon iconName="ChevronRightSmall" className="folder-in-path" />
              : !link.accessGranted && !link.isFolderInPath ?
              <Icon iconName="lockSolid" className="no-access-folder" />
            : null }
          </div>
          <span className="child-elements">{ link.name}</span>
        </div>
      : <div className="link">
          <span>{ link.name }</span>
        </div>
    )
  }

  private _getFileType(doc, fileExtension): { docType: string; icon: string } {
    var kind = doc.kind;

    if (kind === "dir") {
      const docType: string = "dir"
      return {
        docType,
        icon: "folder"
      }
    } else {
      const docType: string = "file"
      if (fileExtension === "mhtml") {
        fileExtension = "html";
      }

      return {
        docType,
        icon: fileExtension
      }
    }
  }

  private traverseFileTree(item, path) {
    path = path || "";
    if (item.isFile) {
      // Get file
      item.file((file) => {});
    } else if (item.isDirectory) {
      // Get folder contents
      var dirReader = item.createReader();
      dirReader.readEntries((entries) => {
        for (var i=0; i<entries.length; i++) {
          this.traverseFileTree(entries[i], path + item.name + "/");
        }
      });
    }
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        newCol.isSortedDescending = !currColumn.isSortedDescending;
        newCol.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    var newItems;
    if (column.key === "name") {
      newItems = _copyAndSortName(items, currColumn.fieldName!, currColumn.isSortedDescending);
    } else {
      newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    }

    localStorage.setItem("dashboardColumns", JSON.stringify(newColumns))

    this._isMounted && this.setState({
      columns: newColumns,
      items: newItems
    });
  };

  private _getInitialBreadcrumb () {
    var breadcrumb: any = [
      {
        text: 'Synergy Drive',
        key: this.props.repoData[0].id,
        onClick: this._onBreadcrumbItemClicked.bind(this)
      }
    ]
    
    if (this.state.fileExplorerType === "recentFiles") {
      breadcrumb = [
        {
          text: i18n.t('app:recentFiles'),
          key: 'recentFiles'
        }
      ]
    } else if (this.state.fileExplorerType === "openFiles") {
      breadcrumb = [
        {
          text: i18n.t('app:openFiles'),
          key: 'openFiles'
        }
      ]
    } else if (this.state.url.includes('bookmarks-')) {
      breadcrumb = [
        {
          text: i18n.t('app:bookmarks'),
          key: 'bookmarks'
        }
      ]
    }

    this._isMounted && this.setState({
      breadcrumbPath: breadcrumb
    })
  }

  private getVersionColor(item:any) {
    if (item.revisions) {
      let revision = item.revisions[0]
      if (revision.version === "draft") return '#ffc107'
      else if (revision.minor !== "00" && revision.external) return '#cbf5c8'
      else if (revision.minor === "00" && revision.external) return '#28a745'
      else if (revision.minor !== "00" && !revision.external) return '#C7E0F4'
      else return '#007bff'
    } else return ''
  }

  private _getColumns() {
    var columns: any = []

    if (this.state.url.startsWith('recents-') && !this.state.searching) {
      columns = [
        {
          key: 'icon',
          name: <div><Icon style={{fontSize: '40%', marginRight: '27px', marginLeft: 0, color: 'transparent'}} iconName="StatusCircleRing" className="offline-status small my-auto" />#</div>,
          ariaLabel: 'Column operations for File type, Press to sort on File type',
          fieldName: 'fileType',
          minWidth: 50,
          maxWidth: 50,
          //isSortedDescending: false,
          //onColumnClick: this._onColumnClick,
          isPadded: false,
          onRender: (item: IDocument) => {
            return (<>
              <Icon style={{fontSize: '40%', marginRight: '22px', marginLeft: 0, color: 'transparent' || this.getVersionColor(item)}} iconName="CircleFill" className="offline-status small my-auto" />
              {item.fileType === 'dir' ?
                <>
                  <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } />
                  { !item.accessGranted && item.isFolderInPath ?
                    <Icon iconName="ChevronRightSmall" className="no-access-folder" />
                  : !item.accessGranted && !item.isFolderInPath ?
                    <Icon iconName="lockSolid" className="no-access-folder" />
                  : null }
                </>
              : item.fileType === 'file' ?
                <Icon {...getFileTypeIconProps({ extension: item.icon, size: 20, imageFileType: 'svg' }) } />
              : null }
            </>)
          }
        },
        {
          key: 'name',
          name: i18n.t('app:name'),
          fieldName: 'name',
          minWidth: 150,
          maxWidth: 150,
          isRowHeader: true,
          isResizable: true,
          isSorted: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: (item: IDocument) => {
            return (
                <div>
                  { item.savingDraft ?
                    <span title={ "Saving..." }><Icon iconName="Save" className="saving mr-2 small" /></span>
                  : null }
                  { item.lock && !item.wopiLock && !item.lockingDocument ?
                    <Icon iconName="Lock" className={"mr-2 small" + (item.lock.id === this.props.userData.user.id ? " text-primary" : "")} />
                  : item.wopiLock && !item.lockingDocument ?
                    <Icon iconName="OfficeLogo" className="mr-2 small" />
                  : item.lockingDocument ?
                    <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
                  :
                    null
                  }
                  <span title={ item.fileName }>{ item.name }</span>
                </div>
              )
          }
        },
        {
          key: 'type',
          name: i18n.t('app:type'),
          fieldName: 'fileExtension',
          minWidth: 64,
          maxWidth: 64,
          isResizable: true,
          data: 'string',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return (
                item.fileType === "dir" ?
                  <span>{i18n.t('app:lowerFolder')}</span>
                : item.fileType === "file" ?
                  <span>{'.' + item.fileExtension}</span>
                : null
              )
          },
          isPadded: true
        },
        {
          key: 'path',
          name: i18n.t('app:path'),
          fieldName: 'path',
          minWidth: 64,
          maxWidth: 220,
          isResizable: true,
          data: 'string',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return <span title={ item.path }>{ item.path }</span>
          },
          isPadded: true
        },
        {
          key: 'lastOpened',
          name: i18n.t('app:lastOpened'),
          fieldName: 'lastOpened',
          minWidth: 64,
          maxWidth: 100,
          isResizable: true,
          data: 'number',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return <span>{ Moment(item.lastOpened).format('Y-MM-DD HH:mm') }</span>
          },
          isPadded: true
        }
      ]
    } else if (this.state.searching) {
      columns = [
        {
          key: 'icon',
          name: <div><Icon style={{fontSize: '40%', marginRight: '27px', marginLeft: 0, color: 'transparent'}} iconName="StatusCircleRing" className="offline-status small my-auto" />#</div>,
          ariaLabel: 'Column operations for File type, Press to sort on File type',
          fieldName: 'fileType',
          minWidth: 50,
          maxWidth: 50,
          //isSortedDescending: false,
          //onColumnClick: this._onColumnClick,
          isPadded: false,
          onRender: (item: IDocument) => {
            return (<>
              <Icon style={{fontSize: '40%', marginRight: '22px', marginLeft: 0, color: 'transparent' || this.getVersionColor(item)}} iconName="CircleFill" className="offline-status small my-auto" />
              {item.fileType === 'dir' ?
                <>
                  <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } />
                  { !item.accessGranted && item.isFolderInPath ?
                    <Icon iconName="ChevronRightSmall" className="no-access-folder" />
                  : !item.accessGranted && !item.isFolderInPath ?
                    <Icon iconName="lockSolid" className="no-access-folder" />
                  : null }
                </>
              : item.fileType === 'file' ?
                <Icon {...getFileTypeIconProps({ extension: item.icon, size: 20, imageFileType: 'svg' }) } />
              : null }
            </>)
          }
        },
        {
          key: 'name',
          name: i18n.t('app:name'),
          fieldName: 'name',
          minWidth: 150,
          maxWidth: 150,
          isRowHeader: true,
          isResizable: true,
          isSorted: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: (item: IDocument) => {
            return (
                <div>
                  { item.savingDraft ?
                    <span title={ "Saving..." }><Icon iconName="Save" className="saving mr-2 small" /></span>
                  : null }
                  { item.lock && !item.wopiLock && !item.lockingDocument ?
                    <Icon iconName="Lock" className={"mr-2 small" + (item.lock.id === this.props.userData.user.id ? " text-primary" : "")} />
                  : item.wopiLock && !item.lockingDocument ?
                    <Icon iconName="OfficeLogo" className="mr-2 small" />
                  : item.lockingDocument ?
                    <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
                  :
                    null
                  }
                  <span title={ item.fileName }>{ item.name }</span>
                </div>
              )
          }
        },
        {
          key: 'type',
          name: i18n.t('app:type'),
          fieldName: 'fileExtension',
          minWidth: 64,
          maxWidth: 64,
          isResizable: true,
          data: 'string',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return (
                item.fileType === "dir" ?
                  <span>{i18n.t('app:lowerFolder')}</span>
                : item.fileType === "file" ?
                  <span>{'.' + item.fileExtension}</span>
                : null
              )
          },
          isPadded: true
        },
        {
          key: 'path',
          name: i18n.t('app:path'),
          fieldName: 'path',
          minWidth: 64,
          maxWidth: 250,
          isResizable: true,
          data: 'string',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return <span title={ item.path }>{ item.path }</span>
          },
          isPadded: true
        }
      ]
    } else {
      columns = [
        {
          key: 'icon',
          name: <div><Icon style={{fontSize: '40%', marginRight: '27px', marginLeft: 0, color: 'transparent'}} iconName="StatusCircleRing" className="offline-status small my-auto" />#</div>,
          ariaLabel: 'Column operations for File type, Press to sort on File type',
          fieldName: 'fileType',
          minWidth: 50,
          maxWidth: 50,
          //isSortedDescending: false,
          //onColumnClick: this._onColumnClick,
          isPadded: false,
          onRender: (item: IDocument) => {
            return (<>
              <Icon style={{fontSize: '40%', marginRight: '22px', marginLeft: 0, color: 'transparent' || this.getVersionColor(item)}} iconName="CircleFill" className="offline-status small my-auto" />
              {item.fileType === 'dir' ?
                <>
                  <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } />
                  { !item.accessGranted && item.isFolderInPath ?
                    <Icon iconName="ChevronRightSmall" className="no-access-folder" />
                  : !item.accessGranted && !item.isFolderInPath ?
                    <Icon iconName="lockSolid" className="no-access-folder" />
                  : null }
                </>
              : item.fileType === 'file' ?
                <Icon {...getFileTypeIconProps({ extension: item.icon, size: 20, imageFileType: 'svg' }) } />
              : null }
            </>)
          }
        },
        {
          key: 'name',
          name: i18n.t('app:name'),
          fieldName: 'name',
          minWidth: 150,
          maxWidth: 150,
          isRowHeader: true,
          isResizable: true,
          isSorted: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: (item: IDocument) => {
            return (
                <div>
                  { item.savingDraft ?
                    <span title={ "Saving..." }><Icon iconName="Save" className="saving mr-2 small" /></span>
                  : null }
                  { item.lock && !item.wopiLock && !item.lockingDocument ?
                    <Icon iconName="Lock" className={"mr-2 small" + (item.lock.id === this.props.userData.user.id ? " text-primary" : "")} />
                  : item.wopiLock && !item.lockingDocument ?
                    <Icon iconName="OfficeLogo" className="mr-2 small" />
                  : item.lockingDocument ?
                    <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
                  :
                    null
                  }
                  <span title={ item.fileName }>{ item.name }</span>
                </div>
              )
          }
        },
        {
          key: 'type',
          name: i18n.t('app:type'),
          fieldName: 'fileExtension',
          minWidth: 64,
          maxWidth: 64,
          isResizable: true,
          data: 'string',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return (
                item.fileType === "dir" ?
                  <span>{i18n.t('app:lowerFolder')}</span>
                : item.fileType === "file" ?
                  <span>{'.' + item.fileExtension}</span>
                : null
              )
          },
          isPadded: true
        },
        {
          key: 'currentVersion',
          name: i18n.t('app:version'),
          fieldName: 'currentVersion',
          minWidth: 80,
          maxWidth: 80,
          isResizable: true,
          data: 'number',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return <div style={{marginRight: 0, marginLeft: 'auto'}}>
              {item.fileType === "file" ? <span style={{display: 'flex'}}>{item.currentVersion}<Icon style={{fontSize: '40%', position: 'relative', top: '1px', marginLeft: '10px', color: this.getVersionColor(item)}} iconName="CircleFill" className="offline-status small my-auto" /></span> : null}
            </div>
          }
        },
        {
          key: 'fileSize',
          name: i18n.t('app:size'),
          fieldName: 'fileSizeRaw',
          minWidth: 64,
          maxWidth: 64,
          isResizable: true,
          data: 'number',
          headerClassName: 'headerToRight',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return (
              item.fileType === "file" ?
              <div style={{marginRight: 0, marginLeft: 'auto'}}>
                <span>{item.fileSize}</span>
              </div>
              : null
            )
          }
        },
        {
          key: 'stored',
          name: i18n.t('app:stored'),
          isIconOnly: true,
          iconName: "Download",
          ariaLabel: 'Column operations for stored files, Press to sort on stored files',
          fieldName: 'stored',
          minWidth: 12,
          maxWidth: 12,
          //isSortedDescending: false,
          //onColumnClick: this._onColumnClick,
          isPadded: false,
          onRender: (item: IDocument) => {
            return (
              item.stored === "same" ?
                <Icon iconName="CircleFill" className="offline-status text-success small align-bottom" />
              : item.stored === "different" ?
                <Icon iconName="SwitcherStartEnd" className="offline-status text-primary align-middle" />
              :  item.stored === "downloading" ?
                <Icon iconName="Sync" className="offline-status text-primary small align-bottom" />
              :  item.stored === "removing" ?
                <Icon iconName="Sync" className="offline-status text-danger small align-bottom" />
                
              :
                null
            )
          }
        },
        {
          key: 'modifiedBy',
          name: i18n.t('app:modifiedBy'),
          fieldName: 'modifiedBy',
          minWidth: 70,
          maxWidth: 90,
          isResizable: true,
          data: 'string',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            if(item.createdBy) {
              return <span className="author">{item.modifiedBy.name}</span>;
            } else {
              return null
            }
          },
          isPadded: true
        }
      ]
    }

    this._isMounted && this.setState({
      columns: columns
    })
  }

  private _sortBySavedColumn = (column: IColumn, items: any): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];

    var newItems;
    if (column.key === "name") {
      newItems = _copyAndSortName(items, currColumn.fieldName!, currColumn.isSortedDescending);
    } else {
      newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    }

    this._isMounted && this.setState({
      columns: newColumns,
      items: newItems
    });
  };

  private _onVersionsColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { versionsColumns, selFile } = this.state;
    const newColumns: IColumn[] = versionsColumns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    var newItems = _copyAndSort(selFile.revisions, currColumn.fieldName!, currColumn.isSortedDescending);

    var newSelFile = this.state.selFile;
    newSelFile.revisions = newItems

    this._isMounted && this.setState({
      versionsColumns: newColumns,
      selFile: newSelFile
    });
  };

  private _getBookmarksFolders(id) {
    userService.getBookmarks([id]).then(response => {
      var bookmarkFolders = response.data.folders.map(folder => {
       return({
         key: folder.id,
         id: folder.id,
         name: folder.name,
         parentId: folder.parent_id,
         repo: folder.repo,
         bookmark: true,
         accessGranted: true,
         isExpanded: false,
         type: "bookmarkFolder",
         hidden: folder.trash
       })
      })
 
      bookmarkFolders = bookmarkFolders.sort((a,b) => {
       if(a.name.toLowerCase() < b.name.toLowerCase())
           return -1;
       if(b.name.toLowerCase() < a.name.toLowerCase())
           return 1;
 
       return 0;
     });
 
      var bookmarks = this.state.nodesTree.filter(item => {
        return item.key === "bookmarks-" + id
       })[0]
       if(bookmarks) {
         bookmarks.isExpanded = true
         bookmarks.children = bookmarkFolders
       }
       this._isMounted && this.setState({nodesTree: this.state.nodesTree})
    }).catch(error => {
      console.log(error)
    })
  }

  private _getFolderTree = () => {
    const { repoData } = this.props;
    var folderTree: any = [];
    var folders: any = this.state.foldersList;

    if(repoData && repoData.length && folders) {
        // Sorting folders by name
        if (folders) {
          folders = folders.sort((a,b) => {
            if(a.name.toLowerCase() < b.name.toLowerCase())
                return -1;
            if(b.name.toLowerCase() < a.name.toLowerCase())
                return 1;

            return 0;
          });

          var nested = folders.map(folder => {

            folder.key = folder.id;
            folder.parentId = folder.parent_id;
            folder.type = "folder";
            folder.accessGranted = true;
            folder.isExpanded = false;
            folder.hidden = folder.trash;
            folder.state= { expanded: false }
            folder.hasChildren = folder.hasSubFolders

            folder.links = folder.hasSubFolders ? [{}] : [];
            
            return folder
          })

          var internalRepos = repoData.filter(repo => {
            if(repo.role === "ADMIN"
              || repo.role === "USER"
              || repo.role === "INTERNAL") {
              return true
            } else {
              return false
            }
          })

          for (let i = 0; i < internalRepos.length; i++) {
            let repo = internalRepos[i];

            this.filteredNested = nested.filter(item => {
              return item.parent_id === repo.id
            })

            folderTree.unshift(
              {
                key: "recents-" + repo.id,
                id: "recents-" + repo.id,
                name: i18n.t('app:recentFiles'),
                isExpanded: false,
                type: "recentFiles",
              },
              {
                key: "bookmarks-" + repo.id,
                id: "bookmarks-" + repo.id,
                name: i18n.t('app:bookmarks'),
                hasSubFolders: true,
                isExpanded: false,
                type: "bookmarks",
                state: {
                  expanded: true
                }
              }
          )

            this._getBookmarksFolders(repo.id)
            folderTree.push(
                {
                  key: repo.id,
                  id: repo.id,
                  name: repo.name,
                  isExpanded: true,
                  hasSubFolders: true,
                  children: this.filteredNested,
                  type: "repo",
                  state: {
                    expanded: true
                  }
                }
              )
            
          }

          var externalRepos = repoData.filter(repo => {
            return repo.role === "EXTERNAL"
          })
          var externalReposLinks: any = []

          for (let i = 0; i < externalRepos.length; i++) {
            let repo = externalRepos[i];

            externalReposLinks.push(
              {
                key: repo.id,
                id: repo.id,
                name: repo.name,
                isExpanded: false,
                type: "repo"
              }
            )
          }

          if(externalRepos.length > 0) {
            folderTree.push({
              name: i18n.t('app:sharedWithMe'),
              children: externalReposLinks
            })
          }

          this._isMounted && this.setState({
            folderTree: folderTree,
            nodesTree: folderTree
          }, () => {
            //this._getExpandedFolders(folders)
          });    
        }
      }
  }

  private _goToFolder(item) {
    if (item) {
      var {history, historyIndex} = this.state;
      history = history.slice(0,historyIndex+1);
      history.push(item.id)
      historyIndex += 1;
      this._isMounted && this.setState({url: item.id, 
        history: history,
        historyIndex: historyIndex,
        searching:false,
        loadMoreFolders: false,
        folderFilter: "",
        filter: ""
      })
    }
  };

  private _onItemInvoked(_item: any): void {
    if (this._selection.getSelection().length === 0) return;
    let item:any = this._selection.getSelection()[0];
    var kind = item.fileType;

    if (kind === "dir") {
      var {history, historyIndex} = this.state;
      history = history.slice(0,historyIndex+1);
      history.push(item.key)
      historyIndex += 1;
      if(item.accessGranted || item.isFolderInPath) this._isMounted && this.setState({url: item.key,
        history: history,
        historyIndex: historyIndex,
        searching:false,
        loadMoreFolders: false,
        folderFilter: "",
        filter: ""
      });
    }
    else if (item.fileExtension === "doc" || item.fileExtension === "docx") {
      this.props.callback(item)
    }
  }

  private _checkDisabled() {
    if(this._selection) {

      var selection:any = this._selection.getSelection();
      if (selection.length === 0) {
        return true;
      }

      if ((selection && selection[0] && selection[0].fileType !== "dir" && (selection[0].fileExtension === "doc" || selection[0].fileExtension === "docx"))) {
        return false;
      }
      else if (selection && selection[0] && selection[0].fileType === "dir") {
        return false;
      }

      return true;
    }

    return true;
  }

  private _getBreadcrumbItems() {
    var folders = (this.props.foldersList && this.props.foldersList.filter((folder)=>{
      return folder.id !== this.state.url
    })) || null
    let repos = this.state.repoData
    if(folders && repos) {

      var folderId = this.state.url;
      let foldersData = folders

      var currentFolder = foldersData.filter(folder => {
        return folder.id === folderId
      })[0]

      if(currentFolder && currentFolder.path_id) {
        var breadcrumb: any = [];
        
        breadcrumb.push({
          text: currentFolder.name,
          key: currentFolder.id,
          onClick: this._onBreadcrumbItemClicked.bind(this)
        })

        this._sortBreadcrumbItems(foldersData, breadcrumb, currentFolder.parent_id, currentFolder.trash)
      } else {
        // Check if root folder
        let repo = repos.filter(data => {
          return data.id === folderId
        })
        repo = repo[0]

        if (repo)
          this.setState({
            breadcrumbPath: [{
              text: repo.name,
              key: repo.id,
              onClick: this._onBreadcrumbItemClicked.bind(this)
            }]
          })

        // Check if trash folder
        let trash = repos.filter(data => {
          return data.id + "-trash" === folderId
        })
        trash = trash[0]

        if (folderId.startsWith("bookmarks-")){
           this.setState({
            breadcrumbPath: [{
              text: i18n.t('app:bookmarks'),
              key: 'bookmarks',
            }]
          })
        }
        else if (folderId.startsWith("recents-")){
          this.setState({
           breadcrumbPath: [{
             text: i18n.t('app:recentFiles'),
             key: 'recents',
           }]
         })
       }

        if (trash) {
          //this._isMounted && this.setState({isTrash: true})

          var repoId = folderId.replace("-trash", "");
          let repo = repos.filter(data => {
            return data.id === repoId
          })
          repo = repo[0]
          
          this.setState({
            breadcrumbPath: [
              {
                text: repo.name,
                key: repo.id,
                onClick: this._onBreadcrumbItemClicked.bind(this)
              },
              {
                text: i18n.t('app:trash'),
                key: folderId,
                onClick: this._onBreadcrumbItemClicked.bind(this)
              }
            ]
          })
        }

        if(!repo && folderId && !folderId.endsWith("-trash") && !folderId.startsWith("bookmarks-") && !folderId.startsWith('recents-')) {
          // Search path folders  
          userService.getPathFolders(folderId).then(response => {
            var pathFolders = response.data
            var currentFolder = pathFolders.filter(folder => {
              return folder.id === folderId
            })[0]

            if(currentFolder) {
              var breadcrumb: any = [];
        
              breadcrumb.push({
                text: currentFolder.name,
                key: currentFolder.id,
                onClick: this._onBreadcrumbItemClicked.bind(this)
              })

              this._sortBreadcrumbItems(pathFolders, breadcrumb, currentFolder.parentId, currentFolder.trash)
            }
          }).catch(error => console.log(error))
        }
      }
    }
  }

  private _sortBreadcrumbItems(foldersData, breadcrumb, id, trash) {
    let repos = this.state.repoData

    var getParentData = (parentId, trash) => {
      var parentData;

      parentData = foldersData.filter(data => {
        return parentId === data.id
      })

      if(!parentData.length) {
        parentData = repos.filter(data => {
          return parentId === data.id
        })
      }

      parentData = parentData[0]

      if(parentData) {
        if (trash) {
          //this._isMounted && this.setState({isTrash: true})
          var repoId = trash.replace("-trash", "");
          let repo = repos.filter(data => {
            return data.id === repoId
          })
          repo = repo[0]

          breadcrumb = breadcrumb.filter(item => {
            return (item.key !== trash && item.key !== repoId)
          })

          breadcrumb.unshift(
            {
              text: repo.name,
              key: repo.id,
              onClick: this._onBreadcrumbItemClicked.bind(this)
            },
            {
              text: i18n.t('app:trash'),
              key: trash,
              onClick: this._onBreadcrumbItemClicked.bind(this)
            }
          )
        }

        if ((trash && parentData.trash)
          || !trash) {
          breadcrumb = breadcrumb.filter(item => {
            return item.key !== parentData.id
          })

          breadcrumb.unshift({
            text: parentData.name,
            key: parentData.id,
            onClick: this._onBreadcrumbItemClicked.bind(this)
          })
        }
      }
      
      this.setState({
        breadcrumbPath: breadcrumb
      })
      
      let parentDataId = parentData && (parentData.parent_id || parentData.parentId)
      if(parentDataId) getParentData(parentDataId, parentData.trash)
    }

    getParentData(id, trash)
  }

  private _expandFolderWhileBrowsing(folderId, subFolders) {
    function searchRecursive(data, id) {
      
      let found = data.find(d => d.key === id && (d.type === "repo" || d.type === "folder" || d.type === "template"));
      if (!found) {
        let i = 0;
        while(!found && i < data.length) {
          if (data[i].children) {
            found = searchRecursive(data[i].children, id);
          }
          i++;
        }
      }

      return found;
    }

    var folder: any = searchRecursive(this.state.nodesTree, folderId);

    if(folder) {

      var links = subFolders.map(subFolder => {
        delete subFolder.icon
        subFolder.state = {expanded: false}
        subFolder.hasChildren = subFolder.hasSubFolders
        return subFolder
      })

      folder.isExpanded = true
      folder.state = {expanded: true}
      // if(folder.id.startsWith("Repo-")) folder.links = subFolders.concat(folder.links)
      // else folder.links = subFolders

      if(!folder.id.startsWith("Repo-")) folder.children = links
      this._isMounted && this.setState({
        nodesTree: this.state.nodesTree,
      }, () => {
        var focusEl = document.getElementsByClassName("scrolledFolder")[0] && document.getElementsByClassName("scrolledFolder")[0].parentElement
        if(focusEl) focusEl = focusEl.parentElement
        if(focusEl) focusEl = focusEl.parentElement

        if(focusEl) {
          focusEl.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest'
          });
        }
      })


      var parentFolder: any = searchRecursive(this.state.folderTree, folder.parent_id || folder.parentId);
      if(parentFolder && parentFolder.id) {
        parentFolder.isExpanded = true
      }
    }
  }

  private _handleExpandLinkClick(ev?: React.MouseEvent<HTMLElement> | undefined, item?: any) {
    // Trigger only on direct click on chevron icon
    var target: any = ev && ev.target;
    if(target && target.className.indexOf("ms-Nav-chevron") !== -1 && item && !item.isExpanded && item.links && item.links.length <= 1 ) {
      if(ev) ev.preventDefault()

      this.setState({folderTreeFocusId: ""})
      
      userService.getFolderContent(item.id).then(response => {
        var repoUsers = this.props.repoUsers;
        // var userGroups = this.props.userData.userGroups.map(userGroup => {
        //   return userGroup.id
        // })

        var links: any = response.data.folders.map(link => {
          link.key = link.id
          link.isExpanded = false
          link.parentId = link.parent_id
          link.type = "folder"
    
          link.fileName = link.name
          link.fileType = "dir"

          // var accessGranted: boolean = false;

          // var isUser = link.users.filter(user => {
          //   return user.id === this.props.userData.user.id
          // })

          // var isAdmin = link.admins.filter(admin => {
          //   return admin === this.props.userData.user.id
          // })

          // var groupAccess = link.groups.filter(group => {
          //   return userGroups.includes(group.id)
          // })

          // var isExternal = link.externals.filter(externalUser => {
          //   return externalUser === this.props.userData.user.id
          // })

          // if(isAdmin.length > 0
          //   || isUser.length > 0
          //   || groupAccess.length > 0
          //   || isExternal.length > 0)
          //   accessGranted = true

          link.accessGranted = true

          let adminsData: any = [];
          let usersData: any = [];
          let groupsData: any = [];
          let externalsData: any = [];

          link.admins && link.admins.forEach(admin => {
              var adminData = repoUsers.filter(repoUser => {
              return repoUser.user.id === admin
              })[0]
              if(adminData) adminsData.push(adminData)
          })

          link.users && link.users.forEach(user => {
              var userData = repoUsers.filter(repoUser => {
              return repoUser.user.id === user.id
              })[0]
              if(userData) usersData.push(userData)
          })

          link.groups && link.groups.forEach(group => {
              var groupData = repoUsers.groups.filter(repoGroup => {
              return repoGroup.id === group.id
              })[0]
              if(groupData) groupsData.push(groupData)
          })

          link.externals && link.externals.forEach(user => {
              var userData = repoUsers.filter(repoUser => {
              return repoUser.user.id === user.id
              })[0]
              if(userData) externalsData.push(userData)
          })

          link.adminsData = adminsData
          link.usersData = usersData
          link.groupsData = groupsData
          link.externalsData = externalsData

          var bookmark: any = link.bookmarks && link.bookmarks.filter(bookmarkData => {
              return bookmarkData.user_id === this.props.userData.user.id
          })[0] !== undefined;

          link.bookmark = bookmark

          return link
        })

        links = links.sort((a,b) => {
          if(a.name.toLowerCase() < b.name.toLowerCase())
              return -1;
          if(b.name.toLowerCase() < a.name.toLowerCase())
              return 1;
  
          return 0;
        });

        this._expandFolderWhileBrowsing(item.id, links)
      }).catch(error => {
        console.log(error)
      })
    }
  }

  private filterFolders(e:any=null, index = this.state.searchIndex) {
    if (e) e.preventDefault();
    if (this.state.folderFilter.length > 0) {
      const lastItems = this.state.items
      let searchString:string;
      if (index === 1) {
        this.setState({items: [], isLoading: true, loadMoreFolders:false, filter: ""})
        searchString = "?name="+this.state.folderFilter+"&parent="+((this.state.url && this.state.url.replace('recents-', '').replace('bookmarks-', '')) || this.props.repoData[0].id)+"&searchType="+ (this.state.searchOptions === 'searchFolders' ? 'FOLDER' : 'FILE')+"&searchInside=true&page="+1
      }
      else {
        this.setState({loadingMoreFolders:true})
        searchString = "?name="+this.state.currentFilter+"&parent="+((this.state.url && this.state.url.replace('recents-', '').replace('bookmarks-', ''))   || this.props.repoData[0].id)+"&searchType="+ (this.state.currentType === 'searchFolders' ? 'FOLDER' : 'FILE')+"&searchInside=true&page="+index
      }
      userService.search(searchString).then((response)=>{
        var folders = response.data.folders
        var documents = response.data.documents;

        if (documents || folders) {
          documents = documents && documents.filter(doc => {
            return !doc.trash
          })
          var searchResults: any = [];
          //this._isMounted && this.setState({items: []});

          if (this._isMounted && folders && folders.length) {
            var userGroups = this.props.userData.userGroups.map(userGroup => {
              return userGroup.id
            })

            folders = folders.filter(folder => {
              return folder.path_id
            })
            
            for (let i = 0; i < folders.length; i++) {
              let folder = folders[i];
              folder.kind = "dir"
              const fileType = this._getFileType(folder, "");
              let dateCreated = new Date(folder.created_at).toString();
              let dateDeleted = folder.deleted_at && folder.deleted_at !== 0 ? new Date(folder.deleted_at).toString(): null;
              let dateModified = new Date(folder.modified_at).toString()

              let fullPath = folder.pathNames;
              if(!fullPath) {
                let pathInfo = this.getPathInfo(folder.parent_id)
                fullPath = pathInfo && pathInfo.map(item => {
                  return item.text
                })
                fullPath = fullPath && fullPath.join("/")
                fullPath = "/" + fullPath
              }
              folder.path = fullPath;

              let bookmark: any = folder.bookmarks && folder.bookmarks.filter(bookmarkData => {
                return bookmarkData.user_id === this.props.userData.user.id
              })[0] !== undefined;

              var accessGranted: boolean = false;

              var isUser = folder.users.filter(user => {
                return user.id === this.props.userData.user.id
              })

              var isAdmin = folder.admins.filter(admin => {
                return admin === this.props.userData.user.id
              })

              var groupAccess = folder.groups.filter(group => {
                return userGroups.includes(group.id)
              })

              var isExternal = folder.externals.filter(externalUser => {
                return externalUser === this.props.userData.user.id
              })

              if(isAdmin.length > 0
                || isUser.length > 0
                || groupAccess.length > 0
                || isExternal.length > 0)
                accessGranted = true

              searchResults.push({
                key: folder.id,
                id: folder.id,
                accessGranted: accessGranted,
                name: folder.name,
                fileName: folder.name,
                repo: folder.repo,
                parentId: folder.parent_id,
                path: folder.path,
                pathIds: folder.pathIds,
                pathNames: folder.pathNames,
                isFolderInPath: folder.isFolderInPath,
                icon: fileType.icon,
                fileType: fileType.docType,
                fileExtension: "folder",
                createdBy: folder.created_by,
                modifiedBy: folder.modified_by,
                dateCreated: new Date(dateCreated),
                dateDeleted: dateDeleted ? new Date(dateDeleted) : null,
                dateModified: new Date(dateModified),
                dateModifiedValue: new Date(dateModified),
                fileSize: 0,
                fileSizeRaw: 0,
                bookmark: bookmark,
                highlights: [],
                scoring: 100,
                admins: folder.admins,
                adminsData: [],
                users: folder.users,
                usersData: [],
                externals: folder.externals,
                externalsData: [],
                groups: folder.groups,
                revision: "HEAD",
                kind: folder.kind,
                tags: folder.tags
              });
            }
          }

          if (this._isMounted && documents && documents.length) {
            for (let i = 0; i < documents.length; i++) {
              let doc = documents[i];
              var fileExtension = doc.type;
              const fileType = this._getFileType(doc, fileExtension);
              let dateCreated= new Date(doc.created_at).toString();
              let dateModified = new Date(doc.modified_at).toString();

              let fullPath = doc.pathNames;
              if(!fullPath) {
                let pathInfo = this.getPathInfo(doc.parent_id)
                fullPath = pathInfo && pathInfo.map(item => {
                  return item.text
                })
                fullPath = fullPath && fullPath.join("/")
                fullPath = "/" + fullPath
              }
              
              var fileSize = "";
              if (doc.size) {
                fileSize = getReadableFileSizeString(doc.size)
              }

              var tags = doc.tags;

              if (tags) {
                tags.sort((a, b) => {
                    if(a.toLowerCase() < b.toLowerCase()) { return -1; }
                    if(a.toLowerCase() > b.toLowerCase()) { return 1; }
                    return 0;
                })
              } else {
                tags = [];
              }

              doc.revisions.map(revision => {
                return revision.version = revision.major + "." + revision.minor
              })

              var currentVersion = doc.activeMajor + "." + doc.activeMinor + (doc.draft ? " Draft" : "")

              var revisions = doc.revisions.filter(revision => {
                return !revision.deleted
              });

              revisions.sort((a, b) => {
                  if(a.timestamp > b.timestamp) { return -1; }
                  if(a.timestamp < b.timestamp) { return 1; }
                  return 0;
              })

              for (let i = 0; i < revisions.length; i++) {
                var version = revisions[i];
                version.isActiveRevision = version.fileId === doc.activeRevision;
              }

              if (doc.draft) {
                revisions.unshift({
                  author: doc.modified_by,
                  fileId: doc.id + "-draft",
                  message: "Draft",
                  previousVersion: null,
                  size: doc.size,
                  timestamp: doc.modified_at,
                  version: "draft"
                })
              }

              let bookmark: any = doc.bookmarks && doc.bookmarks.filter(bookmarkData => {
                return bookmarkData.user_id === this.props.userData.user.id
              })[0] !== undefined;

              searchResults.push({
                key: doc.id,
                id: doc.id,
                hash: doc.hash,
                fileName: doc.name,
                name: doc.title,
                fileExtension: doc.type,
                repo: doc.repo,
                path: fullPath,
                pathIds: doc.pathIds,
                pathNames: doc.pathNames,
                parent_id: doc.parent_id,
                parentId: doc.parent_id,
                icon: fileType.icon,
                fileType: fileType.docType,
                createdBy: doc.created_by,
                modifiedBy: doc.modified_by,
                dateCreated: new Date(dateCreated),
                dateModified: new Date(dateModified),
                dateModifiedValue: new Date(dateModified),
                fileSize: fileSize,
                fileSizeRaw: doc.size,
                tags: tags,
                bookmark: bookmark,
                revisions: revisions,
                activeMajor: doc.activeMajor,
                activeMinor: doc.activeMinor,
                highlights: doc.highlights,
                scoring: doc.scoring,
                activeRevisionId:  doc.activeRevision,
                currentVersion: currentVersion,
                revisionsCount: doc.revisionsCount,
                draft: doc.draft,
                lock: doc.lock,
                lockInstanceId: doc.lockInstanceId,
                wopiLock: doc.wopiLock,
                kind: "file",
                trash: doc.trash
              });
            };
          }

          searchResults.sort(function(a, b){
            if(a.scoring > b.scoring) { return -1; }
            if(a.scoring < b.scoring) { return 1; }
            return 0;
          })
          var isDuplicate = (entry, arr)  => {
            return arr.some(x => (entry.PID === x.PID) && (entry.Week === x.Week))
          }

          if(index === 1) {
            var { history, historyIndex } = this.state;
            history = history.slice(0,historyIndex+1);
            historyIndex += 1;
            history.push(this.state.url)
            this._isMounted && this.setState({url: this.state.url, history: history, historyIndex: historyIndex, items: searchResults, isLoading: false, searching: true, loadMoreFolders: ((folders && folders.length === 50) || (documents && documents.length === 50)), loadingMoreFolders: false, currentType: this.state.searchOptions, currentFilter: this.state.folderFilter, searchInfolder: this.state.breadcrumbPath[this.state.breadcrumbPath.length-1].text,folderFilter: this.state.folderFilter, loadingFolders: false, searchIndex: 2})
          } else {
            let newArray: any = searchResults
            for (const entry of searchResults) {
              if (!isDuplicate(entry, newArray)) { newArray.push(entry) }
            }

            this._isMounted && this.setState({items: this.state.items.concat(newArray), isLoading: false, searching: true, loadMoreFolders: ((folders && folders.length === 50) || (documents && documents.length === 50)), loadingMoreFolders: false, searchInfolder: this.state.breadcrumbPath[this.state.breadcrumbPath.length-1].text,folderFilter: this.state.folderFilter, loadingFolders: false, searchIndex: this.state.searchIndex + 1})
          }
        }
      }).catch((error)=>{
        console.log(error)
        this.setState({items: lastItems, isLoading: false, searching: false, loadMoreFolders: false})
        this._isMounted && this.setState({loadingFolders: false, loadMoreFolders: false});
      })
    }
  }

  private _addChildrenTree(item: INavLink | undefined, levels: number, expandFolders: any) {
    setTimeout(() => {
      if(item && ((item.isExpanded && (item.type === "repo" || item.type === "folder")) || item.type ==="templates")) {
        userService.listFoldersLevels(item.id, levels).then((response: any) => {
            var folderTree: any = this.state.nodesTree;
            var repoUsers = this.props.repoUsers;
            var links = response.data

            links = links.map(link => {
              var isExpanded = expandFolders && expandFolders.some(expandFolder => expandFolder === link.id || false)

              link.key = link.id
              link.isExpanded = isExpanded
              link.state = {expanded: isExpanded}
              link.parentId = link.parent_id
              link.type = "folder"
              link.fileName = link.name
              link.fileType = "dir"
              link.accessGranted = true
  
              let adminsData: any = [];
              let usersData: any = [];
              let groupsData: any = [];
              let externalsData: any = [];
  
              link.admins && link.admins.forEach(admin => {
                  var adminData = repoUsers.filter(repoUser => {
                  return repoUser.user.id === admin
                  })[0]
                  if(adminData) adminsData.push(adminData)
              })
  
              link.users && link.users.forEach(user => {
                  var userData = repoUsers.filter(repoUser => {
                  return repoUser.user.id === user.id
                  })[0]
                  if(userData) usersData.push(userData)
              })
  
              link.groups && link.groups.forEach(group => {
                  var groupData = repoUsers.groups.filter(repoGroup => {
                  return repoGroup.id === group.id
                  })[0]
                  if(groupData) groupsData.push(groupData)
              })
  
              link.externals && link.externals.forEach(user => {
                  var userData = repoUsers.filter(repoUser => {
                  return repoUser.user.id === user.id
                  })[0]
                  if(userData) externalsData.push(userData)
              })
  
              link.adminsData = adminsData
              link.usersData = usersData
              link.groupsData = groupsData
              link.externalsData = externalsData
  
              var bookmark: any = link.bookmarks && link.bookmarks.filter(bookmarkData => {
                  return bookmarkData.user_id === this.props.userData.user.id
              })[0] !== undefined;
              link.bookmark = bookmark
  
              return link
            })
  
            function searchRecursive(data, id) {
              let found = typeof data === "object" && data.find(d => d.id === id && (d.type === "repo" || d.type === "folder" || d.type === "templates"));
              if (!found) {
                let i = 0;
                while(!found && i < data.length) {
                  if (data[i].children && data[i].children.length) {
                    found = searchRecursive(data[i].children, id);
                  }
                  i++;
                }
              }
              return found;
            }
        
            var prevFolder: any = searchRecursive(folderTree, item.id);

            if(prevFolder) {
              if(prevFolder.type === "templates") {
                var templateFolders = links.filter(link => {
                  return link.parent_id === prevFolder.id
                })

                if(prevFolder.links.length === 0) {
                  prevFolder.links = templateFolders
                }
              }
              var immediateLinks = links
              var allChildrenLinks: any = [];
              var filteredLinks = immediateLinks.filter(link => {
                return link.parent_id === prevFolder.id
              })
              prevFolder.children = filteredLinks.sort((a,b) => {
                if((a.name && a.name.toLowerCase()) < (b.name && b.name.toLowerCase()))
                    return -1;
                if((b.name && b.name.toLowerCase()) < (a.name && a.name.toLowerCase()))
                    return 1;
        
                return 0;
              });

              for (let i = 0; i < immediateLinks.length; i++) {
                let immediateLink = searchRecursive(links, immediateLinks[i].id);

                if(immediateLink) {
                  let immediateIsExpanded = expandFolders && expandFolders.some(expandFolder => expandFolder === immediateLink.id || false)
                  immediateLink.isExpanded = immediateIsExpanded
                  immediateLink.state = {expanded: immediateIsExpanded}
                }

                var childrenLinks = links.filter(link => {
                  return link.parentId === (immediateLink && immediateLink.id)
                })

                childrenLinks.links = childrenLinks.sort((a,b) => {
                  if((a.name && a.name.toLowerCase()) < (b.name && b.name.toLowerCase()))
                      return -1;
                  if((b.name && b.name.toLowerCase()) < (a.name && a.name.toLowerCase()))
                      return 1;
          
                  return 0;
                });

                allChildrenLinks.push(childrenLinks)

                immediateLink && (immediateLink.children = childrenLinks)
              }
  
              var linksToPush = links
              if(linksToPush[0] !== undefined) {
                this.setState({foldersList: this.state.foldersList.concat(links)})
              }

              this._isMounted && this.setState({
                folderTree: folderTree,
              }, () => {
                var focusEl = document.getElementsByClassName("scrolledFolder")[0] && document.getElementsByClassName("scrolledFolder")[0].parentElement
                if(focusEl) focusEl = focusEl.parentElement
                if(focusEl) focusEl = focusEl.parentElement

                if(focusEl) {
                  focusEl.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'nearest'
                  });
                }
              })
            }
        }).catch(error => {
          console.log(error)
        })
      }
    }, 100);
  }

  private _expandFolder(id, levels, expandFolders, focusEl?: any) {
    function searchRecursive(data, id) {
      let found = data.find(d => d.key === id && (d.type === "repo" || d.type === "folder" || d.type === "template"));
      if (!found) {
        let i = 0;
        while(!found && i < data.length) {
          if (data[i].children) {
            found = searchRecursive(data[i].children, id);
          }
          i++;
        }
      }
      return found;
    }

    var folder: any = searchRecursive(this.state.nodesTree, id);
    
    if(folder) {
      folder.isExpanded = true
      folder.state = {expanded: true}
      this.setState({folderTreeFocusId: focusEl})
      this._addChildrenTree(folder, levels, expandFolders)
      var parentFolder: any = searchRecursive(this.state.nodesTree, folder.parent_id || folder.parentId);
      if(parentFolder && parentFolder.id) {
        parentFolder.isExpanded = true
        parentFolder.state = {expanded: true}
      }
    }
  }

  private _findInFolderTree = (item) => {
    var isRepo = this.props.userData.repositoryDetailsList.filter(repo => {
      return repo.repository.id === (item.parent_id || item.parentId)
    })[0]

    var folderTreeFocusId = item.fileType === "dir" ? item.id : item.parentId;

    if(isRepo) {
      this._expandFolder(isRepo.id, 2, [isRepo.id], folderTreeFocusId)
    } else {
      var parentId = item.parent_id || item.parentId;
      parentId && userService.getPathFolders(parentId).then(response => {
        var pathFolders: any = []

        const sortPathFolders = (item) => {
          pathFolders.push(item)

          var child = response.data.filter(folder => {
            return folder.parentId === item.id
          })[0]

          if(child) {
            sortPathFolders(child)
          }
        }

        var repo = response.data.filter(item => !item.parentId)[0]
        if(repo) sortPathFolders(repo)
        
        var firstFolder = pathFolders[1]

        if(firstFolder) {
          var levels = pathFolders.length;
          var expandFolders = response.data.map(folder => { return folder.id })

          this._expandFolder(firstFolder.id, levels, expandFolders, folderTreeFocusId)
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
  
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

function _copyAndSortName<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  return items.slice(0).sort((a,b) => {
    if (!isSortedDescending) {
      if(a["fileType"] < b["fileType"])
          return -1;
      if(b["fileType"] < a["fileType"])
          return 1;

      if(a["name"].toLowerCase() < b["name"].toLowerCase())
          return -1;
      if(b["name"].toLowerCase() < a["name"].toLowerCase())
          return 1;
      return 1;
    } else {
      if(a["fileType"] > b["fileType"])
          return -1;
      if(b["fileType"] > a["fileType"])
          return 1;

      if(a["name"].toLowerCase() > b["name"].toLowerCase())
          return -1;
      if(b["name"].toLowerCase() > a["name"].toLowerCase())
          return 1;
      return 1;
    }

  });
}

function onRenderDetailsHeader(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
      {defaultRender!({
        ...props
      })}
    </Sticky>
  );
}
  