import * as React from 'react';
import ReactDOM from 'react-dom';
import { history } from '../_helpers';
import UAParser from 'ua-parser-js';
import uuid from 'uuid-random';
import Moment from 'moment';
import "moment/min/locales";
import { TextField } from '@fluentui/react/lib/TextField';
import { Panel } from '@fluentui/react/lib/Panel';
import { Text } from '@fluentui/react/lib/Text';
import { Link } from '@fluentui/react/lib/Link';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { PivotItem, IPivotItemProps, Pivot } from '@fluentui/react/lib/Pivot';
import { IRenderFunction } from '@fluentui/react/lib/Utilities';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { ChoiceGroup } from '@fluentui/react/lib/ChoiceGroup';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import { Dropdown, IDropdownOption, DropdownMenuItemType } from '@fluentui/react/lib/Dropdown';
import { Icon } from '@fluentui/react/lib/Icon';
import { getFileTypeIconProps, FileIconType } from '@fluentui/react-file-type-icons';
import { ActionButton, IconButton, IIconProps, MessageBar, MessageBarType, Callout, DirectionalHint, SwatchColorPicker, ComboBox, Modal, ResponsiveMode } from '@fluentui/react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import { ContextualMenu, ContextualMenuItemType, IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';

import { userService } from '../_services';
import { FolderRole } from './FolderRole';
import { ActionsPermissions } from './ActionsPermissions';
import { TemplatesBrowser } from './TemplatesBrowser';
import { invalidChars } from '../_data/invalid-chars'
import { FilePicker } from './FilePicker';

import isElectron from 'is-electron';
import i18n from "i18next";
import Cookies from 'js-cookie'
import sanitizeSVG from '../sanitizerSVG';

const detectMob = () => {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}

function pickTextColorBasedOnBgColor(bgColor, lightColor, darkColor) {
  var color = bgColor.replace('rgb(', '').replace(')', '').split(',');
  var r = color[0]
  var g = color[1]
  var b = color[2]
  return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
    darkColor : lightColor;
}

const pickerColors = [
  { id: 'rgb(169, 169, 169,.7)', label: 'grey', color: 'rgb(169, 169, 169,.7)' },
  { id: 'rgb(230, 25, 75,.7)', label: 'red', color: 'rgb(230, 25, 75,.7)' },
  { id: 'rgb(245, 130, 49,.7)', label: 'orange', color: 'rgb(245, 130, 49,.7)' },
  { id: 'rgb(255, 225, 25,.7)', label: 'yellow', color: 'rgb(255, 225, 25,.7)' },
  { id: 'rgb(191, 239, 69,.7)', label: 'lime', color: 'rgb(191, 239, 69,.7)' },
  { id: 'rgb(60, 180, 75,.7)', label: 'green', color: 'rgb(60, 180, 75,.7)' },
  { id: 'rgb(66, 212, 244,.7)', label: 'cyan', color: 'rgb(66, 212, 244,.7)' },
  { id: 'rgb(67, 99, 216,.7)', label: 'blue', color: 'rgb(67, 99, 216,.7)' },
  { id: 'rgb(145, 30, 180,.7)', label: 'purple', color: 'rgb(145, 30, 180,.7)' },
  { id: 'rgb(240, 50, 230,.7)', label: 'magenta', color: 'rgb(240, 50, 230,.7)' },
];

export interface Props {
  match: {
    url: string
  }
}

export interface HeaderState {
  parentRole: number;
  folderRole: number;
  breadcrumbPath: any[];
  breadcrumbRecentFiles: any[];
  breadcrumbSearch: string;
  newSearchTerms: string;
  searchOptions: string;
  goBack: number;
  selFile: any;
  isTrash: boolean;
  hideCreateFolderDialog: boolean;
  newFolder: string;
  newFolderParent: string;
  newFolderPermissions: boolean;
  newFolderTags: string;
  newFolderWarning: string;
  savingFolder: boolean;
  showTemplatesDialog: boolean;
  creatingOfficeDoc: boolean;
  openOfficeDocAfterCreating: boolean;
  repeatedNameItem: any;
  showDownloadsCallout: boolean;
  showDownloadsPanel: boolean;
  showUploadsCallout: boolean;
  showImportPanel: boolean;
  uploadsCount: any;
  gettingUploadsCount: boolean;
  gettingProcessingUploads: boolean;
  gettingQueuedUploads: boolean;
  gettingSuccessUploads: boolean;
  gettingErrorUploads: boolean;
  uploadsQueue: any;
  pendingUploadsQueue: any;
  successUploadsQueue: any;
  errorUploadsQueue: any;
  activeUploadsTab: string;
  pendingUploads: any;
  importedFilesWithError: any;
  confirmingPendingUploads: boolean;
  deletingItem: boolean;
  restoringItem: boolean;
  showTagBulkDialog: boolean;
  newTag: any;
  savingTag: boolean;
  comparingDocs: boolean;
  compareDocs: any;
  showCompareDocs: boolean;
  confirmCompareDocs: boolean;
  mergedDoc: any;
  syncStatus: any;
  showRightPanel: boolean;
  errorMessage: string;
  newMenu: boolean;
  newFileTags: string;
  newFileComments: string;
  parentName:string;
  showNewTagDialog: boolean;
  showEditTagDialog: boolean;
  showNewSingleTagDialog: boolean
  showSingleTagDialog: boolean;
  tagCreated: string;
  tagName: string;
  tagNameWarning: string;
  tagColor: string;
  tagDescription: string;
  tagDescriptionWarning: string;
  tagOptions: any;
  tagOptionsEdit: any;
  tagType: string;
  showColorPick: boolean;
  colorIndex: number;
  tagCategoryId: string;
  tagsList: any;
  isCascade: boolean;
  originalTags: any;
  inheritTags: string;
  filterByNameText: string;
  filterByTypeText: string;
  compareExtension: any;
  savedPdf: boolean;
  savingPdf: boolean;
  savedWord: boolean
  savingWord: boolean;
  appendWord: boolean;
  appendingWord: boolean;
  toAppend: boolean;
  appendingFileAsVersion: boolean;
  showContextualMenu: boolean;
  contextMenuId: string;
  appsPanel: boolean;
}

export class Header extends React.Component<Props, HeaderState> {
  private _isMounted: boolean;
  private downloadsButtonRef:any = React.createRef<HTMLDivElement>();
  private uploadsButtonRef:any = React.createRef<HTMLDivElement>();
  private searchRef:any = React.createRef();
  private _windowWidth: number;
  private _askUsertags: boolean;
  private _sameChoiceForAll: boolean;
  private _sameForAllOption: any;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this._windowWidth = window.innerWidth;
    this._askUsertags = true;
    this._sameChoiceForAll = false;
    this._sameForAllOption = null;

    this.props = props

    this.state = {
      parentRole: 10,
      folderRole: 10,
      breadcrumbPath: props.breadcrumbPath,
      breadcrumbRecentFiles: props.breadcrumbRecentFiles,
      breadcrumbSearch: i18n.t('app:search'),
      newSearchTerms: "",
      searchOptions: "searchByName",
      goBack: 0,
      selFile: props.selFile,
      isTrash: false,
      hideCreateFolderDialog: true,
      newFolder: '',
      newFolderParent: '',
      newFolderPermissions: true,
      newFolderTags: '',
      newFolderWarning: "",
      savingFolder: false,
      showTemplatesDialog: false,
      creatingOfficeDoc: false,
      openOfficeDocAfterCreating: false,
      repeatedNameItem: null,
      showDownloadsCallout: false,
      showDownloadsPanel: false,
      showUploadsCallout: false,
      showImportPanel: false,
      uploadsCount: [{processing: 0, queued: 0, success: 0, error: 0}],
      gettingUploadsCount: false,
      gettingProcessingUploads: false,
      gettingQueuedUploads: false,
      gettingSuccessUploads: false,
      gettingErrorUploads: false,
      uploadsQueue: [],
      pendingUploadsQueue: [],
      successUploadsQueue: [],
      errorUploadsQueue: [],
      activeUploadsTab: "processing",
      pendingUploads: [],
      importedFilesWithError: [],
      confirmingPendingUploads: false,
      deletingItem: false,
      restoringItem: false,
      showTagBulkDialog: false,
      newTag: [],
      savingTag: false,
      comparingDocs: false,
      compareDocs: null,
      showCompareDocs: false,
      confirmCompareDocs: false,
      mergedDoc: null,
      syncStatus: props.syncStatus,
      showRightPanel: props.showRightPanel,
      errorMessage: "",
      newMenu: false,
      newFileTags: '',
      newFileComments: '',
      parentName: '',
      showNewTagDialog: false,
      showEditTagDialog: false,
      showNewSingleTagDialog: false,
      showSingleTagDialog: false,
      tagCreated: '',
      tagName: '',
      tagNameWarning: '',
      tagColor: '',
      tagDescription: '',
      tagDescriptionWarning: '',
      tagOptions: [],
      tagOptionsEdit: [],
      tagType: 'SINGLE_SELECT',
      showColorPick: false,
      colorIndex: 0,
      tagCategoryId: '',
      tagsList: null,
      isCascade: false,
      originalTags: null,
      inheritTags: "KEEP_NEW",
      filterByNameText: "",
      filterByTypeText: "",
      compareExtension: "both",
      savedPdf: false,
      savingPdf: false,
      savedWord: false,
      savingWord: false,
      appendWord: false,
      appendingWord: false,
      appendingFileAsVersion: false,
      toAppend: false,
      showContextualMenu: false,
      contextMenuId: '',
      appsPanel: false
    };
  }

  //pendingUploadsIntervalId = 0;

  public componentDidMount() {
    this._isMounted = true;
    this._isMounted && this._updateUploadsInfo();

    if(this.props.folderId && this.props.userData && this.props.foldersList) {
      this.getParentRole(this.props.folderId, this.props.userData, this.props.foldersList)
    }

    this._isMounted && this.setState({isTrash: false}, () => {
      this._getBreadcrumbItems();
      if(this.props.searchType === "fullSearch") this._getSearchBreadcrumb()
    })

    document.addEventListener("keydown", this._handleKeyDown);
    window.addEventListener('resize', this._onWindowResize);
  }

  public componentDidUpdate(prevProps, prevState) {
    if(this.props.folderId !== prevProps.folderId
      || this.props.userData !== prevProps.userData
      || this.props.foldersList !== prevProps.foldersList) {
      if(this.props.folderId && this.props.userData && this.props.foldersList) {
        this.getParentRole(this.props.folderId, this.props.userData, this.props.foldersList)
      }
    }

    if(this.props.pusher !== prevProps.pusher) {
      if(this.props.pusher) this._newPusherMessage(this.props.pusher)
    }
    
    if(this.props.appPlatform === "webapp" && (this.props.isOnline !== prevProps.isOnline)) {
      console.log("Resume uploads")
      this._startUploadsWebapp()
    }

    if(this.state.showImportPanel !== prevState.showImportPanel) {
      if(this.state.showImportPanel) this._isMounted && this._updateUploadsInfo()
    }

    if(this.props.folderList !== prevProps.folderList
      || this.props.fileExplorerType !== prevProps.fileExplorerType
      || this.props.foldersList !== prevProps.foldersList) {
      this._isMounted && this.setState({isTrash: false}, () => {
        this._getBreadcrumbItems();
        if(this.props.searchType === "fullSearch") this._getSearchBreadcrumb()
      })
    }

    if(this.props.searchQuery !== prevProps.searchQuery && this.props.searchType === "fullSearch") {
      this._getSearchBreadcrumb()
    }

    if(this.props.breadcrumbPath !== prevProps.breadcrumbPath) {
      this._isMounted && this.setState({breadcrumbPath: this.props.breadcrumbPath})
    }

    if(this.props.breadcrumbRecentFiles !== prevProps.breadcrumbRecentFiles) {
      this._isMounted && this.setState({breadcrumbRecentFiles: this.props.breadcrumbRecentFiles})
    }

    if(this.props.selFile !== prevProps.selFile) {
      var selFile = this.props.selFile

      if(selFile) {
        var permissionsId = selFile.fileType === "dir" ? selFile.id : selFile.parent_id || selFile.parentId
        if(permissionsId && this.props.userData) {
          this.getFolderRole(permissionsId, this.props.userData, this.props.foldersList || [])
        }
      }

      this._isMounted && this.setState({selFile: selFile})
    }

    if(this.state.activeUploadsTab !== prevState.activeUploadsTab) {
      let tab = this.state.activeUploadsTab
      if(tab === "processing") this._getUploadsQueue()
      else if (tab === "queued") this._getPendingUploads()
      else if (tab === "success") this._getSuccessUploads()
      else if (tab === "error") this._getErrorUploads()
    }

    if(this.props.uploadsQueue !== prevProps.uploadsQueue) {
      console.log("UPLOAD QUEUE")
      this._updateUploadsInfo();
    }

    if(this.props.socket !== prevProps.socket) {
      if(this.props.socket) this._newSocketMessage(this.props.socket)
    }

    if(this.props.syncStatus !== prevProps.syncStatus) {
      this._isMounted && this.setState({syncStatus: this.props.syncStatus})
    }

    if(this.props.showRightPanel !== prevProps.showRightPanel) {
      this._isMounted && this.setState({showRightPanel: this.props.showRightPanel})
    }
  }

  public componentWillUnmount() {
    localStorage.setItem("uploadsQueue", JSON.stringify(this.state.uploadsQueue))
    this._isMounted = false;
    document.removeEventListener("keydown", this._handleKeyDown, false);
    //clearInterval(this.pendingUploadsIntervalId)
  }

  private getParentRole = async(folderId, userData, foldersList) => {
    var role = await FolderRole(folderId, userData, foldersList)
    this._isMounted && this.setState({parentRole: role})
  }

  private getFolderRole = async(folderId, userData, foldersList) => {
    var role = await FolderRole(folderId, userData, foldersList)
    this._isMounted && this.setState({folderRole: role})
  }

  private _newSocketMessage(info) {
    var message = info && info.message;
    if(message) {
      if(message === "startUploadsQueue") {
        this._updateUploadsInfo();
      }

      if(message === "updateUploadsInfo") {
        this._updateUploadsInfo();
      }
    }
  }

  private _handleKeyDown = (event) => {
    var parser = new UAParser.UAParser();
    var os = parser.getResult().os;
    if (((os.name === "Windows" && (event.ctrlKey || event.altKey) && event.keyCode === 70)
      || (os.name === "Mac OS" && (event.metaKey || event.altKey)  && event.keyCode === 70))
      && (this.props.fileExplorerType === "fileExplorer"
      || this.props.fileExplorerType === "recentFiles")) {
      event.preventDefault();
      this.searchRef.focus();
    }
  }

  private _onWindowResize = () => {
    if (window.innerWidth <= 980 && window.innerWidth < this._windowWidth) {
      if (this.props.callbackFunction) {
        this.props.callbackFunction({showRightPanel: false})
      } 
    }
    this._windowWidth = window.innerWidth;
  }

  private _startUploadsWebapp() {
    var size = this.uploadsLimit - this.uploadingFilesCount
    userService.startUploadsWebapp(size).then(response => {
      var array = response.data.filter (item => {
        return item.language !== "query"
      })

      array = array.map(item => {
        item.uploadStatus = item.status.toLowerCase()
        return item
      })

      console.log("Start uploading", array)

      for (let i = 0; i < array.length; i++) {
        var file = array[i]
        this._uploadFile(file)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  private _getUploadsQueue() {
    if(!this.state.gettingProcessingUploads) {
      this._isMounted && this.setState({gettingProcessingUploads: true})

      userService.getUploadsQueue().then(response => {
        var array = response.data.filter (item => {
          return item.language !== "query"
        })

        array = array.map(item => {
          item.uploadStatus = "processing"
          return item
        })

        this._isMounted && this.setState({
          uploadsQueue: array,
          gettingProcessingUploads: false
        }, () => {
          if(this.state.showImportPanel && this.state.activeUploadsTab === "processing" && (array.length > 0 || this.state.uploadsCount.processing > 0 || this.state.uploadsCount.queued > 0)) {
            setTimeout(() => {
              this._updateUploadsInfo()
            }, 10000)
          }
        })
      }).catch(error => {
        console.log(error)
        this._isMounted && this.setState({gettingProcessingUploads: false})
      })
    }
  }

  private _getPendingUploads() {
    if(!this.state.gettingQueuedUploads) {
      this._isMounted && this.setState({gettingQueuedUploads: true})

      userService.getPendingUploads().then(response => {
        var array = response.data.filter (item => {
          return item.language !== "query"
        })

        array = array.map(item => {
          item.uploadStatus = item.status.toLowerCase()
          return item
        })

        this._isMounted && this.setState({
          pendingUploadsQueue: array,
          gettingQueuedUploads: false
        }, () => {
          if(array.length > 0 || this.state.uploadsCount.processing > 0 || this.state.uploadsCount.queued > 0) {
            setTimeout(() => {
              this._updateUploadsInfo()
            }, 10000)
          }
        })
      }).catch(error => {
        console.log(error)
        this._isMounted && this.setState({gettingQueuedUploads: false})
      })
    }
  }

  private _getSuccessUploads() {
    if(!this.state.gettingSuccessUploads) {
      this._isMounted && this.setState({gettingSuccessUploads: true})

      userService.getSuccessUploads().then(response => {
        var array = response.data.filter (item => {
          return item.language !== "query"
        })

        array = array.map(item => {
          item.uploadStatus = item.status.toLowerCase()
          return item
        })

        this._isMounted && this.setState({
          successUploadsQueue: array,
          gettingSuccessUploads: false
        })
      }).catch(error => {
        console.log(error)
        this._isMounted && this.setState({gettingSuccessUploads: false})
      })
    }
  }

  private _getErrorUploads() {
    if(!this.state.gettingErrorUploads) {
      this._isMounted && this.setState({gettingErrorUploads: true})

      userService.getErrorUploads().then(response => {
        var array = response.data.filter (item => {
          return item.language !== "query"
        })

        array = array.map(item => {
          item.uploadStatus = item.status.toLowerCase()
          return item
        })

        this._isMounted && this.setState({
          errorUploadsQueue: array,
          gettingErrorUploads: false
        })
      }).catch(error => {
        console.log(error)
        this._isMounted && this.setState({gettingErrorUploads: false})
      })
    }
  }

  callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  public render() {
    const { showNewTagDialog, showEditTagDialog, showSingleTagDialog, showNewSingleTagDialog, hideCreateFolderDialog, newFolder, newFolderWarning, savingFolder, creatingOfficeDoc, showDownloadsCallout, showDownloadsPanel, showUploadsCallout, showImportPanel, uploadsCount, uploadsQueue, importedFilesWithError, deletingItem, restoringItem, showTagBulkDialog, newTag, savingTag, comparingDocs, compareDocs, showCompareDocs, confirmCompareDocs, mergedDoc, breadcrumbPath, syncStatus, showRightPanel, errorMessage, searchOptions } = this.state;

    const backIcon: IIconProps = { iconName: 'Back' };
    const forwardIcon: IIconProps = { iconName: 'Forward' };
    const downloadIcon: IIconProps = { iconName: 'Download' };
    const uploadIcon: IIconProps = { iconName: 'Upload', className:  importedFilesWithError.length > 0 ? "text-danger" : "" };
    const refreshIcon: IIconProps = { iconName: 'Refresh' };

    var parser = new UAParser.UAParser();
    var os = parser.getResult().os;

    var currentUploadsQueue: any = []

    var navFolder = this.props.foldersList && this.props.foldersList.filter(folder => {
      return folder && folder.id === this.props.folderId
    })[0]

    var searchPlaceholder: string;

    if(searchOptions === "searchByName") searchPlaceholder = i18n.t('app:searchByName');
    else searchPlaceholder = i18n.t('app:searchByContent');



    if(this.state.activeUploadsTab === "queued") currentUploadsQueue = this.state.pendingUploadsQueue
    else if(this.state.activeUploadsTab === "success") currentUploadsQueue = this.state.successUploadsQueue
    else if(this.state.activeUploadsTab === "error") currentUploadsQueue = this.state.errorUploadsQueue
    else currentUploadsQueue = uploadsQueue

    var parentRole:number = this.state.parentRole
    var role:number = this.state.folderRole

    const folderSelected = () => {
      var selection = this.props.fileExplorerRef._selection.getSelection();
      if (selection.length === 0) return true;
      var folders = selection.filter((item: any) => {
        return item.fileType !== "file"
      })
      var folderSelected = folders.length > 0;
      if (folderSelected) return true;
      else return false;
    }

    const docActionItems: ICommandBarItemProps[] = [
      {
        key: 'showLeftPanel',
        title: i18n.t('app:toggleLeftPanel'), 
        iconProps: {
          iconName: 'SidePanel',
        },
        className: 'sidePanel-button',
        onClick: () => this._toggleLeftPanel(),
      },
      {
        key: 'newItem',
        text: i18n.t('app:new'),
        iconProps: { iconName: 'Add' },
        disabled: (!ActionsPermissions("createFolder", parentRole) && !ActionsPermissions("createOfficeDoc", parentRole)) || this.props.fileExplorerType !== "fileExplorer" || this.state.isTrash || (navFolder && !navFolder.accessGranted),
        subMenuProps: {
          items: [
            {
              key: 'createFolder',
              text: i18n.t('app:folder'),
              secondaryText: os.name === "Windows"  ? 'Alt + N' : 'Option + N',
              onRenderIcon: () => { // Provide custom font awesome element
                return <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } />
              },
              className: "newFolderIcon",
              onClick: this._showNewFolderDialog.bind(this, this.props.folderId),
              disabled: !ActionsPermissions("createFolder", parentRole)
            },
            {
              key: 'insertTemplate',
              text: "Template",
              onRenderIcon: () => { // Provide custom font awesome element
                return <Icon {...getFileTypeIconProps({ type: FileIconType.documentsFolder, size: 20, imageFileType: 'svg' }) } />
              },
              className: "newFolderIcon",
              onClick: () => this.setState({showTemplatesDialog: true}),
              disabled: !ActionsPermissions("createFolder", parentRole)
            },
            {
              key: 'newWord',
              text: i18n.t('app:word'),
              iconProps: { iconName: 'WordDocument' },
              className: "wordDoc",
              onClick: this._createOfficeDocument.bind(this, "WORD"),
              disabled: !ActionsPermissions("createOfficeDoc", parentRole)
            },
            {
              key: 'newExcel',
              text: i18n.t('app:excel'),
              iconProps: { iconName: 'excelDocument' },
              className: "excelDoc",
              onClick: this._createOfficeDocument.bind(this, "EXCEL"),
              disabled: !ActionsPermissions("createOfficeDoc", parentRole)
            },
            {
              key: 'newPowerpoint',
              text: i18n.t('app:powerpoint'),
              iconProps: { iconName: 'PowerPointDocument' },
              className: "powerpointDoc",
              onClick: this._createOfficeDocument.bind(this, "POWERPOINT"),
              disabled: !ActionsPermissions("createOfficeDoc", parentRole)
            }
          ],
        },
      },
      {
        key: 'clipboard',
        text: i18n.t('app:clipboard'),
        iconProps: { iconName: 'ClipboardList' },
        disabled: !ActionsPermissions("clipboard", parentRole) && (this.props.searchType !== "fullSearch" || (this._isMounted && this._checkClipboardEnabled())),
        subMenuProps: {
          items: [
            { key: 'HeaderInternal',
              text: i18n.t('app:internal') + i18n.t('app:inSynergy'), 
              itemType: ContextualMenuItemType.Header, 
              style: {fontSize: '14px'},
              iconProps: {style: {width: 0, margin: 0}}
            },
            {
              key: 'cut',
              text: i18n.t('app:cut'),
              secondaryText: os.name === "Windows"  ? 'Alt + X' : 'Option + X',
              iconProps: { iconName: 'Cut' },
              disabled: this._isMounted && this._checkClipboardCutEnabled(),
              onClick: () => this.props.fileExplorerRef._clipboardCut()
            },
            {
              key: 'copy',
              text: i18n.t('app:copy'),
              secondaryText: os.name === "Windows"  ? 'Alt + C' : 'Option + C',
              iconProps: { iconName: 'Copy' },
              disabled: this._isMounted && this._checkClipboardCopyEnabled(),
              onClick: () => this.props.fileExplorerRef._clipboardCopy(),
            },
            {
              key: 'paste',
              text: i18n.t('app:paste'),
              secondaryText: os.name === "Windows"  ? 'Alt + V' : 'Option + V',
              iconProps: { iconName: 'Paste' },
              disabled: this._isMounted && this._checkClipboardPasteEnabled(),
              onClick: () => this.props.fileExplorerRef._clipboardPaste(),
            },
            {
              key: 'HeaderExternal', 
              text: i18n.t('app:external') + ' ' + (os.name === "Windows" ? i18n.t('app:inWindows') : i18n.t('app:inMac')), 
              itemType: this.props.appPlatform !== ("electron" || "openfin") ? undefined : ContextualMenuItemType.Header, 
              style: {fontSize: '14px'},
              iconProps: {style: {width: 0, margin: 0}},
              hidden: this.props.appPlatform !== ("electron" || "openfin")
            },
            {
              key: 'copyOut',
              text: i18n.t('app:copy') + '/' + i18n.t('app:attach'),
              secondaryText: os.name === "Windows"  ? 'Ctrl + C' : 'Cmd + C',
              iconProps: { iconName: 'Copy' },
              disabled: !ActionsPermissions("attachFile", role) || (this._isMounted && this._checkAttachEnabled()),
              hidden: this.props.appPlatform !== ("electron" || "openfin"),
              onClick: () => this.props.fileExplorerRef._attachFile(),
            },
            {
              key: 'pasteOut',
              text: i18n.t('app:paste'),
              secondaryText: os.name === "Windows"  ? 'Ctrl + V' : 'Cmd + V',
              iconProps: { iconName: 'Paste' },
              disabled: this._isMounted && this._checkClipboardPasteEnabled(),
              hidden: this.props.appPlatform !== ("electron" || "openfin"),
              onClick: () => {
                if(isElectron() && window) {
                  const { ipcRenderer } = window.require('electron');
                  ipcRenderer.send('pasteFromClipboard');
                }
              },
            },
          ]
        }
      },
      {
        key: 'upload',
        text: i18n.t('app:upload'),
        iconProps: { iconName: 'Upload' },
        disabled: !ActionsPermissions("upload", parentRole) || this.props.fileExplorerType !== "fileExplorer",
        subMenuProps: {
          items: [
            {
              key: 'uploadFolder',
              text: i18n.t('app:folder'),
              onRenderIcon: () => { // Provide custom font awesome element
                return <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } />
              },
              className: "newFolderIcon",
              onClick: this._addFolder.bind(this)
            },
            {
              key: 'uploadFiles',
              text: i18n.t('app:files'),
              iconProps: { iconName: 'Document' },
              onClick: this._addFiles.bind(this)
            }
          ],
        },
      },
      {
        key: 'share',
        text: i18n.t('app:share'),
        iconProps: { iconName: 'Share' },
        disabled: this._isMounted && this._checkCopyPrivateShortcutEnabled() && this._checkShareEnabled() && this._checkCreatePublicLinkEnabled(),
        subMenuProps: {
          items: [
            {
              key: 'copyPrivateShortcut',
              name: i18n.t('app:copyPrivateShortcutInternal'),
              secondaryText: os.name === "Windows"  ? 'Alt + P' : 'Option + P',
              iconProps: { iconName: 'Link' },
              disabled: this._isMounted && this._checkCopyPrivateShortcutEnabled(),
              onClick: () => this.props.fileExplorerRef._copyPrivateShortcut(this.props.fileExplorerRef._selection.getSelection()[0], "askToOpen"),
            },
            {
              key: 'copyPrivateHyperlink',
              name: i18n.t('app:copyHyperlinkName'),
              iconProps: { iconName: 'Link' },
              disabled: this._isMounted && this._checkCopyPrivateShortcutEnabled(),
              onClick: () => this.props.fileExplorerRef._copyPrivateShortcutName(this.props.fileExplorerRef._selection.getSelection()[0]),
            },
            {
              key: 'sharingOptions',
              text: i18n.t('app:sharingOptions'),
              iconProps: { iconName: 'Share' },
              disabled: !ActionsPermissions("sharingOptions", role) || (this._isMounted && this._checkShareEnabled()),
              onClick: () => this.props.fileExplorerRef._shareFolder(this.props.fileExplorerRef._selection.getSelection()[0]),
            },
            {
              key: 'createPublicLink',
              text: i18n.t('app:createPublicLink'),
              iconProps: { iconName: 'Globe' },
              disabled: !ActionsPermissions("createPublicLink", role) || (this._isMounted && this._checkCreatePublicLinkEnabled()),
              onClick: () => this.props.fileExplorerRef._createPublicLink(this.props.fileExplorerRef._selection.getSelection()),
            },
          ]
        }
      },
      {
        key: 'downloadItems',
        text: i18n.t('app:download'),
        iconProps: { iconName: 'Download' },
        disabled: !ActionsPermissions("downloadItems", role) || (this._isMounted && this._checkDownloadEnabled()),
        onClick: () => this.props.fileExplorerRef._downloadItems(this.props.fileExplorerRef._selection.getSelection()),
      },
      {
        key: 'attachFile',
        text: i18n.t('app:attach'),
        iconProps: { iconName: 'Attach' },
        disabled: !ActionsPermissions("attachFile", role) || (this._isMounted && this._checkAttachEnabled()),
        onClick: () => this.props.fileExplorerRef._attachFile(),
        hidden: this.props.appPlatform !== ("electron" || "openfin")
      },
      {
        key: 'saveAsPdf',
        text: i18n.t('app:pdf'),
        iconProps: { iconName: 'PDF' },
        disabled: !ActionsPermissions("saveAsPdf", role) || (this._isMounted && this._checkSaveAsPdfEnabled()),
        hidden: os.name !== "Windows" || this.props.appPlatform !== ("electron" || "openfin"),
        onClick: () => this.props.fileExplorerRef._saveAsPdf(),
      },
      {
        key: 'append',
        text: i18n.t('app:append'),
        iconProps: { iconName: 'BranchMerge' },
        disabled: !ActionsPermissions("append", role) || (this._isMounted && this._checkAppendVersionEnabled()),
        onClick: () => {this.props.fileExplorerRef._selection && this.props.fileExplorerRef._selection.getSelection().length === 1 ? this._appendVersion() : this._confirmCompareWordDocs(null, null, true)}
      },
      {
        key: 'compare',
        text: i18n.t('app:compare'),
        iconProps: { iconName: 'BranchCompare' },
        disabled: !ActionsPermissions("compare", role) || (this._isMounted && this._checkCompareEnabled()),
        hidden: this.props.appPlatform !== ("electron" || "openfin"),
        onClick: () => this._confirmCompareWordDocs()
      },
      {
        key: 'deleteItem',
        text: i18n.t('app:delete'),
        iconProps: { iconName: 'Delete' },
        disabled: !ActionsPermissions("deleteItem", role) || (this._isMounted && this._checkDeleteEnabled()),
        hidden: this.state.isTrash,
        onClick: () => this._deleteItems(this.props.fileExplorerRef._selection.getSelection()),
      },
      {
        key: 'restore',
        text: i18n.t('app:restore'),
        iconProps: { iconName: 'ArrangeSendToBack' },
        disabled: !ActionsPermissions("restore", role) || (this._isMounted && this._checkDeleteEnabled()),
        hidden: !this.state.isTrash,
        onClick: () => this._restoreItems(),
      },
      {
        key: 'resetSearch',
        text: i18n.t('app:resetSearch'),
        iconProps: { iconName: 'Cancel' },
        hidden: this._isMounted && this.props.searchType !== "fullSearch",
        onClick: this.props.clearSearch,
      }
    ]

    const farItems: ICommandBarItemProps[] = [
      {
        key: 'filter',
        title: i18n.t('app:toggleRightPanel'), 
        iconProps: { iconName: 'SidePanelMirrored' },
        className: 'sidePanel-button',
        onRender: ()=> {
          if ( this.props.fileExplorerType === "fileExplorer"
            || this.props.fileExplorerType === "recentFiles"
            || this.props.fileExplorerType === "openFiles"
            || this.props.fileExplorerType === "bookmarks"
            || this.props.fileExplorerType === "offlineFiles"
            || this.props.headerSection === "search" ) {
            return(
              <div className={"filtersBox content-wrap d-flex flex-row justify-content-center" + (window.innerWidth < 480 ? " noFilterBox" : window.innerWidth < 640 ? " smallFilterBox" : "")}>
                <div className="view-switch">
                  <Dropdown
                    defaultSelectedKey={this.props.fileExplorerView}
                    options={[
                      { key: 'list', text: i18n.t('app:listView'), data: {icon: "List"} },
                      { key: 'thumbnails', text: i18n.t('app:thumbnailsView'), data: {icon: "GridViewMedium"} },
                    ]}
                    dropdownWidth="auto"
                    onChange={ this.handleViewChange }
                    onRenderTitle = {(option: any) => {
                      var selOption = option[0];
                      return (
                        <div className="selViewTitle">
                          <Icon iconName={selOption.data.icon} aria-hidden="true" title={selOption.text} />
                        </div>
                      );
                    }}
                    onRenderOption = {(option: any) => {
                      return (
                        <div className={"selViewOption" + (option.key === this.props.fileExplorerView ? " selOption" : "")}>
                          <div className="check-wrapper">
                            {option.key === this.props.fileExplorerView ?
                              <Icon iconName="CheckMark" title="Selected view" ariaLabel="Selected view" className="check" />
                            : null }
                          </div>

                          {option.data && option.data.icon && (
                            <Icon iconName={option.data.icon} aria-hidden="true" title={option.text} />
                          )}
                          <span>{option.text}</span>
                        </div>
                      );
                    }}
                    calloutProps={{
                      className: "view-switch-callout",
                      directionalHint: DirectionalHint.bottomCenter
                    }} 
                  />
                </div>
                {this.props.fileExplorerType === "fileExplorer"
                || this.props.fileExplorerType === "recentFiles"
                || this.props.fileExplorerType === "openFiles"
                || this.props.fileExplorerType === "bookmarks" ?
                  <>
                  <div className="filter">
                    <SearchBox placeholder={i18n.t('app:filter') +" "+ i18n.t('app:byName')} value={this.state.filterByNameText} onChange={this._onChangeNameFilter} iconProps={{iconName: "Filter"}} />
                  </div>
                  <div className="filterType">
                    <SearchBox placeholder={i18n.t('app:byType')} value={this.state.filterByTypeText} onChange={this._onChangeTypeFilter} styles={{iconContainer: {width: '4px'}}} iconProps={{styles:{root:{width:0, display: 'none'}} }} />
                  </div>
                  </>
                : null }
              </div>
            )
          }
          else {
            return null;
          }
        },
        onClick: () => { this.props.callbackFunction({showRightPanel: !showRightPanel}) },
        hidden: this.props.fileExplorerType !== "fileExplorer"
      },
      {
        key: 'showRightPanel',
        title: i18n.t('app:toggleRightPanel'), 
        iconProps: { iconName: 'SidePanelMirrored' },
        className: 'sidePanel-button-right ',
        buttonStyles: {root:{marginLeft: '28px'}},
        onClick: () => { this.props.callbackFunction({showRightPanel: !showRightPanel}) },
      }
    ]

    const docActionItemsMobile: ICommandBarItemProps[] = [
      {
        key: 'newItem',
        text: i18n.t('app:new'),
        iconProps: { iconName: 'Add' },
        disabled: this.props.fileExplorerType !== "fileExplorer" || this.state.isTrash || (navFolder && !navFolder.accessGranted),
        onClick: () => this.setState({newMenu: true})
      },
      {
        key: 'publicSharing',
        text: i18n.t('app:createPublicLink'),
        iconProps: { iconName: 'Globe' },
        disabled: !ActionsPermissions("createPublicLink", role) || (this._isMounted && this._checkDownloadEnabled()),
        onClick: () => this.props.fileExplorerRef._createPublicLink(this.props.fileExplorerRef._selection.getSelection()),
      }
    ]

    const newMenuProps: any = {
      items: [
        {
          key: 'createFolder',
          text: i18n.t('app:folder'),
          iconProps: { iconName: 'FabricNewFolder' },
          onClick: this._showNewFolderDialog.bind(this, this.props.folderId),
          id : 'create-folder-button',
          disabled: !ActionsPermissions("createFolder", role)
        },
        {
          key: 'newWord',
          text: i18n.t('app:word'),
          iconProps: { iconName: 'WordDocument' },
          className: "wordDoc",
          onClick: this._createOfficeDocument.bind(this, "WORD"),
          disabled: !ActionsPermissions("createOfficeDoc", role)
        },
        {
          key: 'newExcel',
          text: i18n.t('app:excel'),
          iconProps: { iconName: 'excelDocument' },
          className: "excelDoc",
          onClick: this._createOfficeDocument.bind(this, "EXCEL"),
          disabled: !ActionsPermissions("createOfficeDoc", role)
        },
        {
          key: 'newPowerpoint',
          text: i18n.t('app:powerpoint'),
          iconProps: { iconName: 'PowerPointDocument' },
          className: "powerpointDoc",
          onClick: this._createOfficeDocument.bind(this, "POWERPOINT"),
          disabled: !ActionsPermissions("createOfficeDoc", role)
        }
      ]
    }

    const onRenderOption = (item: any) => {

      return (<div className='px-3 font-weight-bold' style={{backgroundColor: item.color || 'rgb(169, 169, 169,.7)', color: pickTextColorBasedOnBgColor(item.color || 'rgb(169, 169, 169,.7)', '#ffffff', '#000000') , borderRadius: '4px'}}>
              {item.name }
            </div>)
    };
    
    const contextualMenuItems: IContextualMenuItem[] = [
      {
        key: 'openFolder',
        name: i18n.t('app:openFolder'),
        onClick: () => history.push('/files/' + this.state.contextMenuId),
        iconProps: { iconName: 'FabricOpenFolderHorizontal' },
        hidden: !this.props.isOnline || this.props.maintenance
      },
      {
        key: 'openFolderInNewWindow',
        name: i18n.t('app:openFolderInNewWindow'),
        onClick: () => this.props.fileExplorerRef._openFolderInNewWindow(this.state.contextMenuId),
        iconProps: { iconName: 'OpenInNewWindow' },
        hidden: !this.props.isOnline || this.props.maintenance || !isElectron()
      },
    ]

    return(
      <div className="toolbar">
        <div id="top-titlebar" className="d-flex">
          <div className="d-flex title align-items-center mr-3" style={{minWidth: '25%'}}>
            <IconButton onClick={()=>{this._isMounted && this.setState({appsPanel: true})}} className='header-waffle' iconProps={{iconName:'WaffleOffice365'}} styles={{root: {width: '20px', height: '20px'}, icon: {fontSize: 16}}}/>
            <img src={ process.env.PUBLIC_URL + "/favicon.png" } className="img-fluid mx-3" alt="Synergy Drive logo" />
            <span>
              <Link onClick={() => history.push("/")}>
                Client portal
              </Link>
            </span>
          </div>

          <div className='d-flex align-items-center ml-auto pr-2' style={{minWidth: '25%'}}>
            <a className='d-flex align-items-center ml-auto' href='https://www.synergyos.com'><img src="/img/synergy_logo_white.png" height="16px" alt="Synergy Inc. logo" /></a>
          </div>
        </div>
        { <div className="d-flex flex-row">
          <div className={ "history-actions" + (this.props.fileExplorerType !== "fileExplorer" ? " mr-auto" : "")}>
            <IconButton iconProps={backIcon} title={i18n.t('app:back')} ariaLabel="Back" disabled={!this.props.isOnline || this.props.maintenance} onClick={ this._goBack.bind(this) }  />
            {(window.innerWidth > 980 || !detectMob() || this.props.appPlatform === ("electron" || "openfin")) && <IconButton iconProps={forwardIcon} title={i18n.t('app:forward')} ariaLabel="Forward" disabled={ !this.props.isOnline || this.props.maintenance} onClick={ this._goForward.bind(this) } />}
          </div>
          { this.props.fileExplorerType === "fileExplorer"
          || this.props.fileExplorerType === "recentFiles"
          || this.props.fileExplorerType === "openFiles"
          || this.props.fileExplorerType === "bookmarks"
          || this.props.fileExplorerType === "offlineFiles" ?
          <>
            {(window.innerWidth > 980 || !detectMob() || this.props.appPlatform === ("electron" || "openfin")) && <div className="refresh-action">
              {syncStatus.message === "Synced" ?
                <IconButton iconProps={refreshIcon} title={i18n.t('app:refresh')} ariaLabel="Refresh" onClick={ this.props.fileExplorerType === "fileExplorer" ? () => { this.props.getCurrentContent() } : this.props.getCurrentContent.bind(this) } />
              :
                <div className="ms-Spinner-wrap">
                  <Spinner size={SpinnerSize.small} />
                </div>
              }
            </div>}
            <div className={"folders-breadcrumb flex-grow-1" + ((this.props.isOnline && !this.props.maintenance) ? "" : " mr-3") } style={{minWidth:'60px'}}>
              <div className="row m-0 p-0">
                <div className="breadcrumb-icon">
                  { this.props.fileExplorerType === "recentFiles" ?
                    <Icon iconName="Recent" className="recentFilesIcon" />
                  : this.props.fileExplorerType === "openFiles" ?
                    <Icon iconName="ReopenPages" className="openFilesIcon" />
                  : this.props.fileExplorerType === "bookmarks" ?
                    <Icon iconName="FavoriteStar" className="searchIcon" />
                  : this.props.fileExplorerType === "offlineFiles" ?
                    this.props.storedType === "cache" ?
                      <Icon iconName="DatabaseSync" className="openFilesIcon" />
                    : this.props.storedType === "pendingDrafts" ?
                      <Icon iconName="CloudUpload" className="openFilesIcon" />
                    :
                      <Icon iconName="CloudDownload" className="openFilesIcon" />
                  :
                   <Icon iconName="FabricFolder" className="repoIcon" />
                  }
                </div>

                <div className="col m-0 p-0" style={{minWidth:'30px'}}>
                  { this.props.fileExplorerType === "recentFiles" ?
                    <Breadcrumb
                      items={[{
                        text: i18n.t('app:recentFiles'),
                        key: 'recentFiles',
                      }]}
                      ariaLabel="Breadcrumb"
                      overflowAriaLabel="Recent files"
                      overflowIndex={ 0 }
                    />
                  : this.props.fileExplorerType === "openFiles" ?
                    <Breadcrumb
                      items={[{
                        text: i18n.t('app:openFiles'),
                        key: 'openFiles',
                      }]}
                      ariaLabel="Breadcrumb"
                      overflowAriaLabel="Open files"
                      overflowIndex={ 0 }
                    />
                  : this.props.fileExplorerType === "bookmarks" ?
                    <Breadcrumb
                      items={[{
                        text: i18n.t('app:bookmarks'),
                        key: 'bookmarks',
                      }]}
                      ariaLabel="Breadcrumb"
                      overflowAriaLabel="Bookmarks"
                      overflowIndex={ 0 }
                    />
                  : this.props.fileExplorerType === "offlineFiles" ?
                    this.props.storedType === "cache" ?
                      <Breadcrumb
                        items={[{
                          text: i18n.t('app:cacheFiles'),
                          key: 'cacheFiles',
                        }]}
                        ariaLabel="Breadcrumb"
                        overflowAriaLabel="Cache files"
                        overflowIndex={ 0 }
                      />
                    : this.props.storedType === "pendingDrafts" ?
                      <Breadcrumb
                        items={[{
                          text: "Sync",
                          key: 'pendingUploads',
                        }]}
                        ariaLabel="Breadcrumb"
                        overflowAriaLabel="Pending uploads"
                        overflowIndex={ 0 }
                      />
                    :
                      <Breadcrumb
                        items={[{
                          text: i18n.t('app:offlineFiles'),
                          key: 'offlineFiles',
                        }]}
                        ariaLabel="Breadcrumb"
                        overflowAriaLabel="Offline files"
                        overflowIndex={ 0 }
                      />
                  :
                    <Breadcrumb
                      items={ window.innerWidth > 980 || !detectMob() || this.props.appPlatform === ("electron" || "openfin") ? breadcrumbPath : breadcrumbPath[breadcrumbPath.length-1] ? [breadcrumbPath[breadcrumbPath.length-1]] : breadcrumbPath}
                      ariaLabel="Breadcrumb"
                      overflowAriaLabel="More folders"
                      overflowIndex={ 0 }
                      //onRenderItem={ this._onRenderBreadcrumbItem }
                    />
                  }
                </div>
              </div>
            </div>

            {(this.props.fileExplorerType === "fileExplorer"
            || this.props.fileExplorerType === "recentFiles"
            || this.props.fileExplorerType === "bookmarks") ?
              <div className={ "search" + (window.innerWidth < 640 ? " smallSearchBox" : "" ) + (window.innerWidth < 480 ? " noSearchBox" : "") }>
                <SearchBox
                    placeholder={searchPlaceholder}
                    onSearch={newValue => this._goSearch(newValue)}
                    onChange={ this.handleSearchTermsChange }
                    componentRef={(instance) => this.searchRef = instance}
                />
                <Dropdown
                    placeholder="Select an option"
                    defaultSelectedKey={searchOptions}
                    options={[
                      { key: 'HeaderByName', text: i18n.t('app:ByName'), itemType: DropdownMenuItemType.Header },
                      { key: 'searchByName', text: i18n.t('app:files') + " & " + i18n.t('app:folders') },
                      { key: 'HeaderByFileContent', text: i18n.t('app:ByFileContent'), itemType: DropdownMenuItemType.Header },
                      { key: 'filesByTextInThisFolder', text: i18n.t('app:inThisFolder') },
                      { key: 'filesByTextInclSubfolders', text: i18n.t('app:inclSubfolders') }
                    ]}
                    dropdownWidth="auto"
                    onChange={ this.handleSearchTypeChange }
                    className="search-options"
                    onRenderTitle={ () => {
                      return (
                        <Icon iconName={'FilterSettings'} aria-hidden="true" />
                      );
                    }}
                  />
              </div>
            : null }

            { this.props.isOnline && !this.props.maintenance && (window.innerWidth > 980 || !detectMob() || this.props.appPlatform === ("electron" || "openfin")) ?
              <>
                <div className="downloads-panel" ref={this.downloadsButtonRef} >
                  {this._isMounted && this._getDownloadsCount() > 0 ?
                    <span className="badge badge-primary ml-2 align-text-top" onClick={ () => this.setState({showDownloadsPanel: !showDownloadsPanel}) }>{ this._getDownloadsCount() }</span>
                  : null }

                  <IconButton iconProps={downloadIcon} title="Downloads" ariaLabel="Downloads" disabled={false} onClick={ () => this.setState({ showDownloadsPanel: !showDownloadsPanel })} />

                  <Callout
                    className="downloading-callout"
                    target={this.downloadsButtonRef}
                    hidden={!showDownloadsCallout}
                    role="status"
                    aria-live="assertive"
                    backgroundColor="#0066CC"
                    directionalHint={DirectionalHint.bottomCenter}
                    beakWidth={10}
                    onDismiss={ () => this.setState({showDownloadsCallout: false})}
                  >
                    <p className="text-white font-weight-bold m-0 px-3 py-1">{i18n.t('app:downloading')}</p>
                  </Callout>
                </div>
                <div className="imports-panel" ref={this.uploadsButtonRef}>
                  { this.props.creatingFoldersStructure ?
                    <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline creatingFolders" />
                  : null}
                  { this.state.uploadsCount.processing + this.state.uploadsCount.queued > 0 && !this.props.creatingFoldersStructure?
                    <span className="badge badge-primary ml-2 align-text-top" onClick={ () => this.setState({showImportPanel: !showImportPanel}) }>{ this.state.uploadsCount.processing + this.state.uploadsCount.queued}</span>
                  : null }

                  <IconButton iconProps={uploadIcon} title="Uploads" ariaLabel="Uploads" disabled={false} onClick={ () => this.setState({showImportPanel: !showImportPanel}) } className={uploadsCount.error > 0 ? "error": ""}/>

                  <Callout
                    className="uploading-callout"
                    target={this.uploadsButtonRef}
                    hidden={!showUploadsCallout}
                    role="status"
                    aria-live="assertive"
                    backgroundColor="#0066CC"
                    directionalHint={DirectionalHint.bottomCenter}
                    beakWidth={10}
                    onDismiss={ () => this.setState({showUploadsCallout: false})}
                  >
                    <p className="text-white font-weight-bold m-0 px-3 py-1">{i18n.t('app:uploading')}</p>
                  </Callout>
                </div>
              </>
            : null }
          </>
          : null }

          { this.props.headerSection === "search"
          || this.props.headerSection === "tags"
          || this.props.headerSection === "publicLinks"
          || this.props.headerSection === "activity"
          || this.props.headerSection === "settings"
          || this.props.headerSection === "settingsOptions"
          || this.props.headerSection === "settingsTroubleshooting" ?
          <>
            <div className="refresh-action">
              { (this.props.headerSection !== "settings"
                && this.props.headerSection !== "settingsOptions"
                &&  this.props.headerSection !== "settingsTroubleshooting") ?
                this.props.headerSection === "search" || this.props.headerSection === "activity" ?
                syncStatus.message === "Synced" ?
                  <IconButton iconProps={refreshIcon} title={i18n.t('app:refresh')} ariaLabel="Refresh" onClick={ this.props.refreshCurrentContent.bind(this) } />
                :
                  <div className="ms-Spinner-wrap">
                    <Spinner size={SpinnerSize.small} />
                  </div>
              :
                syncStatus.message === "Synced" ?
                  <IconButton iconProps={refreshIcon} title={i18n.t('app:refresh')} ariaLabel="Refresh" onClick={ this.props.getCurrentContent.bind(this) } />
                :
                  <div className="ms-Spinner-wrap">
                    <Spinner size={SpinnerSize.small} />
                  </div>
              : null }
            </div>
            <div className="folders-breadcrumb flex-grow-1">
              <div className="row m-0 p-0">
                <div className="breadcrumb-icon">
                  { this.props.searchType === "tag" ?
                    <Icon iconName="Tag" className="searchIcon" />
                  : this.props.fileExplorerType === "bookmarks" ?
                    <Icon iconName="FavoriteStar" className="searchIcon" />
                  : this.props.searchType === "smartFolder" ?
                    <Icon iconName="FolderSearch" className="searchIcon" />
                  : this.props.headerSection === "tags" ?
                  <Icon iconName="FolderSearch" className="searchIcon" />
                  : this.props.headerSection === "publicLinks" ?
                    <Icon iconName="Globe" className="searchIcon" />
                  : this.props.headerSection === "activity" ?
                    <Icon iconName="LineChart" className="searchIcon" />
                  : this.props.headerSection === "settings"
                    || this.props.headerSection === "settingsOptions"
                    || this.props.headerSection === "settingsTroubleshooting" ?
                    <Icon iconName="Settings" className="searchIcon" />
                  :
                    <Icon iconName="Search" className="searchIcon" />
                  }
                </div>

                <div className="col m-0 p-0">
                  { this.props.searchType === "tag" ?
                    <Breadcrumb
                      items={[{
                        text: i18n.t('app:tags'),
                        key: 'tag',
                      }]}
                      ariaLabel="Breadcrumb"
                      overflowAriaLabel="Tags"
                      overflowIndex={ 0 }
                    />
                  : this.props.searchType === "bookmarks" ?
                    <Breadcrumb
                      items={[{
                        text: i18n.t('app:bookmarks'),
                        key: 'bookmarks',
                      }]}
                      ariaLabel="Breadcrumb"
                      overflowAriaLabel={i18n.t('app:bookmarks')}
                      overflowIndex={ 0 }
                    />
                  : this.props.headerSection === "tags" ?
                  <Breadcrumb
                    items={[{
                      text: i18n.t('app:tags'),
                      key: 'tags',
                    }]}
                    ariaLabel="Breadcrumb"
                    overflowAriaLabel="Tags"
                    overflowIndex={ 0 }
                  />
                  : this.props.searchType === "smartFolder" ?
                    <Breadcrumb
                      items={[{
                        text: i18n.t('app:smartFolders'),
                        key: 'smartFolder',
                      }]}
                      ariaLabel="Breadcrumb"
                      overflowAriaLabel="Smart folders"
                      overflowIndex={ 0 }
                    />
                  : this.props.headerSection === "publicLinks" ?
                    <Breadcrumb
                      items={[{
                        text: i18n.t('app:publicLinks'),
                        key: 'publicLinks',
                      }]}
                      ariaLabel="Breadcrumb"
                      overflowAriaLabel="Public links"
                      overflowIndex={ 0 }
                    />
                  : this.props.headerSection === "activity" ?
                    <Breadcrumb
                      items={[{
                        text: i18n.t('app:activityFeed'),
                        key: 'activityFeed',
                      }]}
                      ariaLabel="Breadcrumb"
                      overflowAriaLabel="Activity feed"
                      overflowIndex={ 0 }
                    />
                  : this.props.headerSection === "settings" ?
                    <Breadcrumb
                      items={[{
                        text: i18n.t('app:settings'),
                        key: 'settings',
                      }]}
                      ariaLabel="Breadcrumb"
                      overflowAriaLabel="Settings"
                      overflowIndex={ 0 }
                    />
                  : this.props.headerSection === "settingsOptions" ?
                    <Breadcrumb
                      items={[{
                        text: i18n.t('app:settings'),
                        key: 'settings',
                      },{
                        text: i18n.t('app:options'),
                        key: 'options',
                      }]}
                      ariaLabel="Breadcrumb"
                      overflowAriaLabel="Settings"
                      overflowIndex={ 0 }
                    />
                  : this.props.headerSection === "settingsTroubleshooting" ?
                    <Breadcrumb
                      items={[{
                        text: i18n.t('app:settings'),
                        key: 'settings',
                      },{
                        text: i18n.t('app:troubleshooting'),
                        key: 'troubleshooting',
                      }]}
                      ariaLabel="Breadcrumb"
                      overflowAriaLabel="Settings"
                      overflowIndex={ 0 }
                    />
                  :
                    <Breadcrumb
                      items={[{
                        text: this.state.breadcrumbSearch,
                        key: 'search'
                      }]}
                      ariaLabel="Breadcrumb"
                      overflowAriaLabel="Search"
                      overflowIndex={ 0 }
                    />
                  }
                </div>
              </div>
            </div>

            <div className="downloads-panel" ref={this.downloadsButtonRef} >
              {this._isMounted && this._getDownloadsCount() > 0 ?
                <span className="badge badge-primary ml-2 align-text-top" onClick={ () => this.setState({showDownloadsPanel: !showDownloadsPanel}) }>{ this._getDownloadsCount() }</span>
              : null }

              <IconButton iconProps={downloadIcon} title={i18n.t('app:downloads')} ariaLabel="Downloads" disabled={false} onClick={ () => this.setState({ showDownloadsPanel: !showDownloadsPanel })} />
            </div>
            <div className="imports-panel">
              { this._getPendingUploadsCount() > 0 ?
                <span className="badge badge-primary ml-2 align-text-top" onClick={ () => this.setState({showImportPanel: !showImportPanel}) }>{ this._getPendingUploadsCount() }</span>
              : null }

              <IconButton iconProps={uploadIcon} title={i18n.t('app:uploads')} ariaLabel="Uploads" disabled={false} onClick={ () => this.setState({showImportPanel: !showImportPanel}) } />
            </div>
          </>
          : null}
        </div>}

        { this.props.fileExplorerType === "fileExplorer"
         || this.props.fileExplorerType === "recentFiles"
         || this.props.fileExplorerType === "bookmarks"
         || this.props.fileExplorerType === "offlineFiles"
         || this.props.fileExplorerType === "openFiles"
         || this.props.headerSection === "search" ?
        <>
          <CommandBar
            items={ window.innerWidth > 980 || !detectMob() || this.props.appPlatform === ("electron" || "openfin") ? docActionItems : docActionItemsMobile }
            farItems={ window.innerWidth > 980 || !detectMob() || this.props.appPlatform === ("electron" || "openfin") ? farItems : undefined }
            ariaLabel="Use left and right arrow keys to navigate between commands"
            className="commandBar"
          />
          
          {this.state.newMenu ?
              <><Dialog
              hidden={false}
              modalProps= {{isBlocking:false, styles: {main: {display: 'none'}}}}
              onDismiss= {() => this.setState({newMenu: false})}
              >
                
              </Dialog><ContextualMenu {...newMenuProps} /></>
            : null}
        </>

        : this.props.headerSection === "tags" ?
                    
        <CommandBar
          items={[
            {
              key: 'newCategory',
              text: i18n.t('app:newCategory'),
              iconProps: { iconName: 'Add' },
              hidden: this._isMounted && this.props.headerSection !== "tags",
              onClick: this._showNewTagDialog.bind(this),
            },
            {
              key: 'newTag',
              text: i18n.t('app:newStandardTag'),
              iconProps: { iconName: 'Tag' },
              hidden: this._isMounted && this.props.headerSection !== "tags",
              onClick: this._showNewSingleTagDialog.bind(this),
            },
            {
              key: 'editCategory',
              text: i18n.t('app:editCategory'),
              iconProps: { iconName: 'Edit' },
              disabled: this._isMounted && this.props.tagCategoryId === '' && this._checkRenameEnabled(),
              hidden: this._isMounted && this.props.headerSection !== "tags",
              onClick: this._showEditTagDialog.bind(this, ''),
            },
            {
              key: 'deleteCategory',
              text: i18n.t('app:deleteCategory'),
              iconProps: { iconName: 'Delete' },
              disabled: this._isMounted && this.props.tagCategoryId === '',
              hidden: this._isMounted && this.props.headerSection !== "tags",
              onClick: ()=>this.props.sidebarRef.deleteTagCategory(this.props.tagCategoryId),
            },
            {
              key: 'editTag',
              text: i18n.t('app:renameTag'),
              iconProps: { iconName: 'Edit' },
              disabled: this._isMounted && this._checkRenameEnabled(),
              hidden: this._isMounted && this.props.headerSection !== "tags",
              onClick: this._showEditSingleTagDialog.bind(this),
            },
            {
              key: 'deleteTag',
              text: i18n.t('app:delete'),
              iconProps: { iconName: 'Delete' },
              disabled: this._isMounted && this._checkDeleteEnabled(),
              hidden: this._isMounted && this.props.headerSection !== "tags",
              onClick: this._deleteTags.bind(this),
            }
          ]}
          farItems={ window.innerWidth > 980 || !detectMob() || this.props.appPlatform === ("electron" || "openfin") ? farItems : undefined }
          ariaLabel="Use left and right arrow keys to navigate between commands"
          className="commandBar"
        />
          
        : this.props.headerSection === "publicLinks" ?
            
          <CommandBar
            items={[{
              key: 'deletePublicLinks',
              text: i18n.t('app:delete'),
              iconProps: { iconName: 'Delete' },
              disabled: this._isMounted && this._checkDeleteEnabled(),
              onClick: () => this._deletePublicLinks(this.props.selection.getSelection()),
            }]}
            ariaLabel="Use left and right arrow keys to navigate between commands"
            className="commandBar"
          />

        : this.props.headerSection === "activity" ?
            
          <CommandBar
            items={[
              {
                key: 'showLeftPanel',
                title: i18n.t('app:toggleLeftPanel'), 
                iconProps: { iconName: 'SidePanel' },
                className: 'sidePanel-button',
                onClick: () => this._toggleLeftPanel(),
              },
              {
                key: 'resetFilters',
                text: i18n.t('app:resetFilters'),
                iconProps: { iconName: 'Cancel' },
                onClick: () => history.push({pathname: '/activity', search: ""})
              }
            ]}
            ariaLabel="Use left and right arrow keys to navigate between commands"
            className="commandBar"
          />
          
        : (this.props.headerSection === "settings"
          || this.props.headerSection === "settingsOptions"
          || this.props.headerSection === "settingsTroubleshooting") ?
            
          <CommandBar
            items={[
              {
                key: 'showLeftPanel',
                title: i18n.t('app:toggleLeftPanel'), 
                iconProps: { iconName: 'SidePanel' },
                className: 'sidePanel-button',
                onClick: () => this._toggleLeftPanel(),
              }
            ]}
            ariaLabel="Use left and right arrow keys to navigate between commands"
            className="commandBar"
          />
          
        : null }

        {!hideCreateFolderDialog ?
          <Dialog
            hidden={hideCreateFolderDialog}
            onDismiss={this._closeDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: <div className='d-flex align-items-center'><Icon className='mr-2' {...getFileTypeIconProps({ type: FileIconType.folder, size: 32, imageFileType: 'svg' }) } />{i18n.t('app:newFolder')}</div>
            }}
            modalProps={{
              titleAriaId: "New folder",
              isBlocking: savingFolder,
              dragOptions: undefined,
              className: "new-folder-dialog"
            }}
          >
            <p className='pb-2 m-0' style={{fontWeight:500}}>{i18n.t('app:parentFolder')}</p>
            <span><Icon className='mr-1' style={{writingMode:'vertical-rl'}} {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } /><span style={{verticalAlign:'top'}}>{this.state.parentName.length > 0 ? this.state.parentName : this.state.breadcrumbPath[this.state.breadcrumbPath.length-1].text}</span></span>
            <form className='mt-2' name="form" onSubmit={this._createFolder.bind(this, this.state.newFolderParent)} >
              <TextField underlined autoFocus placeholder={i18n.t('app:name')} name="newFolder" value={ newFolder } onChange={ this.handleFolderChange } disabled={ savingFolder } errorMessage={ newFolderWarning } required />
            </form>

            { !this.state.newFolderParent.includes(this.props.repoData[0].id) && <ChoiceGroup defaultSelectedKey="KEEP_NEW"
              options={[
                { key: "KEEP_NEW", text: i18n.t('app:keepNewTags') },
                { key: "NONE", text: i18n.t('app:noTags') }
              ]}
              label={i18n.t('app:tags')+":"}
              required={true}
              className="folder-permissions-choice mt-3"
              onChange={ this.handleFolderTagsChoice }
              disabled={ savingFolder } /> }

            <ChoiceGroup defaultSelectedKey="true"
              options={[
                { key: "true", text: i18n.t('app:sameAsParent') },
                { key: "false", text: i18n.t('app:private') }
              ]}
              label={i18n.t('app:permissions')+":"}
              required={true}
              className="folder-permissions-choice mt-3"
              onChange={ this.handleFolderPermissionsChoice }
              disabled={ savingFolder } />

            <p className="tip text-secondary mt-3">
              <Icon iconName="Info" className="align-bottom mr-1" /> <span className="font-weight-bold">Keyboard shortcut:</span> create a new folder by pressing <span className="keyStyles">{os.name === "Mac OS" ? "Option" : "Alt"}</span> + <span className="keyStyles">N</span>.
            </p>

            <DialogFooter className="mt-0">
              { savingFolder ?
                <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
              : null }

              <DefaultButton onClick={this._closeDialog} disabled={ savingFolder } text={i18n.t('app:cancel')} />
              <PrimaryButton onClick={this._createFolder.bind(this, this.state.newFolderParent)} disabled={ savingFolder || this._containsInvalidChars(newFolder) } text={i18n.t('app:add')} />                   
            </DialogFooter>
          </Dialog>
        : null }

        {creatingOfficeDoc ?
          <Dialog
            hidden={!creatingOfficeDoc}
            dialogContentProps={{
              type: DialogType.normal,
              title: i18n.t('app:creatingDocument')
            }}
            modalProps={{
              isBlocking: true,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "creating-office-doc"
            }}
          >
            <div className="text-center w-100">
              <aside>
                {i18n.t('app:pleaseWait')}
              </aside>
              <span className="spinner ml-2"><Spinner size={SpinnerSize.xSmall} /></span>
            </div>
          </Dialog>
        : null }

        { this.props.repoData && this.props.userData && this.props.folderId && this.state.showTemplatesDialog ?
          <TemplatesBrowser repoId={ this.props.repoData[0].id + "-templates" } parentId={ this.props.folderId } userData={ this.props.userData } showTemplatesDialog={ true } callbackFunction={this.callbackFunction } />
        : null }

        {showTagBulkDialog && this.state.tagsList ?
          <Dialog
              hidden={!showTagBulkDialog}
              onDismiss={ () => this.setState({showTagBulkDialog: false}) }
              dialogContentProps={{
                type: DialogType.normal,
                title: i18n.t('app:assignTag')
              }}
              modalProps={{
                titleAriaId: "Assign tag",
                isBlocking: savingTag,
                dragOptions: undefined,
                className: "new-tag-dialog",
              }}
            >
              {
                this.state.tagsList && this.state.tagsList.map((item,index)=>{
                  return(
                    <div className='mb-2' key={item.tagCategory.id}>
                      <span className='pt-1' style={{position: 'absolute', right: 0, opacity:.7}}>{item.tagCategory.tagSelection === 'MULTIPLE_SELECT' ? 'Multiple select' : 'Single select'}</span>
                      <ComboBox 
                      label={item.tagCategory.name} 
                      options={item.tag} 
                      multiSelect={item.tagCategory.tagSelection === 'MULTIPLE_SELECT' ? true : false} 
                      autoComplete='on' 
                      allowFreeform={true} 
                      dropdownWidth={window.innerWidth >= 500 ? 417 : undefined}
                      selectedKey={ newTag[index] } 
                      onChange={ (ev: any, option?: any, i?: number, value?: string) => item.tagCategory.tagSelection === 'MULTIPLE_SELECT' ? this.handleTagChangeMultiple(ev,index,option,i,value) : this.handleTagChange(ev,index,option,i,value) } 
                      disabled={ savingTag } 
                      onRenderOption={onRenderOption}
                      />
                    </div>
                  )
                })
              }
              {folderSelected() && <Checkbox
                className='mt-3'
                label={i18n.t('app:editToSubfolders')}
                checked={this.state.isCascade}
                onChange={()=>this.setState({isCascade:!this.state.isCascade})}
              />}
              <DialogFooter>

                { savingTag ?
                  <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
                : null }

                <DefaultButton onClick={ () => this.setState({showTagBulkDialog: false}) } text={i18n.t('app:cancel')} disabled={savingTag} />
                <PrimaryButton onClick={this._addTagBulk} text={i18n.t('app:save')} disabled={savingTag} />                   
              </DialogFooter>
            </Dialog>
        : null }

        { deletingItem ?
          <Dialog
            hidden={!deletingItem}
            onDismiss={() => this.setState({deletingItem: false, errorMessage: ""})}
            dialogContentProps={{
              type: DialogType.normal,
              title: i18n.t('app:deletingItems')
            }}
            modalProps={{
              isBlocking: this.state.errorMessage ? false : true,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "deleting-item",
            }}
          >
            <div className="text-center w-100">
              { !errorMessage ?
                <>
                <aside>
                  {i18n.t('app:pleaseWait')}.
                </aside>
                <span className="spinner ml-2"><Spinner size={SpinnerSize.xSmall} /></span>
                </>
              : 
                <div className="messageBar-bg-fix">
                  <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                  >
                    {errorMessage}
                  </MessageBar>
                </div>
              }
            </div>
          </Dialog>
        : null }

        { restoringItem ?
          <Dialog
            hidden={!restoringItem}
            onDismiss={() => this.setState({restoringItem: false, errorMessage: ""})}
            dialogContentProps={{
              type: DialogType.normal,
              title: i18n.t('app:restoringItems')
            }}
            modalProps={{
              isBlocking: this.state.errorMessage ? false : true,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "restoring-item",
            }}
          >
            <div className="text-center w-100">
              { !errorMessage ?
                <>
                <aside>
                  {i18n.t('app:pleaseWait')}.
                </aside>
                <span className="spinner ml-2"><Spinner size={SpinnerSize.xSmall} /></span>
                </>
              : 
                <div className="messageBar-bg-fix">
                  <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                  >
                    {errorMessage}
                  </MessageBar>
                </div>
              }
            </div>
          </Dialog>
        : null }

        {showNewTagDialog || showEditTagDialog ?
          <Dialog
            hidden={!showNewTagDialog && !showEditTagDialog}
            onDismiss={()=>{this._isMounted && this.setState({showNewTagDialog:false, showEditTagDialog: false, tagCreated: ''})}}
            dialogContentProps={{
              type: DialogType.normal,
              title: <div className='d-flex align-items-center'><Icon className='mr-2' style={{fontSize: '24px'}} iconName='Tag' />{ showNewTagDialog ? i18n.t('app:newCategory') : i18n.t('app:editCategory')}</div>
            }}
            modalProps={{
              titleAriaId: "Category",
              isBlocking: savingFolder,
              dragOptions: undefined,
              className: "new-tag-dialog"
            }}
          >
            <div className=''>
              <div className="" >
                { this.state.tagCreated.length > 0 && <>
                  <MessageBar
                    messageBarType={MessageBarType.success}
                    isMultiline={false}
                    onDismiss={()=>this.setState({tagCreated: ''})}
                    dismissButtonAriaLabel="Close"
                    className='mt-3'
                    styles={{root: {position:'absolute', top:-62}}}
                  >
                    <span>{this.state.tagCreated}</span>
                  </MessageBar>
                </>}
                <div >
                  <TextField value={ this.state.tagName } onChange={ this.handleTagNameChange } errorMessage={this.state.tagNameWarning} label={i18n.t('app:name')+':'} />
                </div>
                <div className='mt-2'>
                  <Dropdown id='tag-dropdown' options={[{key:'SINGLE_SELECT', text: 'Single select'},{key:'MULTIPLE_SELECT', text: 'Multiple select'}]} selectedKey={this.state.tagType} onChange={this.handleTagTypeChange} label={i18n.t('app:type')+':'} />
                </div> 
                <div className='mt-2' >
                  <TextField multiline={true} value={ this.state.tagDescription } onChange={ this.handleTagDescriptionChange } errorMessage={this.state.tagDescriptionWarning} label={i18n.t('app:description') + ':'} />
                </div>
              </div>
              {this.state.showEditTagDialog && <div className=''>
                <p className='mt-1 mb-0' style={{fontWeight: 600, padding: '5px 0px'}}>{i18n.t('app:options')+":"}</p>
                <div>
                  {this.state.tagOptions ? this.state.tagOptions.map((item,index) => {
                    return (
                      <div className='mb-2 d-flex align-items-center tag-options' key={'tagName-'+index}>
                        <div id={'color'+index} onClick={()=>{if(item.edit) this._isMounted && this.setState({colorIndex: index, showColorPick: !this.state.showColorPick})}} className={item.edit ? 'colorPickerEdit mr-2' : 'colorPicker mr-2'} style={{backgroundColor: item.edit ? this.state.tagOptionsEdit[index].color : item.color}}/>
                        <form className='flex-grow-1' onSubmit={(e)=> {e.preventDefault(); this._updateTagOption(index)}}>
                          <TextField id={'optionInput'+index} value={ item.edit ? this.state.tagOptionsEdit[index].name : this.state.tagOptions[index].name} disabled={!item.edit} onChange={ (e,v)=> this._handleTagOptionChange(v,index) } />
                        </form>
                         { item.edit ? 
                          <>
                            <IconButton onClick={()=>this._cancelEditTagOption(index, item.new)} title={i18n.t('app:cancel')} iconProps={{iconName: 'Cancel'}} />
                            <IconButton onClick={()=>this._updateTagOption(index)} title={i18n.t('app:save')} iconProps={{iconName: 'CheckMark'}} />
                          </>
                          :<>
                            <IconButton onClick={()=>this._editTagOption(index)} title={i18n.t('app:edit')} iconProps={{iconName: 'Edit'}} />
                            <IconButton onClick={()=>this._deleteTagOption(index)} title={i18n.t('app:delete')} iconProps={{iconName: 'Delete'}} />
                          </>
                       }
                      </div>
                    )
                  }) : null}
                </div>
                <DefaultButton className='d-inline' onClick={()=>this._addTagOption()} iconProps={{iconName: 'Add'}} text={i18n.t('app:addOption')} />
              </div>}
              {this.state.showColorPick && 
                <Callout 
                  onDismiss={()=>this._isMounted && this.setState({showColorPick: false})}
                  target={'#color'+this.state.colorIndex}
                  directionalHint={DirectionalHint.leftTopEdge}
                  setInitialFocus
                >
                  <aside className='ml-2' style={{fontWeight: 600, padding: '5px 0px'}}>Color:</aside>
                  <SwatchColorPicker
                    columnCount={5}
                    cellShape={'circle'}
                    cellHeight={30}
                    cellWidth={30}
                    cellBorderWidth={3}
                    colorCells={pickerColors}
                    onChange={(e,v)=>this._handleColorPickerChange(v)}
                    styles={{root: {marginTop: 0}}}
                  />
                </Callout>
              }

            </div>
            <DialogFooter className="mt-2">
              { savingTag ?
                <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
              : null }

              <DefaultButton onClick={()=> {this._isMounted && this.setState({showNewTagDialog:false, showEditTagDialog: false, tagCreated: ''})} } disabled={ savingTag } text={i18n.t('app:close')} />
              <PrimaryButton onClick={()=> showNewTagDialog ? this._createTagCategory() : this._editTagCategory()} disabled={ savingTag } text={showNewTagDialog ? i18n.t('app:add') : i18n.t('app:save')} />                   
            </DialogFooter>
          </Dialog>
        : null }

        {showSingleTagDialog ?
          <Dialog
            hidden={!showSingleTagDialog}
            onDismiss={()=>this._isMounted && this.setState({showSingleTagDialog: false})}
            dialogContentProps={{
              type: DialogType.normal,
              title: <div className='d-flex align-items-center'><Icon className='mr-2' style={{fontSize: '24px'}} iconName='Tag' />{ i18n.t('app:renameTag')}</div>
            }}
            modalProps={{
              titleAriaId: "Edit Tag",
              isBlocking: savingFolder,
              dragOptions: undefined,
              className: "new-tag-dialog"
            }}
          >
            <div className=''>
              <div className="" >
                <form className='d-flex align-items-center' onSubmit={(e)=>{e.preventDefault();this._renameTag()}} >
                  <div id={'colorPicker'} onClick={()=>{this._isMounted && this.setState({showColorPick: !this.state.showColorPick})}} className={'colorPickerEdit mr-2'} style={{backgroundColor: this.state.tagColor}}/>
                  <TextField className='flex-grow-1' value={ this.state.tagName } onChange={ this.handleTagNameChange } errorMessage={this.state.tagNameWarning} />
                </form>
                {this.state.showColorPick && 
                <Callout 
                  onDismiss={()=>this._isMounted && this.setState({showColorPick: false})}
                  target={'#colorPicker'}
                  directionalHint={DirectionalHint.leftTopEdge}
                  setInitialFocus
                >
                  <aside className='ml-2' style={{fontWeight: 600, padding: '5px 0px'}}>Color:</aside>
                  <SwatchColorPicker
                    columnCount={5}
                    cellShape={'circle'}
                    cellHeight={30}
                    cellWidth={30}
                    cellBorderWidth={3}
                    colorCells={pickerColors}
                    onChange={(e,v)=>this.setState({tagColor: v || 'white'})}
                    styles={{root: {marginTop: 0}}}
                  />
                </Callout>
              }
              </div>

            </div>
            <DialogFooter className="mt-2">
              { savingTag ?
                <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
              : null }

              <DefaultButton onClick={()=>this._isMounted && this.setState({showSingleTagDialog: false})} disabled={ savingTag } text={i18n.t('app:cancel')} />
              <PrimaryButton onClick={()=>this._renameTag()} disabled={ savingTag } text={i18n.t('app:save')} />                   
            </DialogFooter>
          </Dialog>
        : null }
        {showNewSingleTagDialog ?
          <Dialog
            hidden={!showNewSingleTagDialog}
            onDismiss={()=>this._isMounted && this.setState({showNewSingleTagDialog: false})}
            dialogContentProps={{
              type: DialogType.normal,
              title: <div className='d-flex align-items-center'><Icon className='mr-2' style={{fontSize: '24px'}} iconName='Tag' />{ i18n.t('app:newStandardTag')}</div>
            }}
            modalProps={{
              titleAriaId: "Edit Tag",
              isBlocking: savingFolder,
              dragOptions: undefined,
              className: "new-tag-dialog"
            }}
          >
            <div className=''>
              <div className="" >
                <form className='d-flex align-items-center' name="form" onSubmit={(e)=>{e.preventDefault();this._createTag()}} >
                  <div id={'colorPicker'} onClick={()=>{this._isMounted && this.setState({showColorPick: !this.state.showColorPick})}} className={'colorPickerEdit mr-2'} style={{backgroundColor: this.state.tagColor}}/>
                  <TextField className='flex-grow-1' value={ this.state.tagName } onChange={ this.handleTagNameChange } errorMessage={this.state.tagNameWarning}/>
                </form>
                {this.state.showColorPick && 
                <Callout 
                  onDismiss={()=>this._isMounted && this.setState({showColorPick: false})}
                  target={'#colorPicker'}
                  directionalHint={DirectionalHint.leftTopEdge}
                  setInitialFocus
                >
                  <aside className='ml-2' style={{fontWeight: 600, padding: '5px 0px'}}>Color:</aside>
                  <SwatchColorPicker
                    columnCount={5}
                    cellShape={'circle'}
                    cellHeight={30}
                    cellWidth={30}
                    cellBorderWidth={3}
                    colorCells={pickerColors}
                    onChange={(e,v)=>this.setState({tagColor: v || 'white'})}
                    styles={{root: {marginTop: 0}}}
                  />
                </Callout>
              }
              </div>

            </div>
            <DialogFooter className="mt-2">
              { savingTag ?
                <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
              : null }

              <DefaultButton onClick={()=>this._isMounted && this.setState({showNewSingleTagDialog: false})} disabled={ savingTag } text={i18n.t('app:cancel')} />
              <PrimaryButton onClick={()=>this._createTag()} disabled={ savingTag } text={i18n.t('app:save')} />                   
            </DialogFooter>
          </Dialog>
        : null }

        <Panel isLightDismiss isOpen={showDownloadsPanel} onDismiss={() => this.setState({showDownloadsPanel: false}) } closeButtonAriaLabel="Close" headerText="Downloads" className="import-panel">
          { this.props.downloadingFiles.length > 0 && (
            <div className="row my-2 mx-0 p-0">
              <ActionButton onClick={this._clearDownloadedFiles.bind(this)} text={i18n.t('app:clearDownloadFiles')} className="clear-imported mb-2 p-0" />
            </div>
          )}

          { this.props.downloadingFiles.length > 0 ?
            this.props.downloadingFiles.map((item, key) => {
              return (
                <li key={ key } className="list-group-item item">
                  <div className="px-2 row">
                    <div className="col mr-auto">{ item.fileName }</div>
                    <div>
                    { item.downloadStatus === "success" ?
                      <Icon iconName="CheckMark" title={i18n.t('app:uploadSuccess')} ariaLabel="Upload success" className="text-success ml-2" />
                    : item.downloadStatus === "downloading" ?
                      <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline ml-2" />
                    : item.downloadStatus === "pending" ?
                      <span className="text-secondary small">{i18n.t('app:queued')}</span>
                    : item.downloadStatus === "processing" ?
                      <span className="text-secondary small">{i18n.t('app:processing')}</span>
                    : item.downloadStatus === "error" ?
                      <Icon iconName="Error" title={i18n.t('app:Error')} ariaLabel="Error" className="text-danger ml-2" />
                    : null }
                    </div>
                  </div>
                </li>
              )
            })
          :
            <p className="text-secondary my-2 p-0">{i18n.t('app:noPendingFiles')}.</p>
          }
        </Panel>

        <Panel isLightDismiss isOpen={showImportPanel} onDismiss={() => this.setState({showImportPanel: false}) } closeButtonAriaLabel="Close" headerText="Uploads" className="import-panel" onOpen={this._updateUploadsInfo.bind(this)}>
          { this.props.creatingFoldersStructure ?
            <div>
              <p className="d-inline-block text-secondary my-2 p-0">
                {i18n.t('app:generatingFolderStructure')}.
              </p>
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline ml-2" />
            </div>
          :
            <>
            <Pivot selectedKey={this.state.activeUploadsTab} onLinkClick={ this._handleLinkClick } headersOnly={true} getTabId={this._getTabId} className="pivot-item-icon-only mb-3">
              <PivotItem itemKey="processing" itemCount={uploadsCount.processing} onRenderItemLink={this._customPivotRenderer}></PivotItem>

              <PivotItem itemKey="queued" itemCount={uploadsCount.queued} onRenderItemLink={this._customPivotRenderer}></PivotItem>

              <PivotItem itemKey="success" itemCount={uploadsCount.success} onRenderItemLink={this._customPivotRenderer}></PivotItem>

              <PivotItem itemKey="error" itemCount={uploadsCount.error} onRenderItemLink={this._customPivotRenderer}></PivotItem>
            </Pivot>

            <div
              aria-labelledby={this._getTabId(this.state.activeUploadsTab)}
              role="tabpanel"
            >
              { this.state.activeUploadsTab === "processing" ?
                <div className="mb-2">
                  <p>{i18n.t('app:currentUploads')}</p>
                  { uploadsCount.processing > 0 ?
                    <ActionButton onClick={this._clearProcessingFiles.bind(this)} iconProps={{ iconName: 'Cancel', className:"text-secondary ml-0" }} text={i18n.t('app:clearProcessingFiles')} className="clear-imported p-0" />
                  : null }
                </div>
              : this.state.activeUploadsTab === "queued" ?
                <div className="mb-2">
                  <p>{i18n.t('app:uploadsQueue')}</p>
                  { uploadsCount.queued > 0 ?
                    <ActionButton onClick={this._clearQueuedFiles.bind(this)} iconProps={{ iconName: 'Cancel', className:"text-secondary ml-0" }} text={i18n.t('app:cancelQueuedFiles')} className="clear-imported p-0" />
                  : null }
                </div>
              : this.state.activeUploadsTab === "success" ?
                <div className="mb-2">
                  <p>{i18n.t('app:successfulUploads')}</p>
                  { uploadsCount.success > 0 ?
                    <ActionButton onClick={this._clearImportedFiles.bind(this)} iconProps={{ iconName: 'Checkmark', className:"text-success ml-0" }} text={i18n.t('app:clearUploadedFiles')} className="clear-imported p-0" />
                  : null }
                </div>
              : this.state.activeUploadsTab === "error" ?
                <div className="mb-2">
                  <p>{i18n.t('app:filesWithError')}</p>
                  { uploadsCount.error > 0 ?
                    <>
                    <ActionButton onClick={this._clearErrorFiles.bind(this)} iconProps={{ iconName: 'Cancel', className:"text-secondary ml-0" }} text={i18n.t('app:cancelUploadsError')} className="clear-imported p-0" />
                    </>
                  : null }
                </div>
              : null }
            </div>
            </>
          }

          { currentUploadsQueue.length > 0 ?
            currentUploadsQueue.map((item, key) => {
                return (
                  <li key={ key } className="list-group-item item">
                    <div className="px-2 row">
                      <div className="col mr-auto">
                        <p className="m-0 p-0">{ item.fileName }</p>
                        { item.uploadStatus === "success" ?
                          <p className="text-secondary small m-0 p-0">
                            {i18n.t('app:uploadedOn')} { Moment(item.timestamp).format(i18n.t('app:dateFormat')) }
                          </p>
                        : item.uploadStatus === "pending" ?
                          <p className="text-secondary small m-0 p-0">
                            {i18n.t('app:addedOn')} { Moment(item.timestamp).format(i18n.t('app:dateFormat')) }
                          </p>
                        : item.uploadStatus === "processing" ?
                          <p className="text-secondary small m-0 p-0">
                            {i18n.t('app:uploadStartedOn')} { Moment(item.timestamp).format(i18n.t('app:dateFormat')) }
                          </p>
                        : item.uploadStatus === "error" ?
                          <p className="text-secondary small m-0 p-0">
                            {i18n.t('app:errorOn')} { Moment(item.timestamp).format(i18n.t('app:dateFormat')) }
                          </p>
                        : null}
                      </div>
                      <div>
                      { item.uploadStatus === "success" ?
                        <>
                          <Icon iconName="CheckMark" title={i18n.t('app:uploadSuccess')} ariaLabel="Upload success" className="text-success ml-2" />
                          <Icon iconName="Cancel" title={i18n.t('app:remove')} ariaLabel="Remove" className="text-secondary cursor-pointer small ml-2" onClick={ this._removeUpload.bind(this, [item]) } />
                        </>
                      : item.uploadStatus === "processing" ?
                        <>
                          <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline ml-2" />
                          <Icon iconName="Cancel" title={i18n.t('app:remove')} ariaLabel="Remove" className="text-secondary cursor-pointer small ml-2" onClick={ this._removeUpload.bind(this, [item]) } />
                        </>
                      : item.uploadStatus === "pending" ?
                        <>
                          <span className="text-secondary ml-2 small">{i18n.t('app:queued')}</span>
                         <Icon iconName="Cancel" title="Remove" ariaLabel="Remove" className="text-secondary cursor-pointer small ml-2" onClick={ this._removeUpload.bind(this, [item]) } />
                        </>
                      : item.uploadStatus === "processing" ?
                        <>
                          <span className="text-secondary ml-2 small">{i18n.t('app:processing')}</span>
                        </>
                      : item.uploadStatus === "error" ?
                        <>
                          <Icon iconName="Error" title={i18n.t('app:error')} ariaLabel="Error" className="text-danger ml-2"/>
                          <Icon iconName="Cancel" title={i18n.t('app:remove')} ariaLabel="Remove" className="text-secondary cursor-pointer small ml-2" onClick={ this._removeUpload.bind(this, [item]) } />
                        </>
                      : null }
                      </div>
                    </div>
                  </li>
                )
              })
          :
            <p className="text-secondary my-2 p-0">{i18n.t('app:empty')}.</p>
          }

          { this.state.activeUploadsTab === "queued"
            && uploadsCount.queued > 100 ?
            <p className="text-center my-2">{i18n.t('app:showing100')} {uploadsCount.queued} {i18n.t('app:queuedUploads')}</p>
          : this.state.activeUploadsTab === "success"
            && uploadsCount.success > 100 ?
            <p className="text-center my-2">{i18n.t('app:showing100')} {uploadsCount.success} {i18n.t('app:successfulUploads')}</p>
          : null }
        </Panel>
        <ContextualMenu
          items={contextualMenuItems}
          hidden={!this.state.showContextualMenu}
          target={this.state.contextMenuId ? "#crumb-" + this.state.contextMenuId : undefined}
          onItemClick={() => this.setState({showContextualMenu: false})}
          onDismiss={() => this.setState({showContextualMenu: false})}
        />
      </div>
    )
  }

  private _goBack() {
    if (this.props.appPlatform === "electron") {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.send('goBack')
	  } else {
      history.back()
    }
  }

  private _goForward() {
    if (this.props.appPlatform === "electron") {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.send('goForward')
	  } else {
      history.forward()
    }
  }

  private _newPusherMessage(message) {
    
    var instanceId = Cookies.get('instanceId') || localStorage.getItem("instanceId")
    
    if(message[0] === instanceId) {
      //var origin = message[0];
      //var repoId = message[1];
      //var author = message[2];
      var statusType = message[3].toLowerCase();
      if(statusType === "pending") statusType = "queued"
      var statusCount = parseInt(message[4]);

      let uploadsCount = this.state.uploadsCount
      uploadsCount[statusType] = statusCount
      this._isMounted && this.setState({uploadsCount: uploadsCount})
    } else {
      var id = message[0];
      // var parentId = message[1];
      // var author = message[2];
      var action = message[3];
      var origin = message[4];

      if(origin === instanceId && action === "uploaddocument") {
        let uploadsCount = this.state.uploadsCount
        if(uploadsCount["processing"] > 0) {
          uploadsCount["processing"] = uploadsCount["processing"] - 1
          uploadsCount["success"] = uploadsCount["success"] + 1

          
          if(this.state.activeUploadsTab === "processing") {
            var uploadsQueue = this.state.uploadsQueue.filter(item => {
              return item.fileId !== id
            })
            this._isMounted && this.setState({
              uploadsCount: uploadsCount,
              uploadsQueue: uploadsQueue
            })
          } else {
            this._isMounted && this.setState({
              uploadsCount: uploadsCount
            })
          }
        }
      }
    }
  }

  private _openUrl(url) {
    if(this.props.appPlatform === "electron") {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.send("open-browser-url", url)
    } else {
      window.open(url, "_blank");
    }
  }

  private uniq = (a) => {
    var prims = {"boolean":{}, "number":{}, "string":{}}, objs:any = [];

    return a.filter(function(item) {
        var type = typeof item;
        if(type in prims)
            return prims[type].hasOwnProperty(item) ? false : (prims[type][item] = true);
        else
            return objs.indexOf(item) >= 0 ? false : objs.push(item);
    });
  }

  private _toggleLeftPanel() {
    var nav = this.props.fileExplorerType;
    if (this.props.fileExplorerType === "fileExplorer"
      || this.props.fileExplorerType === "recentFiles"
      || this.props.fileExplorerType === "openFiles"
      || this.props.fileExplorerType === "bookmarks") nav = "files"
    if (this.props.fileExplorerType === "offlineFiles") nav = "offline"
    if (this.props.fileExplorerType === "search") {
      if (this.props.searchType === "smartFolder") nav = "smartFolder"
      else if (this.props.searchType === "tag") nav = "tag"
    }
    if(nav) try{this.props.sidebarRef.toggleNav(nav)}catch{}
  }

  private _checkDownloadEnabled() {
    if(this.props.fileExplorerType === "openFiles") return true;
    if(this.props.fileExplorerType === "offlineFiles") return true;

    if(this.props.fileExplorerRef
      && this.props.fileExplorerRef._selection) {

      var selection = this.props.fileExplorerRef._selection.getSelection();

      if (selection.length === 0) {
        return true
      }
      else {
        var accessDenied = selection.filter(item => {
          return (item.fileType === "dir" && !item.accessGranted)
        })
        if(accessDenied.length > 0) return true;
  
        var role = selection[0].fileType === "dir" ? this.state.folderRole : this.state.parentRole
        if(role > 1 || this.state.parentRole > 1) {
          if(selection[0].fileType === "dir" && role > 1) return true
          if(selection.length > 20) return true
        }
      }
    }

    return false
  }

  private _checkDownloadFolderEnabled() {
    if(this.props.fileExplorerType === "openFiles") return true;
    if(this.props.fileExplorerType === "offlineFiles") return true;

    if(this.props.fileExplorerRef
      && this.props.fileExplorerRef._selection) {

      var selection = this.props.fileExplorerRef._selection.getSelection();

      if(selection.length !== 1) return true;

      var files = selection.filter(item => {
        return item.fileType === "file"
      })

      if(files.length > 0) return true;
    }

    return false
  }

  private _checkClipboardEnabled() {
    if(this.props.fileExplorerType === "activity") return true;
    if(this.props.fileExplorerType === "openFiles") return true;
    if(this.props.fileExplorerType === "offlineFiles") return true;

    return false
  }

  private _checkClipboardCopyEnabled() {
    var selection = this.props.fileExplorerRef && this.props.fileExplorerRef._selection && this.props.fileExplorerRef._selection.getSelection();

    if(selection && selection.length === 0) return true;

    return false
  }

  private _checkClipboardCutEnabled() {
    var selection = this.props.fileExplorerRef && this.props.fileExplorerRef._selection && this.props.fileExplorerRef._selection.getSelection();

    if(selection && selection.length === 0) return true;

    return false
  }

  private _checkClipboardPasteEnabled() {
    if(this.props.fileExplorerType !== "fileExplorer") return true;

    return false
  }

  private _checkDuplicateEnabled() {
    if(this.props.fileExplorerType === "openFiles") return true;
    if(this.props.fileExplorerType === "offlineFiles") return true;

    if(this.props.fileExplorerRef
      && this.props.fileExplorerRef._selection) {

      var selection = this.props.fileExplorerRef._selection.getSelection();

      if(selection.length !== 1) return true;

      var folders = selection.filter(item => {
        return item.fileType === "dir"
      })

      if(folders.length > 0) return true;
    }

    return false
  }

  private _checkCopyPrivateShortcutEnabled() {

    if(this.props.fileExplorerRef
      && this.props.fileExplorerRef._selection) {

      var selection = this.props.fileExplorerRef._selection.getSelection();

      if(selection.length !== 1) return true;
    }

    return false
  }

  private _checkShareEnabled() {
    if(this.props.fileExplorerType === "openFiles") return true;
    if(this.props.fileExplorerType === "offlineFiles") return true;

    if(this.props.fileExplorerRef
      && this.props.fileExplorerRef._selection) {

      var selection = this.props.fileExplorerRef._selection.getSelection();

      if(selection.length !== 1) return true;

      var files = selection.filter(item => {
        return item.fileType === "file"
      })

      var accessDenied = selection.filter(item => {
        return (item.fileType === "dir" && !item.accessGranted)
      })
      if(accessDenied.length > 0) return true;

      if(files.length > 0) return true;
    }

    return false
  }

  private _checkCreatePublicLinkEnabled() {

    if(this.props.fileExplorerRef
      && this.props.fileExplorerRef._selection) {

      var selection = this.props.fileExplorerRef._selection.getSelection();

      if(selection.length === 0) return true;

      var noAccessGranted = selection.filter((item: any) => {
        return (item.fileType === "dir" && !item.accessGranted)
      })
      const accessGrantedSel = noAccessGranted.length === 0

      if(!accessGrantedSel) return true;
    }

    return false
  }

  private _checkAttachEnabled() {
    if(this.props.fileExplorerType === "openFiles") return true;
    if(this.props.fileExplorerType === "offlineFiles") return true;

    if(this.props.fileExplorerRef
      && this.props.fileExplorerRef._selection) {

      var selection = this.props.fileExplorerRef._selection.getSelection();

      if(selection.length < 1) return true;

      if(this.state.parentRole > 1 && selection.length > 20) return true;

      var folders = selection.filter(item => {
        return item.fileType === "dir"
      })

      if(folders.length > 0) return true;
    }

    return false
  }

  private _checkSaveAsPdfEnabled() {
    if(this.props.fileExplorerType === "openFiles") return true;
    if(this.props.fileExplorerType === "offlineFiles") return true;

    if(this.props.fileExplorerRef
      && this.props.fileExplorerRef._selection) {

      var selection = this.props.fileExplorerRef._selection.getSelection();

      if(selection.length !== 1) return true;

      var folders = selection.filter(item => {
        return item.fileType === "dir"
      })

      if(folders.length > 0) return true;

      var notWordDocs = selection.filter(item => {
        if(item.fileExtension !== "doc"
          && item.fileExtension !== "docx"
          && item.fileExtension !== "ppt"
          && item.fileExtension !== "pptx") {

          return true;
        }
        return false
      })

      return notWordDocs.length > 0;
    }

    return false
  }

  private _checkDeleteEnabled(item = null) {
    if(this.props.fileExplorerType === "openFiles") return true;
    if(this.props.fileExplorerType === "offlineFiles") return true;

    if((this.props.fileExplorerRef
      && this.props.fileExplorerRef._selection) || item) {

      var selection = this.props.fileExplorerRef._selection.getSelection();
      if (item) selection = [item]

      if(selection.length === 0) return true;

      var locked = selection.filter(item => {
        return item.lock
      })
      if(locked.length > 0) return true;

      var accessDenied = selection.filter(item => {
        return (item.fileType === "dir" && !item.accessGranted)
      })
      if(accessDenied.length > 0) return true;
    }

    return false
  }

  private _checkTagEnabled() {
    if(this.props.fileExplorerType === "openFiles") return true;
    if(this.props.fileExplorerType === "offlineFiles") return true;

    if(this.props.fileExplorerRef
      && this.props.fileExplorerRef._selection) {

      var selection = this.props.fileExplorerRef._selection.getSelection();

      if(selection.length === 0) return true;

      var folders = selection.filter(item => {
        return item.fileType === "dir"
      })

      if(folders.length > 0) return true;
    }

    return false
  }

  private _checkStandardTagCategory() {
    var selection = this.props.fileExplorerRef._selection.getSelection();
    if (selection.length > 0 && selection[0].categoryTagId === selection[0].repo + "-tagCategory") {
      return true
    }
    return false
  }

  private _checkRenameEnabled() {
    if(this.props.fileExplorerType === "openFiles") return true;
    if(this.props.fileExplorerType === "offlineFiles") return true;

    if(this.props.fileExplorerRef
      && this.props.fileExplorerRef._selection) {

      var selection = this.props.fileExplorerRef._selection.getSelection();

      if(selection.length !== 1) return true;

      var locked = selection.filter(item => {
        return item.lock
      })
      if(locked.length > 0) return true;

      var accessDenied = selection.filter(item => {
        return (item.fileType === "dir" && !item.accessGranted)
      })
      if(accessDenied.length > 0) return true;
    }

    return false
  }

  private _checkVersionHistoryEnabled() {
    if(this.props.fileExplorerType === "openFiles") return true;
    if(this.props.fileExplorerType === "offlineFiles") return true;

    if(this.props.fileExplorerRef
      && this.props.fileExplorerRef._selection) {

      var selection = this.props.fileExplorerRef._selection.getSelection();

      if(selection.length !== 1) return true;

      var folders = selection.filter(item => {
        return item.fileType === "dir"
      })

      if(folders.length > 0) return true;
    }

    return false
  }

  private _checkAppendVersionEnabled(item = null) {
    if(this.props.fileExplorerType === "openFiles") return true;
    if(this.props.fileExplorerType === "offlineFiles") return true;

    if((this.props.fileExplorerRef
      && this.props.fileExplorerRef._selection) || item) {

      var selection = this.props.fileExplorerRef._selection.getSelection();
      if (item) selection = [item]

      if(selection.length !== 1 && selection.length !== 2) return true;

      var locked = selection.filter(item => {
        return item.lock
      })
      if(locked.length > 0) return true;

      var folders = selection.filter(item => {
        return item.fileType === "dir"
      })

      if(folders.length > 0) return true;
    }

    return false
  }

  private _checkCompareEnabled() {
    if(this.props.fileExplorerType === "openFiles") return true;
    if(this.props.fileExplorerType === "offlineFiles") return true;

    if(this.props.fileExplorerRef
      && this.props.fileExplorerRef._selection) {

      var selection = this.props.fileExplorerRef._selection.getSelection();

      if(selection.length !== 2 && selection.length !== 1) return true;

      var folders = selection.filter(item => {
        return item.fileType === "dir"
      })

      if(folders.length > 0) return true;

      var notWordDocs = selection.filter(item => {
        if(item.fileExtension !== "doc"
          && item.fileExtension !== "docx") {

          return true;
        }
        return false
      })

      return notWordDocs.length > 0;
    }

    return false
  }

  private showDownloadsCallout() {
    this._isMounted && this.setState({ showDownloadsCallout: true })
    setTimeout(() => {
      this.setState({ showDownloadsCallout: false })
    }, 3600)
  }

  private showUploadsCallout() {
    this._isMounted && this.setState({ showUploadsCallout: true })
    setTimeout(() => {
      this._isMounted && this.setState({ showUploadsCallout: false })
    }, 3600)
  }

  private _closeDialog = (): void => {
    this.setState({ hideCreateFolderDialog: true });
  };

  private _dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu
  };

  private _showNewFolderDialog: any = (parentId, parentName = '') => {
    this.setState({hideCreateFolderDialog: false, newFolderParent: parentId, parentName: parentName, newMenu: false, newFolderTags: 'KEEP_NEW', newFolderWarning: ''})
  }

  private _showNewTagDialog: any = () => {
    this.setState({
      showNewTagDialog: true, 
      tagName: '', 
      tagDescription: '', 
      tagType: 'SINGLE_SELECT',
      tagOptions: [],
      originalTags: [],
      savingTag: false,
    })
  }

  private _showEditTagDialog: any = (id: '') => {
    let tagId = id;
    if (tagId === '') {
      tagId = this.props.tagCategoryId;
      if (tagId === '') 
        tagId = this.props.fileExplorerRef._selection.getSelection()[0].categoryTagId;
    }
    userService.getCategoryTags(tagId).then((response:any) => {
      let category:any = response.data.tagCategory;
      let tags:any[] = response.data.tag;
      let originalTags:any[] = []
      for(let i=0; i<tags.length; i++) {
        originalTags.push(tags[i].id)
      }

      this.setState({
        showEditTagDialog: true, 
        tagName: category.name, 
        tagDescription: category.description, 
        tagType: category.tagSelection,
        tagOptions: tags,
        originalTags: originalTags,
        tagCategoryId: tagId,
        savingTag: false,
      })
    }).catch((error)=>{
      console.log(error)
    })
  }

  private _showNewSingleTagDialog: any = () => {
    this.setState({
      showNewSingleTagDialog: true, 
      tagName: '',
      tagColor: 'rgb(169, 169, 169,.7)',
      savingTag: false,
    })
  }

  private _showEditSingleTagDialog: any = () => {
    let selTag = this.props.fileExplorerRef._selection.getSelection()[0]
    this.setState({
      showSingleTagDialog: true, 
      tagName: selTag.name,
      tagColor: selTag.color,
      savingTag: false,
    })
  }

  private handleTagNameChange = (ev: any, newValue?: string) => {
    var value = '';
    if (newValue) value = newValue
    this._isMounted && this.setState({tagNameWarning: ''})
    if(value && this._containsInvalidChars(value)) {
      this.setState({ tagNameWarning: i18n.t('app:invalidChar') + " " + invalidChars.join("   ") })
    }
    else if (value.length > 50) {
      this.setState({ tagNameWarning: i18n.t('app:nameIsTooLong50') })
    }
    this._isMounted && this.setState({ tagName: value || ''});
  }

  private handleTagTypeChange = (ev: any, option: any) => {
    this.setState({ tagType: option.key || ''});
  }

  private handleTagDescriptionChange = (ev: any, newValue?: string) => {
    var value = '';
    if (newValue) value = newValue
    this._isMounted && this.setState({tagDescriptionWarning: ''})
    if(value && this._containsInvalidChars(value)) {
      this.setState({ tagDescriptionWarning: i18n.t('app:invalidChar') + " " + invalidChars.join("   ") })
    }
    this._isMounted && this.setState({ tagDescription: value || ''});
  }


  private _createFolder: any = (path, e) => {
    e.stopPropagation();
    e.preventDefault();

    const { newFolder, newFolderPermissions, newFolderTags } = this.state;

    if(this._containsInvalidChars(newFolder)) {
      // Invalid characters present
    } else if(newFolder.length > 0) {
      this._isMounted && this.setState({ savingFolder: true });

      let tags = newFolderTags;
      if (newFolderTags.includes(this.props.repoData[0].id)){
        tags = "NONE";
      }

      userService.createFolder(newFolder, path, newFolderPermissions, tags)
        .then((response: any) => {
          this._isMounted && this.setState({
            hideCreateFolderDialog: true,
            savingFolder: false,
            newFolder: '',
            newFolderParent: '',
            parentName: '',
            newFolderTags: 'KEEP_NEW'
          });

          var id = response.data.id
          if(id) history.push({search: "?selItem=" + id})
          
        }).catch(error => {
          this._isMounted && this.setState({
            hideCreateFolderDialog: true,
            savingFolder: false,
            newFolder: '',
            newFolderParent: '',
            parentName: '',
            newFolderTags: 'KEEP_NEW'
          });
          console.error(error)
        });
    }
  }

  private handleFolderChange = (ev: any, newValue?: string) => {
    if (!newValue || newValue.length <= 240) {
      this.setState({ newFolder: newValue || ''}, () => {
        const { items } = this.props;

        this.setState({ newFolderWarning: "" })

        if(newValue && this._containsInvalidChars(newValue)) {
          this.setState({ newFolderWarning: i18n.t('app:invalidChar') + " " + invalidChars.join("   ") })
        }

        var folders = items.filter(item => {
          return item.fileType === "dir";
        })

        for (let i = 0; i < folders.length; i++) {
          if (folders[i].name === newValue) {
            this.setState({ newFolderWarning: i18n.t('app:folderAlreadyExists') })
          }
        };

      });
    } else if (newValue.length > 240) {
      this.setState({ newFolderWarning: i18n.t('app:nameIsTooLong') })
    }
  }

  private handleFolderPermissionsChoice = (ev: any, newValue?: any) => {
    var migrateUsers = newValue === "true"
    this.setState({ newFolderPermissions: migrateUsers });
  }

  private handleFolderTagsChoice = (ev: any, newValue?: any) => {
    this.setState({ newFolderTags: newValue.key });
  }

  private _containsInvalidChars = (str: string)  => {
    for(var i in invalidChars){
        var invalidChar = invalidChars[i];
        if (str.indexOf(invalidChar) > -1){
            return true;
        }

    }
    return false;
  }

  private _confirmCompareWordDocs(items:any = null, file: any = null, toAppend: boolean = false) {
    var selDocs: any
    if (items) selDocs = items;
    else selDocs = this.props.fileExplorerRef._selection.getSelection();
    var files: any = []
    var index = 0;

    selDocs.forEach((item: any) => {
      var revisionId: any;
      if (items) {
        let merged = {...item, ...file }
        index += 1;
        revisionId = merged.fileId
        //var version = item.version
        merged.name = merged.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

        if (merged.draft) revisionId = file.id + "-draft"
        merged.revisions.forEach((revision: any) => {
          revision.key = revision.fileId
          revision.text = revision.version === "draft" ? merged.activeMajor+"."+merged.activeMinor + " " + i18n.t("app:draft") : revision.version
        })
        merged.title = merged.name
        merged.revisionId = revisionId
        merged.index = index
        files.push(merged)
      }
      else {
        revisionId = item.activeRevisionId;
        index += 1;
        item.fileName = item.fileName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        item.name = item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

        if (item.draft) revisionId = item.id + "-draft"
        item.revisions.forEach((revision: any) => {
          revision.key = revision.fileId
          revision.text = revision.version === "draft" ? item.activeMajor+"."+item.activeMinor + " " + i18n.t("app:draft") : revision.version
        })
        item.title = item.name
        item.revisionId = revisionId
        files.push(item)
      }
    })

    if (items) files.reverse();

    this._isMounted && this.setState({
      confirmCompareDocs: true,
      compareDocs: files,
      comparingDocs: true,
      toAppend: toAppend
    },()=>{
      if (items) {
        this._updateRevisionId(0,this.state.compareDocs[0].fileId)
        this._updateRevisionId(1,this.state.compareDocs[1].fileId)
      }
    })
  }

  private _appendDocs() {
    var files: any = this.state.compareDocs
    this._isMounted && this.setState({
      appendingFileAsVersion: true,
    })
    const modalBackgrounds:any = document.querySelectorAll(".ms-Overlay--dark");
    if (modalBackgrounds && modalBackgrounds.length > 1) {
      for (let i=0; i<modalBackgrounds.length; i++) {
        if (i !== modalBackgrounds.length-1) {
          modalBackgrounds[i].style.backgroundColor = 'rgba(0,0,0,0)';
        }
      }
    }

    let message = i18n.t('app:appendedFile') + ": " + files[1].fileName + " v" + files[1].activeMajor + "." + files[1].activeMinor + " " + (files[1].draft ? i18n.t('app:draft') : "");
    let messageDraft = i18n.t('app:appendedDraft');

    userService.appendSynergyFileAsVersion(files[1].id, files[1].revisionId, files[0].id, message, false, false, files[0].draft, messageDraft, false).then((response: any) => {
      console.log('Appended file as version')
      this._isMounted && this.setState({
        confirmCompareDocs: false,
        appendingFileAsVersion: false,
      })
    }).catch(error =>  {
      this._isMounted && this.setState({
        appendingFileAsVersion: false,
      })
      console.log(error)
    });

  }

  private _updateRevisionId(index:number, key:any) {
    var files: any = this.state.compareDocs
    files[index].revisionId = key
    this._isMounted && this.setState({compareDocs: files})
  }

  private _addFileToCompare(index: number) {
    var input = document.createElement('input');
    input.type = 'file';
    input.setAttribute("single","");
    input.accept= '.doc, .docx'

    input.onchange = e => {
      if(this.props.appPlatform === ("electron" || "openfin")) {
        let event:any = e as Event;
        event.stopPropagation();
        event.preventDefault();

        var item = event.target.files[0];
        item.fileName = item.name.replace(item.name.substr(item.name.lastIndexOf('.')), '');
        item.localFile = true;
        var compareDocs = this.state.compareDocs;
        compareDocs[index] = item;
        this._isMounted && this.setState({compareDocs: compareDocs});
        
      }

    }

    input.click();
  }

  private _askUserToDeleteItems = async (folders, files, prevItem) => {
    return await new Promise((resolve, reject) => {
      var showDialog = true;

      var deleteItems = () => {
        showDialog = false;
        renderDialog()
        resolve(true)
      }

      var cancelDelete = () => {
        showDialog = false;
        renderDialog()
        reject("Operation rejected by user");
        setTimeout(()=> this._focusSelElements(), 300)
      }

      var div = document.createElement('div');
      var renderDialog = () => {
        ReactDOM.render(
          <Dialog
              hidden={!showDialog}
              onDismiss={cancelDelete}
              dialogContentProps={{
                type: DialogType.normal,
                title: i18n.t('app:deleteItems')
              }}
              modalProps={{
                isBlocking: false,
                styles: { main: { maxWidth: 640 } },
                dragOptions: undefined,
                className: "ask-delete-item-dialog"
              }}
            >
              <div className="w-100">
                <Text block variant={'large'}>
                  { folders.length > 0 && files.length === 0 ?
                    i18n.t('app:deleteAreYouSure') + " " + folders.length + (folders.length === 1 ? " " + i18n.t('app:lowerFolder') + "?" : " " + i18n.t('app:lowerFolders') + "?")
                  : folders.length === 0 && files.length > 0 ?
                  i18n.t('app:deleteAreYouSure') + " " + files.length + (files.length === 1 ? " " + i18n.t('app:lowerFile') + "?" : " " + i18n.t('app:lowerFiles') + "?")
                  :
                  i18n.t('app:deleteAreYouSure') + " " + folders.length + (folders.length === 1 ? " " + i18n.t('app:lowerFolder')  : " " + i18n.t('app:lowerFolders') ) + " " + i18n.t('app:deleteAnd') + " " + files.length + (files.length === 1 ? " " + i18n.t('app:lowerFile') + "?" : " " + i18n.t('app:lowerFiles') + "?")
                  }
                </Text>

                <p className="tip text-secondary mt-3">
                  <Icon iconName="Info" className="align-bottom mr-1" /> <span className="font-weight-bold">Keyboard shortcut:</span> you can delete items by pressing <span className="keyStyles">Delete</span>.
                </p> 
                
                <DialogFooter>
                  <DefaultButton onClick={cancelDelete} text={i18n.t('app:cancel')} />
                  <PrimaryButton autoFocus onClick={ deleteItems } text={i18n.t('app:delete')} />
                </DialogFooter>
              </div>
            </Dialog>
          , div
        )
      }

      renderDialog();
    })
  }

  private _askUserToDeleteTags = async (selection) => {
    return await new Promise((resolve, reject) => {
      var showDialog = true;

      var deleteItems = () => {
        showDialog = false;
        renderDialog()
        resolve(true)
      }

      var cancelDelete = () => {
        showDialog = false;
        renderDialog()
        reject("Operation rejected by user");
        setTimeout(()=> this._focusSelElements(), 300)
      }

      var div = document.createElement('div');
      var renderDialog = () => {
        ReactDOM.render(
          <Dialog
              hidden={!showDialog}
              onDismiss={cancelDelete}
              dialogContentProps={{
                type: DialogType.normal,
                title: i18n.t('app:deleteItems')
              }}
              modalProps={{
                isBlocking: false,
                styles: { main: { maxWidth: 640 } },
                dragOptions: undefined,
                className: "ask-delete-item-dialog"
              }}
            >
              <div className="w-100">
                <Text block variant={'large'}>
                  { selection.length > 1 ?
                    i18n.t('app:deleteAreYouSure') + " " + i18n.t('app:theseTags') +  "?"
                    : i18n.t('app:deleteAreYouSure') + " " + i18n.t('app:thisTag') + "?"
                  }
                </Text>

                <p className="tip text-secondary mt-3">
                  <Icon iconName="Info" className="align-bottom mr-1" /> <span className="font-weight-bold">Keyboard shortcut:</span> you can delete items by pressing <span className="keyStyles">Delete</span>.
                </p> 
                
                <DialogFooter>
                  <DefaultButton onClick={cancelDelete} text={i18n.t('app:cancel')} />
                  <PrimaryButton autoFocus onClick={ deleteItems } text={i18n.t('app:delete')} />
                </DialogFooter>
              </div>
            </Dialog>
          , div
        )
      }

      renderDialog();
    })
  }

  private _focusSelElements() {
    var elements: any = document.getElementsByClassName('ms-DetailsRow is-selected')
    if (elements) {
      for (let i = 0; i < elements.length; i++) {
        let el = elements[i]
        el.focus()
      }
    }
  }

  private _deleteItems(selItems) {
    var prevItem = selItems[0]

    var documentList:any[] = selItems.filter((item: any) => {
      return item.fileType === "file"
    });

    var folderList:any[] = selItems.filter((item: any) => {
      return item.fileType === "dir"
    });
    
    var deleteItems = async () => {

      // Delete documents
      await new Promise((resolve) => {
        var documentsIds: any[] = [];
        for (let i = 0; i < documentList.length; i++) {
          let document = documentList[i];
          let id = document.id;
          documentsIds.push(id)
        };

        if(documentsIds.length > 0) {
          userService.deleteDocuments(documentsIds).then(response => {
            resolve(true);
          }).catch(error => {
            console.log(error)
            if(error.status !== 405) {
              this._isMounted && this.setState({
                errorMessage: error.data.message
              })
            }
          })
        } else {
          resolve(true);
        }
      });
    
      // Delete folders
      await new Promise((resolve) => {
        var foldersIds: any[] = [];
        for (let i = 0; i < folderList.length; i++) {
          let folder = folderList[i];
          let id = folder.id;
          foldersIds.push(id)
        };

        if(foldersIds.length > 0) {
          userService.deleteFolders(foldersIds).then(response => {
            resolve(true);
            if (foldersIds.includes(this.props.folderId)) {
              userService.getFolderDetails(this.props.folderId).then((response) => {
                history.push('/files/'+response.data.parent_id)
              }).catch((error) => {
                console.log(error)
                history.push('/')
              })
            }
          }).catch((error) => {
            console.log(error)
            if(error.status !== 405) {
              this._isMounted && this.setState({
                errorMessage: error.data.message
              })
            }
            resolve(true);
          })
        } else {
          resolve(true);
        }

      });
    }

    this._askUserToDeleteItems(folderList, documentList, prevItem).then((response:any) => {
      this._isMounted && this.setState({ deletingItem: true }, () => {
        const indexes = this.props.fileExplorerRef._selection.getSelectedIndices();
        const nextIndex = indexes[indexes.length-1] + 1;
        this.props.fileExplorerRef._selection.setAllSelected(false)
        deleteItems().then(() => {
          if(!this.state.errorMessage) {
            this._isMounted && this.setState({
              deletingItem: false
            });
          }

          this.props.fileExplorerRef && this.props.fileExplorerRef._setSelByIndexItem(nextIndex)
        })
      })
    }).catch(reject => {console.log(reject)})
  }

  private _restoreItems() {
    this._isMounted && this.setState({ restoringItem: true }, () => {
      this.props.fileExplorerRef._selection.setAllSelected(false)
    })

    var selItems = this.props.fileExplorerRef._selection.getSelection();

    var documentList:any[] = selItems.filter((item: any) => {
      return item.fileType === "file"
    });

    var folderList:any[] = selItems.filter((item: any) => {
      return item.fileType === "dir"
    });
    
    var restoreItems = async () => {

      // Restore documents
      await new Promise((resolve) => {
        var documentsIds: any[] = [];
        for (let i = 0; i < documentList.length; i++) {
          let document = documentList[i];
          let id = document.id;
          documentsIds.push(id)
        };

        if(documentsIds.length > 0) {
          userService.restoreDocuments(documentsIds).then(response => {
            resolve(true);
          }).catch((error) => {
            console.log(error)
            this._isMounted && this.setState({
              errorMessage: error.data.message
            })
            resolve(true);
          })
        } else {
          resolve(true);
        }
      });
    
      // Restore folders
      await new Promise((resolve) => {
        var foldersIds: any[] = [];
        for (let i = 0; i < folderList.length; i++) {
          let folder = folderList[i];
          let id = folder.id;
          foldersIds.push(id)
        };

        if(foldersIds.length > 0) {
          userService.restoreFolders(foldersIds).then(response => {
            resolve(true);
          }).catch((error) => {
            console.log(error)
            this._isMounted && this.setState({
              errorMessage: error.data.message
            })
            resolve(true);
          })
        } else {
          resolve(true);
        }

      });
    }

    restoreItems().then(() => {
      if(!this.state.errorMessage) {
        this._isMounted && this.setState({
          restoringItem: false
        });
      }
    });
  }

  private _createOfficeDocument(officeApp) {
    var showDialog = true;
    var parentId = this.props.folderId;
    var message = "File created"
    var appName = "";
    var fileName = "New";
    var newFileTags = "";
    var fileExtension = "";
    var createFileWarning = "";
    this.setState({newMenu: false})

    if (officeApp === "WORD") {
      appName = "Word";
      fileExtension = ".docx"
    } else if (officeApp === "EXCEL") {
      appName = "Excel";
      fileExtension = ".xlsx"
    } else if (officeApp === "POWERPOINT") {
      appName = "PowerPoint";
      fileExtension = ".pptx"
    }

    var handlVersionMessageChange = (e, newValue) => {
      if(newValue.length <= 240) {
        createFileWarning = ""

        if(newValue && this._containsInvalidChars(newValue)) {
          createFileWarning= i18n.t('app:invalidChar') + " " + invalidChars.join("   ")
        }

        fileName = newValue
      } else if(newValue.length > 240) {
        createFileWarning = i18n.t('app:nameTooLong')
      }

      renderDialog();
    }

    var _createFile = (e) => {
      e.stopPropagation();
      e.preventDefault();

      if(!this._containsInvalidChars(fileName)) {
        this._isMounted && this.setState({creatingOfficeDoc: true})
        showDialog = false;
        renderDialog();
  
        userService.createOfficeDoc(parentId, message, officeApp, fileName, newFileTags).then(async response => {
          var fileId = response.data

          this._isMounted && this.setState({creatingOfficeDoc: false})
          var counter = 0;
          if (this.state.openOfficeDocAfterCreating) {
            const tryToOpenDocument = ()=> {
              setTimeout(()=> {
                counter++;
                if (this.state.selFile && this.state.selFile.id === fileId && this.props.fileExplorerRef ) {
                  const file = this.state.selFile
                  counter = 10;
                  if (isElectron()) {
                    this.props.fileExplorerRef._openDocument(file.id, file.activeRevisionId, file.hash, file.fileName, file.fileExtension, file.lock, false, file, false)
                  }
                  else {
                    this.props.fileExplorerRef._openOfficeOnline(file.activeRevisionId, "edit", file.lock, file.wopiLock)
                  }
                }
                if (counter <= 5) tryToOpenDocument();
              }, 500)
            }
            tryToOpenDocument();
          }

          var id = response.data
          if(id) history.push({search: "?selItem=" + id})
          
        }).catch(error => {
          console.log(error)
          this._isMounted && this.setState({creatingOfficeDoc: false})
        })
      }
    }

    var div = document.createElement('div');
    var renderDialog = () => {
      ReactDOM.render(
        <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: <div className='d-flex align-items-center'><Icon className='mr-2' {...getFileTypeIconProps({ extension: fileExtension, size: 32, imageFileType: 'svg' }) } />{'New ' + appName + ' document'}</div>
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "creating-office-doc"
            }}
            onDismiss={() => {showDialog = false; renderDialog()}}
          >
            <div className="dialog-content-wrap">
              <p className='pb-2 m-0' style={{fontWeight:500}}>{i18n.t('app:parentFolder')}</p>
              <span><Icon className='mr-1' style={{writingMode:'vertical-rl'}} {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } /><span style={{verticalAlign:'top'}}>{this.state.breadcrumbPath[this.state.breadcrumbPath.length-1].text}</span></span>
              <p className='m-0 pt-2' style={{fontWeight:500}}>{i18n.t('app:filename')}</p>
              <form className="pt-2" name="form" onSubmit={ _createFile } >
                <TextField autoFocus underlined placeholder={i18n.t('app:filename')} name="newMessage" value={ fileName } onChange={ handlVersionMessageChange } suffix={fileExtension} errorMessage={ createFileWarning } required />
              </form>
              <ChoiceGroup defaultSelectedKey="KEEP_NEW"
              options={[
                { key: "KEEP_NEW", text: i18n.t('app:keepNewTags') },
                { key: "NONE", text: i18n.t('app:noTags') }
              ]}
              label={i18n.t('app:tags')+":"}
              required={true}
              className="folder-permissions-choice mt-3"
              onChange={ (e,v) => {newFileTags = v!.key} }
              />

              <DialogFooter>
                <Checkbox
                  styles={{root:{textAlign: 'left', position: 'relative', bottom: -32}}}
                  label={i18n.t('app:openAfterSaving')}
                  checked={this.state.openOfficeDocAfterCreating}
                  onChange={()=>this.setState({openOfficeDocAfterCreating: !this.state.openOfficeDocAfterCreating}, ()=>renderDialog())}
                />
                <DefaultButton onClick={() => { showDialog = false; renderDialog();}} text="Cancel" />
                <PrimaryButton onClick={ _createFile } disabled={ !fileName.length || this._containsInvalidChars(fileName) }>
                  Create
                </PrimaryButton>
              </DialogFooter>
            </div>
          </Dialog>
        , div
      )
    }
    this.setState({openOfficeDocAfterCreating: true},()=>renderDialog());
  }

  private _setFocusItem(id) {
    var route = history.location.pathname
    var storedLastFocusedItems = JSON.parse(localStorage.getItem("lastFocusedItems") || "[]")

    var alreadyStored = storedLastFocusedItems.filter(item => {
      return item.route === route
    })[0]
    
    if(alreadyStored) {
      alreadyStored.item = id
    } else {
      var newFocusedItem = {
        route: route,
        item: id
      }
      storedLastFocusedItems.push(newFocusedItem)
    }

    localStorage.setItem("lastFocusedItems", JSON.stringify(storedLastFocusedItems))
  }

  private _askUserForRepeatedItem = async (item:any , moreThanOneItem:boolean, repeatedItems:number = 0, currentIndex:number = 0) => {
    return await new Promise((resolve, reject) => {
      var showDialog = true;

      var uploadItem = () => {
        showDialog = false;
        if (sameForAll) this._sameForAllOption = 'upload';
        renderDialog()
        resolve(true)
      }

      var replaceItem = () => {
        showDialog = false;
        if (sameForAll) this._sameForAllOption = 'replace';
        renderDialog()
        reject('replace')
      }

      var appendItem = () => {
        showDialog = false;
        if (sameForAll) this._sameForAllOption = 'append';
        renderDialog()
        reject('append');
      }

      var skipItem = () => {
        showDialog = false;
        if (sameForAll) this._sameForAllOption = 'skip';
        renderDialog()
        reject();
      }

      var sameForAll: boolean = false;

      var div = document.createElement('div');
      var renderDialog = () => {
        ReactDOM.render(
          <Dialog
              hidden={!showDialog}
              dialogContentProps={{
                type: DialogType.normal,
                title: moreThanOneItem ? (i18n.t('app:sameFoldersName') + (item.kind === "dir" ? i18n.t('app:lowerFolders') : i18n.t('app:lowerFiles'))  + i18n.t('app:sameFolderName2') + ((repeatedItems > 1 ? (currentIndex + '/' + repeatedItems) : '')))
                : i18n.t('app:sameFolderName') + (item.kind === "dir" ? i18n.t('app:lowerFolder') : i18n.t('app:lowerFile'))  + i18n.t('app:sameFolderName2') + (repeatedItems > 1 ? (currentIndex + '/' + repeatedItems) : '')
              }}
              modalProps={{
                isBlocking: true,
                styles: { main: { maxWidth: 640 } },
                dragOptions: undefined,
                className: "repeated-name-dialog"
              }}
            >
              <div className="w-100">
                { item.kind === "dir" ?
                  <span><Icon className='mr-1 mb-1' style={{writingMode:'vertical-rl'}} {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } /><span style={{verticalAlign:'top'}}>{item.fileName}</span></span>
                : <span><Icon className='mr-1 mb-1' style={{writingMode:'vertical-rl'}} {...getFileTypeIconProps({ extension: item.fileExtension, size: 20, imageFileType: 'svg' }) } /><span style={{verticalAlign:'top'}}>{item.fileName}</span></span>
                }
                <p className='p-0 m-0' style={{fontWeight:600}}>{i18n.t('app:chooseOptions')}</p>
                <ul className="m-0 p-0">
                  {!this._checkDeleteEnabled(item) && !moreThanOneItem && item.kind !== "dir" ? 
                  <li className="mt-2 p-2 bg-grey" onClick={replaceItem}>
                    <Icon iconName="Sync" className="text-primary mr-2" />
                    {i18n.t('app:replaceFile') }
                  </li>
                  :<li className="mt-2 p-2 bg-grey disabled">
                    <Icon iconName="Sync" className="text-primary mr-2" />
                    {i18n.t('app:replaceFile') }
                  </li>}
                  {!this._checkAppendVersionEnabled(item) && !moreThanOneItem ? <li className="mt-2 p-2 bg-grey" onClick={appendItem}>
                    <Icon iconName="Copy" className="text-success mr-2"/>
                    {i18n.t('app:appendFile') }
                  </li>
                  : <li className="mt-2 p-2 bg-grey disabled">
                    <Icon iconName="Copy" className="text-success mr-2"/>
                    {i18n.t('app:appendFile') }
                  </li>}
                  <li className="mt-2 p-2 bg-grey" onClick={uploadItem}>
                    <Icon iconName="SaveAll" className="mr-2" style={{color: 'rgb(0, 120, 212)'}} />
                    {i18n.t('app:saveAnyway') }
                  </li>
                  {(repeatedItems > 1) && <Checkbox
                    className='mt-3'
                    styles={{root:{position: 'absolute', bottom: 0}}}
                    label={i18n.t('app:doThisForAll')}
                    checked={sameForAll}
                    onChange={()=>{sameForAll=!sameForAll;this._sameChoiceForAll=sameForAll;renderDialog()}}
                  />}
                  
                  <div className="text-right">
                    <DefaultButton className='mt-3' onClick={skipItem} text={i18n.t('app:Cancel')}/>
                  </div>
                </ul>  
              </div>
            </Dialog>
          , div
        )
      }

      if (this._sameChoiceForAll) {
        if (this._sameForAllOption === 'replace' && !this._checkDeleteEnabled(item) && !moreThanOneItem) reject('replace');
        else if (this._sameForAllOption === 'append' && !this._checkAppendVersionEnabled(item) && !moreThanOneItem) reject('append');
        else if (this._sameForAllOption === 'upload') resolve(true);
        else if (this._sameForAllOption === 'skip') reject();
        else renderDialog();
      }
      else {
        renderDialog();
      }
    })
  }

  private _askUserToInheritTags = async (upload) => {
    var toggleTags = false;
    var tagsChoice = (this.props.folderId !== this.props.repoData[0].id && this.props.folderId !== this.props.repoData.id+"-templates") ? i18n.t('app:keepNewTags') : i18n.t('app:noTags');
    const destination = this.state.parentName.length > 0 ? this.state.parentName : this.state.breadcrumbPath[this.state.breadcrumbPath.length-1].text;
    return await new Promise((resolve, reject) => {
      var showDialog = true;

      var inheritTags = () => {
        showDialog = false;
        renderDialog()
        resolve(true)
      }

      var cancelInherit = () => {
        showDialog = false;
        renderDialog()
        reject("Operation rejected by user");
        setTimeout(()=> this._focusSelElements(), 300)
      }

      var div = document.createElement('div');
      var renderDialog = () => {
        ReactDOM.render(
          <Dialog
              hidden={!showDialog}
              onDismiss={cancelInherit}
              dialogContentProps={{
                type: DialogType.normal,
                title: i18n.t('app:uploadingItems')
              }}
              modalProps={{
                isBlocking: false,
                styles: { main: { maxWidth: 640 } },
                dragOptions: undefined,
                className: "ask-delete-item-dialog"
              }}
            >
              <div className="w-100">
              <p className='pb-2 m-0' style={{fontWeight:600}}>{i18n.t('app:destination')}:</p>
              <span><Icon className='mr-1' style={{writingMode:'vertical-rl'}} {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } /><span style={{verticalAlign:'top'}}>{destination}</span></span>
              <div className='d-flex mt-3 text-left'>
                <IconButton className='my-auto tags-chevron pl-0 w-100 h-auto text-left' style={{verticalAlign:'middle'}} iconProps={{iconName: toggleTags ? 'ChevronDown' : 'ChevronRight', color: 'black'}} onClick={()=>{toggleTags=!toggleTags;renderDialog()}}>
                  <span className='my-auto ml-0'>{tagsChoice +" ("+i18n.t('app:clickMoreOptions') + ")"}</span>
                </IconButton>
              </div>
              <div style={{transitionDuration: '250ms', maxHeight: toggleTags ? '1000px' : 0, overflow: 'hidden'}}>
              <ChoiceGroup defaultSelectedKey={(this.props.folderId !== this.props.repoData[0].id && this.props.folderId !== this.props.repoData.id+"-templates") ? "KEEP_NEW" : "NONE"}
                options={ (this.props.folderId !== this.props.repoData[0].id && this.props.folderId !== this.props.repoData.id+"-templates") ? 
                  !upload ? [
                    { key: "KEEP_NEW", text: i18n.t('app:keepNewTags') },
                    { key: "KEEP_OLD", text: i18n.t('app:keepCurrentTags') },
                    { key: "NONE", text: i18n.t('app:noTags') }
                  ]
                  : [
                    { key: "KEEP_NEW", text: i18n.t('app:keepNewTags') },
                    { key: "NONE", text: i18n.t('app:noTags') }
                  ]
                  : !upload ? [
                    { key: "NONE", text: i18n.t('app:noTags') },
                    { key: "KEEP_OLD", text: i18n.t('app:keepNewTags') }
                  ]
                  : [
                    { key: "NONE", text: i18n.t('app:noTags') }
                  ]
                }
                label={i18n.t('app:tags')+":"}
                required={true}
                className="folder-permissions-choice mt-2"
                onChange={ (e,v) => {this.setState({inheritTags: v!.key});tagsChoice=v!.text;renderDialog()} }
                onKeyDown={(event)=> {
                  if (event.key === "Enter") {
                    inheritTags()
                  }
                }}
              />
              </div>
              <p className="tip text-secondary mt-3">
                <Icon iconName="Info" className="align-bottom mr-1" /> {i18n.t('app:youCanPress')} <span className='keyStyles'>Enter</span> {i18n.t('app:toConfirmSelection')}
              </p>
                
                <DialogFooter>
                  <DefaultButton onClick={cancelInherit} text={i18n.t('app:cancel')} />
                  <PrimaryButton autoFocus onClick={ inheritTags } text={i18n.t('app:upload')} />
                </DialogFooter>
              </div>
            </Dialog>
          , div
        )
      }

      if (this._askUsertags) {
        renderDialog();
      }
      else {
        this._askUsertags = true;
        inheritTags();
      }
    })
  }

  private _addFiles() {
    var input = document.createElement('input');
    input.type = 'file';
    input.setAttribute("multiple","");

    input.onchange = e => {
      if(this.props.appPlatform === ("electron" || "openfin")) {
        let event:any = e as Event;
        event.stopPropagation();
        event.preventDefault();

        let fileEntries:any = {
          files: [],
          folders: []
        };
        var items = event.target.files;

        this._isMounted && this.setState({inheritTags: (this.props.folderId !== this.props.repoData[0].id && this.props.folderId !== this.props.repoData.id+"-templates") ? "KEEP_NEW" : "NONE"})
        this._askUserToInheritTags(true).then(async() => {
          //Header Electron
          //Get number of repeated items
          this._sameChoiceForAll = false;
          var repeatedItems = 0;
          for (let i = 0; i < items.length; i++) {
            let item = items[i];
            var currentItems = this.props.items;
            let repeated = currentItems.filter(data => {
              return data.fileName === item.name;
            })
            if (repeated.length > 0) repeatedItems++;
          }
          for (let i = 0; i < items.length; i++) {
            let item = items[i]
            let isFile = item.path.split('/').pop().indexOf('.') > -1;
            if(isFile) {
              let fileName = item.path.replace(/^.*[\\/]/, '')
    
              if(!fileName.startsWith(".")) {
                let repeatedItem = currentItems.filter(data => {
                  return data.fileName === item.name;
                })
                if (repeatedItem.length > 0) {
                  const repeatedLength = repeatedItem.length >= 2;
                  repeatedItem = repeatedItem[0]
                  await new Promise((resolve, reject) => {
                    const index = i+1;
                    this._askUserForRepeatedItem(repeatedItem, repeatedLength, repeatedItems, index).then((data) => {
                      fileEntries.files.push({
                        absolutePath: item.path,
                        name: fileName,
                        fullPath: "/" + item.webkitRelativePath
                      })
                      resolve(true);
                    }).catch(async(reject)=>{
                      if (reject === 'append') {
                        resolve(true);
                        this._appendVersion(repeatedItem, item, repeatedItems > 1)
                      }
                      else if (reject === 'replace') {
                        await userService.deleteDocuments([repeatedItem.id]).then(()=>{
                          fileEntries.files.push({
                            absolutePath: item.path,
                            name: fileName,
                            fullPath: "/" + item.webkitRelativePath
                          })
                        })
                      }
                      resolve(true);
                    })
                  })
                }
                else {
                  fileEntries.files.push({
                    absolutePath: item.path,
                    name: fileName,
                    fullPath: "/" + item.webkitRelativePath
                  })
                }
              }
            }
          }

          this.props.setItemsToUpload(fileEntries, items, this.state.inheritTags)
          this._sameChoiceForAll = false;
        }).catch(reject => {console.log(reject)})
      } else {
        this.showUploadsCallout()

        let event:any = e as Event;
        event.stopPropagation();
        event.preventDefault();

        var filesList = event.target.files
        var destination = this.props.folderId

        this._isMounted && this.setState({inheritTags: (this.props.folderId !== this.props.repoData[0].id && this.props.folderId !== this.props.repoData.id+"-templates") ? "KEEP_NEW" : "NONE"})
          this._askUserToInheritTags(true).then(() => {
          this._sameChoiceForAll = false;
          userService.createActivityFeedId().then(response => {
            var activityFeedId = response.data
            var files: any = [];
            var filesData: any = [];

            var userData = this.props.userData
            var repos = userData.repositoryDetailsList;
            var repo = repos[0].repository.id
            
            for (let i = 0; i < filesList.length; i++) {
              var file: any = filesList[i]
              var fileData: any = {};
              
              var src = window.URL.createObjectURL(file)
              console.log("File src", src)

              fileData.fileName = file.name
              fileData.absolutePath = src
              fileData.fileId = "File-" + uuid()
              fileData.destination = destination
              fileData.activityFeedId = activityFeedId
              fileData.origin = userService.getInstanceId()
              fileData.repo = repo
              
              var fileContent = fileData
              fileContent.file = file
              fileContent.file._id = fileData._id

              if(!file.name.startsWith(".")) {
                files.push(fileContent)
                filesData.push(fileData)
              }
            }

            userService.addFilesToUploadsDb(files).then(() => {
              console.log("Imported files success, start uploading")
              this._startUploadsWebapp()
            }).catch(error => {
              console.log(error)
            })
          }).catch(error => {
            console.log(error)
          })
        }).catch(reject => {console.log(reject)})
      }

    }

    input.click();
  }

  acceptedFiles: any = []
  acceptedFolders: any = []

  uploadsLimit: number = 3
  uploadingFilesCount: number = 0

  private getBlob = async(blobUrl, fileId) => {
    try {
      return await fetch(blobUrl)
      .then(r => {
        if(r.ok) return r
        throw new Error('Something went wrong while fetching', blobUrl);
      })
      .then(response => {
        return response.blob()
      })
      .catch(error => {
        console.log(error)
        userService.updateUploadFiles([fileId], "ERROR")
        this.uploadingFilesCount = this.uploadingFilesCount -1
        if(this.uploadingFilesCount === 0) this._startUploadsWebapp()
        return null
      });
    } catch (error) {
      console.log(error)
      userService.updateUploadFiles([fileId], "ERROR")
      this.uploadingFilesCount = this.uploadingFilesCount -1
      if(this.uploadingFilesCount === 0) this._startUploadsWebapp()
      return null
    }
  }

  private _uploadFile = async(file) => {
    await new Promise((resolve) => {
        this.getBlob(file.absolutePath, file.fileId).then(async response => {
          var blob:any = response
          if(blob) {
            let filename = file.fileName.split('.')
            if (filename[filename.length-1] !== "svg" || (filename[filename.length-1] === "svg" && await sanitizeSVG(blob))) {
              var fileFromBlob = new File([blob], file.fileName);
              let dropFormData = new FormData();
              dropFormData.append('file', fileFromBlob, file.fileName);
              dropFormData.append("activityFeedId", file.activityFeedId)
              
              userService.uploadFile(dropFormData, file.fileId, file.destination, this.state.inheritTags).then((response: any) => {
                console.log("File uploaded", response.data)
                this.uploadingFilesCount = this.uploadingFilesCount -1
                if(this.uploadingFilesCount === 0) this._startUploadsWebapp()
                resolve(true);
              }).catch(error => {
                console.log(error)
                userService.updateUploadFiles([file.fileId], "ERROR")
                this.uploadingFilesCount = this.uploadingFilesCount - 1
                if(this.uploadingFilesCount === 0) this._startUploadsWebapp()
                resolve(true);
              });
  
              this.uploadingFilesCount = this.uploadingFilesCount + 1
  
              if(this.uploadingFilesCount < this.uploadsLimit) resolve(true)
            }
            else {
              alert("Upload blocked. This file may contain malicious code.")
              userService.updateUploadFiles([file.fileId], "ERROR")
              this.uploadingFilesCount = this.uploadingFilesCount - 1
              if(this.uploadingFilesCount === 0) this._startUploadsWebapp()
              resolve(true);
            }
          }
        });
    });
  }

  private _addFolder() {
    var input = document.createElement('input');
    input.type = 'file';
    input.setAttribute("webkitdirectory","");
    input.setAttribute("mozdirectory","");

    let acceptedFiles: any = [];
    let folders:any = [];

    this._isMounted && this.setState({inheritTags: (this.props.folderId !== this.props.repoData[0].id && this.props.folderId !== this.props.repoData.id+"-templates") ? "KEEP_NEW" : "NONE"})
    userService.createActivityFeedId().then(response => {
      var activityFeedId = response.data
    
      input.onchange = e => {
        if(this.props.appPlatform === ("electron" || "openfin")) {
          let event:any = e as Event;
          event.stopPropagation();
          event.preventDefault();

          let fileEntries:any = {
            files: [],
            folders: []
          };
          var items = event.target.files;
    
          this._askUserToInheritTags(true).then(async() => {
          //Dektop Header Folders
          //Get number of repeated items
          this._sameChoiceForAll = false;
          var repeatedItems = 0;
          for (let i = 0; i < items.length; i++) {
            let item = items[i];
            let itemPath = item.path.replace(/\\/g, "/");
            let folderPath = itemPath.substring(0, itemPath.lastIndexOf("/"));
            let folderName = folderPath.match(/([^/]*)\/*$/)[1]

            var currentItems = this.props.items;
            let repeated = currentItems.filter(data => {
              return data.name === folderName;
            })
            if (repeated.length > 0) repeatedItems++;
          }

          for (let i = 0; i < items.length; i++) {
            let item = items[i]
            let isFile = item.path.split('/').pop().indexOf('.') > -1;
            if(isFile) {
              let fileName = item.path.replace(/^.*[\\/]/, '')
    
              if(!fileName.startsWith(".")) {
                fileEntries.files.push({
                  absolutePath: item.path,
                  name: fileName,
                  fullPath: "/" + item.webkitRelativePath
                })

                let itemPath = item.path.replace(/\\/g, "/");
                let folderPath = itemPath.substring(0, itemPath.lastIndexOf("/"));
                let folderAlreadySet = fileEntries.folders.filter(folder => {
                  return folder.absolutePath === folderPath
                })[0]

                if(!folderAlreadySet) {
                  let folderName = folderPath.match(/([^/]*)\/*$/)[1]
                  let fullPath = item.webkitRelativePath.substring(0, item.webkitRelativePath.lastIndexOf("/"));

                  let repeatedItem = currentItems.filter(data => {
                    return data.name === item.folderName;
                  })
                  if (repeatedItem.length > 0) {
                    const repeatedLength = repeatedItem.length >= 2;
                    repeatedItem = repeatedItem[0]
                    await new Promise((resolve) => {
                      const index = i+1;
                      this._askUserForRepeatedItem(repeatedItem, repeatedLength, repeatedItems, index).then((data) => {
                        fileEntries.folders.push({
                          absolutePath: folderPath,
                          name: folderName,
                          fullPath: "/" + fullPath
                        })
                        resolve(true);
                      }).catch(async(reject)=>{
                        resolve(true);
                      })
                    })
                  }
                  else {
                    fileEntries.folders.push({
                      absolutePath: folderPath,
                      name: folderName,
                      fullPath: "/" + fullPath
                    })
                  }
                }
              }
            }
          }

          this.props.setItemsToUpload(fileEntries, items, this.state.inheritTags)
          }).catch(reject => {console.log(reject)})

        } else {
          this.showUploadsCallout()

          this.acceptedFiles = [];
          this.acceptedFolders = [];

          let newFolders: any = [];

          let event:any = e as Event;
          event.stopPropagation();
          event.preventDefault();

          for (let i = 0; i < event.target.files.length; i++) {
            const file = event.target.files[i]
            this.acceptedFiles.push(file)
            acceptedFiles.push(file)

            var filePath = file.webkitRelativePath.split("/")
            if (filePath.length > 1) filePath.pop();
            
            for (let i = 0; i < filePath.length; i++) {
              let dir  = filePath[i]

              var folderRepeated = folders.filter(folder => {
                return folder.name === dir;
              })

              if(folderRepeated.length === 0) {
                folders.push({
                  name: dir,
                  fullPath: "/" + filePath.join("/"),
                  subFolders: []
                })
              }
            }
          }

          this._askUserToInheritTags(true).then(() => {

          this._sameChoiceForAll = false;
          this.acceptedFolders = folders
            
          var createFolders = async (folders) => {
            this._isMounted && this.props.callbackFunction({ creatingFoldersStructure: true })
            //Web Folders
            //Get number of repeated items
            var repeatedItems = 0;
            for (let i = 0; i < folders.length; i++) {
              let item = folders[i];
              var currentItems = this.props.items;
              let repeated = currentItems.filter(data => {
                return data.fileName === item.name;
              })
              if (repeated.length > 0) repeatedItems++;
            }
            for (let i=0; i<folders.length; i++) {
              let folder = folders[i];
              var parents = folder.fullPath.split("/");
              let path = this.props.folderId;
                if (parents.length > 2) {
                  let parent = parents.slice(0, parents.length - 1).join("/");
                  var parentId = folders.filter(item => {
                    return item.fullPath === parent
                  })
                  if(parentId[0]) path = parentId[0].id
                }
      
                if (path === this.props.folderId) {
                  let pushFolder = {
                    name: folder.name,
                    fullPath: folder.fullPath,
                    subFolders: []
                  }
            
                  let repeatedItem = currentItems.filter(data => {
                    return data.fileName === folder.name;
                  })
                  
                  if (repeatedItem.length > 0) {
                    const repeatedLength = repeatedItem.length >= 2;
                    repeatedItem = repeatedItem[0]
                    await new Promise((resolve, reject) => {
                      const index = i+1;
                      this._askUserForRepeatedItem(repeatedItem, repeatedLength, repeatedItems, index).then((data) => {                        newFolders.push(pushFolder)
                        resolve(true);
                      }).catch(error => {
                        this.acceptedFiles = this.acceptedFiles.filter(file => {
                          return !file.webkitRelativePath.startsWith(pushFolder.fullPath.substr(1))
                        });
                        resolve(true);
                      });
                    })
      
                  } else {
                    newFolders.push(pushFolder)
                  }
                }
      
                if (parents.length > 2) {
                  let parent = parents.slice(0, parents.length - 1).join("/");
                  const findItemNested = (arr, itemId, nestingKey) => (
                    arr.reduce((a, item) => {
                      if (a) return a;
                      if (item.fullPath === itemId) return item;
                      if (item[nestingKey]) return findItemNested(item[nestingKey], itemId, nestingKey)
                      return null;
                    }, null)
                  );
                  const res = findItemNested(newFolders, parent, "subFolders");
      
                  if(res) {
                    res.subFolders.push({
                      name: folder.name,
                      fullPath: folder.fullPath,
                      subFolders: []
                    })
                  }
                }
            };
      
            var selFolderId = this.props.folderId;
            var migrateUsers = true;
            
            await new Promise((resolve, reject) => {
              userService.createFolders(selFolderId, migrateUsers, newFolders, activityFeedId, this.state.inheritTags).then((response: any) => {
                this._isMounted && this.props.callbackFunction({ creatingFoldersStructure: false })
                this.acceptedFolders = response.data
                resolve(response);
                return response
              }).catch(error => {
                console.log(error)
                this._isMounted && this.props.callbackFunction({ creatingFoldersStructure: false })
                reject(error);
                return [];
              });
            });
          }
          
          createFolders(this.acceptedFolders).then( (response: any) => {
            var getFileDestination = (filePath) => {
              var parents = filePath.split("/");
            
              if (parents.length > 2) {
                let parent = parents.slice(0, parents.length - 1).join("/");
                var parentId = this.acceptedFolders.filter(item => {
                  return item.fullPath === parent
                })
                if(parentId[0]) return parentId[0].folder.id
                return this.props.folderId;
              } else {
                return this.props.folderId;
              }
            }

            var files: any = [];
            var filesData: any = [];

            var userData = this.props.userData
            var repos = userData.repositoryDetailsList;
            var repo = repos[0].repository.id

            
            for (let i = 0; i < this.acceptedFiles.length; i++) {
              var file: any = this.acceptedFiles[i]
              var destination = getFileDestination("/" + file.webkitRelativePath);
              var fileData: any = {};
              var src = window.URL.createObjectURL(file)

              fileData.fileName = file.name
              fileData.absolutePath = src
              fileData.fileId = "File-" + uuid()
              fileData.destination = destination
              fileData.activityFeedId = activityFeedId
              fileData.origin = userService.getInstanceId()
              fileData.repo = repo

              var fileContent = fileData
              fileContent.file = file
              fileContent.file._id = fileData._id

              if(!file.name.startsWith(".")) {
                files.push(fileContent)
                filesData.push(fileData)
              }
            }

            userService.addFilesToUploadsDb(filesData).then(() => {
              console.log("Imported files success, start uploading")
              this._startUploadsWebapp()
            }).catch(error => {
              console.log(error)
            })
          }).catch(error => {
            console.log(error)
            this._isMounted && this.props.callbackFunction({ creatingFoldersStructure: false })
            if(error.status !== 405) alert(i18n.t('app:couldNotCreateFolders'))
          })
          }).catch(reject => {console.log(reject)})

        }
      }
    }).catch(error => {
      console.log(error)
    })

    input.click();
  }

  private _uploadItems = (items) => {
    var el: any = document.getElementsByClassName("ms-ScrollablePane")
    for(var i = 0; i < el.length; i++) {
      el[i].classList.remove('dragOverList');
    }

    let acceptedFiles: any = [];
    let folders:any = [];

    items = items.map((item:any) => {
      if(item.path.startsWith("/")) item.relativePath = item.path.replace("/", "")
      else item.relativePath = item.path
      return item
    })

    this._isMounted && this.setState({inheritTags: (this.props.folderId !== this.props.repoData[0].id && this.props.folderId !== this.props.repoData.id+"-templates") ? "KEEP_NEW" : "NONE"})
    var canUpload = true
    // for (let i=0; i < items.length; i++) {
    //   console.log(items[i])
    //   if ((items[i].fullPath && items[i].fullPath.includes('Local/Temp/')) || (items[i].relativePath && items[i].relativePath.includes('Local/Temp/'))) canUpload = false;
    // }
    !canUpload && this._messageUploadAlert()
    canUpload && this._askUserToInheritTags(true).then(async () => {
    this._sameChoiceForAll = false;
    userService.createActivityFeedId().then(response => {
      var activityFeedId = response.data

      this.showUploadsCallout();

      this.acceptedFiles = [];
      this.acceptedFolders = [];

      let newFolders: any = [];

      for (let i = 0; i < items.length; i++) {
        const file = items[i]
        this.acceptedFiles.push(file)
        acceptedFiles.push(file)

        var filePath = file.relativePath.split("/")
        if (filePath.length > 1) {
          filePath.pop();
          
          for (let i = 0; i < filePath.length; i++) {
            let dir  = filePath[i]
            
            var folderRepeated = folders.filter(folder => {
              return  folder.name === dir;
            })
            
            if(folderRepeated.length === 0) {
              let currentIndex = filePath.indexOf(dir)
              var currentFilePath = filePath.slice(0, currentIndex + 1)
              folders.push({
                name: dir,
                fullPath: "/" + currentFilePath.join("/"),
                subFolders: []
              })
            }
          }
        }
      }

      this.acceptedFolders = folders
        
      var createFolders = async (folders) => {
        this._isMounted && this.props.callbackFunction({ creatingFoldersStructure: true })
        
        for (let i=0; i<folders.length; i++) {
          let folder = folders[i];
          var parents = folder.fullPath.split("/");
          let path = this.props.folderId;
            if (parents.length > 2) {
              let parent = parents.slice(0, parents.length - 1).join("/");
              var parentId = folders.filter(item => {
                return item.fullPath === parent
              })
              if(parentId[0]) path = parentId[0].id
            }
  
            if (path === this.props.folderId) {
              let pushFolder = {
                name: folder.name,
                fullPath: folder.fullPath,
                subFolders: []
              }
  
              var currentItems:any = this.props.items;
  
              let repeatedItem = currentItems.filter(data => {
                return data.fileName === folder.name;
              })
              
              if (repeatedItem.length > 0) {
                const repeatedLength = repeatedItem.length >= 2;
                repeatedItem = repeatedItem[0]
                await new Promise((resolve, reject) => {
                  this._askUserForRepeatedItem(repeatedItem, repeatedLength).then((data) => {
                    newFolders.push(pushFolder)
                    resolve(true);
                  }).catch(error => {
                    this.acceptedFiles = this.acceptedFiles.filter(file => {
                      return !file.relativePath.startsWith(pushFolder.fullPath.substr(1))
                    });
                    resolve(true);
                  });
                })
  
              } else {
                newFolders.push(pushFolder)
              }
            }
  
            if (parents.length > 2) {
              let parent = parents.slice(0, parents.length - 1).join("/");
              const findItemNested = (arr, itemId, nestingKey) => (
                arr.reduce((a, item) => {
                  if (a) return a;
                  if (item.fullPath === itemId) return item;
                  if (item[nestingKey]) return findItemNested(item[nestingKey], itemId, nestingKey)
                  return null;
                }, null)
              );
              const res = findItemNested(newFolders, parent, "subFolders");
  
              if(res) {
                res.subFolders.push({
                  name: folder.name,
                  fullPath: folder.fullPath,
                  subFolders: []
                })
              }
            }
        };
  
        var selFolderId = this.props.folderId;
        var migrateUsers = true;
        
        await new Promise((resolve, reject) => {
          userService.createFolders(selFolderId, migrateUsers, newFolders, activityFeedId, this.state.inheritTags).then((response: any) => {
            this._isMounted && this.props.callbackFunction({ creatingFoldersStructure: false })
            this.acceptedFolders = response.data
            resolve(response);
            return response
          }).catch(error => {
            console.log(error)
            this._isMounted && this.props.callbackFunction({ creatingFoldersStructure: false })
            reject(error);
            return [];
          });
        });
      }
      
      createFolders(this.acceptedFolders).then( async(response: any) => {
        var getFileDestination = (filePath) => {
          var parents = filePath.split("/");
        
          if (parents.length > 2) {
            let parent = parents.slice(0, parents.length - 1).join("/");
            var parentId = this.acceptedFolders.filter(item => {
              return item.fullPath === parent
            })
            if(parentId[0]) return parentId[0].folder.id
            return this.props.folderId;
          } else {
            return this.props.folderId;
          }
        }

        
        var filesData: any = [];

        var userData = this.props.userData
        var repos = userData.repositoryDetailsList;
        var repo = repos[0].repository.id

        var i,j, temporary, chunk = 10;
        
        for (i = 0, j = this.acceptedFiles.length; i < j; i += chunk) {
          temporary = this.acceptedFiles.slice(i, i + chunk);
          let files: any = [];

          //Drag and drop
          //Get number of repeated items
          let repeatedItems = 0;
          for (let i = 0; i < temporary.length; i++) {
            let item = temporary[i];
            var currentItems = this.props.items;
            let repeated = currentItems.filter(data => {
              return data.fileName === item.name;
            })
            if (repeated.length > 0) repeatedItems++;
          }

          for (let i=0; i< temporary.length; i++) {

            let file: any = temporary[i]
            var destination = getFileDestination("/" + file.relativePath);
            let fileData: any = {};
            
            if(!file.name.startsWith(".")) {
              var src = window.URL.createObjectURL(file)
              console.log("File src", src)

              fileData.fileName = file.name
              fileData.absolutePath = src
              fileData.fileId = "File-" + uuid()
              fileData.destination = destination
              fileData.activityFeedId = activityFeedId
              fileData.origin = userService.getInstanceId()
              fileData.repo = repo

              let fileContent = fileData
              fileContent.file = file
              fileContent.file._id = fileData._id

              let repeatedItem = currentItems.filter(data => {
                return data.fileName === file.name;
              })
              if (repeatedItem.length > 0 && destination === this.props.folderId && repeatedItems === 1) {
                const repeatedLength = repeatedItem.length >= 2;
                repeatedItem = repeatedItem[0]
                await new Promise((resolve, reject) => {
                  const index = i+1;
                  this._askUserForRepeatedItem(repeatedItem, repeatedLength, repeatedItems, index).then((data) => {                    files.push(fileContent)
                    filesData.push(fileData)
                    resolve(true);
                  }).catch(async (reject)=>{
                    if (reject === 'append') {
                      resolve(true);
                      this._appendVersion(repeatedItem, file, repeatedItems > 1)
                    }
                    else if (reject === 'replace') {
                      await userService.deleteDocuments([repeatedItem.id]).then(()=>{
                        files.push(fileContent)
                        filesData.push(fileData)
                      })
                    }
                    resolve(true);
                  })
                })
              }
              else {
                files.push(fileContent)
                filesData.push(fileData)
              }
              
            }
          }

          console.log("FILES", files)

          userService.addFilesToUploadsDb(files).then(() => {
            console.log("Imported files success, start uploading")
            this._startUploadsWebapp()
          }).catch(error => {
            console.log(error)
          })
        }

      }).catch(error => {
        console.log(error)
        this._isMounted && this.props.callbackFunction({ creatingFoldersStructure: false })
        if(error.status !== 405) alert(i18n.t('app:couldNotCreateFolders'))
      })
    }).catch(error => {
      console.log(error)
    })
  }).catch(reject => {console.log(reject)})
  }


  private _addTagBulk = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    this._isMounted && this.setState({ savingTag: true })
    const { newTag, originalTags } = this.state;

    var selItems = this.props.fileExplorerRef._selection.getSelection();
    if (selItems.length === 0) selItems = [this.state.selFile];

    let folders:any = []
    let files:any = []
    let tagsToAdd: any = []
    let tagsToRemove: any = []
    let newTags: any = []

    for (let i=0; i< selItems.length; i++) {
      if (selItems[i].kind === "dir") folders.push(selItems[i].id)
      else if (selItems[i].kind === "file") files.push(selItems[i].id)
    }

    if (folders.length === 0 && files.length === 0) folders = [this.props.folderId]

    for (let i=0; i< newTag.length; i++) {
      if (newTag[i] && newTag[i].length !== 0) {
        if (typeof newTag[i] === 'string') { //SINGLE_SELECTION
          for (let l=0; l<selItems.length; l++ ) {
            newTags.push(newTag[i])
            if (!selItems[l].tags.includes(newTag[i])) tagsToAdd.push(newTag[i])
          }
        }
        else { //MULTIPLE_SELECTION
          for (let j=0; j < newTag[i].length; j++) {
            for (let l=0; l<selItems.length; l++ ) {
              newTags.push(newTag[i][j])
              if (!selItems[l].tags.includes(newTag[i][j])) tagsToAdd.push(newTag[i][j])
            }
          }
        }
      }
    }

    for (let i=0; i< originalTags.length; i++) {
      if (originalTags[i] && originalTags[i].length !== 0) {
        if (!newTags.includes(originalTags[i])) tagsToRemove.push(originalTags[i])
      }
    }

    if (tagsToRemove.length > 0) {
      await userService.removeTagBulk(folders, files, tagsToRemove, this.state.isCascade)
      .then(() => {
      }).catch(error => {
        this._isMounted && this.setState({ savingTag: false })
        console.log(error)
      });
    }
    if (tagsToAdd.length > 0) {
      await userService.addTagBulk(folders, files, tagsToAdd, this.state.isCascade)
        .then(() => {
        }).catch(error => {
          this._isMounted && this.setState({ savingTag: false })
          console.log(error)
        });
    }
    if (this.state.selFile && this.props.detailsPanelRef) this.props.detailsPanelRef._getTags(this.state.selFile)
    this._isMounted && this.setState({
      showTagBulkDialog: false,
      savingTag: false,
      newTag: []
    });

  }

  private handleTagChange = (ev: any, index: number, option?: any, i?: number, value?: string) => {
    let key:string = '';
    if (option) key = option.key;
    if (!option && value) {
      key = value;
    }
    var tags = this.state.newTag
    tags[index] = key
    this._isMounted && this.setState({newTag: tags})
  }

  private handleTagChangeMultiple = (ev: any, index: number, option?: any, i?: number, value?: string) => {
    let selected = option.selected || false;
    if (!option && value) {
      selected = true;
    }

    var tags = this.state.newTag
    if (!tags[index]) tags[index] = []
    if (option) {
      selected ? tags[index] = [...tags[index], option!.key as string] : tags[index] = tags[index].filter(k => k !== option!.key)
    }
    
    this._isMounted && this.setState({newTag: tags})
  }

  private _deletePublicLinks(links) {
    var showDialog = true;
    var div = document.createElement('div');
    var renderDialog = () => {
      ReactDOM.render(
        <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "public-links-dialog"
            }}
            onDismiss={() => {showDialog = false; renderDialog()}}
          >
            <div className="dialog-content-wrap">
              { links.length === 1 ?
                <h1 className="mt-3">{i18n.t('app:deletePublicLink')}</h1>
              : 
                <h1 className="mt-3">{"Deleting " + links.length + " public links"}</h1>
              }

              <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
              <aside className="d-inline-block mt-3">
                {i18n.t('app:pleaseWait')}...
              </aside>
            </div>
          </Dialog>
        , div
      )
    }

    renderDialog();

    var ids = links.map(link => {
      return link.id
    })
    userService.deletePublicLinks(ids).then(response => {
      showDialog = false;
      renderDialog();
    }).catch(error => console.log(error))
  }

  private _getBreadcrumbItems() {
    var folders = (this.props.foldersList && this.props.foldersList.filter((folder)=>{
      return folder.id !== this.props.folderId
    })) || null
    let repos = this.props.repoData

    if(folders && repos) {

      var folderId = this.props.folderId;
      let foldersData = folders

      var currentFolder = foldersData.filter(folder => {
        return folder && folder.id === folderId
      })[0]

      if(currentFolder && currentFolder.path_id) {
        var breadcrumb: any = [];
        
        breadcrumb.push({
          text: currentFolder.name,
          key: currentFolder.id,
          //onClick: this._onBreadcrumbItemClicked.bind(this),
          onRender: ()=>{return (
            <div id={'crumb-'+currentFolder.id} className='breadcrumb-header' onClick={() => {this.setState({goBack: 0}); history.push('/files/' + currentFolder.id)}} 
              onContextMenu={(event)=>{event.preventDefault(); this.setState({showContextualMenu: true, contextMenuId: currentFolder.id})}}>
              <span className='noselect'>{currentFolder.name}</span>
            </div>
          )},
        })

        this._sortBreadcrumbItems(foldersData, breadcrumb, currentFolder.parent_id, currentFolder.trash)
      } else {
        // Check if root folder
        let repo = repos.filter(data => {
          return data.id === folderId
        })
        repo = repo[0]

        if (repo)
          this.setState({
            breadcrumbPath: [{
              text: repo.name,
              key: repo.id,
              onClick: this._onBreadcrumbItemClicked.bind(this)
            }]
          })

        // Check if trash folder
        let templates = repos.filter(data => {
          return data.id + "-templates" === folderId
        })
        templates = templates[0]

        if (templates) {

          let repoId = folderId.replace("-templates", "");
          let repo = repos.filter(data => {
            return data.id === repoId
          })
          repo = repo[0]
          
          this.setState({
            breadcrumbPath: [
              {
                text: repo.name,
                key: repo.id,
                onClick: this._onBreadcrumbItemClicked.bind(this)
              },
              {
                text: 'Templates',
                key: folderId,
                onClick: this._onBreadcrumbItemClicked.bind(this)
              }
            ]
          })
        }

        // Check if trash folder
        let trash = repos.filter(data => {
          return data.id + "-trash" === folderId
        })
        trash = trash[0]

        if (trash) {
          this._isMounted && this.setState({isTrash: true})

          let repoId = folderId.replace("-trash", "");
          let repo = repos.filter(data => {
            return data.id === repoId
          })
          repo = repo[0]
          
          this.setState({
            breadcrumbPath: [
              {
                text: repo.name,
                key: repo.id,
                onClick: this._onBreadcrumbItemClicked.bind(this)
              },
              {
                text: i18n.t('app:trash'),
                key: folderId,
                onClick: this._onBreadcrumbItemClicked.bind(this)
              }
            ]
          })
        }

        if(!repo && folderId && !folderId.endsWith("-trash") && !folderId.endsWith("-templates")) {
          // Search path folders  
          userService.getPathFolders(folderId).then(response => {
            var pathFolders = response.data
            var currentFolder = pathFolders.filter(folder => {
              return folder && folder.id === folderId
            })[0]

            if(currentFolder) {
              var breadcrumb: any = [];
        
              breadcrumb.push({
                text: currentFolder.name,
                key: currentFolder.id,
                //onClick: this._onBreadcrumbItemClicked.bind(this),
                onRender: () => { return (
                  <div id={'crumb-'+currentFolder.id} className='breadcrumb-header' onClick={() => {this.setState({goBack: 0}); history.push('/files/' + currentFolder.id)}} 
                    onContextMenu={(event)=>{event.preventDefault(); this.setState({showContextualMenu: true, contextMenuId: currentFolder.id})}}>
                    <span className='noselect'>{currentFolder.name}</span>
                  </div>
                )},
              })

              this._sortBreadcrumbItems(pathFolders, breadcrumb, currentFolder.parentId, currentFolder.trash)
            }
          }).catch(error => console.log(error))
        }
      }
    }
  }

  private _sortBreadcrumbItems(foldersData, breadcrumb, id, trash) {
    let repos = this.props.repoData

    var getParentData = (parentId, trash) => {
      var parentData;

      parentData = foldersData.filter(data => {
        return parentId === data.id
      })

      if(!parentData.length) {
        parentData = repos.filter(data => {
          return parentId === data.id
        })
      }

      parentData = parentData[0]

      if(parentData) {
        if (trash) {
          this._isMounted && this.setState({isTrash: true})
          var repoId = trash.replace("-trash", "");
          let repo = repos.filter(data => {
            return data.id === repoId
          })
          repo = repo[0]

          breadcrumb = breadcrumb.filter(item => {
            return (item.key !== trash && item.key !== repoId)
          })

          breadcrumb.unshift(
            {
              text: repo.name,
              key: repo.id,
              onClick: this._onBreadcrumbItemClicked.bind(this)
            },
            {
              text: i18n.t('app:trash'),
              key: trash,
              onClick: this._onBreadcrumbItemClicked.bind(this)
            }
          )
        }

        if ((trash && parentData.trash)
          || !trash) {
          breadcrumb = breadcrumb.filter(item => {
            return item.key !== parentData.id
          })

          breadcrumb.unshift({
            text: parentData.name,
            key: parentData.id,
            //onClick: this._onBreadcrumbItemClicked.bind(this),
            onRender: () => { return (
              <div id={'crumb-'+parentData.id} className='breadcrumb-header' onClick={() => {this.setState({goBack: 0}); history.push('/files/' + parentData.id)}} 
                onContextMenu={(event)=>{event.preventDefault(); this.setState({showContextualMenu: true, contextMenuId: parentData.id})}}>
                <span className='noselect'>{parentData.name}</span>
              </div>
            )},
          })
        }
      }
      
      this.setState({
        breadcrumbPath: breadcrumb
      })
      
      let parentDataId = parentData && (parentData.parent_id || parentData.parentId)
      if(parentDataId) getParentData(parentDataId, parentData.trash)
    }

    getParentData(id, trash)
  }

  private _getSearchBreadcrumb() {
    const searchQuery = this.props.searchQuery;
    const params = new URLSearchParams(searchQuery);
    var parentQuery = params.get('parent');

    if(searchQuery && parentQuery) {
      var folders = this.props.foldersList;
      let repos = this.props.repoData
      
      if(folders && repos) {
        var folderId = parentQuery;
        let foldersData = folders

        var currentFolder = foldersData.filter(folder => {
          return folder && folder.id === folderId
        })[0]

        if(currentFolder) {
          this._isMounted && this.setState({ breadcrumbSearch: i18n.t('app:search') + ' ' + i18n.t('app:inFolder') + ' "' + currentFolder.name + '"'})
        } else {
          // Check if root folder
          let repo = repos.filter(data => {
            return data.id === folderId
          })[0]
  
          if(repo) this._isMounted && this.setState({ breadcrumbSearch: i18n.t('app:search') + ' ' + i18n.t('app:inRepository') + ' "' + repo.name  + '"'})
        }
      } else {
        if(this.state.breadcrumbSearch === i18n.t('app:search')) {
          userService.getFolderDetails(parentQuery).then(response => {
            this._isMounted && this.setState({ breadcrumbSearch: i18n.t('app:search') + ' ' + i18n.t('app:inFolder') + ' "' + response.data.name + '"'})
          }).catch(error => {
            console.log(error)
          })
        }
      }
    }
  }

  private _onChangeSearchOptions = (ev: any, newValue?: string) => {
    if(newValue !== undefined) this.setState({searchOptions: newValue})
  }

  private handleSearchTypeChange = (ev: any, newValue?: IDropdownOption) => {
    console.log("newValue", newValue)
    if(newValue && newValue.key && typeof newValue.key === "string") {
      this.setState({
        searchOptions: newValue.key
      });
    }
  }

  private handleSearchTermsChange = (ev: any, newValue?: string) => {
    this.setState({
      newSearchTerms: newValue || ''
    });
  }

  private _goSearch(terms) {
    const { searchOptions } = this.state;
    var folderId = this.props.folderId || this.props.repoData[0].id

    var searchQuery: string = "?text=&name=" + terms + "&tags=&parent=" + folderId + "&searchType=ALL"

    if (searchOptions === "filesByTextInThisFolder") searchQuery = "?text=" + terms + "&name=&tags=&parent=" + folderId + "&searchInside=false"
    else if (searchOptions === "filesByTextInclSubfolders") searchQuery = "?text=" + terms + "&name=&tags=&parent=" + folderId + "&searchInside=true"

    history.push("/search/" + searchQuery)
  }

  private _showVersionHistory() {
    this.props.callbackFunction({ showRightPanel: true });
    
    this.props.detailsPanelRef.setState({
      activeTab: "versions"
    })  
  }

  private _appendVersion(repeatedItem = null, item:any = null, repeatedItems:boolean = false) {
    var selItem: any = this.props.fileExplorerRef._selection.getSelection()[0];
    if (repeatedItem) selItem = repeatedItem;
    var id = selItem.id;
    var fileName = selItem.fileName

    var appendingVersion: boolean = false
    var message: string = i18n.t('app:appendedVersion')
    var messageDraft: string = i18n.t('app:appendedDraft')
    var isExternal = false
    var majorVersion = false
    var majorVersionDraft = false
    var showDraft = false

    var appendedFile: any = null;
    var appendedFileExtension: string = ""

    if (item) {
      appendedFile = item;
      appendedFileExtension = appendedFile.name.split('.').pop()
    }
    
    this._showVersionHistory()

    function onChangeExternal(e, newValue) {
      isExternal = newValue
      renderDialog();
    }

    function handlVersionMessageChange(e, newValue) {
      message = newValue
      renderDialog();
    }

    function handlVersionMessageDraftChange(e, newValue) {
      messageDraft = newValue
      renderDialog();
    }

    const incrementMinorVersion = minor => {
      var [left, right = '0'] = minor.split(/(\d*$)/),
        length = right.length;

      return left + (+right + 1).toString().padStart(length, '0');
    }

    const handleMajorMinorChange = (ev: any, newValue?: any) => {
      majorVersion = newValue.key === "major"
    }

    const handleMajorMinorDraftChange = (ev: any, newValue?: any) => {
      majorVersionDraft = newValue.key === "major"
      renderDialog();
    }

    var _appendFile = () => {
      if(appendedFile) {
        appendingVersion = true
        renderDialog()

        let dropFormData = new FormData();
        dropFormData.append('file', appendedFile, fileName);
        const modalBackgrounds:any = document.querySelectorAll(".ms-Overlay--dark");
        if (modalBackgrounds && modalBackgrounds.length > 1) {
          for (let i=0; i<modalBackgrounds.length; i++) {
            if (i !== modalBackgrounds.length-1) {
              modalBackgrounds[i].style.backgroundColor = 'rgba(0,0,0,0)';
            }
          }
        }

        userService.appendVersion(dropFormData, id, message, majorVersion, isExternal, selItem.draft, messageDraft, majorVersionDraft).then((response: any) => {
          showDialog = false
          renderDialog()
        }).catch(error =>  {
          console.log(error)
          showDialog = false
          renderDialog()
        });
      }
    }

    var _showFilePicker = () => {
      var input = document.createElement('input');
      input.type = 'file';

      input.onchange = e => {
        let event:any = e as Event;
        event.stopPropagation();
        event.preventDefault();

        appendedFile = event.target.files[0]
        appendedFileExtension = appendedFile.name.split('.').pop()
        renderDialog()
      }

      input.click();
    }

    var onDragEnter = (event) => {
      if (event.target.className.indexOf("droptarget")!== -1) {
        event.target.style.background = "#C7E0F4";
      }
    }

    var onDragLeave = (event) => {
      if (event.target.className.indexOf("droptarget")!== -1) {
        event.target.style.background = "transparent";
      }
    }

    var onDragOver = (event) => {
      event.stopPropagation();
      event.preventDefault();
    }

    var onFileDrop = (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (event.target.className.indexOf("droptarget")!== -1) {
        event.target.style.background = "transparent";
      }

      if(event.dataTransfer.files.length === 1) {
        appendedFile= event.dataTransfer.files[0]
        appendedFileExtension = appendedFile.name.split('.').pop()

        renderDialog()
      }
    }

    var showDialog = true;
    var div = document.createElement('div');
    var renderDialog = () => {
      ReactDOM.render(
        <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: i18n.t('app:appendVersion')
            }}
            modalProps={{
              isBlocking: false,
              styles: repeatedItems ? { main: { maxWidth: 640, boxShadow: 'none' } } : { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "append-dialog"
            }}
            onDismiss={() => {showDialog = false; renderDialog()}}
          >
            { !appendingVersion ?
              <div className="dialog-content-wrap">
                <p className='pb-2 m-0' style={{fontWeight:600}}>{i18n.t('app:filename')}</p>
                <span><Icon className='mr-1 mb-3' style={{writingMode:'vertical-rl', width: '24px', height: '20px', fontSize: '20px'}} {...getFileTypeIconProps({ extension: selItem.fileExtension, size: 20, imageFileType: 'svg' }) } /><span style={{verticalAlign:'top'}}>{selItem.fileName}</span></span>

                { selItem.draft &&<>
                  <div className='d-flex mb-3'>
                    <IconButton className='my-auto draft-chevron pl-0 w-100 h-auto text-left' style={{verticalAlign:'middle'}} iconProps={{iconName: showDraft ? 'ChevronDown' : 'ChevronRight', color: 'black'}} onClick={()=>{showDraft=!showDraft;renderDialog()}}>
                      <span className='m-auto'>{i18n.t('app:draftWillBeSaved')}</span>
                    </IconButton>
                  </div>
                  <div style={{transitionDuration: '250ms', maxHeight: showDraft ? '1000px' : 0, overflow: 'hidden'}}>
                      <TextField autoFocus label={i18n.t('app:appendDraftMessage')} placeholder="Version's message" name="newMessage" value={ messageDraft } onChange={ handlVersionMessageDraftChange } disabled={ appendingVersion } multiline rows={3} required />
                      <ChoiceGroup
                        defaultSelectedKey="minor"
                        options={[
                        { key: "minor", 
                          text: "",
                          onRenderField: (props: any, defaultRender: any) => {
                            return <>
                              {defaultRender!({
                                ...props
                              })}{i18n.t('app:minor')}
                              <span className={"badge version p-1 ml-2 badge-primary-light"}>
                                {selItem.activeMajor + "." + incrementMinorVersion(selItem.activeMinor)}
                              </span>
                            </>
                          }
                        },
                        { key: "major", 
                          text: "",
                          onRenderField: (props: any, defaultRender: any) => {
                            return <>
                              {defaultRender!({
                                ...props
                              })}{i18n.t('app:major')}
                              <span className={"badge version p-1 ml-2 badge-primary"}>
                                {(selItem.activeMajor + 1) + ".00"}
                              </span>
                            </>
                          }
                        }
                        ]}
                        label={i18n.t('app:saveAs')}
                        style={{textAlign:'left'}}
                        required={true}
                        className="saveVersionAs my-3"
                        disabled={ appendingVersion }
                        onChange={ handleMajorMinorDraftChange } 
                      />
                  </div>
                </> }

                { !appendedFile ?
                  <div className={"append-droptarget droptarget"} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDragOver={onDragOver} onDrop={(e) => onFileDrop(e)}>
                    <p>{i18n.t('app:appendTextareaLine1')}<br />
                    <span className="text-uppercase d-inline-block my-2">- {i18n.t('app:appendTextareaLine2')} -</span><br />
                    <PrimaryButton onClick={ _showFilePicker } disabled ={ !message.length }>
                      {i18n.t('app:appendSelectFile')}
                    </PrimaryButton>
                    </p>
                  </div>
                :
                  <div className={"append-droptarget pointer-events-enabled appendedFile"}>
                    <p><Icon {...getFileTypeIconProps({ extension: appendedFileExtension, size: 24, imageFileType: 'svg' }) } /> { appendedFile.name } <br />
                    <span onClick={ () => {appendedFile = null; renderDialog()}} className="remove">Remove</span>
                    </p>
                  </div>
                }

                <TextField autoFocus label={i18n.t('app:appendVersionMessage')} placeholder="Version's message" name="newMessage" value={ message } onChange={ handlVersionMessageChange } disabled={ appendingVersion } multiline rows={3} required className="mt-3" />

                <Checkbox label="Mark as external version" checked={isExternal} onChange={onChangeExternal} className='checkbox my-3'/>

                <ChoiceGroup defaultSelectedKey="minor"
                  options={[
                    {
                    key: "minor", 
                      text: "", 
                      onRenderField: (props: any, defaultRender: any) => {
                        return <>
                          {defaultRender!({
                            ...props
                          })}{i18n.t('app:minor')}
                          <span className={"badge version p-1 ml-2" + (isExternal ? " badge-success-light" : " badge-primary-light")}>
                            {(selItem.activeMajor + (majorVersionDraft ? 1 : 0)) + "." + incrementMinorVersion(majorVersionDraft || !selItem.draft ? selItem.activeMinor : incrementMinorVersion(selItem.activeMinor))}
                          </span>
                        </>
                      }
                    },
                    { key: "major", 
                      text: "",
                      onRenderField: (props: any, defaultRender: any) => {
                        return <>
                          {defaultRender!({
                            ...props
                          })}{i18n.t('app:major')}
                          <span className={"badge version p-1 ml-2" + (isExternal ? " badge-success" : " badge-primary")}>
                            {(selItem.activeMajor + (majorVersionDraft ? 2 : 1)) + ".00"}
                          </span>
                        </>
                      }
                    }
                    ]}
                  label={i18n.t('app:saveAs')}
                  style={{textAlign:'left'}}
                  required={true}
                  className="saveVersionAs my-3"
                  disabled={ appendingVersion }
                  onChange={ handleMajorMinorChange } />

                <DialogFooter className="mt-3">
                  <DefaultButton onClick={() => { showDialog = false; renderDialog();}} text={i18n.t('app:cancel')} />
                  <PrimaryButton onClick={ _appendFile } disabled ={ !message.length || !appendedFile}>
                    {i18n.t('app:append')}
                  </PrimaryButton>
                </DialogFooter>
              </div>
            :
              <div className="dialog-content-wrap">
                <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
                <aside className="d-inline-block mt-3">
                {i18n.t('app:appendingVersionWait')}
                </aside>
              </div>
            }
          </Dialog>
        , div
      )
    }

    if (repeatedItems) _appendFile();
    else renderDialog();
  }

  private _onBreadcrumbItemClicked(ev: React.MouseEvent<HTMLElement> | undefined, item: IBreadcrumbItem | undefined): void {
    if (item) {
      this.setState({goBack: 0})
      history.push('/files/' + item.key)
    }
  }

  private _getDownloadsCount() {
    if(this.props.fileExplorerRef && this.props.fileExplorerRef.state) {
      const { downloadingFiles } = this.props.fileExplorerRef.state;

      var pendingCount = downloadingFiles.filter(item => {
        if (item["downloadStatus"] === "pending"
          || item["downloadStatus"] === "downloading"
          || item["downloadStatus"] === "processing")
          return true;
        
        return false
      })

      return pendingCount.length
    }

    return 0;
  }

  private _clearDownloadedFiles() {
    const { downloadingFiles } = this.props.fileExplorerRef.state;

    var filteredFiles = downloadingFiles.filter(item => {
      return ["success","error"].indexOf(item.downloadStatus) === -1
    })

    this._isMounted && this.props.callbackFunction({ downloadingFiles: filteredFiles })
  }

  private _removeUpload(items) {
    var ids = items.map(item => {
      return item.fileId
    })
    userService.deleteUploadsFiles(ids).then(response => {
      setTimeout(() => {
        this._updateUploadsInfo()
      }, 300)
    }).catch(error => console.log(error))
  }

  private _customPivotRenderer(props: any, defaultRender?: IRenderFunction<IPivotItemProps>): JSX.Element {
    var key = props.itemKey;
    var iconName = ""
    var className = ""

    if(key === "processing") { iconName = "Upload"; className = "text-primary align-bottom " }
    else if(key === "queued") { iconName = "BuildQueue"; className = "text-secondary align-bottom " }
    else if(key === "success") { iconName = "Checkmark"; className = "text-success align-bottom " }
    else if(key === "error") { iconName = "Cancel"; className = "text-danger align-bottom " }

    return (
      <span>
        <Icon iconName={iconName} className={className + "mr-1"} />
        {defaultRender!(props)}
      </span>
    );
  }

  private _handleLinkClick = (item: any): void => {
    this.setState({
      activeUploadsTab: item.props.itemKey
    });
  };

  private _getTabId = (itemKey: string): string => {
    return `${itemKey}`;
  };

  private _updateUploadsInfo() {
    this._getUploadsCount();
    let tab = this.state.activeUploadsTab
    if(tab === "processing") this._getUploadsQueue()
    else if (tab === "queued") this._getPendingUploads()
    else if (tab === "success") this._getSuccessUploads()
    else if (tab === "error") this._getErrorUploads()
  }

  private _getUploadsCount() {
    if(!this.state.gettingUploadsCount) {
      this._isMounted && this.setState({gettingUploadsCount: true})

      userService.getUploadsCount().then(response => {
        var count: any = response

        console.log("Uploads count", count)

        if(this.props.appPlatform === "webapp") {
          this.uploadingFilesCount = count.processing;
          this._startUploadsWebapp()
        }

        this._isMounted && this.setState({
          uploadsCount: count,
          gettingUploadsCount: false
        }, () => {
          if(count.processing !== 0 || count.queued !== 0) {
            // setTimeout(() => {
            //   this._getUploadsCount()
            // }, 10000)
          }
        })
      }).catch(error => {
        console.log(error)
        this._isMounted && this.setState({gettingUploadsCount: false})
      })
    }
  }

  private _getPendingUploadsCount() {
    const { uploadsQueue } = this.state;

    var pendingCount = uploadsQueue.filter(item => {
      if (item["uploadStatus"] === "pending"
        || item["uploadStatus"] === "uploading"
        || item["uploadStatus"] === "processing")
        return true;
      
      return false
    })

    return pendingCount.length
  }

  private _getCompleteUploadsCount() {
    const { uploadsQueue } = this.state;

    var completeCount = uploadsQueue.filter(item => {
      if (item["uploadStatus"] === "success")
        return true;

      return false
    })

    return completeCount.length
  }

  private _getErrorUploadsCount() {
    const { uploadsQueue } = this.state;

    var errorCount = uploadsQueue.filter(item => {
      if (item["uploadStatus"] === "error")
        return true;

      return false
    })

    return errorCount.length
  }

  private _clearProcessingFiles() {
    userService.deleteUploadsDb("PROCESSING").then(response => {
      this._updateUploadsInfo();
    }).catch((error) => {
      console.log(error)
    });
  }

  private _clearQueuedFiles() {
    userService.deleteUploadsDb("PENDING").then(response => {
      this._updateUploadsInfo();
    }).catch((error) => {
      console.log(error)
    });
  }

  private _clearImportedFiles() {
    userService.deleteUploadsDb("SUCCESS").then(response => {
      this._updateUploadsInfo();
    }).catch((error) => {
      console.log(error)
    });
  }

  private _clearErrorFiles() {
    userService.deleteUploadsDb("ERROR").then(response => {
      this._updateUploadsInfo();
    }).catch((error) => {
      console.log(error)
    });
  }

  private _addTagOption() {
    var option = {name: "", color: "rgb(169, 169, 169,.7)", new: true};
    var options = this.state.tagOptions;
    /*let index = options.length-1;
    if (options[index] && options[index].edit === true) {
      this._updateTagOption(index)
    }*/
    options.push(option);
    this._isMounted && this.setState({tagOptions: options})
    this._editTagOption(options.length-1)
  }

  private _deleteTagOption(index: number) {
    var options:any[] = this.state.tagOptions;
    userService.deleteTag(options[index].id).then(()=>{
      if (index > -1) {
        options.splice(index, 1); // 2nd parameter means remove one item only
      }    
      this._isMounted && this.setState({tagOptions: options});
    }).catch((error)=>{
      console.log(error)
    })
  }

  private _editTagOption(index: number) {
    var options:any[] = this.state.tagOptions;
    var optionsEdit:any[] = this.state.tagOptionsEdit;
    options[index].edit = true;
    optionsEdit[index] = JSON.parse(JSON.stringify(options[index]))
    this._isMounted && this.setState({tagOptionsEdit: optionsEdit});
  }

  private _cancelEditTagOption(index: number, isNew: boolean) {
    var options:any[] = this.state.tagOptions;
    if (isNew) {
      if (index > -1) {
        options.splice(index, 1); // 2nd parameter means remove one item only
      }    
      this._isMounted && this.setState({tagOptions: options});
    }
    else {
      options[index].edit = false;
      this._isMounted && this.setState({tagOptions: options});
    }
  }

  private _moveTagOptionUp(index:number) {
    var options:any[] = this.state.tagOptions;
    if (index !== 0) {
      let optionTemp = options[index-1];
      options[index-1] = options[index];
      options[index] = optionTemp;
    }
    this._isMounted && this.setState({tagOptions: options});
  }

  private _handleColorPickerChange(value: any) {
    var options:any[] = this.state.tagOptionsEdit;
    for (let i=0; i < options.length; i++) {
      if (i === this.state.colorIndex) {
        options[i].color = value;
      }
    }
    this._isMounted && this.setState({tagOptionsEdit: options});
  }

  private _handleTagOptionChange(value: any, index: number) {
    var options:any[] = this.state.tagOptionsEdit;
    for (let i=0; i < options.length; i++) {
      if (i === index) {
        if (!this._containsInvalidChars(value) && value.length < 50) options[i].name = value;
      }
    }
    this._isMounted && this.setState({tagOptionsEdit: options});
  }

  private _updateTagOption(index:number) {
    var options:any[] = this.state.tagOptions;
    var optionsEdit:any[] = this.state.tagOptionsEdit;
    options[index] = JSON.parse(JSON.stringify(optionsEdit[index]))
    options[index].edit = false;
    let tag = options[index];
    document.getElementById('optionInput'+index) && document.getElementById('optionInput'+index)!.blur();
    if (tag.new) {
      userService.createTag(tag.color, tag.name, this.state.tagCategoryId, this.props.repoData[0].id).then((response)=>{
        options[index].new = false;
        options[index].id = response.data.id

        let originalTags = this.state.originalTags
        originalTags.push(options[index].id)
        this._isMounted && this.setState({tagOptions: options, originalTags: originalTags});
      }).catch((error)=>{
        console.log(error)
      })
    }
    else {
      userService.editTag(tag.color, tag.name, tag.id).then((response)=>{
        options[index].id = response.data.id;
        this._isMounted && this.setState({tagOptions: options});
      }).catch((error)=>{
        console.log(error)
      })
    }
  }

  private _createTagCategory() {
    const {tagName, tagDescription, tagType, tagNameWarning, tagDescriptionWarning } = this.state
    if (tagDescriptionWarning === '' && tagNameWarning === '') {
      this._isMounted && this.setState({savingTag: true})
      userService.createTagCategory(tagName, this.props.repoData[0].id, tagDescription, tagType).then((response) => {
        let categoryId = response.data.id;
        this._isMounted && this.setState({showNewTagDialog:false, showEditTagDialog: true, tagCreated: i18n.t('app:categoryCreated'), tagCategoryId: categoryId, savingTag: false})
        setTimeout(() => {
          this._isMounted && this.setState({ tagCreated: '' })
        }, 3000)
      }).catch((error)=>{
        console.log(error)
        this._isMounted && this.setState({savingTag: false})
      })
    }
  }

  private async _editTagCategory() {
    const {tagName, tagDescription, tagType, tagNameWarning, tagDescriptionWarning, tagOptions, tagOptionsEdit, originalTags } = this.state
    if (tagDescriptionWarning === '' && tagNameWarning === '') {
      this._isMounted && this.setState({savingTag: true})

      let tagsToCreate:any = [];
      let tagsToDelete:any = [];
      let newTags:any = [];

      for(let i=0; i<tagOptions.length; i++) {
        newTags.push(tagOptions[i].id)
        if (tagOptions[i].edit && !tagOptions[i].new) {
          userService.editTag(tagOptionsEdit[i].color, tagOptionsEdit[i].name, tagOptions[i].id).then(()=>{
          }).catch((error)=>{
            console.log(error)
          })
        }
      }

      for(let i=0; i<tagOptions.length; i++) {
        if(!originalTags.includes(tagOptions[i].id)) tagsToCreate.push(tagOptionsEdit[i])
      }

      for(let i=0; i<originalTags.length; i++) {
        if(!newTags.includes(originalTags[i])) tagsToDelete.push(originalTags[i])
      }

      console.log("CREATE:", tagsToCreate)
      console.log("DELETE:", tagsToDelete)

      if (tagsToCreate.length > 0) {
        await userService.createTagBulk(tagsToCreate, this.state.tagCategoryId, this.props.repoData[0].id).then(() => {
        }).catch((error)=>{
          console.log(error)
          this._isMounted && this.setState({savingTag: false})
        })
      }

      if (tagsToDelete.length > 0) {
        await userService.deleteTagBulk(tagsToDelete).then(() => {
        }).catch((error)=>{
          console.log(error)
          this._isMounted && this.setState({savingTag: false})
        })
      }

      userService.editTagCategory(tagName, this.state.tagCategoryId, tagDescription, tagType).then((response) => {
        this._isMounted && this.setState({showEditTagDialog: false, savingTag: false})
        setTimeout(() => {
          this._isMounted && this.setState({ tagCreated: '' })
        }, 3000)
      }).catch((error)=>{
        console.log(error)
        this._isMounted && this.setState({savingTag: false})
      })
    }
  }

  private _createTag() {
    const {tagName, tagColor} = this.state
    this._isMounted && this.setState({savingTag: true})
    userService.createTag(tagColor, tagName, this.props.repoData[0].id+"-tagCategory", this.props.repoData[0].id).then(() => {
      this._isMounted && this.setState({showNewSingleTagDialog: false, savingTag: false})
    }).catch((error)=>{
      console.log(error)
      this._isMounted && this.setState({savingTag: false})
    })
  }

  private _renameTag() {
    const {tagName, tagColor} = this.state
    this._isMounted && this.setState({savingTag: true})
    userService.editTag(tagColor, tagName, this.props.fileExplorerRef._selection.getSelection()[0].id).then(() => {
      this._isMounted && this.setState({showSingleTagDialog: false, savingTag: false})
    }).catch((error)=>{
      console.log(error)
      this._isMounted && this.setState({savingTag: false})
    })
  }

  private getAllTags() {
    userService.getAllTags(this.props.repoData[0].id).then((response:any)=>{
      let list:any = []
      let newTags:any = []
      let originalTags: any =[]
      var selectionTags:any = []
      var selection = this.props.fileExplorerRef._selection.getSelection();
      if (selection.length === 0) selection = [this.state.selFile];
      for (let i=0; i < selection.length; i++) {
        if (selectionTags.length === 0) selectionTags = selection[i].tags;
        else selectionTags = selectionTags.concat(selection[i].tags);
      }
      const allTags = response.data.filter((item)=>{
        return item.tag.length !== 0;
      })
      for (let i=0; i<allTags.length; i++) {
        let canAdd: boolean = true;
        let tags: any = [];
        for (let j=0; j<allTags[i].tag.length; j++) {
          let tag:any = allTags[i].tag[j]
          tag.text = tag.name
          tag.key = tag.id
          tags.push(tag)
          if (allTags[i].tagCategory.tagSelection === "SINGLE_SELECT" && selectionTags.includes(tag.id)) {
            originalTags.push(tag.key)
            newTags[i] = tag.key
          }
          if (allTags[i].tagCategory.tagSelection === "MULTIPLE_SELECT" && selectionTags.includes(tag.id)) {
            originalTags.push(tag.key)
            newTags[i] ? newTags[i].push(tag.key) : newTags[i] = [tag.key]
          }
        }
        allTags[i].tag = tags;
        if (canAdd && allTags[i].tag.length !== 0) {
          list.push(allTags[i])
        }
      }
      this._isMounted && this.setState({tagsList: list, newTag: newTags, originalTags: originalTags})
    }).catch((error: any)=>{
      console.log(error)
      this._isMounted && this.setState({tagsList: [], newTag: [], originalTags: []})
    })
  }

  private _deleteTags() {
    const selection = this.props.fileExplorerRef._selection.getSelection()
    this._askUserToDeleteTags(selection).then((response:any) => {
      this._isMounted && this.setState({ deletingItem: true }, async () => {
        this.props.fileExplorerRef._selection.setAllSelected(false)
        let tagIds:any = []
        for (let i=0; i< selection.length; i++) {
          tagIds.push(selection[i].id)
        }
        await userService.deleteTagBulk(tagIds).then(()=>{ 
          this._isMounted && this.setState({
            deletingItem: false
          });
          this.props.fileExplorerRef && this.props.fileExplorerRef._setSelFirstItem()
        }).catch((error)=>{
          console.log(error)
        })
      })
    }).catch(reject => {console.log(reject)})
  }

  private handleViewChange = (ev: any, newValue?: IDropdownOption) => {
    console.log("newView", newValue)
    if(newValue && newValue.key && typeof newValue.key === "string") {
      localStorage.setItem("fileExplorerView", newValue.key)
      this.props.callbackFunction({fileExplorerView: newValue.key})
    }
  }

  private _onChangeNameFilter: any = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
    var fullItemsResponse = this.props.fullItemsResponse
    if((text && text.length) > 0 && this.props.fullItemsResponseByType.length > 0
      && this.props.fullItemsResponse.length !== this.props.fullItemsResponseByType) {
      fullItemsResponse = this.props.fullItemsResponseByType
    }

    this.setState({filterByNameText: text || ""})
    this.props.callbackFunction({
      items: text ? fullItemsResponse.filter(i => i.name.toLowerCase().indexOf(text) > -1) : fullItemsResponse,
    })
    this.props.callbackFunction({
      fullItemsResponseByName: text ? this.props.items : this.props.fullItemsResponse
    })
    if(!text && this.state.filterByTypeText.length > 0) this._onChangeTypeFilter(ev, this.state.filterByTypeText)
      
  };

  private _onChangeTypeFilter: any = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
    var fullItemsResponse = this.props.fullItemsResponse
    if((text && text.length > 0) && this.props.fullItemsResponseByName.length > 0
      && this.props.fullItemsResponse.length !== this.props.fullItemsResponseByName) {
      fullItemsResponse = this.props.fullItemsResponseByName
    }

    this.setState({filterByTypeText: text || ""})
    this.props.callbackFunction({
        items: text ? fullItemsResponse.filter(i => i.fileExtension.indexOf(text) > -1) : fullItemsResponse,
    })
    this.props.callbackFunction({
    })
    if(!text && this.state.filterByNameText.length > 0) this._onChangeNameFilter(ev, this.state.filterByNameText)
      
  };

  private _messageUploadAlert = async () => {
    return await new Promise((resolve, reject) => {
      var showDialog = true;

      var cancelAlert = () => {
        showDialog = false;
        renderDialog()
        resolve(true)
      }

      var div = document.createElement('div');
      var renderDialog = () => {
        ReactDOM.render(
          <Dialog
              hidden={!showDialog}
              onDismiss={cancelAlert}
              dialogContentProps={{
                type: DialogType.normal,
                title: i18n.t('app:uploadfromTemp')
              }}
              modalProps={{
                isBlocking: false,
                styles: { main: { maxWidth: 640 } },
                dragOptions: undefined,
                className: "ask-delete-item-dialog"
              }}
            >
              <div className="w-100">
                <Text block variant={'medium'} className="mt-2">
                  {i18n.t('app:uploadfromTemp2')}
                </Text>
                <Text block variant={'medium'} className="mt-2">
                  {i18n.t('app:uploadfromTemp3')}
                </Text>
                
                <DialogFooter>
                  <PrimaryButton autoFocus onClick={ cancelAlert } text={i18n.t('app:OK')} />
                </DialogFooter>
              </div>
            </Dialog>
          , div
        )
      }

      renderDialog();
    })
  }

  private renderVersionOptions(item:any) {
    return (
      <div className='py-1'>
      { item.version === "draft" ?
        <>
          <span className="badge badge-warning p-1" style={{width: '80px', fontSize: '14px'}}>{item.text}</span> <span className="date ml-2">{ Moment(item.timestamp).format(i18n.t('app:dateFormat')) }</span>
        </>
      : item.deleted ?
        <>
          <span className="badge badge-danger p-1" style={{width: '80px', fontSize: '14px'}}>{item.version}</span> <span className="date ml-2">{ Moment(item.timestamp).format(i18n.t('app:dateFormat')) }</span>
        </>
      : item.minor !== "00" && item.external?
        <>
          <span className="badge badge-success-light p-1" style={{width: '80px', fontSize: '14px'}}>{item.version}</span> <span className="date ml-2">{ Moment(item.timestamp).format(i18n.t('app:dateFormat')) }</span>
        </>
    
      : item.minor === "00" && item.external?
        <>
          <span className="badge badge-success p-1" style={{width: '80px', fontSize: '14px'}}>{item.version}</span> <span className="date ml-2">{ Moment(item.timestamp).format(i18n.t('app:dateFormat')) }</span>
        </>

      : item.minor !== "00" && !item.external?
        <>
          <span className="badge badge-primary-light p-1" style={{width: '80px', fontSize: '14px'}}>{item.version}</span> <span className="date ml-2">{ Moment(item.timestamp).format(i18n.t('app:dateFormat')) }</span>
        </>
      
      :
        <>
          <span className="badge badge-primary p-1" style={{width: '80px', fontSize: '14px'}}>{item.version}</span> <span className="date ml-2">{ Moment(item.timestamp).format(i18n.t('app:dateFormat')) }</span>
        </>
      }
      <p className="m-0 mt-1 p-0">
        <span className="font-weight-bold author">{item.author.name}</span>: <span className="ml-1">{ item.message }</span>
      </p>
    </div>
    )
  }

  private renderVersionOptionsTitle(items:any) {
    const item = items[0];
    return (
      item.version === "draft" ?
        <>
          <span className="badge badge-warning p-1" style={{width: '80px', fontSize: '14px'}}>{item.text}</span> <span className="date ml-2">{ Moment(item.timestamp).format(i18n.t('app:dateFormat')) }</span>
        </>
      : item.deleted ?
        <>
          <span className="badge badge-danger p-1" style={{width: '80px', fontSize: '14px'}}>{item.version}</span> <span className="date ml-2">{ Moment(item.timestamp).format(i18n.t('app:dateFormat')) }</span>
        </>
      : item.minor !== "00" && item.external?
        <>
          <span className="badge badge-success-light p-1" style={{width: '80px', fontSize: '14px'}}>{item.version}</span> <span className="date ml-2">{ Moment(item.timestamp).format(i18n.t('app:dateFormat')) }</span>
        </>
    
      : item.minor === "00" && item.external?
        <>
          <span className="badge badge-success p-1" style={{width: '80px', fontSize: '14px'}}>{item.version}</span> <span className="date ml-2">{ Moment(item.timestamp).format(i18n.t('app:dateFormat')) }</span>
        </>

      : item.minor !== "00" && !item.external?
        <>
          <span className="badge badge-primary-light p-1" style={{width: '80px', fontSize: '14px'}}>{item.version}</span> <span className="date ml-2">{ Moment(item.timestamp).format(i18n.t('app:dateFormat')) }</span>
        </>
      
      :
        <>
          <span className="badge badge-primary p-1" style={{width: '80px', fontSize: '14px'}}>{item.version}</span> <span className="date ml-2">{ Moment(item.timestamp).format(i18n.t('app:dateFormat')) }</span>
        </>
    )
  }

  private _switchCompareDocs() {
    var files: any = [];
    files[0] = this.state.compareDocs[1];
    files[1] = this.state.compareDocs[0];
    this._isMounted && this.setState({compareDocs: files})
  }

  private _onDragStart = (event, extension) => {
    const { mergedDoc } = this.state;

    if (this.props.appPlatform === "electron") {
      const { ipcRenderer } = window.require("electron");
      if(event) {
        event.preventDefault();
        ipcRenderer.send('ondragstart', [mergedDoc.absolutePath +'.'+ extension])
      }
    }
  }

  private _openFilePicker = async (index:number) => {
    return await new Promise((resolve, reject) => {
      var showDialog = true;

      var confirm = (item) => {
        if (!this.state.compareDocs) {
          showDialog = false;
          renderDialog();
          reject("Operation rejected by user");
        }
        else {
          var files = this.state.compareDocs

          var revisionId = item.activeRevisionId;
          item.fileName = item.fileName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          item.name = item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

          if (item.draft) revisionId = item.id + "-draft"
          item.revisions.forEach((revision: any) => {
            revision.key = revision.fileId
            revision.text = revision.version === "draft" ? item.activeMajor+"."+item.activeMinor + " " + i18n.t("app:draft") : revision.version
          })
          item.title = item.name
          item.revisionId = revisionId
          files[index] = item
        
          this._isMounted && this.setState({
            compareDocs: files,
          })

          showDialog = false;
          renderDialog()
          resolve(true)
        }
      }

      var cancel = () => {
        showDialog = false;
        renderDialog()
        reject("Operation rejected by user");
      }

      var div = document.createElement('div');
      var renderDialog = () => {
        ReactDOM.render(
          <Modal
            isOpen={showDialog && this.state.compareDocs}
            onDismiss={()=>{cancel()}}
            isBlocking={false}
            styles={{main: {maxWidth: '1200px', width: '100%', maxHeight: '800px'}}}
            className="file-picker"
          >
            <div className="w-100">
              
              <div className="d-flex flex-row noselect align-items-center" style={{height: '28px', backgroundColor: '#2c2c2c', color: 'white', fontWeight: 400, fontSize: '14px'}}>
              <Icon iconName='FabricFolder' className='pr-2 pl-2' style={{height: '18px'}}/><span>Synergy File Picker</span>
              <IconButton onClick={()=>{showDialog=false;renderDialog()}} iconProps={{iconName: 'Cancel'}} className='mr-0 ml-auto file-picker-close-button' style={{height: '28px', color: 'white'}}/>
              </div>
              <FilePicker
                {...this.props}
                startFolder={this.state.compareDocs && (this.state.compareDocs[index === 0 ? 0 : 1] && this.state.compareDocs[index === 0 ? 0 : 1].parentId) ? this.state.compareDocs[index === 0 ? 0 : 1].parentId 
                          : this.state.compareDocs && (this.state.compareDocs[index === 0 ? 1 : 0] && this.state.compareDocs[index === 0 ? 1 : 0].parentId) ? this.state.compareDocs[index === 0 ? 1 : 0].parentId : null}
                location={this.props.location}
                database={this.props.database}
                isOnline={this.props.isOnline}
                maintenance={this.props.maintenance}
                fileExplorerType='fileExplorer'
                userData={this.props.userData}
                foldersList={this.props.foldersList}
                newFolder={null}
                repoUsers={this.props.repoUsers}
                appPlatform={this.props.appPlatform}
                callback={(item)=> confirm(item)}
              />
            </div>
          </Modal>
          , div
        )
      }

      if (!this.state.compareDocs) {
        showDialog = false;
        renderDialog();
        reject("Operation rejected by user");
      }

      renderDialog();
    })
  }
}