import * as React from 'react';
import { Header } from '../_components/Header';
import { Sidebar } from '../_components/Sidebar';
import { history } from '../_helpers';
import "moment/min/locales";
import { ActionButton, DefaultButton, Dropdown, IconButton, IDocumentCardStyles, mergeStyles, ScrollablePane, ScrollbarVisibility, ShimmeredDetailsList, TextField, Selection, IRenderFunction, IDetailsHeaderProps, Sticky, StickyPositionType, GroupHeader, IGroupDividerProps, SelectionMode, GroupFooter, ConstrainMode, DatePicker, PrimaryButton, Icon, DayOfWeek } from '@fluentui/react';
import i18n from "i18next";
import { DetailsPanel } from '../_components/DetailsPanel';
import { FormAddActivity } from '../_components/FormAddActivity';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import DataGrid, { Row, textEditor } from 'react-data-grid';
import { groupBy as rowGrouper } from 'lodash';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormAddProjectSheet } from '../_components/FormAddProjectSheet';


export interface Props {
  userData: any;
  appPlatform: string,
  isOnline: boolean,
  page: string
}

export interface State { 
  userData: any;
  breadcrumbPath: any[];
  searchQuery: any;
  syncStatus: any;
  selItem: any;
  showRightPanel: boolean;
  activities: any;
  addingActivity: boolean;
  addingActivityTo: any;
  addingProject: boolean;
  weekSelected: string;
  data:any;
  selectedDate: any;
}

const rows = [
  { id: 0, title: 'Example' },
  { id: 1, title: 'Demo' }
];

export class TimeTrackingPage extends React.Component<Props, State> {
  private _isMounted: boolean;
  private sidebarRef:any = React.createRef();
  private detailsPanelRef:any = React.createRef();
  private _selection: Selection;

  private fakeActivities:any = [
    {
      id: 'asd',
      title: 'General',
      project: 'Project 1',
      monday: '4',
      tuesday: '2',
      wednesday: '',
      thurday: '',
      friday: '',
      saturday: '',
      sunday: '',
    },
    {
      id: 'Activity-asd1',
      title: 'Drafting',
      project: 'Project 1',
      monday: '2',
      tuesday: '',
      wednesday: '',
      thurday: '',
      friday: '',
      saturday: '',
      sunday: '',
    },
    {
      id: 'Activity-asd12',
      title: 'Calls',
      project: 'Project 1',
      monday: '2',
      tuesday: '4',
      wednesday: '',
      thurday: '',
      friday: '',
      saturday: '',
      sunday: '',
    },
    {
      id: 'project1-add',
      title: 'Add activity',
      project: 'Project 1',
      type: 'add-button'
    },
    {
      id: 'Activity-asd2',
      title: 'Planning',
      project: 'Project 2',
      monday: '4',
      tuesday: '0',
      wednesday: '',
      thurday: '',
      friday: '',
      saturday: '',
      sunday: '',
    },
    {
      id: 'project2-add',
      title: 'Add activity',
      project: 'Project 2',
      type: 'add-button'
    }
  ]


  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this._selection = new Selection({
      canSelectItem: (item:any) => {
        return !item.type;
      },
      onSelectionChanged: () => {
        var selItem: any = this._selection.getSelection()[this._selection.getSelection().length - 1]

        setTimeout(() => {
          if (selItem && this._selection.getSelectedCount() === 1) {
            this._isMounted && this.setState({selItem: selItem});
          } else if (selItem && this._selection.getSelectedCount() > 1) {
            this._isMounted && this.setState({selItem: null});
          } else if (this._selection.getSelectedCount() === 0) {
            this._isMounted && this.setState({selItem: null});
          }
        }, 50)
      }
    });

    this.state = {
      userData: null,
      breadcrumbPath: [{
        text: 'Time tracking',
        key: 'timeTracking',
        onClick: ()=>{history.push('/time-sheet')}
      }],
      searchQuery: [],
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      selItem: null,
      showRightPanel: localStorage.showRightPanel === 'true',
      activities: _copyAndSort(this.fakeActivities.splice(0), 'key', false, 'project'),
      addingActivity: false,
      addingActivityTo: null,
      addingProject: false,
      weekSelected: 'thisWeek',
      data: [
        { active: true, firstName: 'Elon', lastName: 'Musk' },
      { active: false, firstName: 'Jeff', lastName: 'Bezos' },
      ],
      selectedDate: new Date(),
    }
  }

  public componentDidMount() {
    this._isMounted = true
  }

  public componentWillUnmount() {
    this._isMounted = false
  }

  private callbackFunction = (childData) => {
    if (this.state.addingActivity === true) {
      this._isMounted && this.setState(childData)
      let items = this.state.activities;
      let activity = {
        key: 'Activity-asdd' + Math.random() * 100,
        name: 'Activity',
        project: this.state.addingActivityTo.project,
        monday: '2',
        tuesday: '',
        wednesday: '',
        thurday: '',
        friday: '',
        saturday: '',
        sunday: '',
      }
      items.push(activity)
      this._isMounted && this.setState({activities: _copyAndSort(items, 'key', false, 'project')})
    } else {
      this._isMounted && this.setState(childData)
    }
  }

  private addTimeActivity(item: any) {
    this._isMounted && this.setState({addingActivity: true, addingActivityTo: item})
    /*
    let items = this.state.activities;
    let activity = {
      key: 'Activity-asdd' + Math.random() * 100,
      name: 'Activity',
      project: item.project,
      monday: '',
      tuesday: '',
      wednesday: '',
      thurday: '',
      friday: '',
      saturday: '',
      sunday: '',
    }
    items.push(activity)
    this._isMounted && this.setState({activities: _copyAndSort(items, 'key', false, 'project')})
    */
  }

  private updateHour(item,value,field) {
    let activites = this.state.activities;
    for (let i=0; i<activites.length; i++) {
      if (activites[i] === item) {
        activites[i][field] = value;
        this._isMounted && this.setState({activities: activites});
        break;
      }
    }
  }

  private getHoursSum(id: string, field: string, column?) {
    if (field === 'remove') return '';
    const { activities } = this.state;
    let sum = 0;
    if (field) {
      for (let i=0; i<activities.length; i++) {
        if (activities[i].project === id && activities[i][field]) {
          sum += parseInt(activities[i][field])
        }
      }
      return sum ? sum : sum === 0 ? sum : id;
    }
    
  }

  public render() {

    const { showRightPanel, addingActivity, selectedDate } = this.state;    

    const onFormatDate = (date?: Date): string => {
      let today = new Date;
      let curr = date || today;
      let firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()+ (curr.getDay() == 0 ? -6:1) ));
      let lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() +7));
      let current_lastday = new Date(today.setDate(today.getDate() - today.getDay() +7));
      return current_lastday.toLocaleDateString() === lastday.toLocaleDateString() ? 'This week' : firstday.toDateString() + " - " + lastday.toDateString()
    };

    const nextWeek = () => {
      var curr = selectedDate
      var nextWeek = new Date(curr.getTime() + 7 * 24 * 60 * 60 * 1000);
      this._isMounted && this.setState({selectedDate: nextWeek})
    }

    const previousWeek = () => {
      var curr = selectedDate
      var nextWeek = new Date(curr.getTime() - 7 * 24 * 60 * 60 * 1000);
      this._isMounted && this.setState({selectedDate: nextWeek})
    }

    const getWeekNumber = () => {
      let currentDate:any = selectedDate
      let startDate:any = new Date(currentDate.getFullYear(), 0, 1);
      let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
      let weekNumber = Math.ceil(days / 7);
      return weekNumber
    }
    

    return(
      <div>
        <Header
          userData={ this.props.userData }
          breadcrumbPath={ this.state.breadcrumbPath }
          sidebarRef={ this.sidebarRef }
          page={this.props.page}
          actions="timetracking"
          getActivity={ null }
          syncStatus={this.state.syncStatus}
          showRightPanel={showRightPanel}
          callbackFunction={this.callbackFunction}
        />

        <div className="content-wrap d-flex flex-row" >
          <Sidebar
            ref={(instance:any) => { this.sidebarRef = instance; }}
            userData={ this.props.userData }
            page={this.props.page}
          />

          <div className="list mr-auto flex-grow-1">
            <div className='pl-3 pt-3 timetracker h-100'>
              {this.props.page === "timeSheet" ?
              <>
                <div className='d-flex pb-3' style={{background: 'white', position: 'sticky', top: '16px', zIndex: 10, borderBottom: '1px solid #ddd'}}>
                  <DatePicker 
                    className="week-picker" 
                    style={{width:'300px'}}
                    styles={{textField:{border:'none'}}}
                    showWeekNumbers 
                    placeholder="Select a date..." 
                    value={selectedDate} 
                    onSelectDate={(v:any)=> {this.setState({selectedDate: v})}}
                    formatDate={onFormatDate}
                    firstDayOfWeek={DayOfWeek.Monday}
                    label={"Week " + getWeekNumber()}

                  />
                  <IconButton onClick={()=>{previousWeek()}} iconProps={{iconName:'ChevronLeftMed'}} style={{border:'1px solid grey', borderLeft: 'none', borderRadius: 0, marginTop: '31px'}}/>
                  <IconButton onClick={()=>{nextWeek()}} iconProps={{iconName:'ChevronRightMed'}} style={{border:'1px solid grey', borderLeft: 'none',borderTopLeftRadius: 0, borderBottomLeftRadius: 0, marginTop: '31px'}}/>
                </div>
                <Sheet/> 
              </> 
              : 
              <>
                <Tracker/>
              </>
              }
            </div>
          </div>
          <DetailsPanel
            appPlatform={ this.props.appPlatform }
            userData={ this.props.userData }
            showRightPanel={ showRightPanel }
            callbackFunction={this.callbackFunction}
            page={this.props.page}
            id={this.state.selItem?.key}
            item={this.state.selItem}
            selItem={this.state.selItem}
            listExplorerRef={this}
          />
        </div>
        { addingActivity && <FormAddActivity callback={this.callbackFunction.bind(this)} page={this.props.page} /> }
      </div>
    )
  }
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean, secondColumnKey?: string): T[] {
  const key = columnKey as keyof any;
  const key2 = secondColumnKey as keyof any;
  if (key === 'amount') {
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? parseFloat(a[key].split(' ')[0]) < parseFloat(b[key].split(' ')[0]) : parseFloat(a[key].split(' ')[0]) > parseFloat(b[key].split(' ')[0])) ? 1 : -1));
  } else {
    if (key2 && key2 !== key) return items.slice(0).sort((a: T, b: T) => {
      if (a[key2] < b[key2]) return -1;
      if (a[key2] > b[key2]) return 1;
      else if (a[key] < b[key]) return isSortedDescending ? 1 : -1;
      else if (a[key] > b[key]) return isSortedDescending ? -1 : 1;
      else return 0;
    })
    else return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
  }
}

function rowKeyGetter(row: Row) {
  return row.id;
}

const options = ['project'] as const;

interface Row {
  id: number,
  title: string,
  project: string,
  monday: number,
  tuesday: number,
  wednesday: number,
  thursday: number,
  friday: number,
  saturday: number,
  sunday: number,
  total: number
}

function Sheet() {
  const fakeActivities:any = [
    {
      id: 1231231,
      title: 'General',
      project: 'Project 1',
      monday: 3,
      tuesday: 2,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      mondayM: 0,
      tuesdayM: 0,
      wednesdayM: 0,
      thursdayM: 0,
      fridayM: 0,
      saturdayM: 0,
      sundayM: 0,
      total: 0
    },
    {
      id: 'Activity-asd1',
      title: 'Drafting',
      project: 'Project 1',
      monday: 1,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      mondayM: 0,
      tuesdayM: 0,
      wednesdayM: 0,
      thursdayM: 0,
      fridayM: 0,
      saturdayM: 0,
      sundayM: 0,
      total: 0
    },
    {
      id: 2345,
      title: 'Calls',
      project: 'Project 1',
      monday: 2,
      tuesday: 4,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      mondayM: 0,
      tuesdayM: 0,
      wednesdayM: 0,
      thursdayM: 0,
      fridayM: 0,
      saturdayM: 0,
      sundayM: 0,
      total: 0
    },
    {
      id: 'Project 1-new',
      title: '',
      project: 'Project 1',
    },
    {
      id: 335345,
      title: 'Brainstorm',
      project: 'Project 2',
      monday: 1,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      mondayM: 0,
      tuesdayM: 0,
      wednesdayM: 0,
      thursdayM: 0,
      fridayM: 0,
      saturdayM: 0,
      sundayM: 0,
    },
    {
      id: 345345,
      title: 'Planning',
      project: 'Project 2',
      monday: 1,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      mondayM: 0,
      tuesdayM: 0,
      wednesdayM: 0,
      thursdayM: 0,
      fridayM: 0,
      saturdayM: 0,
      sundayM: 0,
    },
    {
      id: 355345,
      title: 'General',
      project: 'Project 2',
      monday: 2,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      mondayM: 0,
      tuesdayM: 0,
      wednesdayM: 0,
      thursdayM: 0,
      fridayM: 0,
      saturdayM: 0,
      sundayM: 0,
    },
    {
      id: 'Project 2-new',
      title: '',
      project: 'Project 2',
    },
  ]
  const [addingActivity, setAddingActivity] = useState(false);
  const [addingProject, setAddingProject] = useState(false);
  const [rows, setRows] = useState(fakeActivities);
  const [selectedRows, setSelectedRows] = useState<ReadonlySet<number>>(() => new Set());
  const [selectedOptions, setSelectedOptions] = useState<readonly string[]>([
    options[0]
  ]);
  const [expandedGroupIds, setExpandedGroupIds] = useState<ReadonlySet<unknown>>(
    () => new Set<unknown>(['Project 1', 'Project 2'])
  );

  const getHoursFromMinutes = (n:number) => {
    let hours = (n / 60);
    let rhours = Math.floor(hours);
    return rhours;
  }

  const getRemainingMinutesFromMinutes= (n:number) => {
    let hours = (n / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    return rminutes
  }

  const getTotalfromRow = (row) => {
    let hours = (parseInt(row.monday) || 0) + (parseInt(row.tuesday) || 0)  + (parseInt(row.wednesday) || 0) +  (parseInt(row.thursday) || 0) + (parseInt(row.friday) || 0) + (parseInt(row.saturday) || 0) + (parseInt(row.sunday) || 0);
    let minutes = (parseInt(row.mondayM) || 0) + (parseInt(row.tuesdayM) || 0)  + (parseInt(row.wednesdayM) || 0) +  (parseInt(row.thursdayM) || 0) + (parseInt(row.fridayM) || 0) + (parseInt(row.saturdayM) || 0) + (parseInt(row.sundayM) || 0)
    let rhours = hours + getHoursFromMinutes(minutes);
    let rminutes = getRemainingMinutesFromMinutes(minutes);
    return (rhours < 10 ? ("0"+ rhours) : rhours) + ':' + (rminutes < 10 ? ("0"+rminutes) : rminutes);
  }

  const getTotalInMinutes = (row) => {
    let hours = (parseInt(row.monday) || 0) + (parseInt(row.tuesday) || 0)  + (parseInt(row.wednesday) || 0) +  (parseInt(row.thursday) || 0) + (parseInt(row.friday) || 0) + (parseInt(row.saturday) || 0) + (parseInt(row.sunday) || 0);
    let minutes = (parseInt(row.mondayM) || 0) + (parseInt(row.tuesdayM) || 0)  + (parseInt(row.wednesdayM) || 0) +  (parseInt(row.thursdayM) || 0) + (parseInt(row.fridayM) || 0) + (parseInt(row.saturdayM) || 0) + (parseInt(row.sundayM) || 0)
    return hours*60 + minutes;
  }

  const minutesToString = (n:number) => {
    let rhours = getHoursFromMinutes(n);
    let rminutes = getRemainingMinutesFromMinutes(n)
    return (rhours < 10 ? ("0"+ rhours) : rhours) + ':' + (rminutes < 10 ? ("0"+rminutes) : rminutes);
  }

  const toDoubleDigits = (n:number) => {
    if (n < 10) return "0"+n;
    else return n;
  }

  const columns = [
    {
      key: 'project',
      name: <div style={{textAlign: 'left'}}>Project</div>,
      minWidth: 120,
      maxWidth: 200,
      frozen: false,
      groupFormatter({row, isExpanded}) {
        if (isExpanded) {
          return <div style={{textAlign: 'left', alignItems: 'center', display: 'flex'}}><Icon className='mr-2' style={{fontSize:12}} iconName="ChevronDown"></Icon><span>{row.groupKey}</span></div>
        } else {
          return <div style={{textAlign: 'left', alignItems: 'center', display: 'flex'}}><Icon className='mr-2' style={{fontSize:12}} iconName="ChevronRight"></Icon><span>{row.groupKey}</span></div>
        }
      },
      summaryFormatter({ row }) {
        if (row.id === 'add_project') {
          return <div style={{textAlign: 'left'}}>
            <ActionButton onClick={()=>{setAddingProject(true)}} className='' iconProps={{iconName:'CircleAddition'}} text={'Project'} style={{height: '30px'}}/> 
          </div>
        } else if (row.id === 'empty') {
          return <></>
        } else {
          return <div style={{textAlign: 'left'}}>
            Total
          </div>
        }
      },
      colSpan(args) {
        if (args.type === 'SUMMARY') {
          return 2;
        }
      }
    },
    {
      key: 'title',
      name: <div style={{textAlign: 'left'}}>Name</div>,
      minWidth: 120,
      maxWidth: 200,
      formatter(props) {
        if (props && props.row && (props.row.id+"").includes('-new')) {
          return <div style={{textAlign: 'left'}}><ActionButton onClick={()=>{setAddingActivity(true)}} style={{height: '28px', marginTop: '3px', paddingLeft: '8px', paddingRight: '8px', textAlign: 'left'}} iconProps={{iconName:'Add'}} text={""}/></div>
        } else if (props && props.row) {
          return <div style={{textAlign: 'left'}}>{props.row.title}</div>
        }
      },
      groupFormatter({ childRows }) {
        return <div style={{textAlign: 'left'}}>Subtotal</div>
      },
      cellClass(row) {
        if (row.id.toString().includes('-new')) return 'border-right-none';
        else return undefined;
      },
      //colSpan(args) {
        //if (args.type === 'ROW' && (args.row.id+"").includes('-new')) {
          //return 16;
        //}
      //}
    },
    {
      key: 'monday',
      name: <div className='h-100 w-100'>
        <p style={{height: '0px', top: '-16px', textAlign: 'center', position: 'relative', marginBottom: '10px'}}>Monday</p>
        <div style={{borderBottom: '1px solid #ddd', width: '164px', position: 'relative', left: '-10px', top: '22px'}}></div>
        <div className='w-100 d-flex' style={{marginTop: '4px', height: '32px', fontWeight: 400}}>
          <span className='col-6' style={{textAlign: 'right', padding: '0 8px', color: 'grey'}}>hh</span>
          <div style={{borderRight: '1px solid #ddd', position: 'relative', top:'18px'}}></div>
          <span className='col-6' style={{textAlign: 'right', padding: 0, color: 'grey'}}>mm</span>
        </div>
      </div>,
      minWidth: 50,
      width: 50,
      editor: textEditor,
      resizable: false,
      formatter ({row}) {
        return <>{row.monday}</>
      },
      groupFormatter({ childRows }) {
        return <>{childRows.reduce((prev, { monday }) => prev + (parseInt(monday) || 0), 0)}</>;
      },
      summaryFormatter({ row }) {
        return row.id === 'add_project' || row.id === 'empty' ? <></> : <>{row.monday}</>;
      },
      colSpan(args) {
        if (args.type === 'HEADER') {
          return 2;
        }
      },
      cellClass(row) {
        return 'border-right-none blue';
      },
      groupCellClass() {
        return 'border-right-none';
      },
      summaryCellClass() {
        return 'border-right-none';
      }
    },
    {
      key: 'mondayM',
      name: 'MondayM',
      minWidth: 50,
      width: 50,
      editor: textEditor,
      resizable: false,
      formatter({row}) {
        return row.id.toString().includes('-new') ? null : <><span>:</span><span style={{marginLeft: '12px'}}>{row.mondayM ? toDoubleDigits(row.mondayM) : "00"}</span></>
      },
      groupFormatter({ childRows }) {
        return <><span>:</span><span style={{marginLeft: '12px'}}>{toDoubleDigits(childRows.reduce((prev, { mondayM }) => prev + (parseInt(mondayM) || 0), 0)) || "00"}</span></>
      },
      summaryFormatter({ row }) {
        return row.id === 'add_project' || row.id === 'empty' ? null : <><span>:</span><span style={{marginLeft: '12px'}}>{row.mondayM ? toDoubleDigits(row.mondayM) : "00"}</span></>
      },
      cellClass(row) {
        if (row.id.toString().includes('-new')) return 'border-right-none';
        else return "blue";
      }
    },
    {
      key: 'tuesday',
      name: <div className='h-100 w-100'>
        <p style={{height: '0px', top: '-16px', textAlign: 'center', position: 'relative', marginBottom: '10px'}}>Tuesday</p>
        <div style={{borderBottom: '1px solid #ddd', width: '164px', position: 'relative', left: '-10px', top: '22px'}}></div>
        <div className='w-100 d-flex' style={{marginTop: '4px', height: '32px', fontWeight: 400}}>
          <span className='col-6' style={{textAlign: 'right', padding: '0 8px', color: 'grey'}}>hh</span>
          <div style={{borderRight: '1px solid #ddd', position: 'relative', top:'18px'}}></div>
          <span className='col-6' style={{textAlign: 'right', padding: 0, color: 'grey'}}>mm</span>
        </div>
      </div>,
      minWidth: 50,
      width: 50,
      editor: textEditor,
      resizable: false,
      formatter ({row}) {
        return <>{row.tuesday}</>
      },
      groupFormatter({ childRows }) {
        return <>{childRows.reduce((prev, { tuesday }) => prev + (parseInt(tuesday) || 0), 0)}</>;
      },
      summaryFormatter({ row }) {
        return row.id === 'add_project' || row.id === 'empty' ? <></> : <>{row.tuesday || 0}</>;
      },
      colSpan(args) {
        if (args.type === 'HEADER') {
          return 2;
        }
      },
      cellClass(row) {
        return 'border-right-none blue';
      },
      groupCellClass() {
        return 'border-right-none';
      },
      summaryCellClass() {
        return 'border-right-none';
      }
    },
    {
      key: 'tuesdayM',
      name: 'TuesdayM',
      minWidth: 50,
      width: 50,
      editor: textEditor,
      resizable: false,
      formatter({row}) {
        return row.id.toString().includes('-new') ? null : <><span>:</span><span style={{marginLeft: '12px'}}>{row.tuesdayM ? toDoubleDigits(row.tuesdayM) : "00"}</span></>
      },
      groupFormatter({ childRows }) {
        return <><span>:</span><span style={{marginLeft: '12px'}}>{toDoubleDigits(childRows.reduce((prev, { tuesdayM }) => prev + (parseInt(tuesdayM) || 0), 0)) || "00"}</span></>
      },
      summaryFormatter({ row }) {
        return row.id === 'add_project' || row.id === 'empty' ? null : <><span>:</span><span style={{marginLeft: '12px'}}>{row.tuesdayM ? toDoubleDigits(row.tuesdayM) : "00"}</span></>
      },
      cellClass(row) {
        if (row.id.toString().includes('-new')) return 'border-right-none';
        else return 'blue';
      }
    },
    {
      key: 'wednesday',
      name: <div className='h-100 w-100'>
        <p style={{height: '0px', top: '-16px', textAlign: 'center', position: 'relative', marginBottom: '10px'}}>Wednesday</p>
        <div style={{borderBottom: '1px solid #ddd', width: '164px', position: 'relative', left: '-10px', top: '22px'}}></div>
        <div className='w-100 d-flex' style={{marginTop: '4px', height: '32px', fontWeight: 400}}>
          <span className='col-6' style={{textAlign: 'right', padding: '0 8px', color: 'grey'}}>hh</span>
          <div style={{borderRight: '1px solid #ddd', position: 'relative', top:'18px'}}></div>
          <span className='col-6' style={{textAlign: 'right', padding: 0, color: 'grey'}}>mm</span>
        </div>
      </div>,
      minWidth: 50,
      width: 50,
      editor: textEditor,
      resizable: false,
      formatter ({row}) {
        return <>{row.wednesday}</>
      },
      groupFormatter({ childRows }) {
        return <>{childRows.reduce((prev, { wednesday }) => prev + (parseInt(wednesday) || 0), 0)}</>;
      },
      summaryFormatter({ row }) {
        return row.id === 'add_project' || row.id === 'empty' ? <></> : <>{row.wednesday || 0}</>;
      },
      colSpan(args) {
        if (args.type === 'HEADER') {
          return 2;
        }
      },
      cellClass(row) {
        console.log(row)
        return 'border-right-none blue';
      },
      groupCellClass() {
        return 'border-right-none';
      },
      summaryCellClass() {
        return 'border-right-none';
      }
    },
    {
      key: 'wednesdayM',
      name: 'WednesdayM',
      minWidth: 50,
      width: 50,
      editor: textEditor,
      resizable: false,
      formatter({row}) {
        return row.id.toString().includes('-new') ? null : <><span>:</span><span style={{marginLeft: '12px'}}>{row.wednesdayM ? toDoubleDigits(row.wednesdayM) : "00"}</span></>
      },
      groupFormatter({ childRows }) {
        return <><span>:</span><span style={{marginLeft: '12px'}}>{toDoubleDigits(childRows.reduce((prev, { wednesdayM }) => prev + (parseInt(wednesdayM) || 0), 0)) || "00"}</span></>
      },
      summaryFormatter({ row }) {
        return row.id === 'add_project' || row.id === 'empty' ? null : <><span>:</span><span style={{marginLeft: '12px'}}>{row.wednesdayM ? toDoubleDigits(row.wednesdayM) : "00"}</span></>
      },
      cellClass(row) {
        if (row.id.toString().includes('-new')) return 'border-right-none';
        else return 'blue';
      }
    },
    {
      key: 'thursday',
      name: <div className='h-100 w-100'>
        <p style={{height: '0px', top: '-16px', textAlign: 'center', position: 'relative', marginBottom: '10px'}}>Thursday</p>
        <div style={{borderBottom: '1px solid #ddd', width: '164px', position: 'relative', left: '-10px', top: '22px'}}></div>
        <div className='w-100 d-flex' style={{marginTop: '4px', height: '32px', fontWeight: 400}}>
          <span className='col-6' style={{textAlign: 'right', padding: '0 8px', color: 'grey'}}>hh</span>
          <div style={{borderRight: '1px solid #ddd', position: 'relative', top:'18px'}}></div>
          <span className='col-6' style={{textAlign: 'right', padding: 0, color: 'grey'}}>mm</span>
        </div>
      </div>,
      minWidth: 50,
      width: 50,
      editor: textEditor,
      resizable: false,
      formatter ({row}) {
        return <>{row.thursday}</>
      },
      groupFormatter({ childRows }) {
        return <>{childRows.reduce((prev, { thursday }) => prev + (parseInt(thursday) || 0), 0)}</>;
      },
      summaryFormatter({ row }) {
        return row.id === 'add_project' || row.id === 'empty' ? <></> : <>{row.thursday || 0}</>;
      },
      colSpan(args) {
        if (args.type === 'HEADER') {
          return 2;
        }
      },
      cellClass(row) {
        return 'border-right-none blue';
      },
      groupCellClass() {
        return 'border-right-none';
      },
      summaryCellClass() {
        return 'border-right-none';
      }
    },
    {
      key: 'thursdayM',
      name: 'ThursdayM',
      minWidth: 50,
      width: 50,
      editor: textEditor,
      resizable: false,
      formatter({row}) {
        return row.id.toString().includes('-new') ? null : <><span>:</span><span style={{marginLeft: '12px'}}>{row.thursdayM ? toDoubleDigits(row.thursdayM) : "00"}</span></>
      },
      groupFormatter({ childRows }) {
        return <><span>:</span><span style={{marginLeft: '12px'}}>{toDoubleDigits(childRows.reduce((prev, { thursdayM }) => prev + (parseInt(thursdayM) || 0), 0)) || "00"}</span></>
      },
      summaryFormatter({ row }) {
        return row.id === 'add_project' || row.id === 'empty' ? null : <><span>:</span><span style={{marginLeft: '12px'}}>{row.thursdayM ? toDoubleDigits(row.thursdayM) : "00"}</span></>
      },
      cellClass(row) {
        if (row.id.toString().includes('-new')) return 'border-right-none';
        else return 'blue';
      }
    },
    {
      key: 'friday',
      name: <div className='h-100 w-100'>
        <p style={{height: '0px', top: '-16px', textAlign: 'center', position: 'relative', marginBottom: '10px'}}>Friday</p>
        <div style={{borderBottom: '1px solid #ddd', width: '164px', position: 'relative', left: '-10px', top: '22px'}}></div>
        <div className='w-100 d-flex' style={{marginTop: '4px', height: '32px', fontWeight: 400}}>
          <span className='col-6' style={{textAlign: 'right', padding: '0 8px', color: 'grey'}}>hh</span>
          <div style={{borderRight: '1px solid #ddd', position: 'relative', top:'18px'}}></div>
          <span className='col-6' style={{textAlign: 'right', padding: 0, color: 'grey'}}>mm</span>
        </div>
      </div>,
      minWidth: 50,
      width: 50,
      editor: textEditor,
      resizable: false,
      formatter ({row}) {
        return <>{row.friday}</>
      },
      groupFormatter({ childRows }) {
        return <>{childRows.reduce((prev, { friday }) => prev + (parseInt(friday) || 0), 0)}</>;
      },
      summaryFormatter({ row }) {
        return row.id === 'add_project' || row.id === 'empty' ? <></> : <>{row.friday || 0}</>;
      },
      colSpan(args) {
        if (args.type === 'HEADER') {
          return 2;
        }
      },
      cellClass(row) {
        return 'border-right-none blue';
      },
      groupCellClass() {
        return 'border-right-none';
      },
      summaryCellClass() {
        return 'border-right-none';
      }
    },
    {
      key: 'fridayM',
      name: 'FridayM',
      minWidth: 50,
      width: 50,
      editor: textEditor,
      resizable: false,
      formatter({row}) {
        return row.id.toString().includes('-new') ? null : <><span>:</span><span style={{marginLeft: '12px'}}>{row.fridayM ? toDoubleDigits(row.fridayM) : "00"}</span></>
      },
      groupFormatter({ childRows }) {
        return <><span>:</span><span style={{marginLeft: '12px'}}>{toDoubleDigits(childRows.reduce((prev, { fridayM }) => prev + (parseInt(fridayM) || 0), 0)) || "00"}</span></>
      },
      summaryFormatter({ row }) {
        return row.id === 'add_project' || row.id === 'empty' ? null : <><span>:</span><span style={{marginLeft: '12px'}}>{row.fridayM ? toDoubleDigits(row.fridayM) : "00"}</span></>
      },
      cellClass(row) {
        if (row.id.toString().includes('-new')) return 'border-right-none';
        else return 'blue';
      }
    },
    {
      key: 'saturday',
      name: <div className='h-100 w-100'>
        <p style={{height: '0px', top: '-16px', textAlign: 'center', position: 'relative', marginBottom: '10px'}}>Saturday</p>
        <div style={{borderBottom: '1px solid #ddd', width: '164px', position: 'relative', left: '-10px', top: '22px'}}></div>
        <div className='w-100 d-flex' style={{marginTop: '4px', height: '32px', fontWeight: 400}}>
          <span className='col-6' style={{textAlign: 'right', padding: '0 8px', color: 'grey'}}>hh</span>
          <div style={{borderRight: '1px solid #ddd', position: 'relative', top:'18px'}}></div>
          <span className='col-6' style={{textAlign: 'right', padding: 0, color: 'grey'}}>mm</span>
        </div>
      </div>,
      minWidth: 50,
      width: 50,
      editor: textEditor,
      resizable: false,
      formatter ({row}) {
        return <>{row.saturday}</>
      },
      groupFormatter({ childRows }) {
        return <>{childRows.reduce((prev, { saturday }) => prev + (parseInt(saturday) || 0), 0)}</>;
      },
      summaryFormatter({ row }) {
        return row.id === 'add_project' || row.id === 'empty' ? <></> : <>{row.saturday || 0}</>;
      },
      colSpan(args) {
        if (args.type === 'HEADER') {
          return 2;
        }
      },
      cellClass(row) {
        return 'border-right-none blue';
      },
      groupCellClass() {
        return 'border-right-none';
      },
      summaryCellClass() {
        return 'border-right-none';
      }
    },
    {
      key: 'saturdayM',
      name: 'SaturdayM',
      minWidth: 50,
      width: 50,
      editor: textEditor,
      resizable: false,
      formatter({row}) {
        return row.id.toString().includes('-new') ? null : <><span>:</span><span style={{marginLeft: '12px'}}>{row.saturdayM ? toDoubleDigits(row.saturdayM) : "00"}</span></>
      },
      groupFormatter({ childRows }) {
        return <><span>:</span><span style={{marginLeft: '12px'}}>{toDoubleDigits(childRows.reduce((prev, { saturdayM }) => prev + (parseInt(saturdayM) || 0), 0)) || "00"}</span></>
      },
      summaryFormatter({ row }) {
        return row.id === 'add_project' || row.id === 'empty' ? null : <><span>:</span><span style={{marginLeft: '12px'}}>{row.saturdayM ? toDoubleDigits(row.saturdayM) : "00"}</span></>
      },
      cellClass(row) {
        if (row.id.toString().includes('-new')) return 'border-right-none';
        else return 'blue';
      }
    },
    {
      key: 'sunday',
      name: <div className='h-100 w-100'>
        <p style={{height: '0px', top: '-16px', textAlign: 'center', position: 'relative', marginBottom: '10px'}}>Sunday</p>
        <div style={{borderBottom: '1px solid #ddd', width: '164px', position: 'relative', left: '-10px', top: '22px'}}></div>
        <div className='w-100 d-flex' style={{marginTop: '4px', height: '32px', fontWeight: 400}}>
          <span className='col-6' style={{textAlign: 'right', padding: '0 8px', color: 'grey'}}>hh</span>
          <div style={{borderRight: '1px solid #ddd', position: 'relative', top:'18px'}}></div>
          <span className='col-6' style={{textAlign: 'right', padding: 0, color: 'grey'}}>mm</span>
        </div>
      </div>,
      minWidth: 50,
      width: 50,
      editor: textEditor,
      resizable: false,
      formatter ({row}) {
        return <>{row.sunday}</>
      },
      groupFormatter({ childRows }) {
        return <>{childRows.reduce((prev, { sunday }) => prev + (parseInt(sunday) || 0), 0)}</>;
      },
      summaryFormatter({ row }) {
        return row.id === 'add_project' || row.id === 'empty' ? <></> : <>{row.sunday || 0}</>;
      },
      colSpan(args) {
        if (args.type === 'HEADER') {
          return 2;
        }
      },
      cellClass(row) {
        return 'border-right-none blue';
      },
      groupCellClass() {
        return 'border-right-none';
      },
      summaryCellClass() {
        return 'border-right-none';
      }
    },
    {
      key: 'sundayM',
      name: 'SundayM',
      minWidth: 50,
      width: 50,
      editor: textEditor,
      resizable: false,
      formatter({row}) {
        return row.id.toString().includes('-new') ? null : <><span>:</span><span style={{marginLeft: '12px'}}>{row.sundayM ? toDoubleDigits(row.sundayM) : "00"}</span></>
      },
      groupFormatter({ childRows }) {
        return <><span>:</span><span style={{marginLeft: '12px'}}>{toDoubleDigits(childRows.reduce((prev, { sundayM }) => prev + (parseInt(sundayM) || 0), 0)) || "00"}</span></>
      },
      summaryFormatter({ row }) {
        return row.id === 'add_project' || row.id === 'empty' ? null : <><span>:</span><span style={{marginLeft: '12px'}}>{row.sundayM ? toDoubleDigits(row.sundayM) : "00"}</span></>
      },
      cellClass(row) {
        if (row.id.toString().includes('-new')) return 'border-right-none';
        else return 'blue';
      }
    },
    {
      key: 'total',
      name: 'Total',
      minWidth: 100,
      width: 100,
      formatter({ row }) {
        return (row.id).toString().includes('-new') ? <></> : <>{getTotalfromRow(row)}</>;
      },
      groupFormatter({ childRows }) {
        return <>{minutesToString(childRows.reduce((prev, row) => (parseInt(prev) || 0) +  getTotalInMinutes(row), 0))}</>;
      },
      summaryFormatter({ row }) {
        return row.id === 'add_project' || row.id === 'empty' ? <></> : <>{minutesToString(getTotalInMinutes(row))}</>;
      }
    }
  ]

  const summaryRows = useMemo(() => {

    let monday = 0;
    let mondayM = 0;
    let tuesday = 0;
    let tuesdayM = 0;
    let wednesday = 0;
    let wednesdayM = 0;
    let thursday = 0;
    let thursdayM = 0;
    let friday = 0;
    let fridayM = 0;
    let saturday = 0;
    let saturdayM = 0;
    let sunday = 0;
    let sundayM = 0;
    let total = 0;
    rows && rows.map((item) => {
      monday += parseInt(item.monday || 0)
      mondayM += parseInt(item.mondayM || 0)
      tuesday += parseInt(item.tuesday || 0)
      tuesdayM += parseInt(item.tuesdayM || 0)
      wednesday += parseInt(item.wednesday || 0)
      wednesdayM += parseInt(item.wednesdayM || 0)
      thursday += parseInt(item.thursday || 0)
      thursdayM += parseInt(item.thursdayM || 0)
      friday += parseInt(item.friday || 0)
      fridayM += parseInt(item.fridayM || 0)
      saturday += parseInt(item.saturday || 0)
      saturdayM += parseInt(item.saturdayM || 0)
      sunday += parseInt(item.sunday || 0)
      sundayM += parseInt(item.sundayM || 0)
      total += parseInt(item.total || 0)
    })

    const summaryRow: any = [
      {id:'empty'},
      {
      id: 'add_project',
      project: 'Add project',
      title: 'Add project',
      monday: monday,
      mondayM: mondayM,
      tuesday: tuesday,
      tuesdayM: tuesdayM,
      wednesday: wednesday,
      wednesdayM: wednesdayM,
      thursday: thursday,
      thursdayM: thursdayM,
      friday: friday,
      fridayM: fridayM,
      saturday: saturday,
      saturdayM: saturdayM,
      sunday: sunday,
      sundayM: sundayM,
      total: total
    },
    ]
    return summaryRow;
  }, [rows]);

  const summaryRowsTop = useMemo(() => {

    let monday = 0;
    let mondayM = 0;
    let tuesday = 0;
    let tuesdayM = 0;
    let wednesday = 0;
    let wednesdayM = 0;
    let thursday = 0;
    let thursdayM = 0;
    let friday = 0;
    let fridayM = 0;
    let saturday = 0;
    let saturdayM = 0;
    let sunday = 0;
    let sundayM = 0;
    let total = 0;
    rows && rows.map((item) => {
      monday += parseInt(item.monday || 0)
      mondayM += parseInt(item.mondayM || 0)
      tuesday += parseInt(item.tuesday || 0)
      tuesdayM += parseInt(item.tuesdayM || 0)
      wednesday += parseInt(item.wednesday || 0)
      wednesdayM += parseInt(item.wednesdayM || 0)
      thursday += parseInt(item.thursday || 0)
      thursdayM += parseInt(item.thursdayM || 0)
      friday += parseInt(item.friday || 0)
      fridayM += parseInt(item.fridayM || 0)
      saturday += parseInt(item.saturday || 0)
      saturdayM += parseInt(item.saturdayM || 0)
      sunday += parseInt(item.sunday || 0)
      sundayM += parseInt(item.sundayM || 0)
      total += parseInt(item.total || 0)
    })

    const summaryRowTop: any = [
    {id:'empty'},
    {
      id: 'total_0',
      project: 'Total',
      title: 'Total',
      monday: monday,
      mondayM: mondayM,
      tuesday: tuesday,
      tuesdayM: tuesdayM,
      wednesday: wednesday,
      wednesdayM: wednesdayM,
      thursday: thursday,
      thursdayM: thursdayM,
      friday: friday,
      fridayM: fridayM,
      saturday: saturday,
      saturdayM: saturdayM,
      sunday: sunday,
      sundayM: sundayM,
      total: total
    },
    {id:'empty'}
    ]
    
    return summaryRowTop;
  }, [rows]);

  const rowHeight:any = () => {
    return 30
  }

  return (
    <div style={{maxHeight: 'calc(100% - 68px)', maxWidth: '1200px'}}>
      <DataGrid
        style={{blockSize: '100%'}} 
        columns={columns}
        rows={rows}
        rowKeyGetter={rowKeyGetter}
        summaryRowHeight={32}
        selectedRows={selectedRows}
        onSelectedRowsChange={setSelectedRows}
        onRowsChange={setRows}
        groupBy={selectedOptions}
        rowGrouper={rowGrouper}
        topSummaryRows={summaryRowsTop}
        bottomSummaryRows={summaryRows}
        expandedGroupIds={expandedGroupIds}
        onExpandedGroupIdsChange={setExpandedGroupIds}
        defaultColumnOptions={{ resizable: true }}
        headerRowHeight={64}
        //rowClass={(row:any)=>{return row.id === 'empty' ? 'bg-grey' : 'bg-grey'}}
      />

      { addingActivity && <FormAddActivity callback={()=>{setAddingActivity(false)}} /> }
      { addingProject && <FormAddProjectSheet callback={()=>{setAddingProject(false)}} /> }
    </div>
  );
}

function Tracker() {

  const [seconds, setSeconds] = useState(0)
  const [trackerON, setTracker] = useState(false)

  const getMinutesFromSeconds= (n:number) => {
    //Get minutes from seconds
    let minutes = (n / 60);
    let rminutes = Math.floor(minutes);
    let seconds = (minutes - rminutes) * 60;
    let totalSeconds = Math.round(seconds);
    //Get hours from minutes
    let hours = (rminutes / 60);
    let totalHours = Math.floor(hours);
    let minutes2 = (hours - totalHours) * 60;
    let totalMinutes = Math.round(minutes2);
    //Return as string
    return (totalHours < 10 ? ("0"+ totalHours) : totalHours) + ':' + (totalMinutes < 10 ? ("0"+totalMinutes) : totalMinutes) + ':' + (totalSeconds < 10 ? ("0"+totalSeconds) : totalSeconds);
  }

  useEffect(()=> {
    if (trackerON) {
      let n = seconds;
      setTimeout(()=> {
        n++;
        trackerON && setSeconds(n)
      },1000)
    }
  },[seconds,trackerON])

  const toggleTracker = () =>{    
    if (trackerON) {
      toast.error("Timer stopped!")
    } else {
      toast.success("Timer started!")
    }
    setTracker(!trackerON)

  }


  return (
    <div className='d-flex w-100'>
      <TextField className='w-100'/>
      <div style={{width:'130px', fontSize:20, fontWeight: 600, textAlign: 'center'}}>
        {getMinutesFromSeconds(seconds)}
      </div>
      <PrimaryButton text={trackerON ? 'Stop' : 'Start'} onClick={()=>toggleTracker()}/>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}