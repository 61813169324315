import { ComboBox, DefaultButton, Dialog, DialogFooter, DialogType, IconButton, PrimaryButton, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import i18next from 'i18next';
import * as React from 'react';
import i18n from '../i18n';

export interface Props {
  callback:any;
}

export interface States {
  hidden: boolean;
  loading: boolean;
  name: string;
  description: string;
}

const fieldTypes = [{key: "closeDate", text: "Close Date", type: "Date"}, {key: "city", text: "City", type: "String"}, {key: "aboutUs", text: "About us", type: "String"}, {key: "employeeNumbers", text: "Number of employees", type: "Number"}]

export class FormAddActivity extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      hidden: false,
      loading: false,
      name: '',
      description: ''
    };
  }

  public componentDidMount() {
    this._isMounted = true;
  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      setTimeout(()=>this.props.callback(),200)
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private submit() {
    this._isMounted && this.setState({loading:true});
    
    setTimeout(()=>{
      this._isMounted && this.setState({loading:false});
      this.setState({hidden:true})
    },200)
  }

  public render() {

    const { hidden, loading, name, description } = this.state;
    
    return(
      <div>
        <Dialog
          hidden={hidden}
          dialogContentProps={{
            type: DialogType.close,
            title: i18n.t('New activity'),
            onDismiss: ()=> {this._isMounted && this.setState({hidden:true})}
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640 } },
            dragOptions: undefined,
            className: "form-dialog",
            onDismiss: ()=> {this._isMounted && this.setState({hidden:true})}
          }}
        >
          <form onSubmit={()=>this.submit()}>
            <TextField value={name} onChange={(e,v)=> {this._isMounted && this.setState({name: v || ''})}} className='mt-2' label={i18next.t('Name')}/>
            <TextField value={description} onChange={(e,v)=> {this._isMounted && this.setState({description: v || ''})}} multiline className='mt-2' label={i18next.t('Description')}/>
          </form>
          <DialogFooter>
            { loading ?
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
            : null }
            <DefaultButton onClick={()=> {this._isMounted && this.setState({hidden:true})}} text={i18n.t('app:cancel')} disabled={loading} />
            <PrimaryButton onClick={()=>this.submit()} text={i18n.t('app:save')} disabled={loading} />                   
          </DialogFooter>
        </Dialog>
      </div>
    )
  }

}
