import * as React from 'react';
import ReactDOM from 'react-dom';
import {JsonTable} from 'react-json-to-html';
import { history } from '../_helpers';
import UAParser from 'ua-parser-js';
import { Fabric } from '@fluentui/react/lib/Fabric';
import { Resizable } from 're-resizable';
import Tree from 'react-virtualized-tree'
import {createSelector} from 'reselect';
import { Nav, INavLink } from '@fluentui/react/lib/Nav';
import { Text } from '@fluentui/react/lib/Text';
import { ComboBox, IComboBox, IComboBoxOption,} from '@fluentui/react';
import { TextField } from '@fluentui/react/lib/TextField';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { ContextualMenu, ContextualMenuItemType, IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { Icon } from '@fluentui/react/lib/Icon';
import { IconButton, IIconProps, DatePicker, DayOfWeek, IDatePickerStrings } from '@fluentui/react';
import { getFileTypeIconProps, FileIconType } from '@fluentui/react-file-type-icons';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { CommandBar } from '@fluentui/react/lib/CommandBar';

import { userService } from '../_services';
import { FolderRole } from './FolderRole';
import { ActionsPermissions } from './ActionsPermissions';
import actions from '../_data/actions.json'

import i18n from 'i18next';
import isElectron from 'is-electron';
import Cookies from 'js-cookie';

export const getNodeRenderOptions = createSelector(
  (node: any) => (node.state || {}).expanded,
  node => (node.state || {}).favorite,
  node => (node.state || {}).deletable,
  node => node.hasSubFolders,
  node => node.id,
  node => node.type,
  node => node.hidden,
  (expanded, favorite, deletable, hasSubFolders, id, type, hidden = []) => ({
    hasChildren: !!hasSubFolders,
    isExpanded: !!expanded,
    isFavorite: !!favorite,
    isDeletable: !!deletable,
    id: id,
    type: type,
    hidden: !!hidden
  }),
);

export const updateNode = (originalNode, newState: any) =>
  ({
  
  node: {
    ...originalNode,
    state: {
      ...originalNode.state,
      ...newState,
    },
  },
  type: "2" //UPDATE
});

const detectMob = () => {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}

export interface Props {
  match: {
    url: string
  },
  location: any,
  searchType: string
}

const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test( userAgent );
}

const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator["standalone"]);

export interface SidebarState {
  userData?: any;
  repoData?: any;
  folderTree: any[];
  nodesTree: any[];
  selFolder: any;
  folderTreeFocusId: string;
  showContextualMenu: boolean;
  showContextualMenuSearch: boolean;
  showContextualMenuTags: boolean;
  selFolderTaget: any;
  folderRole: number;
  draggedItem: any;
  movingItem: boolean;
  panelHeight: any;
  panelWidth: any;
  recentFilesTree: any;
  offlineFilesTree: any;
  searchFilters: any;
  smartFoldersTree: any;
  tags: any;
  editTagDialog: boolean;
  selTag: any;
  newTag: string;
  selNav: string;
  savingTag: boolean;
  selAction: string;
  minDate: Date;
  maxDate: Date;
  initialDate: Date;
  finalDate: Date;
  goBack: number;
  userName: string;
  settingsMenu: boolean;
  personaMenu: boolean;
  language: string;
  toolbarHeight: number;
  tagsList: any;
  tagFilter: string;
  pendingDrafts: any;
}

export class Sidebar extends React.Component<Props, SidebarState, { t, i18n }> {
  private _isMounted: boolean;
  private userDataSubscription: any = null;
  private _windowWidth: number;
  private filteredNested: any;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this._windowWidth = window.innerWidth;

    var panelWidth = "240px"
    if (typeof(localStorage.getItem("sidebarPrefs")) === "string") {
      var panelPrefs = JSON.parse(localStorage.getItem("sidebarPrefs") || "[]");
      panelWidth = panelPrefs.width
    }

    this.state = {
      userData: null,
      repoData: null,
      goBack: 0,
      folderTree: [],
      nodesTree: [],
      showContextualMenu: false,
      showContextualMenuSearch: false,
      showContextualMenuTags: false,
      selFolderTaget: null,
      folderRole: 10,
      selFolder: null,
      folderTreeFocusId: "",
      draggedItem: null,
      movingItem: false,
      panelHeight: 'auto',
      panelWidth: panelWidth,
      recentFilesTree: [],
      offlineFilesTree: [],
      searchFilters: [],
      smartFoldersTree: null,
      tags: null,
      editTagDialog: false,
      selTag: null,
      newTag: '',
      savingTag: false,
      selAction: "allActions",
      minDate: new Date(2020,0,1),
      maxDate: new Date(Date.now()),
      initialDate: new Date(2020,0,1),
      finalDate: new Date(Date.now()),
      selNav: this._getSelNav(),
      userName: "",
      settingsMenu: false,
      personaMenu: false,
      language: i18n.language,
      toolbarHeight: 89,
      tagsList: null,
      tagFilter: 'allTags',
      pendingDrafts: null
    };
  }

  public componentDidMount() {
    this._isMounted = true;

    window.onblur = () => {
      this.setState({draggedItem: null})
    }

    var repo = this.props.userData && this.props.userData.repository;
    var repoData: any = [];
    if(repo) {
      repoData.push({
        id: repo.id,
        name: repo.name,
        role: this.props.userData.user.role
      })
      
      this._isMounted && this.setState({
        userData: this.props.userData,
        userName: this.props.userData.user.name + " " + this.props.userData.user.surname,
        repoData: repoData
      }, () => {
        if(this.props.fileExplorerType === "fileExplorer"
        || this.props.fileExplorerType === "recentFiles"
        || this.props.fileExplorerType === "openFiles"
        || this.props.fileExplorerType === "bookmarks") {
          this._getFolderTree();
        }
        
        if(this.props.searchType === "fullSearch") {
          this._getSearchFilters()
        } else if (this.props.searchType === "tag") {
          this._getTags();
        } else if (this.props.searchType === "smartFolder") {
          this._getSmartFolders();
        }

        if(this.props.sidebarNav === "activity") {
          this._getActivitySelFilters()
        }
      })
    }
    
    if (this.props.fileExplorerType === "offlineFiles" ) {
      this._getOfflineFilesTree()
    }
    
      const self = this;
      if (window.innerWidth <= 980) {
        self.toggleNav("")
      }
      window.addEventListener('resize', function() {
        var toolbar : HTMLDivElement | null  = document.querySelector('.toolbar');
        self._isMounted && self.setState({toolbarHeight: toolbar!.offsetHeight})
        if (window.innerWidth <= 980 && window.innerWidth < self._windowWidth) {
          self.toggleNav("")
        }
        self._windowWidth = window.innerWidth;
      })

    if(this.props.pendingDrafts) {
      this.setState({pendingDrafts: this.props.pendingDrafts})
    }
    
  }
  
  public componentDidUpdate(prevProps) {
    if(this.props.userData !== prevProps.userData || this.state.language !== i18n.language) {
      this._isMounted && this.setState({language: i18n.language});
      
      if(this.props.appPlatform === ("electron")) {
        const { ipcRenderer } = window.require('electron');
        ipcRenderer.send('switch_language', i18n.language)
      }

      var repo = this.props.userData && this.props.userData.repository;
      var repoData: any = [];
      if(repo) {
        repoData.push({
          id: repo.id,
          name: repo.name,
          role: this.props.userData.user.role
        })

        this._isMounted && this.setState({
          userData: this.props.userData,
          userName: this.props.userData.user.name + " " + this.props.userData.user.surname,
          repoData: repoData
        }, () => {
          if(this.props.fileExplorerType === "fileExplorer"
            || this.props.fileExplorerType === "recentFiles"
            || this.props.fileExplorerType === "openFiles"
            || this.props.fileExplorerType === "bookmarks") {
            this._getFolderTree();
          }

          if(this.props.searchType === "fullSearch") {
            this._getSearchFilters()
          } else if (this.props.searchType === "tag") {
            this._getTags();
          } else if (this.props.searchType === "smartFolder") {
            this._getSmartFolders();
          }

          if(this.props.sidebarNav === "activity") {
            this._getActivitySelFilters()
          }
        })
      }
    }

    // Expand sidebar while navigating in File Explorer
    // if((this.props.folderId !== prevProps.folderId)
    //   && this.props.fileExplorerType === "fileExplorer") {
    //     this._expandFolder(this.props.folderId, 2, null)
    // }

    if(this.props.pendingDrafts !== prevProps.pendingDrafts) {
      if(this.props.pendingDrafts) this.setState({pendingDrafts: this.props.pendingDrafts})
    }

    if(this.props.folders !== prevProps.folders) {
      this._expandFolderWhileBrowsing(this.props.folderId, this.props.folders)
    }

    if(this.props.folderId !== prevProps.folderId
      || this.props.fileExplorerType !== prevProps.fileExplorerType) {
      this.setState({folderTreeFocusId: ""})
    }

    if(this.props.newFolder !== prevProps.newFolder) {
      if(this.props.fileExplorerType === "fileExplorer"
      || this.props.fileExplorerType === "recentFiles"
      || this.props.fileExplorerType === "openFiles"
      || this.props.fileExplorerType === "bookmarks") {
        if(this.props.newFolder.fileType === "repo") {
          this._getFolderTree();
        } else {
          this._updateFolderTree(this.props.newFolder);
        }
      }
    }

    if(this.props.foldersList !== prevProps.foldersList) {
      if(!prevProps.foldersList &&
        (this.props.fileExplorerType === "fileExplorer"
        || this.props.fileExplorerType === "recentFiles"
        || this.props.fileExplorerType === "openFiles"
        || this.props.fileExplorerType === "bookmarks")) {
        this._getFolderTree();
      } else if ((this.props.foldersList
        && this.props.foldersList[0]
        && this.props.foldersList[0].isRepo)
        && (this.state.folderTree)
        && !this._getFolderTreeTriggered
        && (this.props.fileExplorerType === "fileExplorer"
        || this.props.fileExplorerType === "recentFiles"
        || this.props.fileExplorerType === "openFiles"
        || this.props.fileExplorerType === "bookmarks")) {
          this._getFolderTreeTriggered = true
          this._getFolderTree();
      } else if(this.props.searchType === "fullSearch") {
        this._getSearchFilters()
      }
    }

    if(this.props.pusher !== prevProps.pusher) {
      if(this.props.pusher) {
        if(this.props.fileExplorerType === "fileExplorer"
        || this.props.fileExplorerType === "recentFiles"
        || this.props.fileExplorerType === "openFiles"
        || this.props.fileExplorerType === "bookmarks") {
          var message = this.props.pusher;
          var id = message[0];
          //var parentId = message[1];
          var author = message[2];
          var action = message[3];

          var isFolder = this.props.foldersList.filter(folder => {
              return folder.id === id
          })[0] !== undefined;

          if( this.props.userData &&
            ((author === this.props.userData.user.id && action === "addbookmark" && isFolder)
            || (author === this.props.userData.user.id && action === "deletebookmark" && isFolder)
            || action === "updatefolder")) {

            let userId = this.props.userData.user.id
            let groups = this.props.userData.userGroups.map(userGroup => {
              return userGroup.id
            })

            userService.checkItemPermissions(id, userId, groups).then(response => {
              var permissionsGranted = response.data;
  
              if(permissionsGranted) {
                userService.getFolderDetails(id).then(response => {
                    var newFolder = response.data;
                    this._getBookmarksFolders(newFolder.repo)
                }).catch(error => {
                  console.log(error)
                })
              }
            }).catch(error => {
              console.log(error)
            })
          }
        }
      }
    }

    if(this.props.searchType !== prevProps.searchType) {
      if(this.props.searchType === "fullSearch") {
        this._getSearchTree()
      } else if (this.props.searchType === "tag") {
        this._getTags();
      } else if (this.props.searchType === "smartFolder") {
        this._getSmartFolders();
      }
    }

    if (this.props.fileExplorerType !== prevProps.fileExplorerType || this.state.language !== i18n.language) {
      this._isMounted && this.setState({language: i18n.language});
      if(this.props.fileExplorerType === "offlineFiles" ) {
        this._getOfflineFilesTree()
      }
    }

    if(this.props.searchQuery !== prevProps.searchQuery) {
      if(this.props.sidebarNav === "activity") {
        this._getActivitySelFilters()
      }
    }


    if (window.location.href.indexOf("/files") !== -1 && document.getElementById('files-button')) {
      document.getElementById('files-button')!.style.background = '#F2F2F2'
      document.getElementById('recents-button')!.style.background = '#bfbfbf'
      document.getElementById('bookmark-button')!.style.background = '#bfbfbf'
    }
    else if (window.location.href.indexOf("/recent-files") !== -1 && document.getElementById('files-button')) {
      document.getElementById('recents-button')!.style.background = '#F2F2F2'
      document.getElementById('files-button')!.style.background = '#bfbfbf'
      document.getElementById('bookmark-button')!.style.background = '#bfbfbf'
    }
    else if (window.location.href.indexOf("/bookmarks") !== -1 && document.getElementById('files-button')) {
      document.getElementById('bookmark-button')!.style.background = '#F2F2F2'
      document.getElementById('files-button')!.style.background = '#bfbfbf'
      document.getElementById('recents-button')!.style.background = '#bfbfbf'
    }
    else if (window.location.href.indexOf("/search") !== -1 && document.getElementById('files-button') ) {
      document.getElementById('search-button')!.style.background = '#F2F2F2'
    }

    if (window.location.href.indexOf("/settings") !== -1 && document.getElementById('settings-button') ) {
      document.getElementById('settings-button')!.style.background = '#F2F2F2'
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  public render() {
    const { movingItem, selNav, folderTree, selFolder, recentFilesTree, searchFilters, smartFoldersTree, offlineFilesTree, tags, selTag, newTag, editTagDialog, savingTag, selAction, minDate, maxDate, initialDate, finalDate } = this.state;
    const helpIcon: IIconProps = { iconName: 'Help' };
    const settingsIcon: IIconProps = { iconName: 'Settings' };

    const{ creatingFoldersStructure } = this.props
    var actionsLanguage;

    if (i18n.language === 'de') {
      actionsLanguage = require('../locales/de/actions_de.json')
    }
    else if (i18n.language === 'es') {
      actionsLanguage = actions;
    }
    else {
      actionsLanguage = actions;
    }

    const sortedActions = actionsLanguage["actions"].sort((a:any,b:any) => {
      if(a.text < b.text)
          return -1;
      if(b.text < a.text)
          return 1;

      return 0;
    });

    const DayPickerStrings: IDatePickerStrings = {
      months: [
        i18n.t('app:datePicker.months', { returnObjects: true })[0],
        i18n.t('app:datePicker.months', { returnObjects: true })[1],
        i18n.t('app:datePicker.months', { returnObjects: true })[2],
        i18n.t('app:datePicker.months', { returnObjects: true })[3],
        i18n.t('app:datePicker.months', { returnObjects: true })[4],
        i18n.t('app:datePicker.months', { returnObjects: true })[5],
        i18n.t('app:datePicker.months', { returnObjects: true })[6],
        i18n.t('app:datePicker.months', { returnObjects: true })[7],
        i18n.t('app:datePicker.months', { returnObjects: true })[8],
        i18n.t('app:datePicker.months', { returnObjects: true })[9],
        i18n.t('app:datePicker.months', { returnObjects: true })[10],
        i18n.t('app:datePicker.months', { returnObjects: true })[11],
      ],
      
      shortMonths: [i18n.t('app:datePicker.shortMonths', { returnObjects: true })[0], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[1], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[2], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[3], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[4], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[5], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[6], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[7], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[8], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[9], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[10], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[11]],
    
      days: [i18n.t('app:datePicker.days', { returnObjects: true })[0], i18n.t('app:datePicker.days', { returnObjects: true })[1], i18n.t('app:datePicker.days', { returnObjects: true })[2], i18n.t('app:datePicker.days', { returnObjects: true })[3], i18n.t('app:datePicker.days', { returnObjects: true })[4], i18n.t('app:datePicker.days', { returnObjects: true })[5], i18n.t('app:datePicker.days', { returnObjects: true })[6]],
    
      shortDays: [i18n.t('app:datePicker.shortDays', { returnObjects: true })[0], i18n.t('app:datePicker.shortDays', { returnObjects: true })[1], i18n.t('app:datePicker.shortDays', { returnObjects: true })[2], i18n.t('app:datePicker.shortDays', { returnObjects: true })[3], i18n.t('app:datePicker.shortDays', { returnObjects: true })[4], i18n.t('app:datePicker.shortDays', { returnObjects: true })[5], i18n.t('app:datePicker.shortDays', { returnObjects: true })[6]],
    
      goToToday: i18n.t('app:datePicker.goToToday'),
      prevMonthAriaLabel: 'Go to previous month',
      nextMonthAriaLabel: 'Go to next month',
      prevYearAriaLabel: 'Go to previous year',
      nextYearAriaLabel: 'Go to next year',
      closeButtonAriaLabel: 'Close date picker',
      monthPickerHeaderAriaLabel: '{0}, select to change the year',
      yearPickerHeaderAriaLabel: '{0}, select to change the month',
    };

    var role:number = this.state.folderRole

    const contextualMenuItems: IContextualMenuItem[] = (this.props.fileExplorerRef && [
      {
        key: 'openFolder',
        name: i18n.t('app:openFolder'),
        onClick: this.props.fileExplorerRef._openFolder && this.state.selFolder && this.props.fileExplorerRef._openFolder.bind(this, this.state.selFolder.id),
        iconProps: { iconName: 'FabricOpenFolderHorizontal' },
        disabled: this.state.selFolder && this.state.selFolder.type !== "repo" && !this.state.selFolder.accessGranted && !this.state.selFolder.isFolderInPath,
        hidden: !this.props.isOnline || this.props.maintenance
      },
      {
        key: 'openFolderInNewWindow',
        name: i18n.t('app:openFolderInNewWindow'),
        onClick: this.props.fileExplorerRef._openFolder && this.state.selFolder && this.props.fileExplorerRef._openFolderInNewWindow.bind(this, this.state.selFolder.id),
        iconProps: { iconName: 'OpenInNewWindow' },
        disabled: this.state.selFolder && this.state.selFolder.type !== "repo" && !this.state.selFolder.accessGranted && !this.state.selFolder.isFolderInPath,
        hidden: !this.props.isOnline || this.props.maintenance || !isElectron()
      },
      {
        key: 'newFolder',
        name: i18n.t('app:newFolder'),
        onClick: this.props.headerRef._showNewFolderDialog && this.state.selFolder && this.props.headerRef._showNewFolderDialog.bind(this, this.state.selFolder.id, this.state.selFolder.name),
        iconProps: { iconName: 'FabricNewFolder' },
        disabled: this.state.selFolder && (!ActionsPermissions("createFolder", role) || (this.state.selFolder.type !== "repo" && !this.state.selFolder.accessGranted)),
        hidden: !this.props.isOnline || this.props.maintenance,
      },
      {
        key: 'divider_0',
        itemType: ContextualMenuItemType.Divider
      },
      {
        key: 'addBookmark',
        name: i18n.t('app:addBookmarks'),
        onClick: () => this.props.detailsPanelRef.addBookmark(this.state.selFolder),
        iconProps: { iconName: 'FavoriteStar' },
        hidden: !this.props.isOnline || this.props.maintenance || (this.state.selFolder && (this.state.selFolder.type === "repo" || this.state.selFolder.bookmark))
      },
      {
        key: 'removeBookmark',
        name: i18n.t('app:removeBookmarks'),
        onClick: () => this.props.detailsPanelRef.removeBookmark(this.state.selFolder),
        iconProps: { iconName: 'Unfavorite' },
        hidden: !this.props.isOnline || this.props.maintenance || (this.state.selFolder && (this.state.selFolder.type === "repo" || !this.state.selFolder.bookmark))
      },
      {
        key: 'copyPrivateShortcut',
        name: i18n.t('app:copyPrivateShortcut'),
        onClick: this.props.fileExplorerRef._copyPrivateShortcut && this.props.fileExplorerRef._copyPrivateShortcut.bind(this, this.state.selFolder, "askToOpen"),
        iconProps: { iconName: 'Link' },
        hidden: !this.props.isOnline || this.props.maintenance || (this.state.selFolder && this.state.selFolder.type === "repo")
      },
      {
        key: 'findInFolderTree',
        name: i18n.t('app:findInFolderTree'),
        hidden: (this.state.selFolder && this.state.selFolder.type !== "bookmarkFolder") || (!this.props.isOnline || this.props.maintenance),
        onClick: this.props.fileExplorerRef._openFolder && this.props.fileExplorerRef._findInFolderTree.bind(this, this.state.selFolder),
        iconProps: { iconName: 'BulletedTreeList' },
      },
      {
        key: 'divider_1',
        itemType: ContextualMenuItemType.Divider,
        hidden: this.state.selFolder && this.state.selFolder.type === "repo"
      },
      {
        key: 'renameItem',
        name: i18n.t('app:rename'),
        onClick: this.props.fileExplorerRef._showRenameDialog && this.props.fileExplorerRef._showRenameDialog.bind(this, this.state.selFolder),
        iconProps: { iconName: 'Edit' },
        disabled: this.state.selFolder && (!ActionsPermissions("renameItem", role) || !this.state.selFolder.accessGranted),
        hidden: !this.props.isOnline || this.props.maintenance || (this.state.selFolder && this.state.selFolder.type === "repo")
      },
      {
        key: 'downloadItems',
        name: i18n.t('app:download'),
        onClick: this.props.fileExplorerRef._downloadAsZip && this.props.fileExplorerRef._downloadAsZip.bind(this, [this.state.selFolder]),
        iconProps: { iconName: 'Download' },
        disabled: this.state.selFolder && (!ActionsPermissions("downloadFolders", role) || !this.state.selFolder.accessGranted),
        hidden: !this.props.isOnline || this.props.maintenance || (this.state.selFolder && this.state.selFolder.type === "repo")
      },
      {
        key: 'sharingOptions',
        name: i18n.t('app:sharingOptions'),
        onClick: this.props.fileExplorerRef._shareFolder && this.props.fileExplorerRef._shareFolder.bind(this, this.state.selFolder),
        iconProps: { iconName: 'Share' },
        disabled: this.state.selFolder && (!ActionsPermissions("sharingOptions", role) || (this.state.selFolder.type !== "repo" && !this.state.selFolder.accessGranted)),
        hidden: !this.props.isOnline || this.props.maintenance
      },
      {
        key: 'createPublicLink',
        name: i18n.t('app:createPublicLink'),
        onClick: this.props.fileExplorerRef._createPublicLink && this.props.fileExplorerRef._createPublicLink.bind(this, [this.state.selFolder]),
        iconProps: { iconName: 'Globe' },
        disabled: this.state.selFolder && (!ActionsPermissions("createPublicLink", role) || (this.state.selFolder.type !== "repo" && !this.state.selFolder.accessGranted)),
        //hidden: !this.props.isOnline || this.props.maintenance || (this.state.selFolder && this.state.selFolder.type === "repo")
        hidden: true
      },
      {
        key: 'divider_2',
        itemType: ContextualMenuItemType.Divider,
        hidden: this.state.selFolder && this.state.selFolder.type === "repo"
      },
      {
        key: 'deleteItem',
        name: i18n.t('app:delete'),
        onClick: () => this.props.headerRef._deleteItems && this.props.headerRef._deleteItems([this.state.selFolder]),
        iconProps: { iconName: 'Delete' },
        disabled: this.state.selFolder && (!ActionsPermissions("deleteItem", role) || !this.state.selFolder.accessGranted),
        hidden: !this.props.isOnline || this.props.maintenance || (this.state.selFolder && this.state.selFolder.type === "repo")
      }
    ]) || []

    const self = this;
    function toggleSettingsMenu(){
      self.setState({settingsMenu: !self.state.settingsMenu})
    }
    function togglePersonaMenu(){
      self.setState({personaMenu: !self.state.personaMenu})
    }

    const settingsItems: IContextualMenuItem[] = [
      {
        key: 'updateAvailable',
        text: i18n.t('app:applyUpdateNow'),
        iconProps: { iconName: 'Important' },
        onClick: () => {this._applyUpdate()},
        className: 'important',
        hidden: !this.props.appUpdateAvailable && !this.props.webUpdateAvailable
      },
      {
        key: 'about',
        text: i18n.t('app:aboutSynergyDrive'),
        iconProps: { iconName: 'Info' },
        onClick: () => {this._getAppInfo()},
      },
      {
        key: 'checkForUpdates',
        text: 'Check for updates',
        iconProps: { iconName: 'Sync' },
        onClick: () => {this._checkforUpdates()},
      },
      {
        key: 'settings',
        text: i18n.t('app:settings'),
        iconProps: { iconName: 'Settings' },
        onClick: this._goToSettingsPage.bind(this)
      },
    ];

    const personaItems: IContextualMenuItem[] = [
      {
        itemType: ContextualMenuItemType.Header,
        key: 'userName',
        text: this.state.userName,
        className: "userName"
      },
      {
        itemType: ContextualMenuItemType.Header,
        key: 'userEmail',
        text: this.props.userData && this.props.userData.user.email,
        className: "userEmail"
      },
      {
        key: 'userSettings',
        text: i18n.t('app:account'),
        iconProps: { iconName: 'Contact' },
        onClick: () => {this._goToSettings()},
      },
      {
        key: 'singOut',
        text: i18n.t('app:signOut'),
        iconProps: { iconName: 'signOut' },
        onClick: () => userService.logout(),
      }
    ];

    return(
      <Fabric>
        { (window.innerWidth <= 980 && !isElectron() && detectMob()) &&
          <div className="fixed-bottom" style={{margin: '0 auto', width:'100%', background: '#bfbfbf', borderTop: '1px solid #bfbfbf'}}>
            <CommandBar
              id="mobile-bar"
              styles={{root: { height: isIos() && isInStandaloneMode() ? '58px' : '48px', width: '252px', padding:0, margin:'auto'}}}
              items={[
                {
                  id: 'files-button',
                  name: '',
                  url: '',
                  iconProps: { iconName: 'FabricFolder', styles: {root: {fontSize: '32px', paddingLeft: '10px', marginBottom: isIos() && isInStandaloneMode() ? '12px' : '0px'}} },
                  key: 'files',
                  onClick: this._goToFilesMobile.bind(this),
                  ariaLabel: 'File Explorer',
                  title: i18n.t('app:fileExplorer'),
                  disabled: !this.props.isOnline || this.props.maintenance,
                },
                {
                  id: 'recents-button',
                  name: '',
                  url: '',
                  iconProps: { iconName: 'Recent', styles: {root: {fontSize: '32px', paddingLeft: '10px', marginBottom: isIos() && isInStandaloneMode() ? '12px' : '0px'}} },
                  key: 'recentFiles',
                  onClick: this._goToRecentFiles.bind(this),
                  ariaLabel: "Recent files",
                  title: i18n.t('app:recentFiles'),
                },
                {
                  id: 'bookmark-button',
                  name: '',
                  url: '',
                  iconProps: { iconName: 'FavoriteStar', styles: {root: {fontSize: '32px', paddingLeft: '10px', marginBottom: isIos() && isInStandaloneMode() ? '12px' : '0px'}} },
                  key: 'bookmarks',
                  onClick: this._goToBookmarks.bind(this),
                  ariaLabel: i18n.t('app:bookmarks'),
                  title: i18n.t('app:bookmarks'),
                  disabled: creatingFoldersStructure || !this.props.isOnline || this.props.maintenance
                },
                {
                  id: 'search-button',
                  name: '',
                  url: '',
                  iconProps: { iconName: 'Search', styles: {root: {fontSize: '32px', paddingLeft: '10px', marginBottom: isIos() && isInStandaloneMode() ? '12px' : '0px'}} },
                  key: 'search',
                  onClick: this._goToSearch.bind(this),
                  ariaLabel: "Search",
                  title: i18n.t('app:search'),
                  disabled: creatingFoldersStructure || !this.props.isOnline || this.props.maintenance
                },

              ]}
            />
          </div>
        }
        
        <div id="main-nav" className={"d-flex flex-row " + this.props.appPlatform }>
          <nav className="main">
              <Nav
                expandButtonAriaLabel="Expand or collapse"
                ariaLabel="Main navigation"
                initialSelectedKey={ selNav }
                styles={{
                  root: {
                    width: 48,
                    boxSizing: 'border-box',
                    overflowY: 'auto'
                  }
                }}
                groups={[
                  {
                    links: [
                      {
                        name: '',
                        url: '',
                        icon: 'Home',
                        key: 'home',
                        onClick: this._goToHome.bind(this),
                        ariaLabel: 'Home',
                        title: i18n.t('Home'),
                        //disabled: !this.props.isOnline || this.props.maintenance
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'FabricFolder',
                        key: 'files',
                        onClick: this._goToFiles.bind(this),
                        ariaLabel: 'File Explorer',
                        title: i18n.t('app:fileExplorer'),
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'Search',
                        key: 'search',
                        onClick: this._goToSearch.bind(this),
                        ariaLabel: "Search",
                        title: i18n.t('app:search'),
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'Work',
                        key: 'projects',
                        onClick: this._goToProjects.bind(this),
                        ariaLabel: 'Projects',
                        title: i18n.t('app:projects'),
                        //disabled: !this.props.isOnline || this.props.maintenance
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'TaskLogo',
                        key: 'tasks',
                        onClick: this._goToTasks.bind(this),
                        ariaLabel: 'Tasks',
                        title: i18n.t('app:tasks'),
                        //disabled: !this.props.isOnline || this.props.maintenance
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'Clock',
                        key: 'timeTracking',
                        onClick: this._goToTimeTracking.bind(this),
                        ariaLabel: 'Time tracking',
                        title: 'Time tracking',
                        //disabled: !this.props.isOnline || this.props.maintenance
                      },
                    ]
                  }
                ]}
              />
              <div className="fixedBottom">
              <IconButton
                  iconProps={helpIcon}
                  styles={{
                    root: {
                      width: 48,
                      boxSizing: 'border-box',
                      overflowY: 'auto'
                    }
                  }}
                  title={i18n.t('app:help')}
                  ariaLabel="Help"
                  onClick={ () => {
                    const domain = Cookies.get('serverdomain') || localStorage.getItem('serverdomain') || localStorage.getItem('serverDomain')
                    const domainApi = 'synergy-api.' + domain;
                    const token = Cookies.get('token') || localStorage.getItem('token')
                    window.open("https://synergy.page/support?chatwoot=on&domain="+domainApi+"&token="+token, '_blank')
                  } }
                />
                <div className="settings-wrap">
                  <IconButton
                    id="settings-button"
                    iconProps={settingsIcon}
                    styles={{
                      root: {
                        width: 48,
                        boxSizing: 'border-box',
                        overflowY: 'auto'
                      }
                    }}
                    title={i18n.t('app:settings')}
                    ariaLabel="Settings"
                    onClick={toggleSettingsMenu}
                  />
                  { this.props.appUpdateAvailable || this.props.webUpdateAvailable?
                    <Icon iconName="CircleFill" className="webUpdateAvailable" />
                  : null }
                </div>
                <ContextualMenu
                  items={settingsItems}
                  hidden={!this.state.settingsMenu}
                  target={`#${"settings-button"}`}
                  onItemClick={()=> this.setState({settingsMenu: true})}
                  onDismiss={()=> this.setState({settingsMenu: false})}
                  className="contextualSettingsMenu"
                />
                <div className="persona-wrap">
                  <Persona 
                    id="persona-button" 
                    styles={{
                      root: {
                        width: 48,
                        height: 48,
                        boxSizing: 'border-box',
                        overflowY: 'auto',
                        padding: '0 8px'
                      }
                    }}
                    text={this.state.userName} 
                    size={PersonaSize.size32} 
                    hidePersonaDetails={true}
                    className="persona"
                    onClick={togglePersonaMenu}
                    initialsColor="#7F7F7F"  
                  />
                </div>
                <ContextualMenu
                  items={personaItems}
                  hidden={!this.state.personaMenu}
                  target={`#${"persona-button"}`}
                  onItemClick={()=> this.setState({personaMenu: true})}
                  onDismiss={()=> this.setState({personaMenu: false})}
                  className="contextualUserMenu"
                />
              </div>
          </nav>
          { selNav === "files" ?
            <>
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResize={() => {
                this.props.fileExplorerRef.props.fileExplorerView === "thumbnails" && this.props.fileExplorerRef && this.props.fileExplorerRef._listRoot.current.forceUpdate();
              }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                });
                this.props.fileExplorerRef.props.fileExplorerView === "thumbnails" && this.props.fileExplorerRef && this.props.fileExplorerRef._listRoot.current.forceUpdate();
                this._saveResizeState();
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={240}
              maxWidth={480}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree">
                <p className="title mb-0">{i18n.t('app:fileExplorer')}</p>
                <ul className="ms-Nav-navItems list-page h-100 m-0 pl-0">
                <Tree
                  nodes={this.state.nodesTree}
                  onChange={this.handleNodesChange}>
                  {({onChange, style, node, index, ...rest}) => {

                    const {hasChildren, isExpanded, type} = getNodeRenderOptions(node);
                    const handleChange = () =>{
                      onChange({...updateNode(node, {expanded: !isExpanded}) })
                    }

                    //const nodeRef = React.useRef<any>(id)

                    // return (
                    //   <div style={style} tabIndex={index} id={id} ref={nodeRef} onContextMenu={this.showContextualMenu.bind(this, true, node)}>X
                    //     <Deepness node={node} {...rest}>
                    //       <div className="node-wrapper">
                    //         { hasChildren && !isExpanded?
                    //           <Icon iconName="ChevronRight"  onClick={handleChange} className="d-inline-block mr-1" />
                    //         : hasChildren && isExpanded?
                    //           <Icon iconName="ChevronDown"  onClick={handleChange} className="d-inline-block mr-1" />
                    //         : null }
                            
                    //         <div className="d-inline-block" ref={nodeRef} onClick={() => console.log(node)}>
                    //           { this._onRenderLink(node) }
                    //         </div>
                    //       </div>
                    //     </Deepness>
                    //   </div>
                    //   )
                    if(typeof(style.marginLeft) === "number") style.paddingLeft = style.marginLeft / 2
                    if(typeof(style.marginLeft) === "number") style.marginLeft = 0

                    var currentPath = this.props.folderId
                    let selected = false;
                    let item:any = node;

                    if (item.type === "repo") selected = this.props.fileExplorerType === "fileExplorer" && item.id === currentPath;

                    else if (item.type === "folder" || item.type === "bookmarkFolder" || item.type === "templates" || item.type === "trash") selected = item.id === currentPath;

                    else if (item.type === "recentFiles") selected = this.props.fileExplorerType === "recentFiles";

                    else if (item.type === "openFiles") selected = this.props.fileExplorerType === "openFiles";

                    else if(item.type === "bookmarks") selected = this.props.fileExplorerType === "bookmarks";

                    else if(item.type === "recentFiles") selected = this.props.fileExplorerType === "recentFiles";

                    else if(item.type === "offlineFiles") selected = this.props.storedType === "offline";

                    else if(item.type === "cacheFiles") selected = this.props.storedType === "cache";

                    else if(item.type === "pendingUploads") selected = this.props.storedType === "pendingDrafts";

                    else if(item.type === "smartFolder") selected = this.checkSelSmartFolder(item);

                    else if(item.type === "tag") selected = this.props.fileExplorerType === "recentFiles";

                    else if(item.type === "settingsLink") selected = this.props.headerSection === "settingsOptions";

                    else if(item.type === "troubleshooting") selected = this.props.headerSection === "settingsTroubleshooting";
                    
                    return(
                      <li className={"ms-Nav-navItem " + (selected ? 'bg-selected' : '')} style={style} tabIndex={index} onClick={(ev) => {this._goToFolder(ev, node); if(!isExpanded) handleChange()}}>
                            <div className={ "ms-Nav-compositeLink d-flex"} style={{minWidth: '100%'}} >
                          
                              <button className="chevronButton">
                                { hasChildren && !isExpanded?
                                  <Icon iconName="ChevronRight"  onClick={(e) => {e.stopPropagation(); handleChange();
                                    if(type === "repo"
                                    || type === "folder"
                                    || type === "templates")
                                      this._handleExpandLinkClick(undefined, node)}} className="ms-Nav-chevron d-inline-block" />
                                : hasChildren && isExpanded?
                                  <Icon iconName="ChevronDown"  onClick={(e) => {e.stopPropagation();handleChange()}} className="ms-Nav-chevron d-inline-block" />
                                :
                                  <div className="noChevron"></div>
                                }
                              </button>
                              
                              <button className="ms-Button ms-Button--action ms-Button--command ms-Nav-link pl-0 flex-grow-1">
                                { this._onRenderLink(node) }
                              </button>
                        
                        </div>
                          </li>
                    )}
                  }
                </Tree>
                </ul>
              </nav>
            </Resizable>
            <ContextualMenu
              items={contextualMenuItems}
              hidden={!this.state.showContextualMenu}
              target={this.state.selFolder ? "#" + this.state.selFolder.id : undefined}
              onItemClick={() => this.setState({showContextualMenu: false})}
              onDismiss={() => this.setState({showContextualMenu: false})}
            />
            </>
          : null }
          { selNav === "recentFiles" ?
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResize={() => {
                this.props.fileExplorerRef.props.fileExplorerView === "thumbnails" && this.props.fileExplorerRef && this.props.fileExplorerRef._listRoot.current.forceUpdate();
              }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                });
                this.props.fileExplorerRef.props.fileExplorerView === "thumbnails" && this.props.fileExplorerRef && this.props.fileExplorerRef._listRoot.current.forceUpdate();
                this._saveResizeState();
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={240}
              maxWidth={480}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree search">
                <p className="title mb-0">{i18n.t('app:recentFiles')}</p>
                <Nav
                  styles={{
                    root: {
                      width: "fit-content",
                      boxSizing: 'border-box',
                      overflowY: 'auto',
                      overflowX: 'auto'
                    }
                  }}
                  className="fileExplorer"
                  expandButtonAriaLabel="Expand or collapse"
                  ariaLabel="Nav example with nested links"
                  groups={ recentFilesTree }
                  onRenderLink={ this._onRenderLink }
                  onLinkClick={ this._goToRecentFile.bind(this) }
                />
              </nav>
            </Resizable>
          : null }
          { selNav === "search" ?
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResize={() => {
                this.props.fileExplorerRef.props.fileExplorerView === "thumbnails" && this.props.fileExplorerRef && this.props.fileExplorerRef._listRoot.current.forceUpdate();
              }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                });
                this.props.fileExplorerRef.props.fileExplorerView === "thumbnails" && this.props.fileExplorerRef && this.props.fileExplorerRef._listRoot.current.forceUpdate();
                this._saveResizeState();
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={240}
              maxWidth={480}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree search">
                <p className="title mb-0">{i18n.t('app:search')}</p>
                <Nav
                  styles={{
                    root: {
                      width: "fit-content",
                      boxSizing: 'border-box',
                      overflowY: 'auto',
                      overflowX: 'auto'
                    }
                  }}
                  className="tagFilter search-items"
                  expandButtonAriaLabel="Expand or collapse"
                  ariaLabel="Nav example with nested links"
                  groups={ searchFilters }
                  onRenderLink={ this._onRenderLink }
                  onLinkClick={ this._filterSearch.bind(this) }
                  onLinkExpandClick={ this._handleExpandLinkClick.bind(this) }
                />

                <Nav
                  styles={{
                    root: {
                      width: "fit-content",
                      boxSizing: 'border-box',
                      overflowY: 'auto',
                      overflowX: 'auto'
                    }
                  }}
                  initialSelectedKey={ selFolder }
                  expandButtonAriaLabel="Expand or collapse"
                  ariaLabel="Nav example with nested links"
                  groups={ folderTree }
                  onRenderLink={ this._onRenderLink }
                />
              </nav>
              <ContextualMenu
                  items={[{
                    key: 'openFolder',
                    name: i18n.t('app:openFolder'),
                    onClick: this.props.fileExplorerRef._openFolder && this.state.selFolder && this.props.fileExplorerRef._openFolder.bind(this, this.state.selFolder.id),
                    iconProps: { iconName: 'FabricOpenFolderHorizontal' },
                    disabled: this.state.selFolder && this.state.selFolder.type !== "repo" && !this.state.selFolder.accessGranted,
                    hidden: !this.props.isOnline || this.props.maintenance
                  }]}
                  hidden={!this.state.showContextualMenuSearch}
                  target={this.state.selFolderTaget}
                  onItemClick={() => this.setState({showContextualMenuSearch: false})}
                  onDismiss={() => this.setState({showContextualMenuSearch: false})}
                />
            </Resizable>
          : null }
          { selNav === "smartFolder" ?
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResize={() => {
                this.props.fileExplorerRef.props.fileExplorerView === "thumbnails" && this.props.fileExplorerRef && this.props.fileExplorerRef._listRoot.current.forceUpdate();
              }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                });
                this.props.fileExplorerRef.props.fileExplorerView === "thumbnails" && this.props.fileExplorerRef && this.props.fileExplorerRef._listRoot.current.forceUpdate();
                this._saveResizeState();
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={240}
              maxWidth={480}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree search">
                <p className="title mb-0">{i18n.t('app:smartFolders')}</p>
                <Nav
                  styles={{
                    root: {
                      width: "fit-content",
                      boxSizing: 'border-box',
                      overflowY: 'auto',
                      overflowX: 'auto'
                    }
                  }}
                  expandButtonAriaLabel="Expand or collapse"
                  ariaLabel="Nav example with nested links"
                  groups={ smartFoldersTree }
                  onRenderLink={ this._onRenderLink }
                  onLinkClick={ this._goToSmartFolder.bind(this) }
                />
              </nav>
            </Resizable>
          : null }
          { selNav === "offline" ?
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResize={() => {
                this.props.fileExplorerRef.props.fileExplorerView === "thumbnails" && this.props.fileExplorerRef && this.props.fileExplorerRef._listRoot.current.forceUpdate();
              }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                });
                this.props.fileExplorerRef.props.fileExplorerView === "thumbnails" && this.props.fileExplorerRef && this.props.fileExplorerRef._listRoot.current.forceUpdate();
                this._saveResizeState();
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={240}
              maxWidth={480}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree">
                <p className="title mb-0">{i18n.t('app:offline')}</p>
                <Nav
                  styles={{
                    root: {
                      width: "fit-content",
                      boxSizing: 'border-box',
                      overflowY: 'auto',
                      overflowX: 'auto'
                    }
                  }}
                  expandButtonAriaLabel="Expand or collapse"
                  ariaLabel="Nav example with nested links"
                  className="fileExplorer"
                  groups={ offlineFilesTree }
                  onRenderLink={ this._onRenderLink }
                  onLinkClick={ this._goToOfflineFiles.bind(this) }
                />
              </nav>
            </Resizable>
          : null }
          { selNav === "tag" ?
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResize={() => {
                this.props.fileExplorerRef.props.fileExplorerView === "thumbnails" && this.props.fileExplorerRef && this.props.fileExplorerRef._listRoot.current.forceUpdate();
              }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                });
                this.props.fileExplorerRef.props.fileExplorerView === "thumbnails" && this.props.fileExplorerRef && this.props.fileExplorerRef._listRoot.current.forceUpdate();
                this._saveResizeState();
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={240}
              maxWidth={480}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree search">
                <p className="title mb-0">{i18n.t('app:tags')}</p>
                <Nav
                  styles={{
                    root: {
                      width: "fit-content",
                      boxSizing: 'border-box',
                      overflowY: 'auto',
                      overflowX: 'auto'
                    }
                  }}
                  initialSelectedKey="allTags"
                  expandButtonAriaLabel="Expand or collapse"
                  ariaLabel="Nav example with nested links"
                  groups={ tags }
                  onRenderLink={ this._onRenderLink }
                  onLinkClick={ this._goToTag.bind(this) }
                />
              </nav>
              {this.state.showContextualMenuTags && <ContextualMenu
                items={[
                  {
                    key: 'editCategory',
                    name: i18n.t('app:editCategory'),
                    onClick: () => this.props.headerRef._isMounted && this.props.headerRef._showEditTagDialog(this.state.selFolder.id),
                    iconProps: { iconName: 'Edit' },
                  },
                  {
                    key: 'deleteCategory',
                    name: i18n.t('app:deleteCategory'),
                    onClick: () => this.deleteTagCategory(this.state.selFolder.id),
                    iconProps: { iconName: 'Delete' },
                  },

              ]}
                hidden={!this.state.showContextualMenuTags}
                target={this.state.selFolderTaget}
                onItemClick={() => this.setState({showContextualMenuTags: false})}
                onDismiss={() => this.setState({showContextualMenuTags: false})}
              />}
            </Resizable>
          : null }
          { selNav === "activity" ?
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResize={() => {
                this.props.fileExplorerRef.props.fileExplorerView === "thumbnails" && this.props.fileExplorerRef && this.props.fileExplorerRef._listRoot.current.forceUpdate();
              }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                });
                this.props.fileExplorerRef.props.fileExplorerView === "thumbnails" && this.props.fileExplorerRef && this.props.fileExplorerRef._listRoot.current.forceUpdate();
                this._saveResizeState();
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={240}
              maxWidth={480}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree">
                <p className="title mb-0">{i18n.t('app:activity')}</p>
                <ComboBox
                  key={'actionsFilter'}
                  allowFreeform={true}
                  autoComplete={'on'}
                  options={sortedActions}
                  shouldRestoreFocus={true}
                  className="mx-3"
                  scrollSelectedToTop={true}
                  placeholder={i18n.t('app:selectAnAction')}
                  onChange={ this._filterAction }
                  selectedKey={selAction}
                  label={i18n.t('app:actions')}
                />

                <DatePicker
                  className="m-3"
                  firstDayOfWeek={DayOfWeek.Sunday}
                  strings={DayPickerStrings}
                  placeholder={i18n.t('app:selectStartDate')}
                  ariaLabel="Select start date"
                  allowTextInput={true}
                  minDate={minDate}
                  maxDate={maxDate}
                  initialPickerDate={initialDate}
                  value={initialDate}
                  onSelectDate={this._oninitialDateSelect}
                  label={i18n.t('app:initialDate')}
                />

                <DatePicker
                  className="m-3"
                  firstDayOfWeek={DayOfWeek.Sunday}
                  strings={DayPickerStrings}
                  placeholder={i18n.t('app:selectEndDate')}
                  ariaLabel="Select end date"
                  allowTextInput={true}
                  minDate={initialDate}
                  maxDate={maxDate}
                  initialPickerDate={finalDate}
                  value={finalDate}
                  onSelectDate={this._onfinalDateSelect}
                  label={i18n.t('app:endDate')}
                />
              </nav>
            </Resizable>
          : null }

          { selNav === "publicLinks" ?
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResize={() => {
                this.props.fileExplorerRef.props.fileExplorerView === "thumbnails" && this.props.fileExplorerRef && this.props.fileExplorerRef._listRoot.current.forceUpdate();
              }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                });
                this.props.fileExplorerRef.props.fileExplorerView === "thumbnails" && this.props.fileExplorerRef && this.props.fileExplorerRef._listRoot.current.forceUpdate();
                this._saveResizeState();
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={240}
              maxWidth={480}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree">
                <p className="title mb-0">{i18n.t('app:publicLinks')}</p>
                <Nav
                  styles={{
                    root: {
                      width: "fit-content",
                      boxSizing: 'border-box',
                      overflowY: 'auto',
                      overflowX: 'auto'
                    }
                  }}
                  expandButtonAriaLabel="Expand or collapse"
                  ariaLabel="Nav example with nested links"
                  groups={[
                    {
                      links: [
                        {
                          name: 'All links',
                          url: '',
                          key: 'allPulicLinks',
                          ariaLabel: 'All links',
                          disabled: !this.props.isOnline || this.props.maintenance,
                          type: 'publicLink'
                        }
                      ]
                    }
                  ]}
                  onRenderLink={ this._onRenderLink }
                  onLinkClick={ this._goToOfflineFiles.bind(this) }
                />
              </nav>
            </Resizable>
          : null }

          { selNav === "settings" ?
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResize={() => {
                this.props.fileExplorerRef.props.fileExplorerView === "thumbnails" && this.props.fileExplorerRef && this.props.fileExplorerRef._listRoot.current.forceUpdate();
              }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                });
                this.props.fileExplorerRef.props.fileExplorerView === "thumbnails" && this.props.fileExplorerRef && this.props.fileExplorerRef._listRoot.current.forceUpdate();
                this._saveResizeState();
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={240}
              maxWidth={480}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree">
                <p className="title mb-0">{i18n.t('app:settings')}</p>
                <Nav
                  styles={{
                    root: {
                      width: "fit-content",
                      boxSizing: 'border-box',
                      overflowY: 'auto',
                      overflowX: 'auto'
                    }
                  }}
                  expandButtonAriaLabel="Expand or collapse"
                  ariaLabel="Nav example with nested links"
                  groups={[
                    {
                      links: [
                        {
                          name: i18n.t('app:options'),
                          url: '',
                          key: 'options',
                          ariaLabel: 'Options',
                          disabled: !this.props.isOnline || this.props.maintenance,
                          type: 'settingsLink'
                        },
                        {
                          name: i18n.t('app:troubleshooting'),
                          url: '',
                          key: 'troubleshooting',
                          ariaLabel: 'Troubleshooting',
                          hidden: this.props.appPlatform !== "electron",
                          disabled: !this.props.isOnline || this.props.maintenance,
                          type: 'troubleshooting'
                        }
                      ]
                    }
                  ]}
                  onRenderLink={ this._onRenderLink }
                  onLinkClick={ this._goToOfflineFiles.bind(this) }
                />
              </nav>
            </Resizable>
          : null }

          { movingItem ?
            <Dialog
              hidden={!movingItem}
              onDismiss={this._closeDialog}
              dialogContentProps={{
                isMultiline: false,
                type: DialogType.normal,
                className: "moving-item"
              }}
              modalProps={{
                isBlocking: true,
                dragOptions: undefined,
                className: "moving-item"
              }}
            >
              <div className="mt-4 text-center w-100">
                <aside>
                  {i18n.t('app:movingItemPleaseWait')}
                </aside>
                <span className="spinner ml-2"><Spinner size={SpinnerSize.xSmall} /></span>
              </div>
            </Dialog>
          : null }

          { selTag && editTagDialog ?
            <Dialog
              hidden={!editTagDialog}
              onDismiss={this._closeDialog}
              dialogContentProps={{
                type: DialogType.normal,
                title: i18n.t('app:editTag') + selTag.search,
                className: "edit-dialog"
              }}
              modalProps={{
                dragOptions: undefined,
                isBlocking: savingTag
              }}
            >
              <form name="form" onSubmit={this._editTag} >
                <TextField underlined autoFocus placeholder={i18n.t('app:tagName')} name="newFolder" value={ newTag } onChange={ this.handleTagChange } disabled={ savingTag } required />
              </form>

              <DialogFooter>
                { savingTag ?
                  <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-text-top" />
                : null }
                <DefaultButton onClick={this._closeDialog} text={i18n.t('app:cancel')} />
                <PrimaryButton onClick={this._editTag} text={i18n.t('app:save')} />                   
              </DialogFooter>
            </Dialog>
          : null }
        </div>
      </Fabric>
    )
  }

  private handleNodesChange = nodesTree => {
    this.setState({nodesTree});
  }

  private _saveResizeState() {
    const { panelHeight, panelWidth } = this.state;
    
    var prefs = {
      height: panelHeight,
      width: panelWidth
    }

    localStorage.setItem("sidebarPrefs", JSON.stringify(prefs))
  }

  private toggleNav(nav) {
    const { selNav } = this.state;
    if (selNav === nav) {
      this.props.callbackFunction({showLeftPanel: false})
      nav = "";
    } else {
      this.props.callbackFunction({showLeftPanel: true})
    }
    this._isMounted && this.setState({ selNav: nav })
  }

  private _goToFolder(ev, item) {
    console.log(ev, item)
    if (item) {
      if(item.type === "recentFiles") history.push("/recent-files/" + item.id.replace("recentFiles-", ""))
      else if(item.type === "openFiles") history.push("/open-files")
      else if(item.type === "bookmarks") {
        history.push("/bookmarks");
      } else if(item.type === "pendingUploads") {
        history.push("/pending-uploads")
      } else if(((item.type === "folder" || item.type === "bookmarkFolder") && (item.accessGranted || item.isFolderInPath)) || item.type === "repo" || item.type === "templates" || item.type === "trash") {
        var parser = new UAParser.UAParser();
        var os = parser.getResult().os;

        if ((os.name === "Windows" && ev.ctrlKey)
          || (os.name === "Mac OS" && ev.metaKey)) {
            this.props.fileExplorerRef._openFolderInNewWindow(item.id)
          } else {
            history.push("/files/" + item.id)
          }
      }
    }
  };

  private _goToRecentFile(ev: React.MouseEvent<HTMLElement> | undefined, item?: INavLink | undefined) {
    if (ev) {
      ev.preventDefault();
    }

    if (item) {
      if(item.type === "openFiles") history.push("/open-files")
      if(item.type === "recentFiles") history.push("/recent-files/" + item.id)
    }
  };

  private _goToSmartFolder(ev: React.MouseEvent<HTMLElement> | undefined, item?: INavLink | undefined) {
    if (ev) {
      ev.preventDefault();
    }

    if (item && item.type === "smartFolder") {
      history.push("/smart-folder/" + item.search)
    }
  };

  private _goToTag(ev: React.MouseEvent<HTMLElement> | undefined, item?: INavLink | undefined) {
    if (ev) {
      ev.preventDefault();
    }

    if (item && item.type === "tag") {
      const { searchQuery } = this.props;
      const params = new URLSearchParams(searchQuery);
      params.set("text", "");
      params.set("tags", item.search);
      params.set("name", "");
      params.set("parent", item.repo);

      history.push("/tag/?" + params.toString());
    }
  };

  private _filterSearch(ev: React.MouseEvent<HTMLElement> | undefined, item?: INavLink | undefined) {
    if (ev
      && (item && item.type
      && item.type !== "filterFolders"
      && item.type !== "filterTags"
      && item.type !== "filterFileType")) {
      ev.preventDefault();
    }

    if (item && item.type === "tag") {
      this.props.addTag({key: item.id, name:item.name, search: item.id, type: item.type, color: item.color})
    } else if (item && item.type === "folder") {
      this.props.addFolder(item)
    } else if (item && item.type === "repoFilter") {
      this.props.addFolder(item)
    } else if ((item && item.type === "filterPdf")
      || (item && item.type === "filterWord")
      || (item && item.type === "filterExcel")
      || (item && item.type === "filterPowerpoint")
      || (item && item.type === "filterEmail")) {
        this.props.addType({key: item.name, name:item.name, search: item.search, type: item.type})
    }
  };

  private checkSelFilter(item) {
    const { searchQuery } = this.props;

    const params = new URLSearchParams(searchQuery);

    var repoQuery = params.get('parent');
    if (item.type === "repoFilter") {
      if(item.key === repoQuery) {
        return true
      }
    } else if (item.type === "folder") {
      item.selFilter = false;
      if(item.key === repoQuery) {
        item.selFilter = true
        return true;
      } else {
        /*var parent = this.findById(this.state.searchFilters, item.parent_id)
        if (parent && parent.selFilter) {
          item.selFilter = true
        }*/
      }
    }

    var tagsQuery: any = params.get('tags');
    if (item.type === "tag" && tagsQuery) {
      let currentRepo = this.findById(this.state.searchFilters, item.repo)
      tagsQuery = tagsQuery.split(',')

      let selected = tagsQuery.filter(tag => {
        return tag === item.search && ((currentRepo && currentRepo.id === item.repo) || !currentRepo)
      })

      if (selected.length) {
        let searchFilters = this.state.searchFilters;
        if(searchFilters.length > 0) {
          let selRepo: any = searchFilters.filter(searchFilter => {
            return searchFilter.links[0].id === item.repo
          })

          selRepo[0].links[0].links[0].isExpanded = true;
        }

        return true;
      }
    }

    var docTypeQuery: any = params.get('docType');
    if (docTypeQuery &&
        ((item && item.type === "filterPdf")
        || (item && item.type === "filterWord")
        || (item && item.type === "filterExcel")
        || (item && item.type === "filterPowerpoint")
        || (item && item.type === "filterEmail"))
      ) {
      let currentRepo = this.findById(this.state.searchFilters, item.repo)

      let selected: any = docTypeQuery.indexOf(item.search) !== -1 && currentRepo && currentRepo.id === item.repo;

      if (selected) {
        let searchFilters = this.state.searchFilters;
        if(searchFilters.length > 0) {
          let selRepo: any = searchFilters.filter(searchFilter => {
            return searchFilter.links[0].id === item.repo
          })

          selRepo[0].links[0].links[1].isExpanded = true;
        }

        return true;
      }
    }

    return false
  }

  private checkSelSmartFolder(item) {
    const { searchQuery } = this.props;

    return searchQuery === item.search
  }

  private _goToOfflineFiles(ev: React.MouseEvent<HTMLElement> | undefined, item?: INavLink | undefined) {
    if (ev) {
      ev.preventDefault();
    }

    if (item) {
      if (item.type === "offlineFiles") {
        history.push("/offline-files")
      } else if (item.type === "cacheFiles") {
        history.push("/cache-files")
      } else if (item.type === "pendingUploads") {
        history.push("/pending-uploads")
      }
    }
  };

  private showContextualMenu(show, item, ev) {
    ev.preventDefault()

    item.fileType = "dir"
    item.fileName = item.name

    var repoUsers = this.props.repoUsers;
    let adminsData: any = [];
    let usersData: any = [];
    let groupsData: any = [];
    let externalsData: any = [];

    item.admins && item.admins.forEach(admin => {
      var adminData = repoUsers.filter(repoUser => {
        return repoUser.user.id === admin
      })[0]
      if(adminData) adminsData.push(adminData)
    })

    item.users && item.users.forEach(user => {
      var userData = repoUsers.filter(repoUser => {
        return repoUser.user.id === user.id
      })[0]
      if(userData) usersData.push(userData)
    })

    item.groups && item.groups.forEach(group => {
      var groupData = repoUsers.groups.filter(repoGroup => {
        return repoGroup.id === group.id
      })[0]
      if(groupData) groupsData.push(groupData)
    })

    item.externals && item.externals.forEach(user => {
      var userData = repoUsers.filter(repoUser => {
        return repoUser.user.id === user.id
      })[0]
      if(userData) externalsData.push(userData)
    })

    item.adminsData = adminsData
    item.usersData = usersData
    item.groupsData = groupsData
    item.externalsData = externalsData

    if(item.id && this.props.userData && this.props.foldersList) {
      this.getFolderRole(item.id, this.props.userData, this.props.foldersList)
    }

    this._isMounted && this.setState({
      showContextualMenu: show,
      selFolder: item,
      selFolderTaget: ev.target as HTMLElement
    })
  }

  private showContextualMenuSearch(show, item, ev) {
    ev.preventDefault()
    item.fileType = "dir"
    item.fileName = item.name

    if(item.id && this.props.userData && this.props.foldersList) {
      this.getFolderRole(item.id, this.props.userData, this.props.foldersList)
    }

    this._isMounted && this.setState({
      showContextualMenuSearch: show,
      selFolder: item,
      selFolderTaget: ev.target as HTMLElement
    })
  }

  private showContextualMenuTags(show, item, ev) {
    ev.preventDefault()
    item.fileType = "dir"
    item.fileName = item.name

    this._isMounted && this.setState({
      showContextualMenuTags: show,
      selFolder: item,
      selFolderTaget: ev.target as HTMLElement
    })
  }

  private filterTags(filter?) {
    const {tagsList, tagFilter} = this.state
    var filteredList:any;
    
    let key = filter || tagFilter;
    let tagCategoryId = '';

    if (key === "allTags") {
      filteredList = tagsList;
    } else if ( key === this.state.repoData[0].id + '-tagCategory') {
      filteredList = tagsList.filter(tag => {
        return tag.categoryTagId === this.state.repoData[0].id + '-tagCategory' || undefined
      });
    } else {
      tagCategoryId = key.replace('cat-','')
      filteredList = tagsList.filter(tag => {
        return tag.categoryTagId === key.replace('cat-','')
      });
    }

    this.props.callbackFunction({tagsList: filteredList, groups: null, tagsListUpdate: !this.props.tagsListUpdate, tagCategoryId: tagCategoryId})
    setTimeout(()=>this.props.callbackFunction({tagsListUpdate: this.props.tagsListUpdate}),50)
  }

  private getFolderRole = async(folderId, userData, foldersList) => {
    var role = await FolderRole(folderId, userData, foldersList)
    this._isMounted && this.setState({folderRole: role}, () => console.log("Folder role", role))
  }

  private _onRenderLink = (link: any): JSX.Element | null => {
    const { selNav } = this.state;
    var currentPath = this.props.folderId

    return (
      link.type === "repo" ?
        <div ref={() => link.id} id={link.id} className={"folderLink droptarget " + (this.props.fileExplorerType === "fileExplorer" && link.id === currentPath ? "selFolder":"")} onContextMenu={this.props.searchType === "fullSearch" ? undefined : this.showContextualMenu.bind(this, true, link)} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave}
          onDragOver={this.onDragOver} onDrop={ evt => { this.onFileDrop(evt, link) } }>
          <Icon   iconName="OfflineStorage" className="repoIcon child-elements" />
          <span className="child-elements">{ link.name }</span>
        </div>
      : link.type === "repoFilter" ?
        <div className={"folderLink "}>
          <Icon   iconName="OfflineStorage" className="repoIcon" />
          <span>{ link.name }</span>
        </div>
      : link.type === "folder" || link.type === "bookmarkFolder" ?
        <div ref={() => link.id} id={link.id} onContextMenu={this.props.searchType === "fullSearch" ? this.showContextualMenuSearch.bind(this, true, link) : this.showContextualMenu.bind(this, true, link)} className={"folderLink dir droptarget " + (link.id === currentPath ? "selFolder":"") + " " + ((link.type === "folder" && this.state.folderTreeFocusId === link.id) ? " scrolledFolder" : "")} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} draggable={this.props.searchType !== "fullSearch"} onDragStart={this.onDragStart.bind(this,link)}
                onDragOver={this.onDragOver} onDrop={ evt => { this.onFileDrop(evt, link) } }>
          <div className="icon-wrap">
            <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } className="child-elements" />
            { link.isFolderInPath ?
              <Icon iconName="ChevronRightSmall" className="folder-in-path" />
              : !link.accessGranted && !link.isFolderInPath ?
              <Icon iconName="lockSolid" className="no-access-folder" />
            : null }
          </div>
          <span className="child-elements">{ link.name}</span>
        </div>
      : link.type === "templates" ?
        <div className={"folderLink droptarget dir templates " + (link.id === currentPath ? "selFolder":"")}
        onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave}
        onDragOver={this.onDragOver} onDrop={ evt => { this.onFileDrop(evt, link) } }>
          <Icon {...getFileTypeIconProps({ type: FileIconType.documentsFolder, size: 20, imageFileType: 'svg' }) } />
          <span className="child-elements">{ link.name }</span>
        </div>
      : link.type === "trash" ?
        <div className={"folderLink dir " + (link.id === currentPath ? "selFolder":"")}>
          <Icon   iconName="Trash" className="trash align-top" />
          <span>{ link.name }</span>
        </div>
      : link.type === "recentFiles" ?
      <div className={"folderLink " + (this.props.fileExplorerType === "recentFiles" ? "selFolder":"")}>
        <Icon   iconName="Recent" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "openFiles" ?
        <div className={"folderLink " + (this.props.fileExplorerType === "openFiles" ? "selFolder":"")}>
          <Icon   iconName="ReopenPages" className="openFiles align-top" />
          <span>{ link.name }</span>
        </div>
      : link.type === "bookmarks" ?
      <div className={"folderLink " + (this.props.fileExplorerType === "bookmarks" ? "selFolder":"")}>
        <Icon   iconName="FavoriteStar" className="bookmarks align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "offlineFiles" ?
        <div className={"folderLink " + (this.props.storedType === "offline" ? "selFolder":"")}>
          <Icon   iconName="CloudDownload" className="offlineFiles align-top" />
          <span>{ link.name }</span>
        </div>
      : link.type === "cacheFiles" ?
        <div className={"folderLink " + (this.props.storedType === "cache" ? "selFolder":"")}>
          <Icon   iconName="DatabaseSync" className="offlineFiles align-top" />
          <span>{ link.name }</span>
        </div>
      : link.type === "pendingUploads" ?
        <div className={"folderLink" + (this.props.storedType === "pendingDrafts" ? " selFolder":"")}>
          <Icon   iconName="CloudUpload" className="offlineFiles align-top" />
          <span>{ link.name } {(this.state.pendingDrafts && this.state.pendingDrafts.length > 0 ? " (" + this.state.pendingDrafts.length + ")" : "")}</span>
        </div>
      : link.type === "smartFolder" ?
        <div className={"folderLink " + (this.checkSelSmartFolder(link) ? "smartFolder-selected" : "")} onClick={ evt => { evt.preventDefault(); this.setState({goBack: 0}) } }>
          <Icon   iconName="FolderSearch" className="smart-folder" />
          <span>{ link.name }</span>
          { link.status === "deleting" ?
            <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline m-0 ml-2" />
          :
            <span onClick={ evt => { this._deleteSmartFolder(evt, link) } }><Icon   iconName="Cancel" className="delete small align-baseline m-0 ml-2" /></span>
          }
        </div>
      : link.type === "tag" && selNav === "tag" ?
        <div className={"folderLink "} onClick={ evt => { evt.preventDefault(); this.setState({goBack: 0}) } }>
          <Icon   iconName="Tag" className="tag" />
          <span>{ link.name }</span>
          { link.status === "active" ?
            <span onClick={ evt => { evt.stopPropagation(); this.setState({editTagDialog: true, selTag: link, newTag: link.search})} }><Icon   iconName="Edit" className="edit small align-baseline m-0 ml-2" /></span>
          : null }
          { link.status === "deleting" ?
            <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline m-0 ml-2" />
          :
            <span onClick={ evt => { this._deleteTag(evt, link) } }><Icon   iconName="Cancel" className="delete small align-baseline m-0" /></span>
          }
        </div>
        : link.type === "tag" && selNav === "search" ?
        <div className={"folderLink d-flex align-items-center"} onClick={ evt => { evt.preventDefault(); this.setState({goBack: 0}) } }>
          <div style={{backgroundColor: link.color, width: '16px', height: '16px', borderRadius: '10px'}} className="tag mr-2" />
          <span >{ link.name }</span>
        </div>
        : link.type === "tagCategory" ?
        <div className={this.props.searchType==="fullsearch" ? "folderLink" : "folderLink tagCategory"} onClick={()=>{this._isMounted && this.setState({tagFilter: link.key}); this.filterTags(link.key)}} onContextMenu={this.showContextualMenuTags.bind(this, true, link)}>
          <Icon iconName="Tag" style={{color: '#505050'}} className="tag" />
          <span>{ link.name }</span>
        </div>
        : link.type === "filterAllTags" ?
        <div className="folderLink tagCategory" onClick={()=>{this._isMounted && this.setState({tagFilter: link.key}); this.filterTags(link.key)}}>
          <Icon iconName="BulletedList" className="tag" />
          <span>{ link.name }</span>
        </div>
        : link.type === "filterStandardTags" ?
        <div className="folderLink tagCategory" onClick={()=>{this._isMounted && this.setState({tagFilter: link.key}); this.filterTags(link.key)}}>
          <Icon iconName="Tag" className="tag" />
          <span>{ link.name }</span>
        </div>
        :link.type === "filterPdf" ?
        <div className={"folderLink "}>
          <Icon   iconName="PDF" className="fileType pdf" />
          <span>{ link.name }</span>
        </div>
        :link.type === "filterWord" ?
        <div className={"folderLink "}>
          <Icon   iconName="WordDocument" className="fileType word" />
          <span>{ link.name }</span>
        </div>
        :link.type === "filterExcel" ?
        <div className={"folderLink "}>
          <Icon   iconName="excelDocument" className="fileType excel" />
          <span>{ link.name }</span>
        </div>
        :link.type === "filterPowerpoint" ?
        <div className={"folderLink "}>
          <Icon   iconName="PowerPointDocument" className="fileType powerpoint" />
          <span>{ link.name }</span>
        </div>
        :link.type === "filterEmail" ?
        <div className={"folderLink "}>
          <Icon   iconName="Mail" className="fileType email" />
          <span>{ link.name }</span>
        </div>
        :link.type === "publicLink" ?
        <div className="folderLink" onClick={ () => history.push('/public-links') }>
          <Icon iconName="Globe" className="publicLink" />
          <span className="allPublicLinks">{ link.name }</span>
        </div>
        :link.type === "settingsLink" ?
        <div className={"settings folderLink" + (this.props.headerSection === "settingsOptions" ? " selFolder" : "")} onClick={ () => history.push('/settings/options') }>
          <Icon iconName="Settings" className="publicLink" />
          <span className="settings">{ link.name }</span>
        </div>
        :link.type === "troubleshooting" ?
        <div className={"settings folderLink" + (this.props.headerSection === "settingsTroubleshooting" ? " selFolder" : "")} onClick={ () => history.push('/settings/troubleshooting') }>
          <Icon iconName="AlertSettings" className="publicLink" />
          <span className="settings">{ link.name }</span>
        </div>
      :
        <div className="link">
          <span>{ link.name }</span>
        </div>
    )
  }

  private _onRenderGroupHeader = (group: any): JSX.Element | null => {
    return(
      <p className="nav-repo-group m-0">{ group.name }</p>
    );
  }

  private _getSearchTree() {
    var userData = this.props.userData
    var repos = userData.repository;
    var repoData: any = [];
    for (let i = 0; i < repos.length; i++) {
      var repo = repos[i];
      repoData.push({
        id: repo.repository.id,
        name: repo.repository.name,
        role: repo.role.repositoryRole
      })
    };

    this._isMounted && this.setState({
      repoData: repoData
    }, () => {
      if(this.props.searchType === "fullSearch") {
        this._getSearchFilters();
        }
      if(this.props.searchType === "tag") this._getTags();
      if(this.props.searchType === "smartFolder") this._getSmartFolders();
    })
  }

  private _getFolderTreeTriggered: boolean = false

  private _getFolderTree = () => {
    const { repoData } = this.state;
    var folderTree: any = [];
    var folders: any = this.props.foldersList;

    if(repoData && repoData.length && folders) {
        // Sorting folders by name
        if (folders) {
          folders = folders.sort((a,b) => {
            if(a.name.toLowerCase() < b.name.toLowerCase())
                return -1;
            if(b.name.toLowerCase() < a.name.toLowerCase())
                return 1;

            return 0;
          });

          var nested = folders.map(folder => {
            // var accessGranted: boolean = false;
            // var userGroups = this.props.userData.userGroups.map(userGroup => {
            //   return userGroup.id
            // })

            // var isUser = folder.users && folder.users.filter(user => {
            //   return user.id === this.props.userData.user.id
            // })

            // var isAdmin = folder.admins && folder.admins.filter(admin => {
            //   return admin === this.props.userData.user.id
            // })

            // var groupAccess = folder.admins && folder.groups.filter(group => {
            //   return userGroups.includes(group.id)
            // })

            // var isExternal = folder.externals && folder.externals.filter(externalUser => {
            //   return externalUser === this.props.userData.user.id
            // })

            // if((isAdmin && isAdmin.length > 0)
            //   || (isUser && isUser.length > 0)
            //   || (groupAccess && groupAccess.length > 0)
            //   || (isExternal && isExternal.length > 0))
            //   accessGranted = true

            folder.key = folder.id;
            folder.parentId = folder.parent_id;
            folder.type = "folder";
            folder.accessGranted = true;
            folder.isExpanded = false;
            folder.hidden = folder.trash;
            folder.state= { expanded: false }
            folder.hasChildren = folder.hasSubFolders

            folder.links = folder.hasSubFolders ? [{}] : [];
            
            return folder
          })

          // var immediateLinks = nested;
          // var allChildrenLinks: any = [];

          // for (let i = 0; i < nested.length; i++) {
          //   let immediateLink = immediateLinks[i]

          //   var childrenLinks = nested.filter(link => {
          //     return link.parent_id === (immediateLink && immediateLink.id)
          //   })

          //   childrenLinks.links = childrenLinks.sort((a,b) => {
          //     if(a.name.toLowerCase() < b.name.toLowerCase())
          //         return -1;
          //     if(b.name.toLowerCase() < a.name.toLowerCase())
          //         return 1;
      
          //     return 0;
          //   });

          //   allChildrenLinks.push(childrenLinks)

          //   immediateLink && (immediateLink.links = childrenLinks)
          // }

          var internalRepos = repoData.filter(repo => {
            if(repo.role === "ADMIN"
              || repo.role === "USER"
              || repo.role === "INTERNAL") {
              return true
            } else {
              return false
            }
          })

          for (let i = 0; i < internalRepos.length; i++) {
            let repo = internalRepos[i];

            this.filteredNested = nested.filter(item => {
              return item.parent_id === repo.id
            })

            if(this.props.searchType !== "fullSearch") {
               // Template folder
               let templatesId = repo.id + "-templates"
               let templates = {
                 key: templatesId,
                 id: templatesId,
                 name: 'Templates',
                 isExpanded: false,
                 links: [],
                 children: [],
                 type: "templates",
                 url: '',
                 hasSubFolders: true,
                 state: {
                  expanded: false
                 }
               }
               this.filteredNested.push(templates)

               //this._addChildrenTree(templates, 1, null)

              // Trash folder
              let trashId = repo.id + "-trash"
              let trash = {
                key: trashId,
                id: trashId,
                name: i18n.t('app:trash'),
                isExpanded: false,
                links: [],
                type: "trash"
              }
              this.filteredNested.push(trash)
              folderTree.unshift(
                  {
                    key: "recentFiles-" + repo.id,
                    id: "recentFiles-" + repo.id,
                    name: i18n.t('app:recentFiles'),
                    isExpanded: false,
                    type: "recentFiles",
                  },
                  {
                    key: "bookmarks-" + repo.id,
                    id: "bookmarks-" + repo.id,
                    name: i18n.t('app:bookmarks'),
                    hasSubFolders: true,
                    isExpanded: false,
                    type: "bookmarks",
                    state: {
                      expanded: true
                    }
                  }
              )


              this.props.appPlatform === ("electron" || "openfin") &&
              folderTree.unshift(
                  // {
                  //   key: "openFiles-" + repo.id,
                  //   id: "openFiles-" + repo.id,
                  //   name: i18n.t('app:openFiles'),
                  //   isExpanded: false,
                  //   type: "openFiles",
                  //   //hidden: this.props.appPlatform !== ("electron" || "openfin")
                  // },
                  {
                    key: "pendingUploads-" + repo.id,
                    id: "pendingUploads-" + repo.id,
                    name: "Sync",
                    isExpanded: false,
                    links: [],
                    // disabled: !this.state.pendingDrafts || (this.state.pendingDrafts && this.state.pendingDrafts.length === 0),
                    disabled: true,
                    type: "pendingUploads"
                  }
              )

              this._getBookmarksFolders(repo.id)
              folderTree.push(
                  {
                    key: repo.id,
                    id: repo.id,
                    name: repo.name,
                    isExpanded: true,
                    hasSubFolders: true,
                    children: this.filteredNested,
                    type: "repo",
                    state: {
                      expanded: true
                    }
                  }
                )
            }
          }

          var externalRepos = repoData.filter(repo => {
            return repo.role === "EXTERNAL"
          })
          var externalReposLinks: any = []

          for (let i = 0; i < externalRepos.length; i++) {
            let repo = externalRepos[i];

            externalReposLinks.push(
              {
                key: repo.id,
                id: repo.id,
                name: repo.name,
                isExpanded: false,
                type: "repo"
              }
            )
          }

          if(externalRepos.length > 0) {
            folderTree.push({
              name: i18n.t('app:sharedWithMe'),
              children: externalReposLinks
            })
          }

          this._isMounted && this.setState({
            folderTree: folderTree,
            nodesTree: folderTree
          }, () => {
            //this._getExpandedFolders(folders)
          });    
        }
      }
  }

  
  private _handleExpandLinkClick(ev?: React.MouseEvent<HTMLElement> | undefined, item?: any) {
    // Trigger only on direct click on chevron icon
    var target: any = ev && ev.target;
    if(this.props.searchType !== "fullSearch"
      || ((this.props.searchType === "fullSearch") && target && target.className.indexOf("ms-Nav-chevron") !== -1 && item && !item.isExpanded && item.links && item.links.length <= 1 && item.type !== "filterTags")) {
      if(ev) ev.preventDefault()

      this.setState({folderTreeFocusId: ""})
      
      userService.getFolderContent(item.id).then(response => {
        var repoUsers = this.props.repoUsers;
        // var userGroups = this.props.userData.userGroups.map(userGroup => {
        //   return userGroup.id
        // })

        var links: any = response.data.folders.map(link => {
          link.key = link.id
          link.isExpanded = false
          link.parentId = link.parent_id
          link.type = "folder"
    
          link.fileName = link.name
          link.fileType = "dir"

          // var accessGranted: boolean = false;

          // var isUser = link.users.filter(user => {
          //   return user.id === this.props.userData.user.id
          // })

          // var isAdmin = link.admins.filter(admin => {
          //   return admin === this.props.userData.user.id
          // })

          // var groupAccess = link.groups.filter(group => {
          //   return userGroups.includes(group.id)
          // })

          // var isExternal = link.externals.filter(externalUser => {
          //   return externalUser === this.props.userData.user.id
          // })

          // if(isAdmin.length > 0
          //   || isUser.length > 0
          //   || groupAccess.length > 0
          //   || isExternal.length > 0)
          //   accessGranted = true

          link.accessGranted = true

          let adminsData: any = [];
          let usersData: any = [];
          let groupsData: any = [];
          let externalsData: any = [];

          link.admins && link.admins.forEach(admin => {
              var adminData = repoUsers.filter(repoUser => {
              return repoUser.user.id === admin
              })[0]
              if(adminData) adminsData.push(adminData)
          })

          link.users && link.users.forEach(user => {
              var userData = repoUsers.filter(repoUser => {
              return repoUser.user.id === user.id
              })[0]
              if(userData) usersData.push(userData)
          })

          link.groups && link.groups.forEach(group => {
              var groupData = repoUsers.groups.filter(repoGroup => {
              return repoGroup.id === group.id
              })[0]
              if(groupData) groupsData.push(groupData)
          })

          link.externals && link.externals.forEach(user => {
              var userData = repoUsers.filter(repoUser => {
              return repoUser.user.id === user.id
              })[0]
              if(userData) externalsData.push(userData)
          })

          link.adminsData = adminsData
          link.usersData = usersData
          link.groupsData = groupsData
          link.externalsData = externalsData

          var bookmark: any = link.bookmarks && link.bookmarks.filter(bookmarkData => {
              return bookmarkData.user_id === this.state.userData.user.id
          })[0] !== undefined;

          link.bookmark = bookmark

          return link
        })

        links = links.sort((a,b) => {
          if(a.name.toLowerCase() < b.name.toLowerCase())
              return -1;
          if(b.name.toLowerCase() < a.name.toLowerCase())
              return 1;
  
          return 0;
        });

        if(this.props.searchType === "fullSearch") this._expandSearchFolder(item.id, links)
        else this._expandFolderWhileBrowsing(item.id, links)
      }).catch(error => {
        console.log(error)
      })
    } else if (this.props.searchType === "fullSearch" && item.isExpanded) {
      //if(ev) ev.preventDefault()
      this._expandSearchFolder(item.id, item.links)
    }
  }

  private _addChildrenTree(item: INavLink | undefined, levels: number, expandFolders: any) {
    setTimeout(() => {
      if(item && ((item.isExpanded && (item.type === "repo" || item.type === "folder")) || item.type ==="templates")) {
        userService.listFoldersLevels(item.id, levels).then((response: any) => {
            var folderTree: any = this.props.searchType === "fullSearch" ? this.state.searchFilters :  this.state.nodesTree;
            var repoUsers = this.props.repoUsers;
            var links = response.data

            links = links.map(link => {
              var isExpanded = expandFolders && expandFolders.some(expandFolder => expandFolder === link.id || false)

              link.key = link.id
              link.isExpanded = isExpanded
              link.state = {expanded: isExpanded}
              link.parentId = link.parent_id
              link.type = "folder"
        
              link.fileName = link.name
              link.fileType = "dir"
  
              // var accessGranted: boolean = false;
              // var userGroups = this.state.userData.userGroups.map(userGroup => {
              //     return userGroup.id
              // })
  
              // var isUser = link.users.filter(user => {
              //     return user.id === this.state.userData.user.id
              // })
  
              // var isAdmin = link.admins.filter(user => {
              //     return user === this.state.userData.user.id
              // })
  
              // var groupAccess = link.groups.filter(group => {
              //     return userGroups.includes(group.id)
              // })
  
              // var isExternal = link.externals.filter(user => {
              //     return user === this.state.userData.user.id
              // })
  
              // if(isAdmin.length > 0
              //     || isUser.length > 0
              //     || groupAccess.length > 0
              //     || isExternal.length > 0)
              //     accessGranted = true
              
              link.accessGranted = true
  
              let adminsData: any = [];
              let usersData: any = [];
              let groupsData: any = [];
              let externalsData: any = [];
  
              link.admins && link.admins.forEach(admin => {
                  var adminData = repoUsers.filter(repoUser => {
                  return repoUser.user.id === admin
                  })[0]
                  if(adminData) adminsData.push(adminData)
              })
  
              link.users && link.users.forEach(user => {
                  var userData = repoUsers.filter(repoUser => {
                  return repoUser.user.id === user.id
                  })[0]
                  if(userData) usersData.push(userData)
              })
  
              link.groups && link.groups.forEach(group => {
                  var groupData = repoUsers.groups.filter(repoGroup => {
                  return repoGroup.id === group.id
                  })[0]
                  if(groupData) groupsData.push(groupData)
              })
  
              link.externals && link.externals.forEach(user => {
                  var userData = repoUsers.filter(repoUser => {
                  return repoUser.user.id === user.id
                  })[0]
                  if(userData) externalsData.push(userData)
              })
  
              link.adminsData = adminsData
              link.usersData = usersData
              link.groupsData = groupsData
              link.externalsData = externalsData
  
              var bookmark: any = link.bookmarks && link.bookmarks.filter(bookmarkData => {
                  return bookmarkData.user_id === this.state.userData.user.id
              })[0] !== undefined;
  
              link.bookmark = bookmark
  
              return link
            })
  
            function searchRecursive(data, id) {
              let found = typeof data === "object" && data.find(d => d.id === id && (d.type === "repo" || d.type === "folder" || d.type === "templates"));
              if (!found) {
                let i = 0;
                while(!found && i < data.length) {
                  if (data[i].children && data[i].children.length) {
                    found = searchRecursive(data[i].children, id);
                  }
                  i++;
                }
              }
              return found;
            }
        
            var prevFolder: any = searchRecursive(folderTree, item.id);

            if(prevFolder) {
              if(prevFolder.type === "templates") {
                var templateFolders = links.filter(link => {
                  return link.parent_id === prevFolder.id
                })

                if(prevFolder.links.length === 0) {
                  prevFolder.links = templateFolders
                }
              }

              // var immediateLinks = links.filter(link => {
              //   return link.parent_id === prevFolder.id
              // })

              var immediateLinks = links

              var allChildrenLinks: any = [];

              var filteredLinks = immediateLinks.filter(link => {
                return link.parent_id === prevFolder.id
              })

              prevFolder.children = filteredLinks.sort((a,b) => {
                if((a.name && a.name.toLowerCase()) < (b.name && b.name.toLowerCase()))
                    return -1;
                if((b.name && b.name.toLowerCase()) < (a.name && a.name.toLowerCase()))
                    return 1;
        
                return 0;
              });

              for (let i = 0; i < immediateLinks.length; i++) {
                let immediateLink = searchRecursive(links, immediateLinks[i].id);

                if(immediateLink) {
                  let immediateIsExpanded = expandFolders && expandFolders.some(expandFolder => expandFolder === immediateLink.id || false)
                  immediateLink.isExpanded = immediateIsExpanded
                  immediateLink.state = {expanded: immediateIsExpanded}
                }

                var childrenLinks = links.filter(link => {
                  return link.parentId === (immediateLink && immediateLink.id)
                })

                childrenLinks.links = childrenLinks.sort((a,b) => {
                  if((a.name && a.name.toLowerCase()) < (b.name && b.name.toLowerCase()))
                      return -1;
                  if((b.name && b.name.toLowerCase()) < (a.name && a.name.toLowerCase()))
                      return 1;
          
                  return 0;
                });

                allChildrenLinks.push(childrenLinks)

                immediateLink && (immediateLink.children = childrenLinks)
              }
  
              if (this.props.searchType === "fullSearch"){
                this._isMounted && this.setState({
                  searchFilters: folderTree,
                })
              } else {
                // Push links to App.tsx foldersList
                var linksToPush = links
                if(linksToPush[0] !== undefined) {
                  window.postMessage(
                    {
                        msg: "addItemsToFoldersList",
                        links: linksToPush
                    }
                    , '*');
                }

                this._isMounted && this.setState({
                  folderTree: folderTree,
                }, () => {
                  var focusEl = document.getElementsByClassName("scrolledFolder")[0] && document.getElementsByClassName("scrolledFolder")[0].parentElement
                  if(focusEl) focusEl = focusEl.parentElement
                  if(focusEl) focusEl = focusEl.parentElement
  
                  if(focusEl) {
                    focusEl.scrollIntoView({
                      behavior: 'smooth',
                      block: 'nearest',
                      inline: 'nearest'
                    });
                  }
                })
              }
            }
        }).catch(error => {
          console.log(error)
        })
      }
    }, 100);
  }

  private _updateFolderTree(newFolder) {
    var folderTree: any = this.state.nodesTree;

    function searchRecursive(data, id) {
      let found = data.find(d => d.key === id && (d.type === "repo" || d.type === "folder" || d.type === "templates"));
      if (!found) {
        let i = 0;
        while(!found && i < data.length) {
          if (data[i].children && data[i].children.length) {
            found = searchRecursive(data[i].children, id);
          }
          i++;
        }
      }
      return found;
    }

    var prevFolder: any = searchRecursive(folderTree, newFolder.id);

    if(newFolder.remove) {
      let prevFolderParent = searchRecursive(folderTree, newFolder.parent_id);
      if(prevFolderParent && prevFolderParent.children) {
        prevFolderParent.children = prevFolderParent.children.filter(link => {
          return link.id !== newFolder.id
        })
      }
      this._isMounted && this.setState({
        nodesTree: folderTree
      })
    } else if(prevFolder) {
      newFolder.accessGranted = prevFolder.accessGranted;
      newFolder.key = newFolder.id
      newFolder.isExpanded = prevFolder.isExpanded
      newFolder.parentId = newFolder.parent_id
      newFolder.type = "folder"
      newFolder.children = prevFolder.children
      newFolder.hidden = newFolder.trash;

      let prevFolderParent = searchRecursive(folderTree, prevFolder.parent_id);
      if(prevFolderParent && prevFolderParent.links) {
        prevFolderParent.children = prevFolderParent.children.filter(link => {
          return link.id !== prevFolder.id
        })
      }

      let parentFolder = searchRecursive(folderTree, newFolder.parent_id);
      if(parentFolder) {
        parentFolder.state = {expanded: true}
        this._handleExpandLinkClick(undefined, parentFolder)
        // parentFolder.isExpanded = true
        // if(parentFolder.children) {
        //   parentFolder.children = parentFolder.children.filter(link => {
        //     return link.id !== prevFolder.id
        //   })

        //   parentFolder.children.push(newFolder)

        //   parentFolder.children = parentFolder.children.sort((a,b) => {
        //     if((a.name && a.name.toLowerCase()) < (b.name && b.name.toLowerCase()))
        //         return -1;
        //     if((b.name && b.name.toLowerCase()) < (a.name && a.name.toLowerCase()))
        //         return 1;
    
        //     return 0;
        //   });

        //   // Push trash folder to last position
        //   let trashFolder = parentFolder.children.filter(link => {
        //     return link.type === "trash"
        //   })[0]
        //   if(trashFolder) parentFolder.children.push(parentFolder.children.splice(parentFolder.children.indexOf(trashFolder), 1)[0]);
          
        // } else { parentFolder.children = newFolder }
      }

      this._isMounted && this.setState({
        nodesTree: folderTree
      })
    } else {
      console.log(newFolder)
      newFolder.key = newFolder.id
      newFolder.isExpanded = false
      newFolder.parentId = newFolder.parent_id
      newFolder.type = "folder"
      newFolder.hasChildren = newFolder.hasSubFolders

      var accessGranted: boolean = false;
      var userGroups = this.props.userData.userGroups.map(userGroup => {
        return userGroup.id
      })

      var isUser = newFolder.users.filter(user => {
        return user.id === this.props.userData.user.id
      })

      var isAdmin = newFolder.admins.filter(admin => {
        return admin === this.props.userData.user.id
      })

      var groupAccess = newFolder.groups.filter(group => {
        return userGroups.includes(group.id)
      })

      var isExternal = newFolder.externals.filter(externalUser => {
        return externalUser === this.props.userData.user.id
      })

      if(isAdmin.length > 0
        || isUser.length > 0
        || groupAccess.length > 0
        || isExternal.length > 0)
        accessGranted = true

      newFolder.accessGranted = accessGranted

      let parentFolder = searchRecursive(folderTree, newFolder.parent_id);
      if(parentFolder) {
        console.log("Parent folder", parentFolder)
        parentFolder.state = {expanded: true}
        this._handleExpandLinkClick(undefined, parentFolder)
        // parentFolder.isExpanded = true
        
        // if(parentFolder.children && typeof parentFolder.children === 'object' ) {
        //   parentFolder.children.push(newFolder)

        //   if(parentFolder.children.children) {
        //     delete parentFolder.children.children;
        //   }

        //   // Search for bulk folders
        //   var newLinks = this.props.foldersList.filter(folder => {
        //     return folder.parent_id === newFolder.parent_id
        //   })
        //   var parentFoldersLinksIds = parentFolder.children.map(link => {return link.id})
        //   newLinks = newLinks.filter(link => {
        //     return !parentFoldersLinksIds.includes(link.id)
        //   })

        //   for (let i = 0; i < newLinks.length; i++) {
        //     this._updateFolderTree(newLinks[i])
        //   }

        //   parentFolder.children = parentFolder.children.sort((a,b) => {
        //     if((a.name && a.name.toLowerCase()) < (b.name && b.name.toLowerCase()))
        //         return -1;
        //     if((b.name && b.name.toLowerCase()) < (a.name && a.name.toLowerCase()))
        //         return 1;
    
        //     return 0;
        //   });

        //   // Push trash folder to last position
        //   let trashFolder = parentFolder.children.filter(link => {
        //     return link.type === "trash"
        //   })[0]
        //   if(trashFolder) parentFolder.children.push(parentFolder.children.splice(parentFolder.children.indexOf(trashFolder), 1)[0]);

        // } else { parentFolder.children = newFolder }
      }

      this._isMounted && this.setState({
        nodesTree: folderTree
      })
    }
  }

  private _getRecentFilesTree() {
    const {repoData} = this.state;

        var recentFilesTree: any = [];

    var getRecentFiles = async () => {

      for (let i = 0; i < repoData.length; i++) {
        let repo = repoData[i];

        recentFilesTree.children.push ({
            key: repo.id,
            id: repo.id,
            name: repo.name,
            isExpanded: false,
            links: [],
            type: "repo"
          })
      };
    }
    
    getRecentFiles().then(() => {
      recentFilesTree.children.sort((a,b) => {
        if((a.name && a.name.toLowerCase()) < (b.name && b.name.toLowerCase()))
          return -1;
        if((b.name && b.name.toLowerCase()) < (a.name && a.name.toLowerCase()))
          return 1;
        
        return 0;
      });

      // recentFilesTree.children.push ({
      //   key: "openFiles",
      //   name: i18n.t('app:openFiles'),
      //   isExpanded: false,
      //   links: [],
      //   type: "openFiles"
      // })

      this._isMounted && this.setState({ recentFilesTree: recentFilesTree })
    });
  }

  private _getBookmarksFolders(id) {
    userService.getBookmarks([id]).then(response => {
     var bookmarkFolders = response.data.folders.map(folder => {
      return({
        key: folder.id,
        id: folder.id,
        name: folder.name,
        parentId: folder.parent_id,
        repo: folder.repo,
        bookmark: true,
        accessGranted: true,
        isExpanded: false,
        type: "bookmarkFolder",
        hidden: folder.trash
      })
     })

     bookmarkFolders = bookmarkFolders.sort((a,b) => {
      if((a.name && a.name.toLowerCase()) < (b.name && b.name.toLowerCase()))
        return -1;
      if((b.name && b.name.toLowerCase()) < (a.name && a.name.toLowerCase()))
        return 1;

      return 0;
    });
    var bookmarks = this.state.nodesTree.filter(item => {
      return item.key === "bookmarks-" + id
    })[0]
    if(bookmarks) {
      bookmarks.expanded = true
      bookmarks.children = bookmarkFolders
    }
      this._isMounted && this.setState({nodesTree: this.state.nodesTree})
   }).catch(error => {
     console.log(error)
   })
  }

  private _getOfflineFilesTree() {
    var offlineMenu = [{
      links:[
        {
          key: "pendingUploads",
          name: "Sync",
          isExpanded: false,
          links: [],
          type: "pendingUploads",
        },
        {
          key: "offlineFiles",
          name: i18n.t('app:offlineFiles'),
          isExpanded: false,
          links: [],
          type: "offlineFiles"
        },
        {
          key: "cacheFiles",
          name: i18n.t('app:cacheFiles'),
          isExpanded: false,
          links: [],
          type: "cacheFiles",
          //hidden: true
        }
      ]
    }]

    this._isMounted && this.setState({
      offlineFilesTree: offlineMenu
    })
  }

  private _getSearchFilters() {
    const { repoData } = this.state;
    var folderTree: any = [];

    var searchQuery = this.props.searchQuery
    var params = new URLSearchParams(searchQuery);
    var searchFolder = params.get("searchFolder") === "true";

      let repo = repoData[0];
      this._getFolderTree();
      folderTree.push({
        links: [
                {
                  key: "folders-" + repo.id,
                  id: repo.id,
                  name: i18n.t('app:folders'),
                  isExpanded: true,
                  type: "filterTags",
                  links: [
                    {
                      key: repo.id,
                      id: repo.id,
                      name: repo.name,
                      isExpanded: true,
                      links: this.filteredNested,
                      type: "repoFilter"
                    }
                  ]
                },
                {
                  key: "tags-" + repo.id,
                  id: repo.id,
                  name: i18n.t('app:tags'),
                  isExpanded: true,
                  type: "filterTags",
                  disabled: searchFolder
                },
                {
                  key: "fileType-" + repo.id,
                  id: repo.id,
                  name: i18n.t('app:fileType'),
                  isExpanded: true,
                  disabled: searchFolder,
                  links: [
                    {
                      key: "pdf-" + repo.id,
                      name: i18n.t('app:pdf'),
                      search: "pdf",
                      repo: repo.id,
                      isExpanded: false,
                      type: "filterPdf",
                      disabled: searchFolder
                    },
                    {
                      key: "word-" + repo.id,
                      name: i18n.t('app:word'),
                      search: "doc,docx",
                      repo: repo.id,
                      isExpanded: false,
                      type: "filterWord",
                      disabled: searchFolder
                    },
                    {
                      key: "excel-" + repo.id,
                      name: i18n.t('app:excel'),
                      search: "xls,xlsx",
                      repo: repo.id,
                      isExpanded: false,
                      type: "filterExcel",
                      disabled: searchFolder
                    },
                    {
                      key: "powerpoint-" + repo.id,
                      name: i18n.t('app:powerpoint'),
                      search: "ppt,pptx",
                      repo: repo.id,
                      isExpanded: false,
                      type: "filterPowerpoint",
                      disabled: searchFolder
                    },
                    {
                      key: "email-" + repo.id,
                      name: i18n.t('app:email'),
                      search: "msg,email",
                      repo: repo.id,
                      isExpanded: false,
                      type: "filterEmail",
                      disabled: searchFolder
                    }
                  ],
                  type: "filterFileType"
                }
        ]
      })


    folderTree.sort((a,b) => {
      if(a.links[0].name.toLowerCase() < b.links[0].name.toLowerCase())
          return -1;
      if(b.links[0].name.toLowerCase() < a.links[0].name.toLowerCase())
          return 1;

      return 0;
    });


    this._isMounted && this.setState({
      searchFilters: folderTree
    }, () => {
      this._getTags();

      // Check if file type filter is selected in the repo
      var repos = this.state.searchFilters
      for (let i = 0; i < repos.length; i++) {
        var repo = repos[i]
        var fileTypeFilters = repo.links[2];
        for (let i = 0; i < fileTypeFilters.length; i++) {
          this.checkSelFilter(fileTypeFilters[i])
        }
      }
    });
  }

  private _getSelNav() {
    if (window.location.href.indexOf("/files") !== -1) {
      return  "files";
    } else if (window.location.href.indexOf("/recent-files") !== -1) {
      return "files";
    } else if (window.location.href.indexOf("/open-files") !== -1) {
      return "files";
    } else if (window.location.href.indexOf("/bookmarks") !== -1) {
      return "files";
    } else if (window.location.href.indexOf("/search") !== -1) {
      return "search";
    } else if (window.location.href.indexOf("/smart-folder") !== -1) {
      return "smartFolder";
    } else if (window.location.href.indexOf("/tag") !== -1) {
      return "tag";
    } else if (window.location.href.indexOf("/offline-files") !== -1) {
      return "offline";
    } else if (window.location.href.indexOf("/cache-files") !== -1) {
      return "offline";
    } else if (window.location.href.indexOf("/pending-uploads") !== -1) {
      return "offline";
    } else if (window.location.href.indexOf("/public-links") !== -1) {
      return "publicLinks";
    } else if (window.location.href.indexOf("/activity") !== -1) {
      return "activity";
    } else if (window.location.href.indexOf("/settings") !== -1) {
      return "settings";
    }
    return "";
  }

  onDragOver = (e) => {
    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onDragEnter = (event) => {
    if (event.target.className.indexOf("droptarget")!== -1) {
      var draggedItems = this._draggedItem;
      if (draggedItems && draggedItems[0]) event.target.style.background = "#C7E0F4";
    }
  }

  onDragLeave = (event) => {
    if (event.target.className.indexOf("droptarget")!== -1) {
      event.target.style.background = "transparent";
    }
  
  }
  private _draggedItem: any = null

  onDragStart = (item) => {
    console.log("DRAG START", item)
    item.fileType = "dir"
    this._draggedItem = [item]
  }


  onFileDrop = (e, dest) => {
    let event:any = e as Event;
    event.stopPropagation();
    event.preventDefault();

    if (event.target.className.indexOf("droptarget")!== -1) {
      event.target.style.background = "transparent";
    }

    
    var draggedItems = this._draggedItem;

    if (draggedItems && draggedItems[0]) this.props.fileExplorerRef._moveSelectedItems(draggedItems, dest)
  }

  private _getSmartFolders() {
    const {repoData} = this.state;

    var smartFoldersTree: any = [{
      links:[]
    }];

    var getSmartFolders = async () => {

      for (let i = 0; i < repoData.length; i++) {
        let repo = repoData[i];
        let repoId = repo.id

        await new Promise((resolve) => {
        userService.getSmartFolders(repoId)
        .then((response: any) => {
          var smartFolders: any = [];

          if (response) {

            response.data.sort(function(a, b){
              if(a.description < b.description) { return -1; }
              if(a.description > b.description) { return 1; }
              return 0;
            })

            for (let i = 0; i < response.data.length; i++) {
              var smartFolder = response.data[i];



              smartFolders.push({
                key: smartFolder.id,
                name: smartFolder.description,
                url: '',
                isExpanded :false,
                type: "smartFolder",
                status: "active",
                repo: smartFolder.repo,
                search: smartFolder.search
              })
              
            }
          }

          if(smartFolders.length === 0) {
            smartFolders.push({
              key: "empty " + repo.id,
              name: i18n.t('app:empty'),
              url: '',
              isExpanded :false,
              type: "empty",
              status: "active",
              repo: repo.id,
              search: "",
              disabled: true
            })
          }

          smartFoldersTree[0].links.push ({
              key: repo.id,
              name: repo.name,
              isExpanded: true,
              links: smartFolders,
              type: "repoFilter"
            })

            resolve(true);
         

          }).catch(error => {
            console.log(error)
            resolve(true);
          })
        });
      }
    }

    if(repoData) {
      getSmartFolders().then(() => {
        smartFoldersTree[0].links.sort((a,b) => {
          if((a.name && a.name.toLowerCase()) < (b.name && b.name.toLowerCase()))
            return -1;
          if((b.name && b.name.toLowerCase()) < (a.name && a.name.toLowerCase()))
            return 1;

          return 0;
        });

        this._isMounted && this.setState({ smartFoldersTree: smartFoldersTree })
      });
    }
  }

  private _deleteSmartFolder(e, smartFolder) {
    e.stopPropagation();
    const {smartFoldersTree} = this.state;

    for (let i = 0; i < smartFoldersTree[0].links[0].links.length; i++) {
      var item = smartFoldersTree[0].links[0].links[i];

      if (item.key === smartFolder.key) {
        item.status = "deleting"
      }
    }

    this._isMounted && this.setState({smartFoldersTree: this.state.smartFoldersTree})

    smartFolder.status = "deleting";
    
    userService.deleteSmartFolder(smartFolder.key).then(response => {
      this._getSmartFolders();
      if(this.checkSelSmartFolder(smartFolder)) history.push("/smart-folder")
    })
  }

  private _getTags() {
    const {repoData} = this.state;

    var tagsTree: any = [{
        links:[]
      }];

    var getTags = async () => {
      var searchQuery = this.props.searchQuery
      var params = new URLSearchParams(searchQuery);
      var searchFolder = params.get("searchFolder") === "true";

        for (let i = 0; i < repoData.length; i++) {
          let repo = repoData[i];
          let repoId = repo.id

          await new Promise((resolve) => {
            var tags: any = [];

              

              userService.getAllTags(repoId).then((response)=>{
                let tagCategories = response.data
                tagCategories.sort((a,b) => {
                  if(a.tagCategory.name.toLowerCase() < b.tagCategory.name.toLowerCase())
                      return -1;
                  if(b.tagCategory.name.toLowerCase() < a.tagCategory.name.toLowerCase())
                      return 1;
                  return 0;
                });
                var tagsList:any = []
                for(let i=0; i < tagCategories.length; i++) {
                  let category = tagCategories[i].tagCategory;
                  for(let j=0; j<tagCategories[i].tag.length; j++) {
                    tagCategories[i].tag[j].category = tagCategories[i].tagCategory.name
                    tagCategories[i].tag[j].type = "tag"
                    tagCategories[i].tag[j].description = tagCategories[i].tagCategory.description
                    tagCategories[i].tag[j].key = tagCategories[i].tag[j].id
                    tagsList.push(tagCategories[i].tag[j])
                  }
                  if (this.props.headerSection !== "tags") {
                    tags.push({
                      key: 'cat-' + category.id,
                      id: category.id,
                      name: category.name,
                      isExpanded :true,
                      type: "tagCategory",
                      status: "active",
                      repo: repoId,
                      disabled: searchFolder,
                      links: tagCategories[i].tag
                    })
                  }
                  else {
                    tags.push({
                      key: 'cat-' + category.id,
                      id: category.id,
                      name: category.name,
                      isExpanded :true,
                      type: "tagCategory",
                      status: "active",
                      repo: repoId,
                      disabled: searchFolder,
                    })
                  }
                }
                this.setState({tagsList: tagsList})
                this.props.callbackFunction({tagsList: tagsList})

                var searchFilters = this.state.searchFilters;
                if(searchFilters.length > 0) {
                  var selRepo: any = searchFilters.filter(searchFilter => {
                    return searchFilter.links[0].id === repo.id
                  })

                  selRepo[0].links[1].links = tags
                }

                tagsTree[0].links.push ({
                    key: repo.id,
                    name: repo.name,
                    isExpanded: true,
                    links: tags,
                    type: "repoFilter"
                  })
                  let fileTags = [
                    {
                      key: "pdf-" + repo.id,
                      name: i18n.t('app:pdf'),
                      search: "pdf",
                      repo: repo.id,
                      isExpanded: false,
                      type: "filterPdf",
                      disabled: searchFolder
                    },
                    {
                      key: "word-" + repo.id,
                      name: i18n.t('app:word'),
                      search: "doc,docx",
                      repo: repo.id,
                      isExpanded: false,
                      type: "filterWord",
                      disabled: searchFolder
                    },
                    {
                      key: "excel-" + repo.id,
                      name: i18n.t('app:excel'),
                      search: "xls,xlsx",
                      repo: repo.id,
                      isExpanded: false,
                      type: "filterExcel",
                      disabled: searchFolder
                    },
                    {
                      key: "powerpoint-" + repo.id,
                      name: i18n.t('app:powerpoint'),
                      search: "ppt,pptx",
                      repo: repo.id,
                      isExpanded: false,
                      type: "filterPowerpoint",
                      disabled: searchFolder
                    },
                    {
                      key: "email-" + repo.id,
                      name: i18n.t('app:email'),
                      search: "msg",
                      repo: repo.id,
                      isExpanded: false,
                      type: "filterEmail",
                      disabled: searchFolder
                    }
                  ]

                  var typesList:any = []
                  for(let i=0; i< fileTags.length; i++) {
                    let item = fileTags[i];
                    typesList.push({key: item.name, name:item.name, search: item.search, type: item.type})
                  }
                  this.filterTags()

                resolve(true);
              })
        });
      }

    }

    getTags().then(() => {
      tagsTree[0].links.sort((a,b) => {
        if((a.name && a.name.toLowerCase()) < (b.name && b.name.toLowerCase()))
            return -1;
        if((b.name && b.name.toLowerCase()) < (a.name && a.name.toLowerCase()))
            return 1;

        return 0;
      });

      var allTags: any = {
        key: "allTags",
        name: "All tags",
        type: "filterAllTags",
      };

      var standardTags: any = {
        key: this.state.repoData[0].id + '-tagCategory',
        name: "Standard tags",
        type: "filterStandardTags"
      };

      var categories: any = tagsTree[0].links[0].links.filter(link => link.id !== this.state.repoData[0].id + '-tagCategory');

      var filters: any = [{
        links: [allTags, standardTags].concat(categories),
        name: "",
        isExpanded: true
      }];
      

      this._isMounted && this.setState({ tags: filters })
    });
  }

  private _editTag = (e) => {
    e.stopPropagation();
    e.preventDefault();

    this._isMounted && this.setState({savingTag: true});
    
    /*userService.editTag(selTag, newTag).then(response => {
      this._isMounted && this.setState({savingTag: false});
      this._getTags();
      this._closeDialog();
    })*/
  }

  private handleTagChange = (ev: any, newValue?: string) => {
    this._isMounted && this.setState({ newTag: newValue || ''});
  }

  private _closeDialog = (): void => {
    this._isMounted && this.setState({ editTagDialog: false });
  };

  private _deleteTag(e, tag) {
    e.stopPropagation();
    const {tags} = this.state;

    for (let i = 0; i < tags[0].links[0].links.length; i++) {
      var item = tags[0].links[0].links[i];

      if (item.key === tag.key) {
        item.status = "deleting"
      }
    }

    this._isMounted && this.setState({tags: this.state.tags})

    tag.status = "deleting";

    userService.deleteTagGlobal(tag.key).then(response => {
      this._getTags();
    })
  }

  private _goToFiles() {
    const {repoData} = this.state;

    this.toggleNav("files")
    if (window.location.href.indexOf("/files") === -1
      && window.location.href.indexOf("/recent-files") === -1
      && window.location.href.indexOf("/open-files") === -1
      && window.location.href.indexOf("/bookmarks") === -1) {
      repoData && history.push('/files/' + repoData[0].id)
    }
  }

  private _goToFilesMobile() {
    const {repoData} = this.state;
    this.toggleNav("files")
    repoData && history.push('/files/' + repoData[0].id)
  }

  private _goToBookmarks() {
    this.toggleNav("bookmarks")
    if (window.location.href.indexOf("/bookmarks") === -1) {
      history.push("/bookmarks");
    }
  }

  private _goToRecentFiles() {
    const {repoData} = this.state;

    this.toggleNav("recentFiles")
    if (window.location.href.indexOf("/recent-files") === -1
      && window.location.href.indexOf("/open-files") === -1) {
      history.push('/recent-files/' + repoData[0].id)
    }
  }

  private _goToSearch() {
    this.toggleNav("search")
    if (window.location.href.indexOf("/search") === -1) {
      history.push('/search')
    }
  }

  private _goToSmartFolders() {
    this.toggleNav("smartFolder")
    if (window.location.href.indexOf("/smart-folder") === -1) {
      history.push('/smart-folder')
    }
  }

  private _goToTags() {
    this.toggleNav("tag")
    if (window.location.href.indexOf("/tag") === -1) {
      history.push('/tag')
    }
  }

  private _goToOffline() {
    this.toggleNav("offline")
    if (window.location.href.indexOf("/offline-files") === -1
      && window.location.href.indexOf("/cache-files") === -1
      && window.location.href.indexOf("/pending-uploads") === -1) {
      history.push('/offline-files')
    }
  }

  private _goToPublicLinks() {
    this.toggleNav("publicLinks")

    if (window.location.href.indexOf("/public-links") === -1) {
      history.push('/public-links')
    }
  }

  private _goToActivity() {
    this.toggleNav("activity")

    if (window.location.href.indexOf("/activity") === -1) {
      history.push('/activity')
    }
  }

  private _goToSettingsPage() {
    this.toggleNav("settings")

    if (window.location.href.indexOf("/settings") === -1) {
      history.push('/settings')
    }
  }
  
  private _goToProjects() {
    const {repoData} = this.state;

    this.toggleNav("projects")
    if (window.location.href.indexOf("/projects") === -1
      && window.location.href.indexOf("/projects/clients") === -1
      && window.location.href.indexOf("/projects/milestones") === -1
      && window.location.href.indexOf("/projects/board") === -1 ) {
      repoData && history.push('/projects/list')
    }
  }

  private _goToTimeTracking() {
    const {repoData} = this.state;

    this.toggleNav("timeTracking")
    if (window.location.href.indexOf("/time-tracker") === -1
    && window.location.href.indexOf("/time-sheet") === -1) {
      repoData && history.push('/time-sheet')
    }
  }

  private _goToHome() {
    const {repoData} = this.state;

    this.toggleNav("home")
    if (window.location.href.indexOf("/home") === -1) {
      repoData && history.push('/home')
    }
  }

  private _goToTasks() {
    const {repoData} = this.state;

    this.toggleNav("tasks")
    if (window.location.href.indexOf("/tasks") === -1) {
      repoData && history.push('/tasks')
    }
  }

  private _getActivitySelFilters() {
    var searchQuery = this.props.searchQuery
    const params = new URLSearchParams(searchQuery);

    var operationType = params.get("operation_type");
    var modelType = params.get("model_type");
    if(operationType && modelType) {
      var selAction = modelType + "||" + operationType
      this._isMounted && this.setState({ selAction: selAction })
    } else {
      this._isMounted && this.setState({ selAction: "allActions" })
    }

    var startDate = params.get("start_date");
    if(startDate) {
      var date: Date = new Date(parseInt(startDate))
      this._isMounted && this.setState({ initialDate: date })
    }

    var endDate = params.get("end_date");
    if(endDate) {
      var finalDate: Date = new Date(parseInt(endDate))
      this._isMounted && this.setState({ finalDate: finalDate })
    }
  }

  private _filterAction = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number) => {
    if(option) {
      var queryString = "";
      var key:any = option.key
      var query = key.split("||")

      var searchQuery = this.props.searchQuery

      const params = new URLSearchParams(searchQuery);

      var modelType = params.get("model_type");
      if(modelType) {
        params.delete("model_type")
        searchQuery = params.toString();
      }

      var operationType = params.get("operation_type");
      if(operationType) {
        params.delete("operation_type")
        searchQuery = params.toString();
      }

      if(query.length === 2) {
        if(searchQuery === "?" || searchQuery === "") {
          queryString = searchQuery + "model_type=" + query[0] + "&operation_type=" + query[1];
        } else {
          queryString = searchQuery + "&model_type=" + query[0] + "&operation_type=" + query[1];
        }
      } else if (key !== "allActions") {
        queryString = searchQuery + key
      }

      history.push({
        pathname: '/activity',
        search: queryString
      })
    }
  }

  private _oninitialDateSelect = (date: Date | null | undefined) => {
    if(date) {
      this._isMounted && this.setState({ initialDate: date},
        () => {
          var queryString = "";
          var searchQuery = this.props.searchQuery
          const params = new URLSearchParams(searchQuery);

          var startDate = params.get("start_date");
          if(startDate) {
            params.delete("start_date")
            searchQuery = params.toString();
          }

          if(searchQuery === "?") {
            queryString = searchQuery + "start_date=" + date.getTime();
          } else {
            queryString = searchQuery + "&start_date=" + date.getTime();
          }

          history.push({
            pathname: '/activity',
            search: queryString
          })
        })
    }
  }

  private _onfinalDateSelect = (date: Date | null | undefined) => {
    if(date) {
      this._isMounted && this.setState({ finalDate: date},
        () => {
          var queryString = "";
          var searchQuery = this.props.searchQuery
          const params = new URLSearchParams(searchQuery);

          var endDate = params.get("end_date");
          if(endDate) {
            params.delete("end_date")
            searchQuery = params.toString();
          }

          var endOfTheDay = new Date(date);
          endOfTheDay.setHours(23,59,59,999);

          if(searchQuery === "?") {
            queryString = searchQuery + "end_date=" + endOfTheDay.getTime();
          } else {
            queryString = searchQuery + "&end_date=" + endOfTheDay.getTime();
          }

          history.push({
            pathname: '/activity',
            search: queryString
          })
        })
    }
  }

  private _applyUpdate() {
    if(this.props.appPlatform === "electron") {
      const { ipcRenderer } = window.require("electron");
      if(this.props.appUpdateAvailable) ipcRenderer.send('restart_app');
      else ipcRenderer.send('quitAndRelaunchApp');
    } else {
        window.location.reload();
    }
  }

  private _getAppInfo() {
    //var appInfo = userService.getAppInfo()

    var showDialog = true;

    const Css = {
      jsonTr: {
        height: '25px'
      },
      jsonTd: {
        padding: '5px',
        borderSpacing: '0',
        borderRadius: '0'
      },
      rowSpacer: {
        height: '2px'
      },
      rootElement: {
        padding: '5px',
        borderSpacing: '0',
        backgroundColor: '#FFF',
        fontWeight: 'bold',
        fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
        borderRadius: '0',
      },
      subElement: {
        padding: '5px',
        borderSpacing: '0',
        backgroundColor: '#FFF',
        fontWeight: 'normal',
        fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
        borderRadius: '0'
      },
      dataCell: {
        borderSpacing: '0',
        backgroundColor: '#FFF',
        fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
        borderRadius: '0'
      }
    }

    var div = document.createElement('div');
    var renderDialog = () => {
      ReactDOM.render(
        <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: 'Synergy Drive'
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "app-info-dialog"
            }}
            onDismiss={() => {showDialog = false; renderDialog()}}
          >
            <div className="w-100 appInfo">
              <JsonTable json={/*appInfo*/{}} css={ Css } />
            </div>
          </Dialog>
        , div
      )
    }

    renderDialog();
  }

  private _checkforUpdates() {
    console.log("Check for updates")

    window.postMessage(
      {
        msg: "updateServiceWorker"
      }, '*');

    if(this.props.appPlatform === "electron") {
      const { ipcRenderer } = window.require("electron")
      ipcRenderer.send('checkForUpdates');
    }
  }

  private _goToSettings() {
    var url = "https://account.synergy.page"
    if(this.props.appPlatform === "electron") {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.send("open-browser-url", url)
    } else {
        window.open(url, "_blank");
    }
  }

  private _expandFolder(id, levels, expandFolders, focusEl?: any) {
    function searchRecursive(data, id) {
      let found = data.find(d => d.key === id && (d.type === "repo" || d.type === "folder" || d.type === "template"));
      if (!found) {
        let i = 0;
        while(!found && i < data.length) {
          if (data[i].children) {
            found = searchRecursive(data[i].children, id);
          }
          i++;
        }
      }
      return found;
    }

    var folder: any = searchRecursive(this.state.nodesTree, id);
    
    if(folder) {
      folder.isExpanded = true
      folder.state = {expanded: true}
      this.setState({folderTreeFocusId: focusEl})
      this._addChildrenTree(folder, levels, expandFolders)
      var parentFolder: any = searchRecursive(this.state.nodesTree, folder.parent_id || folder.parentId);
      if(parentFolder && parentFolder.id) {
        parentFolder.isExpanded = true
        parentFolder.state = {expanded: true}
      }
    }
  }

  private _expandFolderWhileBrowsing(folderId, subFolders) {
    function searchRecursive(data, id) {
      
      let found = data.find(d => d.key === id && (d.type === "repo" || d.type === "folder" || d.type === "template"));
      if (!found) {
        let i = 0;
        while(!found && i < data.length) {
          if (data[i].children) {
            found = searchRecursive(data[i].children, id);
          }
          i++;
        }
      }

      return found;
    }

    var folder: any = searchRecursive(this.state.nodesTree, folderId);

    if(folder) {

      var links = subFolders.map(subFolder => {
        delete subFolder.icon
        subFolder.state = {expanded: false}
        subFolder.hasChildren = subFolder.hasSubFolders
        return subFolder
      })

      if(folder.type === "templates") {
        var templateFolders = links.filter(link => {
          return link.parent_id === folder.id
        })

        if(folder.children.length === 0) {
          folder.children = templateFolders
        }
      }

      folder.isExpanded = true
      folder.state = {expanded: true}
      // if(folder.id.startsWith("Repo-")) folder.links = subFolders.concat(folder.links)
      // else folder.links = subFolders

      //folder.children = links

      if(folder.id.indexOf("-trash") === -1 && folder.id.indexOf("-templates") === -1) folder.children = links
      
      if (folder.id.startsWith("Repo-")
        && folder.id.indexOf("-trash") === -1
        && folder.id.indexOf("-templates") === -1) {

          let templatesId = folder.id + "-templates"
          if(!folder.children.includes(templatesId)) {
            let templates = {
              key: templatesId,
              id: templatesId,
              name: 'Templates',
              isExpanded: false,
              links: [],
              children: [],
              type: "templates",
              url: '',
              hasSubFolders: true,
              state: {
              expanded: false
              }
            }
            folder.children.push(templates)
          }

          let trashId = folder.id + "-trash"
          if(!folder.children.includes(trashId)) {
            let trash = {
              key: trashId,
              id: trashId,
              name: i18n.t('app:trash'),
              isExpanded: false,
              links: [],
              type: "trash"
            }
            folder.children.push(trash)
          }
      }

      // Push links to App.tsx foldersList
      // var linksToPush = links
      // if(linksToPush[0] !== undefined) {
      //   window.postMessage(
      //     {
      //         msg: "addItemsToFoldersList",
      //         links: linksToPush
      //     }
      //     , '*');
      // }

      this._isMounted && this.setState({
        nodesTree: this.state.nodesTree,
      }, () => {
        var focusEl = document.getElementsByClassName("scrolledFolder")[0] && document.getElementsByClassName("scrolledFolder")[0].parentElement
        if(focusEl) focusEl = focusEl.parentElement
        if(focusEl) focusEl = focusEl.parentElement

        if(focusEl) {
          focusEl.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest'
          });
        }
      })


      var parentFolder: any = searchRecursive(this.state.folderTree, folder.parent_id || folder.parentId);
      if(parentFolder && parentFolder.id) {
        parentFolder.isExpanded = true
      }
    }
  }

  private _expandSearchFolder(folderId, subFolders) {
    function searchRecursive(data, id) {
      
      let found = data.find(d => d.key === id && (d.type === "repo" || d.type === "folder" || d.type === "template"));
      if (!found) {
        let i = 0;
        while(!found && i < data.length) {
          if (data[i].links && data[i].links.length) {
            found = searchRecursive(data[i].links, id);
          }
          i++;
        }
      }

      return found;
    }

    var folder: any = searchRecursive(this.state.searchFilters, folderId);

    if(folder && !folder.isExpanded) {
      folder.isExpanded = true

      var links = subFolders.map(subfolder => {
        delete subfolder.icon
        subfolder.links = subfolder.hasSubFolders ? [{}] : []
        return subfolder
      })

      // if(folder.id.startsWith("Repo-")) folder.links = subFolders.concat(folder.links)
      // else folder.links = subFolders

      if(!folder.id.startsWith("Repo-")) folder.links = links
      else {
        console.log("REpo", folder)
        var linksId = links.map(link => {
          return link.id
        })
        var foldersList = folder.links && folder.links.filter(folder => {
          return !linksId.includes(folder.id)
        })
        
        if(foldersList) folder.links = foldersList.concat(folder.links)
      }

      // Push links to App.tsx foldersList
      // var linksToPush = links
      // if(linksToPush[0] !== undefined) {
      //   window.postMessage(
      //     {
      //         msg: "addItemsToFoldersList",
      //         links: linksToPush
      //     }
      //     , '*');
      // }


      this._isMounted && this.setState({
        searchFilters: this.state.searchFilters,
      }, () => {
        var focusEl = document.getElementsByClassName("scrolledFolder")[0] && document.getElementsByClassName("scrolledFolder")[0].parentElement
        if(focusEl) focusEl = focusEl.parentElement
        if(focusEl) focusEl = focusEl.parentElement

        if(focusEl) {
          focusEl.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest'
          });
        }
      })


      var parentFolder: any = searchRecursive(this.state.searchFilters, folder.parent_id || folder.parentId);
      if(parentFolder && parentFolder.id) {
        parentFolder.isExpanded = true
      }
    } else if (folder && folder.isExpanded) {
      folder.isExpanded = false
      this._isMounted && this.setState({
        searchFilters: this.state.searchFilters,
      })
    }
  }

  private _getExpandedFolders(folders) {
    const { searchQuery } = this.props;
    const params = new URLSearchParams(searchQuery);
    var repoQuery = params.get('parent');
    var folderId = this.props.folderId || repoQuery;

    if(folderId && folders) {
      var repo = this.props.userData.repository

      // Set isExpanded state for all folders at start
      folders.map(data => {
        const findItemNested = (arr, itemId, nestingKey) => (
          arr.reduce((a, item) => {
            if (a) return a;
            if (item.id === itemId) return item;
            if (item[nestingKey]) return findItemNested(item[nestingKey], itemId, nestingKey)
            return null;
          }, null)
        );
        const res = findItemNested(this.state.folderTree, data.id, "links");

        if(res) {
          return data.isExpanded = res.isExpanded
        } else {
          return data.isExpanded = false
        }
      })

      var currentFolder = folders.filter(data => {
        return data.id === folderId;
      })
      currentFolder = currentFolder[0]

      if (currentFolder && !currentFolder.trash) {
        if (!this.props.searchType) currentFolder.isExpanded = true

        if (currentFolder.parent_id) {
          var getParentData = (parentId) => {
            var parentData;

            parentData = folders.filter(data => {
              return parentId === data.id
            })

            const {searchFilters} = this.state;
            if(!parentData.length && searchFilters.length > 0) {
              parentData = parentId === repo.id ? repo : null;
            }

            if(parentData) {
              parentData.isExpanded = true;
              getParentData(parentData.parent_id)
            }
          }

          getParentData(currentFolder.parent_id)
        }
      }
    }
  }

  private _askUserToDeleteTagCategory = async (id) => {
    return await new Promise((resolve, reject) => {
      var showDialog = true;

      var deleteItems = () => {
        showDialog = false;
        renderDialog()
        resolve(true)
      }

      var cancelDelete = () => {
        showDialog = false;
        renderDialog()
        reject("Operation rejected by user");
      }

      var div = document.createElement('div');
      var renderDialog = () => {
        ReactDOM.render(
          <Dialog
              hidden={!showDialog}
              onDismiss={cancelDelete}
              dialogContentProps={{
                type: DialogType.normal,
                title: i18n.t('app:deleteCategory')
              }}
              modalProps={{
                isBlocking: false,
                styles: { main: { maxWidth: 640 } },
                dragOptions: undefined,
                className: "ask-delete-item-dialog"
              }}
            >
              <div className="w-100">
                <Text block variant={'large'}>
                    {i18n.t('app:deleteAreYouSure') + " " + i18n.t('app:thisCategory') +  "?"}
                </Text>

                <p className="tip text-secondary pt-3">
                </p> 
                
                <DialogFooter>
                  <DefaultButton onClick={cancelDelete} text={i18n.t('app:cancel')} />
                  <PrimaryButton autoFocus onClick={ deleteItems } text={i18n.t('app:delete')} />
                </DialogFooter>
              </div>
            </Dialog>
          , div
        )
      }

      renderDialog();
    })
  }


  private deleteTagCategory(id) {
    this._askUserToDeleteTagCategory(id).then((response:any) => {
      userService.deleteTagCategory(id).then(()=>{
        this._isMounted && this.setState({tagFilter: ''}); this.filterTags('')
      }).catch((error)=>{
        console.log(error)
      })
    }).catch(reject => {console.log(reject)})
  }

  private findById = (o, id) => {
      //Early return
      if( o && o.id === id ){
        return o;
      }
      var result, p; 
      for (p in o) {
          if( o.hasOwnProperty(p) && typeof o[p] === 'object' ) {
              result = this.findById(o[p], id);
              if(result){
                  return result;
              }
          }
      }
      return result;
    }
}