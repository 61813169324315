import { ComboBox, DefaultButton, Dialog, DialogFooter, DialogType, IconButton, PrimaryButton, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import i18next from 'i18next';
import * as React from 'react';
import i18n from '../i18n';

export interface Props {
  callback:any;
}

export interface States {
  hidden: boolean;
  loading: boolean;
  customFields: any[];
  chosenFields:any;
  chosenFieldsKeys: any;
}

const fieldTypes = [{key: "closeDate", text: "Close Date", type: "Date"}, {key: "city", text: "City", type: "String"}, {key: "aboutUs", text: "About us", type: "String"}, {key: "employeeNumbers", text: "Number of employees", type: "Number"}]

export class CompanyNewForm extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      hidden: false,
      loading: false,
      customFields: [{name:"", value: ""}],
      chosenFields: [],
      chosenFieldsKeys: []
    };
  }

  public componentDidMount() {
    this._isMounted = true;
  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      setTimeout(()=>this.props.callback({showNewCompanyForm: false}),200)
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private _addCustomField() {
    var { customFields } = this.state;
    customFields.push({name:"", value: ""});
    this._isMounted && this.setState({customFields: customFields})
  }

  private _changeCustomFieldValue(name,index){
    var { chosenFields } = this.state;
    chosenFields[index].value = name;
    console.log(chosenFields)
    this._isMounted && this.setState({chosenFields: chosenFields})
  }

  private _changeCustomFields(e, option: any){
    let selected = option?.selected
    if (option) {
      var keys = selected ? [...this.state.chosenFieldsKeys, option!.key as string] : this.state.chosenFieldsKeys.filter(k => k !== option!.key)
      var options = selected ? [...this.state.chosenFields, option] : this.state.chosenFields.filter(k => k.key !== option!.key)
      this._isMounted && this.setState({chosenFieldsKeys: keys, chosenFields: options})
    }
  }

  public render() {

    const { hidden, loading, customFields, chosenFields, chosenFieldsKeys } = this.state;
    
    return(
      <div>
        <Dialog
          hidden={hidden}
          dialogContentProps={{
            type: DialogType.close,
            title: i18n.t('New Company'),
            onDismiss: ()=> {this._isMounted && this.setState({hidden:true})}
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640 } },
            dragOptions: undefined,
            className: "form-dialog",
            onDismiss: ()=> {this._isMounted && this.setState({hidden:true})}
          }}
        >
          <form>
            <TextField className='mt-2' label={i18next.t('Name')}/>
            <TextField className='mt-2' label={i18next.t('Domain name')}/>
            <TextField className='mt-2' label={i18next.t('Company owner')}/>
            <TextField className='mt-2' label={i18next.t('Industry')}/>
            <div>
              <ComboBox
                selectedKey={chosenFieldsKeys}
                onChange={(e,option)=>this._changeCustomFields(e,option)}
                className='mt-2'
                label={i18n.t('Custom fields')}
                options={fieldTypes}
                multiSelect
                useComboBoxAsMenuWidth
              />
              <IconButton className='mt-3 d-none' iconProps={{iconName:'Add'}} text={i18n.t('Add')} onClick={this._addCustomField.bind(this)}/>
              { chosenFields.map((item,index)=>{
                return (
                  <div className='' key={item.key}>
                    <TextField className='mt-2' value={item.value || ""} onChange={(e,v)=>this._changeCustomFieldValue(v,index)} label={item.text}/>
                  </div>
                )
              }) }
            </div>
          </form>
          <DialogFooter>
            { loading ?
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
            : null }
            <DefaultButton onClick={()=> {this._isMounted && this.setState({hidden:true})}} text={i18n.t('app:cancel')} disabled={loading} />
            <PrimaryButton onClick={()=>{this._isMounted && this.setState({loading:true});setTimeout(()=>{this.setState({loading:false})},1000)}} text={i18n.t('app:save')} disabled={loading} />                   
          </DialogFooter>
        </Dialog>
      </div>
    )
  }

}
