import * as React from 'react';
import { Header } from '../_components/Header';
import { Sidebar } from '../_components/Sidebar';
import { history } from '../_helpers';
import "moment/min/locales";
import { ConstrainMode, DetailsListLayoutMode, IColumn, Icon, IDocumentCardStyles, mergeStyles, SelectionMode } from '@fluentui/react';
import i18n from "i18next";
import { ListExplorer } from '../_components/ListExplorer';

export interface Props {
  userData: any;
  appPlatform: string,
  isOnline: boolean,
  page: string,
  maintenance: boolean,
  socket: any,
  pusher: any,
}

export interface State { 
  userData: any;
  breadcrumbPath: any[];
  searchQuery: any;
  syncStatus: any;
  itemsFull: any;
  items: any;
  columns: any[];
  selItem:any;
}

const fakePipelines = [
      {index: 1, name: 'Qualified', color: 'rgb(196, 196, 196)', pipeline: 'Investments'},
      {index: 2, name: 'Piched', color: 'blue', pipeline: 'Investments'},
      {index: 3, name: 'Proposal', color: 'rgb(254, 192, 110)', pipeline: 'Investments'},
      {index: 4, name: 'Execution', color: 'yellow', pipeline: 'Investments'},
      {index: 5, name: 'Diligence', color: 'limegreen', pipeline: 'Investments'},
      {index: 6, name: 'Closing', color: 'green', pipeline: 'Investments'},
      {index: 1, name: 'Pre-Marketing', color: 'rgb(196, 196, 196)', pipeline: 'Divestments'},
      {index: 2, name: 'Marketing', color: 'lightblue', pipeline: 'Divestments'},
      {index: 3, name: 'First Bids', color: 'blue', pipeline: 'Divestments'},
      {index: 4, name: 'Due Diligence', color: 'yellow', pipeline: 'Divestments'},
      {index: 5, name: 'Final Bids', color: 'rgb(254, 192, 110)', pipeline: 'Divestments'},
      {index: 6, name: 'Closing', color: 'rgb(7, 200, 115)', pipeline: 'Divestments'}
]

export class PipelinesPage extends React.Component<Props, State> {
  private _isMounted: boolean;
  private sidebarRef:any = React.createRef();
  private listExplorerRef:any = React.createRef();
  private headerRef:any = React.createRef();
  private detailsPanelRef:any = React.createRef();


  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this.state = {
      userData: null,
      breadcrumbPath: [{
        text: i18n.t('app:pipelines'),
        key: 'pipelines',
        onClick: ()=>{history.push('/pipelines')}
      }],
      searchQuery: [],
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      itemsFull: fakePipelines,
      items: _copyAndSort(fakePipelines, 'index', false, 'pipeline'),
      columns: [],
      selItem: null,
    }
  }

  public componentDidMount() {
    this._isMounted = true;
    this._isMounted && this._getColumns();
    let items:any = fakePipelines;
    const params = new URLSearchParams(document.location.search);
    params.forEach((value, key)=>{
      let field:string = params.get(key) || "";
      let fields:string[] = field.toLowerCase().split(';');
      if (field !== 'noFilter') {
        if (field === 'all') {
          items = items.filter((item:any)=>{
            return (item[key] && item[key] !== '')
          })
        } else if (field === 'none') {
          items = items.filter((item:any)=>{
            return (!item[key] || item[key] === '')
          })
        } else if (fields && fields.length > 0) {
          items = items.filter((item:any)=>{
            return (item[key] && fields.includes(item[key].toLowerCase()))
          })
        }
      }
    })
    
    var newItems = _copyAndSort(fakePipelines, 'index', false, 'pipeline');
    this._isMounted && this.setState({
      items: newItems
    });
  }

  private callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  private _getColumns() {

    const columns = [
      {
        key: 'fakeColumn',
        name: 'fakeColumn',
        fieldName: 'conflict',
        minWidth: 0,
        maxWidth: 0,
        isRowHeader: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        styles: { root: { display: "none" } },
        className: "d-none"
      },
      {
        key: 'name',
        name: 'Stage',
        fieldName: 'name',
        minWidth: 220,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: this.getMilestone
      },
      {
        key: 'pipeline',
        name: 'Pipeline',
        fieldName: 'pipeline',
        minWidth: 220,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return <span title={ item.pipeline }>{ item.pipeline }</span>
        }
      }
    ]

    this._isMounted && this.setState({columns: columns})
  }

  public render() {

    const { items, columns } = this.state;

    return(
      <div>
        <Header
          userData={ this.props.userData }
          breadcrumbPath={ this.state.breadcrumbPath }
          sidebarRef={ this.sidebarRef }
          page={this.props.page}
          actions="pipelines"
          getActivity={ null }
          syncStatus={this.state.syncStatus}
        />

        <div className="content-wrap d-flex flex-row" >
          <Sidebar
            ref={(instance:any) => { this.sidebarRef = instance; }}
            userData={ this.props.userData }
            page={this.props.page}
          />

          <div className="list mr-auto flex-grow-1">
            {items && <ListExplorer
              ref={instance => { this.listExplorerRef = instance; }}
              appPlatform={ this.props.appPlatform }
              isOnline={ this.props.isOnline }
              maintenance={ this.props.maintenance }
              socket={this.props.socket}
              pusher={this.props.pusher}
              userData={this.props.userData}
              foldersList={ [] }
              items={items}
              selItem={ this.state.selItem }
              isCompactMode={true}
              columns={columns}
              selectionMode={SelectionMode.multiple}
              layoutMode={DetailsListLayoutMode.fixedColumns}
              //enableShimmer={isLoading}
              constrainMode={ConstrainMode.unconstrained}
              //onFileDrop={this.onFileDrop.bind(this)}
              //getCurrentContent={console.log('Get Content')}
              //getOfflineFiles={this._getOfflineFiles.bind(this)}
              //isLoading={isLoading}
              //blankLoading={blankLoading}
              //emptyMessage={emptyMessage}
              headerRef={ this.headerRef }
              detailsPanelRef={ this.detailsPanelRef }
              callbackFunction={ this.callbackFunction }
              page={this.props.page}
              //updateItem={ this.updateItem }
              sidebarRef={ this.sidebarRef }
              //pipelineId={this.state.pipelineId}
            />}
          </div>
        </div>
      </div>
    )
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        newCol.isSortedDescending = !currColumn.isSortedDescending;
        newCol.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    let mainColumn = 'pipeline';
    let newItems:any;
    if (column.key === mainColumn) newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    else if (column.key === 'name' || column.key === '#') newItems = _copyAndSort(items, 'index', currColumn.isSortedDescending, mainColumn);
    else newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending, mainColumn);

    this._isMounted && this.setState({
      columns: newColumns,
      items: newItems
    });
  };

  private getMilestone(item:any) {
    let nStages = 6; //Hardcoded
    let index = item.index;
    return (
      <div key={item.name+index} className='d-flex align-items-center'>
        <Icon 
          iconName="CircleShapeSolid" 
          className="tag mr-2" 
          style={{
            fontSize:12,
            textAlign:'center',
            color: 'rgb(16, 110, 190)',
            opacity: index/nStages
          }} 
        />
        <span style={{paddingBottom: '2px'}}>{item.name}</span>
      </div> 
    )
  }

}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean, secondColumnKey?: string): T[] {
  const key = columnKey as keyof any;
  const key2 = secondColumnKey as keyof any;
  if (key === 'amount') {
    if (key2 && key2 !== key) return items.slice(0).sort((a: T, b: T) => {
      if (a[key2] < b[key2]) return -1;
      if (a[key2] > b[key2]) return 1;
      else if (parseFloat(a[key]) < parseFloat(b[key])) return isSortedDescending ? 1 : -1;
      else if (parseFloat(a[key]) > parseFloat(b[key])) return isSortedDescending ? -1 : 1;
      else return 0;
    })
    else return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? parseFloat(a[key]) < parseFloat(b[key]) : parseFloat(a[key]) > parseFloat(b[key])) ? 1 : -1));
  } else {
    if (key2 && key2 !== key) return items.slice(0).sort((a: T, b: T) => {
      if (a[key2] < b[key2]) return -1;
      if (a[key2] > b[key2]) return 1;
      else if (a[key] < b[key]) return isSortedDescending ? 1 : -1;
      else if (a[key] > b[key]) return isSortedDescending ? -1 : 1;
      else return 0;
    })
    else return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
  }
}