import { ActionButton, Checkbox, ComboBox, DatePicker, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, DropdownMenuItemType, IconButton, optionProperties, PrimaryButton, ResponsiveMode, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import * as React from 'react';
import i18n from '../i18n';

export interface Props {
  callback:any;
}

export interface States {
  hidden: boolean,
  loading: boolean,
  name: string;
  description: string;
  fieldGroup: any,
  fieldType: any,
}

const fieldGroups = [
  {key: "company", text: i18n.t('Company')},
  {key: "contact", text: i18n.t('Contact')},
  {key: "deal", text: i18n.t('Deal')},
  {key: "project", text: i18n.t('Project')}
]

const fieldTypes = [
  {key: 'textInput', text: i18n.t('Text input'), itemType: DropdownMenuItemType.Header},
  {key: "singleLine", text: i18n.t('Single-line text')},
  {key: "multiLine", text: i18n.t('Multi-line text')},
  {key: "number", text: i18n.t('Number')},
  {key: 'options', text: i18n.t('Options'), itemType: DropdownMenuItemType.Header},
  {key: "checkbox", text: i18n.t('Checkbox')},
  {key: "dropdownSelection", text: i18n.t('Dropdown selection')},
  {key: "multiSelection", text: i18n.t('Multiple selection')},
  {key: "datePicker", text: i18n.t('Date picker')},
  {key: 'other', text: i18n.t('Other'), itemType: DropdownMenuItemType.Header},
  {key: "user", text: i18n.t('Contact')},
  {key: "file", text: i18n.t('File')},
]

export class FormEditProject extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      hidden: false,
      loading: false,
      name: '',
      description: '',
      fieldGroup: 'company',
      fieldType: 'singleLine',
    };
  }

  public componentDidMount() {
    this._isMounted = true;
  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      setTimeout(()=>this.props.callback({showEditProjectForm: false}),200)
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  public render() {

    const { hidden, loading, name, description, fieldGroup, fieldType } = this.state;
    
    return(
      <div>
        <Dialog
          hidden={hidden}
          dialogContentProps={{
            type: DialogType.close,
            title: i18n.t('Edit project'),
            onDismiss: ()=> {this._isMounted && this.setState({hidden:true})}
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640, height: '100%' } },
            dragOptions: undefined,
            className: "form-dialog",
            onDismiss: ()=> {this._isMounted && this.setState({hidden:true})}
          }}
        >
          <form>
            <Dropdown 
              className='mt-2'
              label={i18n.t('Group')}
              options={fieldGroups}
              selectedKey={fieldGroup}
              onChange={(e,option:any)=>{this._isMounted && this.setState({fieldGroup: option.key || 'company'})}}
            />
            <TextField
              className='mt-2'
              label={i18n.t('Name')}
              value={name}
              onChange={(e,value:any)=>{this._isMounted && this.setState({name: value})}}
            />
            <TextField
              className='mt-2'
              label={i18n.t('Description')}
              value={description}
              onChange={(e,value:any)=>{this._isMounted && this.setState({description: value})}}
              multiline
            />
            <Dropdown 
              className='mt-2'
              label={i18n.t('Field type')}
              options={fieldTypes}
              selectedKey={fieldType}
              onChange={(e,option:any)=>{this._isMounted && this.setState({fieldType: option.key || 'singleLine'})}}
              responsiveMode={ResponsiveMode.small}
            />
          </form>
          <DialogFooter>
            { loading ?
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
            : null }
            <DefaultButton onClick={()=> {this._isMounted && this.setState({hidden:true})}} text={i18n.t('app:cancel')} disabled={loading} />
            <PrimaryButton onClick={()=>{this._isMounted && this.setState({loading:true});setTimeout(()=>{this.setState({loading:false})},1000)}} text={i18n.t('app:save')} disabled={loading} />                   
          </DialogFooter>
        </Dialog>
      </div>
    )
  }

}
