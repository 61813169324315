import * as React from 'react';
import { IDetailsHeaderProps, IDetailsList, IRenderFunction, ScrollablePane, ScrollbarVisibility, ShimmeredDetailsList, Sticky, StickyPositionType, ThemeProvider, Selection, SelectionMode, SearchBox, TagPicker, ITag, DefaultButton, PrimaryButton, IBasePicker, IPickerItemProps, IButtonProps, ActionButton} from '@fluentui/react';
import { IconButton } from '@fluentui/react';
import { Persona } from '@fluentui/react/lib/Persona';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { history } from '../_helpers';
import i18n from "i18next";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
 const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderRadius: "2px",
  border: "1px solid lightgrey",

  // change background colour if dragging
  background: isDragging ? "white" : "white",
  boxShadow: isDragging ? '8px 8px 4px rgba(0,0,0,0.1)' : '0px 2px 4px rgba(0,0,0,0.1)',

  // styles we need to apply on draggables
  ...draggableStyle
});
const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#f4f6fa" : "#f4f6fa",
  borderLeft: "1px solid rgb(237, 235, 233)",
  borderRight: "1px solid rgb(237, 235, 233)",
  borderBottom: "1px solid rgb(237, 235, 233)",
  borderRadius : '2px',
  padding: grid,
  width: 250,
  height: '100%',
  margin: '0 0.5rem',
  overflowY: 'auto',
  overflowX: 'hidden'
});

const testTags: ITag[] = [
  {key: 'completed', name: 'Milestone: Completed', type: 'milestone'},
  {key: 'incompleted', name: 'Milestone: Incompleted', type: 'milestone'},
  {key: 'marketing', name: 'Milestone: Marketing', type: 'milestone'},
  {key: 'bidding', name: 'Milestone: Bidding', type: 'milestone'},
  {key: '', name: 'Owner: Francisco Fontinha', type: 'owner'},
  {key: '', name: 'Owner: Jaime Rivas', type: 'owner'},
  {key: '', name: 'Owner: Manuel Marcos', type: 'owner'},
  {key: '', name: 'Owner: Michael Bommers', type: 'owner'},
  {key: '', name: 'Owner: Xavier Campillo', type: 'owner'},
  {key: '', name: 'Company: Google', type: 'company'},
  {key: '', name: 'Company: Meta', type: 'company'},
  {key: '', name: 'Company: Microsoft', type: 'company'},
  {key: '', name: 'Company: Synergy', type: 'company'},
].map(item => ({ key: item.name, name: item.name}));

const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false;
  }
  return tagList.some(compareTag => compareTag.key === tag.key);
};

const filterSuggestedTags = (filter: string, tagList: any): any[] => {
  return filter
    ? testTags.filter(
        tag => tag.name.toLowerCase().indexOf(filter.toLowerCase()) === 0 && !listContainsTagList(tag, tagList),
      )
    : [];
};

export interface Props {
  match: {
    url: string
  },
  location: any,
  searchType: string,
  userData: any,
  page: string,
  callbackFunction: any,
  
}

export interface ColumnViewState {
  webUpdateAvailable: boolean;
  userData?: any;
  repoData?: any;
  goBack: number;
  showContextualMenu: boolean;
  selFolder: any;
  items: any;
  selectedTags: any;
  groups: any;
  columns: any;
  milestones: any;
}

export class ColumnView extends React.Component<Props, ColumnViewState> {
  private _isMounted: boolean;
  private _selection: Selection;
  private _root:any = React.createRef<IDetailsList>();  
  private picker:any = React.createRef<IBasePicker<ITag>>();

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    //this._root = React.createRef<IDetailsList>(); 
    this.props = props


    this._selection = new Selection({
      onSelectionChanged: () => {
        var selItem: any = this._selection.getSelection()[this._selection.getSelection().length - 1]

        /*if(selItem) {
          var route = history.location.pathname
          var storedLastFocusedItems = JSON.parse(localStorage.getItem("lastFocusedItems") || "[]")

          var alreadyStored = storedLastFocusedItems.filter(item => {
            return item.route === route
          })[0]
          
          if(alreadyStored) {
            alreadyStored.item = selItem.id
          } else {
            var newFocusedItem = {
              route: route,
              item: selItem.id
            }
            storedLastFocusedItems.push(newFocusedItem)
          }

          localStorage.setItem("lastFocusedItems", JSON.stringify(storedLastFocusedItems))
        }*/

        setTimeout(() => {
          if (selItem && this._selection.getSelectedCount() === 1) {
            this._isMounted && this.props.callbackFunction({selItem: selItem});
          } else if (selItem && this._selection.getSelectedCount() > 1) {
            this._isMounted && this.props.callbackFunction({selItem: null});
          } else if (this._selection.getSelectedCount() === 0) {
            this._isMounted && this.props.callbackFunction({selItem: null});
          }
        }, 50)
      }
    });

    this.state = {
      webUpdateAvailable: false,
      userData: null,
      repoData: null,
      goBack: 0,
      showContextualMenu: false,
      selFolder: null,
      items: this.props.items,
      selectedTags: [],
      groups: [],
      columns: [],
      milestones: []
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    //this._setSelItem()
    if (this.props.page === "clientsExplorer"){ 
      let groups = _getGroups(this.props.items, 'company')
      this._isMounted && this.setState({groups:groups})
    }
    else if (this.props.page === "milestonesExplorer"){ 
      let groups = _getGroups(this.props.items, 'milestone') 
      this._isMounted && this.setState({groups:groups})
    }
    else if (this.props.page === "projectsExplorer"){ 
      this._isMounted && this.setState({groups:null})
    }
    console.log(this.state.items)
  }
  
  public componentDidUpdate(prevProps:any) {
    if (this.props.pipelineId === 'none' || this.props.pipelineId === 'all') {
      history.push('/projects/list' + document.location.search)
    }
    else if(this.props.items !== prevProps.items) {
      this.handleSearch()
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  onMessageReceived(event:any) {
    var data:any = event.data;

      if (data && data.msg === "webUpdateAvailable") {
        this._isMounted && this.setState({webUpdateAvailable: true})
      }
  }

  private onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(this.state.columns[sInd], source.index, destination.index);
      const newState = [...this.state.columns];
      newState[sInd] = items;
      this._isMounted && this.setState({columns:newState});
    } else {
      const result = move(this.state.columns[sInd], this.state.columns[dInd], source, destination);
      const newState = [...this.state.columns];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      this._isMounted && this.setState({columns:newState.filter(group => group)});
    }
  }

  private handleSearch() {
    let items = this.picker.current.selection._items
    if (items.length > 0) {
      var newItems:any = [];
      for (var i=0; i<this.props.items.length; i++) {
        for (var j=0; j<items.length; j++) {
          if ('Milestone: ' + this.props.items[i].milestone === items[j].key && !newItems.includes(this.props.items[i])) 
            newItems.push(this.props.items[i])
          else if ('Company: ' + this.props.items[i].company === items[j].key && !newItems.includes(this.props.items[i])) 
            newItems.push(this.props.items[i])
        }
      }

      this._isMounted && this.setState({
        items: newItems,
        columns: [newItems.slice(0,3), newItems.slice(3,5), newItems.slice(5,8)]
      })
      if (this.props.page === "clientsExplorer"){ 
        let groups = _getGroups(newItems, 'company')
        this._isMounted && this.setState({groups:groups})
      }
      else if (this.props.page === "milestonesExplorer"){ 
        let groups = _getGroups(newItems, 'milestone') 
        this._isMounted && this.setState({groups:groups})
      }
      else if (this.props.page === "projectsExplorer"){ 
        this._isMounted && this.setState({groups:null})
      }
      else if (this.props.page === "boardExplorer"){ 
        let groups = _getGroups(this.props.items, 'milestone') 
        this._isMounted && this.setState({groups:groups})
      }
    }
    else if (this.props.pipeline) {
      var milestones = this.props.pipeline.milestones

      var columns:any = [];

      for (let i=0; i < milestones.length; i++) {
        columns[i] = (this.props.items.filter((item)=>{
          return item.milestone === milestones[i].name
        }));
      }

      this._isMounted && this.setState({
        items: this.props.items,
        columns: columns,
        milestones: milestones
      })

      let groups = _getGroups(this.props.items, 'milestone') 
      this._isMounted && this.setState({groups:groups})
      
    }

  }

  private clearSearch() {
    this.picker.current.removeItems(this.picker.current.selection._items)
    setTimeout(()=>this.handleSearch(),100)
  }

  private addTag(tag:any) {
    if (this.picker.current) {
      let tagType:string = tag.key.split(': ')[0]
      let currentItems = this.picker.current.selection._items;
      let cantAdd = false;
      for (var i=0; i<currentItems.length; i++) {
        if (currentItems[i].key.split(': ')[0] === tagType) {
          if (currentItems[i].key === tag.key) {
            cantAdd = true;
          }
        }
      }
      if (!cantAdd) {
        this.picker.current.addItem(tag);   
      }
    }
  }

  public render() {

    return(
     
        <div>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <div className='d-flex flex-grow-1 align-items-center' style={{height: '46px', borderBottom: '1px solid #f2f2f2'}}>
            <span className='ml-3'>
              Main view
            </span>
          </div>
          <form className='d-flex mx-3 pt-2' onSubmit={(ev)=>{ev.preventDefault();this.handleSearch()}} style={{position:'sticky', left: '1rem', top: 0, zIndex: 1}}>
            <TagPicker 
              className="mr-1" 
              styles={{root: {width:'100%'}}} 
              onResolveSuggestions={filterSuggestedTags}
              inputProps={{
                id: 'picker1',
                style: {paddingRight: '29px'}
              }}
              componentRef={this.picker}
              onRenderItem={(item:any)=> {
                var tagType = item.key.split(': ')[0];
                var tagName = item.key.split(': ')[1];
                return(
                  tagType === 'Milestone' ?
                    <div key={item.key} className='d-flex align-items-center tag-badge' style={{backgroundColor:'yellow', borderRadius:'20px', paddingLeft:'6px'}}>
                      <span>{tagName}</span>
                      <IconButton onClick={()=>this.picker.current.removeItem(item.item)} className='remove-tag-button' iconProps={{iconName:'Cancel', style:{fontSize:14}}}/>
                    </div>
                  : tagType === 'Owner' ?
                    <div key={item.key} className='d-flex align-items-center tag-badge' style={{backgroundColor:'#f4f4f4', borderRadius:'20px'}}>
                      <span><Persona className="tag" size={10} text={tagName}/></span>
                      <IconButton onClick={()=>this.picker.current.removeItem(item.item)} className='remove-tag-button' iconProps={{iconName:'Cancel', style:{fontSize:14}}}/>
                    </div>
                  : tagType === 'Company' ?
                    <div key={item.key} className='d-flex align-items-center tag-badge' style={{backgroundColor:'#f4f4f4', borderRadius:'20px', paddingLeft:'6px'}}>
                      <span>{tagName}</span>
                      <IconButton onClick={()=>this.picker.current.removeItem(item.item)} className='remove-tag-button' iconProps={{iconName:'Cancel', style:{fontSize:14}}}/>
                    </div>
                  : <div key={item.key} className='d-flex align-items-center tag-badge' style={{backgroundColor:'#f4f4f4', borderRadius:'20px', paddingLeft:'6px'}}>
                      <span>{tagName}</span>
                      <IconButton onClick={()=>this.picker.current.removeItem(item.item)} className='remove-tag-button' iconProps={{iconName:'Cancel', style:{fontSize:14}}}/>
                    </div>
                )
              }}
            />
            <IconButton 
              iconProps={{iconName:'Cancel'}}
              onClick={this.clearSearch.bind(this)}
              //style={{position:'relative', right:'35px', width:'30px', height:'30px', top: '1px'}}
              className='clear-search-button'
            />
          </form>
          {this.state.columns && <>
            <PrimaryButton
              className='mt-3 ml-3 d-none h-100'
              text='Add new group'
              onClick={() => {
                this._isMounted && this.setState({columns:[...this.state.columns, []]})
              }}
            />
            <div className='d-flex mt-3 mx-2' style={{height: 'calc(100% - 158px)'}}>
              <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                {this.state.columns.map((el, ind) => (
                  <div key={ind}>
                    <div className='mx-2 column-header' style={{borderTopColor: 'rgba(16, 110, 190, '+(ind+1)/this.state.milestones.length+')'}}>
                      <span className='pl-1'>
                        {this.state.milestones[ind].name}
                      </span>
                    </div>
                    <Droppable key={ind} droppableId={`${ind}`}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          {...provided.droppableProps}
                        >
                          {el.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <div
                                    style={{
                                      //display: "flex",
                                      //justifyContent: "space-around"
                                    }}
                                  >
                                    <div className='col-12 px-0 my-1' style={{fontWeight:600}}>
                                      <a href={"/projects/"+item.id}>{item.name}</a>
                                    </div>
                                    <div className='col-12 px-0 my-1' style={{fontSize: 14}}>
                                      <span style={{fontWeight: 600}}>{i18n.t('app:company')+': '}</span>
                                      <span>{item.company}</span>
                                    </div>
                                    <div className='col-12 px-0 my-1' style={{fontSize: 14}}>
                                      <span style={{fontWeight: 600}}>{i18n.t('Close date')+': '}</span>
                                      <span>---</span>
                                    </div>
                                    <div className='col-12 px-0 my-1' style={{fontSize: 12, color: 'grey'}}>
                                      <span className='d-flex align-items-center'><Persona text={item.owner} color={'grey'} size={8} styles={{root: {width: '20px'}}}/>{item.owner}</span>
                                    </div>
                                    <div className='d-none col-2 px-0 my-auto'>
                                      <IconButton
                                      iconProps={{iconName:'Delete'}}
                                      onClick={() => {
                                        const newState = [...this.state.columns];
                                        newState[ind].splice(index, 1);
                                        this._isMounted && this.setState({columns:newState.filter(group => group.length)});
                                      }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                ))}
              </DragDropContext>
            </div>
          </>}
          </ScrollablePane>
        </div>
    )
  }

  private _settingSelItem: boolean = false;

  private focusItem(selItem) {
    if(selItem !== null) {
      this._settingSelItem = true;
      this._selection.setAllSelected(false)
      setTimeout(() => {
        this._selection.setKeySelected(selItem, true, true)
        var selIndex = this._selection.getSelectedIndices()[0]
        this._root.current && this._root.current.focusIndex(selIndex);
        this._settingSelItem = false
      }, 200)
    }
  }

}

function _getGroups(items, param:string = 'company') {
  var groups:any = []
  for (var i=0; i<items.length;i++) {
    let parameter = items[i][param];
    if (groups.filter(function(e) { return e.key === parameter+'-group'; }).length > 0) {
      groups.filter(function(e) { return e.key === parameter+'-group'; })[0].count++;
    }
    else {
      groups.push({key: parameter+'-group', name: parameter, startIndex: i, count: 1})
    }
  }
  return groups
}

function onRenderDetailsHeader(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
      {defaultRender!({
        ...props
      })}
    </Sticky>
  );
}