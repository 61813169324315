//import { authHeader } from '../_helpers';
import axios from 'axios'
import { BehaviorSubject } from 'rxjs';
import { authHeader, history } from '../_helpers';
import i18n from "i18next";
import Cookies from 'js-cookie'
import uuid from 'uuid-random';

var getListData = new BehaviorSubject<any | undefined>(undefined);
var sessionDuration = 30 //Same duration as the token
var canRefreshToken = true;

export const userService = {
    getTokenAAD,
    login,
    loginAAD,
    afterLogin,
    verifyEmailCode,
    resendVerifyEmailCode,
    refreshToken,
    setServerDomain,
    getServerDomain,
    logout,
    getUserData,
    getInstanceId,
    getMaintenance,
    getDriveRequiredVersion,
    getToolsRequiredVersion,
    getActivity,
    createActivityFeedId,
    search,
    saveSmartFolder,
    deleteSmartFolder,
    getSmartFolders,
    indexDocument,
    indexDocuments,
    moveDocuments,
    moveFolders,
    renameDocument,
    renameFolder,
    deleteDocuments,
    deleteFolders,
    restoreDocuments,
    restoreFolders,
    checkItemPermissions,
    getDocument,
    getFolderDetails,
    getFolderContent,
    getFolderCount,
    getFolderCountBulk,
    getPathData,
    getPathFolders,
    getRepoUsers,
    getPossibleUsers,
    addInternalPermissions,
    removeInternalPermissions,
    addExternalPermissions,
    removeExternalPermissions,
    getRepoGroups,
    getPossibleGroups,
    addGroupPermissions,
    removeGroupPermissions,
    getBookmarks,
    addBookmark,
    removeBookmark,
    deleteTagGlobal,
    getComments,
    addComment,
    deleteComment,
    getFileActivity,
    getAnnotations,
    getCoincidences,
    listFolders,
    listFoldersLevels,
    listFoldersFrom,
    getTrash,
    getRecentFiles,
    createFolder,
    createFolders,
    getBatchPendingFiles,
    addFilesToUploadsDb,
    updateUploadFiles,
    deleteUploadsFiles,
    deleteUploadsDb,
    startUploadsWebapp,
    createOfficeDoc,
    getUploadsCount,
    getUploadsQueue,
    getPendingUploads,
    getSuccessUploads,
    getErrorUploads,
    retryUploads,
    uploadFile,
    saveVersion,
    appendVersion,
    appendSynergyFileAsVersion,
    saveVersionFromDraft,
    deleteDraft,
    editVersionMessage,
    convertVersion,
    markAsExternalVersion,
    downloadOnlineDocument,
    restoreRevision,
    deleteRevision,
    undoDeleteRevision,
    downloadOnlineAsZip,
    getPublicLinks,
    createPublicLink,
    deletePublicLinks,
    copyPaste,
    insertTemplate,
    duplicateFile,
    duplicateFolder,
    getOfficeOnlineUrl,
    zipItems,
    unzipFile,
    syncLocalFiles,
    lockDocument,
    unlockDocument,
    forceUnlockDocument,
    importCitation,
    citationToCsl,
    saveCitation,
    getCitationbyDocument,
    deleteCitation,
    getTagCategories,
    getTagCategory,
    createTagCategory,
    editTagCategory,
    deleteTagCategory,
    getTag,
    getCategoryTags,
    getAllTags,
    createTag,
    createTagBulk,
    editTag,
    deleteTag,
    deleteTagBulk,
    getFileTags,
    addTag,
    addTagBulk,
    removeTag,
    removeTagBulk,
    getServerInfo,
    getServerInfoCompany,
    getWopiDomain,
    createSharepointLink,
    deleteSharepointLink,
    revertVersion,
    overwriteVersionFromDraft,
    updateColorTagDocuments,
    updateColorTagFolders
};

const getStoredData = (name: string) => {
    var cookie = Cookies.get(name)
    return cookie
}

async function deleteCookies(cookies) {
    for (let i = 0; i < cookies.length; i++) {
        await new Promise((resolve, reject) => {
            try {
                let cookie = cookies[i];
                Cookies.remove(cookie, { domain: domain, expires: '', sameSite: 'none', secure: true})
                Cookies.remove(cookie, { domain: 'portal.'+domain, expires: '' || undefined, sameSite: 'none', secure: true})
                resolve(true)
            } catch(error) {
                reject(error)
            }
        })
    }
}

const currentHostName = window.location.hostname
const synergyApi = process.env.REACT_APP_SYNERGY_API_URL
const domain = process.env.NODE_ENV === 'production' ? window.location.hostname.split('.').slice(1).join('.') : process.env.REACT_APP_DOMAIN;
var serverDomain = getStoredData("serverdomain") || "";
var membershipApi = synergyApi + serverDomain + process.env.REACT_APP_MEMBERSHIP_API_URL;
var artifactApi = synergyApi + serverDomain + process.env.REACT_APP_ARTIFACT_API_URL;
var searchApi = synergyApi + serverDomain + process.env.REACT_APP_SEARCH_API_URL;
var indexerApi = synergyApi + serverDomain + process.env.REACT_APP_INDEXER_API_URL;
var shareApi = synergyApi + serverDomain + process.env.REACT_APP_SHARE_API_URL;
var activityApi = synergyApi + serverDomain + process.env.REACT_APP_ACTIVITY_API_URL;
var translationApi = synergyApi + serverDomain + process.env.REACT_APP_TRANSLATION_API_URL;
let wopiApi = "https://wopi.synergy.page"
if(serverDomain === "us-prod.synergy.page") wopiApi = "https://wopi-alpha.synergy.page"
else if(serverDomain === "eu-dev.synergy.page") wopiApi = "https://wopi-beta.synergy.page"
else wopiApi = process.env.REACT_APP_WOPI_API_URL || "https://wopi.synergy.page"

function getWopiDomain() {
    return wopiApi;
}

function getInstanceId() {
    var instanceId = Cookies.get('instanceId')
    
    if(!instanceId) {
        instanceId = "Origin-" + uuid()
        Cookies.set("instanceId", instanceId,{ domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
    }
    
    if (!Cookies.get('instanceId')) {
        Cookies.set("instanceId", instanceId,{ domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
    }

    return instanceId;
}

function addDays(date: Date, days: number) {
    var result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
  }

  function toTimestamp(strDate: any){
    var datum = Date.parse(strDate);
    return datum;
 }

function getTokenAAD(token, domain?) {
    const headersOptions = {
        headers: { 'Authorization': token }
    }

    return axios.post((domain ? synergyApi + domain : synergyApi + serverDomain) + '/scim/v2/aad/token', {}, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleLoginResponse(error.response || error)
    });
}

function login(email, password, companyDomain: string, staySignedIn: boolean) {
    const requestOptions = {
        'application': 'Web',
        'domain': companyDomain,
        'email': email,
        'origin': getInstanceId(),
        'password': password,
        'staySignedIn': staySignedIn
    }

    return axios.post(membershipApi + '/v2/public/auth/login', requestOptions, {})
    .then(response => {
        return response;
    }).catch(error => {
        throw handleLoginResponse(error.response || error)
    });
}

function loginAAD(token, companyDomain: string, staySignedIn: boolean) {
    let requestOptions = {
        "domain": companyDomain,
        "token": token
    }

    return axios.post(membershipApi + '/v2/public/azure/exchange-token', requestOptions)
    .then(response => {
        let headersOptions = {
            headers: { 'Authorization': response.data.token }
        };
        
        let requestOptions = {
            "application": "Web",
            "domain": companyDomain,
            "origin": getInstanceId(),
            "staySignedIn": staySignedIn
        };

        return axios.post(membershipApi + '/v2/azure/login', requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleLoginResponse(error.response || error)
        });
    }).catch(error => {
        throw handleLoginResponse(error.response || error)
    });
}


function verifyEmailCode(token:string, code:string, staySignedIn:boolean) {
    let headersOptions = {
        headers: { 'Authorization': token }
    };

    let requestOptions = {
        "application": "Web",
        "key": code,
        "origin": getInstanceId(),
        "staySignedIn": staySignedIn
    }

    return axios.post(membershipApi + '/v2/auth/2fa/validate', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleLoginResponse(error.response || error)
    });
}

function resendVerifyEmailCode(email, password, companyDomain) {
    const requestOptions = {
        "domain": companyDomain,
        "email": email,
        "password": password
    }

    return axios.post(membershipApi + '/v2/public/auth/2fa/resend', requestOptions)
    .then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}

async function afterLogin(response, staySignedIn){
    console.log(response)
    var token = response.data.accessToken;
    var daysToExpire = 9 //Used by wopi
    var expirationDate = addDays(new Date(), daysToExpire)
    var tokenttl = toTimestamp(expirationDate)
    if (staySignedIn) {
        Cookies.set('refreshKey', response.data.refreshToken, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
    }

    var sessionExpirationDate = new Date();
    sessionExpirationDate.setDate(sessionExpirationDate.getDate() + sessionDuration);

    var cookies = Cookies.withConverter({
        write: function (value, name) {
            return value;
        }
    })
    console.log(token, domain)
    cookies.set('token', token, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
    Cookies.set('tokenttl', tokenttl, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
    Cookies.set("serverdomain", serverDomain, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})

    return response;
}

async function refreshToken() {
    var instanceId = getInstanceId();
    var refreshKey = Cookies.get('refreshKey') || localStorage.getItem('refreshKey');
    if (canRefreshToken && refreshKey) {
        canRefreshToken = false;
        await axios.post(membershipApi + '/v2/public/auth/refresh-token', {refreshToken: refreshKey})
        .then(response => {
            console.log('Token refreshed')
            var token = response.data.accessToken;
            var daysToExpire = 9 //Used by wopi
            var expirationDate = addDays(new Date(), daysToExpire)
            var tokenttl = toTimestamp(expirationDate)
            var sessionExpirationDate = new Date();
            sessionExpirationDate.setDate(sessionExpirationDate.getDate() + sessionDuration);

            var cookies = Cookies.withConverter({
                write: function (value, name) {
                return value;
                }
            })
            Cookies.set('refreshKey', response.data.refreshToken, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
            Cookies.set('instanceId', instanceId, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
            cookies.set('token', token, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
            Cookies.set('tokenttl', tokenttl, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
            Cookies.set("serverdomain", serverDomain, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})


            setTimeout(()=>{
                canRefreshToken = true;
            },10000)
            return response
        }).catch(error => {
            setTimeout(()=>{
                canRefreshToken = true;
            },10000)
            logout();
            history.push('/login');
            console.log('Error trying to refresh token.')
            throw error;
        });
    }
    else {
        logout();
        history.push('/login');
        console.log('Error trying to refresh token.')
    }
}

function setServerDomain(url: string) {
    var promise = new Promise((resolve, reject) => {
        serverDomain = url
        membershipApi =  synergyApi + serverDomain + process.env.REACT_APP_MEMBERSHIP_API_URL;
        artifactApi = synergyApi + serverDomain + process.env.REACT_APP_ARTIFACT_API_URL;
        searchApi = synergyApi + serverDomain + process.env.REACT_APP_SEARCH_API_URL;
        indexerApi = synergyApi + serverDomain + process.env.REACT_APP_INDEXER_API_URL;
        shareApi = synergyApi + serverDomain + process.env.REACT_APP_SHARE_API_URL;
        activityApi = synergyApi + serverDomain + process.env.REACT_APP_ACTIVITY_API_URL;
        translationApi = synergyApi + serverDomain + process.env.REACT_APP_TRANSLATION_API_URL;
        Cookies.set("serverdomain", serverDomain, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
        resolve(url)
    })
    return promise
}

function getServerDomain() {
    return serverDomain
}

function deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=" + domain + ";SameSite;Secure";
    }
}

function logout() {
    const headersOptions:any = {
        headers: authHeader()
    };
    if (getStoredData("token")) axios.delete(membershipApi + '/v2/auth/logout', headersOptions)
    // remove company logos
    localStorage.removeItem('companyLogo1');
    localStorage.removeItem('companyLogo2');
    localStorage.removeItem('companyLogo3');

    // remove serverDomain condition
    var serverDomain = Cookies.get("serverdomain")
    if(serverDomain !== process.env.REACT_APP_SYNERGY_CLOUD_US_URL
        && serverDomain !== process.env.REACT_APP_SYNERGY_CLOUD_EU_URL) {
        deleteCookies(["serverdomain"])
    }
    localStorage.removeItem('refreshKey')
    deleteCookies(["token", "tokenttl", "refreshKey"]).then(response => {
        history.push('/login');
    }).catch(error => console.log(error))
    history.push('/login');
}

function getUserData() {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(membershipApi + '/v2/auth/introspect-token', headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        console.log(error)
        if(error.status === 404) {
            logout();
            if(history.location.pathname.indexOf("/appUpdate") === -1) {
                history.push('/login');
            }
            throw error.response;
        } else {
            throw handleResponse(error.response)
        }
    });
}

function handleLoginResponse(response) {
    if (response) {
        if (response.status === 405) {
            window.postMessage(
                {
                    msg: "showServiceDialog",
                    dialogMessage: i18n.t('app:noPermission')
                }
                , '*');
            throw response;
        } else if (response.status === 503) {
            // Maintenance mode
            window.postMessage(
            {
                msg: "maintenance"
            }
            , '*');
            throw response;
        } else {
            throw response;
        }
    }
}

function handleResponse(response:any) {
    if(response) {
        if (response.status === 401 || response.status === 403) {
            // auto logout if 401 or 403 response returned from api
            logout();
            history.push('/login');
            throw response;
        } else if (response.status === 405) {
            alert(i18n.t('app:noPermission'))
            throw response;
        } else if (response.status === 503) {
            // Maintenance mode
            window.postMessage(
            {
                msg: "maintenance"
            }
            , '*');
            throw response;
        } else {
            throw response;
        }
    }
    
    if(!response) {
        return 'Error: Network Error';
    }
}

//Drive endpoints

function updateColorTagDocuments(list: string[], colorCode:string,) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.put(artifactApi + '/documents/colorTags/' + colorCode, list, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateColorTagFolders(list: string[], colorCode:string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.put(artifactApi + '/folders/colorTags/' + colorCode, list, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function overwriteVersionFromDraft(id: string, message:string) {
    const headersOptions: any = {
        headers: authHeader()
    };

    const requestOptions: any = {
        documentId: id,
        message: message,
    }

    return axios.post(indexerApi + '/repomanager/version/overwrite', requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function overwriteVersionAppend(form: any, id:string, message: string, isExternal: boolean) {
    const requestOptions: any = {
        headers: authHeader(),
        contentType: 'multipart/form-data',
        maxContentLength: Infinity,
        maxBodyLength: Infinity
    }
    
    return axios.post(indexerApi + '/repomanager/append/version/overwrite?documentId=' + id + '&message=' + encodeURI(message) + '&external=' + isExternal, form, requestOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function revertVersion(documentId: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(indexerApi + '/repomanager/version/undo/' + documentId, {}, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function createSharepointLink(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + '/sharepoint-service/sharepoint/link/' + id, {}, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function deleteSharepointLink(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.delete(synergyApi + serverDomain + '/sharepoint-service/sharepoint/link/' + id, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getMaintenance() {
    return axios.get(membershipApi + '/maintenance/public', {})
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getDriveRequiredVersion() {
    return axios.get(membershipApi + '/maintenance/public/minimum/drive/version', {})
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getToolsRequiredVersion() {
    return axios.get(membershipApi + '/maintenance/public/minimum/tools/version', {})
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getActivity(repo: string, user_id: string | any, model_type: string | any, operation_type: string | any, initialDate: number | any, finalDate: number | any, paginationResultFrom: number, paginationResultSize: number) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        repo: repo,
        user_id: user_id,
        model_type: model_type,
        operation_type: operation_type,
        initialDate: parseInt(initialDate),
        finalDate: parseInt(finalDate),
        paginationResultFrom: paginationResultFrom,
        paginationResultSize: paginationResultSize
    }

    return axios.post(activityApi + '/activity-feed/search', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function createActivityFeedId() {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(activityApi + '/activity-feed/new_activity_id', headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function search(query: string) {
    const headersOptions:any = {
        headers: authHeader(),
    };

    return axios.get(searchApi + '/search' + query, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function saveSmartFolder(repo: string, description: string, searchTerms: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        repo: repo,
        description: description,
        search: searchTerms
    }

    return axios.post(artifactApi + '/smartfolders/create', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function deleteSmartFolder(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.delete(artifactApi + '/smartfolders/' + id, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getSmartFolders(repo: string) {
    const requestOptions:any = {
        headers: authHeader(),
        params: {
            repo: repo
        }
    };

    return axios.get(artifactApi + '/smartfolders/search', requestOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function indexDocument(document) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(indexerApi + '/documents/index', document, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function indexDocuments(documents) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(indexerApi + '/documents/multiindex', documents, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function moveDocuments(draggedItemIds: any[], destinationId: string, tagInheritance?: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        documentsToMove: draggedItemIds,
        folderDestination: destinationId,
        tagInheritance: tagInheritance
    }

    return axios.put(artifactApi + '/documents/move', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function moveFolders(draggedItemId: string, destinationId: string, tagInheritance?: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        folderToMove: draggedItemId,
        folderDestination: destinationId,
        tagInheritance: tagInheritance
    }

    return axios.put(artifactApi + '/folders/move', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function renameDocument(id: string, name: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        documentId: id,
        documentName: name
    }


    return axios.put(artifactApi + '/documents/rename', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function renameFolder(id: string, name: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
            id: id,
            name: name
    }

    return axios.put(artifactApi + '/folders/update', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function deleteDocuments(documentsIds: any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(artifactApi + '/documents/trash', documentsIds, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function deleteFolders(foldersIds: any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(artifactApi + '/folders/trash', foldersIds, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function restoreDocuments(documentsIds: any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(artifactApi + '/documents/restore', documentsIds, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function restoreFolders(foldersIds: any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(artifactApi + '/folders/restore', foldersIds, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function checkItemPermissions(itemId: string, userId: string, groups: any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        userId: userId,
        groups: groups
    };

    return axios.post(artifactApi + '/permissions/check/' + itemId, requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getDocument(id) {
    const headersOptions:any = {
        headers: authHeader()
    };

    var fileId = id
    if(fileId.endsWith("-read-only")) fileId = fileId.replace("-read-only", "")

    return axios.get(artifactApi + '/documents/byid/' + id, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getFolderDetails(id) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(artifactApi + '/folders/' + id, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getFolderContent(id) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(artifactApi + '/folders/search/' + id, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {        
        throw handleResponse(error.response)
    });
}

function getFolderCount(id) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(artifactApi + '/folders/details?parentId=' + id, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getFolderCountBulk(ids) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(artifactApi + '/artifact/folders/multiple/details', headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getPathData(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(artifactApi + '/paths/' + id, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getPathFolders(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(artifactApi + '/paths/folders/' + id, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getRepoUsers() {
    const headersOptions:any = {
        headers: authHeader()
    };

    var size = 2000 // request limit

    return axios.get(membershipApi + '/v2/users?size=' + size, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getPossibleUsers(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(artifactApi + '/permissions/possible/users/' + id, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function addInternalPermissions(folderId: string, users: any, cascade: boolean, rolePermissions: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        folderId: folderId,
        users: users,
        cascade: cascade,
        rolePermissions: rolePermissions
    };

    return axios.post(artifactApi + '/permissions/add/user', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function removeInternalPermissions(folderId: string, users: any, cascade: boolean) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        folderId: folderId,
        users: users,
        cascade: cascade
    };

    return axios.post(artifactApi + '/permissions/remove/user', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function addExternalPermissions(folderId: string, users: any, cascade: boolean) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        folderId: folderId,
        users: users,
        cascade: cascade
    };

    return axios.post(artifactApi + '/permissions/add/external/user', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function removeExternalPermissions(folderId: string, users: any, cascade: boolean) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        folderId: folderId,
        users: users,
        cascade: cascade
    };

    return axios.post(artifactApi + '/permissions/remove/external/user', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getRepoGroups() {
    const headersOptions:any = {
        headers: authHeader()
    };

    var size = 2000 // request limit

    return axios.get(membershipApi + '/v2/groups?size=' + size, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getPossibleGroups(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(artifactApi + '/permissions/possible/groups/' + id, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function addGroupPermissions(folderId: string, groups: any, cascade: boolean, rolePermissions: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        folderId: folderId,
        groups: groups,
        cascade: cascade,
        rolePermissions: rolePermissions
    };

    return axios.post(artifactApi + '/permissions/add/group', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function removeGroupPermissions(folderId: string, groups: any, cascade: boolean) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        folderId: folderId,
        groups: groups,
        cascade: cascade
    };

    return axios.post(artifactApi + '/permissions/remove/group', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getBookmarks(repoId: any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(artifactApi + '/bookmarks/getMyBookmarks?reposIds=' + repoId, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function addBookmark(repoId: string, fileId: string, isFolder: boolean) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        repoId: repoId,
        fileId: fileId,
        isFolder: isFolder
    };

    return axios.post(artifactApi + '/bookmarks/add?repoId=' + repoId + "&fileId=" + fileId + "&isFolder=" + isFolder, requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function removeBookmark(repoId: string, fileId: string, isFolder: boolean) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.delete(artifactApi + '/bookmarks/remove?repoId=' + repoId + "&fileId=" + fileId + "&isFolder=" + isFolder, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function deleteTagGlobal(id: string) {
    const requestOptions:any = {
        headers: authHeader()
    };

    return axios.delete(artifactApi + '/tags/delete?id=' + id, requestOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getComments(id) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(artifactApi + '/comments/search/' + id, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function addComment(id: string, comment: string, author: Object) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        idArtifact: id,
        comment: comment,
        author: author,
        idReferrer: null,
        referrerComment: null
    }

    return axios.post(artifactApi + '/comments', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function deleteComment(id) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.delete(artifactApi + '/comments/' + id, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getFileActivity(id) {
    const headersOptions:any = {
        headers: authHeader()
    }

    const requestOptions:any = {
        modelsToSearchIDs: [id],
        paginationResultFrom: 0,
        paginationResultSize: 100
    }

    return axios.post(activityApi + '/activity-feed/search', requestOptions, headersOptions)
        .then(response => {
            return response;
        })
        .catch(error => {
            throw handleResponse(error.response)
        });
}

function getAnnotations(document) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(artifactApi + "/annotations/search?id=" + document, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getCoincidences(id: string, text: string) {
    const requestOptions:any = {
        headers: authHeader(),
        params: {
            text: text
        }
    }

    return axios.get(searchApi + "/search/" + id, requestOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

// function list() {
//     var observable = Observable.create(() => {
//         const headersOptions:any = {
//             headers: authHeader()
//         }

//         return axios.get(artifactApi + '/documents/list', headersOptions)
//         .then(response => {
//             getListData.next(response)
//             return response;
//         }).catch(error => {
//             throw handleResponse(error.response)
//         });
//     });

//     return observable;
// }

function listFolders() {
    const headersOptions:any = {
        headers: authHeader()
    }

    return axios.get(artifactApi + `/folders/list`, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function listFoldersLevels(id:string, levels: number) {
    const headersOptions:any = {
        headers: authHeader()
    }

    return axios.get(artifactApi + `/folders/search/`+ id + `/levels/`+ levels, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function listFoldersFrom(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    }

    return axios.get(artifactApi + `/folders/search/from/` + id, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getTrash(repo: string) {
    const headersOptions:any = {
        headers: authHeader()
    }

    return axios.get(artifactApi + `/documents/list/trash?repo=` + repo, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getRecentFiles() {
    const headersOptions:any = {
        headers: authHeader()
    }

    return axios.get(activityApi + `/activity-feed/recentfiles`,headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function createFolder(name: string, parentId: string, migrateUsers: boolean, tagInheritance?:string) {
    const headersOptions:any = {
        headers: authHeader()
    }

    const requestOptions:any = {
        folder: {
            name: name,
            parent_id: parentId
        },
        migrateUsers: migrateUsers,
        tagInheritance: tagInheritance
    }

    return axios.post(artifactApi + '/folders/create', requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function createFolders(parentId: string, migrateUsers: boolean, folders: any, activityFeedId: string, tagInheritance?:string) {
    const headersOptions:any = {
        headers: authHeader()
    }

    const requestOptions:any = {
        parentId: parentId,
        migrateUsers: migrateUsers,
        folders: folders,
        activityFeedId: activityFeedId,
        tagInheritance: tagInheritance
    }

    return axios.post(artifactApi + '/folders/createBulk', requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getBatchPendingFiles(id: string) {
    const requestOptions: any = {
        headers: authHeader()
    }

    var size = 10

    return axios.get(indexerApi + '/uploadmanager/pending/files/' + id + '?size=' + size, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function addFilesToUploadsDb(files: object, tagInheritance?:string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(indexerApi + `/uploadmanager/add/files`, files, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function updateUploadFiles(files: any, status: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    var instanceId = getInstanceId();

    return axios.put(indexerApi + '/uploadmanager/update/files/' + instanceId + "?uploadStatus=" + status, files, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function deleteUploadsFiles(ids: any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(indexerApi + '/uploadmanager/delete/files/' + getInstanceId(), ids, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function deleteUploadsDb(status: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.delete(indexerApi + '/uploadmanager/files/' + getInstanceId() + '?uploadStatus=' + status, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function startUploadsWebapp(size) {
    const requestOptions: any = {
        headers: authHeader()
    }

    var uploadStatus = "PENDING"
    var sort = "asc"

    return axios.get(indexerApi + `/uploadmanager/files/` + getInstanceId() + "?uploadStatus=" + uploadStatus + "&size=" + size + "&sort=" + sort, requestOptions)
        .then(response => {
            console.log(response)
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function createOfficeDoc(parentId: string, message: string, fileType: string, fileName: string, tagInheritance?:string) {
    const headersOptions:any = {
        headers: authHeader(),
        params: {
            parentId: parentId,
        message: message,
        fileType: fileType,
        fileName: fileName,
        tagInheritance: tagInheritance
        }
    }

    const requestOptions: any = {
        parentId: parentId,
        message: message,
        fileType: fileType,
        fileName: fileName,
        tagInheritance: tagInheritance
    }

    return axios.post(indexerApi + `/repomanager/create/fromtemplate`, requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getUploadsCount() {
    const requestOptions: any = {
        headers: authHeader()
    }
    
    return axios.get(indexerApi + `/uploadmanager/status/` + getInstanceId(), requestOptions)
        .then((response: any) => {
            var queue = {
                processing: response.data.processing,
                queued: response.data.pending,
                success: response.data.success,
                error: response.data.failure
            }
            
            return queue;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getUploadsQueue() {
    const requestOptions: any = {
        headers: authHeader()
    }

    var uploadStatus = "PROCESSING"
    var size = 100
    var sort = "desc"

    return axios.get(indexerApi + `/uploadmanager/files/` + getInstanceId() + "?uploadStatus=" + uploadStatus + "&size=" + size + "&sort=" + sort + "&viewOnly=true", requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getPendingUploads() {
    const requestOptions: any = {
        headers: authHeader()
    }

    var uploadStatus = "PENDING"
    var size = 100
    var sort = "asc"

    return axios.get(indexerApi + `/uploadmanager/files/` + getInstanceId() + "?uploadStatus=" + uploadStatus + "&size=" + size + "&sort=" + sort + "&viewOnly=true", requestOptions)
        .then(response => {
            console.log(response)
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getSuccessUploads() {
    const requestOptions: any = {
        headers: authHeader()
    }

    var uploadStatus = "SUCCESS"
    var size = 100
    var sort = "desc"

    return axios.get(indexerApi + `/uploadmanager/files/` + getInstanceId() + "?uploadStatus=" + uploadStatus + "&size=" + size + "&sort=" + sort + "&viewOnly=true", requestOptions)
        .then(response => {
            console.log(response)
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getErrorUploads() {
    const requestOptions: any = {
        headers: authHeader()
    }

    var uploadStatus = "ERROR"
    var size = 100
    var sort = "desc"

    return axios.get(indexerApi + `/uploadmanager/files/` + getInstanceId() + "?uploadStatus=" + uploadStatus + "&size=" + size + "&sort=" + sort + "&viewOnly=true", requestOptions)
        .then(response => {
            console.log(response)
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function retryUploads(ids: Object) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(indexerApi + "/uploadmanager/retry/files/" + getInstanceId(), ids, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function uploadFile(form: any, fileId: string, parentId:string, tagInheritance?:string) {
    const requestOptions: any = {
        headers: authHeader(),
        contentType: 'multipart/form-data',
        maxContentLength: Infinity,
        maxBodyLength: Infinity
    }

    var message = "File uploaded"

    return axios.post(indexerApi + '/repomanager/upload?fileId=' + fileId + '&parentId=' + parentId + '&tagInheritance=' + tagInheritance + '&message=' + encodeURI(message) + '&origin=' + getInstanceId(), form, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function saveVersion(form: any, id:string, message: string, major: boolean, isExternal: boolean) {
    const requestOptions: any = {
        headers: authHeader(),
        contentType: 'multipart/form-data',
        maxContentLength: Infinity,
        maxBodyLength: Infinity
    }

    return axios.post(indexerApi + '/repomanager/upload/revision?documentId=' + id + '&message=' + encodeURI(message) + '&major=' + major + '&external=' + isExternal, form, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function appendVersion(form: any, id:string, message: string, major: boolean, isExternal: boolean, draft: boolean, messageDraft: string, majorDraft: boolean) {
    const requestOptions: any = {
        headers: authHeader(),
        contentType: 'multipart/form-data',
        maxContentLength: Infinity,
        maxBodyLength: Infinity
    }

    if (draft) {
        return axios.post(indexerApi + '/repomanager/append/revision?documentId=' + id + '&message=' + encodeURI(message) + '&major=' + major + '&external=' + isExternal + '&draftMessage=' + encodeURI(messageDraft) + '&draftMajor=' + majorDraft, form, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
    }
    else {
        return axios.post(indexerApi + '/repomanager/append/revision?documentId=' + id + '&message=' + encodeURI(message) + '&major=' + major + '&external=' + isExternal, form, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
    }
}

function appendSynergyFileAsVersion(documentFromAppendId: string, revisionFromAppendId:string, id:string, message: string, major: boolean, isExternal: boolean, draft: boolean, messageDraft: string, majorDraft: boolean) {
    const requestOptions: any = {
        headers: authHeader()
    }

    if (draft) {
        return axios.post(indexerApi + '/repomanager/append/existing/revision?documentId=' + id + '&message=' + encodeURI(message) + '&major=' + major + '&external=' + isExternal + '&draftMessage=' + encodeURI(messageDraft) + '&draftMajor=' + majorDraft + '&documentFromAppendId=' + documentFromAppendId + '&revisionFromAppendId=' + revisionFromAppendId, {}, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
    }
    else {
        return axios.post(indexerApi + '/repomanager/append/existing/revision?documentId=' + id + '&message=' + encodeURI(message) + '&major=' + major + '&external=' + isExternal + '&documentFromAppendId=' + documentFromAppendId + '&revisionFromAppendId=' + revisionFromAppendId, {}, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
    }
}

function saveVersionFromDraft(id: string, message:string, major: boolean) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions: any = {
        documentId: id,
        message: message,
    }

    return axios.post(indexerApi + '/repomanager/save/draft/?major=' + major, requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function deleteDraft(id: string) {
    const requestOptions:any = {
        headers: authHeader()
    };

    return axios.delete(indexerApi + '/repomanager/draft/' + id, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function editVersionMessage(documentId: string, revisionId: number, message:string) {
    const headersOptions:any = {
        headers: authHeader()
    };
    
    const requestOptions: any = {
        documentId: documentId,
        revisionId: revisionId,
        message: message
    }

    return axios.put(artifactApi + '/documents/rename/revision', requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function convertVersion(documentId: string, major: boolean) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(artifactApi + '/documents/convert/revision?documentId=' + documentId + "&major=" + major, {}, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function markAsExternalVersion(documentId: string, revisionId: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions: any = {
        id: documentId,
        revisionId: revisionId
    }

    return axios.post(indexerApi + '/repomanager/revision/external', requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function downloadOnlineDocument(id: string, revisionId: string, type:string = "DOWNLOAD") {
    const requestOptions: any = {
        headers: authHeader(),
        responseType: "arraybuffer" as any
    }

    return axios.get(indexerApi + '/repomanager/' + revisionId + "?documentId=" + id + "&downloadType=" + type, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function downloadOnlineAsZip(files: Array<any>, folders: Array<any>) {
    const headersOptions: any = {
        headers: authHeader(),
        responseType: "arraybuffer" as any
    }
    const requestOptions: any = {
        documents: files,
        folders: folders
    }

    return axios.post(shareApi + `/zip/download`, requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getPublicLinks() {
    const requestOptions:any = {
        headers: authHeader(),
    };

    return axios.get(shareApi + '/zip/list', requestOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function createPublicLink(folders: Array<string>, documents: Array<string>) {
    const headersOptions: any = {
        headers: authHeader()
    }
    const requestOptions: any = {
        folders: folders,
        documents: documents
    }

    return axios.post(shareApi + `/zip/create/link`, requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function deletePublicLinks(ids: string) {
    const requestOptions:any = {
        headers: authHeader(),
        data: ids
    };

    return axios.delete(shareApi + '/zip/delete/link', requestOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function copyPaste(folders: any, documents: any, destinationId: string, tagInheritance?: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        folders: folders,
        documents: documents,
        folderDestination: destinationId,
        tagInheritance: tagInheritance
    }

    return axios.post(indexerApi + `/repomanager/copy/paste`, requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function insertTemplate(folders: any, documents: any, destinationId: string, tagInheritance?: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        folders: folders,
        documents: documents,
        folderDestination: destinationId,
        tagInheritance: tagInheritance
    }

    return axios.post(indexerApi + `/repomanager/copy/paste/template`, requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function duplicateFile(id: string, revisionId?) {
    const headersOptions: any = {
        headers: authHeader()
    }

    let revision = revisionId ? ("?revisionId="+revisionId) : ""

    return axios.post(indexerApi + `/repomanager/duplicate/file/` + id + revision, {}, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function duplicateFolder(id: string) {
    const headersOptions: any = {
        headers: authHeader()
    }

    return axios.post(indexerApi + `/repomanager/duplicate/folder/` + id, {}, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function restoreRevision(id: string, revisionId:string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions: any = {
        id: id,
        revisionId: revisionId
    }

    return axios.post(indexerApi + '/repomanager/restore', requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function deleteRevision(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(indexerApi + '/repomanager/revision/delete/' + id, {}, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function undoDeleteRevision(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(indexerApi + '/repomanager/revision/delete/undo/' + id, {}, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getOfficeOnlineUrl(fileId: string, action: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(wopiApi + `/wopi/access_info_url?fileId=${fileId}&action=${action}`, null, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function zipItems(folders: any, documents:any, zipName: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions: any = {
        folders: folders,
        documents: documents,
        zipName: zipName
    }

    return axios.post(shareApi + '/zip/create', requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function unzipFile(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(shareApi + '/zip/unzip/' + id, {}, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function syncLocalFiles(ids: any) {
    const requestOptions: any = {
        headers: authHeader(),
        params: {
            ids: ids.join(",")
        }
    };

    return axios.get(artifactApi + `/documents/offline/info`, requestOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function lockDocument(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(indexerApi + '/repomanager/lock/' + id + "?lockInstanceId=" + getInstanceId(), null, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function unlockDocument(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(indexerApi + '/repomanager/unlock/' + id + '?lockInstanceId=' + getInstanceId(), {}, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function forceUnlockDocument(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(indexerApi + '/repomanager/unlock/' + id + '?force=true', {}, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function importCitation(form: any) {
    return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open("POST", translationApi + "/import", true);
        xhr.setRequestHeader('Content-Type', 'text/plain');
        xhr.onload = (e) => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                resolve(xhr.responseText);
              } else {
                reject(xhr);
              }
            }
        };
        xhr.onerror = (e) => {
            reject(xhr);
        };

        xhr.send(form);
    });
}

function citationToCsl(citation: any) {
    return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open("POST", translationApi + "/export?format=csljson", true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = (e) => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                resolve(xhr.responseText);
              } else {
                reject(xhr.statusText);
              }
            }
        };
        xhr.onerror = (e) => {
            reject(xhr.statusText);
        };

        xhr.send(citation);
    });
}

function saveCitation(document_id: string, citation: any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        document_id: document_id,
        citation: citation
    }

    return axios.post(artifactApi + '/citations/create' , requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getCitationbyDocument(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(artifactApi + '/citations/searchByDocument/' + id, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function deleteCitation(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.delete(artifactApi + '/citations/' + id, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getTagCategories(repoId: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(artifactApi + '/tags/categories?repo=' + repoId, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getTagCategory(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(artifactApi + '/tags/categories/' + id, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function createTagCategory(name: string, repoId: string, description: string, tagSelection: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        name: name,
        repo: repoId,
        description: description && description.length > 0 ? description : null,
        tagSelection: tagSelection,
    }

    return axios.post(artifactApi + '/tags/categories/create', requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function editTagCategory(name: string, id:string, description: string, tagSelection: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        name: name,
        description: description && description.length > 0 ? description : null,
        tagSelection: tagSelection,
    }

    return axios.put(artifactApi + '/tags/categories/update/' + id, requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function deleteTagCategory(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.delete(artifactApi + '/tags/categories/' + id, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getTag(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(artifactApi + '/tags/' + id, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getCategoryTags(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(artifactApi + '/tags/all/categories/' + id, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getAllTags(repo: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(artifactApi + '/tags/all/' + repo, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function createTag(color: string, name: string, categoryId: string, repo: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        color: color,
        name: name,
        repo: repo,
        categoryTagId: categoryId
    }

    return axios.post(artifactApi + '/tags/create', requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function createTagBulk(tags: any[], categoryId: string, repo: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    let requestOptions:any = []
    for (let i=0; i<tags.length; i++) {
        requestOptions.push({
            color: tags[i].color,
            name: tags[i].name,
            repo: repo,
            categoryTagId: categoryId
        })
    }

    return axios.post(artifactApi + '/tags/bulk/create', requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function editTag(color: string, name: string, id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        color: color,
        name: name
    }

    return axios.put(artifactApi + '/tags/update/' + id, requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function deleteTag(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.delete(artifactApi + '/tags/' + id, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function deleteTagBulk(ids: string[]) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.delete(artifactApi + '/tags/bulk/delete?tagIds=' + ids.toString(), headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function addTag(folderIds: any[], documentIds: any[], tagId :string,  cascade: boolean = false) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        folderIds: folderIds,
        documentIds: documentIds,
        tagId: tagId,
        cascade: cascade,
    }

    return axios.post(artifactApi + '/tags/add', requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            window.postMessage(
                {
                    msg: "showServiceDialog",
                    dialogMessage: i18n.t('app:cantAddTag')
                }
                , '*');
        });
}

function addTagBulk(folderIds: any[], documentIds: any[], tagIds :string[],  cascade: boolean = false) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        folderIds: folderIds,
        documentIds: documentIds,
        tagIds: tagIds,
        cascade: cascade,
    }

    return axios.post(artifactApi + '/tags/bulk/add', requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            window.postMessage(
                {
                    msg: "showServiceDialog",
                    dialogMessage: i18n.t('app:cantAddTag')
                }
                , '*');
        });
}

function removeTag(folderIds: any[], documentIds: any[], tagId :string,  cascade: boolean = false) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        folderIds: folderIds,
        documentIds: documentIds,
        tagId: tagId,
        cascade: cascade,
    }

    return axios.post(artifactApi + '/tags/remove', requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function removeTagBulk(folderIds: any[], documentIds: any[], tagIds :string[],  cascade: boolean = false) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        folderIds: folderIds,
        documentIds: documentIds,
        tagIds: tagIds,
        cascade: cascade,
    }

    return axios.post(artifactApi + '/tags/bulk/remove', requestOptions, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getFileTags(id: string, isFolder: boolean) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(artifactApi + '/tags/object/' + id + "?isFolder=" + isFolder, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getServerInfo(companyDomain: string) {
    return axios.get('https://synergy-api.common.synergy.page/server-info/public/'+companyDomain)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getServerInfoCompany(companyDomain: string) {
    return axios.get('https://synergy-api.common.synergy.page/server-info/public/company/'+companyDomain)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}