import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { Nav, INavLink } from '@fluentui/react/lib/Nav';
import { Icon } from '@fluentui/react/lib/Icon';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { getFileTypeIconProps, FileIconType } from '@fluentui/react-file-type-icons';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';

import { userService } from '../_services';
import i18n from '../i18n';
import { ChoiceGroup } from '@fluentui/react';


export interface Props {
    repoId: string;
    parentId: string;
    userData: any;
    showTemplatesDialog: boolean;
}

export interface templatesState {
    items: any;
    selItem: any;
    loadingItems: boolean;
    insertingTemplate: boolean;
    insertingTemplateError: boolean;
    inheritedTags: string;
}

export class TemplatesBrowser extends React.Component<Props, templatesState> {
    props: any;
    
    constructor(props: any) {
        super(props);

        this.props = props

        this.state = {
            items: null,
            selItem: null,
            loadingItems: true,
            insertingTemplate: false,
            insertingTemplateError: false,
            inheritedTags: "KEEP_NEW"
        }
    }

    componentDidMount() {
        this.getFolderContent(this.props.repoId)
    }

    public render() {
        const { items, loadingItems, insertingTemplate, insertingTemplateError } = this.state

        return(
            <Dialog
            hidden={!this.props.showTemplatesDialog}
            dialogContentProps={{
                type: DialogType.normal,
                title: <div className='d-flex text-left'><Icon {...getFileTypeIconProps({ type: FileIconType.documentsFolder, size: 32, imageFileType: 'svg' }) } className='mr-2' />Insert template</div>
            }}
            modalProps={{
                isBlocking: false,
                styles: { main: { maxWidth: 640 } },
                dragOptions: undefined,
                className: "templates-dialog"
            }}
            onDismiss={ () => this.props.callbackFunction({showTemplatesDialog: false}) }
            >

                { loadingItems ?
                    <>
                        <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
                        <span>Loading templates, please wait...</span>
                    </>
                : insertingTemplate ?
                    <>
                        <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
                        <span>Inserting template, please wait...</span>
                    </>
                : insertingTemplateError ?
                    <p>There was an error loading the templates. Please, try again.</p>
                : items ?
                    <>
                    <p>Select a folder or file and then click on the insert button</p>

                    <nav className="templatesBrowser">
                        <Nav
                        //   onRenderGroupHeader={ this._onRenderGroupHeader }
                        styles={{
                            root: {
                            width: "fit-content",
                            boxSizing: 'border-box',
                            overflowY: 'auto',
                            overflowX: 'auto'
                            }
                        }}
                        className="fileExplorer"
                        expandButtonAriaLabel="Expand or collapse"
                        ariaLabel="Nav example with nested links"
                        groups={ items }
                        onRenderLink={ this._onRenderLink }
                        onLinkClick={ this._handleLinkClick }
                        onLinkExpandClick={ this._handleExpandLinkClick }
                        />
                    </nav>
                    <ChoiceGroup defaultSelectedKey={!this.props.parentId.includes(this.props.userData.repository.id) ? "KEEP_NEW" : "KEEP_OLD"}
                            options={!this.props.parentId.includes(this.props.userData.repository.id) ?
                            [
                                { key: "KEEP_NEW", text: i18n.t('app:keepNewTags') },
                                { key: "KEEP_OLD", text: i18n.t('app:keepCurrentTags') },
                                { key: "NONE", text: i18n.t('app:noTags') }
                            ]:[
                                { key: "KEEP_OLD", text: i18n.t('app:keepCurrentTags') },
                                { key: "NONE", text: i18n.t('app:noTags') }
                            ]}
                            label={i18n.t('app:tags')+":"}
                            required={true}
                            className="folder-permissions-choice mt-2 text-left"
                            onChange={ (e,v) => {this.setState({inheritedTags: v!.key})} }
                        />

                    <DialogFooter>
                        
                        <DefaultButton onClick={ () => this.props.callbackFunction({showTemplatesDialog: false}) } className="secondaryButton" text="Cancel" /> 
                        
                        <PrimaryButton
                            text="Insert"
                            onClick={() => this.insertTemplate()}
                            disabled={!this.state.selItem}
                            />
                    </DialogFooter>
                    </>
                : null }
            </Dialog>
        )
    }

    private getFolderContent = async(folderId: string) => {
        function searchRecursive(data, id) {
            let found = data.find(d => d.key === id && (d.type === "repo" || d.type === "folder" || d.type === "templates"));
            if (!found) {
              let i = 0;
              while(!found && i < data.length) {
                if (data[i].links && data[i].links.length) {
                  found = searchRecursive(data[i].links, id);
                }
                i++;
              }
            }
            return found;
          }

        return userService.getFolderContent(folderId).then(response => {
    
            var templates = response.data
    
            var folders: any = templates.folders.map(folder => {
                var accessGranted: boolean = false;
                var userGroups = this.props.userData.userGroups.map(userGroup => {
                    return userGroup.id
                })
        
                var isUser = folder.users && folder.users.filter(user => {
                    return user.id === this.props.userData.user.id
                })
        
                var isAdmin = folder.admins && folder.admins.filter(admin => {
                    return admin === this.props.userData.user.id
                })
        
                var groupAccess = folder.admins && folder.groups.filter(group => {
                    return userGroups.includes(group.id)
                })
        
                var isExternal = folder.externals && folder.externals.filter(externalUser => {
                    return externalUser === this.props.userData.user.id
                })
        
                if((isAdmin && isAdmin.length > 0)
                    || (isUser && isUser.length > 0)
                    || (groupAccess && groupAccess.length > 0)
                    || (isExternal && isExternal.length > 0))
                    accessGranted = true
        
                folder.key = folder.id
                folder.type = "folder"
                folder.accessGranted = accessGranted
                folder.links = [{
                    key: folder.id + "-loading",
                    name: "Loading...",
                    type: "loading",
                    disabled: true
                }]
                folder.isExpanded = false;
        
                return folder
            }).sort((a,b) => {
                if(a.name.toLowerCase() < b.name.toLowerCase())
                    return -1;
                if(b.name.toLowerCase() < a.name.toLowerCase())
                    return 1;
        
                return 0;
            });

            var re: any = /(?:\.([^.]+))?$/;

            var files = templates.documents.map(file => {
                file.key = file.id
                file.type = "file"
                file.fileExtension = re.exec(file.name)[1]
        
                return file
            }).sort((a,b) => {
                if(a.name.toLowerCase() < b.name.toLowerCase())
                    return -1;
                if(b.name.toLowerCase() < a.name.toLowerCase())
                    return 1;

                return 0;
            });
            
            if(folderId.endsWith("-templates")) {
                var items: any = [ { links: [] } ]

                if(folders && folders.length > 0) items[0].links = [...items[0].links, ...folders]
                if(files && files.length > 0) items[0].links = [...items[0].links, ...files]

                if(folders && folders.length === 0 && files && files.length === 0) {
                    items[0].links = [{
                        key: folderId + "-empty",
                        name: "Empty",
                        type: "empty",
                        disabled: true
                    }]
                }

                this.setState({
                    loadingItems: false,
                    items: items
                })
            } else {
                var parentFolder = searchRecursive(this.state.items, folderId)

                if(parentFolder) {
                    if(!parentFolder.links || parentFolder.links[0].type === "loading") parentFolder.links = []
                    parentFolder.links = [...parentFolder.links, ...folders]
                    parentFolder.links = [...parentFolder.links, ...files]

                    if(folders && folders.length === 0 && files && files.length === 0) {
                        parentFolder.links = [{
                            key: folderId + "-empty",
                            name: "Empty",
                            type: "empty",
                            disabled: true
                        }]
                    }

                    this.setState({
                        loadingItems: false,
                        items: this.state.items
                    })
                }
            }
        }).catch(error => {
            console.log(error)
            this.setState({
                loadingItems: false
            })
            this.props.callbackFunction({showTemplatesDialog: false})
        })
    }

    private insertTemplate() {
        this.setState({insertingTemplate: true})
                
        var selItem = this.state.selItem
        var destination = this.props.parentId
        var folders: any = []
        var files: any = []
        
        if(selItem.type === "folder") folders = [selItem.id]
        if(selItem.type === "file") files = [selItem.id]

        userService.insertTemplate(folders, files, destination, this.state.inheritedTags).then(response => {
            this.props.callbackFunction({showTemplatesDialog: false})
        }).catch(error => {
            console.log(error)
            this.setState({
                insertingTemplateError: false
            })
        })
    }

    private _handleLinkClick = async (ev: React.MouseEvent<HTMLElement> | undefined, item?: INavLink | undefined) => {
        if(ev) {
            ev.stopPropagation();

            if(item && item.isExpanded) ev.preventDefault()
        }

        if(item && item.links) {
            this.setState({selItem: item})
            if(item.links[0].type === "loading") this.getFolderContent(item.id)
        }
    }

    private _handleExpandLinkClick = async (ev: React.MouseEvent<HTMLElement> | undefined, item?: INavLink | undefined) => {
        // Trigger only on direct click on chevron icon
        var target: any = ev && ev.target;

        if(item && item.links && item.links[0].type === "loading" && target && target.className.indexOf("ms-Nav-chevron") !== -1) {
            this.getFolderContent(item.id)
        }
    }

    private _onRenderLink = (link: any): JSX.Element | null => {
    
        return (
            link.type === "folder" ?
                <div ref={() => link.id} id={link.id} className={"folderLink dir" + (this.state.selItem && this.state.selItem.id === link.id ? " selItem" : "")}>
                    <div className="icon-wrap">
                        <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } className="child-elements" />
                        { !link.accessGranted && link.isFolderInPath ?
                        <Icon iconName="ChevronRightSmall" className="folder-in-path" />
                        : !link.accessGranted && !link.isFolderInPath ?
                        <Icon iconName="lockSolid" className="no-access-folder" />
                        : null }
                    </div>
                    <span className="child-elements">{ link.name}</span>
                </div>
            : link.type === "file" ?
                <div ref={() => link.id} id={link.id} className={"folderLink file" + (this.state.selItem && this.state.selItem.id === link.id ? " selItem" : "")} onClick={ () => this.setState({selItem: link}) }>
                    <div className="icon-wrap">
                        <Icon {...getFileTypeIconProps({ extension: link.fileExtension, size: 32, imageFileType: 'svg' }) } className="child-elements" />
                    </div>
                    <span className="child-elements">{ link.name}</span>
                </div>
            :
                <div className="folderLink link">
                    <span>{ link.name }</span>
                </div>
        )
    }
}
