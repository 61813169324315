import * as React from 'react';
import { history } from '../_helpers';
import { Resizable } from 're-resizable';
import { Nav, INavLink, INavLinkGroup } from '@fluentui/react/lib/Nav';
import { DatePicker, DayOfWeek, DefaultButton, IDatePickerStrings, ThemeProvider, TooltipHost,} from '@fluentui/react';
import { ContextualMenu, ContextualMenuItemType, IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { Icon } from '@fluentui/react/lib/Icon';
import { IconButton, IIconProps } from '@fluentui/react';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import Tree from 'react-virtualized-tree';
import {createSelector} from 'reselect';


import { userService } from '../_services';

import i18n from 'i18next';
import { FilterColumn } from './FilterColumn';

export const getNodeRenderOptions = createSelector(
  (node: any) => (node.state || {}).expanded,
  node => (node.state || {}).favorite,
  node => (node.state || {}).deletable,
  node => node.hasChildren,
  node => node.id,
  node => node.type,
  node => node.hidden,
  (expanded, favorite, deletable, hasChildren, id, type, hidden = []) => ({
    hasChildren: !!hasChildren,
    isExpanded: !!expanded,
    isFavorite: !!favorite,
    isDeletable: !!deletable,
    id: id,
    type: type,
    hidden: !!hidden
  }),
);

export const updateNode = (originalNode, newState: any) =>
  ({
  
  node: {
    ...originalNode,
    state: {
      ...originalNode.state,
      ...newState,
    },
  },
  type: "2" //UPDATE
});

export interface Props {
  match: {
    url: string
  },
  location: any,
  searchType: string,
  userData: any,
  page: string,
  items: any,
  listExplorerRef: any,
}

export interface SidebarState {
  webUpdateAvailable: boolean;
  userData?: any;
  repoData?: any;
  itemTree: any[];
  customViews: any[];
  selItem: any;
  panelHeight: any;
  panelWidth: any;
  panel2Width: any;
  searchFilters: any;
  smartFoldersTree: any;
  tags: any;
  editTagDialog: boolean;
  selTag: any;
  newTag: string;
  selNav: string;
  savingTag: boolean;
  selAction: string;
  minDate: Date;
  maxDate: Date;
  initialDate: Date;
  finalDate: Date;
  goBack: number;
  userName: string;
  settingsMenu: boolean;
  personaMenu: boolean;
  language: string;
  toolbarHeight: number;
  filterColumnOpen: boolean;
  filterName: string;
  searchQuery: string;
}

export class Sidebar extends React.Component<Props, SidebarState, { t:any, i18n: any }> {
  private _isMounted: boolean;
  private _windowWidth: number;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this._windowWidth = window.innerWidth;

    var panelWidth = "240px"
    if (typeof(localStorage.getItem("sidebarPrefs")) === "string") {
      let panelPrefs = JSON.parse(localStorage.getItem("sidebarPrefs") || "[]");
      panelWidth = panelPrefs.width
    }

    var panel2Width = "240px"
    if (typeof(localStorage.getItem("sidebar2Prefs")) === "string") {
      let panelPrefs = JSON.parse(localStorage.getItem("sidebar2Prefs") || "[]");
      panel2Width = panelPrefs.width
    }

    this.state = {
      webUpdateAvailable: false,
      userData: null,
      repoData: null,
      goBack: 0,
      itemTree: [],
      customViews: [],
      selItem: null,
      panelHeight: 'auto',
      panelWidth: panelWidth,
      panel2Width: panel2Width,
      searchFilters: [],
      smartFoldersTree: null,
      tags: null,
      editTagDialog: false,
      selTag: null,
      newTag: '',
      savingTag: false,
      selAction: "allActions",
      minDate: new Date(2020,0,1),
      maxDate: new Date(Date.now()),
      initialDate: new Date(2020,0,1),
      finalDate: new Date(Date.now()),
      selNav: this._getSelNav(),
      userName: "",
      settingsMenu: false,
      personaMenu: false,
      language: i18n.language,
      toolbarHeight: 89,
      filterColumnOpen: false,
      filterName: '',
      searchQuery: '',
    };
  }

  public componentDidMount() {
    this._isMounted = true;

    window.addEventListener("message", this.onMessageReceived.bind(this), false);

    var repo = this.props.userData && this.props.userData.repository;
    var repoData: any = [];
    if(repo) {
      repoData.push({
        id: repo.id,
        name: repo.name,
        role: this.props.userData.user.role,
      })
      
      this._isMounted && this.setState({
        userData: this.props.userData,
        userName: this.props.userData.user.name + " " + this.props.userData.user.surname,
        repoData: repoData
      }, () => {
        if(this.props.page === "projectsExplorer"
        || this.props.page === "clientsExplorer"
        || this.props.page === "milestonesExplorer"
        || this.props.page === "boardExplorer"
        || this.props.page === "pipelinesExplorer") {
          this._getItemTree();
          //this._getCustomViewsTree();
        }
        else if (this.props.page === "projectData") {
          this._getProjectViewsTree();
        }
        else if (this.props.page === "companiesExplorer") {
          this._getCompaniesTree();
        }
        else if (this.props.page === "companyData") {
          this._getCompanyViewsTree();
        }
        else if (this.props.page === "contactsExplorer") {
          this._getContactsTree();
        }
        else if (this.props.page === "contactData") {
          this._getContactViewsTree();
        }
        else if (this.props.page === "home") {
          this._getHomeTree();
        }
        else if (this.props.page === "pipelines") {
          this._getPipelinesTree();
        }
        else if (this.props.page === "tasksExplorer") {
          this._getTasksTree();
        }
        else if (this.props.page === "timeTracker" || this.props.page === "timeSheet") {
          this._getTimeTrackingTree();
        }

        //this._getSearchFilters()
      })
    }
    
      const self = this;
      if (window.innerWidth <= 980) {
        self.toggleNav("")
      }
      window.addEventListener('resize', function() {
        var toolbar : HTMLDivElement | null  = document.querySelector('.toolbar');
        self._isMounted && self.setState({toolbarHeight: toolbar!.offsetHeight})
        if (window.innerWidth <= 980 && window.innerWidth < self._windowWidth) {
          self.toggleNav("")
        }
        self._windowWidth = window.innerWidth;
      })
    
  }
  
  public componentDidUpdate(prevProps:any, prevState:any) {
    if (this.state.language !== i18n.language) {
      this._isMounted && this.setState({language: i18n.language});
    }

    if(this.props.page !== prevProps.page || this.props.userData !== prevProps.userData || prevState.searchQuery !== document.location.search) {

      var repo = this.props.userData && this.props.userData.repository;
      var repoData: any = [];
      if(repo) {
        repoData.push({
          id: repo.id,
          name: repo.name,
          role: this.props.userData.user.role,
        })
        
        this._isMounted && this.setState({
          userData: this.props.userData,
          userName: this.props.userData.user.name + " " + this.props.userData.user.surname,
          repoData: repoData,
          searchQuery: document.location.search
        }, () => {
          if(this.props.page === "projectsExplorer"
          || this.props.page === "clientsExplorer"
          || this.props.page === "milestonesExplorer"
          || this.props.page === "boardExplorer"
          || this.props.page === "pipelinesExplorer") {
            this._getItemTree();
            //this._getCustomViewsTree();
          }
          else if (this.props.page === "projectData") {
            this._getProjectViewsTree();
          }
          else if (this.props.page === "companiesExplorer") {
            this._getCompaniesTree();
          }
          else if (this.props.page === "companyData") {
            this._getCompanyViewsTree();
          }
          else if (this.props.page === "contactsExplorer") {
            this._getContactsTree();
          }
          else if (this.props.page === "contactData") {
            this._getContactViewsTree();
          }
          else if (this.props.page === "home") {
            this._getHomeTree();
          }
          else if (this.props.page === "pipelines") {
            this._getPipelinesTree();
          }
          else if (this.props.page === "tasksExplorer") {
            this._getTasksTree();
          }
          else if (this.props.page === "timeTracker" || this.props.page === "timeSheet") {
            this._getTimeTrackingTree();
          }

          //this._getSearchFilters()
        })
      }
    }

    if(this.props.folderId !== prevProps.folderId || this.props.page !== prevProps.page) {
      this._isMounted && this.setState({language: i18n.language})
    }


  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  onMessageReceived(event:any) {
    var data:any = event.data;

      if (data && data.msg === "webUpdateAvailable") {
        this._isMounted && this.setState({webUpdateAvailable: true})
      }
  }
  
  private _goBack() {
    history.back()
  }

  private _goBackToList() {
    if(this.state.selNav === "projects") history.push("/projects/list")
    if(this.state.selNav === "contacts") history.push("/contacts")
    if(this.state.selNav === "companies") history.push("/companies")
  }

  public render() {
    const { selNav, itemTree, customViews, selItem, searchFilters, minDate, maxDate, initialDate, finalDate } = this.state;
    const helpIcon: IIconProps = { iconName: 'Help' };
    const settingsIcon: IIconProps = { iconName: 'Settings' };

    const self = this;
    function toggleSettingsMenu(){
      self._isMounted && self.setState({settingsMenu: !self.state.settingsMenu})
    }
    function togglePersonaMenu(){
      self._isMounted && self.setState({personaMenu: !self.state.personaMenu})
    }

    let navLinks:any = document.getElementsByClassName('folderLink')
    for (let i=0; i<navLinks.length; i++) {
      let link:any = navLinks[i]
      let grandParent = link.parentElement.parentElement;
      if (link.className.includes('selItem')) {
        grandParent.style.background = '#bfbfbf';
      } else {
        grandParent.style.background = 'none';
      }
    }

    let navLinks2:any = document.getElementsByClassName('link')
    for (let i=0; i<navLinks2.length; i++) {
      let link:any = navLinks2[i]
      let grandParent = link.parentElement.parentElement;
      if (link.className.includes('selItem')) {
        grandParent.style.background = '#bfbfbf';
      } else {
        grandParent.style.background = 'none';
      }
    }

    const DayPickerStrings: IDatePickerStrings = {
      months: [
        i18n.t('app:datePicker.months', { returnObjects: true })[0],
        i18n.t('app:datePicker.months', { returnObjects: true })[1],
        i18n.t('app:datePicker.months', { returnObjects: true })[2],
        i18n.t('app:datePicker.months', { returnObjects: true })[3],
        i18n.t('app:datePicker.months', { returnObjects: true })[4],
        i18n.t('app:datePicker.months', { returnObjects: true })[5],
        i18n.t('app:datePicker.months', { returnObjects: true })[6],
        i18n.t('app:datePicker.months', { returnObjects: true })[7],
        i18n.t('app:datePicker.months', { returnObjects: true })[8],
        i18n.t('app:datePicker.months', { returnObjects: true })[9],
        i18n.t('app:datePicker.months', { returnObjects: true })[10],
        i18n.t('app:datePicker.months', { returnObjects: true })[11],
      ],
      
      shortMonths: [i18n.t('app:datePicker.shortMonths', { returnObjects: true })[0], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[1], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[2], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[3], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[4], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[5], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[6], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[7], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[8], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[9], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[10], i18n.t('app:datePicker.shortMonths', { returnObjects: true })[11]],
    
      days: [i18n.t('app:datePicker.days', { returnObjects: true })[0], i18n.t('app:datePicker.days', { returnObjects: true })[1], i18n.t('app:datePicker.days', { returnObjects: true })[2], i18n.t('app:datePicker.days', { returnObjects: true })[3], i18n.t('app:datePicker.days', { returnObjects: true })[4], i18n.t('app:datePicker.days', { returnObjects: true })[5], i18n.t('app:datePicker.days', { returnObjects: true })[6]],
    
      shortDays: [i18n.t('app:datePicker.shortDays', { returnObjects: true })[0], i18n.t('app:datePicker.shortDays', { returnObjects: true })[1], i18n.t('app:datePicker.shortDays', { returnObjects: true })[2], i18n.t('app:datePicker.shortDays', { returnObjects: true })[3], i18n.t('app:datePicker.shortDays', { returnObjects: true })[4], i18n.t('app:datePicker.shortDays', { returnObjects: true })[5], i18n.t('app:datePicker.shortDays', { returnObjects: true })[6]],
    
      goToToday: i18n.t('app:datePicker.goToToday'),
      prevMonthAriaLabel: 'Go to previous month',
      nextMonthAriaLabel: 'Go to next month',
      prevYearAriaLabel: 'Go to previous year',
      nextYearAriaLabel: 'Go to next year',
      closeButtonAriaLabel: 'Close date picker',
      monthPickerHeaderAriaLabel: '{0}, select to change the year',
      yearPickerHeaderAriaLabel: '{0}, select to change the month',
    };

    const settingsItems: IContextualMenuItem[] = [
      {
        key: 'webUpdateAvailable',
        text: i18n.t('app:applyUpdateNow'),
        iconProps: { iconName: 'Important' },
        onClick: () => {this._applyWebUpdate()},
        className: 'important',
        hidden: !this.state.webUpdateAvailable
      },
      {
        key: 'about',
        text: i18n.t('app:aboutSynergyDrive'),
        iconProps: { iconName: 'Info' },
        //onClick: () => {this._getAppInfo()},
      },
      {
        key: 'language',
        text: i18n.t('app:language'),
        iconProps: { iconName: 'LocaleLanguage' },
        subMenuProps: {
          items: [
            {
              key: 'de',
              text: 'Deutsch',
              onClick: () => {i18n.changeLanguage('de')},
            },
            {
              key: 'en',
              text: 'English',
              onClick: () => {i18n.changeLanguage('en')},
            },
            {
              key: 'es',
              text: 'Español',
              onClick: () => {i18n.changeLanguage('es')},
            },
          ],
        },
        id: 'language-button'
      }
    ];

    const personaItems: IContextualMenuItem[] = [
      {
        itemType: ContextualMenuItemType.Header,
        key: 'userName',
        text: this.state.userName,
        className: "userName"
      },
      {
        itemType: ContextualMenuItemType.Header,
        key: 'userEmail',
        text: this.props.userData && this.props.userData.user.email,
        className: "userEmail"
      },
      {
        key: 'userSettings',
        text: i18n.t('app:account'),
        iconProps: { iconName: 'Contact' },
        onClick: () => {this._goToSettings()},
      },
      {
        key: 'singOut',
        text: i18n.t('app:signOut'),
        iconProps: { iconName: 'signOut' },
        onClick: () => userService.logout(),
      }
    ];

    const treeLine = (style,index,node,isExpanded,hasChildren,handleChange) => {
      let selected = false;
      if (node.type === 'view-projects') selected = this.props.page === node.page && document.location.search === node.search ? true : false;
      else if (node.type === 'view-custom') selected = this.checkSelected();
      else if (node.type === 'projectsBoard') selected = this.props.page === 'boardExplorer';
      else if (node.type === 'projectsMilestone') selected = this.props.page === 'milestonesExplorer';
      else if (node.type === 'projectsCompany') selected = this.props.page === 'clientsExplorer';
      else if (node.type === 'projectsPipeline') selected = this.props.page === 'pipelinesExplorer';
      else if (node.type === 'projectsNothing') selected = this.props.page === 'projectsExplorer';
      else if (node.type === 'projectsList') selected = this.props.page === "projectsExplorer"
        || this.props.page === "clientsExplorer"
        || this.props.page === "milestonesExplorer"
        || this.props.page === "pipelinesExplorer"
        || this.props.page === "tasksExplorer";
      else if (node.type === 'pipelinesList') selected =  this.props.pipelineId === 'all'
      else if (node.type === 'pipelines') selected =  this.props.pipelineId === node.name
      else if (node.type === 'view-project') selected = document.location.search === node.search || ((!document.location.search || document.location.search === '?type=All') && node.search === '');
      //else if (node.type) selected = this.checkSelFilter(node, node.type.replace('tag','').toLowerCase());

      return (
        <>
          <li className={"ms-Nav-navItem " + (selected ? 'bg-selected' : '')} style={style} tabIndex={index} onClick={(ev) => { (node.type !== 'category' && !node.type.includes('tag')) && this._goToPage(ev,node); if(!isExpanded) handleChange()}}>
            <div className={ "ms-Nav-compositeLink"} >
          
              <button className="chevronButton">
                { hasChildren && !isExpanded?
                  <Icon iconName="ChevronRight"  onClick={(e) => {e.stopPropagation(); handleChange();
                  }} className="ms-Nav-chevron d-inline-block" />
                : hasChildren && isExpanded?
                  <Icon iconName="ChevronDown"  onClick={(e) => {e.stopPropagation();handleChange()}} className="ms-Nav-chevron d-inline-block" />
                :
                  <div className="noChevron"></div>
                }
              </button>
              
              <button className="ms-Button ms-Button--action ms-Button--command ms-Nav-link pl-0">
                { this._onRenderLink(node) }
              </button>
              {node.type === 'view-custom' && this.checkSelected() &&
                <TooltipHost key='view-custom-tt' styles={{root:{position:'fixed', right: 0, marginRight:'10px',  height: '24px'}}} content={'Save custom view'} setAriaDescribedBy={false}>
                  <button className='custom-view ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon style={{height: '20px', top: '-2px', position: 'relative'}} iconName='Save'/></button>
                </TooltipHost>
              }
              {node.type === 'category' &&
                <TooltipHost key={'category-tt-edit-'+node.id} styles={{root:{position:'fixed', right: 0, marginRight:'36px',  height: '24px'}}} content={'Edit'} setAriaDescribedBy={false}>
                  <button onClick={(ev)=>{this._goToPage(ev,node)}} className='filter-category ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon style={{height: '20px', top: '-2px', position: 'relative'}} iconName='Edit'/></button>
                </TooltipHost>
              }
              {node.type === 'category' &&
                <TooltipHost key={'category-tt-remove-'+node.id} styles={{root:{position:'fixed', right: 0, marginRight:'10px',  height: '24px'}}} content={'Remove'} setAriaDescribedBy={false}>
                  <button onClick={(ev)=>{this.removeFilterCategory(node.field)}} className='filter-category ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon style={{height: '20px', top: '-2px', position: 'relative'}} iconName='Delete'/></button>
                </TooltipHost>
              }
              {node.type === 'filtersHeader' &&
                <TooltipHost key={'filtersHeader-tt-remove-'+node.id} styles={{root:{position:'fixed', right: 0, marginRight:'10px',  height: '24px'}}} content={'Remove'} setAriaDescribedBy={false}>
                  <button onClick={(ev)=>{history.push('')}} className='filter-category ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon style={{height: '20px', top: '-2px', position: 'relative'}} iconName='Delete'/></button>
                </TooltipHost>
              }
              {node.type.includes('tag') &&
                <TooltipHost key={'tag-tt-edit-'+node.id} styles={{root:{position:'fixed', right: 0, marginRight:'36px',  height: '24px'}}} content={'Edit'} setAriaDescribedBy={false}>
                  <button onClick={(ev)=>{this._goToPage(ev,node)}} className='filter-category ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon style={{height: '20px', top: '-2px', position: 'relative'}} iconName='Edit'/></button>
                </TooltipHost>
              }
              {node.type.includes('tag') &&
                <TooltipHost key={'tag-tt-remove-'+node.id} styles={{root:{position:'fixed', right: 0, marginRight:'10px',  height: '24px'}}} content={'Remove'} setAriaDescribedBy={false}>
                  <button onClick={(ev)=>{this.removeFilter(node)}} className='filter-category ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon style={{height: '20px', top: '-2px', position: 'relative'}} iconName='Delete'/></button>
                </TooltipHost>
              }
            </div>
          </li>
        </>
      )
    }

    return(
      <ThemeProvider>
        
        <div id="main-nav" className=" d-flex flex-row">
          <nav className="main">
              <Nav
                expandButtonAriaLabel="Expand or collapse"
                ariaLabel="Main navigation"
                initialSelectedKey={ selNav }
                styles={{
                  root: {
                    width: 48,
                    boxSizing: 'border-box',
                    overflowY: 'auto'
                  }
                }}
                groups={[
                  {
                    links: [
                      {
                        name: '',
                        url: '',
                        icon: 'Home',
                        key: 'home',
                        onClick: this._goToHome.bind(this),
                        ariaLabel: 'Home',
                        title: i18n.t('Home'),
                        //disabled: !this.props.isOnline || this.props.maintenance
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'FabricFolder',
                        key: 'files',
                        onClick: this._goToFiles.bind(this),
                        ariaLabel: 'File Explorer',
                        title: i18n.t('app:fileExplorer'),
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'Search',
                        key: 'search',
                        onClick: this._goToSearch.bind(this),
                        ariaLabel: "Search",
                        title: i18n.t('app:search'),
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'Work',
                        key: 'projects',
                        onClick: this._goToProjects.bind(this),
                        ariaLabel: 'Projects',
                        title: i18n.t('app:projects'),
                        //disabled: !this.props.isOnline || this.props.maintenance
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'TaskLogo',
                        key: 'tasks',
                        onClick: this._goToTasks.bind(this),
                        ariaLabel: 'Tasks',
                        title: i18n.t('app:tasks'),
                        //disabled: !this.props.isOnline || this.props.maintenance
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'Clock',
                        key: 'timeTracking',
                        onClick: this._goToTimeTracking.bind(this),
                        ariaLabel: 'Time tracking',
                        title: 'Time tracking',
                        //disabled: !this.props.isOnline || this.props.maintenance
                      }
                    ]
                  }
                ]}
              />
              <div className="fixed-bottom">
              <IconButton
                  iconProps={helpIcon}
                  styles={{
                    root: {
                      width: 48,
                      boxSizing: 'border-box',
                      overflowY: 'auto'
                    }
                  }}
                  title={i18n.t('app:help')}
                  ariaLabel="Help"
                  onClick={ () => window.postMessage("supportToggle", '*') }
                />
                <div className="settings-wrap">
                  <IconButton
                    id="settings-button"
                    iconProps={settingsIcon}
                    styles={{
                      root: {
                        width: 48,
                        boxSizing: 'border-box',
                        overflowY: 'auto'
                      }
                    }}
                    title={i18n.t('app:settings')}
                    ariaLabel="Settings"
                    onClick={toggleSettingsMenu}
                  />
                  { this.state.webUpdateAvailable ?
                    <Icon iconName="CircleFill" className="webUpdateAvailable" />
                  : null }
                </div>
                <ContextualMenu
                  items={settingsItems}
                  hidden={!this.state.settingsMenu}
                  target={`#${"settings-button"}`}
                  onItemClick={()=> this._isMounted && this.setState({settingsMenu: true})}
                  onDismiss={()=> this._isMounted && this.setState({settingsMenu: false})}
                  className="contextualSettingsMenu"
                />
                <div className="persona-wrap">
                  <Persona 
                    id="persona-button" 
                    styles={{
                      root: {
                        width: 48,
                        height: 48,
                        boxSizing: 'border-box',
                        overflowY: 'auto',
                        padding: '0 8px'
                      }
                    }}
                    text={this.state.userName} 
                    size={PersonaSize.size32} 
                    hidePersonaDetails={true}
                    className="persona"
                    onClick={togglePersonaMenu}
                    initialsColor="#7F7F7F"  
                  />
                </div>
                <ContextualMenu
                  items={personaItems}
                  hidden={!this.state.personaMenu}
                  target={`#${"persona-button"}`}
                  onItemClick={()=> this._isMounted && this.setState({personaMenu: true})}
                  onDismiss={()=> this._isMounted && this.setState({personaMenu: false})}
                  className="contextualUserMenu"
                />
              </div>
          </nav>
          { selNav === "projects" && this.props.page !== 'projectData' ?
            <>
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this._isMounted && this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                });
                this._saveResizeState();
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={240}
              maxWidth={480}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree">
                <p className="title mb-0">{i18n.t('app:projects')}</p>
                <ul className="ms-Nav-navItems list-page h-100 mx-0 mb-0 pl-0">
                <Tree
                  nodes={itemTree}
                  onChange={this.handleNodesChange}>
                  {({onChange, style, node, index, ...rest}) => {

                    const {hasChildren, isExpanded, type} = getNodeRenderOptions(node);
                    const handleChange = () =>{
                      onChange({...updateNode(node, {expanded: !isExpanded}) })
                    }
                    if(typeof(style.marginLeft) === "number") style.paddingLeft = style.marginLeft / 2
                    if(typeof(style.marginLeft) === "number") style.marginLeft = 0
                    let item:any = node;
                    return(item.type === 'separator' ? <>
                      <li className="ms-Nav-navItem" style={{...style, background: 'transparent', cursor: 'default'}} tabIndex={index}>
                          <hr/>
                      </li>
                    </> 
                    : treeLine(style,index,node,isExpanded,hasChildren,handleChange))}
                  }
                </Tree>
                </ul>
                {/*<hr style={{margin: '8px 0px 12px'}}/>
                <Nav
                  onRenderGroupHeader={ this._onRenderGroupHeader }
                  styles={{
                    root: {
                      width: "fit-content",
                      boxSizing: 'border-box',
                      overflowY: 'auto',
                      overflowX: 'auto'
                    }
                  }}
                  className="fileExplorer"
                  initialSelectedKey={ selItem }
                  expandButtonAriaLabel="Expand or collapse"
                  ariaLabel="Nav example with nested links"
                  groups={ customViews }
                  onRenderLink={ this._onRenderLink }
                  onLinkClick={ this._goToPage.bind(this) }
                  //onLinkExpandClick={ this._handleExpandLinkClick.bind(this) }
                />
                <hr style={{margin: '8px 0px 12px'}}/>
                <div id="nav-tags">
                  <Nav
                    styles={{
                      root: {
                        width: "fit-content",
                        boxSizing: 'border-box',
                        overflowY: 'auto',
                        overflowX: 'auto'
                      }
                    }}
                    className="tagFilter mt-0"
                    expandButtonAriaLabel="Expand or collapse"
                    ariaLabel="Nav example with nested links"
                    groups={ searchFilters }
                    onRenderLink={ this._onRenderLink }
                    onLinkClick={ this._goToPage.bind(this) }
                  />
                </div>
                <DatePicker
                  className="m-3"
                  firstDayOfWeek={DayOfWeek.Sunday}
                  strings={DayPickerStrings}
                  placeholder={i18n.t('app:Date Created')}
                  ariaLabel="Select start date"
                  allowTextInput={true}
                  minDate={minDate}
                  maxDate={maxDate}
                  initialPickerDate={initialDate}
                  value={initialDate}
                  onSelectDate={this._dateCreated}
                  label={'Date created'}
                />
                <DatePicker
                  className="m-3"
                  firstDayOfWeek={DayOfWeek.Sunday}
                  strings={DayPickerStrings}
                  placeholder={i18n.t('app:selectEndDate')}
                  ariaLabel="Select start date"
                  allowTextInput={true}
                  minDate={initialDate}
                  maxDate={maxDate}
                  initialPickerDate={finalDate}
                  value={finalDate}
                  onSelectDate={this._lastActivity}
                  label={'Last activity'}
                />*/}
              </nav>
            </Resizable>
            {this.state.filterColumnOpen && <FilterColumn type={this.state.filterName} save={(type,filters,checkbox)=>this._addFiltersToTree(type,filters,checkbox)} callback={(value)=>{this._isMounted && this.setState({filterColumnOpen: value}) }}/>}
            </>
          : selNav === "projects" && this.props.page === 'projectData' ?
          <>
          <Resizable
            size={{ width: this.state.panel2Width, height: this.state.panelHeight }}
            onResizeStop={(e, direction, ref, d) => {
              var currentWidth = this.state.panel2Width.match(/\d+/)[0];
              this._isMounted && this.setState({
                panel2Width: +currentWidth + +d.width + "px"
              });
              this._saveResizeState2();
            }}
            enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
            minWidth={240}
            maxWidth={480}
            handleStyles= {{
              right: {
                "right": "-6px",
                "width": "6px",
                "zIndex": 1
              }
            }}
            handleClasses={{"right": "resize-handle"}}
          >
            <nav className="tree">
              
              <DefaultButton className="d-flex align-items-center my-2 p-1 hover-button" onClick={ this._goBackToList.bind(this) }  >
                <Icon className='ml-1 mr-2 pr-1' iconName='Back'/>
                <span className="title mb-0">{i18n.t('app:projects')}</span>
              </DefaultButton>
              <div className='align-items-center my-2' style={{padding: '4px 12px 0px 12px'}}>
                <div>
                  <div className='d-flex align-items-center ml-4 pb-3'>
                    <div className='mr-2' style={{background: 'white', borderRadius: '24px', height: '24px', width: '24px'}}>
                      <img className='d-none m-auto' alt="Contact" src={ process.env.PUBLIC_URL + "/img/icon_synergy.png"} style={{width: '100%'}} />
                    </div>
                    <span className="m-0 pt-0" style={{fontSize: '14px', fontWeight: 600}}>{this.props.item.name}</span>
                  </div>
                  {this.getStagesArrows(this.props.item)}
                </div>
              </div>
              <hr style={{margin: '10px 0px 16px'}}/>
              <ul className="ms-Nav-navItems h-100 m-0 pl-0">
                <Tree
                  nodes={itemTree}
                  onChange={this.handleNodesChange}>
                  {({onChange, style, node, index, ...rest}) => {

                    const {hasChildren, isExpanded, type} = getNodeRenderOptions(node);
                    const handleChange = () =>{
                      onChange({...updateNode(node, {expanded: !isExpanded}) })
                    }
                    if(typeof(style.marginLeft) === "number") style.paddingLeft = style.marginLeft / 2
                    if(typeof(style.marginLeft) === "number") style.marginLeft = 0
                    let item:any = node;
                    return(item.type === 'separator' ? <>
                      <li className="ms-Nav-navItem" style={{...style, background: 'transparent', cursor: 'default'}} tabIndex={index}>
                          <hr/>
                      </li>
                    </> 
                    : treeLine(style,index,node,isExpanded,hasChildren,handleChange))}
                  }
                </Tree>
              </ul>
            </nav>
          </Resizable>
          
          </>
          : selNav === "companies" && this.props.page !== 'companyData' ?
            <>
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this._isMounted && this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                });
                this._saveResizeState();
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={240}
              maxWidth={480}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree">
                <p className="title mb-0">{i18n.t('app:companies')}</p>
                <ul className="ms-Nav-navItems list-page h-100 m-0 pl-0">
                <Tree
                  nodes={itemTree}
                  onChange={this.handleNodesChange}>
                  {({onChange, style, node, index, ...rest}) => {

                    const {hasChildren, isExpanded, type} = getNodeRenderOptions(node);
                    const handleChange = () =>{
                      onChange({...updateNode(node, {expanded: !isExpanded}) })
                    }
                    if(typeof(style.marginLeft) === "number") style.paddingLeft = style.marginLeft / 2
                    if(typeof(style.marginLeft) === "number") style.marginLeft = 0
                    let item:any = node;
                    return(item.type === 'separator' ? <>
                      <li className="ms-Nav-navItem" style={{...style, background: 'transparent', cursor: 'default'}} tabIndex={index}>
                          <hr/>
                      </li>
                    </> 
                    : treeLine(style,index,node,isExpanded,hasChildren,handleChange))}
                  }
                </Tree>
              </ul>
              </nav>
            </Resizable>
            {/*<FormFilter items={this.props.items}/>*/}
            </>
          : selNav === "companies" && this.props.page === 'companyData' ?
          <>
          <Resizable
            size={{ width: this.state.panel2Width, height: this.state.panelHeight }}
            onResizeStop={(e, direction, ref, d) => {
              var currentWidth = this.state.panel2Width.match(/\d+/)[0];
              this._isMounted && this.setState({
                panel2Width: +currentWidth + +d.width + "px"
              });
              this._saveResizeState2();
            }}
            enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
            minWidth={240}
            maxWidth={480}
            handleStyles= {{
              right: {
                "right": "-6px",
                "width": "6px",
                "zIndex": 1
              }
            }}
            handleClasses={{"right": "resize-handle"}}
          >
            <nav className="tree">
              <DefaultButton className="d-flex align-items-center my-2 p-1 hover-button" onClick={ this._goBackToList.bind(this) }  >
                <Icon className='ml-1 mr-2 pr-1' iconName='Back'/>
                <span className="title mb-0">{i18n.t('app:companies')}</span>
              </DefaultButton>
              <div className='d-flex align-items-center mb-2' style={{padding: '0px 12px'}}>
                <div className='mr-2' style={{background: 'white', height: '24px', minWidth: '24px'}}>
                  <img className='d-none m-auto' alt="Company logo" src={ process.env.PUBLIC_URL + "/img/icon_synergy.png"} style={{width: '100%'}} />
                </div>
                <div>
                  <p className="m-0 p-0" style={{fontSize: '14px', fontWeight: 600}}>{this.props.item['Company name']}</p>
                </div>
              </div>
              <a className="m-0 pl-1 ml-2" href={'https://'+this.props.item['Company Domain Name']} style={{fontSize: '12px', fontWeight: 400, color: 'grey'}}>{this.props.item['Company Domain Name']}</a>
              <ul className="ms-Nav-navItems h-100 m-0 pl-0">
                <Tree
                  nodes={itemTree}
                  onChange={this.handleNodesChange}>
                  {({onChange, style, node, index, ...rest}) => {

                    const {hasChildren, isExpanded, type} = getNodeRenderOptions(node);
                    const handleChange = () =>{
                      onChange({...updateNode(node, {expanded: !isExpanded}) })
                    }
                    if(typeof(style.marginLeft) === "number") style.paddingLeft = style.marginLeft / 2
                    if(typeof(style.marginLeft) === "number") style.marginLeft = 0
                    let item:any = node;
                    return(item.type === 'separator' ? <>
                      <li className="ms-Nav-navItem" style={{...style, background: 'transparent', cursor: 'default'}} tabIndex={index}>
                          <hr/>
                      </li>
                    </> 
                    : treeLine(style,index,node,isExpanded,hasChildren,handleChange))}
                  }
                </Tree>
              </ul>
            </nav>
          </Resizable>
          </>
          : selNav === "contacts" && this.props.page !== 'contactData' ?
          <>
          <Resizable
            size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
            onResizeStop={(e, direction, ref, d) => {
              var currentWidth = this.state.panelWidth.match(/\d+/)[0];
              this._isMounted && this.setState({
                panelWidth: +currentWidth + +d.width + "px"
              });
              this._saveResizeState();
            }}
            enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
            minWidth={240}
            maxWidth={480}
            handleStyles= {{
              right: {
                "right": "-6px",
                "width": "6px",
                "zIndex": 1
              }
            }}
            handleClasses={{"right": "resize-handle"}}
          >
            <nav className="tree">
              <p className="title mb-0">{i18n.t('app:contacts')}</p>
              <ul className="ms-Nav-navItems list-page h-100 m-0 pl-0">
                <Tree
                  nodes={itemTree}
                  onChange={this.handleNodesChange}>
                  {({onChange, style, node, index, ...rest}) => {

                    const {hasChildren, isExpanded, type} = getNodeRenderOptions(node);
                    const handleChange = () =>{
                      onChange({...updateNode(node, {expanded: !isExpanded}) })
                    }
                    if(typeof(style.marginLeft) === "number") style.paddingLeft = style.marginLeft / 2
                    if(typeof(style.marginLeft) === "number") style.marginLeft = 0
                    let item:any = node;
                    return(item.type === 'separator' ? <>
                      <li className="ms-Nav-navItem" style={{...style, background: 'transparent', cursor: 'default'}} tabIndex={index}>
                          <hr/>
                      </li>
                    </> 
                    : treeLine(style,index,node,isExpanded,hasChildren,handleChange))}
                  }
                </Tree>
              </ul>
            </nav>
          </Resizable>
          {/*<FormFilter items={this.props.items}/>*/}
          </>
          : selNav === "contacts" && this.props.page === 'contactData' ?
          <>
          <Resizable
            size={{ width: this.state.panel2Width, height: this.state.panelHeight }}
            onResizeStop={(e, direction, ref, d) => {
              var currentWidth = this.state.panel2Width.match(/\d+/)[0];
              this._isMounted && this.setState({
                panel2Width: +currentWidth + +d.width + "px"
              });
              this._saveResizeState2();
            }}
            enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
            minWidth={240}
            maxWidth={480}
            handleStyles= {{
              right: {
                "right": "-6px",
                "width": "6px",
                "zIndex": 1
              }
            }}
            handleClasses={{"right": "resize-handle"}}
          >
            <nav className="tree">
              <DefaultButton className="d-flex align-items-center my-2 p-1 hover-button" onClick={ this._goBackToList.bind(this) }  >
                <Icon className='ml-1 mr-2 pr-1' iconName='Back'/>
                <span className="title mb-0">{i18n.t('app:contacts')}</span>
              </DefaultButton>
              <div className='d-flex align-items-center mb-2' style={{padding: '0px 12px'}}>
                <div className='mr-2' style={{background: 'white', borderRadius: '24px', height: '24px', minWidth: '24px'}}>
                  <img className='d-none m-auto' alt="Contact" src={ process.env.PUBLIC_URL + "/img/icon_synergy.png"} style={{width: '100%'}} />
                </div>
                <div>
                  <p className="m-0 p-0" style={{fontSize: '14px', fontWeight: 600}}>{this.props.item['First Name'] +" "+this.props.item['Last Name']}</p>
                </div>
              </div>
              <a className="m-0 ml-2 pl-1" href={'mailto:'+ this.props.item['Email']} style={{fontSize: '12px', fontWeight: 400, color: 'grey'}}>{this.props.item['Email']}</a>
              <ul className="ms-Nav-navItems h-100 m-0 pl-0">
                <Tree
                  nodes={itemTree}
                  onChange={this.handleNodesChange}>
                  {({onChange, style, node, index, ...rest}) => {

                    const {hasChildren, isExpanded, type} = getNodeRenderOptions(node);
                    const handleChange = () =>{
                      onChange({...updateNode(node, {expanded: !isExpanded}) })
                    }
                    if(typeof(style.marginLeft) === "number") style.paddingLeft = style.marginLeft / 2
                    if(typeof(style.marginLeft) === "number") style.marginLeft = 0
                    let item:any = node;
                    return(item.type === 'separator' ? <>
                      <li className="ms-Nav-navItem" style={{...style, background: 'transparent', cursor: 'default'}} tabIndex={index}>
                          <hr/>
                      </li>
                    </> 
                    : treeLine(style,index,node,isExpanded,hasChildren,handleChange))}
                  }
                </Tree>
              </ul>
            </nav>
          </Resizable>
          </>
          : selNav === "pipelines" ?
          <>
          <Resizable
            size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
            onResizeStop={(e, direction, ref, d) => {
              var currentWidth = this.state.panelWidth.match(/\d+/)[0];
              this._isMounted && this.setState({
                panelWidth: +currentWidth + +d.width + "px"
              });
              this._saveResizeState();
            }}
            enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
            minWidth={240}
            maxWidth={480}
            handleStyles= {{
              right: {
                "right": "-6px",
                "width": "6px",
                "zIndex": 1
              }
            }}
            handleClasses={{"right": "resize-handle"}}
          >
            <nav className="tree">
              <p className="title mb-0">{i18n.t('app:Pipelines')}</p>
              <ul className="ms-Nav-navItems list-page h-100 m-0 pl-0">
                <Tree
                  nodes={itemTree}
                  onChange={this.handleNodesChange}>
                  {({onChange, style, node, index, ...rest}) => {

                    const {hasChildren, isExpanded, type} = getNodeRenderOptions(node);
                    const handleChange = () =>{
                      onChange({...updateNode(node, {expanded: !isExpanded}) })
                    }
                    if(typeof(style.marginLeft) === "number") style.paddingLeft = style.marginLeft / 2
                    if(typeof(style.marginLeft) === "number") style.marginLeft = 0
                    let item:any = node;
                    return(item.type === 'separator' ? <>
                      <li className="ms-Nav-navItem" style={{...style, background: 'transparent', cursor: 'default'}} tabIndex={index}>
                          <hr/>
                      </li>
                    </> 
                    : treeLine(style,index,node,isExpanded,hasChildren,handleChange))}
                  }
                </Tree>
              </ul>
            </nav>
          </Resizable>
          {/*<FormFilter items={this.props.items}/>*/}
          </>
          : selNav === "home" ?
          <>
          <Resizable
            size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
            onResizeStop={(e, direction, ref, d) => {
              var currentWidth = this.state.panelWidth.match(/\d+/)[0];
              this._isMounted && this.setState({
                panelWidth: +currentWidth + +d.width + "px"
              });
              this._saveResizeState();
            }}
            enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
            minWidth={240}
            maxWidth={480}
            handleStyles= {{
              right: {
                "right": "-6px",
                "width": "6px",
                "zIndex": 1
              }
            }}
            handleClasses={{"right": "resize-handle"}}
          >
            <nav className="tree">
              <p className="title mb-0">{i18n.t('app:home')}</p>
              <ul className="ms-Nav-navItems list-page h-100 m-0 pl-0">
                <Tree
                  nodes={itemTree}
                  onChange={this.handleNodesChange}>
                  {({onChange, style, node, index, ...rest}) => {

                    const {hasChildren, isExpanded, type} = getNodeRenderOptions(node);
                    const handleChange = () =>{
                      onChange({...updateNode(node, {expanded: !isExpanded}) })
                    }
                    if(typeof(style.marginLeft) === "number") style.paddingLeft = style.marginLeft / 2
                    if(typeof(style.marginLeft) === "number") style.marginLeft = 0
                    let item:any = node;
                    return(item.type === 'separator' ? <>
                      <li className="ms-Nav-navItem" style={{...style, background: 'transparent', cursor: 'default'}} tabIndex={index}>
                          <hr/>
                      </li>
                    </> 
                    : treeLine(style,index,node,isExpanded,hasChildren,handleChange))}
                  }
                </Tree>
              </ul>
            </nav>
          </Resizable>
          {/*<FormFilter items={this.props.items}/>*/}
          </>
          : selNav === "tasks" ?
          <>
          <Resizable
            size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
            onResizeStop={(e, direction, ref, d) => {
              var currentWidth = this.state.panelWidth.match(/\d+/)[0];
              this._isMounted && this.setState({
                panelWidth: +currentWidth + +d.width + "px"
              });
              this._saveResizeState();
            }}
            enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
            minWidth={240}
            maxWidth={480}
            handleStyles= {{
              right: {
                "right": "-6px",
                "width": "6px",
                "zIndex": 1
              }
            }}
            handleClasses={{"right": "resize-handle"}}
          >
            <nav className="tree">
              <p className="title mb-0">{i18n.t('app:Tasks')}</p>
              <ul className="ms-Nav-navItems list-page h-100 m-0 pl-0">
                <Tree
                  nodes={itemTree}
                  onChange={this.handleNodesChange}>
                  {({onChange, style, node, index, ...rest}) => {

                    const {hasChildren, isExpanded, type} = getNodeRenderOptions(node);
                    const handleChange = () =>{
                      onChange({...updateNode(node, {expanded: !isExpanded}) })
                    }
                    if(typeof(style.marginLeft) === "number") style.paddingLeft = style.marginLeft / 2
                    if(typeof(style.marginLeft) === "number") style.marginLeft = 0
                    let item:any = node;
                    return(item.type === 'separator' ? <>
                      <li className="ms-Nav-navItem" style={{...style, background: 'transparent', cursor: 'default'}} tabIndex={index}>
                          <hr/>
                      </li>
                    </> 
                    : treeLine(style,index,node,isExpanded,hasChildren,handleChange))}
                  }
                </Tree>
              </ul>
            </nav>
          </Resizable>
          {/*<FormFilter items={this.props.items}/>*/}
          </>
          : selNav === "timeTracking" ?
          <>
          <Resizable
            size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
            onResizeStop={(e, direction, ref, d) => {
              var currentWidth = this.state.panelWidth.match(/\d+/)[0];
              this._isMounted && this.setState({
                panelWidth: +currentWidth + +d.width + "px"
              });
              this._saveResizeState();
            }}
            enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
            minWidth={240}
            maxWidth={480}
            handleStyles= {{
              right: {
                "right": "-6px",
                "width": "6px",
                "zIndex": 1
              }
            }}
            handleClasses={{"right": "resize-handle"}}
          >
            <nav className="tree">
              <p className="title mb-0">{'Time tracking'}</p>
              <ul className="ms-Nav-navItems list-page h-100 m-0 pl-0">
                <Tree
                  nodes={itemTree}
                  onChange={this.handleNodesChange}>
                  {({onChange, style, node, index, ...rest}) => {

                    const {hasChildren, isExpanded, type} = getNodeRenderOptions(node);
                    const handleChange = () =>{
                      onChange({...updateNode(node, {expanded: !isExpanded}) })
                    }
                    if(typeof(style.marginLeft) === "number") style.paddingLeft = style.marginLeft / 2
                    if(typeof(style.marginLeft) === "number") style.marginLeft = 0
                    let item:any = node;
                    return(item.type === 'separator' ? <>
                      <li className="ms-Nav-navItem" style={{...style, background: 'transparent', cursor: 'default'}} tabIndex={index}>
                          <hr/>
                      </li>
                    </> 
                    : treeLine(style,index,node,isExpanded,hasChildren,handleChange))}
                  }
                </Tree>
              </ul>
            </nav>
          </Resizable>
          {/*<FormFilter items={this.props.items}/>*/}
          </>
        : null }
        </div>
      </ThemeProvider>
    )
  }

  private _saveResizeState() {
    const { panelHeight, panelWidth } = this.state;
    
    var prefs = {
      height: panelHeight,
      width: panelWidth
    }

    localStorage.setItem("sidebarPrefs", JSON.stringify(prefs))
  }

  private _saveResizeState2() {
    const { panelHeight, panel2Width } = this.state;
    
    var prefs = {
      height: panelHeight,
      width: panel2Width
    }

    localStorage.setItem("sidebar2Prefs", JSON.stringify(prefs))
  }

  private toggleNav(nav:any) {
    const { selNav } = this.state;
    if (selNav === nav) {
      nav = "";
    }
    this._isMounted && this.setState({ selNav: nav })
  }

  private removeFilterCategory(category) {
    let search = new URLSearchParams(document.location.search)
    search.delete(category.toLowerCase())
    history.push('?'+search.toString())
  }

  private removeFilter(item) {
    let search = new URLSearchParams(document.location.search)
    let tags = search.get(item.category.toLowerCase())?.split(';')
    if (tags) {
      let values = tags.filter((value)=>{
        return value !== item.name
      });
      search.delete(item.category.toLowerCase())
      values.length > 0 && search.set(item.category.toLowerCase(), values.join(';'))
      history.push('?'+search.toString())
    }
  }

  private _goToPage(ev: React.MouseEvent<HTMLElement> | undefined, item?: any | undefined) {
    if (ev) {
      ev.preventDefault();
    }

    if (item) {
      if(item.type === "projectsList") history.push("/projects/list" + document.location.search)
      else if(item.type === "projectsNothing") history.push("/projects/list" + document.location.search)
      else if(item.type === "projectsCompany") history.push("/projects/clients" + document.location.search)
      else if(item.type === "projectsMilestone") history.push("/projects/milestones" + document.location.search)
      else if(item.type === "projectsPipeline") history.push("/projects/pipeline" + document.location.search)
      else if(item.type === "projectsBoard" && this.props.pipelineId !== "all") history.push("/projects/board/" + document.location.search)
      else if(item.type === "companiesList") history.push("/companies" + document.location.search)
      else if(item.type === "contactsList") history.push("/contacts" + document.location.search)
      else if(item.type === "pipelines") history.push(item.name + document.location.search)
      else if(item.type === "pipelinesList") history.push("/projects/pipeline" + document.location.search)
      else if(item.type === "homeList") history.push("/home" + document.location.search)
      else if(item.type === "taskList") history.push("/tasks" + document.location.search)
      else if(item.type === "viewAll") history.push("/projects/list")
      else if(item.type === "viewPipelines") history.push("/projects/pipeline" + document.location.search)
      else if(item.type === "viewPipeline") history.push("/projects/list/" + item.name + document.location.search)
      else if(item.type === "viewBoardPipeline") history.push("/projects/board/" + item.name)
      else if(item.type === "viewActivity") this.props.callbackFunction({query: 'all'})
      else if(item.type === "viewNotes") this.props.callbackFunction({query: 'notes'})
      else if(item.type === "viewFiles") this.props.callbackFunction({query: 'files'})
      else if(item.type === "view-projects") {
        if (item.page === 'projectsExplorer') history.push("/projects/list" + item.search)
        else if (item.page === 'clientsExplorer') history.push("/projects/clients" + item.search)
        else if (item.page === 'pipelinesExplorer') history.push("/projects/pipelines" + item.search)
        else if (item.page === 'boardExplorer') history.push("/projects/board" + item.search)
      }
      else if (item.type === 'category' || item.type === 'tagButton'){
        this._isMounted && this.setState({filterColumnOpen: true, filterName: item.field})
      }
      else if (item.type.includes('tag')){
        this._isMounted && this.setState({filterColumnOpen: true, filterName: item.category})
      }
      else if(item.type === "view-project") {
        history.push(item.search)
      }
      else if(item.type === "homeProjects") history.push("/projects/list" )
      else if(item.type === "homeContacts") history.push("/contacts" )
      else if(item.type === "homeCompanies") history.push("/companies" )
      else if(item.type === "homePipelines") history.push("/pipelines" )
      else if(item.type === "homeTasks") history.push("/tasks" )
      else if(item.type === "homeTime") history.push("/time-sheet" )
      else if(item.type === "timeSheet") history.push("/time-sheet" )
      else if(item.type === "timeTracker") history.push("/time-tracker" )
      else if(item.type === "homeFiles") history.push("/files/" + this.state.repoData[0].id )
      else if(item.type === "homeSearch") history.push("/search" )

    }
  };

  private handleNodesChange = itemTree => {
    this.setState({itemTree});
  }

  private _onRenderGroupHeader = (group: any): JSX.Element | null => {
    return(
      <p className="nav-repo-group m-0">{ group.name }</p>
    );
  }

  private _goToSettings() {
    var url = "https://account.synergy.page"
    if(this.props.appPlatform === "electron") {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.send("open-browser-url", url)
    } else {
        window.open(url, "_blank");
    }
  }

  private _applyWebUpdate() {
    if(this.props.appPlatform === "electron") {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.send('quitAndRelaunchApp');
    } else {
        window.location.reload();
    }
  }

  private _goToProjects() {
    const {repoData} = this.state;

    this.toggleNav("projects")
    if (window.location.href.indexOf("/projects") === -1
      && window.location.href.indexOf("/projects/clients") === -1
      && window.location.href.indexOf("/projects/milestones") === -1
      && window.location.href.indexOf("/projects/board") === -1 ) {
      repoData && history.push('/projects/list')
    }
  }

  private _goToTimeTracking() {
    const {repoData} = this.state;

    this.toggleNav("timeTracking")
    if (window.location.href.indexOf("/time-tracker") === -1
    && window.location.href.indexOf("/time-sheet") === -1) {
      repoData && history.push('/time-sheet')
    }
  }

  private _goToHome() {
    const {repoData} = this.state;

    this.toggleNav("home")
    if (window.location.href.indexOf("/home") === -1) {
      repoData && history.push('/home')
    }
  }

  private _goToTasks() {
    const {repoData} = this.state;

    this.toggleNav("tasks")
    if (window.location.href.indexOf("/tasks") === -1) {
      repoData && history.push('/tasks')
    }
  }

  private _goToFiles() {
    const {repoData} = this.state;

    this.toggleNav("files")
    if (window.location.href.indexOf("/files") === -1
      && window.location.href.indexOf("/recent-files") === -1
      && window.location.href.indexOf("/open-files") === -1
      && window.location.href.indexOf("/bookmarks") === -1) {
      repoData && history.push('/files/' + repoData[0].id)
    }
  }

  private _goToSearch() {
    this.toggleNav("search")
    if (window.location.href.indexOf("/search") === -1) {
      history.push('/search')
    }
  }

  private _getSelNav() {
    if (window.location.href.indexOf("/projects") !== -1) {
      return  "projects";
    } else if (window.location.href.indexOf("/companies") !== -1) {
      return  "companies";
    } else if (window.location.href.indexOf("/contacts") !== -1) {
      return  "contacts";
    } else if (window.location.href.indexOf("/pipelines") !== -1) {
      return  "pipelines";
    } else if (window.location.href.indexOf("/tasks") !== -1) {
      return  "tasks";
    } else if (window.location.href.indexOf("/time") !== -1) {
      return  "timeTracking";
    } else if (window.location.href.indexOf("/home") !== -1) {
      return  "home";
    }
    return "";
  }

  private addTag(key:string, value:string) {
    //var queryString = "?";
    var params = new URLSearchParams(document.location.search);
    /*let index=0
    params.forEach((value, key)=>{
      if (index === 0) {
        //queryString += "?"
      } else queryString += "&"
      index ++;
      let field:string = params.get(key) || "";
      if (field && field.length > 0) {
        params.set(key,value)
        //queryString +=  key + "=" + value
      }
    })*/
    params.set(key,value)
    history.push("?"+params.toString())
  }

  private saveCustomView() {
    var params = new URLSearchParams(document.location.search);
    var page: string = '';
    if (this.props.page === 'companiesExplorer') page = '/companies' ;
    else if (this.props.page === 'contactsExplorer') page = '/contacts';
    else if (this.props.page === 'projectsExplorer') page = '/projects/list' + document.location.search;
    else if (this.props.page === 'clientsExplorer') page = '/projects/clients' + document.location.search;
    else if (this.props.page === 'pipelinesExplorer') page = '/projects/pipelines' + document.location.search;
    else if (this.props.page === 'milestonesExplorer') page = '/projects/milestones' + document.location.search;
    else if (this.props.page === 'boardExplorer') page = '/projects/board' + document.location.search;

    console.log(page)
  }

  private _onRenderLink = (link: any): JSX.Element | null => {
    var currentPath = this.props.folderId
    return ( 
      link.type === "trash" ?
        <div className={"folderLink dir " + (link.id === currentPath ? "selItem":"")}>
          <Icon iconName="Trash" className="trash align-top" />
          <span>{ link.name }</span>
        </div>
      : link.type === "pipelinesList" ?
        <div className={"folderLink " + (this.props.pipelineId === 'all' ? "selItem":"")}>
          <Icon iconName="D365ProjectOperationsIcon" className="recentFiles align-top" />
          <span>{ link.name }</span>
        </div>
      : link.type === "pipelines" ?
        <div className={"folderLink " + (this.props.pipelineId === link.name ? "selItem":"")}>
          <Icon iconName="D365ProjectOperationsIcon" className="recentFiles align-top" />
          <span>{ link.name }</span>
        </div>
      : link.type === "projectsList" ?
        <div className={"folderLink " + (this.props.page === "projectsExplorer"
        || this.props.page === "clientsExplorer"
        || this.props.page === "milestonesExplorer"
        || this.props.page === "pipelinesExplorer"
        || this.props.page === "tasksExplorer" ? "selItem":"")}>
          <Icon iconName="BulletedList" className="recentFiles align-top" />
          <span>{ link.name }</span>
        </div>
      : link.type === "all" ?
      <div className={"folderLink selItem"}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "viewAll" ?
      <div className={"folderLink " + (this.props.page === "projectsExplorer" && this.props.pipelineId === "all" ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "viewPipelines" ?
      <div className={"folderLink " + (this.props.page === "pipelinesExplorer" && this.props.pipelineId === "all" ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "viewPipeline" ?
      <div className={"folderLink " + (this.props.page === "projectsExplorer" && this.props.pipelineId === link.name ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "viewBoardPipeline" ?
      <div className={"folderLink " + (this.props.page === "boardExplorer" && this.props.pipelineId === link.name ? "selItem":"")}>
        <Icon iconName="TripleColumn" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "viewActivity" ?
      <div className={"folderLink " + (this.props.page === "projectData" && this.props.query === "all" ? "selItem":"")}>
        <Icon iconName="LineChart" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "viewNotes" ?
      <div className={"folderLink " + (this.props.page === "projectData" && this.props.query === "notes" ? "selItem":"")}>
        <Icon iconName="QuickNote" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "viewFiles" ?
      <div className={"folderLink " + (this.props.page === "projectData" && this.props.query === "files" ? "selItem":"")}>
        <Icon iconName="Documentation" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "projectsNothing" ?
      <div className={"folderLink " + (this.props.page === "projectsExplorer" ? "selItem":"")}>
        <Icon iconName="FabricFolder" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "projectsCompany" ?
        <div className={"folderLink " + (this.props.page === "clientsExplorer" ? "selItem":"")}>
          <Icon iconName="CityNext2" className="recentFiles align-top" />
          <span>{ link.name }</span>
        </div>
      : link.type === "projectsMilestone" ?
      <div className={"folderLink " + (this.props.page === "milestonesExplorer" ? "selItem":"")}>
        <Icon iconName="CRMProcesses" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "projectsPipeline" ?
      <div className={"folderLink " + (this.props.page === "pipelinesExplorer" ? "selItem":"")}>
        <Icon iconName="D365ProjectOperationsIcon" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "projectsBoard" ?
      <div className={"folderLink " + (this.props.page === "boardExplorer" ? "selItem":"")}>
        <Icon iconName="TripleColumn" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "companiesList" ?
      <div className={"folderLink " + (this.props.page === "companiesExplorer" ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "contactsList" ?
      <div className={"folderLink " + (this.props.page === "contactsExplorer" ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homeList" ?
      <div className={"folderLink " + (this.props.page === "home" ? "selItem":"")}>
        <Icon iconName="Home" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "taskList" ?
      <div className={"folderLink " + (this.props.page === "tasksExplorer" ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "myTasks" ?
      <div className={"folderLink "}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "custom" ?
       <div className={"folderLink "} style={{opacity:.6}}>
         <Icon iconName="SingleColumnEdit" className="recentFiles align-top" />
         <span >{ link.name }</span>
       </div>
      : link.type === "category" ?
      <div className="folderLink">
        <span>{ link.name }</span>
      </div>
      : link.type === "tagOwner" ?
      <div className={"folderLink "}>
          <span><Persona className="tag" styles={{root: {marginLeft: '6px'}, primaryText:{marginLeft: '2px'} }} size={10} text={link.name}/></span>
      </div>
      : link.type === "tagMilestone" ?
      <div className={"folderLink " }>
        <Icon iconName="CircleShapeSolid" className="tag" 
          style={{color: link.name === 'Done' ? '#07c873': link.name === 'Pending' ? '#c4c4c4' : link.name === 'In progress' ? '#fec06e' : 'rgb(50, 49, 48)', fontSize:12, textAlign:'center'}} 
        />
        <span>{ link.name }</span>
      </div>
      : link.type === "tagCompany" ?
      <div className={"folderLink "}>
        <Icon iconName="Tag" className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "tagPipeline" ?
      <div className={"folderLink "}>
        <Icon iconName="D365ProjectOperationsIcon" className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "viewsHeader" ?
      <div className={"folderLink " + (this.checkSelFilter(link) ? "filter-selected" : "")} onClick={ evt => { evt.preventDefault(); this.setState({goBack: 0}) } }>
        <Icon iconName="View" className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "filtersHeader" ?
      <div className={"folderLink " + (this.checkSelFilter(link) ? "filter-selected" : "")} onClick={ evt => { evt.preventDefault(); this.setState({goBack: 0}) } }>
        <Icon iconName="Filter" className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "filterTags" ?
      <div className={"folderLink " + (this.checkSelFilter(link) ? "filter-selected" : "")} onClick={ evt => { evt.preventDefault(); this.setState({goBack: 0}) } }>
        <Icon iconName={link.iconName} className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "optionsHeader" ?
      <div className={"folderLink " + (this.checkSelFilter(link) ? "filter-selected" : "")} onClick={ evt => { evt.preventDefault(); this.setState({goBack: 0}) } }>
        <Icon iconName="FilterSettings" className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "view-projects" ?
      <div className={"view-folderlink folderLink " + (this.props.page === link.page && document.location.search === link.search ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "view-custom" ?
      <div className={"folderLink " + (this.checkSelected() ? "selItem" : "")} style={{opacity: (this.checkSelected() ? 1: .6)}} onClick={ evt => { evt.preventDefault(); if (this.checkSelected()) this.saveCustomView()}}>
        <Icon iconName="SingleColumnEdit" className="recentFiles align-top" />
        <span >{ link.name }</span>
      </div>
      : link.type === "view-project" ?
      <div className={"view-folderlink folderLink " + (document.location.search === link.search || ((!document.location.search || document.location.search === '?type=All') && link.search === '') ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "tagType" ?
      <div className={"folderLink " + (this.checkSelFilter(link,'type') ? "selItem" : "")}>
        <Icon iconName={link.iconName} className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "tagButton" ?
      <div className={"folderLink "}>
        <Icon iconName={link.iconName} className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "tagUndefined" ?
      <div className={"folderLink "} style={{opacity: link.iconName === 'ClearFilter' ? .6 : 1}}>
        <Icon iconName={link.iconName || 'Tag'} className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homeFiles" ?
      <div className={"folderLink "}>
        <Icon iconName='FabricFolder' className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homeSearch" ?
      <div className={"folderLink "}>
        <Icon iconName='Search' className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homeProjects" ?
      <div className={"folderLink "}>
        <Icon iconName='Work' className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homeContacts" ?
      <div className={"folderLink "}>
        <Icon iconName='Contact' className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homeCompanies" ?
      <div className={"folderLink "}>
        <Icon iconName='CityNext2' className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homePipelines" ?
      <div className={"folderLink "}>
        <Icon iconName='D365ProjectOperationsIcon' className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homeTasks" ?
      <div className={"folderLink "}>
        <Icon iconName='TaskLogo' className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homeTime" ?
      <div className={"folderLink "}>
        <Icon iconName='Clock' className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "timeSheet" ?
      <div className={"folderLink " + (this.props.page === link.type ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "timeTracker" ?
      <div className={"folderLink " + (this.props.page === link.type ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : <div className="link">
        <span>{ link.name }</span>
      </div>
    )
  }

  private _getItemTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];

    if(repoData && repoData.length) {

      var internalRepos = repoData.filter(repo => {
        if(repo.role === "ADMIN"
          || repo.role === "USER"
          || repo.role === "INTERNAL") {
          return true
        } else {
          return false
        }
      })

      for (let i = 0; i < internalRepos.length; i++) {
        let repo = internalRepos[i];
        itemTree.unshift({
          
          id: repo.id,
          hasChildren: true,
          isExpanded: true,
          state: {
            expanded: true
          },
          children: [
            {
              key: "viewAll-" + repo.id,
              id: "viewAll-" + repo.id,
              name: i18n.t('app:views'),
              isExpanded: true,
              type: "viewsHeader",
              state: {
                expanded: true
              },
              hasChildren: true,
              children: [
                {
                  key: "viewProjects-" + repo.id,
                  id: "viewProjects-" + repo.id,
                  name: 'All projects',
                  type: 'view-projects',
                  page: 'pipelinesExplorer',
                  search: '',
                  state: {}
                },
                {
                  key: "Investments" + repo.id,
                  id: "Investments" + repo.id,
                  name: 'Investments',
                  type: 'view-projects',
                  page: 'boardExplorer',
                  search: '?pipeline=Investments',
                  state: {}
                },
                {
                  key: "Divestments" + repo.id,
                  id: "Divestments" + repo.id,
                  name: 'Divestments',
                  type: 'view-projects',
                  page: 'boardExplorer',
                  search: '?pipeline=Divestments',
                  state: {}
                },
                {
                  key: "custom-" + repo.id,
                  id: "custom-" + repo.id,
                  name: 'Custom view',
                  type: "view-custom",
                  state: {}
                }
              ]
            },
            {
              key: "separator1-" + repo.id,
              id: "separator1-" + repo.id,
              type: "separator"
            },
            {
              key: "viewOptionsHeader-" + repo.id,
              id: "viewOptionsHeader-" + repo.id,
              name: i18n.t('app:viewOptions'),
              isExpanded: true,
              state: {
                expanded: true
              },
              hasChildren: true,
              type: "optionsHeader",
              children: [
                {
                  key: "viewHeader-" + repo.id,
                  id: "viewHeader-" + repo.id,
                  name: 'View as',
                  isExpanded: true,
                  state: {
                    expanded: true
                  },
                  hasChildren: true,
                  type: "header",
                  children: [
                    {
                      key: "projectslist-" + repo.id,
                      id: "projectslist-" + repo.id,
                      name: 'List view',
                      type: "projectsList",
                    },
                    {
                      key: "projectsboard-" + repo.id,
                      id: "projectsboard-" + repo.id,
                      name: 'Board view',
                      type: "projectsBoard",
                    }
                  ]
                },
                {
                  key: "groupHeader-" + repo.id,
                  id: "groupHeader-" + repo.id,
                  name: 'Group by',
                  isExpanded: true,
                  state: {
                    expanded: true
                  },
                  hasChildren: true,
                  type: "header",
                  children: [
                    {
                      key: "projectsNothing-" + repo.id,
                      id: "projectsNothing-" + repo.id,
                      name: 'Nothing',
                      type: "projectsNothing",
                    },
                    {
                      key: "projectsCompany-" + repo.id,
                      id: "projectsCompany-" + repo.id,
                      name: 'Company',
                      type: "projectsCompany",
                    },
                    {
                      key: "projectspipelines-" + repo.id,
                      id: "projectspipelines-" + repo.id,
                      name: 'Pipeline',
                      type: "projectsPipeline",
                    },
                    {
                      key: "projectsmilestone-" + repo.id,
                      id: "projectsmilestone-" + repo.id,
                      name: 'Stage',
                      type: "projectsMilestone",
                    },
                  ]
                }
              ]
            }
          ]
        })
      }
      
      this._isMounted && this.setState({
        itemTree: itemTree[0].children
      }, ()=>{
        this._getSearchFilters();
      });
    }
  }

  private _getCustomViewsTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];
    var folders: any = this.props.items
    if(repoData && repoData.length && folders) {
        // Sorting folders by name

        if (folders) {

          var internalRepos = repoData.filter(repo => {
            if(repo.role === "ADMIN"
              || repo.role === "USER"
              || repo.role === "INTERNAL") {
              return true
            } else {
              return false
            }
          })

          for (let i = 0; i < internalRepos.length; i++) {
            let repo = internalRepos[i];

            itemTree.unshift({
              children: [
                {
                  key: "viewOptionsHeader-" + repo.id,
                  id: repo.id,
                  name: i18n.t('app:viewOptions'),
                  isExpanded: true,
                  type: "optionsHeader",
                  children: [
                    {
                      key: "viewHeader-" + repo.id,
                      id: repo.id,
                      name: 'View as',
                      isExpanded: true,
                      type: "header",
                      children: [
                        {
                          key: "projectslist-" + repo.id,
                          id: repo.id,
                          name: 'List view',
                          type: "projectsList",
                        },
                        {
                          key: "projectsboard-" + repo.id,
                          id: repo.id,
                          name: 'Board view',
                          type: "projectsBoard",
                        }
                      ]
                    },
                    {
                      key: "groupHeader-" + repo.id,
                      id: repo.id,
                      name: 'Group by',
                      isExpanded: true,
                      type: "header",
                      children: [
                        {
                          key: "projectsNothing-" + repo.id,
                          id: repo.id,
                          name: 'Nothing',
                          type: "projectsNothing",
                        },
                        {
                          key: "projectsCompany-" + repo.id,
                          id: repo.id,
                          name: 'Company',
                          type: "projectsCompany",
                        },
                        {
                          key: "projectspipelines-" + repo.id,
                          id: repo.id,
                          name: 'Pipeline',
                          type: "projectsPipeline",
                        },
                        {
                          key: "projectsmilestone-" + repo.id,
                          id: repo.id,
                          name: 'Stage',
                          type: "projectsMilestone",
                        },
                      ]
                    }
                  ]
                }
              ]
            })
          }

          this._isMounted && this.setState({
            customViews: itemTree
          });   
        }
      }
  }

  private _getProjectViewsTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];
    
    if(repoData && repoData.length) {

      var internalRepos = repoData.filter(repo => {
        if(repo.role === "ADMIN"
          || repo.role === "USER"
          || repo.role === "INTERNAL") {
          return true
        } else {
          return false
        }
      })

      for (let i = 0; i < internalRepos.length; i++) {
        let repo = internalRepos[i];
        itemTree = [
          {
            key: "viewAllHeader-" + repo.id,
            id: "viewAllHeader-" + repo.id,
            name: i18n.t('app:views'),
            isExpanded: true,
            type: "viewsHeader",
            state: {expanded: true},
            hasChildren: true,
            children: [
              {
                key: "viewActivity-" + repo.id,
                id: "viewActivity-" + repo.id,
                name: 'All',
                type: "view-project",
                search: ''
              },
              {
                key: "viewEmails-" + repo.id,
                id: "viewEmails-" + repo.id,
                name: 'Emails',
                type: "view-project",
                search: '?type=Emails'
              },
              {
                key: "viewActivities-" + repo.id,
                id: "viewActivities-" + repo.id,
                name: 'Activities',
                type: "view-project",
                search: '?type=Activities'
              },
              {
                key: "custom-" + repo.id,
                id: "custom-" + repo.id,
                name: 'Custom view',
                type: "view-custom",
              }
            ]
          }
        ]
      }

      this._isMounted && this.setState({
        itemTree: itemTree
      }, ()=>{
        this._getSearchFilters();
      });
    }
  }

  private _getCompaniesTree = () => {

    const { repoData } = this.state;
    var itemTree: any = [];
    var items: any = this.props.items

    if(repoData && repoData.length && items) {

        if (items) {

          var internalRepos = repoData.filter(repo => {
            if(repo.role === "ADMIN"
              || repo.role === "USER"
              || repo.role === "INTERNAL") {
              return true
            } else {
              return false
            }
          })

          for (let i = 0; i < internalRepos.length; i++) {
            let repo = internalRepos[i];

            itemTree = [
              {
                key: "companiesHeader-" + repo.id,
                id: "companiesHeader-" + repo.id,
                name: 'Views',
                isExpanded: true,
                state: {expanded: true},
                hasChildren: true,
                type: "header",
                children: [
                  {
                    key: "companieslist-" + repo.id,
                    id: "companieslist-" + repo.id,
                    name: 'All companies',
                    type: "companiesList",
                  },
                  {
                    key: "custom-" + repo.id,
                    id: "custom-" + repo.id,
                    name: 'Custom view',
                    type: "custom",
                  }
                ]
              }
            ]
          }

          this._isMounted && this.setState({
            itemTree: itemTree
          });  
        }
      }
  }

  private _getCompanyViewsTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];
    
    if(repoData && repoData.length) {

      var internalRepos = repoData.filter(repo => {
        if(repo.role === "ADMIN"
          || repo.role === "USER"
          || repo.role === "INTERNAL") {
          return true
        } else {
          return false
        }
      })

      for (let i = 0; i < internalRepos.length; i++) {
        let repo = internalRepos[i];
        itemTree = [
          {
            key: "viewAllHeader-" + repo.id,
            id: "viewAllHeader-" + repo.id,
            name: i18n.t('app:views'),
            isExpanded: true,
            state: {expanded: true},
            hasChildren: true,
            type: "header",
            children: [
              {
                key: "viewActivity-" + repo.id,
                id: "viewActivity-" + repo.id,
                name: 'All',
                type: "view-project",
                search: ''
              },
              {
                key: "viewEmails-" + repo.id,
                id: "viewEmails-" + repo.id,
                name: 'Emails',
                type: "view-project",
                search: '?type=Emails'
              },
              {
                key: "viewActivities-" + repo.id,
                id: "viewActivities-" + repo.id,
                name: 'Activities',
                type: "view-project",
                search: '?type=Activities'
              },
              {
                key: "custom-" + repo.id,
                id: "custom-" + repo.id,
                name: 'Custom view',
                type: "view-custom",
              }
            ]
          }
        ]
      }

      this._isMounted && this.setState({
        itemTree: itemTree
      }, ()=>{
        this._getSearchFilters();
      });
    }
  }

  private _getContactsTree = () => {

    const { repoData } = this.state;
    var itemTree: any = [];
    var items: any = this.props.items

    if(repoData && repoData.length && items) {

        if (items) {

          var internalRepos = repoData.filter(repo => {
            if(repo.role === "ADMIN"
              || repo.role === "USER"
              || repo.role === "INTERNAL") {
              return true
            } else {
              return false
            }
          })

          for (let i = 0; i < internalRepos.length; i++) {
            let repo = internalRepos[i];

            itemTree = [
              {
                key: "contactsHeader-" + repo.id,
                id: "contactsHeader-" + repo.id,
                name: 'Views',
                isExpanded: true,
                state: {expanded: true},
                hasChildren: true,
                type: "header",
                children: [
                  {
                    key: "contactslist-" + repo.id,
                    id: "contactslist-" + repo.id,
                    name: 'All contacts',
                    type: "contactsList",
                  },
                  {
                    key: "custom-" + repo.id,
                    id: "custom-" + repo.id,
                    name: 'Custom view',
                    type: "custom",
                  }
                ]
              }
            ]
          }

          this._isMounted && this.setState({
            itemTree: itemTree
          });  
        }
      }
  }

  private _getContactViewsTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];
    
    if(repoData && repoData.length) {

      var internalRepos = repoData.filter(repo => {
        if(repo.role === "ADMIN"
          || repo.role === "USER"
          || repo.role === "INTERNAL") {
          return true
        } else {
          return false
        }
      })

      for (let i = 0; i < internalRepos.length; i++) {
        let repo = internalRepos[i];
        itemTree = [
          {
            key: "viewAllHeader-" + repo.id,
            id: "viewAllHeader-" + repo.id,
            name: i18n.t('app:views'),
            isExpanded: true,
            state: {expanded: true},
            hasChildren: true,
            type: "header",
            children: [
              {
                key: "viewActivity-" + repo.id,
                id: "viewActivity-" + repo.id,
                name: 'All',
                type: "view-project",
                search: ''
              },
              {
                key: "viewEmails-" + repo.id,
                id: "viewEmails-" + repo.id,
                name: 'Emails',
                type: "view-project",
                search: '?type=Emails'
              },
              {
                key: "viewActivities-" + repo.id,
                id: "viewActivities-" + repo.id,
                name: 'Activities',
                type: "view-project",
                search: '?type=Activities'
              },
              {
                key: "custom-" + repo.id,
                id: "custom-" + repo.id,
                name: 'Custom view',
                type: "view-custom",
              }
            ]
          }
        ]
      }

      this._isMounted && this.setState({
        itemTree: itemTree
      }, ()=>{
        this._getSearchFilters();
      });
    }
  }

  private _getHomeTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];

    if(repoData) {

      let repo = repoData[0];

      itemTree = [
        {
          key: "homelist-" + repo.id,
          id: "homelist-" + repo.id,
          name: 'Home',
          type: "homeList",
        },
        {
          key: "homelist-" + 2,
          id: "homelist-" + 2,
          name: 'Files',
          type: "homeFiles",
        },
        {
          key: "homelist-" + 3,
          id: "homelist-" + 3,
          name: 'Search',
          type: "homeSearch",
        },
        {
          key: "homelist-" + 1,
          id: "homelist-" + 1,
          name: 'Projects',
          type: "homeProjects"
        },
        {
          key: "homelist-" + 5,
          id: "homelist-" + 5,
          name: 'Tasks',
          type: "homeTasks"
        },
        {
          key: "homelist-" + 4,
          id: "homelist-" + 4,
          name: 'Timetracker',
          type: "homeTime"
        }
      ]
      
      this._isMounted && this.setState({
        itemTree: itemTree
      });  
    }
  }

  private _getPipelinesTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];
    
    if(repoData && repoData.length) {

      var internalRepos = repoData.filter(repo => {
        if(repo.role === "ADMIN"
          || repo.role === "USER"
          || repo.role === "INTERNAL") {
          return true
        } else {
          return false
        }
      })

      for (let i = 0; i < internalRepos.length; i++) {
        let repo = internalRepos[i];
        itemTree = [
          {
            key: "viewAllHeader-" + repo.id,
            id: "viewAllHeader-" + repo.id,
            name: i18n.t('app:views'),
            isExpanded: true,
            state: {expanded: true},
            hasChildren: true,
            type: "header",
            children: [
              {
                key: "viewAll-" + repo.id,
                id: "viewAll-" + repo.id,
                name: 'All',
                type: "all",
              },
              {
                key: "custom-" + repo.id,
                id: "custom-" + repo.id,
                name: 'Custom view',
                type: "custom",
              }
            ]
          }
        ]
      }

      this._isMounted && this.setState({
        itemTree: itemTree
      });
    }
  }

  private _getTasksTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];

    if(repoData && repoData.length) {
      var internalRepos = repoData.filter(repo => {
        if(repo.role === "ADMIN"
          || repo.role === "USER"
          || repo.role === "INTERNAL") {
          return true
        } else {
          return false
        }
      })

      for (let i = 0; i < internalRepos.length; i++) {
        let repo = internalRepos[i];
        itemTree = [
          {
            key: "viewAllHeader-" + repo.id,
            id: "viewAllHeader-" + repo.id,
            name: i18n.t('app:views'),
            isExpanded: true,
            state: {expanded: true},
            hasChildren: true,
            type: "header",
            children: [
              {
                key: "taskList-" + repo.id,
                id: "taskList-" + repo.id,
                name: 'All tasks',
                type: "taskList",
              },
              {
                key: "myTasks-" + repo.id,
                id: "myTasks-" + repo.id,
                name: 'My tasks',
                type: "myTasks",
              },
              {
                key: "custom-" + repo.id,
                id: "custom-" + repo.id,
                name: 'Custom view',
                type: "custom",
              }
            ]
          }
        ]
      }
      this._isMounted && this.setState({
        itemTree: itemTree
      });  
    }
  }

  private _getTimeTrackingTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];
    
    if(repoData && repoData.length) {

      var internalRepos = repoData.filter(repo => {
        if(repo.role === "ADMIN"
          || repo.role === "USER"
          || repo.role === "INTERNAL") {
          return true
        } else {
          return false
        }
      })

      for (let i = 0; i < internalRepos.length; i++) {
        let repo = internalRepos[i];
        itemTree = [
          {
            key: "viewAllHeader-" + repo.id,
            id: "viewAllHeader-" + repo.id,
            name: i18n.t('app:views'),
            isExpanded: true,
            state: {expanded: true},
            hasChildren: true,
            type: "header",
            children: [
              {
                key: "sheet-" + repo.id,
                id: "sheet-" + repo.id,
                name: 'My time sheet',
                type: "timeSheet",
              },
              {
                key: "tracker-" + repo.id,
                id: "tracker-" + repo.id,
                name: 'My time tracker',
                type: "timeTracker",
              },
              {
                key: "custom-" + repo.id,
                id: "custom-" + repo.id,
                name: 'Custom view',
                type: "custom",
              }
            ]
          }
        ]
      }

      this._isMounted && this.setState({
        itemTree: itemTree
      });
    }
  }

  private _addFiltersToTree = (type, filters, checkbox) => {
    var itemTree = this.state.itemTree;
    for (let i=0; i< itemTree.length; i++) {
      if (itemTree[i].type === 'filtersHeader') {
        var tree = itemTree[i].children
        for (let i=0; i< tree.length; i++) {
          if (tree[i].name === type) {
            if (filters && filters.length > 0) {
              tree[i].children = filters;
              this.setState({itemTree: itemTree})
            } else {
              tree[i].children = [ 
              {
                key: 'tag' + checkbox,
                id: 'tag' + checkbox,
                name: checkbox === 'none' ? 'None' : checkbox === 'all' ? 'All' : 'No filter' ,
                type: 'tagUndefined',
                category: type,
                iconName: checkbox === 'none' ? 'Tag' : checkbox === 'all' ? 'Tag' : 'ClearFilter' ,
              }]
              this.setState({itemTree: itemTree})
            }
            var params = new URLSearchParams(document.location.search);
            let query = ''
            if (checkbox === 'noFilter') {
              params.delete(type.toLowerCase())
              history.push("?"+params.toString())
            } else if (checkbox === 'none') {
              params.set(type.toLowerCase(),checkbox)
              history.push("?"+params.toString())
            } else if (checkbox === 'all') {
              params.set(type.toLowerCase(),checkbox)
              history.push("?"+params.toString())
            } else {
              tree[i].children.map(tag=>{
                if (tag.id !== 'addTag') query === '' ? query = tag.name : query += (';'+tag.name)
              })
              params.set(type.toLowerCase(),query)
              history.push("?"+params.toString())
            }
          }
        }
      }
    }
  }

  private _dateCreated = (date: Date | null | undefined) => {
    if(date) {
      this._isMounted && this.setState({ initialDate: date},
        () => {
          var searchQuery = this.props.searchQuery
          const params = new URLSearchParams(searchQuery);

          var startDate = params.get("start_date");
          if(startDate) {
            params.delete("start_date")
            searchQuery = params.toString();
          }

          //this.props.callbackFunction({searchQuery:queryString})
          this.props.listExplorerRef.addTag({key:'Time: '+date.toDateString(),name:'Time: '+date.toDateString()})
          /*history.push({
            pathname: '/projects',
            search: queryString
          })*/
        })
    }
  }

  private _lastActivity = (date: Date | null | undefined) => {
    if(date) {
      this._isMounted && this.setState({ finalDate: date},
        () => {
          var searchQuery = this.props.searchQuery
          const params = new URLSearchParams(searchQuery);

          var endDate = params.get("end_date");
          if(endDate) {
            params.delete("end_date")
            searchQuery = params.toString();
          }

          var endOfTheDay = new Date(date);
          endOfTheDay.setHours(23,59,59,999);

          //this.props.callbackFunction({searchQuery:queryString})
          this.props.listExplorerRef.addTag({key:'Last activity: '+endOfTheDay.toDateString(),name:'Last activity: '+endOfTheDay.toDateString()})
          /*history.push({
            pathname: '/projects',
            search: queryString
          })*/
        })
    }
  }

  private _getSearchFilters() {
    const { repoData } = this.state;
    var itemTree: any = this.state.itemTree;

    for (let i = 0; i < repoData.length; i++) {
      let repo = repoData[i];

      if (this.props.page === "projectData" || this.props.page === "contactData" || this.props.page === "companyData") {
        itemTree.push({
          key: "separator1-" + repo.id,
          id: "separator1-" + repo.id,
          type: "separator"
        },
        {
          key: "tags-" + repo.id,
          id: "tags-" + repo.id,
          name: i18n.t('app:filters'),
          isExpanded: true,
          state: {
            expanded: true
          },
          hasChildren: true,
          type: "filtersHeader",
          children: [
            {
              key: "tagsType-" + repo.id,
              id: "tagsType-" + repo.id,
              name: i18n.t('app:Types'),
              isExpanded: true,
              state: {
                expanded: true
              },
              hasChildren: true,
              type: "filtersHeader",
              children: [
                {
                  key: "tags-" + 1,
                  id: "tags-" + 1,
                  name: 'All',
                  type: "tagType",
                  iconName: 'BulletedList'
                },
                {
                  key: "tags-" + 2,
                  id: "tags-" + 2,
                  name: 'Activities',
                  type: "tagType",
                  iconName: 'LineChart'
                },
                {
                  key: "tags-" + 3,
                  id:"tags-" + 3,
                  name: 'Notes',
                  type: "tagType",
                  iconName: 'QuickNote'
                },
                {
                  key: "tags-" + 4,
                  id: "tags-" + 4,
                  name: 'Emails',
                  type: "tagType",
                  iconName: 'Mail'
                },
                {
                  key: "tags-" + 7,
                  id: repo.id,
                  name: 'Meetings/Calls',
                  type: "tagType",
                  iconName: 'ActivityFeed'
                },
                {
                  key: "tags-" + 5,
                  id: "tags-" + 5,
                  name: 'Tasks',
                  type: "tagType",
                  iconName: 'TaskLogo'
                },
                {
                  key: "tags-" + 6,
                  id: "tags-" + 6,
                  name: 'Files',
                  type: "tagType",
                  iconName: 'Documentation'
                }
              ]
            }
          ]
        })
      }
      
    }

    this._isMounted && this.setState({
      itemTree: itemTree
    }, () => {
      this.props.items && (this.props.page !== 'contactsExplorer' && this.props.page !== 'companiesExplorer') && this._getTags();

      // Check if file type filter is selected in the repo
      /*var repos = this.state.searchFilters[0].links
      for (let i = 0; i < repos.length; i++) {
        var repo = repos[i]
        var fileTypeFilters = repo.links || [];
        for (let i = 0; i < fileTypeFilters.length; i++) {
          this.checkSelFilter(fileTypeFilters[i])
        }
      }*/
    });
  }

  private _getTags() {
    const {repoData} = this.state;

    var tagsTree: any = [{
      children:[]
      }];

    var getTags = async () => {
      var searchQuery = this.props.searchQuery
      var params = new URLSearchParams(searchQuery);
      var searchFolder = params.get("searchFolder") === "true";

        for (let i = 0; i < repoData.length; i++) {
          let repo = repoData[i];
          let repoId = repo.id
          var filters:any[] = [];

          var companyFilters = this.props.items[0];
          if (companyFilters) {
            filters = [
              {
                "id": 'Company name',
                "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
                "tag": 'Company name',
                "description": null,
                "color": 'grey'
              },
              {
                "id": 'Company owner',
                "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
                "tag": 'Company owner',
                "description": null,
                "color": 'grey'
              },
              {
                "id": 'City',
                "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
                "tag": 'City',
                "description": null,
                "color": 'grey'
              },
              {
                "id": 'State/Region',
                "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
                "tag": 'State/Region',
                "description": null,
                "color": 'grey'
              },
              {
                "id": 'Industry',
                "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
                "tag": 'Industry',
                "description": null,
                "color": 'grey'
              }
            ]
            for (let i=0; i<companyFilters.length; i++) {
              filters.push({
                "id": i,
                "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
                "tag": companyFilters[i],
                "description": null,
                "color": 'grey',
                children : [
                  {
                    "name": "Asd"
                  }
                ]
              })
            }
          }
          
          /*userService.getTags(repoId)
          .then((response: any) => {*/
            var response:any = {data: []};
            response.data = this.props.page === "companiesExplorer" ? 
            filters 
            : [
              {
                "id": "Tag-534a199b-5447-4450-984a-f4419f50adcg",
                "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
                "tag": "Company:3M",
                "author": {
                    "id": "U6da36385-3ed4-4654-96ef-691c54e2fe80",
                    "email": "jaime.rivas@synergy.page",
                    "name": "Jaime Rivas"
                },
                "description": null,
                "color": null
              },
              {
                "id": "Tag-534a199b-5447-4450-984a-f4419f50adch",
                "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
                "tag": "Company:Boeing",
                "author": {
                    "id": "U6da36385-3ed4-4654-96ef-691c54e2fe80",
                    "email": "jaime.rivas@synergy.page",
                    "name": "Jaime Rivas"
                },
                "description": null,
                "color": null
              },
              {
                "id": "Tag-534a199b-5447-4450-984a-f4419f50adcl",
                "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
                "tag": "Company:Caterpillar",
                "author": {
                    "id": "U6da36385-3ed4-4654-96ef-691c54e2fe80",
                    "email": "jaime.rivas@synergy.page",
                    "name": "Jaime Rivas"
                },
                "description": null,
                "color": null
              },
              {
                "id": "Tag-534a199b-5447-4450-984a-f4419f50adcm",
                "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
                "tag": "Company:Cisco",
                "author": {
                    "id": "U6da36385-3ed4-4654-96ef-691c54e2fe80",
                    "email": "jaime.rivas@synergy.page",
                    "name": "Jaime Rivas"
                },
                "description": null,
                "color": null
              },
              {
                "id": "Tag-534a299b-5447-4450-984a-f4419f50adcm",
                "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
                "tag": "Company:Intel",
                "author": {
                    "id": "U6da36385-3ed4-4654-96ef-691c54e2ff80",
                    "email": "jaime.rivas@synergy.page",
                    "name": "Jaime Rivas"
                },
                "description": null,
                "color": null
              },
              {
                "id": "Tag-534a199b-5447-4450-984a-f4419f60adcm",
                "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
                "tag": "Company:Nike",
                "author": {
                    "id": "U6da36385-3ed4-4654-96ef-691c54e2ge80",
                    "email": "jaime.rivas@synergy.page",
                    "name": "Jaime Rivas"
                },
                "description": null,
                "color": null
              },
              {
                "id": "Tag-534a199b-5447-4450-984a-f4419f52adcm",
                "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
                "tag": "Company:Pfizer",
                "author": {
                    "id": "U6da36385-3ed4-4654-96ef-691c54e2he80",
                    "email": "jaime.rivas@synergy.page",
                    "name": "Jaime Rivas"
                },
                "description": null,
                "color": null
              },
              {
                "id": "Tag-534a199b-5447-4450-984a-f4414f50adcm",
                "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
                "tag": "Company:Procter & Gamble",
                "author": {
                    "id": "U6da36385-3ed4-4654-96ef-681c54e2fe80",
                    "email": "jaime.rivas@synergy.page",
                    "name": "Jaime Rivas"
                },
                "description": null,
                "color": null
              },
              {
                "id": "Tag-534a199b-5447-4450-984a-f4419f50adce",
                "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
                "tag": "Owner:Francisco Fontinha",
                "author": {
                    "id": "U6da36385-3ed4-4654-96ef-691c54e2fe80",
                    "email": "jaime.rivas@synergy.page",
                    "name": "Jaime Rivas"
                },
                "description": null,
                "color": null
              },
              {
                "id": "Tag-534a199b-5447-4450-984a-f4419f50adcf",
                "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
                "tag": "Owner:Jaime Rivas",
                "author": {
                    "id": "U6da36385-3ed4-4654-96ef-691c54e2fe80",
                    "email": "jaime.rivas@synergy.page",
                    "name": "Jaime Rivas"
                },
                "description": null,
                "color": null
              },
              {
                "id": "Tag-534a199b-5447-4450-984a-f4419f50adci",
                "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
                "tag": "Owner:Manuel Marcos",
                "author": {
                    "id": "U6da36385-3ed4-4654-96ef-691c54e2fe80",
                    "email": "jaime.rivas@synergy.page",
                    "name": "Jaime Rivas"
                },
                "description": null,
                "color": null
              },
              {
                "id": "Tag-534a199b-5447-4450-984a-f4419f50adcj",
                "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
                "tag": "Owner:Michael Bommers",
                "author": {
                    "id": "U6da36385-3ed4-4654-96ef-691c54e2fe80",
                    "email": "jaime.rivas@synergy.page",
                    "name": "Jaime Rivas"
                },
                "description": null,
                "color": null
              },
              {
                "id": "Tag-534a199b-5447-4450-984a-f4419f50adck",
                "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
                "tag": "Owner:Xavier Campillo",
                "author": {
                    "id": "U6da36385-3ed4-4654-96ef-691c54e2fe80",
                    "email": "jaime.rivas@synergy.page",
                    "name": "Jaime Rivas"
                },
                "description": null,
                "color": null
              }
              
          ]
            var tags: any = [
              {
                key: "separator2-" + repo.id,
                id: "separator2-" + repo.id,
                type: "separator"
              },
              {
                key: "filtersHeader-" + repo.id,
                id: repo.id,
                name: i18n.t('app:filters'),
                isExpanded: true,
                state: {expanded: true},
                hasChildren: true,
                type: "filtersHeader",
                children: [
                  {
                    key: "projectsHeader-" + repo.id,
                    id: repo.id,
                    name: 'Pipeline',
                    isExpanded: true,
                    state: {expanded: true},
                    hasChildren: true,
                    type: "category",
                    field: 'Pipeline',
                    children: []
                  }
                ]
              }
            ];

            /*
            Get pipeline filters from URL and add them to the sidebar
            */
            const params = new URLSearchParams(document.location.search);
            let field:string = params.get('pipeline') || '';
            let fields:string[] = field.split(';');
            if (field === 'all' || field === 'none' || field === '') {
              tags[1].children[0].children.push({
                key: 'tag' + field,
                id: 'tag' + field,
                name: field === 'none' ? 'None' : field === 'all' ? 'All' : 'No filter' ,
                type: 'tagUndefined',
                category: 'Pipeline',
                iconName: field === 'none' ? 'Tag' : field === 'all' ? 'Tag' : 'ClearFilter' ,
              })
            } else if (fields && fields.length > 0 && field.length > 0) {
              fields.map((item:string)=>{
                tags[1].children[0].children.push({
                  key: 'tag' + item,
                  id: 'tag' + item,
                  name: item,
                  type: 'tagPipeline',
                  category: 'Pipeline',
                })
              })
            }

            if (response) {
              /*response.data.sort(function(a, b){
                  if(a.tag < b.tag) { return -1; }
                  if(a.tag > b.tag) { return 1; }
                  return 0;
              })*/

              var categories: any = [];

              for (let i = 0; i < response.data.length; i++) {
                var tag = response.data[i];

                var splitName = this.props.page === "companiesExplorer" ?  tag : tag.tag.split(/:(.+)/)

                if(splitName.length > 1) {
                  let category = splitName[0];
                  var tagName = splitName[1];
                  var repeated = categories.includes(category);

                  if(!repeated) {
                    categories.push(category);

                    tags[1].children.push({
                      key: 'cat-' + tag.id,
                      id: 'cat-' + tag.id,
                      name: category,
                      field: category,
                      isExpanded: true,
                      type: "category",
                      status: "active",
                      state: {expanded: true},
                      hasChildren: true,
                      children: []
                    })


                    /*
                    Get all current filters from URL and add them to the sidebar
                    */
                    const params = new URLSearchParams(document.location.search);
                    let field:string = params.get(category.toLowerCase()) || "";
                    let fields:string[] = field.split(';');
                    for (let i=0; i < tags[1].children.length; i ++) {
                      if (tags[1].children[i].field && tags[1].children[i].field.toLowerCase() === category.toLowerCase()) {
                        if (field === 'all' || field === 'none' || field === '') {
                          tags[1].children[i].children.push({
                            key: 'tag' + field,
                            id: 'tag' + field,
                            name: field === 'none' ? 'None' : field === 'all' ? 'All' : 'No filter' ,
                            type: 'tagUndefined',
                            category: category,
                            iconName: field === 'none' ? 'Tag' : field === 'all' ? 'Tag' : 'ClearFilter' ,
                          })
                        } else if (fields && fields.length > 0 && field.length > 0) {
                          fields.map((item:string)=>{
                            tags[1].children[i].children.push({
                              key: 'tag' + item,
                              id: 'tag' + item,
                              name: item,
                              type: 'tag' + category,
                              category: category,
                            })
                          })
                        }
                      }
                    }
                  }

                } else {
                  tags[1].children.push({
                    key: tag.id,
                    id: tag.id,
                    name: tag.tag,
                    url: '',
                    isExpanded :false,
                    type: "tag",
                    status: "active",
                    repo: tag.repo,
                    search: tag.tag,
                    disabled: searchFolder
                  })
                }

                this.checkSelFilter({
                  key: tag.id,
                  name: tag.tag,
                  type: "tag",
                  repo: tag.repo,
                  search: tag.tag
                })
              }

              if(tags.length === 0) {
                tags.push({
                  key: "empty " + repo.id,
                  name: i18n.t('app:empty'),
                  url: '',
                  isExpanded :false,
                  type: "empty",
                  status: "active",
                  repo: repo.id,
                  search: "",
                  disabled: true
                })
              }

              var searchFilters = this.state.searchFilters;
              if(searchFilters.length > 0) {
                var selRepo: any = searchFilters.filter(searchFilter => {
                  return searchFilter.children[0].id === repo.id
                })

                selRepo[0].children = tags
              }

              tagsTree[0].children.push ({
                  key: repo.id,
                  name: repo.name,
                  isExpanded: false,
                  children: tags,
                  type: "repoFilter"
                })

            }

          /*}).catch(error => {
            console.log(error)
            resolve(true);
          })*/
        
      }

    }

    getTags().then(() => {
      tagsTree[0].children.sort((a,b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase())
            return -1;
        if(b.name.toLowerCase() < a.name.toLowerCase())
            return 1;

        return 0;
      });
      let tree = this.state.itemTree;
      tree[3] = tagsTree[0].children[0].children[0]
      tree[4] = tagsTree[0].children[0].children[1]
      this._isMounted && this.setState({ itemTree: tree})
    });
  }

  private checkSelFilter(item, field?){
    const params = new URLSearchParams(document.location.search);
    if (field && params.get(field) === item.name ) {
      return true
    } else {
      return false
    }
  }

  private checkSelected(){
    const elements:any = document.getElementsByClassName('view-folderlink folderLink selItem');
    if (elements.length > 0) return false;
    else return true;
  }

  private findById = (o, id) => {
    //Early return
    if( o && o.id === id ){
      return o;
    }
    var result, p; 
    for (p in o) {
        if( o.hasOwnProperty(p) && typeof o[p] === 'object' ) {
            result = this.findById(o[p], id);
            if(result){
                return result;
            }
        }
    }
    return result;
  }

  private _filterSearch(ev: React.MouseEvent<HTMLElement> | undefined, item?: INavLink | undefined) {

    if (ev
      && (item && item.type
      && item.type !== "filterFolders"
      && item.type !== "filterTags"
      && item.type !== "filterFileType")) {
      ev.preventDefault();
    }

    if (item && item.type.includes('tag')) {
      let badge = item.search.replace(':',': ')      
      this.props.listExplorerRef.addTag({key:badge,name:badge, color:'grey'})
      setTimeout(()=>{
        this.props.listExplorerRef.handleSearch()
      },50)
    }
  };

  private getStagesArrows(item:any) {
    let selected = false
    let pipeline = this.props.pipeline;
    let nStages = pipeline.milestones.length;
    var index = 0;
    let colors = ['rgba(192,0,0,.8)', 'rgba(200,50,0,.8)', 'rgba(255,155,0,.8)', 'rgba(255,255,0,.8)', 'rgba(200,255,55,.8)', 'rgba(100,255,50,.8)', 'rgba(0,200,0,.8)']
    return (
      <>
        <div className='text-secondary' style={{paddingLeft: '22px', fontSize: 12}}>Stage</div>
        <div className='d-flex align-items-center'>
          <Icon 
            iconName="CircleShapeSolid" 
            className="tag mr-2" 
            style={{
              fontSize:12,
              textAlign:'center',
              color: 'rgb(16, 110, 190)',
              opacity: (item.milestoneIndex)/nStages,
              paddingLeft: '22px'
            }} 
          />
          <span className="m-0 p-0" style={{fontSize: 12, fontWeight: 700}}>{this.props.item.milestone}</span>
        </div>
        <div className='pt-2 text-secondary' style={{paddingLeft: '22px', fontSize: 12}}>Progress</div>
        <div className='d-flex align-items-center align-self-center pt-1' style={{paddingLeft: '22px'}}>
          {pipeline.milestones.map((milestone)=>{
            index++;
            if (!selected && milestone.name !== item.milestone) {
              return (
                <TooltipHost key={milestone.name}  content={pipeline.name + " - " + milestone.name} setAriaDescribedBy={false}>
                  <img className='selected' src={process.env.PUBLIC_URL + '/img/arrow.svg'} style={{opacity: index/nStages, width: '32px', height: '20px'}}/>
                </TooltipHost>
              )
            }
            else if (!selected && milestone.name === item.milestone) {
              selected = true
              return (
                <TooltipHost key={milestone.name}  content={pipeline.name + " - " + milestone.name} setAriaDescribedBy={false}>
                  <img className='selected' src={process.env.PUBLIC_URL + '/img/arrow.svg'} style={{opacity: index/nStages, width: '32px', height: '20px'}}/>
                </TooltipHost>
              )
            }
            else if (selected && milestone.name !== item.milestone) {
              return (
                <TooltipHost key={milestone.name}  content={pipeline.name + " - " + milestone.name} setAriaDescribedBy={false}>
                  <img src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{width: '32px', height: '20px'}}/>
                </TooltipHost>
              )
            }
            else return null;
          })}
        </div>
      </>  
    )
  }

}