import isElectron from 'is-electron';

const getStoredData = (name: string) => {
    if(isElectron()) {
        return localStorage.getItem(name)
    } else {
        let a = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`);
        return a ? a[1] : '';
    }
}

export function authHeader() {
    // return authorization header with jwt token
    let token = getStoredData("token");
    
    if(token) {
        return { 'Authorization': token };
    } else {
        return {};
    }
}