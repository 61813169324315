import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { history } from '../_helpers';
import { Fabric } from '@fluentui/react/lib/Fabric';
import Cookies from 'js-cookie'
import uuid from 'uuid-random';
import Moment from 'moment';
import "moment/min/locales";
import { ContextualMenu, IContextualMenuItem, MessageBarType } from '@fluentui/react';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import { DetailsListLayoutMode, Selection, SelectionMode, IColumn, ConstrainMode } from '@fluentui/react/lib/DetailsList';
import { Icon } from '@fluentui/react/lib/Icon';
import { getFileTypeIconProps, FileIconType } from '@fluentui/react-file-type-icons';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Header } from '../_components/HeaderDrive';
import { Sidebar } from '../_components/SidebarDrive';
import { FileExplorer } from '../_components/FileExplorer';
import { DetailsPanel } from '../_components/DetailsPanelDrive';
import Dropzone from 'react-dropzone'
import { userService } from '../_services';

import i18n from "i18next";
import isElectron from 'is-electron';

const detectMob = () => {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}

declare global {
  interface Window {
      require: any;
      sreader: any;
  }
}

export interface Props {
  match: {
    url: string
  },
  location: any,
  appPlatform: string,
  isOnline: boolean,
  maintenance: boolean,
  socket: any,
  pusher: any,
  appUpdateAvailable: boolean,
  webUpdateAvailable: boolean,
  fileExplorerType: string,
  userData: any,
  foldersList: any,
  newFolder: any,
  repoUsers: any,
  clipboard: any,
  setClipboard: any
  addItemsToFoldersList: any,
  pendingDrafts?: any,
  params: any
}

export interface IDetailsListDashboardState {
  userData?: any;
  repoData?: any;
  goBack: number;
  fileExplorerView: string;
  isLoading: boolean;
  blankLoading: boolean;
  savingTag: boolean;
  savingComment: boolean;
  syncStatus: any;
  downloadingFiles: any;
  uploadsQueue: any;
  creatingFoldersStructure: boolean;
  acceptedFiles: any;
  acceptedFolders: any;
  currentPath: string;
  breadcrumbPath: any[];
  columns: IColumn[];
  versionsColumns: IColumn[];
  items: IDocument[];
  folders: IDocument[];
  fullItemsResponse: any;
  fullItemsResponseByName: any;
  fullItemsResponseByType: any;
  storedFiles: any;
  draggedItem: any;
  selFile: any;
  showLeftPanel: boolean;
  showRightPanel: boolean;
  selectionDetails: string;
  isModalSelection: boolean;
  isCompactMode: boolean;
  newTag: string;
  newComment: string;
  newFolder: string;
  savingFolder: boolean;
  showTagBulkDialog: boolean;
  hideDialog: boolean;
  activeTab: string;
  language: string;
  filterToggle: boolean;
  toolbarHeight: number;
  showContextualMenu: boolean;
  contextMenuId: string;
  fileId: string;
}

export interface IDocument {
  key: string;
  fileName: string;
  name: string;
  fileExtension: string;
  accessGranted: boolean;
  hasPermissions: boolean;
  value: string;
  icon: string;
  fileType: string;
  path: string;
  hasSubFolders: boolean;
  isFolderInPath: boolean;
  lastOpened: string;
  createdBy: any;
  modifiedBy: any;
  dateCreated: string;
  dateDeleted: string;
  dateModified: string;
  dateModifiedValue: number;
  fileSize: string;
  fileSizeRaw: number;
  author: string;
  date: number;
  revisionsCount: number;
  currentVersion: string;
  draft: boolean;
  savingDraft: boolean;
  lock: any;
  lockInstanceId: string;
  wopiLock: any;
  lockingDocument: boolean;
  msg: string;
  hash: string;
  stored: string;
}

class DashboardPage extends React.Component<Props, IDetailsListDashboardState> {
  private _isMounted: boolean;
  private _selection: Selection;
  private _repoInfo: any;
  private _allItems: any;
  private _userData: any;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();
  private fileExplorerRef:any = React.createRef();
  private detailsPanelRef:any = React.createRef();

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    const versionsColumns: IColumn[] = [
      {
        key: 'date',
        name: i18n.t('app:versionsByDate'),
        fieldName: 'dateModifiedValue',
        isSorted: true,
        minWidth: 120,
        isResizable: false,
        isMultiline: true,
        onColumnClick: this._onVersionsColumnClick,
        data: 'number',
        onRender: (item: IDocument) => {
          return (
            <div>
              <p className="font-weight-bold m-0 p-0">
                { Moment(item.date).format(i18n.t('app:dateFormat')) } by <span className="author">{item.author}</span>
              </p>
              <p className="m-0 ml-2 p-0">
                { item.msg }
              </p>
            </div>
          )
        },
        isPadded: false
      }
    ];

    this._selection = new Selection({
      onSelectionChanged: () => {
        this._isMounted && this.setState({
          selFile: this._selection.getSelection()[this._selection.getSelection().length - 1] || []
        });
      }
    });

    console.log(window.location, this.props.params)

    this.state = {
      userData: null,
      repoData: null,
      goBack: 0,
      fileExplorerView: localStorage.fileExplorerView || "list",
      isLoading: true,
      blankLoading: false,
      savingTag: false,
      savingComment: false,
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      downloadingFiles: [],
      uploadsQueue: this._getImports(),
      creatingFoldersStructure: false,
      acceptedFiles: [],
      acceptedFolders: [],
      currentPath: props.match?.url,
      breadcrumbPath: [],
      items: [],
      folders: [],
      fullItemsResponse: [],
      fullItemsResponseByName: [],
      fullItemsResponseByType: [],
      storedFiles: [],
      draggedItem: null,
      columns: [],
      versionsColumns: versionsColumns,
      selFile: null,
      showLeftPanel: true,
      showRightPanel: true,
      selectionDetails: '',
      isModalSelection: false,
      isCompactMode: true,
      newTag: '',
      newComment: '',
      newFolder: '',
      savingFolder: false,
      showTagBulkDialog: false,
      hideDialog: true,
      activeTab: 'details',
      language: i18n.language,
      filterToggle: true,
      toolbarHeight: 89,
      showContextualMenu: false,
      contextMenuId: '',
      fileId: window.location.pathname.replace('/files/', '')
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.props.userData && this._getRepoData();
    this._isMounted && this._getColumns();
    this._isMounted && this._getInitialBreadcrumb();
    this._isMounted && this.props.appPlatform === ("electron" || "openfin") && this._getOfflineFiles();

    window.addEventListener("message", this.onMessageReceived);
    
    this._onResize();
    window.addEventListener('resize', this._onResize);
  }

  public componentDidUpdate(prevProps: any, prevState: IDetailsListDashboardState) {

    if(this.state.language !== i18n.language) {
      this.setState({language: i18n.language});
      this._getCurrentContent();
      this._getColumns();
    }

    if (prevState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
      this._selection.setAllSelected(false);
    }

    if(this.props.userData !== prevProps.userData) {
      this.props.userData && this._getRepoData();
    }

    if (prevState.fileId !== window.location.pathname.replace('/files/', '')
      || this.props.fileExplorerType !== prevProps.fileExplorerType) {
      
      if(this.props.fileExplorerType === prevProps.fileExplorerType && this.props.fileExplorerType === "fileExplorer") {
        this._isMounted && this.setState({
          blankLoading: true,
          items: []
        })
      } else {
        this._isMounted && this.setState({items: []})
      }

      this._isMounted && this.setState({ selFile: null, fileId: window.location.pathname.replace('/files/', '')});
      this._getCurrentContent();
      this._getColumns();
    }

    if (this.props.foldersList !== prevProps.foldersList) {
      if(!prevProps.foldersList && this.props.fileExplorerType !== "openFiles") {
        this._getCurrentContent()
      }
    }

    if (this.props.fileExplorerType !== prevProps.fileExplorerType) {
      this._isMounted && this.setState({ isLoading: true });
    }

  }

  public componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('message', this.onMessageReceived, false);
    window.removeEventListener('resize', this._onResize, false);
  }

  private _onResize = () => {
    var toolbar : HTMLDivElement | null  = document.querySelector('.toolbar');
    if (toolbar!.offsetHeight >= 89) this.setState({toolbarHeight: toolbar!.offsetHeight})
    if (window.innerWidth <= 980 && !isElectron() && detectMob() && this.state.filterToggle) {
      this.setState({filterToggle: false})
    }
    else if (window.innerWidth > 980 && !this.state.filterToggle) {
      this.setState({filterToggle: true})
    }
  }

  private _getColumnSize(column) {
    var dashboardColumnsWidth = JSON.parse(localStorage.getItem("dashboardColumnsWidth") || "[]") || []
    var columnSetting = dashboardColumnsWidth.filter(item => {
      return item.key === column
    })
    columnSetting = columnSetting[0]
    if(columnSetting) return columnSetting.width
    return null;
  }

  private updateItem = (id, name, value) => {
    var items:any = this.state.items
    
    if (items && items.length) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === id) {
          this._isMounted && this.setState(prevState => {
            const items = [...prevState.items];
            items[i] = { ...items[i], [name]: value};
            setTimeout(()=>this.headerRef && this.headerRef.forceUpdate(),100)
            return { items };
          });   
        }
      }
    }
  }

  private updateCopiedItems = (items, contentIds, name) => {
    for (let i=0; i<items.length; i++) {
      if(items[i].clipboard && !contentIds.includes(items[i].id)) items[i].clipboard = false;
      else if (contentIds.includes(items[i].id)) items[i].clipboard = true;
    }
    this._isMounted && this.setState(prevState => {
      const items = [...prevState.items];
      setTimeout(()=>this.headerRef.forceUpdate(),100)
      return { items };
    }); 
  }

  callbackFunction = (childData) => {
      this._isMounted && this.setState(childData)
  }

  onMessageReceived = (event) => {
    var data:any = event.data;

    if (data && data.msg === "uploadItems") {
      let fileEntries:any = {
        files: [],
        folders: []
      };
      var filePaths = data.filePaths;
      var folderPresent = false

      for (let i = 0; i < filePaths.length; i++) {
        let filePath = filePaths[i]
        let isFile = filePath.split('/').pop().indexOf('.') > -1;
        if(isFile) {
          var fileName = filePath.replace(/^.*[\\/]/, '')

          fileEntries.files.push({
            absolutePath: filePath,
            name: fileName,
            fullPath: "/" + fileName 
          })
        } else {
          folderPresent = true
        }
      }

      if(folderPresent) {
        let alertMessageType = MessageBarType.warning
        let alertMessageText = "Only file pasting is allowed."

        this._isMounted && this.fileExplorerRef.setState({
          alertMessage: {
            type: alertMessageType,
            text: alertMessageText
          }
        })

        setTimeout(() => {
          this._isMounted && this.fileExplorerRef.setState({ alertMessage: null })
        }, 4000)
      }
      this.headerRef.setState({inheritTags: (this.headerRef.props.folderId !== this.headerRef.props.repoData[0].id && this.headerRef.props.folderId !== this.headerRef.props.repoData.id+"-templates") ? "KEEP_NEW" : "KEEP_OLD"})
      this.headerRef._askUserToInheritTags(true).then(() => {
        this.headerRef._sameChoiceForAll = false;
        this._sendToUploadsFolder(fileEntries, this.headerRef.state.inheritTags)
      }).catch(reject => {console.log(reject)})
    }
  }

  public render() {
    const { isLoading, blankLoading, columns, isCompactMode, items, selFile } = this.state;
    const modalBackgrounds:any = document.querySelectorAll(".ms-Overlay--dark");
    if (modalBackgrounds && modalBackgrounds.length > 1) {
      for (let i=0; i<modalBackgrounds.length; i++) {
        if (i !== modalBackgrounds.length-1) {
          modalBackgrounds[i].style.backgroundColor = 'rgba(0,0,0,0)';
        }
        else {
          modalBackgrounds[i].style.backgroundColor = 'rgba(0,0,0,0.4)';
        }
      }
    }

    const selectedCount = this.fileExplorerRef._selection && this.fileExplorerRef._selection.getSelectedCount()
    var selFilePathIds = selFile && selFile.pathIds && selFile.pathIds.split("/").slice(1)
    var selFilePathNames = selFile && selFile.pathIds && selFile.pathNames.split("/").slice(1)
    var itemBreadcrumb = selFilePathIds && selFilePathNames && selFilePathIds.map((e, i) => {
      return {
        key: e,
        text: selFilePathNames[i],
        //onClick: () => history.push('/files/' + e),
        onRender: ()=>{return (
          <div id={'crumb-'+e} className='breadcrumb-button' onClick={() => history.push('/files/' + e)} 
            onContextMenu={(event)=>{event.preventDefault(); this.setState({showContextualMenu: true, contextMenuId: e})}}>
            <span className='noselect'>{selFilePathNames[i]}</span>
          </div>
        )},

      }
    });
    if (!itemBreadcrumb && selFile && selFile.parentId) {
      itemBreadcrumb = [{key: selFile.parentId,
        text: selFile.path.replace('/',''),
        onRender: ()=>{return (
          <div id={'crumb-'+selFile.parentId} className='breadcrumb-button' onClick={() => history.push('/files/' +  (selFile ? selFile.parentId : ''))} 
            onContextMenu={(event)=>{event.preventDefault(); this.setState({showContextualMenu: true, contextMenuId: selFile.parentId})}}>
            <span className='noselect'>{selFile.path.replace('/','')}</span>
          </div>
        )},
      }]
    }

    const contextualMenuItems: IContextualMenuItem[] = [
      {
        key: 'openFolder',
        name: i18n.t('app:openFolder'),
        onClick: () => history.push('/files/' + this.state.contextMenuId),
        iconProps: { iconName: 'FabricOpenFolderHorizontal' },
        hidden: !this.props.isOnline || this.props.maintenance
      },
      {
        key: 'openFolderInNewWindow',
        name: i18n.t('app:openFolderInNewWindow'),
        onClick: () => this.fileExplorerRef._openFolderInNewWindow(this.state.contextMenuId),
        iconProps: { iconName: 'OpenInNewWindow' },
        hidden: !this.props.isOnline || this.props.maintenance || !isElectron()
      },
    ]

    var emptyMessage = i18n.t('app:empty')

    if (this.props.fileExplorerType === "fileExplorer") {
      if(window.location.pathname.indexOf("-trash") !== -1) {
        emptyMessage = i18n.t('app:trashIsEmpty')
      } else {
        emptyMessage= i18n.t('app:dropFilesHere')
      }
    } else if (this.props.fileExplorerType === "bookmarks") {
      emptyMessage = i18n.t('app:noBookmarks')
    } else if (this.props.fileExplorerType === "recentFiles") {
      emptyMessage = i18n.t('app:noRecentFiles')
    } else if (this.props.fileExplorerType === "openFiles") {
      emptyMessage = i18n.t('app:noOpenFiles')
    }

    return (
      <Fabric>
        <Header
          match={this.props.match}
          ref={instance => { this.headerRef = instance; }}
          appPlatform={ this.props.appPlatform }
          userData={ this.props.userData }
          isOnline={ this.props.isOnline }
          maintenance={ this.props.maintenance }
          pusher={this.props.pusher}
          repoData={ this.state.repoData }
          selFile={ this.state.selFile }
          foldersList={ this.props.foldersList }
          fileExplorerView={ this.state.fileExplorerView }
          items={items}
          folderId={ window.location.pathname.replace('/files/', '') }
          breadcrumbPath={ this.state.breadcrumbPath }
          syncStatus={ this.state.syncStatus }
          showRightPanel={ this.state.showRightPanel }
          downloadingFiles={ this.state.downloadingFiles }
          uploadsQueue={ this.state.uploadsQueue }
          creatingFoldersStructure={ this.state.creatingFoldersStructure }
          getCurrentContent={ this._getCurrentContent.bind(this) }
          setItemsToUpload={ this._setItemsToUpload.bind(this) }
          socket={this.props.socket}
          detailsPanelRef={ this.detailsPanelRef }
          callbackFunction={ this.callbackFunction }
          updateItem={ this.updateItem }
          fileExplorerType={this.props.fileExplorerType}
          fileExplorerRef={ this.fileExplorerRef }
          sidebarRef={ this.sidebarRef }
          fullItemsResponse={ this.state.fullItemsResponse }
          fullItemsResponseByName={ this.state.fullItemsResponseByName }
          fullItemsResponseByType={ this.state.fullItemsResponseByType }
          repoUsers={this.props.repoUsers}
        />

        <div className="content-wrap d-flex flex-row">
          
          <Sidebar
            ref={instance => { this.sidebarRef = instance; }}
            appPlatform={ this.props.appPlatform }
            isOnline={ this.props.isOnline }
            maintenance={ this.props.maintenance }
            pusher={this.props.pusher}
            appUpdateAvailable={ this.props.appUpdateAvailable }
            webUpdateAvailable={ this.props.webUpdateAvailable }
            userData={ this.props.userData }
            foldersList={ this.props.foldersList }
            newFolder={ this.props.newFolder }
            items={this.state.items}
            folders={this.state.folders}
            repoUsers={this.props.repoUsers}
            folderId={ window.location.pathname.replace('/files/', '') }
            creatingFoldersStructure={ this.state.creatingFoldersStructure }
            pendingDrafts={this.props.pendingDrafts}
            fileExplorerType={this.props.fileExplorerType}
            headerRef={ this.headerRef }
            callbackFunction={ this.callbackFunction }
            fileExplorerRef={ this.fileExplorerRef }
            detailsPanelRef={ this.detailsPanelRef }
          />
          
         

          <div className='flex-grow-1'>
          { (this.props.fileExplorerType === "recentFiles" || this.props.fileExplorerType === "bookmarks") && <>
            <hr className='m-0'/>
              <div className="folders-breadcrumb-search-results flex-grow-1" style={{minWidth:'60px'}}>
                <div className="row m-0 p-0">
                  <div className="breadcrumb-icon">
                    <Icon iconName="FabricFolder" className="repoIcon" />
                  </div>

                  <div className="col m-0 p-0" style={{minWidth:'30px'}}>
                    { selectedCount === 1 && itemBreadcrumb ?
                      <Breadcrumb
                        items={itemBreadcrumb}
                        ariaLabel="Breadcrumb"
                        overflowAriaLabel="Breadcrumb"
                        overflowIndex={ 0 }
                      />
                    : selectedCount > 1 ?
                      <Breadcrumb
                        items={[{
                          text: selectedCount + ' items selected',
                          key: 'multipleItemsBreadcrumb',
                        }]}
                        ariaLabel="Breadcrumb"
                        overflowAriaLabel="More folders"
                        overflowIndex={ 0 }
                      />
                    :
                      <Breadcrumb
                        items={[{
                          text: 'Select an item to show its path',
                          key: 'emptyBreadcrumb',
                        }]}
                        ariaLabel="Breadcrumb"
                        overflowAriaLabel="Breadcrumb"
                        overflowIndex={ 0 }
                      />
                    }
                  </div>
                </div>
              </div>
            <hr className='m-0'/>
          </>}
          <div className={"list mr-auto flex-grow-1 " + this.props.appPlatform + " " + (this.props.fileExplorerType === "recentFiles" || this.props.fileExplorerType === "bookmarks" ? "recents" : "")}>
            <div id="dropzone" className="h-100" 
              onDragEnter={ this.onDragEnter }
              onDragLeave={ this.onDragLeave }
              onDragOver={ this.onDragOver }
              onDrop={this.props.appPlatform === ("electron" || "openfin") ? this.onFileDrop : (e) => {return null}}>
              <Dropzone onDrop={ this.props.appPlatform === ("webapp") ?  (acceptedFiles) => this.headerRef._uploadItems(acceptedFiles) : (e) => {return null}} noClick noKeyboard>
                {({getRootProps}) => (
                  <div {...getRootProps()}>
                    <FileExplorer
                      ref={instance => { this.fileExplorerRef = instance; }}
                      appPlatform={ this.props.appPlatform }
                      isOnline={ this.props.isOnline }
                      maintenance={ this.props.maintenance }
                      socket={this.props.socket}
                      pusher={this.props.pusher}
                      userData={this.props.userData}
                      fileExplorerView={ this.state.fileExplorerView }
                      foldersList={ this.props.foldersList }
                      items={items}
                      folderId={ window.location.pathname.replace('/files/', '') }
                      selFile={ this.state.selFile }
                      location={ this.props.location }
                      isCompactMode={isCompactMode}
                      columns={columns}
                      selectionMode={SelectionMode.multiple}
                      layoutMode={DetailsListLayoutMode.fixedColumns}
                      enableShimmer={isLoading}
                      constrainMode={ConstrainMode.unconstrained}
                      onFileDrop={this.onFileDrop.bind(this)}
                      getCurrentContent={this._getCurrentContent.bind(this)}
                      getOfflineFiles={this._getOfflineFiles.bind(this)}
                      isLoading={isLoading}
                      blankLoading={blankLoading}
                      emptyMessage={emptyMessage}
                      headerRef={ this.headerRef }
                      detailsPanelRef={ this.detailsPanelRef }
                      callbackFunction={ this.callbackFunction }
                      fileExplorerType={this.props.fileExplorerType}
                      updateItem={ this.updateItem }
                      updateCopiedItems={ this.updateCopiedItems }
                      draggedItem={ this.state.draggedItem }
                      sidebarRef={ this.sidebarRef }
                      clipboard={this.props.clipboard}
                      setClipboard={this.props.setClipboard}
                      showLeftPanel={this.state.showLeftPanel}
                      showRightPanel={this.state.showRightPanel}
                    >
                      
                    </FileExplorer>      
                  </div>   
                )}
              </Dropzone>
            </div>
          </div>
          </div>
          
          { this.props.fileExplorerType !== "openFiles" && (window.innerWidth > 980 || isElectron() || !detectMob()) ?
            <DetailsPanel
              ref={instance => { this.detailsPanelRef = instance; }}
              appPlatform={ this.props.appPlatform }
              userData={ this.props.userData }
              repoData={ this.state.repoData }
              foldersList={ this.props.foldersList }
              repoUsers={this.props.repoUsers}
              newFolder={ this.props.newFolder }
              items={ items }
              showRightPanel={ this.state.showRightPanel }
              selection={ this._selection }
              selFile={ this.state.selFile }
              folderId={ window.location.pathname.replace('/files/', '') }
              getCurrentContent={this._getCurrentContent.bind(this)}
              fileId={ window.location.pathname.replace('/files/', '') }
              headerRef={ this.headerRef }
              fileExplorerRef={ this.fileExplorerRef }
              fileExplorerType={ this.props.fileExplorerType }
              detailsPanelSection="explorer"
              addItemsToFoldersList={this.props.addItemsToFoldersList}
              callbackFunction={this.callbackFunction}
            />
          : 
              <DetailsPanel
              ref={instance => { this.detailsPanelRef = instance; }}
              appPlatform={ this.props.appPlatform }
              userData={ this.props.userData }
              repoData={ this.state.repoData }
              foldersList={ this.props.foldersList }
              repoUsers={this.props.repoUsers}
              items={ items }
              showRightPanel={ false }
              selection={ this._selection }
              selFile={ this.state.selFile }
              folderId={ window.location.pathname.replace('/files/', '') }
              getCurrentContent={this._getCurrentContent.bind(this)}
              fileId={ window.location.pathname.replace('/files/', '') }
              fileExplorerRef={ this.fileExplorerRef }
              fileExplorerType={ this.props.fileExplorerType }
              detailsPanelSection="explorer"
            /> }
        </div>
        <ContextualMenu
          items={contextualMenuItems}
          hidden={!this.state.showContextualMenu}
          target={this.state.contextMenuId ? "#crumb-" + this.state.contextMenuId : undefined}
          onItemClick={() => this.setState({showContextualMenu: false})}
          onDismiss={() => this.setState({showContextualMenu: false})}
        />
      </Fabric>
    );
  }

  private _onBreadcrumbItemClicked(ev: React.MouseEvent<HTMLElement> | undefined, item: IBreadcrumbItem | undefined): void {
    if (item) {
      this.setState({goBack: 0})
      history.push('/files/' + item.key)
    }
  }

  private _onRenderBreadcrumbItem = (item: any): JSX.Element | null => {
    return (
      <div className="breadcrumb-item">
        <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } />
        <span>{ item.text }</span>
      </div>
    );
  }

  private _showDialog = (): void => {
    this.setState({ hideDialog: false });
  };

  private _closeDialog = (): void => {
    this.setState({ hideDialog: true });
  };

  private _handleLinkClick = (item: any): void => {
    this.setState({
      activeTab: item.props.itemKey
    });
  };

  private _getTabId = (itemKey: string): string => {
    return `${itemKey}`;
  };

  private _getRepoData() {
    var userData = this.props.userData
    var repo = userData && userData.repository;
    var repoData: any = [];

    repoData.push({
      id: repo.id,
      name: repo.name,
      role: userData.role
    })

    this._isMounted && this.setState({
      repoData: repoData
    }, () => {
      this._getCurrentContent();
    })
  }

  private _getOfflineFiles() {
  }

  private _getFolderDetails(id) {
    userService.getFolderDetails(id).then((response: any) => {
      return response.data;
    }).catch(error => {
      console.log(error)
    });
  }

  private _getCurrentContent() {
    var setItems = (items, childrenFolders) => {
      childrenFolders.sort((a,b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase())
            return -1;
        if(b.name.toLowerCase() < a.name.toLowerCase())
            return 1;

        return 0;
      });

      let folders: any = []
      var userGroups = this.props.userData.groups;

      for (let i = 0; i < childrenFolders.length; i++) {
        let folder = childrenFolders[i];
        folder.kind = "dir"
        const fileType = this._getFileType(folder, "");
        var dateCreated = new Date(folder.created_at).toString();
        var dateDeleted = folder.deleted_at && folder.deleted_at !== 0 ? new Date(folder.deleted_at).toString(): null;
        var dateModified = new Date(folder.modified_at).toString()

        var fullPath = folder.pathNames;
        if(!fullPath) {
          var pathInfo = this.getPathInfo(folder.parent_id)
          fullPath = pathInfo && pathInfo.map(item => {
            return item.text
          })
          fullPath = fullPath && fullPath.join("/")
          fullPath = "/" + fullPath
        }
        folder.path = fullPath;

        var bookmark: any = folder.bookmarks && folder.bookmarks.filter(bookmarkData => {
          return bookmarkData.user_id === this.props.userData.user.id
        })[0] !== undefined;

        var accessGranted: boolean = false;

        var isUser = folder.users.filter(user => {
          return user.id === this.props.userData.user.id
        })

        var isAdmin = folder.admins.filter(admin => {
          return admin === this.props.userData.user.id
        })

        var groupAccess = folder.groups.filter(group => {
          return userGroups.includes(group.id)
        })

        var isExternal = folder.externals.filter(externalUser => {
          return externalUser === this.props.userData.user.id
        })

        if(isAdmin.length > 0
          || isUser.length > 0
          || groupAccess.length > 0
          || isExternal.length > 0)
          accessGranted = true

        folders.push({
          key: folder.id,
          id: folder.id,
          accessGranted: accessGranted,
          name: folder.name,
          fileName: folder.name,
          repo: folder.repo,
          parentId: folder.parent_id,
          path: folder.path,
          pathIds: folder.pathIds,
          pathNames: folder.pathNames,
          hasSubFolders: folder.hasSubFolders,
          links: folder.hasSubFolders ? [{}] : [],
          isFolderInPath: folder.isFolderInPath,
          //icon: fileType.icon,
          fileType: fileType.docType,
          fileExtension: "folder",
          createdBy: folder.created_by,
          modifiedBy: folder.modified_by,
          dateCreated: new Date(dateCreated),
          dateDeleted: dateDeleted ? new Date(dateDeleted) : null,
          dateModified: new Date(dateModified),
          dateModifiedValue: new Date(dateModified),
          fileSize: 0,
          fileSizeRaw: 0,
          bookmark: bookmark,
          admins: folder.admins,
          adminsData: [],
          users: folder.users,
          usersData: [],
          externals: folder.externals,
          externalsData: [],
          groups: folder.groups,
          revision: "HEAD",
          kind: folder.kind,
          tags: folder.tags,
          type: "folder",
          state: {expanded: false}
        });
      }

      var path = window.location.pathname.replace('/files/', '');
      const newItems: any = folders.concat(this.sortAndMapItems(items, path))

      this._isMounted && this.setState({
        fullItemsResponse: newItems,
        fullItemsResponseByName: newItems,
        fullItemsResponseByType: newItems,
        folders: folders
      })
      this.headerRef && this.headerRef.setState({
        filterByNameText: "",
        filterByTypeText: ""
      })

      this.props.addItemsToFoldersList(childrenFolders)

      // Update items list
      var prevItemsString: any = this.state.items.map((item: any) => {
        var compItem = {
          id: item.id
        }
        return compItem
      });
      prevItemsString.sort((a,b) => {
        if(a.id.toLowerCase() < b.id.toLowerCase())
            return -1;
        if(b.id.toLowerCase() < a.id.toLowerCase())
            return 1;

        return 0;
      });
      prevItemsString = JSON.stringify(prevItemsString)
      
      var newItemsString: any = newItems.map(item => {
        var compItem = {
          id: item.id
        }
        return compItem
      });
      newItemsString.sort((a,b) => {
        if(a.id.toLowerCase() < b.id.toLowerCase())
            return -1;
        if(b.id.toLowerCase() < a.id.toLowerCase())
            return 1;

        return 0;
      });
      newItemsString = JSON.stringify(newItemsString)
      
      // if(prevItemsString !== newItemsString) {
      //   userService.list().subscribe();
      // }

      // Update state
      this._isMounted && this.setState({
        isLoading: false,
        blankLoading: false,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        }
      })

      if (typeof(localStorage.getItem("dashboardColumns")) === "string") {
        var dashboardColumns: IColumn[] = JSON.parse(localStorage.getItem("dashboardColumns") || "[]");

        for (let i = 0; i < this.state.columns.length; i++) {
          let column = this.state.columns[i]

          let savedColumn: any = dashboardColumns.filter(savedColumn => {
            return column.key === savedColumn.key
          })
          savedColumn = savedColumn[0]

          if(column && savedColumn) {
            column.isSorted = savedColumn.isSorted;
            column.isSortedDescending = savedColumn.isSortedDescending

            if(column.isSorted) {
              this._sortBySavedColumn(column, newItems);
            }
          } else { this._isMounted && this.setState({items: newItems}, () => {
            this._isMounted && this.props.appPlatform === ("electron" || "openfin") && this._getOfflineFiles();
          }) }
        }
      } else {
        this._isMounted && this.setState({items: newItems}, () => {
          this._isMounted && this.props.appPlatform === ("electron" || "openfin") && this._getOfflineFiles();
        })
      }
    }

    if (this.props.fileExplorerType === "fileExplorer") {
      var id = window.location.pathname.replace('/files/', '').replace('/','');
      console.log(id)

      this._isMounted && this.setState({
        syncStatus: {
          message: "Syncing",
          type: "info",
          icon: "spinner"
        }
      })

      if(id.indexOf("-trash") !== -1) {
        //userService.list().subscribe();
        var repo = id.replace("-trash", "")
        userService.getTrash(repo).then(response => {
          var items: any = []
          items = response.data.documents.filter(document => {
            return document.trash;
          })

          // Folders in trash
          var childrenFolders: any = []
          childrenFolders = response.data.folders.filter(folder => {
            return folder.trash;
          })

          setItems(items, childrenFolders)
        }).catch(error => {
          console.log(error)
        })
      } else {
        var currentLocation = window.location.href.split('?')[0]
        userService.getFolderContent(id).then((response: any) => {
          var items: any = response.data.documents;
          var childrenFolders: any = response.data.folders;
          if (currentLocation === window.location.href.split('?')[0]) setItems(items, childrenFolders)
        }).catch(error => {
          console.log(error)
          // Update state
          this._isMounted && this.setState({
            isLoading: false,
            blankLoading: false,
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            }
          })
        });
      }
    } else if (this.props.fileExplorerType === "recentFiles") {
      this._isMounted && this.setState({
        isLoading: true,
        syncStatus: {
          message: "Syncing",
          type: "info",
          icon: "spinner"
        }
      })
  
      let repo = this.props.userData && this.props.userData.repository.id;
      if(repo) {
        userService.getRecentFiles()
        .then((response: any) => {
          if (response !== undefined && response.data) {
              let list: any = [];
              var items = response.data;

              var mergedItems = Object.entries(items).map((item: any) => {
                var lastOpened = parseInt(item[0]);
                return {...{"lastOpened": lastOpened}, ...item[1]}
              })
              
              mergedItems = mergedItems.filter(item => {
                return !item.trash
              })

              list = this.sortAndMapRecentFiles(mergedItems);
                this._isMounted && this.setState({
                  isLoading: false,
                  blankLoading: false,
                  syncStatus: {
                    message: "Synced",
                    type: "success",
                    icon: "SkypeCircleCheck"
                  }
                })
              
              this._isMounted
              && this.props.fileExplorerType === "recentFiles"
              && this.setState({
                  items: list,
                  fullItemsResponse: list,
                  fullItemsResponseByName: list,
                  fullItemsResponseByType: list
              }, () => {
                this.headerRef && this.headerRef.setState({
                  filterByNameText: "",
                  filterByTypeText: ""
                })
                this._isMounted && this.props.appPlatform === ("electron" || "openfin") && this._getOfflineFiles();
              })
              return list;
            
          } else {
              this._isMounted && this.setState({
                isLoading: false,
                blankLoading: false,
                items: [],
                syncStatus: {
                  message: "Synced",
                  type: "success",
                  icon: "SkypeCircleCheck"
                }
              })
            }
        });
      }
    } else if (this.props.fileExplorerType === "bookmarks") {
      this._isMounted && this.setState({
        isLoading: true,
        syncStatus: {
          message: "Syncing",
          type: "info",
          icon: "spinner"
        }
      })

      let repo = this.props.userData && this.props.userData.repository.id;
      userService.getBookmarks(repo)
      .then((response:any) => {
        var items: any = response.data.documents;
        var childrenFolders: any = response.data.folders;
        if(items.length > 0 || childrenFolders.length > 0) {
          items = items && items.filter(item => {
            return !item.trash
          })

          childrenFolders = childrenFolders && childrenFolders.filter(folder => {
            return !folder.trash
          })

          setItems(items, childrenFolders)
        } else {
          this._isMounted && this.setState({
            isLoading: false,
            blankLoading: false,
            items: [],
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            }
          })
        }
      })
    }
  }

  private sortAndMapRecentFiles(items) {

    function getReadableFileSizeString(fileSizeInBytes) {
      var i = -1;
      var byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
      do {
          fileSizeInBytes = fileSizeInBytes / 1024;
          i++;
      } while (fileSizeInBytes > 1024);

      return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    };

    var documents = items;
    if (items && !items.length && items !== "") {
      documents = [items]
    }
  
    let tmp = {}
    
    documents.forEach(o=> {
      tmp[o.id] = tmp[o.id] || o;
      // assign lower value if applicable
      if( o.val < tmp[o.id].val){         
        tmp[o.id].val = o.val;
      }
    });
    
    documents = Object.values(tmp);

    documents = documents.filter(document => {
      return document.id
    })

    var list : any = [];

    if (documents && documents.length) {
      documents.sort((a,b) => {
        if(a.lastOpened < b.lastOpened)
            return 1;
        if(b.lastOpened < a.lastOpened)
            return -1;

        return 0;
      });
      
      for (let i = 0; i < documents.length; i++) {
        let doc = documents[i];
        var fileExtension = doc.type;
        const fileType = this._getFileType(doc, fileExtension);
        var dateCreated = new Date(doc.created_at).toString();
        var dateModified = new Date(doc.modified_at).toString();

        var currentVersion = (doc.draft ? (i18n.t('app:draftPost')  + "-") : "") + doc.activeMajor + "." + doc.activeMinor 

        var fullPath = doc.pathNames;
        if(!fullPath) {
          var pathInfo = this.getPathInfo(doc.parent_id)
          fullPath = pathInfo && pathInfo.map(item => {
            return item.text
          })
          fullPath = fullPath && fullPath.join("/")
          fullPath = "/" + fullPath
        }

        var fileSize = "";
        if (doc.size) {
          fileSize = getReadableFileSizeString(doc.size)
        }

        var stored: string;
        var checkStored = this.state.storedFiles && this.state.storedFiles.filter(storedFile => {
          return storedFile.id === doc.id
        })
        stored = checkStored.length > 0 ? "same" : "no"

        var tags = doc.tags;

        if (tags) {
          tags.sort((a, b) => {
              if(a.toLowerCase() < b.toLowerCase()) { return -1; }
              if(a.toLowerCase() > b.toLowerCase()) { return 1; }
              return 0;
          })
        } else {
          tags = [];
        }

        doc.revisions && doc.revisions.map(revision => {
          return revision.version = revision.major + "." + revision.minor
        })

        var revisions = doc.revisions && doc.revisions.filter(revision => {
          return !revision.deleted
        });

        if (revisions) {
          revisions.sort((a, b) => {
            if(a.timestamp > b.timestamp) { return -1; }
            if(a.timestamp < b.timestamp) { return 1; }
            return 0;
          })

          for (let i = 0; i < revisions.length; i++) {
            var version = revisions[i];
            version.isActiveRevision = version.fileId === doc.activeRevision;
          }

          if (doc.draft) {
            revisions.unshift({
              author: doc.modified_by,
              fileId: doc.id + "-draft",
              message: "Draft",
              previousVersion: null,
              size: doc.size,
              timestamp: doc.modified_at,
              version: "draft"
            })
          }
        }

        var bookmark: any = doc.bookmarks && doc.bookmarks.filter(bookmarkData => {
          return bookmarkData.user_id === this.props.userData.user.id
        })[0] !== undefined;

        list.push({
            key: doc.id,
            id: doc.id,
            hash: doc.hash,
            fileName: doc.name,
            name: doc.title,
            fileExtension: fileExtension,
            parentId: doc.parent_id,
            repo: doc.repo,
            parent_id: doc.parent_id,
            hasPermissions: doc.hasPermissions,
            path: fullPath,
            pathNames: doc.pathNames,
            pathIds: doc.pathIds,
            icon: fileType.icon,
            fileType: fileType.docType,
            createdBy: doc.created_by,
            modifiedBy: doc.modified_by,
            lastOpened: doc.lastOpened,
            dateCreated: new Date(dateCreated),
            dateModified: new Date(dateModified),
            dateModifiedValue: new Date(dateModified),
            fileSize: fileSize,
            fileSizeRaw: doc.size,
            status: doc.status,
            tags: tags,
            bookmark: bookmark,
            revisions: revisions,
            activeMajor: doc.activeMajor,
            activeMinor: doc.activeMinor,
            activeRevisionId:  doc.activeRevision,
            currentVersion: currentVersion,
            revisionsCount: doc.revisionsCount,
            draft: doc.draft,
            lock: doc.lock,
            lockInstanceId: doc.lockInstanceId,
            wopiLock: doc.wopiLock,
            thumbnailCreated: doc.thumbnailCreated,
            stored: stored,
            kind: "file",
            trash: doc.trash
        });
      };
    }

    return list;
  }

  private sortAndMapItems(items, path) {

    function getReadableFileSizeString(fileSizeInBytes) {
      var i = -1;
      var byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
      do {
          fileSizeInBytes = fileSizeInBytes / 1024;
          i++;
      } while (fileSizeInBytes > 1024);

      return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    };

    var documents = items;
    var list : any = [];

    if (documents && documents.length) {
      var folders = this.props.foldersList;
      var currentFolder: any;
      if(folders){
        currentFolder = folders.filter(folder => {
          return folder.id === path
        })[0]
      }

      if((path && path.indexOf("-trash") !== -1) 
        || (currentFolder && currentFolder.trash)) {
        documents = documents.filter(item => {
          return item.trash;
        })
      } else if (path && path.indexOf("-trash") === -1) {
        documents = documents.filter(item => {
          return (item.parent_id === path && !item.trash);
        })
      }

      documents.sort((a,b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase())
            return -1;
        if(b.name.toLowerCase() < a.name.toLowerCase())
            return 1;

        return 0;
      });

      for (let i = 0; i < documents.length; i++) {
        let doc = documents[i];
        var fileExtension = doc.type;
        const fileType = this._getFileType(doc, fileExtension);
        var dateCreated = new Date(doc.created_at).toString();
        var dateDeleted = doc.deleted_at && doc.deleted_at !== 0 ? new Date(doc.deleted_at).toString() : null;
        var dateModified = new Date(doc.modified_at).toString();

        var fileSize = "";
        if (doc.size) {
          fileSize = getReadableFileSizeString(doc.size)
        }

        var currentVersion = (doc.draft ? (i18n.t('app:draftPost')  + "-") : "") + doc.activeMajor + "." + doc.activeMinor

        var stored: string;
        var checkStored = this.state.storedFiles && this.state.storedFiles.filter(storedFile => {
          return storedFile.id === doc.id
        })
        stored = checkStored.length > 0 ? "same" : "no"

        var fullPath = "/"
        var breadcrumbPath = this.headerRef && this.headerRef.state && this.headerRef.state.breadcrumbPath
        if (breadcrumbPath) {
          fullPath = breadcrumbPath.map(folder => {
            return folder.text
          }).join("/")
          fullPath = "/" + fullPath
        }

        var tags = doc.tags;

        if (tags) {
          tags.sort((a, b) => {
              if(a.toLowerCase() < b.toLowerCase()) { return -1; }
              if(a.toLowerCase() > b.toLowerCase()) { return 1; }
              return 0;
          })
        } else {
          tags = [];
        }

        doc.revisions.map(revision => {
          return revision.version = revision.major + "." + revision.minor
        })

        var revisions = doc.revisions.filter(revision => {
          return !revision.deleted
        });

        revisions.sort((a, b) => {
            if(a.timestamp > b.timestamp) { return -1; }
            if(a.timestamp < b.timestamp) { return 1; }
            return 0;
        })

        for (let i = 0; i < revisions.length; i++) {
          var version = revisions[i];
          version.isActiveRevision = version.fileId === doc.activeRevision;
        }

        if (doc.draft) {
          revisions.unshift({
            author: doc.modified_by,
            fileId: doc.id + "-draft",
            message: "Draft",
            previousVersion: null,
            size: doc.size,
            timestamp: doc.modified_at,
            version: "draft"
          })
        }

        var bookmark: any = doc.bookmarks && doc.bookmarks.filter(bookmarkData => {
          return bookmarkData.user_id === this.props.userData.user.id
        })[0] !== undefined;

        list.push({
          key: doc.id,
          id: doc.id,
          hash: doc.hash,
          fileName: doc.name,
          name: doc.title,
          fileExtension: fileExtension,
          parentId: doc.parent_id,
          hasPermissions: doc.hasPermissions,
          repo: doc.repo,
          path: fullPath,
          pathNames: doc.pathNames,
          pathIds: doc.pathIds,
          icon: fileType.icon,
          fileType: fileType.docType,
          createdBy: doc.created_by,
          modifiedBy: doc.modified_by,
          dateCreated: new Date(dateCreated),
          dateDeleted: dateDeleted ? new Date(dateDeleted) : null,
          dateModified: new Date(dateModified),
          dateModifiedValue: new Date(dateModified),
          fileSize: fileSize,
          fileSizeRaw: doc.size,
          status: doc.status,
          tags: tags,
          bookmark: bookmark,
          revisions: revisions,
          activeMajor: doc.activeMajor,
          activeMinor: doc.activeMinor,
          activeRevisionId:  doc.activeRevision,
          currentVersion: currentVersion,
          draft: doc.draft,
          lock: doc.lock,
          lockInstanceId: doc.lockInstanceId,
          wopiLock: doc.wopiLock,
          thumbnailCreated: doc.thumbnailCreated,
          stored: stored,
          kind: "file",
          trash: doc.trash
        });
      };
    }
    return list;
  }

  private sortAndMapOpenFiles(items) {

    function getReadableFileSizeString(fileSizeInBytes) {
      var i = -1;
      var byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
      do {
          fileSizeInBytes = fileSizeInBytes / 1024;
          i++;
      } while (fileSizeInBytes > 1024);

      return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    };

    var documents = items;
    if (items && !items.length && items !== "") {
      documents = [items]
    }

    var list : any = [];

    if (documents && documents.length) {
      documents.sort((a,b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase())
            return -1;
        if(b.name.toLowerCase() < a.name.toLowerCase())
            return 1;

        return 0;
      });
      
      for (let i = 0; i < documents.length; i++) {
        var doc = documents[i];
        var fileExtension = doc.type;
        const fileType = this._getFileType(doc, fileExtension);

        var fileSize = "";
        if (doc.stats && doc.stats.size) {
          fileSize = getReadableFileSizeString(doc.stats.size)
        }

        list.push({
          key: doc.id,
          id: doc.id,
          fileName: doc.fileName,
          name: doc.name,
          fileExtension: fileExtension,
          parentId: doc.parentId,
          icon: fileType.icon,
          fileType: fileType.docType,
          fileSize: fileSize,
          fileSizeRaw: doc.stats ? doc.stats.size : 0,
          kind: "file"
        });
      };
    }

    return list;
  }

  private getPathInfo(id) {
    var folders = this.props.foldersList;

    if(folders) {

      var folderId = id;
      let foldersData = folders
      let repos = this.state.repoData

      var currentFolder = foldersData.filter(folder => {
        return folder.id === folderId
      })
      currentFolder = currentFolder[0]


      var breadcrumb: any = [];

      if(currentFolder && currentFolder.path_id) {
        
        breadcrumb.push({
            text: currentFolder.name,
            key: currentFolder.id
          })

        var getParentData = (parentId) => {
          var parentData;

          parentData = foldersData.filter(data => {
            return parentId === data.id
          })

          if(!parentData.length) {
            parentData = repos && repos.filter(data => {
              return parentId === data.id
            })
          }

          parentData = parentData[0]

          if(parentData) {
            breadcrumb.unshift({
              text: parentData.name,
              key: parentData.id
            })
          }

          if(parentData && parentData.parent_id)
            getParentData(parentData.parent_id)
        }

        getParentData(currentFolder.parent_id)
      } else {
        // Check if root folder
        let folder = repos && repos.filter(data => {
          return data.id === folderId
        })[0]

        if (folder)
          breadcrumb.unshift({
              text: folder.name,
              key: folder.id
            })
      }
    }

    return breadcrumb
  }

  private _onRenderLink = (link: any): JSX.Element | null => {
    return (
      link.type === "repo" ?
        <div className="folderLink" onClick={ evt => {
        evt.preventDefault(); this.setState({goBack: 0}) } }>
          <Link to={ "/files/"}>
            <Icon   iconName="OfflineStorage" className="repoIcon" />
            <span>{ link.name }</span>
          </Link>
        </div>
      : link.type === "folder" ?
        <div className="folderLink" onClick={ evt => { evt.preventDefault(); this.setState({goBack: 0}) } }>
          <Link to={ "/files/" + link.key}>
            <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } />
            <span>{ link.name }</span>
          </Link>
        </div>
      : null 
    )
  }

  private _getFileType(doc, fileExtension): { docType: string; icon: string } {
    var kind = doc.kind;

    if (kind === "dir") {
      const docType: string = "dir"
      return {
        docType,
        icon: "folder"
      }
    } else {
      const docType: string = "file"
      if (fileExtension === "mhtml") {
        fileExtension = "html";
      }
      if (fileExtension === "email") {
        fileExtension = "eml";
      }

      return {
        docType,
        icon: fileExtension
      }
    }
  }

  private _getKey(item: any, index?: number): string {
    return item.key;
  }

  private _onChangeCompactMode = (ev: React.MouseEvent<HTMLElement>, checked: any): void => {
    this._isMounted && this.setState({ isCompactMode: checked });
  };

  private _onChangeModalSelection = (ev: React.MouseEvent<HTMLElement>, checked: any): void => {
    this._isMounted && this.setState({ isModalSelection: checked });
  };

  private _indexDocument(doc) {
    userService.indexDocument(doc).then((response:any) => {});
  }

  onDragOver = (e) => {
    e.dataTransfer.dropEffect = 'copy'
    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onDragEnter = (e) => {
    e.dataTransfer.dropEffect = 'copy'
    if(this.fileExplorerRef
      && !this.fileExplorerRef.state.draggedItem) {
      var el: any = document.getElementsByClassName("ms-ScrollablePane")
      if(el[0]) {
        for(var i = 0; i < el.length; i++) {
          if (!el[i].classList.contains("dragOverFileExplorerList")) {
            el[i].classList.add('dragOverFileExplorerList');
          }
        }
      }
    }

    let event:any = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onDragLeave = (e) => {
    if (e.target.className.indexOf("ms-ScrollablePane") !== -1) {
      var el: any = document.getElementsByClassName("ms-ScrollablePane")
      for(var i = 0; i < el.length; i++) {
        el[i].classList.remove('dragOverFileExplorerList');
      }
    }

    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onFileDrop = (e) => {
    var el: any = document.getElementsByClassName("ms-ScrollablePane")
    for(var i = 0; i < el.length; i++) {
      el[i].classList.remove('dragOverFileExplorerList');
    }
    
    let event:any = e as Event;
    event.stopPropagation();
    event.preventDefault();

    var sourceItems = event.dataTransfer.items
    var sourceFiles = event.dataTransfer.files

    this.getAllFileEntries(sourceItems).then(response => {
      this.headerRef.setState({inheritTags: (this.headerRef.props.folderId !== this.headerRef.props.repoData[0].id && this.headerRef.props.folderId !== this.headerRef.props.repoData.id+"-templates") ? "KEEP_NEW" : "KEEP_OLD"})
      if (response.files[0] || response.folders[0]) {
        var canUpload = true
        // for (let i=0; i < sourceFiles.length; i++) {
        //   if (sourceFiles[i].path &&
        //     (sourceFiles[i].path.includes('\\AppData\\Local\\Temp\\')
        //     || sourceFiles[i].path.includes('/com.microsoft.Outlook/'))) canUpload = false;
        // }
          !canUpload && this.headerRef._messageUploadAlert();
          canUpload && this.headerRef._askUserToInheritTags(true).then(async() => { //Drag and drop Desktop
            this.headerRef._sameChoiceForAll = false;
            if (sourceFiles.length === 1 && response.folders.length === 0) {
              console.log(response,sourceFiles)
              //Get number of repeated items
              var repeatedItems = 0;
              for (let i = 0; i < sourceFiles.length; i++) {
                let item = sourceFiles[i];
                var currentItems:any = this.state.items;
                let repeated = currentItems.filter(data => {
                  return data.fileName === item.name;
                })
                if (repeated.length > 0) repeatedItems++;
              }
              for (let i=0; i<sourceFiles.length;i++) {
                currentItems = this.state.items;
                let item = sourceFiles[i];

                let repeatedItem = currentItems.filter(data => {
                  return data.fileName === item.name;
                })
                
                if (repeatedItem.length > 0 && sourceFiles.length === 1) {
                  const repeatedLength = repeatedItem.length >= 2;
                  repeatedItem = repeatedItem[0]
                  await new Promise((resolve, reject) => {
                    const index = i+1;
                    this.headerRef._askUserForRepeatedItem(repeatedItem, repeatedLength, repeatedItems, index).then((data) => {
                      this._setItemsToUpload(response, sourceFiles, this.headerRef.state.inheritTags)
                      resolve(true);
                    }).catch(async(reject) => {
                      if (reject === 'append') {
                        resolve(true);
                        this.headerRef._appendVersion(repeatedItem, item)
                      }
                      else if (reject === 'replace') {
                        await userService.deleteDocuments([repeatedItem.id]).then(()=>{
                          this._setItemsToUpload(response, sourceFiles, this.headerRef.state.inheritTags)
                        })
                      }
                      resolve(true);
                    });
                  })

                } else {
                  this._setItemsToUpload(response, sourceFiles, this.headerRef.state.inheritTags)
                }
              }
            }
            else {
              this._setItemsToUpload(response, sourceFiles, this.headerRef.state.inheritTags)
            }
          }).catch(reject => {console.log(reject)})
      } else {
        if (this.props.appPlatform === ("electron" || "openfin")) {
          const { ipcRenderer } = window.require("electron");
          (async () => {
            var draggedItem = await ipcRenderer.invoke('getDraggedItem')
            if(draggedItem) {
              this.setState({draggedItem: draggedItem})
            } else {
              this.setState({draggedItem: null})
            }
            ipcRenderer.send('clearDraggedItem')
          })();
        }
      }
    });
  }

  private _setItemsToUpload(traversedItems, sourceFiles, inheritTags) {
    console.log(traversedItems, sourceFiles)

    if (this.props.appPlatform === ("electron" || "openfin")) {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.send('clearDraggedItem')
    }

    var foldersPresent = traversedItems.folders && traversedItems.folders.length > 0
    console.log("Folders present", foldersPresent)

    for (let i = 0; i < sourceFiles.length; i++) {
      var sourceFile = sourceFiles[i]
      var sourceFilePath = sourceFiles[i].path.replace(/\\/g,"/");
      let lastFolder = sourceFilePath.replace(/\/$/, "").split("/").pop();

      let matchFolder = lastFolder;
      if(matchFolder.replace(/ *\([^)]*\) */g, "") === sourceFiles[i].name) matchFolder = matchFolder.replace(/ *\([^)]*\) */g, "")

      var filesInFolder = traversedItems.files.filter(item => {
        var fullPath = item.fullPath.replace(/\\/g,"/");
        var firsFolder = fullPath.split("/")[1];
        return firsFolder === matchFolder;
      })

      console.log(filesInFolder)

      if(filesInFolder.length > 0) {
        if (sourceFilePath.substr(0,sourceFile.path.lastIndexOf('\\')).length)
          sourceFilePath = sourceFilePath.substr(0,sourceFile.path.lastIndexOf('\\'))
        else 
          sourceFilePath = sourceFilePath.substr(0,sourceFile.path.lastIndexOf('/'))

        for (let i = 0; i < filesInFolder.length; i++) {
          var absolutePath = foldersPresent ? sourceFilePath + filesInFolder[i].fullPath : sourceFile.path;
          if(lastFolder.replace(/ *\([^)]*\) */g, "") === absolutePath) absolutePath = lastFolder
          filesInFolder[i].absolutePath = absolutePath;
        }
      }
    }

    if(traversedItems.files.length > 0 || traversedItems.folders.length > 0) {
      this._sendToUploadsFolder(traversedItems, inheritTags)
    }
  }

  private traverseFileTree(item, path) {
    path = path || "";
    if (item.isFile) {
      // Get file
      item.file((file) => {});
    } else if (item.isDirectory) {
      // Get folder contents
      var dirReader = item.createReader();
      dirReader.readEntries((entries) => {
        for (var i=0; i<entries.length; i++) {
          this.traverseFileTree(entries[i], path + item.name + "/");
        }
      });
    }
  }

  // Drop handler function to get all files
  async getAllFileEntries(dataTransferItemList) {
    let fileEntries:any = {
      files: [],
      folders: []
    };
    // Use BFS to traverse entire directory/file structure
    let queue:any = [];
    // Unfortunately dataTransferItemList is not iterable i.e. no forEach
    for (let i = 0; i < dataTransferItemList.length; i++) {
      queue.push(dataTransferItemList[i].webkitGetAsEntry());
    }
    while (queue.length > 0) {
      let entry:any = queue.shift();
      if (entry && entry.isFile) {
        if(!entry.name.startsWith(".")) fileEntries.files.push(entry);
      } else if (entry && entry.isDirectory) {
        fileEntries.folders.push(entry);
        queue.push(...await this.readAllDirectoryEntries(entry.createReader()));
      }
    }
    return fileEntries;
  }

  // Get all the entries (files or sub-directories) in a directory 
  // by calling readEntries until it returns empty array
  async readAllDirectoryEntries(directoryReader) {
    let entries:any = [];
    let readEntries:any = await this.readEntriesPromise(directoryReader);
    while (readEntries && readEntries.length > 0) {
      entries.push(...readEntries);
      readEntries = await this.readEntriesPromise(directoryReader);
    }
    return entries;
  }

  // Wrap readEntries in a promise to make working with readEntries easier
  // readEntries will return only some of the entries in a directory
  // e.g. Chrome returns at most 100 entries at a time
  async readEntriesPromise(directoryReader) {
    try {
      return await new Promise((resolve, reject) => {
        directoryReader.readEntries(resolve, reject);
      });
    } catch (err) {
      console.log("--------------------------------")
      console.log("READ FOLDER ENTRY ERROR")
      console.log("--------------------------------")
      console.log(err);
    }
  }

  acceptedFiles: any = []
  acceptedFolders: any = []

  private _sendToUploadsFolder(items, inheritTags?) {
    this.headerRef && this.headerRef.showUploadsCallout();

    this.acceptedFiles = [];
    this.acceptedFolders = [];

    let newFolders: any = [];
    var acceptedFolders: any = [];

    for (let i=0; i<items.files.length; i++) {
      this.acceptedFiles.push(items.files[i])
    }
    this.acceptedFolders = items.folders;

    var createFolders = async (folders, activityFeedId) => {
      this._isMounted && this.setState({ creatingFoldersStructure: true })
      //Header Web
      //Get number of repeated items
      var repeatedItems = 0;
      for (let i = 0; i < folders.length; i++) {
        let item = folders[i];
        var currentItems:any = this.state.items;
        let repeated = currentItems.filter(data => {
          return data.fileName === item.name;
        })
        if (repeated.length > 0) repeatedItems++;
      }
      for (let i=0; i<folders.length; i++) {
        let folder = folders[i];
        var parents = folder.fullPath.split("/");
        let path = window.location.pathname.replace('/files/', '');
          if (parents.length > 2) {
            let parent = parents.slice(0, parents.length - 1).join("/");
            var parentId = folders.filter(item => {
              return item.fullPath === parent
            })
            path = parentId[0].id
          }

          if (path === window.location.pathname.replace('/files/', '')) {
            let pushFolder = {
              name: folder.name,
              fullPath: folder.fullPath,
              subFolders: []
            }

            currentItems = this.state.items;

            let repeatedItem = currentItems.filter(data => {
              return data.fileName === folder.name;
            })
            
            if (repeatedItem.length > 0) {
              const repeatedLength = repeatedItem.length >= 2;
              repeatedItem = repeatedItem[0]
              await new Promise((resolve, reject) => {
                const index = i+1;
                this.headerRef._askUserForRepeatedItem(repeatedItem, repeatedLength, repeatedItems, index).then((data) => {                  newFolders.push(pushFolder)
                  resolve(true);
                }).catch(reject => {
                  console.log(reject)
                  resolve(true);
                });
              })

            } else {
              newFolders.push(pushFolder)
            }
          }

          if (parents.length > 2) {
            let parent = parents.slice(0, parents.length - 1).join("/");
            const findItemNested = (arr, itemId, nestingKey) => (
              arr.reduce((a, item) => {
                if (a) return a;
                if (item.fullPath === itemId) return item;
                if (item[nestingKey]) return findItemNested(item[nestingKey], itemId, nestingKey)
                return null;
              }, null)
            );
            const res = findItemNested(newFolders, parent, "subFolders");

            if(res) {
              res.subFolders.push({
                name: folder.name,
                fullPath: folder.fullPath,
                subFolders: []
              })
            }
          }
      };

      var selFolderId = window.location.pathname.replace('/files/', '');
      var migrateUsers = true;
      
      await new Promise((resolve, reject) => {
        userService.createFolders(selFolderId, migrateUsers, newFolders, activityFeedId, inheritTags).then((response: any) => {
          this._isMounted && this.setState({ creatingFoldersStructure: false })
          acceptedFolders = response.data
          resolve(response);
          return response
        }).catch(error => {
          console.log(error)
          this._isMounted && this.setState({ creatingFoldersStructure: false })
          reject(error);
          return [];
        });
      });
    }

    userService.createActivityFeedId().then(response => {
      var activityFeedId = response.data

      createFolders(this.acceptedFolders, activityFeedId).then(() => {
        var folders = acceptedFolders

        var setFileContent = async (fileList) => {
          for await (let fileEntry of fileList) {
            var parents = fileEntry.fullPath.split("/");
          
            if (parents.length > 2) {
              var parentId = folders.filter(item => {
                return item.fullPath + "/" + fileEntry.name === fileEntry.fullPath
              })
              if(parentId[0] && parentId[0].folder) fileEntry.destination = parentId[0].folder.id
            } else {
              fileEntry.destination = window.location.pathname.replace('/files/', '');
            }
            fileEntry.fileName = fileEntry.name
            fileEntry.uploadStatus = "pending"
            fileEntry.automaticRetryAttempts = 0
            fileEntry.userId = this.props.userData.user.id
            fileEntry.timestamp = new Date().getTime()
            fileEntry.activityFeedId = activityFeedId
            fileEntry.fileId = "File-" + uuid()
          }
        }

        setFileContent(this.acceptedFiles).then(() => {
          var acceptedFiles = this.acceptedFiles.filter(acceptedFile => {
            return acceptedFile.destination;
          })

          var instanceId = Cookies.get('instanceId') || localStorage.getItem("instanceId")
            if(!instanceId) {
              instanceId = "Origin-" + uuid()
              Cookies.set("instanceId", origin,{ domain: Cookies.get('serverdomain'), expires: 30, sameSite: 'none', secure: true})
              localStorage.setItem("instanceId", instanceId)
            }

            var userData = this.props.userData
            var repos = userData.repositoryDetailsList;
            var repo = repos[0].repository.id

          acceptedFiles = acceptedFiles.map(file => {
            return {
              fileName: file.fileName,
              absolutePath: file.absolutePath,
              fileId: "File-" + uuid(),
              destination: file.destination,
              activityFeedId: activityFeedId,
              origin: instanceId,
              repo: repo
            }
          })

          if (acceptedFiles.length > 0) {

            let i, j, temporary, chunk = 5000;

            
            let addImportedFiles = async (i, temporary, chunk) => {
              for (i = 0, j = acceptedFiles.length; i < j; i += chunk) {
                temporary = acceptedFiles.slice(i, i + chunk);
                let acceptedFilesChunk: any = []

                for (let x=0; x< temporary.length; x++) {
                  acceptedFilesChunk.push(temporary[x])
                }
                await new Promise((resolve, reject) => {
                  userService.addFilesToUploadsDb(acceptedFilesChunk).then(() => {
                    resolve(true)
                  }).catch(error => {
                    console.log(error)
                    reject(error)
                  })
                })
              }
            }
          }
        });
      }).catch(error => {
        console.log(error)
        if(error.status !== 405) alert(i18n.t('app:couldNotCreateFolders'))
      })
    }).catch(error => {
      console.log(error)
    })
  }

  private _getImports() {
    if(localStorage.getItem("uploadsQueue")) {
      return JSON.parse(localStorage.getItem("uploadsQueue") || "{}");
    } else {
      return [];
    }
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        newCol.isSortedDescending = !currColumn.isSortedDescending;
        newCol.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    var newItems;
    if (column.key === "name") {
      newItems = _copyAndSortName(items, currColumn.fieldName!, currColumn.isSortedDescending);
    } else {
      newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    }

    localStorage.setItem("dashboardColumns", JSON.stringify(newColumns))

    this._isMounted && this.setState({
      columns: newColumns,
      items: newItems
    }, () => {
      this._isMounted && this.props.appPlatform === ("electron" || "openfin") && this._getOfflineFiles();
    });
  };

  private _getInitialBreadcrumb () {
    var breadcrumb: any = [
      {
        text: 'Synergy Drive',
        key: '/',
        onClick: this._onBreadcrumbItemClicked.bind(this)
      }
    ]
    
    if (this.props.fileExplorerType === "recentFiles") {
      breadcrumb = [
        {
          text: i18n.t('app:recentFiles'),
          key: 'recentFiles'
        }
      ]
    } else if (this.props.fileExplorerType === "openFiles") {
      breadcrumb = [
        {
          text: i18n.t('app:openFiles'),
          key: 'openFiles'
        }
      ]
    } else if (this.props.fileExplorerType === "bookmarks") {
      breadcrumb = [
        {
          text: i18n.t('app:bookmarks'),
          key: 'bookmarks'
        }
      ]
    }

    this._isMounted && this.setState({
      breadcrumbPath: breadcrumb
    })
  }

  private getVersionColor(item:any) {
    if (item.revisions) {
      let revision = item.revisions[0]
      if (revision.version === "draft") return '#ffc107'
      else if (revision.minor !== "00" && revision.external) return '#cbf5c8'
      else if (revision.minor === "00" && revision.external) return '#28a745'
      else if (revision.minor !== "00" && !revision.external) return '#C7E0F4'
      else return '#007bff'
    } else return ''
  }

  private _getColumns() {
    var columns: any = []

    if (this.props.fileExplorerType === "openFiles") {
      columns = [
        {
          key: 'fakeColumn',
          name: 'fakeColumn',
          fieldName: 'conflict',
          minWidth: 0,
          maxWidth: 0,
          isRowHeader: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          data: 'string',
          isPadded: true,
          styles: { root: { display: "none" } },
          className: "d-none"
        },
        {
          key: 'icon',
          name: <div><Icon style={{fontSize: '40%', marginRight: '27px', marginLeft: 0, color: 'transparent'}} iconName="StatusCircleRing" className="offline-status small my-auto" />#</div>,
          ariaLabel: 'Column operations for File type, Press to sort on File type',
          fieldName: 'fileType',
          minWidth: window.innerWidth > 980 || isElectron() || !detectMob() ? 50 : 32,
          maxWidth: window.innerWidth > 980 || isElectron() || !detectMob() ? 50 : 32,
          //isSortedDescending: false,
          //onColumnClick: this._onColumnClick,
          isPadded: false,
          onRender: (item: IDocument) => {
              if (window.innerWidth > 980 || isElectron() || !detectMob()) {
                return (<>
                  <Icon style={{fontSize: '40%', marginRight: '22px', marginLeft: 0, color: 'transparent' || this.getVersionColor(item)}} iconName="CircleFill" className="offline-status small my-auto" />
                  {item.fileType === 'dir' ?
                    <>
                      <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } />
                      { !item.accessGranted && item.isFolderInPath ?
                        <Icon iconName="ChevronRightSmall" className="no-access-folder" />
                      : !item.accessGranted && !item.isFolderInPath ?
                        <Icon iconName="lockSolid" className="no-access-folder" />
                      : null }
                    </>
                  : item.fileType === 'file' ?
                    <Icon {...getFileTypeIconProps({ extension: item.icon, size: 20, imageFileType: 'svg' }) } />
                  : null }
                  </>)
              }
              else {
                return (
                  item.fileType === 'dir' ?
                    <>
                      <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 32, imageFileType: 'svg' }) } />
                      { !item.accessGranted && item.isFolderInPath ?
                        <Icon iconName="ChevronRightSmall" className="no-access-folder" />
                      : !item.accessGranted && !item.isFolderInPath ?
                        <Icon iconName="lockSolid" className="no-access-folder" />
                      : null }
                    </>
                  : item.fileType === 'file' ?
                    <Icon {...getFileTypeIconProps({ extension: item.icon, size: 32, imageFileType: 'svg' }) } />
                  : null 
                  )
              }
          }
      },
        {
          key: 'name',
          name: i18n.t('app:name'),
          fieldName: 'name',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          isSorted: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: (item: IDocument) => {
            return (
                !item.lock && !item.wopiLock ?
                  <span title={ item.fileName }>{ item.name }</span>
                : item.wopiLock ?
                  <span title={ item.fileName }><Icon iconName="OfficeLogo" className="mr-2 small" />{ item.name }</span>
                :
                  <span title={ item.fileName }><Icon iconName="Lock" className={"mr-2 small" + (item.lock.id === this.props.userData.user.id ? " text-primary" : "")} />{ item.name }</span>
              )
          }
        },
        {
          key: 'type',
          name: i18n.t('app:type'),
          fieldName: 'fileExtension',
          minWidth: 32,
          maxWidth: 32,
          isResizable: true,
          data: 'string',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return (
                item.fileType === "dir" ?
                  <span>{i18n.t('app:lowerFolder')}</span>
                : item.fileType === "file" ?
                  <span>{'.' + item.fileExtension}</span>
                : null
              )
          },
          isPadded: true
        },
        {
          key: 'fileSize',
          name: <div>{i18n.t('app:size')}</div>,
          fieldName: 'fileSizeRaw',
          minWidth: 64,
          maxWidth: 64,
          isResizable: true,
          data: 'number',
          headerClassName: 'headerToRight',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return (
              item.fileType === "file" ?
              <div style={{marginRight: 0, marginLeft: 'auto'}}>
                <span>{item.fileSize}</span>
              </div>
              : null
            )
          }
        }
      ]
    } else if (this.props.fileExplorerType === "recentFiles") {
      columns = [
        {
          key: 'icon',
          name: <div><Icon style={{fontSize: '40%', marginRight: '27px', marginLeft: 0, color: 'transparent'}} iconName="StatusCircleRing" className="offline-status small my-auto" />#</div>,
          ariaLabel: 'Column operations for File type, Press to sort on File type',
          fieldName: 'fileType',
          minWidth: window.innerWidth > 980 || isElectron() || !detectMob() ? 50 : 32,
          maxWidth: window.innerWidth > 980 || isElectron() || !detectMob() ? 50 : 32,
          //isSortedDescending: false,
          //onColumnClick: this._onColumnClick,
          isPadded: false,
          onRender: (item: IDocument) => {
              if (window.innerWidth > 980 || isElectron() || !detectMob()) {
                return (<>
                  <Icon style={{fontSize: '40%', marginRight: '22px', marginLeft: 0, color: 'transparent' || this.getVersionColor(item)}} iconName="CircleFill" className="offline-status small my-auto" />
                  {item.fileType === 'dir' ?
                    <>
                      <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } />
                      { !item.accessGranted && item.isFolderInPath ?
                        <Icon iconName="ChevronRightSmall" className="no-access-folder" />
                      : !item.accessGranted && !item.isFolderInPath ?
                        <Icon iconName="lockSolid" className="no-access-folder" />
                      : null }
                    </>
                  : item.fileType === 'file' ?
                    <Icon {...getFileTypeIconProps({ extension: item.icon, size: 20, imageFileType: 'svg' }) } />
                  : null }
                  </>)
              }
              else {
                return (
                  item.fileType === 'dir' ?
                    <>
                      <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 32, imageFileType: 'svg' }) } />
                      { !item.accessGranted && item.isFolderInPath ?
                        <Icon iconName="ChevronRightSmall" className="no-access-folder" />
                      : !item.accessGranted && !item.isFolderInPath ?
                        <Icon iconName="lockSolid" className="no-access-folder" />
                      : null }
                    </>
                  : item.fileType === 'file' ?
                    <Icon {...getFileTypeIconProps({ extension: item.icon, size: 32, imageFileType: 'svg' }) } />
                  : null 
                  )
              }
          }
        },
        {
          key: 'name',
          name: i18n.t('app:name'),
          fieldName: 'name',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: (item: IDocument) => {
            return (
                <div>
                  { item.savingDraft ?
                    <span title={ "Saving..." }><Icon iconName="Save" className="saving mr-2 small" /></span>
                  : null }
                  { item.lock && !item.wopiLock && !item.lockingDocument ?
                    <Icon iconName="Lock" className={"mr-2 small" + (item.lock.id === this.props.userData.user.id ? " text-primary" : "")} />
                  : item.wopiLock && !item.lockingDocument ?
                    <Icon iconName="OfficeLogo" className="mr-2 small" />
                  : item.lockingDocument ?
                    <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
                  :
                    null
                  }
                  <span title={ item.fileName }>{ item.name }</span>
                </div>
              )
          }
        },
        {
          key: 'type',
          name: i18n.t('app:type'),
          fieldName: 'fileExtension',
          minWidth: 32,
          maxWidth: 32,
          isResizable: true,
          data: 'string',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return (
                item.fileType === "dir" ?
                  <span>{i18n.t('app:lowerFolder')}</span>
                : item.fileType === "file" ?
                  <span>{'.' + item.fileExtension}</span>
                : null
              )
          },
          isPadded: true
        },
        {
          key: 'path',
          name: i18n.t('app:path'),
          fieldName: 'path',
          minWidth: 170,
          isResizable: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: (item: IDocument) => {
            return (
                <span title={item.path}>{item.path}</span>
              )
          }
        },
        {
          key: 'currentVersion',
          name: i18n.t('app:version'),
          fieldName: 'currentVersion',
          minWidth: 80,
          maxWidth: 80,
          isResizable: true,
          data: 'number',
          headerClassName: 'headerToRight',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return <div style={{marginRight: 0, marginLeft: 'auto'}}>
              {item.fileType === "file" ? <span style={{display: 'flex'}}>{item.currentVersion}<Icon style={{fontSize: '40%', position: 'relative', top: '1px', marginLeft: '10px', color: this.getVersionColor(item)}} iconName="CircleFill" className="offline-status small my-auto" /></span> : null}
            </div>
          }
        },
        {
          key: 'fileSize',
          name: i18n.t('app:size'),
          fieldName: 'fileSizeRaw',
          minWidth: 64,
          maxWidth: 64,
          isResizable: true,
          data: 'number',
          headerClassName: 'headerToRight',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return (
              item.fileType === "file" ?
              <div style={{marginRight: 0, marginLeft: 'auto'}}>
                <span>{item.fileSize}</span>
              </div>
              : null
            )
          }
        },
        {
          key: 'stored',
          name: i18n.t('app:stored'),
          isIconOnly: true,
          iconName: "Download",
          ariaLabel: 'Column operations for stored files, Press to sort on stored files',
          fieldName: 'stored',
          minWidth: 12,
          maxWidth: 12,
          isSortedDescending: false,
          onColumnClick: this._onColumnClick,
          isPadded: false,
          onRender: (item: IDocument) => {
            return (<div className='d-flex align-items-center justify-content-center'>
              {item.stored === "same" ?
                <Icon style={{fontSize: 14, fontWeight: 600}} iconName="CheckMark" className="text-success" />
              : item.stored === "different" ?
                <Icon style={{fontSize: 14, fontWeight: 600}} iconName="SwitcherStartEnd" className="text-primary" />
              :  item.stored === "downloading" ?
                <Icon style={{fontSize: 14, fontWeight: 600}} iconName="Sync" className="text-primary small" />
              :  item.stored === "removing" ?
                <Icon style={{fontSize: 14, fontWeight: 600}} iconName="Sync" className="text-danger small" />
                
              :
                null}
            </div>)
          }
        },
        {
          key: 'lastOpened',
          name: i18n.t('app:lastOpened'),
          fieldName: 'lastOpened',
          minWidth: 100,
          maxWidth: 100,
          isResizable: true,
          isSorted: true,
          isSortedDescending: true,
          data: 'number',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
              return <span>{ Moment(item.lastOpened).format('Y-MM-DD HH:mm') }</span>;
          },
          isPadded: true
        }
      ];
    } else if (this.props.fileExplorerType === "bookmarks") {
      columns = [
        {
          key: 'icon',
          name: <div><Icon style={{fontSize: '40%', marginRight: '27px', marginLeft: 0, color: 'transparent'}} iconName="StatusCircleRing" className="offline-status small my-auto" />#</div>,
          ariaLabel: 'Column operations for File type, Press to sort on File type',
          fieldName: 'fileType',
          minWidth: window.innerWidth > 980 || isElectron() || !detectMob() ? 50 : 32,
          maxWidth: window.innerWidth > 980 || isElectron() || !detectMob() ? 50 : 32,
          //isSortedDescending: false,
          //onColumnClick: this._onColumnClick,
          isPadded: false,
          onRender: (item: IDocument) => {
              if (window.innerWidth > 980 || isElectron() || !detectMob()) {
                return (<>
                  <Icon style={{fontSize: '40%', marginRight: '22px', marginLeft: 0, color: 'transparent' || this.getVersionColor(item)}} iconName="CircleFill" className="offline-status small my-auto" />
                  {item.fileType === 'dir' ?
                    <>
                      <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } />
                      { !item.accessGranted && item.isFolderInPath ?
                        <Icon iconName="ChevronRightSmall" className="no-access-folder" />
                      : !item.accessGranted && !item.isFolderInPath ?
                        <Icon iconName="lockSolid" className="no-access-folder" />
                      : null }
                    </>
                  : item.fileType === 'file' ?
                    <Icon {...getFileTypeIconProps({ extension: item.icon, size: 20, imageFileType: 'svg' }) } />
                  : null }
                  </>)
              }
              else {
                return (
                  item.fileType === 'dir' ?
                    <>
                      <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 32, imageFileType: 'svg' }) } />
                      { !item.accessGranted && item.isFolderInPath ?
                        <Icon iconName="ChevronRightSmall" className="no-access-folder" />
                      : !item.accessGranted && !item.isFolderInPath ?
                        <Icon iconName="lockSolid" className="no-access-folder" />
                      : null }
                    </>
                  : item.fileType === 'file' ?
                    <Icon {...getFileTypeIconProps({ extension: item.icon, size: 32, imageFileType: 'svg' }) } />
                  : null 
                  )
              }
          }
        },
        {
          key: 'name',
          name: i18n.t('app:name'),
          fieldName: 'name',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: (item: IDocument) => {
            return (
                <div>
                  { item.savingDraft ?
                    <span title={ "Saving..." }><Icon iconName="Save" className="saving mr-2 small" /></span>
                  : null }
                  { item.lock && !item.wopiLock && !item.lockingDocument ?
                    <Icon iconName="Lock" className={"mr-2 small" + (item.lock.id === this.props.userData.user.id ? " text-primary" : "")} />
                  : item.wopiLock && !item.lockingDocument ?
                    <Icon iconName="OfficeLogo" className="mr-2 small" />
                  : item.lockingDocument ?
                    <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
                  :
                    null
                  }
                  <span title={ item.fileName }>{ item.name }</span>
                </div>
              )
          }
        },
        {
          key: 'type',
          name: i18n.t('app:type'),
          fieldName: 'fileExtension',
          minWidth: 32,
          maxWidth: 32,
          isResizable: true,
          data: 'string',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return (
                item.fileType === "dir" ?
                  <span>{i18n.t('app:lowerFolder')}</span>
                : item.fileType === "file" ?
                  <span>{'.' + item.fileExtension}</span>
                : null
              )
          },
          isPadded: true
        },
        {
          key: 'path',
          name: i18n.t('app:path'),
          fieldName: 'path',
          minWidth: 170,
          isResizable: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: (item: IDocument) => {
            return (
                <span title={item.path}>{item.path}</span>
              )
          }
        },
        {
          key: 'currentVersion',
          name: i18n.t('app:version'),
          fieldName: 'currentVersion',
          minWidth: 80,
          maxWidth: 80,
          isResizable: true,
          data: 'number',
          headerClassName: 'headerToRight',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return <div style={{marginRight: 0, marginLeft: 'auto'}}>
              {item.fileType === "file" ? <span style={{display: 'flex'}}>{item.currentVersion}<Icon style={{fontSize: '40%', position: 'relative', top: '1px', marginLeft: '10px', color: this.getVersionColor(item)}} iconName="CircleFill" className="offline-status small my-auto" /></span> : null}
            </div>
          }
        },
        {
          key: 'fileSize',
          name: i18n.t('app:size'),
          fieldName: 'fileSizeRaw',
          minWidth: 64,
          maxWidth: 64,
          isResizable: true,
          data: 'number',
          headerClassName: 'headerToRight',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return (
              item.fileType === "file" ?
              <div style={{marginRight: 0, marginLeft: 'auto'}}>
                <span>{item.fileSize}</span>
              </div>
              : null
            )
          }
        },
        {
          key: 'stored',
          name: i18n.t('app:stored'),
          isIconOnly: true,
          iconName: "Download",
          ariaLabel: 'Column operations for stored files, Press to sort on stored files',
          fieldName: 'stored',
          minWidth: 12,
          maxWidth: 12,
          isSortedDescending: false,
          onColumnClick: this._onColumnClick,
          isPadded: false,
          onRender: (item: IDocument) => {
            return (<div className='d-flex align-items-center justify-content-center'>
              {item.stored === "same" ?
                <Icon style={{fontSize: 14, fontWeight: 600}} iconName="CheckMark" className="text-success" />
              : item.stored === "different" ?
                <Icon style={{fontSize: 14, fontWeight: 600}} iconName="SwitcherStartEnd" className="text-primary" />
              :  item.stored === "downloading" ?
                <Icon style={{fontSize: 14, fontWeight: 600}} iconName="Sync" className="text-primary small" />
              :  item.stored === "removing" ?
                <Icon style={{fontSize: 14, fontWeight: 600}} iconName="Sync" className="text-danger small" />
                
              :
                null}
            </div>)
          }
        },
        {
          key: 'dateModified',
          name: i18n.t('app:modified'),
          fieldName: 'dateModified',
          minWidth: 100,
          maxWidth: 100,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'number',
          onRender: (item: IDocument) => {
            return (
              item.fileType === "file" ?
                <span>{ Moment(item.dateModified).format('Y-MM-DD HH:mm') }</span>
              : null
            )
          },
          isPadded: true
        },
        {
          key: 'modifiedBy',
          name: i18n.t('app:modifiedBy'),
          fieldName: 'modifiedBy',
          minWidth: 70,
          maxWidth: 90,
          isResizable: true,
          data: 'string',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            if(item.createdBy) {
              return <span className="author">{item.modifiedBy.name}</span>;
            } else {
              return null
            }
          },
          isPadded: true
        }
      ];
    } else {
      columns = [
        {
          key: 'icon',
          name: <div><Icon style={{fontSize: '40%', marginRight: '27px', marginLeft: 0, color: 'transparent'}} iconName="StatusCircleRing" className="offline-status small my-auto" />#</div>,
          ariaLabel: 'Column operations for File type, Press to sort on File type',
          fieldName: 'fileType',
          minWidth: window.innerWidth > 980 || isElectron() || !detectMob() ? 50 : 32,
          maxWidth: window.innerWidth > 980 || isElectron() || !detectMob() ? 50 : 32,
          //isSortedDescending: false,
          //onColumnClick: this._onColumnClick,
          isPadded: false,
          onRender: (item: IDocument) => {
              if (window.innerWidth > 980 || isElectron() || !detectMob()) {
                return (<>
                <Icon style={{fontSize: '40%', marginRight: '22px', marginLeft: 0, color: 'transparent' || this.getVersionColor(item)}} iconName="CircleFill" className="offline-status small my-auto" />
                {item.fileType === 'dir' ?
                  <>
                    <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } />
                    { !item.accessGranted && item.isFolderInPath ?
                      <Icon iconName="ChevronRightSmall" className="no-access-folder" />
                    : !item.accessGranted && !item.isFolderInPath ?
                      <Icon iconName="lockSolid" className="no-access-folder" />
                    : null }
                  </>
                : item.fileType === 'file' ?
                  <Icon {...getFileTypeIconProps({ extension: item.icon, size: 20, imageFileType: 'svg' }) } />
                : null }
                </>)
              }
              else {
                return (
                  item.fileType === 'dir' ?
                    <>
                      <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 32, imageFileType: 'svg' }) } />
                      { !item.accessGranted && item.isFolderInPath ?
                        <Icon iconName="ChevronRightSmall" className="no-access-folder" />
                      : !item.accessGranted && !item.isFolderInPath ?
                        <Icon iconName="lockSolid" className="no-access-folder" />
                      : null }
                    </>
                  : item.fileType === 'file' ?
                    <Icon {...getFileTypeIconProps({ extension: item.icon, size: 32, imageFileType: 'svg' }) } />
                  : null 
                  )
              }
          }
        },
        {
          key: 'name',
          name: i18n.t('app:name'),
          fieldName: 'name',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          isSorted: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
          onRender: (item: IDocument) => {
            return (
                <div>
                  { item.savingDraft ?
                    <span title={ "Saving..." }><Icon iconName="Save" className="saving mr-2 small" /></span>
                  : null }
                  { item.lock && !item.wopiLock && !item.lockingDocument ?
                    <Icon iconName="Lock" className={"mr-2 small" + (item.lock.id === this.props.userData.user.id ? " text-primary" : "")} />
                  : item.wopiLock && !item.lockingDocument ?
                    <Icon iconName="OfficeLogo" className="mr-2 small" />
                  : item.lockingDocument ?
                    <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
                  :
                    null
                  }
                  <span title={ item.fileName }>{ item.name }</span>
                </div>
              )
          }
        },
        {
          key: 'type',
          name: i18n.t('app:type'),
          fieldName: 'fileExtension',
          minWidth: 32,
          maxWidth: 32,
          isResizable: true,
          data: 'string',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return (
                item.fileType === "dir" ?
                  <span>{i18n.t('app:lowerFolder')}</span>
                : item.fileType === "file" ?
                  <span>{'.' + item.fileExtension}</span>
                : null
              )
          },
          isPadded: true
        },
        {
          key: 'currentVersion',
          name: i18n.t('app:version'),
          fieldName: 'currentVersion',
          minWidth: 100,
          maxWidth: 100,
          isResizable: true,
          data: 'number',
          headerClassName: 'headerToRight',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return <div style={{marginRight: 0, marginLeft: 'auto'}}>
              {item.fileType === "file" ? <span style={{display: 'flex'}}>{item.currentVersion}<Icon style={{fontSize: '40%', position: 'relative', top: '1px', marginLeft: '10px', color: this.getVersionColor(item)}} iconName="CircleFill" className="offline-status small my-auto" /></span> : null}
            </div>
          }
        },
        {
          key: 'fileSize',
          name: i18n.t('app:size'),
          fieldName: 'fileSizeRaw',
          minWidth: 64,
          maxWidth: 64,
          isResizable: true,
          data: 'number',
          headerClassName: 'headerToRight',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return (
              item.fileType === "file" ?
              <div style={{marginRight: 0, marginLeft: 'auto'}}>
                <span>{item.fileSize}</span>
              </div>
              : null
            )
          }
        },
        {
          key: 'stored',
          name: i18n.t('app:stored'),
          isIconOnly: true,
          iconName: "Download",
          ariaLabel: 'Column operations for stored files, Press to sort on stored files',
          fieldName: 'stored',
          minWidth: 12,
          maxWidth: 12,
          isSortedDescending: false,
          onColumnClick: this._onColumnClick,
          isPadded: false,
          onRender: (item: IDocument) => {
            return (<div className='d-flex align-items-center justify-content-center'>
              {item.stored === "same" ?
                <Icon style={{fontSize: 10, fontWeight: 600}} iconName="CheckMark" className="text-success" />
              : item.stored === "different" ?
                <Icon style={{fontSize: 10, fontWeight: 600}} iconName="SwitcherStartEnd" className="text-primary" />
              :  item.stored === "downloading" ?
                <Icon style={{fontSize: 10, fontWeight: 600}} iconName="Sync" className="text-primary small" />
              :  item.stored === "removing" ?
                <Icon style={{fontSize: 10, fontWeight: 600}} iconName="Sync" className="text-danger small" />
                
              :
                null}
            </div>)
          }
        },
        window.location.pathname.replace('/files/', '').indexOf("-trash") !== -1 ?
          {
            key: 'dateDeleted',
            name: i18n.t('app:deleted'),
            fieldName: 'dateDeleted',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onColumnClick: this._onColumnClick,
            data: 'number',
            onRender: (item: IDocument) => {
              return (
                item.dateDeleted ?
                  <span>{ Moment(item.dateDeleted).format('Y-MM-DD HH:mm') }</span>
                : null
              )
            },
            isPadded: true
          }
        :
          {
            key: 'dateModified',
            name: i18n.t('app:modified'),
            fieldName: 'dateModified',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onColumnClick: this._onColumnClick,
            data: 'number',
            onRender: (item: IDocument) => {
              return (
                item.fileType === "file" ?
                  <span>{ Moment(item.dateModified).format('Y-MM-DD HH:mm') }</span>
                : null
              )
            },
            isPadded: true
          }
        ,
        {
          key: 'modifiedBy',
          name: i18n.t('app:modifiedBy'),
          fieldName: 'modifiedBy',
          minWidth: 70,
          maxWidth: 90,
          isResizable: true,
          data: 'string',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            if(item.createdBy) {
              return <span className="author">{item.modifiedBy.name}</span>;
            } else {
              return null
            }
          },
          isPadded: true
        }
      ]
    }

    this._isMounted && this.setState({
      columns: columns
    })
  }

  private _sortBySavedColumn = (column: IColumn, items: any): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];

    var newItems;
    if (column.key === "name") {
      newItems = _copyAndSortName(items, currColumn.fieldName!, currColumn.isSortedDescending);
    } else {
      newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    }

    this._isMounted && this.setState({
      columns: newColumns,
      items: newItems
    }, () => {
      this._isMounted && this.props.appPlatform === ("electron" || "openfin") && this._getOfflineFiles();
    });
  };

  private _onVersionsColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { versionsColumns, selFile } = this.state;
    const newColumns: IColumn[] = versionsColumns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    var newItems = _copyAndSort(selFile.revisions, currColumn.fieldName!, currColumn.isSortedDescending);

    var newSelFile = this.state.selFile;
    newSelFile.revisions = newItems

    this._isMounted && this.setState({
      versionsColumns: newColumns,
      selFile: newSelFile
    });
  };
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

function _copyAndSortName<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  return items.slice(0).sort((a,b) => {
    if (!isSortedDescending) {
      if(a["fileType"] < b["fileType"])
          return -1;
      if(b["fileType"] < a["fileType"])
          return 1;

      return a["name"].localeCompare(b["name"]);
    } else {
      if(a["fileType"] > b["fileType"])
          return -1;
      if(b["fileType"] > a["fileType"])
          return 1;

      return b["name"].localeCompare(a["name"]);
    }

  });
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

export default withParams(DashboardPage);
