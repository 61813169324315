import * as React from 'react';
import { IDetailsHeaderProps, IDetailsList, IRenderFunction, ScrollablePane, ScrollbarVisibility, ShimmeredDetailsList, Sticky, StickyPositionType, Selection, TagPicker, ITag, IBasePicker, ActionButton} from '@fluentui/react';
import { IconButton } from '@fluentui/react';
import { Persona } from '@fluentui/react/lib/Persona';
import { history } from '../_helpers';
import i18n from '../i18n';

const testTags: ITag[] = [
  {key: 'Milestone: Done', name: 'Milestone: Done', type: 'milestone', color:'grey'},
  {key: 'pending', name: 'Milestone: Pending', type: 'milestone', color:'yellow'},
  {key: 'inProgress', name: 'Milestone: In progress', type: 'milestone', color:'green'},
  {key: '', name: 'Owner: Francisco Fontinha', type: 'owner'},
  {key: '', name: 'Owner: Jaime Rivas', type: 'owner'},
  {key: '', name: 'Owner: Manuel Marcos', type: 'owner'},
  {key: '', name: 'Owner: Michael Bommers', type: 'owner'},
  {key: '', name: 'Owner: Xavier Campillo', type: 'owner'},
  {key: '', name: 'Company: 3M', type: 'company'},
  {key: '', name: 'Company: Boeing', type: 'company'},
  {key: '', name: 'Company: Caterpillar', type: 'company'},
  {key: '', name: 'Company: Cisco', type: 'company'},
  {key: '', name: 'Company: Intel', type: 'company'},
  {key: '', name: 'Company: Nike', type: 'company'},
  {key: '', name: 'Company: Pfizer', type: 'company'},
  {key: '', name: 'Company: Procter & Gamble', type: 'company'},
].map(item => ({ key: item.name, name: item.name}));

const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false;
  }
  return tagList.some(compareTag => compareTag.key === tag.key);
};

const filterSuggestedTags = (filter: string, tagList: any): any[] => {
  return filter
    ? testTags.filter(
        tag => tag.name.toLowerCase().indexOf(filter.toLowerCase()) === 0 && !listContainsTagList(tag, tagList),
      )
    : [];
};

export interface Props {
  match: {
    url: string
  },
  location: any,
  searchType: string,
  userData: any,
  page: string,
  callbackFunction: any,
  
}

export interface ListExplorerState {
  webUpdateAvailable: boolean;
  userData?: any;
  repoData?: any;
  goBack: number;
  showContextualMenu: boolean;
  selFolder: any;
  items: any;
  selectedTags: any;
  groups: any;
}

export class ListExplorer extends React.Component<Props, ListExplorerState> {
  private _isMounted: boolean;
  private _selection: Selection;
  private _root:any = React.createRef<IDetailsList>();  
  private picker:any = React.createRef<IBasePicker<ITag>>();

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props


    this._selection = new Selection({
      onSelectionChanged: () => {
        var selItem: any = this._selection.getSelection()[this._selection.getSelection().length - 1]

        setTimeout(() => {
          if (selItem && this._selection.getSelectedCount() === 1) {
            this._isMounted && this.props.callbackFunction({selItem: selItem});
          } else if (selItem && this._selection.getSelectedCount() > 1) {
            this._isMounted && this.props.callbackFunction({selItem: null});
          } else if (this._selection.getSelectedCount() === 0) {
            this._isMounted && this.props.callbackFunction({selItem: null});
          }
        }, 50)
      }
    });

    this.state = {
      webUpdateAvailable: false,
      userData: null,
      repoData: null,
      goBack: 0,
      showContextualMenu: false,
      selFolder: null,
      items: this.props.items,
      selectedTags: [],
      groups: [],
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    if (this.props.page === "clientsExplorer"){ 
      let groups = _getGroups(this.props.items, 'company')
      this._isMounted && this.setState({groups:groups})
    }
    else if (this.props.page === "milestonesExplorer"){ 
      let groups = _getGroups(this.props.items, 'milestone') 
      this._isMounted && this.setState({groups:groups})
    }
    else if (this.props.page === "pipelinesExplorer"){ 
      let groups = _getGroups(this.props.items, 'pipeline') 
      this._isMounted && this.setState({groups:groups})
    }
    else if (this.props.page === "projectsExplorer"){ 
      this._isMounted && this.setState({groups:null})
    }
    else if (this.props.page === "companiesExplorer"){ 
      this._isMounted && this.setState({groups:null})
    }
    else if (this.props.page === "contactsExplorer"){ 
      this._isMounted && this.setState({groups:null})
    }
    else if (this.props.page === "tasksExplorer") {
      let groups = _getGroups(this.props.items, 'milestone')
      this._isMounted && this.setState({groups:groups})
    }
    else if (this.props.page === "pipelines") {
      let groups = _getGroups(this.props.items, 'pipeline')
      this._isMounted && this.setState({groups:groups})
    }

  }
  
  public componentDidUpdate(prevProps:any) {
    if(this.props.items !== prevProps.items) {
      this.handleSearch()
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  onMessageReceived(event:any) {
    var data:any = event.data;

      if (data && data.msg === "webUpdateAvailable") {
        this._isMounted && this.setState({webUpdateAvailable: true})
      }
  }

  private handleSearch() {
    let items = this.picker.current.selection._items
    var newItems:any = this.props.items;
    if (items.length > 0) {
      
      this._isMounted && this.setState({items: newItems})
      if (this.props.page === "clientsExplorer"){ 
        let groups = _getGroups(newItems, 'company')
        this._isMounted && this.setState({groups:groups})
      }
      else if (this.props.page === "milestonesExplorer"){ 
        let groups = _getGroups(newItems, 'milestone') 
        this._isMounted && this.setState({groups:groups})
      }
      else if (this.props.page === "pipelinesExplorer"){ 
        let groups = _getGroups(newItems, 'pipeline') 
        this._isMounted && this.setState({groups:groups})
      }
      else if (this.props.page === "projectsExplorer"){ 
        this._isMounted && this.setState({groups:null})
      }
      else if (this.props.page === "companiesExplorer"){ 
        this._isMounted && this.setState({groups:null})
      }
      else if (this.props.page === "contactsExplorer"){ 
        this._isMounted && this.setState({groups:null})
      }
      else if (this.props.page === "tasksExplorer") {
        let groups = _getGroups(newItems, 'milestone')
        this._isMounted && this.setState({groups:groups})
      }
      else if (this.props.page === "pipelines") {
        let groups = _getGroups(newItems, 'pipeline')
        this._isMounted && this.setState({groups:groups})
      }

    }
    else {
      //this.picker.current.addItem({key:'Marketing',name:'Marketing'})
      this._isMounted && this.setState({items: this.props.items})
      if (this.props.page === "clientsExplorer"){ 
        let groups = _getGroups(this.props.items, 'company')
        this._isMounted && this.setState({groups:groups})
      }
      else if (this.props.page === "milestonesExplorer"){ 
        let groups = _getGroups(this.props.items, 'milestone') 
        this._isMounted && this.setState({groups:groups})
      }
      else if (this.props.page === "pipelinesExplorer"){ 
        let groups = _getGroups(this.props.items, 'pipeline') 
        this._isMounted && this.setState({groups:groups})
      }
      else if (this.props.page === "projectsExplorer"){ 
        this._isMounted && this.setState({groups:null})
      }
      else if (this.props.page === "companiesExplorer"){ 
        this._isMounted && this.setState({groups:null})
      }
      else if (this.props.page === "contactsExplorer"){ 
        this._isMounted && this.setState({groups:null})
      }
      else if (this.props.page === "tasksExplorer") {
        let groups = _getGroups(newItems, 'milestone')
        this._isMounted && this.setState({groups:groups})
      }
      else if (this.props.page === "pipelines") {
        let groups = _getGroups(newItems, 'pipeline')
        this._isMounted && this.setState({groups:groups})
      }

    }

  }

  private clearSearch() {
    this.picker.current.removeItems(this.picker.current.selection._items)
    setTimeout(()=>this.handleSearch(),100)
  }

  private _onItemInvoked(item) {
    if (this.props.page === "companiesExplorer") {
      history.push('/companies/' + item['Company ID'])
    } else if (this.props.page === "projectsExplorer") {
      history.push('/projects/' + item.id)
    } else if (this.props.page === "clientsExplorer") {
      history.push('/projects/' + item.id)
    } else if (this.props.page === "milestonesExplorer") {
      history.push('/projects/' + item.id)
    } else if (this.props.page === "boardExplorer") {
      history.push('/projects/' + item.id)
    } else if (this.props.page === "pipelinesExplorer") {
      history.push('/projects/' + item.id)
    } else if (this.props.page === "contactsExplorer") {
      history.push('/contacts/' + item['Contact ID'])
    }
  }

  public render() {

    return(
     
        <div>
          <div className='d-flex flex-grow-1 align-items-center' style={{height: '46px', borderBottom: '1px solid #f2f2f2'}}>
            <span className='ml-3'>
              Main view
            </span>
          </div>
          <form className='d-flex mx-3 pt-2' onSubmit={(ev)=>{ev.preventDefault();this.handleSearch()}} style={{position:'sticky', left: '1rem', top: 0, zIndex: 1, background: 'white'}}>
            <TagPicker 
              className="mr-1" 
              styles={{root: {width:'100%'}}} 
              onResolveSuggestions={filterSuggestedTags}
              inputProps={{
                id: 'picker1',
                style: {paddingRight: '29px'}
              }}
              componentRef={this.picker}
              onRenderItem={(item:any)=> {
                var tagType = item.key.split(': ')[0];
                var tagName = item.key.split(': ')[1];
                return(
                  tagType === 'Milestone' ?
                    <div key={item.key} className='d-flex align-items-center tag-badge' style={{
                      backgroundColor: tagName === 'Done' ? '#07c873': tagName === 'Pending' ? '#c4c4c4' : tagName === 'In progress' ? '#fec06e' : 'white', 
                      borderRadius:'20px', 
                      paddingLeft:'6px'
                      }}>
                      <span>{tagName}</span>
                      <IconButton onClick={()=>this.picker.current.removeItem(item.item)} className='remove-tag-button' iconProps={{iconName:'Cancel', style:{fontSize:14}}}/>
                    </div>
                  : tagType === 'Owner' ?
                    <div key={item.key} className='d-flex align-items-center tag-badge' style={{backgroundColor:'#f4f4f4', borderRadius:'20px'}}>
                      <span><Persona className="tag" size={10} text={tagName}/></span>
                      <IconButton onClick={()=>this.picker.current.removeItem(item.item)} className='remove-tag-button' iconProps={{iconName:'Cancel', style:{fontSize:14}}}/>
                    </div>
                  : tagType === 'Company' ?
                    <div key={item.key} className='d-flex align-items-center tag-badge' style={{backgroundColor:'#f4f4f4', borderRadius:'20px', paddingLeft:'6px'}}>
                      <span>{tagName}</span>
                      <IconButton onClick={()=>this.picker.current.removeItem(item.item)} className='remove-tag-button' iconProps={{iconName:'Cancel', style:{fontSize:14}}}/>
                    </div>
                  : <div key={item.key} className='d-flex align-items-center tag-badge' style={{backgroundColor:'#f4f4f4', borderRadius:'20px', paddingLeft:'6px'}}>
                      <span>{tagName}</span>
                      <IconButton onClick={()=>this.picker.current.removeItem(item.item)} className='remove-tag-button' iconProps={{iconName:'Cancel', style:{fontSize:14}}}/>
                    </div>
                )
              }}
            />
            <IconButton 
              iconProps={{iconName:'Cancel'}}
              onClick={this.clearSearch.bind(this)}
              className='clear-search-button'
            />
          </form>
        <ScrollablePane style={{marginTop: '6rem'}} scrollbarVisibility={ScrollbarVisibility.auto}>
          
          {this.state.items  && this.state.items.length > 0 ? <ShimmeredDetailsList 
            componentRef={this._root}
            className={"listEl"}
            items={this.state.items}
            compact={this.props.isCompactMode}
            columns={this.props.columns}
            onRenderDetailsHeader={onRenderDetailsHeader}
            selectionMode={this.props.selectionMode}
            setKey="set"
            layoutMode={this.props.layoutMode}
            isHeaderVisible={true}
            selection={this._selection}
            selectionPreservedOnEmptyClick={false}
            onItemInvoked={(item) => this._onItemInvoked(item)}
            enableShimmer={this.props.enableShimmer && !this.props.blankLoading}
            ariaLabelForShimmer="Content is being fetched"
            enterModalSelectionOnTouch={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            shimmerLines={12}
            constrainMode={this.props.constrainMode}
            listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
            groups={this.state.groups}
          />
        : <p className='text-center mt-2'>Empty</p> }
          </ScrollablePane>
        </div>
    )
  }

}

function _getGroups(items, param:string = 'company') {
  var groups:any = []
  for (var i=0; i<items.length;i++) {
    let parameter = items[i][param];
    if (groups.filter(function(e) { return e.key === parameter+'-group'; }).length > 0) {
      groups.filter(function(e) { return e.key === parameter+'-group'; })[0].count++;
    }
    else {
      groups.push({key: parameter+'-group', name: parameter, startIndex: i, count: 1})
    }
  }
  return groups
}

function onRenderDetailsHeader(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
      {defaultRender!({
        ...props
      })}
    </Sticky>
  );
}