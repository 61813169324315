import { Icon, IconButton } from '@fluentui/react';
import * as React from 'react';
import i18n from "i18next";

export interface Props {
  allCardsCollapsed: boolean;
  item:any;
}

export interface States {
  collapsed: boolean;
}

export class MoveCard extends React.Component<Props, States> {
  private _isMounted: boolean;
  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      collapsed: this.props.allCardsCollapsed
    };
  }

  public componentDidMount() {
    this._isMounted = true;
  }
  
  public componentDidUpdate(prevProps:any) {
    if (prevProps.allCardsCollapsed !== this.props.allCardsCollapsed) {
      this._isMounted && this.setState({collapsed: this.props.allCardsCollapsed})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private toggleCollapse() {
    const { collapsed } = this.state;
    this._isMounted && this.setState({collapsed: !collapsed})

  }

  public render() {

    const { collapsed } = this.state;

    return(
      <>
        <div className='d-flex align-items-center py-3 ml-3'>
          <Icon style={{fontSize: '20px', width:'24px', height: '24px', textAlign: 'center'}} iconName='Move' />
          <span className='title ml-3'>{this.props.item.title}</span>
          <div className='d-flex align-items-center mr-3 ml-auto'>
            <span style={{whiteSpace: 'nowrap', overflow:'hidden'}}>{this.props.date || new Date(this.props.item.content.date).toLocaleDateString()}</span>
            {this.props.item.attachments && <IconButton className='card-header-icon-attachments' menuIconProps={{}} iconProps={{iconName:'Attach', styles: {root: {fontSize: 18}}}}/>}
            <IconButton className='card-header-icon' iconProps={{iconName:'More', styles: {root: {fontSize: 18}}}}/>
            <IconButton className='card-header-icon' onClick={()=>this.toggleCollapse()} iconProps={{iconName: collapsed ? 'ExploreContentSingle' : 'CollapseContentSingle', styles: {root: {fontSize: 18}}}} />
          </div>
        </div>
        {!collapsed && <>
          <hr className='mb-3 separator' />
          <div className='description ml-5 pl-2 pr-3 pb-3'>
            <span>
            <a href={'/contacts/'+this.props.item.content.authorId}>{this.props.item.content.authorName}</a> {this.props.item.content.description}
            </span>
          </div>
        </>}
      </>
    )
  }
}
