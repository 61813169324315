import { Icon, IconButton, Persona, PersonaSize, TooltipHost } from '@fluentui/react';
import * as React from 'react';
import i18n from "i18next";
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';

export interface Props {
  allCardsCollapsed: boolean;
  subject: string;
  from: any;
  to: any[];
  cc: any[];
  bcc: any[];
}

export interface States {
  collapsed: boolean;
}

export class EmailViewer extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      collapsed: this.props.allCardsCollapsed
    };
  }

  public componentDidMount() {
    this._isMounted = true;
  }
  
  public componentDidUpdate(prevProps:any) {
    if (prevProps.allCardsCollapsed !== this.props.allCardsCollapsed) {
      this._isMounted && this.setState({collapsed: this.props.allCardsCollapsed})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private toggleCollapse() {
    const { collapsed } = this.state;
    this._isMounted && this.setState({collapsed: !collapsed})

  }

  public render() {

    const { collapsed } = this.state;

    return(
      <div>
        <div>
          <div className='d-flex align-items-center pl-3 py-3'>
            <Icon style={{verticalAlign:'bottom'}} {...getFileTypeIconProps({ extension: '.eml', size: 24, imageFileType: 'svg' }) } />
            <span className='email-subject'>{this.props.subject}</span>
            <div className='d-flex align-items-center mr-3 ml-auto'>
            <span style={{whiteSpace: 'nowrap', overflow:'hidden'}}>{new Date(this.props.date).toLocaleDateString()}</span>
              {this.props.attachments && <IconButton className='card-header-icon-attachments' menuIconProps={{}} iconProps={{iconName:'Attach', styles: {root: {fontSize: 18}}}}/>}
              <IconButton className='card-header-icon' iconProps={{iconName:'More', styles: {root: {fontSize: 18}}}}/>
              <IconButton className='card-header-icon' onClick={()=>this.toggleCollapse()} iconProps={{iconName: collapsed ? 'ExploreContentSingle' : 'CollapseContentSingle', styles: {root: {fontSize: 18}}}} />
            </div>
          </div>
          { !collapsed && <>
            <hr className='mb-3 separator'/>
            <div className='mt-2 ml-5'>
              <div className='email-header px-2'>
                <p className='email-date d-none mb-1'><span className='font-weight-bold' style={{minWidth:'50px', textAlign: 'left', marginRight: '10px'}}>{i18n.t('app:date')+ ': '}</span>{new Date(this.props.date).toLocaleString()}</p>
                <div className='email-from d-flex'>
                  <span style={{minWidth:'40px', textAlign: 'left', marginRight: '10px'}}>{i18n.t('app:from')+ ': '}</span>
                  <TooltipHost
                    key={'from'}
                    content= {
                      <div className='m-1'>
                        <Persona className='mb-2' text={this.props.from.name} initialsColor="#7F7F7F" size={PersonaSize.size32}/>
                        <span className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</span>
                        <p className='p-0 m-0' style={{fontSize:'14px'}}>{this.props.from.email}</p>
                      </div>
                    }
                    id={'from'}
                    calloutProps={{ gapSpace: 0 }}
                    //styles={hostStyles}
                  >
                    <span aria-describedby={'from'} className='mb-1 pr-1 email-to-badges'>{this.props.from.name}</span>
                  </TooltipHost>
                </div>
                <div className='email-to d-flex'>
                  <span style={{minWidth:'40px', textAlign: 'left', marginRight: '10px'}}>{i18n.t('app:To')+ ': '}</span>
                  <TooltipHost
                    key={'to'}
                    content= {
                      <div className='m-1'>
                        <Persona className='mb-2' text={this.props.to.name} initialsColor="#7F7F7F" size={PersonaSize.size32}/>
                        <span className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</span>
                        <p className='p-0 m-0' style={{fontSize:'14px'}}>{this.props.to.email}</p>
                      </div>
                    }
                    id={'to'}
                    calloutProps={{ gapSpace: 0 }}
                    //styles={hostStyles}
                  >
                    <span aria-describedby={'to'} className='mb-1 pr-1 email-to-badges'>{this.props.to.name}</span>
                  </TooltipHost>
                </div>
                {this.props.cc && this.props.cc.length>0 && 
                  <div className='d-flex'>
                    <span style={{minWidth:'40px', textAlign: 'left', marginRight: '10px', fontSize: '14px'}}>{i18n.t('app:Cc')+':'}</span>
                    <div>
                      {this.props.cc.map((item, i)=>{
                        return (
                          <TooltipHost
                            key={item.email+'cc'}
                            content= {
                              <div>
                                <Persona className='mb-2' text={item.name} initialsColor="#7F7F7F" size={PersonaSize.size32}/>
                                <span className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</span>
                                <p className='p-0 m-0' style={{fontSize:'14px'}}>{item.email}</p>
                              </div>
                            }
                            id={item.email+'cc'}
                            calloutProps={{ gapSpace: 0 }}
                            //styles={hostStyles}
                          >
                            <span aria-describedby={item.email+'cc'} className='mb-1 pr-1 email-to-badges' > {i === this.props.cc.length-1 ? item.name : item.name+', ' }</span>
                          </TooltipHost>
                        )                        
                      })}
                    </div>
                  </div>}
                  {this.props.bcc && this.props.bcc.length>0 && 
                  <div className='d-flex'>
                    <span style={{minWidth:'40px', textAlign: 'left', marginRight: '10px', fontSize: '14px'}}>{i18n.t('app:Bcc')+':'}</span>
                    <div>
                      {this.props.cc.map((item, i)=>{
                        return (
                          <TooltipHost
                            key={item.email+'bcc'}
                            content= {
                              <div>
                                <Persona className='mb-2' text={item.name} initialsColor="#7F7F7F" size={PersonaSize.size32}/>
                                <span className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</span>
                                <p className='p-0 m-0' style={{fontSize:'14px'}}>{item.email}</p>
                              </div>
                            }
                            id={item.email+'bcc'}
                            calloutProps={{ gapSpace: 0 }}
                            //styles={hostStyles}
                          >
                            <span aria-describedby={item.email+'bcc'} className='mb-1 pr-1 email-to-badges' > {i === this.props.bcc.length-1 ? item.name : item.name+', ' }</span>
                          </TooltipHost>
                        )                        
                      })}
                    </div>
                  </div>}
              </div>
            </div>
          </> }
        </div>
        { !collapsed && <>
          <div className='ml-5 mt-3 px-2 pr-3 pb-3'>
            <p>Dear all,</p>
            <p>We are very excited to start the selling process and as we discussed personally we would like to engage you as our advisors.</p>
            <p className='m-0'>Speak on Friday for the kickoff meeting</p>
          </div>
        </> }
      </div>
    )
  }

  private frameCSS() {
    const iframe:any = document.getElementById('mail-iframe');
    console.log(iframe)
    setTimeout(()=>{
    if (iframe) {
      const link = document.createElement("style");
      link.innerHTML = `
        body {
          font-family: Segoe UI;
        }
      `
      //iframe.contentDocument.head.appendChild(link)
    }
    },100)
  }

}
