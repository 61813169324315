import React from 'react';
import { Router, Route, Routes, Navigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from './_helpers';
import { alertActions } from './_actions';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { LoginPage } from './_pages/LoginPage';
import { userService } from './_services/user.service';
import ProjectsPage from './_pages/ProjectsPage';
import { CompaniesPage } from './_pages/CompaniesPage';
import { PartialTheme, registerIcons, ThemeProvider } from '@fluentui/react';
import Cookies from 'js-cookie'
import CompanyPage  from './_pages/CompanyPage';
import { ContactsPage } from './_pages/ContactsPage';
import { HomePage } from './_pages/HomePage';
import ProjectPage from './_pages/ProjectPage';
import { PipelinesPage } from './_pages/PipelinesPage';
import { D365ProjectOperationsIcon } from '@fluentui/react-icons-mdl2-branded';
import ContactPage from './_pages/ContactPage';
import { TasksPage } from './_pages/TasksPage';
import { TimeTrackingPage } from './_pages/TimeTrackingPage';
import DashboardPage from './_pages/DashboardPage';
import { SearchPage } from './_pages/SearchPage';

export interface Props {
  dispatch?: any;
  match?:any;
  location?:any;
}

export interface appState {
  userData?: any;
  initialRepo?: any;
  foldersList?: any;
  newFolder?: any;
  repoUsers?: any;
  database?: any;
  isOnline: boolean;
  driveUpdateRequired: boolean;
  toolsUpdateRequired: boolean;
  updatingTools: boolean;
  maintenance: boolean;
  showMaintenanceDialog: boolean;
  noSynergyToolsRunning: boolean;
  tryingToRunSynergyTools: boolean;
  socketMessage: any;
  pusherMessage: any;
  pusherStarted: boolean;
  editMessage: boolean;
  editMessageData: any;
  newEditMessage: string;
  saveAsMajorVersion: boolean;
  editingMessage: boolean;
  clipboard: any;
  pendingDrafts: any,
}

const appPlatform = () => {
  return "webapp"
}

const getStoredData = (name: string) => {
  var cookie = Cookies.get(name)
  return cookie
}

class App extends React.Component<Props, appState> {
  
  constructor(props: Props) {
    super(props);
    const { dispatch } = this.props;
    history.listen(() => {
        // clear alert on location change
        dispatch(alertActions.clear());
    });
    
    initializeIcons();
    initializeFileTypeIcons();
    registerIcons({
      icons: {
        D365ProjectOperationsIcon: <D365ProjectOperationsIcon className='brand-icon'/>,
        D365ProjectOperationsIconNav: <D365ProjectOperationsIcon className='brand-icon-nav'/>,
      },
    });

    let token = getStoredData("token");
    if(token) {
        this._getUserData()
    } else {
        var pathname = window.location.pathname;
        var search = window.location.search;
        var prevPath = pathname + search;

        if (pathname.indexOf("/login") === -1 && prevPath !== "/index.html") localStorage.setItem("prevPath", prevPath)
        history.replace('/login')
    }
    // Clear importing files list from local storage
    localStorage.removeItem("uploadsQueue")
    this.state = {
      userData: null,
      initialRepo: null,
      foldersList: null,
      newFolder: null,
      repoUsers: [],
      database: {
          /*processingUploadsDb: new PouchDB('synergy_processing_uploads_db'),
          queuedUploadsDb: new PouchDB('synergy_queued_uploads_db'),
          successfulUploadsDb: new PouchDB('synergy_successful_uploads_db'),
          errorUploadsDb: new PouchDB('synergy_error_uploads_db')*/
      },
      isOnline: navigator.onLine,
      driveUpdateRequired: false,
      toolsUpdateRequired: false,
      updatingTools: false,
      maintenance: false,
      showMaintenanceDialog: false,
      noSynergyToolsRunning: false,
      tryingToRunSynergyTools: false,
      socketMessage: null,
      pusherMessage: null,
      pusherStarted: false,
      editMessage: false,
      editMessageData: null,
      newEditMessage: i18n.t('app:minorVersion'),
      saveAsMajorVersion: false,
      editingMessage: false,
      clipboard: "",
      pendingDrafts: null,
    }
    localStorage.removeItem("lastFocusedItems")
  }
  
  public resetDataOnLogout() {
    console.log("Logout: reset app data")
    this.setState({
      userData: null,
      initialRepo: null,
      foldersList: null,
      newFolder: null,
      repoUsers: [],
      database: {
          /*processingUploadsDb: new PouchDB('synergy_processing_uploads_db'),
          queuedUploadsDb: new PouchDB('synergy_queued_uploads_db'),
          successfulUploadsDb: new PouchDB('synergy_successful_uploads_db'),
          errorUploadsDb: new PouchDB('synergy_error_uploads_db')*/
      },
      isOnline: navigator.onLine,
      driveUpdateRequired: false,
      toolsUpdateRequired: false,
      updatingTools: false,
      maintenance: false,
      showMaintenanceDialog: false,
      noSynergyToolsRunning: false,
      tryingToRunSynergyTools: false,
      socketMessage: null,
      pusherMessage: null,
      pusherStarted: false,
      editMessage: false,
      editMessageData: null,
      newEditMessage: i18n.t('app:minorVersion'),
      saveAsMajorVersion: false,
      editingMessage: false
    }, () => {
        //this.disconnectPusher()
    })
}

  private _getUserData = ()  => {
    //checkTokenExpirationDate().then(()=>{
     // if (this._tokenSchedule === false) setInterval(checkTokenExpirationDate,600000)
     // this._tokenSchedule = true;
      userService.getUserData().then((response:any) => {
        var user = response.data

        var  initialRepo = user.repository.id;

        this._getRepoUsers(user).then(response => {
          localStorage.getItem('companyDomain') && userService.getServerInfo(localStorage.getItem('companyDomain') || '').then((res)=>{
              localStorage.setItem('companyLogo1', res.data.imageLarge);
              localStorage.setItem('companyLogo2', res.data.imageMedium);
              localStorage.setItem('companyLogo3', res.data.imageSmall);
          })
          var repoUsers = response

          this.setState({
              userData: user,
              initialRepo: initialRepo,
              repoUsers: repoUsers,
              //signingSSO: false
          }, () => {
              //if(!this._pusher) {
              //    this.setState({pusherStarted: false}, () => {
              //        this.initPusherNotifications()
              //    })
              //}
          })

          userService.getFolderDetails(initialRepo)
          .then((response: any) => {
            var repo = response.data
            repo.isRepo = true
            var accessGranted: boolean = false;
            var userGroups = this.state.userData.groups;

            var isUser = repo.users.filter(user => {
                return user.id === this.state.userData.user.id
            })

            var isAdmin = repo.admins.filter(user => {
                return user === this.state.userData.user.id
            })

            var groupAccess = repo.groups.filter(group => {
                return userGroups.includes(group.id)
            })

            var isExternal = repo.externals.filter(user => {
                return user === this.state.userData.user.id
            })

            if(isAdmin.length > 0
                || isUser.length > 0
                || groupAccess.length > 0
                || isExternal.length > 0)
                accessGranted = true
            
            repo.accessGranted = accessGranted

            var foldersList = [repo]

            if ((window.location.pathname.indexOf("/files") !== -1 && window.location.pathname.indexOf(initialRepo) === -1)
                || window.location.pathname.indexOf("/recent-files") !== -1
                || window.location.pathname.indexOf("/bookmarks") !== -1
                || window.location.pathname.indexOf("/search") !== -1) {
                this._listFoldersLevels(repo).then(response =>{
                    if (response) foldersList = foldersList.concat(response)
                    this.setState({foldersList: foldersList})
                }).catch(error => console.log(error))                        
            }
          }).catch(error => {
              console.log(error)
          })

          var prevPath = localStorage.getItem("prevPath");

          if (window.location.pathname === ("/projects")
          || window.location.pathname.includes("/login")
          || window.location.href.indexOf("null") !== -1) {
              if (prevPath && prevPath !== "/index.html") history.push(prevPath)
              else history.push('/projects/list')
              localStorage.removeItem("prevPath")
          }
      })
    })
    //})
  }

  private _getRepoUsers = async (userData) => {
    var repoUsers: any = []
    repoUsers.groups = []
    repoUsers.rootFolderAccess = []

    await new Promise((resolve, reject) => {
        try {
            var repo = userData.repository
            userService.getRepoUsers().then((response: any) => {
                var users = response.data
                users.forEach(user => {
                    repoUsers.push(user)
                })

                userService.getRepoGroups().then((response: any) => {
                    var groups = response.data
                    groups.forEach(group => {
                        repoUsers.groups.push(group)
                    })
                    if(userData.user.role === "ADMIN") {
                      userService.getFolderDetails(repo.id).then(response => {
                          repoUsers.rootFolderAccess.admins = response.data.admins
                          repoUsers.rootFolderAccess.users = response.data.users
                          repoUsers.rootFolderAccess.groups = response.data.groups
                          repoUsers.rootFolderAccess.externals = response.data.externals
                          resolve(repoUsers)
                      }).catch(error => {
                          console.log(error)
                      })
                    } else {
                        resolve(repoUsers)
                    }
                })
            })
        } catch (error) {
            console.log(error)
            reject()
        }
    })
    return repoUsers
  }

  private _listFoldersLevels = async (repo) => {

    var getListFoldersLevels = async () => {
      return await new Promise((resolve, reject) => {
          try {
              userService.getFolderContent(repo.id).then((response: any) => {
                  var folders = response.data.folders
                  resolve(folders)
              }).catch(error => {
                  console.log(error)
                  reject()
              })
          } catch (error) {
              console.log(error)
              reject()
          }
      })
    }

    return getListFoldersLevels().then(response => {
        return response
    }).catch(error => console.log(error))
}

  private setClipboard(value: any) {
  }

  private _addItemsToFoldersList = (folders) => {
    if(this.state.foldersList) {
        var links = folders
        var linksId = links.map(link => {
            return link.id
        })
        var foldersList = this.state.foldersList && this.state.foldersList.filter(folder => {
            return !linksId.includes(folder.id)
        })

        if(foldersList) this.setState({foldersList: foldersList.concat(links)})
    } else {
        this.setState({foldersList: folders})
    }
}
  
  render() {

    const { initialRepo } = this.state

    const appTheme: PartialTheme = {
      palette: {
        //themePrimary: 'red'
      }
    };

    function withParams(Component) {
      return props => <Component {...props} params={useParams()} />;
    }

    //if (window.location.href.indexOf("/files") !== -1 && window.location.href.indexOf("/files/Repo-") === -1 && window.location.href.indexOf("/files/Folder-") === -1 && initialRepo !== null) {
    //  history.push('/files/' + initialRepo)
    //}    

    return (
      
      <ThemeProvider theme={appTheme}>
        <Router location={history.location} navigator={history}>
          <Routes>
            <Route path="/login" element={<LoginPage appPlatform={appPlatform()} resetDataOnLogout={this.resetDataOnLogout.bind(this)} getUserData={this._getUserData} />} />
            <Route path="/files" element={<DashboardPage {...this.props} appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusherMessage} appUpdateAvailable={false} webUpdateAvailable={false} fileExplorerType="fileExplorer" userData={this.state.userData} foldersList={this.state.foldersList} newFolder={this.state.newFolder} repoUsers={this.state.repoUsers} clipboard={''} addItemsToFoldersList={this._addItemsToFoldersList} setClipboard={this.setClipboard.bind(this)} /> } />
            <Route path="/files/:id" element={<DashboardPage {...this.props} appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusherMessage} appUpdateAvailable={false} webUpdateAvailable={false} fileExplorerType="fileExplorer" userData={this.state.userData} foldersList={this.state.foldersList} newFolder={this.state.newFolder} repoUsers={this.state.repoUsers} pendingDrafts={this.state.pendingDrafts} clipboard={this.state.clipboard} addItemsToFoldersList={this._addItemsToFoldersList} setClipboard={this.setClipboard.bind(this)} />} />
            <Route path="/bookmarks" element={<DashboardPage {...this.props} appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusherMessage} appUpdateAvailable={false} webUpdateAvailable={false} fileExplorerType="bookmarks" userData={this.state.userData} foldersList={this.state.foldersList} newFolder={this.state.newFolder} repoUsers={this.state.repoUsers} pendingDrafts={this.state.pendingDrafts} clipboard={this.state.clipboard} addItemsToFoldersList={this._addItemsToFoldersList} setClipboard={this.setClipboard.bind(this)} />} />
            <Route path="/recent-files/:id" element={<DashboardPage {...this.props} appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusherMessage} appUpdateAvailable={false} webUpdateAvailable={false} fileExplorerType="recentFiles" userData={this.state.userData} foldersList={this.state.foldersList} newFolder={this.state.newFolder} repoUsers={this.state.repoUsers} pendingDrafts={this.state.pendingDrafts} clipboard={this.state.clipboard} addItemsToFoldersList={this._addItemsToFoldersList} setClipboard={this.setClipboard.bind(this)} />} />
            <Route path="/search" element={<SearchPage {...this.props} appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusherMessage} appUpdateAvailable={false} webUpdateAvailable={false} fileExplorerType="search" searchType="fullSearch" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} clipboard={''} setClipboard={this.setClipboard.bind(this)} /> } />
            <Route path="/projects/list" element={<ProjectsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusherMessage} page="projectsExplorer" userData={this.state.userData} foldersList={this.state.foldersList} newFolder={this.state.newFolder} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/projects/clients" element={<ProjectsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusherMessage} page="clientsExplorer" userData={this.state.userData} foldersList={this.state.foldersList} newFolder={this.state.newFolder} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/projects/milestones" element={<ProjectsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusherMessage} page="milestonesExplorer" userData={this.state.userData} foldersList={this.state.foldersList} newFolder={this.state.newFolder} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/projects/board" element={<ProjectsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusherMessage} page="boardExplorer" userData={this.state.userData} foldersList={this.state.foldersList} newFolder={this.state.newFolder} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/projects/pipeline" element={<ProjectsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusherMessage} page="pipelinesExplorer" userData={this.state.userData} foldersList={this.state.foldersList} newFolder={this.state.newFolder} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/projects/:id" element={<ProjectPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusherMessage} page="projectData" userData={this.state.userData} foldersList={this.state.foldersList} newFolder={this.state.newFolder} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/projects" element={initialRepo !== null ? <Navigate replace to={{ pathname: '/projects/list'}} /> : null}/>
            <Route path="/companies" element={<CompaniesPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusherMessage} page="companiesExplorer" userData={this.state.userData} foldersList={this.state.foldersList} newFolder={this.state.newFolder} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/companies/:id" element={<CompanyPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusherMessage} page="companyData" userData={this.state.userData} foldersList={this.state.foldersList} newFolder={this.state.newFolder} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/contacts" element={<ContactsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusherMessage} page="contactsExplorer" userData={this.state.userData} foldersList={this.state.foldersList} newFolder={this.state.newFolder} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/contacts/:id" element={<ContactPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusherMessage} page="contactData" userData={this.state.userData} foldersList={this.state.foldersList} newFolder={this.state.newFolder} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/pipelines" element={<PipelinesPage appPlatform={appPlatform()} isOnline={this.state.isOnline} userData={this.state.userData} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusherMessage} page="pipelines" />} />
            <Route path="/tasks" element={<TasksPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusherMessage} page="tasksExplorer" userData={this.state.userData} foldersList={this.state.foldersList} newFolder={this.state.newFolder} repoUsers={this.state.repoUsers} database={this.state.database}/>} />
            <Route path="/time-sheet" element={<TimeTrackingPage appPlatform={appPlatform()} isOnline={this.state.isOnline} userData={this.state.userData} page="timeSheet" />} />
            <Route path="/time-tracker" element={<TimeTrackingPage appPlatform={appPlatform()} isOnline={this.state.isOnline} userData={this.state.userData} page="timeTracker" />} />
            <Route path="/home" element={<HomePage appPlatform={appPlatform()} isOnline={this.state.isOnline} userData={this.state.userData} />} />
            <Route path="/" element={initialRepo !== null ? <Navigate replace to={{ pathname: '/home'}} /> : null}/>           
          </Routes>
        </Router>
      </ThemeProvider>
    );
  }
 
}

function mapStateToProps(state: any) {
  const { alert } = state;
  return {
      alert
  };
}

const connectedApp = withTranslation()(connect(mapStateToProps)(App));
export { connectedApp as App }; 
