import { userService } from '../_services';

function getRoleName(userData, folderData) {
    //console.log("Folder's role data", folderData)

    var userId = userData.user.id

    var isAdmin = folderData.admins.filter(admin => {
        return admin === userId
    })[0]
    if(isAdmin) return "ADMIN"

    var isUser = folderData.users.filter(user => {
        return user.id === userId
    })[0]
    if(isUser) return isUser.role

    var userGroups = userData.userGroups.map(userGroup => {
        return userGroup.id
    })
    var groupAccess = folderData.groups.filter(group => {
        return userGroups.includes(group.id)
    })[0]
    if(groupAccess) return groupAccess.role

    var isExternal = folderData.externals.filter(user => {
        return user === userId
    })[0]
    if(isExternal) return "EXTERNAL"
}

export const FolderRole = async (folderId: string, userData: any, foldersList: any) => {
    var roleName: string = "";

    var navFolder = foldersList && foldersList.filter(folder => {
    return folderId === (folder && folder.id)
    })[0]

    if(navFolder) {
        if(navFolder.isRepo && !navFolder.accessGranted) {
            roleName = "FOLDERINPATH"
        } else {
            roleName = getRoleName(userData, navFolder)
        }
    } else {
        if(folderId.indexOf("-trash") === -1
            && folderId.indexOf("-templates") === -1) {
            //Get role from endpoint
            await userService.getFolderDetails(folderId)
            .then((response: any) => {
                var folderData = response.data
                roleName = getRoleName(userData, folderData)
            }).catch(error => console.log(error))
        }
    }

    if(roleName) {
        if(roleName === "ADMIN") return 0
        if(roleName === "EDITOR") return 1
        if(roleName === "RESTRICTED_EDITOR") return 2
        if(roleName === "VIEWER") return 5
        if(roleName === "PREVIEWER") return 7
        if(roleName === "EXTERNAL") return 10
        if(roleName === "FOLDERINPATH") return 99
    }

    return 10;
}