import React from 'react';
import { connect } from 'react-redux';

import { history } from '../_helpers';
import { userActions, alertActions } from '../_actions';

import { Text } from '@fluentui/react/lib/Text';
import { Link } from '@fluentui/react/lib/Link';
import { TextField } from '@fluentui/react/lib/TextField';
import {
  PrimaryButton,
  Modal,
  Checkbox,
  DialogFooter,
  DefaultButton,
  MessageBar
} from '@fluentui/react';

import i18n from "i18next";
import { userService } from '../_services';
import ReactDOM from 'react-dom';

import * as msal from "@azure/msal-browser";
import { Titlebar } from '../_components/Titlebar';

const msalConfig = {
    auth: {
        clientId: '9e0d5428-0a10-44f2-972c-26000e5118d7',
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: window.location.origin + '/blank.html'
    }
};
const msalInstance = new msal.PublicClientApplication(msalConfig);

export interface Props {
  alert?: any;
  dispatch?: any;
  loggingIn?: boolean;
  appPlatform: string,
  resetDataOnLogout: any;
  getUserData: any;
  checkMaintenanceMode: any;
  checkRequiredVersion: any;
  signingSSO:boolean;
}

export interface LoginState {
  serverDomain?: string,
  customServerDomain?: string,
  username?: string;
  password?: string;
  staySignedIn: boolean;
  showAADLogin: boolean;
  gettingServerDomain: boolean;
  serverDomainChosen:boolean;
  companyDomain: string;
  sso: boolean;
  signingAAD: boolean;
  waitingForRedirect: boolean;
  image1: string;
}

class LoginPage extends React.Component<Props, LoginState> {

    constructor(props: any) {
        super(props);

        const { dispatch } = this.props;
        history.listen(() => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
        // reset login status
        //this.props.dispatch(userActions.logout());

        this.state = {
            serverDomain: "synergyCloudUs",
            customServerDomain: "",
            username: "",
            password: "",
            staySignedIn: true,
            showAADLogin: false,
            gettingServerDomain: false,
            serverDomainChosen: false,
            companyDomain: '',
            sso: false,
            signingAAD: false,
            waitingForRedirect: false,
            image1: '',

        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.props.resetDataOnLogout()
    }

    componentDidMount() {
        
        var companyDomain = process.env.NODE_ENV === "development" ? "dev.synergy.page" : window.location.host.split('.').slice(1).join('.');
        if(companyDomain && companyDomain.length > 0 && companyDomain !== 'app.synergyos.com' && companyDomain !== 'synergyos.com') {
            this.setState({companyDomain: companyDomain, image1: ''})
            userService.getServerInfo(companyDomain).then((response)=>{
                localStorage.setItem('companyDomain', companyDomain);
                localStorage.setItem('companyLogo1', response.data.imageLarge);
                localStorage.setItem('companyLogo2', response.data.imageMedium);
                localStorage.setItem('companyLogo3', response.data.imageSmall);
                this.setState({gettingServerDomain: false, serverDomainChosen: true, serverDomain: 'customServer', customServerDomain: response.data.serverDomain, sso: response.data.sso, image1: response.data.imageLarge, companyDomain: response.data.companyDomain})
            }).catch((error)=>{
                this.setState({gettingServerDomain: false})
                alert("Can't reach server")
            })
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState<any>({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        const { customServerDomain, username, password, companyDomain, staySignedIn } = this.state;
        const { dispatch } = this.props;

        if (username && password) {
            userService.setServerDomain(customServerDomain || '').then(() => {
                dispatch(userActions.login(username, password, companyDomain, staySignedIn)).then(
                    (response) => {
                        if(response) {
                            if (response.data["2fa"] ) {
                                this._askUserForCode(response.data['2faToken']).then((result) => {
                                    userService.afterLogin(result,staySignedIn).then(()=>{
                                        this.props.getUserData()
                                    }).catch(reject => {console.log(reject)})
                                }).catch(reject => {console.log(reject)})
                            }
                            else {
                                userService.afterLogin(response,staySignedIn).then(()=>{
                                    this.props.getUserData()
                                }).catch(reject => {console.log(reject)})
                            }
                        }
                    },
                    (error) => {
                        console.log(error)
                    }
                )
            })
        }
    }

    getServerDomain(e?) {
        if (e) e.preventDefault();
        this.setState({gettingServerDomain: true, image1: ''})
        userService.getServerInfoCompany(this.state.companyDomain).then((response)=>{
            localStorage.setItem('companyDomain',this.state.companyDomain);
            localStorage.setItem('companyLogo1', response.data.imageLarge);
            localStorage.setItem('companyLogo2', response.data.imageMedium);
            localStorage.setItem('companyLogo3', response.data.imageSmall);
            this.setState({gettingServerDomain: false, serverDomainChosen: true, serverDomain: 'customServer', customServerDomain: response.data.serverDomain, sso: response.data.sso, image1: response.data.imageLarge, companyDomain: response.data.companyDomain})
        }).catch((error)=>{
            this.setState({gettingServerDomain: false})
            alert("Can't reach server")
        })
    }

    goBackToCompanyDomain(e) {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        localStorage.removeItem('companyDomain')
        this.setState({gettingServerDomain: false, serverDomainChosen: false, companyDomain: '', sso: false, signingAAD: false})
    }

    render() {
        const { loggingIn, alert } = this.props;

        const { gettingServerDomain, serverDomainChosen, companyDomain, sso, signingAAD, username, password, staySignedIn, image1 } = this.state;

        const loginAAD = () => {
            try {
                this.setState({signingAAD: true});
                userService.setServerDomain(this.state.customServerDomain || '').then(() => {
                    msalInstance.loginPopup().then((response) => {
                        if (response && response.accessToken) {
                            userService.getTokenAAD('Bearer ' + response.accessToken).then((response: any)=>{
                                userService.loginAAD('Bearer ' + response.data.token, this.state.companyDomain, this.state.staySignedIn).then((response)=>{
                                    if (response.status === 201) {
                                        var responseData = {
                                            data: {
                                                accessToken: 'Bearer ' + response.data.token
                                            }
                                        }
                                        console.log("RESPONSE", responseData)
                                        userService.afterLogin(response,this.state.staySignedIn).then(()=>{
                                            this.props.getUserData()
                                        }).catch(reject => {console.log(reject)})
                                    }
                                }).catch((error) => {
                                    this.setState({signingAAD: false});
                                    this.props.dispatch(alertActions.error(error));
                                    console.log(error)
                                });
                            }).catch((error) => {
                                this.setState({signingAAD: false});
                                this.props.dispatch(alertActions.error(error));
                                console.log(error)
                            });
                        }
                    }).catch((error) => {
                        this.setState({signingAAD: false});
                        this.props.dispatch(alertActions.error(error));
                        console.log(error)
                    });
                })
            } catch (err) {
                console.log(err)
            }
        }
        
        return (
            <div>
                <Titlebar disabledSearch={true} companyImage={this.state.image1}/>
                { !serverDomainChosen ?
                <Modal
                    isOpen={true}
                    isBlocking={true}
                    className="modal-wrapper"
                    isDarkOverlay={false}
                    isModeless={true}
                    containerClassName="form-modal p-5"
                    scrollableContentClassName="scrollable-modal-content"
                >
                    <Text variant={'xLarge'} className="lead">
                        {i18n.t('app:companyDomain')}
                    </Text>

                    { alert.message &&
                        <div className={`alert my-3 ${alert.type}`}>
                            {alert.message.status === 401 ?
                                "Your account or password is incorrect. If you don't remember your password, contact your account administrator."
                                : alert.message.status === 503 ? "Synergy is currently unavailable due to planned maintenance."
                                : alert.message === "Error: Network Error" ? "Server not found. Please try again."
                                : alert.message.message === "Network Error" ? "Server not found. Please try again."
                                : alert.message.message || alert.message.data.message || "There was an error processing your request. Please, try again."}</div>
                    }

                    <form name="form" onSubmit={this.getServerDomain.bind(this)} className="mt-2">
                        <TextField type="text" placeholder={i18n.t('app:domainInput')} name="companyDomain" value={companyDomain} onChange={this.handleChange} underlined required autoFocus autoComplete="companyDomain" className="required-hidden mb-4" />

                        <Link href='https://synergyos.com/support' variant={'small'}>
                            {i18n.t('app:cantAccessAccount')}
                        </Link>

                        <div className="form-group text-right mt-3 mb-0">
                            <PrimaryButton text={i18n.t('app:next')} disabled={gettingServerDomain} type="submit"/>
                            {gettingServerDomain &&
                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="Loading..." className="ml-2 "/>
                            }
                        </div>
                    </form>
                </Modal>
            : !sso ?
                <Modal
                    isOpen={true}
                    isBlocking={true}
                    className="modal-wrapper"
                    isDarkOverlay={false}
                    isModeless={true}
                    containerClassName="form-modal p-5"
                    scrollableContentClassName="scrollable-modal-content"
                >
                    <p className="lead m-0">
                        {i18n.t('app:companyDomain')}
                    </p>
                    <a href={'https://' + this.state.companyDomain}>{this.state.companyDomain}</a>
                    
                    { image1 && image1.length > 0 ?
                        <div className="d-flex pt-2 mt-4 mb-3 w-100">
                            <div className="text-center w-100">
                                <img src={'data:image/png;base64,'+image1 } className="logo img-fluid" style={{width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100px'}} alt={ "Company logo"} />
                            </div>
                        </div>
                    : null }

                    <p className="lead mt-4">
                        {i18n.t('app:signIn')}
                    </p>


                    { alert.message &&
                        <div className={`alert my-3 ${alert.type}`}>
                            {alert.message.status === 401 ?
                                "Your account or password is incorrect. If you don't remember your password, contact your account administrator."
                                : alert.message.status === 503 ? "Synergy is currently unavailable due to planned maintenance."
                                : alert.message === "Error: Network Error" ? "Server not found. Please try again."
                                : alert.message.message === "Network Error" ? "Server not found. Please try again."
                                : alert.message.message || alert.message.data.message || "There was an error processing your request. Please, try again."}</div>
                    }

                    <form name="form" onSubmit={this.handleSubmit}>
                        <TextField type="email" placeholder={i18n.t('app:email')} name="username" value={username} onChange={this.handleChange} underlined required autoFocus autoComplete="username" className="required-hidden mb-3" />
                        <TextField type="password" placeholder={i18n.t('app:password')} name="password" value={password} onChange={this.handleChange} underlined required canRevealPassword revealPasswordAriaLabel="Show password" className="required-hidden mb-3" />

                        <Checkbox
                            className='mt-4 mb-2'
                            label={i18n.t('app:staySignedIn')}
                            checked={staySignedIn}
                            onChange={()=>this.setState({staySignedIn: !staySignedIn})}
                        />

                        <Link variant={'small'} onClick={this._goToForgotPassword.bind(this)}>
                            {i18n.t('app:forgotPassword')}
                        </Link>

                        <div className="form-group text-right mt-4 mb-0">
                            {(window.location.host === 'portal.synergy.page' || window.location.host === 'portal.synergyos.com' || window.location.host === 'portal.app.synergyos.com') && <DefaultButton className='mr-2 cancel-button' onClick={this.goBackToCompanyDomain.bind(this)} text={i18n.t('app:back')}/>}
                            <PrimaryButton text={i18n.t('app:signIn')} disabled={loggingIn} type="submit"/>
                            {loggingIn &&
                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="Loading..." className="ml-2 "/>
                            }
                        </div>
                    </form>
                </Modal>
            :
                <Modal
                    isOpen={true}
                    isBlocking={true}
                    className="modal-wrapper"
                    isDarkOverlay={false}
                    isModeless={true}
                    containerClassName="form-modal p-5"
                    scrollableContentClassName="scrollable-modal-content"
                >

                    <p className="lead m-0">
                        {i18n.t('app:companyDomain')}
                    </p>
                    <a href={'https://' + this.state.companyDomain}>{this.state.companyDomain}</a>
                    
                    { image1 && image1.length > 0 ?
                        <div className="d-flex pt-2 mt-4 mb-3 w-100">
                            <div className="text-center w-100">
                                <img src={'data:image/png;base64,'+image1 } className="logo img-fluid" style={{width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100px'}} alt={ "Company logo"} />
                            </div>
                        </div>
                    : null }

                    <p className="lead mt-4 mb-5">
                        {i18n.t('app:signIn')}
                    </p>


                    { alert.message &&
                        <div className={`alert my-3 ${alert.type}`}>
                            {alert.message.status === 401 ?
                                "Your account or password is incorrect. If you don't remember your password, contact your account administrator."
                                : alert.message.status === 503 ? "Synergy is currently unavailable due to planned maintenance."
                                : alert.message === "Error: Network Error" ? "Server not found. Please try again."
                                : alert.message.message === "Network Error" ? "Server not found. Please try again."
                                : alert.message.message || alert.message.data.message || "There was an error processing your request. Please, try again."}</div>
                    }

                    <form name="form" onSubmit={this.handleSubmit}>
                        <div className="text-center mb-0">
                            <DefaultButton onClick={loginAAD} disabled={signingAAD || this.props.signingSSO} style={{border: '1px solid #bfbfbf', height: '32px', padding: '18px 50px'}}>
                                { signingAAD || this.props.signingSSO ? 
                                  <>
                                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="  style={{marginRight: '15px'}} alt="Loading..." />
                                    <span style={{fontWeight: 600, color: '#5e5e5e'}}>{i18n.t('app:signInAAD')}</span>
                                  </>
                                : <>
                                    <img src={ process.env.PUBLIC_URL + "/img/ms-icon.svg" } style={{marginRight: '10px'}} alt="Microsoft logo" />
                                    <span style={{fontWeight: 600, color: '#5e5e5e'}}>{i18n.t('app:signInAAD')}</span>
                                  </>
                                }
                            </DefaultButton>
                        </div>
                        <Checkbox
                            className='mt-5'
                            label={i18n.t('app:staySignedIn')}
                            checked={staySignedIn}
                            onChange={()=>this.setState({staySignedIn: !staySignedIn})}
                        />
                        <Link onClick={()=>{this.setState({sso:false})}} className='mt-4 mb-0 pb-0'>Click here to sign in with your Synergy credentials instead</Link>
                        {this.state.waitingForRedirect && <MessageBar className='mt-2 d-flex align-items-center' onDismiss={()=>{this.setState({waitingForRedirect: false})}}>
                            {i18n.t('app:waitingForBrowser')}
                        </MessageBar>}
                        {(window.location.host === 'portal.synergy.page' || window.location.host === 'portal.synergyos.com' || window.location.host === 'portal.app.synergyos.com') && <div className="form-group text-right mt-4 mb-0">
                        <DefaultButton className='mr-2 cancel-button' onClick={this.goBackToCompanyDomain.bind(this)} text={i18n.t('app:back')}/>
                        </div>}

                    </form>
                </Modal>
            }
            </div>

        );
    }

    private _goToForgotPassword() {
        const domain = process.env.NODE_ENV === 'production' ? window.location.hostname.split('.').slice(1).join('.') : process.env.REACT_APP_DOMAIN;
        var url = 'https://account.' + domain + "/reset-password"
        window.open(url, "_blank");
    }

    private _askUserForCode = async (_token:string) => {
        return await new Promise((resolve, reject) => {
          var showDialog = true;
          var code = '';
          var warning: string = "";
          var token: string = _token;
          var loading = false;
    
          var confirm = (e) => {
            e.preventDefault();
            warning = "";
            loading = true;
            renderDialog();
            userService.verifyEmailCode(token,code,this.state.staySignedIn).then((response)=>{
                resolve(response)
                setTimeout(()=>{
                    showDialog = false;
                    loading = false;
                    renderDialog()
                },1000)
            }).catch(()=>{
                warning = "invalidCode";
                loading = false;
                renderDialog()
            })
          }
    
          var cancel = () => {
            showDialog = false;
            renderDialog()
            reject("Operation rejected by user");
          }

          var resend = () => {
            renderDialog()
            userService.resendVerifyEmailCode(this.state.username, this.state.companyDomain, this.state.password).then((response)=>{
                if (response.status === 202) {
                    warning = "emailSent";
                    token = response.headers.authorization
                    renderDialog();
                } else {
                    warning = "cantResend";
                    renderDialog();
                }
            })
          }

          const handleInput = (value)=> {
            code = value;
            renderDialog();
          }
    
          var div = document.createElement('div');
          var renderDialog = () => {
            ReactDOM.render(
              <Modal
                onDismiss={cancel}
                isOpen={showDialog}
                isBlocking={true}
                className="modal-wrapper"
                isDarkOverlay={false}
                isModeless={true}
                containerClassName="form-modal p-5"
                scrollableContentClassName="scrollable-modal-content"
                styles={{root:{zIndex:9999999}, layer: {zIndex: 9999999}}}
                >
                  <div className="w-100">

                    <p className="lead mb-0">
                        {i18n.t('app:companyDomain')}
                    </p>
                    <a href={'https://' + this.state.companyDomain}>{this.state.companyDomain}</a>
                    
                    { this.state.image1 && this.state.image1.length > 0 ?
                        <div className="d-flex mt-4 pt-2 mb-3 w-100">
                            <div className="text-center w-100">
                                <img src={'data:image/png;base64,'+this.state.image1 } className="logo img-fluid" style={{width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100px'}} alt={ "Company logo"} />
                            </div>
                        </div>
                    : null }

                    <p className="lead mt-4">
                        {i18n.t('app:verifyEmail')}
                    </p>
                    {   warning === "invalidCode" ?
                        <div className={`alert my-3 alert-danger`}>
                            {i18n.t('app:expiredCode')}
                        </div>
                        : warning === "cantResend" ?
                        <div className={`alert my-3 alert-danger`}>
                            {i18n.t('app:codeCantSend')}
                        </div>
                        : warning === "emailSent" ?
                        <div className={`alert my-3 alert-success`}>
                            {i18n.t('app:codeSent')}
                        </div>
                        : null
                    }
                    <p className='mt-3'>{i18n.t('app:codeSentEmail')}</p>
                    <form name="form" onSubmit={confirm}>
                    <TextField
                        placeholder={i18n.t('app:enterCode')}
                        className=''
                        value={code}
                        onChange={(e,v)=>handleInput(v)}
                        onSubmit={confirm}
                    />
                    </form>
                    <div className="mt-3 fake-url" onClick={resend}>
                        <p>{i18n.t('app:resendEmail')}</p>
                    </div>
                    
                    <DialogFooter>
                        <DefaultButton className='cancel-button' onClick={cancel} text={i18n.t('app:back')} />
                        <PrimaryButton autoFocus onClick={ confirm } text={i18n.t('app:signIn')} />
                        {loading &&
                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="Loading..." className="ml-2 "/>
                        }
                    </DialogFooter>
                  </div>
                </Modal>
              , div
            )
          }
    
          renderDialog();
        })
    }
}

function mapStateToProps(state: any) {
    const { loggingIn } = state.authentication;
    const { alert } = state;

    return {
        loggingIn,
        alert
    };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage }; 