import * as React from 'react';
import { Resizable } from 're-resizable';
import { Icon, IconButton, Pivot, PivotItem, TooltipHost } from '@fluentui/react';
import i18n from "i18next";


export interface Props {
  id: string;
  item: any;
}

export interface State {
  repoData: any,
  showRightPanel: boolean,
  panelHeight: string,
  panelWidth: any
  item: any
  activeTab: string;
}

export class DataPanel extends React.Component<Props, State> {
  private _isMounted: boolean;
  private _windowWidth: number;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this._windowWidth = window.innerWidth;

    this.props = props

   var panelWidth = "420px"
   if (typeof(localStorage.getItem("detailsPanelPrefs")) === "string") {
      var panelPrefs = JSON.parse(localStorage.getItem("detailsPanelPrefs") || "[]");
      panelWidth = panelPrefs.width
   }

    this.state = {
      repoData: props.repoData,
      showRightPanel: props.showRightPanel,
      panelHeight: 'auto',
      panelWidth: panelWidth,
      item: this.props.item,
      activeTab: 'summary'
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    window.addEventListener('resize', this._onWindowResize);
  }

  public componentDidUpdate(prevProps, prevState) {
    if (prevProps.showRightPanel !== this.props.showRightPanel) {
      this._isMounted && this.props.callbackFunction({showRightPanel: this.props.showRightPanel})
      this._isMounted && this.setState({showRightPanel: this.props.showRightPanel})
    }
    if (prevProps.item !== this.props.item) {
      this._isMounted && this.setState({item: this.props.item})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this._onWindowResize, false);
  }

  private _onWindowResize = () => {
    if (window.innerWidth <= 980 && window.innerWidth < this._windowWidth) {
      this._isMounted && this.props.callbackFunction({showRightPanel: false})
      this.setState({showRightPanel: false});
    }
    this._windowWidth = window.innerWidth;
  }

  private _handleLinkClick = (item: any): void => {
    this.setState({
      activeTab: item.props.itemKey
    });
  };

  public render() {
    const { showRightPanel, item, activeTab } = this.state;

    return(
      showRightPanel && item ?
        <Resizable
          size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
          onResizeStop={(e, direction, ref, d) => {
            var currentWidth = this.state.panelWidth.match(/\d+/)[0];
            this.setState({
              panelWidth: +currentWidth + +d.width + "px"
            });
            this._saveResizeState();
          }}
          enable={{ top:false, right:false, bottom:false, left:true, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
          minWidth={360}
          maxWidth={480}
          handleStyles= {{
            left: {
              "left": "0px",
              "width": "6px"
            }
          }}
          handleClasses={{"left": "resize-handle"}}
        >
          <div className="details-panel pt-0 pb-3 px-3">
            {!item.type && <div className="tabs-wrap sticky-top">
              <Pivot selectedKey={activeTab} headersOnly={true} onLinkClick={ this._handleLinkClick } className="pivot-item-icon-only">
                <PivotItem itemIcon="Info" itemKey="summary"></PivotItem>
                <PivotItem itemIcon="DietPlanNotebook" itemKey="details"></PivotItem>
              </Pivot>
            </div>}
            {!item.type && <>
              {
              activeTab === "summary" ? 
              <div>
              <div className="tab-title text-center py-3 pb-2 sticky-top">
                <p className="lead font-weight-bold m-0">{i18n.t('app:summary')}</p>
              </div>
              <div className='d-none align-items-center pb-2'>
                <div className='mr-3 d-flex' style={{background: 'white', borderRadius: '40px', height: '64px', width: '64px'}}>
                  <img className='d-none m-auto' src={ process.env.PUBLIC_URL + "/img/icon_synergy.png"} style={{width: '90%'}} />
                </div>
                <div>
                  <p className="m-0" style={{fontSize: '20px'}}>{item['Company name'] || item.name}</p>
                </div>
              </div>
              <div className='mt-2 justify-content-center'>
              { this.props.page === 'projectData' ? 
                <div>
                  <div className='mt-2' key={'name'}>
                    <div className="text-secondary mr-2">Project name</div>
                    <div className="username font-weight-bold">{item.name}</div>
                  </div>
                  <div className='mt-2' key={'closeDate'}>
                    <div className="text-secondary mr-2">Close date</div>
                    <div className="username font-weight-bold">{item.closeDate || '---'}</div>
                  </div>
                  <div className='mt-2' key={'stage'}>
                    <div className="text-secondary mr-2">Stage</div>
                    <div className="username font-weight-bold">{item.milestone || '---'}</div>
                  </div>
                  <div className='mt-2' key={'pipeline'}>
                    <div className="text-secondary mr-2">Pipeline</div>
                    <div className="username font-weight-bold">{item.pipeline || '---'}</div>
                  </div>
                  <div className='mt-2' key={'company'}>
                    <div className="text-secondary mr-2">Company</div>
                    <div className="username font-weight-bold">{item.company || '---'}</div>
                  </div>
                  <div className='mt-2' key={'owner'}>
                    <div className="text-secondary mr-2">Owner</div>
                    <div className="username font-weight-bold">{item.owner || '---'}</div>
                  </div>
                  <div className='mt-2' key={'amount'}>
                    <div className="text-secondary mr-2">Amount</div>
                    <div className="username font-weight-bold">{item.amount || '---'}</div>
                  </div>
                </div>
              : this.props.page === 'contactData' ? 
                <div>
                  <div className='mt-2' key={'Full name'}>
                    <div className="text-secondary mr-2">Full name</div>
                    <div className="username font-weight-bold">{item['First Name'] + ' ' + item['Last Name'] || '---'}</div>
                  </div>
                  <div className='mt-2' key={'Email'}>
                    <div className="text-secondary mr-2">Email</div>
                    {
                      item['Email'] ? 
                      <a href={'mailto:'+item['Email'] } className="username font-weight-bold">{item['Email'] || '---'}</a>
                      : <div className="username font-weight-bold">{'---'}</div>
                    }
                  </div>
                  <div className='mt-2' key={'Phone Number'}>
                    <div className="text-secondary mr-2">Mobile</div>
                    <div className="username font-weight-bold">{item['Phone Number'] || '---'}</div>
                  </div>
                  <div className='mt-2' key={'Company name'}>
                    <div className="text-secondary mr-2">Company name</div>
                    <div className="username font-weight-bold">{item['Company Name'] || '---'}</div>
                  </div>
                  <div className='mt-2' key={'Job title'}>
                    <div className="text-secondary mr-2">Job title</div>
                    <div className="username font-weight-bold">{item['Job Title'] || '---'}</div>
                  </div>
                </div>
              : this.props.page === 'companyData' ? 
                <div>
                  <div className='mt-2' key={'Full name'}>
                    <div className="text-secondary mr-2">Company name</div>
                    <div className="username font-weight-bold">{item['Company name'] || '---'}</div>
                  </div>
                  <div className='mt-2' key={'Website URL'}>
                    <div className="text-secondary mr-2">Domain</div>
                    {
                      item['Website URL'] ? 
                      <a href={'https://'+item['Website URL'] } className="username font-weight-bold">{item['Website URL']}</a>
                      : <div className="username font-weight-bold">{'---'}</div>
                    }
                  </div>
                  <div className='mt-2' key={'Industry'}>
                    <div className="text-secondary mr-2">Industry</div>
                    <div className="username font-weight-bold">{item['Industry'] || '---'}</div>
                  </div>
                  <div className='mt-2' key={'Postal Code'}>
                    <div className="text-secondary mr-2">Postal Code</div>
                    <div className="username font-weight-bold">{item['Postal Code'] || '---'}</div>
                  </div>
                  <div className='mt-2' key={'City'}>
                    <div className="text-secondary mr-2">City</div>
                    <div className="username font-weight-bold">{item['City'] || '---'}</div>
                  </div>
                  <div className='mt-2' key={'State'}>
                    <div className="text-secondary mr-2">State/Region</div>
                    <div className="username font-weight-bold">{item['State/Region'] || '---'}</div>
                  </div>
                </div>
                : null }
              </div>
              </div>
              : activeTab === "details" ? 
              <div>
                <div className="tab-title text-center py-3 pb-2 sticky-top">
                  <p className="lead font-weight-bold m-0">{i18n.t('app:details')}</p>
                </div>
                <div className='d-none align-items-center pb-2'>
                  <div className='mr-3 d-flex' style={{background: 'white', borderRadius: '40px', height: '64px', width: '64px'}}>
                    <img className='d-none m-auto' src={ process.env.PUBLIC_URL + "/img/icon_synergy.png"} style={{width: '90%'}} />
                  </div>
                  <div>
                    <p className="m-0" style={{fontSize: '20px'}}>{item['Company name'] || item.name}</p>
                  </div>
                </div>
                <div className='mt-2 d-none justify-content-center'>
                  <TooltipHost
                    content="Mail"
                    id={'mail'}
                    setAriaDescribedBy={false}
                  >
                    <IconButton className='mx-2' iconProps={{iconName: 'Mail'}} aria-label="Mail" styles={{ root: {height: '40px', width: '40px'}, icon:{fontSize: '24px'} }}/>
                  </TooltipHost>
                  <TooltipHost
                    content="Phone"
                    id={'phone'}
                    setAriaDescribedBy={false}
                  >
                    <IconButton className='mx-2' iconProps={{iconName: 'Phone'}} aria-label="Phone" styles={{ root: {height: '40px', width: '40px'}, icon:{fontSize: '24px'} }}/>
                  </TooltipHost>
                  <TooltipHost
                    content="Add note"
                    id={'dietPlanNotebook'}
                    setAriaDescribedBy={false}
                  >
                    <IconButton className='mx-2' iconProps={{iconName: 'DietPlanNotebook'}} aria-label="DietPlanNotebook" styles={{ root: {height: '40px', width: '40px'}, icon:{fontSize: '24px'} }}/>
                  </TooltipHost>
                  <TooltipHost
                    content="Schedule"
                    id={'calendar'}
                    setAriaDescribedBy={false}
                  >
                    <IconButton className='mx-2' iconProps={{iconName: 'Calendar'}} aria-label="Calendar" styles={{ root: {height: '40px', width: '40px'}, icon:{fontSize: '24px'} }}/>
                  </TooltipHost>
                </div>
                {Object.keys(item).map(function(key) {
                  return (
                    item[key] && <div className='mt-2' key={key}>
                      <div className="text-secondary mr-2">{key}</div>
                      <div className="username font-weight-bold">{item[key]}</div>
                    </div>
                  )
                })}
              </div> 
              : null}
            </>}
            {item.type && <>
              <div className="tab-title text-center" style={{paddingTop: '13px', paddingBottom: '13px'}}>
                <p className="lead font-weight-bold m-0">{i18n.t('app:summary')}</p>
              </div>
                <div className='mt-2' key={'name'}>
                  <div className="text-secondary mr-2">Activity title</div>
                  <div className="username font-weight-bold">{item.title}</div>
                </div>
                <div className='mt-2' key={'closeDate'}>
                  <div className="text-secondary mr-2">Author</div>
                  <div className="username font-weight-bold">{item.content.authorName || '---'}</div>
                </div>
                <div className='mt-2' key={'stage'}>
                  <div className="text-secondary mr-2">Date</div>
                  <div className="username font-weight-bold">{new Date(item.content.date).toLocaleDateString() || '---'}</div>
                </div>
            </>}
            
          </div>
        </Resizable>

      : null
    )
  }

  private _saveResizeState() {
    const { panelHeight, panelWidth } = this.state;
    var prefs = {
      height: panelHeight,
      width: panelWidth
    }
    localStorage.setItem("detailsPanelPrefs", JSON.stringify(prefs))
  }

}
