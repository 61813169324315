import * as React from 'react';
import { Header } from '../_components/Header';
import { Sidebar } from '../_components/Sidebar';
import { history } from '../_helpers';
import "moment/min/locales";
import { ConstrainMode, DetailsListLayoutMode, IColumn, Icon, IDocumentCardStyles, mergeStyles, Persona, Selection, SelectionMode, TooltipHost } from '@fluentui/react';
import i18n from "i18next";
import { ListExplorer } from '../_components/ListExplorer';

const fakePipelines = [
  {
    name: "Investments", 
    milestones: [
      {name: 'Qualified', color: 'rgb(196, 196, 196)'},
      {name: 'Piched', color: 'blue'},
      {name: 'Proposal', color: 'rgb(254, 192, 110)'},
      {name: 'Execution', color: 'yellow'},
      {name: 'Diligence', color: 'limegreen'},
      {name: 'Closing', color: 'green'}
    ]
  },
  {
    name: "Divestments", 
    milestones: [
      {name: 'To do', color: 'rgb(196, 196, 196)'},
      {name: 'To do later', color: 'lightblue'},
      {name: 'To wait/follow up', color: 'blue'},
    ]
  }
]

const fakeTasks = [
  {key : '7', name: 'Financial Model', stage: 'Pre-marketing', pipeline: 'Divestments', milestone: 'To do', project:'Project: Lima: Divesture', owner:'Jaime Rivas'},
  {key : '2', name: 'Working Party List', stage: 'Pre-marketing', pipeline: 'Divestments', milestone: 'To do', project:'Project Kilo: Buybacks', owner:'Jaime Rivas'},
  {key : '0', name: 'Define Strategy', stage: 'Pre-marketing', pipeline: 'Divestments', milestone: 'To do later', project:'Project Kilo: Buybacks', owner:'Jaime Rivas'},
  {key : '3', name: 'Prepare Non-Disclosure Agreement (NDA)', stage: 'Pre-marketing', pipeline: 'Divestments', milestone: 'To do later', project:'Project Kilo: Buybacks', owner:'Jaime Rivas'},
  {key : '1', name: 'Prepare the Teaser', stage: 'Pre-marketing', pipeline: 'Divestments', milestone: 'To do later', project:'Project Kilo: Buybacks', owner:'Jaime Rivas'},
  {key : '4', name: 'Brainstorm information for Information Memorandum', stage: 'Pre-marketing', pipeline: 'Divestments', milestone: 'To wait/follow up', project:'Project Kilo: Buybacks', owner:'Jaime Rivas'},
  {key : '5', name: 'Prepare the Confidentiality Information Memorandum (CIM)', stage: 'Pre-marketing', pipeline: 'Divestments', milestone: 'To wait/follow up', project:'Project: Lima: Divesture', owner:'Jaime Rivas'},
  {key : '6', name: 'Valuation report', stage: 'Pre-marketing', pipeline: 'Divestments', milestone: 'To wait/follow up', project:'Project: Lima: Divesture', owner:'Jaime Rivas'},
  {key : '8', name: 'Prepare list of potential buyers', stage: 'Marketing', pipeline: 'Divestments', milestone: 'To do', project:'Project: Lima: Divesture', owner:'Jaime Rivas'},
  {key : '9', name: 'Contact buyers', stage: 'Marketing', pipeline: 'Divestments', milestone: 'To do later', project:'Project Foxtrot: Merger', owner:'Jaime Rivas'},
  {key : '11', name: 'Review interest and present to Board', stage: 'Marketing', pipeline: 'Divestments', milestone: 'To do later', project:'Project Foxtrot: Merger', owner:'Jaime Rivas'},
  {key : '12', name: 'Board presentation', stage: 'Marketing', pipeline: 'Divestments', milestone: 'To wait/follow up', project:'Project Foxtrot: Merger', owner:'Jaime Rivas'},
  {key : '10', name: 'Schedule initial meeting', stage: 'Marketing', pipeline: 'Divestments', milestone: 'To wait/follow up', project:'Project Foxtrot: Merger', owner:'Jaime Rivas'},
  {key : '15', name: 'Approval to go to next step', stage: 'First bids', pipeline: 'Divestments', milestone: 'To do', project:'Project Golf: Pitch', owner:'Jaime Rivas'},
  {key : '13', name: 'Receive initial bids', stage: 'First bids', pipeline: 'Divestments', milestone: 'To do', project:'Project Golf: Pitch', owner:'Jaime Rivas'},
  {key : '14', name: 'Bids presentation to shareholders and management team', stage: 'First bids', pipeline: 'Divestments', milestone: 'To wait/follow up', project:'Project Golf: Pitch', owner:'Jaime Rivas'},
  {key : '16', name: 'Create Data Room for DD', stage: 'Due Diligence', pipeline: 'Divestments', milestone: 'To do', project:'Project Foxtrot: Merger', owner:'Jaime Rivas'},
  {key : '17', name: 'Schedule Management Meetings', stage: 'Due Diligence', pipeline: 'Divestments', milestone: 'To wait/follow up', project:'Project Foxtrot: Merger', owner:'Jaime Rivas'},
  {key : '18', name: 'Schedule visit to the manufacturing plant', stage: 'Due Diligence', pipeline: 'Divestments', milestone: 'To wait/follow up', project:'Project Foxtrot: Merger', owner:'Jaime Rivas'},
  {key : '19', name: 'Receive binding bids', stage: 'Final bids', pipeline: 'Divestments', milestone: 'To do', project:'Project Foxtrot: Merger', owner:'Jaime Rivas'},
  {key : '20', name: 'Draft definitive agreement', stage: 'Final bids', pipeline: 'Divestments', milestone: 'To do later', project:'Project Foxtrot: Merger', owner:'Jaime Rivas'},
  {key : '21', name: 'Engage lawyers', stage: 'Final bids', pipeline: 'Divestments', milestone: 'To do later', project:'Project Foxtrot: Merger', owner:'Jaime Rivas'}
]

export interface Props {
  appPlatform: string,
  database: any,
  isOnline: boolean,
  maintenance: boolean,
  socket: any,
  pusher: any,
  page: string,
  userData: any,
  foldersList: any,
  newFolder: any,
  repoUsers: any,
}

export interface State { 
  userData: any;
  breadcrumbPath: any[];
  searchQuery: any;
  syncStatus: any;
  items: any;
  columns: any;
  selItem: any;
}

export class TasksPage extends React.Component<Props, State> {
  private _isMounted: boolean;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();
  private listExplorerRef:any = React.createRef();
  private detailsPanelRef:any = React.createRef();

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this.state = {
      userData: null,
      breadcrumbPath: [{
        text: i18n.t('app:tasks'),
        key: 'tasks',
        onClick: ()=>{history.push('/tasks')}
      }],
      searchQuery: [],
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      columns: [],
      items: _copyAndSort(fakeTasks, 'milestone', false),
      selItem: null
    }
  }

  public componentDidMount() {
    this._isMounted = true
    this._isMounted && this._getColumns();  }

  private _getColumns() {

    const columns = [
      {
        key: 'fakeColumn',
        name: 'fakeColumn',
        fieldName: 'conflict',
        minWidth: 0,
        maxWidth: 0,
        isRowHeader: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        styles: { root: { display: "none" } },
        className: "d-none"
      },
      {
        key: 'name',
        name: 'Task name',
        fieldName: 'name',
        minWidth: 220,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return <span title={ item.fileName }>{ item.name }</span>
        }
      },
      {
        key: 'project',
        name: i18n.t('app:Project'),
        fieldName: 'project',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
            <span title={ item.project }>{ item.project }</span>
          )
        }
      },
      {
        key: 'milestone',
        name: i18n.t('app:Stage'),
        fieldName: 'milestone',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onRender: this.getMilestone
      },
      {
        key: 'milestonearrows',
        name: i18n.t('Task progress'),
        fieldName: 'milestonearrows',
        minWidth: 200,
        isResizable: true,
        data: 'string',
        onRender: this.getStagesArrows,
        isPadded: true
      },
      {
        key: 'owner',
        name: i18n.t('Assigned to'),
        fieldName: 'owner',
        minWidth: 64,
        maxWidth: 128,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        onRender: (item: any) => {
          return (
            <div className='d-flex align-items-center'>
                <span><Persona className="tag" size={10} text={item.owner}/></span>
            </div>  
            )
        },
        isPadded: true
      },
    ]

    this._isMounted && this.setState({columns: columns})
  }

  callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  public render() {

    const {columns, items} = this.state;

    return(
      <div>
        <Header
          userData={ this.props.userData }
          breadcrumbPath={ this.state.breadcrumbPath }
          sidebarRef={ this.sidebarRef }
          page={this.props.page}
          actions="tasks"
          getActivity={ null }
          syncStatus={this.state.syncStatus}
        />

        <div className="content-wrap d-flex flex-row" >
          <Sidebar
            ref={(instance:any) => { this.sidebarRef = instance; }}
            userData={ this.props.userData }
            page={this.props.page}
          />

          <div className="list flex-grow-1 justify-content-center" style={{textAlign: 'left'}}>
            <ListExplorer
              ref={instance => { this.listExplorerRef = instance; }}
              appPlatform={ this.props.appPlatform }
              isOnline={ this.props.isOnline }
              maintenance={ this.props.maintenance }
              socket={this.props.socket}
              pusher={this.props.pusher}
              userData={this.props.userData}
              foldersList={ this.props.foldersList }
              items={items}
              selItem={ this.state.selItem }
              isCompactMode={true}
              columns={columns}
              selectionMode={SelectionMode.multiple}
              layoutMode={DetailsListLayoutMode.fixedColumns}
              //enableShimmer={isLoading}
              constrainMode={ConstrainMode.unconstrained}
              //onFileDrop={this.onFileDrop.bind(this)}
              //getCurrentContent={console.log('Get Content')}
              //getOfflineFiles={this._getOfflineFiles.bind(this)}
              //isLoading={isLoading}
              //blankLoading={blankLoading}
              //emptyMessage={emptyMessage}
              headerRef={ this.headerRef }
              detailsPanelRef={ this.detailsPanelRef }
              callbackFunction={ this.callbackFunction }
              page={this.props.page}
              //updateItem={ this.updateItem }
              sidebarRef={ this.sidebarRef }
              //pipelineId={this.state.pipelineId}
            /> 
          </div>
        </div>
      </div>
    )
  }

  private getMilestone(item:any) {
    let pipeline = fakePipelines.filter((pipeline)=>{
      return item.pipeline === pipeline.name;
    })[0];
    let nStages = pipeline.milestones.length;
    let index = 0;
    return (
      pipeline.milestones.map((milestone)=>{
        index++;
        if (milestone.name === item.milestone) {
          return (
            <div key={item.milestone} className='d-flex align-items-center'>
              <Icon 
                iconName="CircleShapeSolid" 
                className="tag mr-2" 
                style={{
                  fontSize:12,
                  textAlign:'center',
                  color: 'rgb(16, 110, 190)',
                  opacity: index/nStages
                }} 
              />
              <span style={{paddingBottom: '2px'}}>{item.milestone}</span>
            </div> 
          )
        }
      })
    )
  }

  private getStagesArrows(item:any) {
    let selected = false
    let pipeline = fakePipelines.filter((pipeline)=>{
      return item.pipeline === pipeline.name;
    })[0];
    let nStages = pipeline.milestones.length;
    let index = 0;
    let colors = ['rgba(192,0,0,.8)', 'rgba(200,50,0,.8)', 'rgba(255,155,0,.8)', 'rgba(255,255,0,.8)', 'rgba(200,255,55,.8)', 'rgba(100,255,50,.8)', 'rgba(0,200,0,.8)']
    return (
      <div className='d-flex align-items-center align-self-center pt-1'>
        { true ?
          pipeline.milestones.map((milestone)=>{
            index++;
            if (!selected && milestone.name !== item.milestone) {
              return (
                <TooltipHost key={milestone.name}  content={milestone.name} setAriaDescribedBy={false}>
                  <div style={{opacity: index/nStages}} className='arrow-pointer selected'></div>
                </TooltipHost>
              )
            }
            else if (!selected && milestone.name === item.milestone) {
              selected = true
              return (
                <TooltipHost key={milestone.name}  content={milestone.name} setAriaDescribedBy={false}>
                  <div style={{opacity: index/nStages}} className='arrow-pointer selected'></div>
                </TooltipHost>
              )
            }
            else if (selected && milestone.name !== item.milestone) {
              return (
                <TooltipHost key={milestone.name}  content={milestone.name} setAriaDescribedBy={false}>
                  <div style={{}} className='arrow-pointer'></div>
                </TooltipHost>
              )
            }
          })
          :
          pipeline.milestones.map((milestone)=>{
            index++;
            if (!selected && milestone.name !== item.milestone) {
              return (
                <TooltipHost key={milestone.name}  content={pipeline.name + " - " + milestone.name} setAriaDescribedBy={false}>
                  <div style={{background: colors[Math.ceil((index/nStages)*colors.length)-1], borderColor: colors[Math.ceil((index/nStages)*colors.length)-1]}} className='arrow-pointer selected'></div>
                </TooltipHost>
              )
            }
            else if (!selected && milestone.name === item.milestone) {
              selected = true
              return (
                <TooltipHost key={milestone.name}  content={pipeline.name + " - " + milestone.name} setAriaDescribedBy={false}>
                  <div style={{background: colors[Math.ceil((index/nStages)*colors.length)-1], borderColor: colors[Math.ceil((index/nStages)*colors.length)-1]}} className='arrow-pointer selected'></div>
                </TooltipHost>
              )
            }
            else if (selected && milestone.name !== item.milestone) {
              return (
                <TooltipHost key={milestone.name}  content={pipeline.name + " - " + milestone.name} setAriaDescribedBy={false}>
                  <div className='arrow-pointer'></div>
                </TooltipHost>
              )
            }
          })
        }
      </div>  
    )
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        newCol.isSortedDescending = !currColumn.isSortedDescending;
        newCol.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    var newItems;
    if (column.key === "name") {
      newItems = _copyAndSortName(items, currColumn.fieldName!, currColumn.isSortedDescending);
    } else {
      newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    }

    this._isMounted && this.setState({
      columns: newColumns,
      items: newItems
    });
  };
  
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof any;
  if (key === 'amount') {
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? parseFloat(a[key].split(' ')[0]) < parseFloat(b[key].split(' ')[0]) : parseFloat(a[key].split(' ')[0]) > parseFloat(b[key].split(' ')[0])) ? 1 : -1));
  } else
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

function _copyAndSortName<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  return items.slice(0).sort((a,b) => {
    if (!isSortedDescending) {
      if(a["fileType"] < b["fileType"])
          return -1;
      if(b["fileType"] < a["fileType"])
          return 1;

      return a["name"].localeCompare(b["name"]);
    } else {
      if(a["fileType"] > b["fileType"])
          return -1;
      if(b["fileType"] > a["fileType"])
          return 1;

      return b["name"].localeCompare(a["name"]);
    }

  });
}