import * as React from 'react';
import ReactDOM from 'react-dom';
import { history } from '../_helpers';
import UAParser from 'ua-parser-js';
import { ScrollablePane, ScrollbarVisibility } from '@fluentui/react/lib/ScrollablePane';
import { FocusZone } from '@fluentui/react-focus';
import { IList, List } from '@fluentui/react/lib/List';
import { ShimmeredDetailsList } from '@fluentui/react/lib/ShimmeredDetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { Sticky, StickyPositionType } from '@fluentui/react/lib/Sticky';
import { IRenderFunction } from '@fluentui/react/lib/Utilities';
import { IDetailsHeaderProps, IDetailsList, Selection, SelectionMode, SelectionZone, IColumn, IDragDropEvents, IDragDropContext, CheckboxVisibility, DetailsListLayoutMode  } from '@fluentui/react/lib/DetailsList';
import { Check } from '@fluentui/react/lib/Check';
import { IContextualMenuProps, ContextualMenuItemType, DirectionalHint, ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { Text } from '@fluentui/react/lib/Text';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PivotItem, Pivot } from '@fluentui/react/lib/Pivot';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { ChoiceGroup } from '@fluentui/react/lib/ChoiceGroup';
import { TextField } from '@fluentui/react/lib/TextField';
import { TooltipDelay } from '@fluentui/react/lib/Tooltip';
import { PrimaryButton, DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  Modal,
  SelectableOptionMenuItemType,
  TooltipHost
} from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import { getFileTypeIconProps, FileIconType } from '@fluentui/react-file-type-icons';
import { ITheme, mergeStyleSets, getTheme, getFocusStyle } from '@fluentui/react/lib/Styling';
import { Panel, MessageBar, MessageBarType } from '@fluentui/react';
import Moment from 'moment';

import { userService } from '../_services';
import { FolderRole } from './FolderRole';
import { ActionsPermissions } from './ActionsPermissions';
import { invalidChars } from '../_data/invalid-chars'
import * as clipboardy from 'clipboardy'

import i18n from "i18next";
import isElectron from 'is-electron';
import Cookies from 'js-cookie'

const officeExtensions = [
  "docx",
  "xlsx",
  "pptx"
]

const officeCompatibilityExtensions = [
  "doc",
  "docx",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "pdf"
]

const officeOnlineExtensions = [
  "docx",
  "xlsx",
  "pptx",
  "wopitest",
  "wopitestx"
]

const previewExtensions = [
  "docx",
  "doc",
  "pptx",
  "ppt",
  "xlsx",
  "xls",
  "pdf",
  "png",
  "jpg",
  "jpeg",
  "svg",
  "txt",
  "email"
]

const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test( userAgent );
}

const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator["standalone"]);

const detectMob = () => {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}

const ROWS_PER_PAGE = 5;
const MAX_ROW_HEIGHT = 120;

var electronVersion;
if (typeof window !== 'undefined' && isElectron()) {
  const { ipcRenderer } = window.require("electron");
  ipcRenderer.send('app_version');
  ipcRenderer.on('app_version', (event, arg) => {
    ipcRenderer.removeAllListeners('app_version');
    electronVersion = arg.version.replace(/\./g,'-');
  })
}

export interface Props {
  match: {
    url: string
  }
}

export interface FileExplorerState {
  alertMessage: any;
  parentRole: number;
  folderRole: number;
  focusItem: any;
  openingFile: boolean;
  duplicatingDocument: boolean;
  duplicatingFolder: boolean;
  attachedFiles: any;
  showAttachedFiles: boolean;
  currentAttachFile: any;
  attachedFileVersion: any;
  fileDefaultName: string;
  attachNameOption: string;
  attachName: string;
  attachNameError: string;
  attachNameSuccess: boolean;
  saveAsPdfFile: any;
  showSaveAsPdf: boolean;
  downloadingFiles: any;
  draggedItem: any;
  itemToEdit: any;
  renameItemDialog: boolean;
  newItemName: string;
  newItemTags: string;
  newItemComments: string;
  renameWarning: string;
  renamingItem: boolean;
  movingItem: boolean;
  movingItemInfo: any;
  showLockedDocDialog: boolean;
  lockedDocument: any;
  creatingPublicLink: boolean;
  showPublicLinkDialog: boolean;
  publicLinkDialogTitle: string;
  enablePublicLinkNotification: boolean;
  copyPastingItems: boolean;
  showPasteDialog: boolean;
  pasteDialogTitle: string;
  enablePasteNotification: boolean;
  zippingItems: boolean;
  showZipDialog: boolean;
  zipDialogTitle: string;
  enableZipNotificacion: boolean;
  unZippingFile: boolean;
  showUnzipDialog: boolean;
  unzipDialogTitle: string;
  enableUnzipNotificacion: boolean;
  activeShareTab: string;
  contextualMenuProps?: IContextualMenuProps;
  mobileView: boolean;
  showMobileDetails: boolean;
  selectMode: boolean;
  imagePreviewDialog: boolean;
  previewData: any;
  showContextualFileExplorerMenu: boolean;
  contextualFileExplorerMenuProps?: IContextualMenuProps;
  clipboardCopy: any;
  inheritTags: string;
  searchWord: string;
  selItem: any;
  showSelItemDialog: boolean;
}

export class FileExplorer extends React.Component<Props, FileExplorerState> {
  private _isMounted: boolean;
  private _selection: Selection;
  private _dragDropEvents: IDragDropEvents;
  private _root:any = React.createRef<IDetailsList>();
  private _listRoot: any = React.createRef<IList>();

  private _columnCount: number;
  private _columnWidth: number;
  private _rowHeight: number;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this._root = React.createRef<IDetailsList>();
    this._listRoot = React.createRef<IList>();

    this._columnCount = 0
    this._columnWidth = 0
    this._rowHeight = 0

    this.props = props

    this._dragDropEvents = this._getDragDropEvents();

    this._selection = new Selection({
      canSelectItem: this.canSelectItem.bind(this),
      onSelectionChanged: () => {
        var selFile: any = this._selection.getSelection()[this._selection.getSelection().length - 1]
        if(selFile) {
          if(this.props.fileExplorerView === "thumbnails"
            && (!this.props.selFile
              || (this.props.selFile && this.props.selFile !== selFile.id))) {
              let currentSelFile: any = this._selection.getSelection()[this._selection.getSelection().length - 1]
              if(selFile.id === (currentSelFile && currentSelFile.id)) {
                this._listRoot.current.forceUpdate()
              }
          }


          var permissionsId = selFile.fileType === "dir" ? selFile.id : selFile.parent_id || selFile.parentId

          if(permissionsId && this.props.userData && this.props.foldersList) {
            this.getFolderRole(permissionsId, this.props.userData, this.props.foldersList)
          }

          var route = history.location.pathname
          var storedLastFocusedItems = JSON.parse(localStorage.getItem("lastFocusedItems") || "[]")

          var alreadyStored = storedLastFocusedItems.filter(item => {
            return item.route === route
          })[0]
          
          if(alreadyStored) {
            alreadyStored.item = selFile.id
          } else {
            var newFocusedItem = {
              route: route,
              item: selFile.id
            }
            storedLastFocusedItems.push(newFocusedItem)
          }

          localStorage.setItem("lastFocusedItems", JSON.stringify(storedLastFocusedItems))
        } else {
          if(this.props.fileExplorerView === "thumbnails" && this.props.items && this.props.items[0]) this.props.updateItem(this.props.items[0].id, "hasBeenSelected", true)
        }
        
        setTimeout(() => {
          if (selFile && this._selection.getSelectedCount() === 1) {
            this._isMounted && this.props.callbackFunction({selFile: selFile});
          } else if (selFile && this._selection.getSelectedCount() > 1) {
            this._isMounted && this.props.callbackFunction({selFile: null});
          } else if (this._selection.getSelectedCount() === 0) {
            this._isMounted && this.props.callbackFunction({selFile: null});
          }
        }, 50)
      }
    });

    this.state = {
      alertMessage: null,
      parentRole: 10,
      folderRole: 10,
      focusItem: null,
      openingFile: false,
      duplicatingDocument: false,
      duplicatingFolder: false,
      attachedFiles: null,
      showAttachedFiles: false,
      currentAttachFile: null,
      attachedFileVersion: null,
      attachNameOption: 'fileNameVersion',
      attachName: '',
      attachNameError: '',
      attachNameSuccess: false,
      fileDefaultName: "fileNameVersion",
      saveAsPdfFile: null,
      showSaveAsPdf: false,
      downloadingFiles: [],
      draggedItem: null,
      itemToEdit: null,
      renameItemDialog: false,
      newItemName: "",
      newItemTags: "",
      newItemComments: "",
      renameWarning: "",
      renamingItem: false,
      movingItem: false,
      movingItemInfo: null,
      showLockedDocDialog: false,
      lockedDocument: null,
      creatingPublicLink: false,
      showPublicLinkDialog: false,
      publicLinkDialogTitle: "",
      enablePublicLinkNotification: false,
      copyPastingItems: false,
      showPasteDialog: false,
      pasteDialogTitle: "",
      enablePasteNotification: false,
      zippingItems: false,
      showZipDialog: false,
      zipDialogTitle: "",
      enableZipNotificacion: false,
      unZippingFile: false,
      showUnzipDialog: false,
      unzipDialogTitle: "",
      enableUnzipNotificacion: false,
      activeShareTab: 'internal',
      contextualMenuProps: undefined,
      mobileView: false,
      showMobileDetails: false,
      selectMode: false,
      imagePreviewDialog: false,
      previewData: null,
      showContextualFileExplorerMenu: false,
      contextualFileExplorerMenuProps: undefined,
      clipboardCopy: null,
      inheritTags: "",
      searchWord: "",
      selItem: null,
      showSelItemDialog: false
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this._setFocusFirstItem()
    this._setSelItem()

    if(this.props.folderId && this.props.userData && this.props.foldersList) {
      this.getParentRole(this.props.folderId, this.props.userData, this.props.foldersList)
    }

    document.addEventListener("keydown", this._handleKeyDown);

    var listEl = document.getElementsByClassName("listEl")[0]
    if(listEl) listEl.addEventListener("keydown", this._handleListKeyDown);

    var explorerEl = document.getElementsByClassName("explorerEl")[0]
    if(explorerEl) explorerEl.addEventListener("click", this._handleClickOutside.bind(this));

    this._onWindowResize();
    window.addEventListener('resize', this._onWindowResize);
  }

  public componentDidUpdate(prevProps) {
    if(this.props.fileExplorerView === "thumbnails") {
      this._selection.setItems(this.props.items, false);

      if(this.props.showLeftPanel !== prevProps.showLeftPanel
        || this.props.showRightPanel !== prevProps.showRightPanel) {
        setTimeout(() => {
          this._listRoot.current.forceUpdate()
        }, 0)
      }
    }

    if(this.props.fileExplorerView !== prevProps.fileExplorerView) {
      var listEl = document.getElementsByClassName("listEl")[0]
      if(listEl) {
        listEl.removeEventListener("keydown", this._handleListKeyDown, false);
        listEl.addEventListener("keydown", this._handleListKeyDown);
      }
    }
    
    if(this.props.isOnline !== prevProps.isOnline) {
      const { isOnline }= this.props
      console.log("IS ONLINE", isOnline)
    }

    if(this.props.maintenance !== prevProps.maintenance) {
      const { maintenance }= this.props
      console.log("MAINTENANCE", maintenance)
    }

    if(this.props.socket !== prevProps.socket) {
      if(this.props.socket) this._newSocketMessage(this.props.socket)
    }

    if(this.props.pusher !== prevProps.pusher) {
      if(this.props.pusher) this._newPusherMessage(this.props.pusher)
    }

    if(this.props.items !== prevProps.items) {
      if(document.activeElement && document.activeElement.classList && !document.activeElement.classList.contains("ms-SearchBox-field")) {
        this._setFocusFirstItem()
        this._setSelItem()
      }
    }

    if(this.props.draggedItem !== prevProps.draggedItem) {
      if(this.props.draggedItem && this.props.draggedItem[0]
        && (this.props.draggedItem[0].parentId !== this.props.folderId)) {
        userService.getFolderDetails(this.props.folderId).then(response => {
          var destination = response.data
          this._moveSelectedItems(this.props.draggedItem, destination);
          this.props.callbackFunction({ draggedItem: null })
        }).catch(error => {
          console.log(error)
          this.props.callbackFunction({ draggedItem: null })
          if (this.props.appPlatform === ("electron" || "openfin")) {
            const { ipcRenderer } = window.require("electron");
            ipcRenderer.send('clearDraggedItem')
          }
        })
      } else {
        if (this.props.appPlatform === ("electron" || "openfin")) {
          const { ipcRenderer } = window.require("electron");
          ipcRenderer.send('clearDraggedItem')
        }
      }
    }

    if(this.props.location && (this.props.location.search !== prevProps.location.search)) {
      this._setSelItem()
    }

    if(this.props.folderId !== prevProps.folderId
      || this.props.fileExplorerType !== prevProps.fileExplorerType
      || this.props.searchType !== prevProps.searchType) {
      this._setFocusFirstItem()
      this._setSelItem() 
      this.setState({imagePreviewDialog: false})
    }

    if(this.props.folderId !== prevProps.folderId
      || this.props.userData !== prevProps.userData
      || this.props.foldersList !== prevProps.foldersList) {
      if(this.props.folderId && this.props.userData && this.props.foldersList) {
        this.getParentRole(this.props.folderId, this.props.userData, this.props.foldersList)
        this._setSelItem()
      }
    }
    
    if (this._selection.count === 0 && this.state.selectMode) {
      this.setState({selectMode: false})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("keydown", this._handleKeyDown, false);
    var listEl = document.getElementsByClassName("listEl")[0]
    if(listEl) listEl.removeEventListener("keydown", this._handleListKeyDown, false);
    var explorerEl = document.getElementsByClassName("explorerEl")[0]
    if(explorerEl) explorerEl.removeEventListener("click", this._handleClickOutside.bind(this), false);
    window.removeEventListener('resize', this._onWindowResize, false);
  }

  private getParentRole = async(folderId, userData, foldersList) => {
    var role = await FolderRole(folderId, userData, foldersList)
    this._isMounted && this.setState({parentRole: role})
  }

  private getFolderRole = async(folderId, userData, foldersList) => {
    var role = await FolderRole(folderId, userData, foldersList)
    this._isMounted && this.setState({folderRole: role})
  }

  private canSelectItem(item: any): boolean {
    return item.hasPermissions !== false;
  }

  private getCompanyName(item) {
    var attachedFileRepo = this.props.userData.repository;
    var isCompany = attachedFileRepo && attachedFileRepo.repository.type === "COMPANY"
    if (isCompany) return attachedFileRepo.repository.name
    else return false
  }

  private _handleClickOutside(event) {
    if (event.repeat) { return }
    if (document && document.activeElement && document.activeElement.tagName === "BODY") {
      this._setFocusFirstItem();
    }
  }

  public render() {
    const { openingFile, duplicatingDocument, attachedFiles, showAttachedFiles, currentAttachFile, saveAsPdfFile, showSaveAsPdf, itemToEdit, renameItemDialog, newItemName, renamingItem, renameWarning, movingItem, movingItemInfo, showLockedDocDialog, lockedDocument, showPublicLinkDialog, publicLinkDialogTitle, showPasteDialog, pasteDialogTitle, showZipDialog, zipDialogTitle, showUnzipDialog, unzipDialogTitle, contextualMenuProps, imagePreviewDialog, selItem, showSelItemDialog } = this.state;

    const { selFile } = this.props;

    var parser = new UAParser.UAParser();
    var os = parser.getResult().os;

    var fileActionsEnabled = true
    if(this.props.fileExplorerType === "openFiles"
      || this.props.storedType === "pendingDrafts")
      fileActionsEnabled = false

    const theme: ITheme = getTheme();
    const { palette, fonts } = theme;

    const classNames = mergeStyleSets({
      container: {
        overflow: 'auto',
        maxHeight: 500,
      },
      itemCell: [
        getFocusStyle(theme, { inset: -1 }),
        {
          //minHeight: 24,
          padding: '0 0 10 10',
          //paddingLeft: 0,
          boxSizing: 'border-box',
          display: 'flex',
        },
      ],
      itemImage: {
        flexShrink: 0,
      },
      itemContent: {
        marginLeft: 10,
        overflow: 'hidden',
        flexGrow: 1,
      },
      itemName: [
        fonts.xLarge,
        {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      ],
      itemIndex: {
        fontSize: fonts.small.fontSize,
        color: palette.neutralTertiary,
        marginBottom: 10,
      },
      chevron: {
        alignSelf: 'center',
        marginLeft: 10,
        color: palette.neutralTertiary,
        fontSize: fonts.large.fontSize,
        flexShrink: 0,
      },
    });

    const columnSlice = JSON.parse(JSON.stringify(this.props.columns.slice(1,3)))
    if (typeof this.props.columns !== undefined) {
      if (columnSlice && columnSlice[1] && this.state.mobileView && !isElectron()) {
        columnSlice[1].onRender = (item, index) => {
          return (
            <div className={classNames.itemCell}>
              <div onClick={()=> fileActionsEnabled && detectMob() ? this._onItemInvoked(item) : undefined} style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: window.innerWidth-100+'px'}}>
                { item.savingDraft ?
                  <span title={ "Saving..." }><Icon iconName="Save" className="saving mr-2 small" /></span>
                : null }
                { item.lock && !item.wopiLock && !item.lockingDocument ?
                  <Icon iconName="Lock" className={"mr-2 small" + (item.lock.id === this.props.userData.user.id ? " text-primary" : "")} />
                : item.wopiLock && !item.lockingDocument ?
                  <Icon iconName="OfficeLogo" className="mr-2 small" />
                : item.lockingDocument ?
                  <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
                :
                  null
                }
                <span style={{fontSize: 16}} title={ item.fileName } >{ item.name }</span><br/>
                <span style={{opacity: 0.7}}>{ Moment(item.dateModified).format('Y-MM-DD') } </span>
                {item.fileType === "file" ?
                <span style={{opacity: 0.7}}> - {item.fileSize}</span>
                : null}
              </div>
              <div className="my-auto" style={{marginLeft: 'auto'}}>
                <IconButton onClick={()=>this._onItemContextMenuMobile(item, index)} iconProps={{ iconName: 'More', styles: {root: {color: 'black', fontSize: '20px'}} }} ariaLabel="Options" style={{width:'48px'}}/>
              </div>
            </div>
          )
        }
        columnSlice[0].onRender = (item) => {
          return (
            item.fileType === 'dir' ?
              <>
                <Icon onClick={()=> fileActionsEnabled ? this._onItemInvoked(item) : undefined} {...getFileTypeIconProps({ type: FileIconType.folder, size: 32, imageFileType: 'svg' }) } />
                { !item.accessGranted && item.isFolderInPath ?
                  <Icon onClick={()=> fileActionsEnabled ? this._onItemInvoked(item) : undefined} iconName="ChevronRightSmall" className="no-access-folder" />
                : !item.accessGranted && !item.isFolderInPath ?
                  <Icon onClick={()=> fileActionsEnabled ? this._onItemInvoked(item) : undefined} iconName="lockSolid" className="no-access-folder" />
                : null }
              </>
            : item.fileType === 'file' ?
              <Icon onClick={()=> fileActionsEnabled ? this._onItemInvoked(item) : undefined} {...getFileTypeIconProps({ extension: item.icon, size: 32, imageFileType: 'svg' }) } />
            : null 
          )
        }
      }
      else if (columnSlice && columnSlice[1] && (!this.state.mobileView || isElectron())) {
        columnSlice[0].minWidth = 28;
        columnSlice[0].maxWidth = 28;
        columnSlice[1].onRender = (item) => {
          return (
            <div>
              { item.savingDraft ?
                <span title={ "Saving..." }><Icon iconName="Save" className="saving mr-2 small" /></span>
              : null }
              { item.lock && !item.wopiLock && !item.lockingDocument ?
                <Icon iconName="Lock" className={"mr-2 small" + (item.lock.id === this.props.userData.user.id ? " text-primary" : "")} />
              : item.wopiLock && !item.lockingDocument ?
                <Icon iconName="OfficeLogo" className="mr-2 small" />
              : item.lockingDocument ?
                <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
              :
                null
              }
              <span title={ item.fileName }>{ item.name }</span>
            </div>
          )
        }
        columnSlice[0].onRender = (item) => {
          return (
            item.fileType === 'dir' ?
              <>
                <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } />
                { !item.accessGranted && item.isFolderInPath ?
                  <Icon iconName="ChevronRightSmall" className="no-access-folder" />
                : !item.accessGranted && !item.isFolderInPath ?
                  <Icon iconName="lockSolid" className="no-access-folder" />
                : null }
              </>
            : item.fileType === 'file' ?
              <Icon {...getFileTypeIconProps({ extension: item.icon, size: 20, imageFileType: 'svg' }) } />
            : null 
          )
        }
      }
    }
    
    return(
      <div>
        {this.props.fileExplorerView === "thumbnails" ?
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}
          onContextMenu={ this._onFileExplorerContextMenu }
          className="explorerEl"
        >
          <div className='thumbnails-wrapper'>
            <MarqueeSelection
              selection={this._selection}
            >
              <SelectionZone
                selection={this._selection}
                onItemInvoked={(item, index, ev) => fileActionsEnabled && this.props.headerSection !== "tags" ? this._onItemInvoked(item, index, ev) : undefined}
                selectionPreservedOnEmptyClick={false}
                onItemContextMenu={fileActionsEnabled ? this._onItemContextMenu : undefined}
                selectionMode={fileActionsEnabled ? this.props.selectionMode : 0}
              >
                <FocusZone
                  shouldRaiseClicksOnEnter={false}
                >
                  <List
                    componentRef={this._listRoot}
                    className="thumbnails-view listEl"
                    items={this.props.items}
                    getItemCountForPage={ this._getItemCountForPage }
                    getPageHeight={ this._getPageHeight }
                    renderedWindowsAhead={ 4 }
                    onRenderCell={ this._onRenderCell.bind(this) }
                  />
                </FocusZone>
              </SelectionZone>
            </MarqueeSelection>
            </div>
            { !this.props.isLoading && this.props.canLoadMore ?
              <div className="load-more text-center p-3 w-100">
                <PrimaryButton onClick={() => this.props.getCurrentContent()} text={i18n.t('app:loadMoreResults')} disabled={ this.props.isLoadingMoreResults } className="w-50"/>
                { this.props.isLoadingMoreResults ?
                  <Spinner size={SpinnerSize.xSmall} className="d-inline-block ml-2" />
                : null }
              </div>
            : null }
          </ScrollablePane>
        : 
          window.innerWidth > 980 || isElectron() || !detectMob() ? 
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}
            onContextMenu={ this._onFileExplorerContextMenu }
            className="explorerEl"
          >
            <MarqueeSelection selection={this._selection}>
            <ShimmeredDetailsList
              componentRef={this._root}
              className={"listEl"}
              items={this.props.items}
              compact={this.props.isCompactMode}
              columns={this.props.columns}
              groups={this.props.groups || undefined}
              onRenderDetailsHeader={onRenderDetailsHeader}
              selectionMode={fileActionsEnabled ? this.props.selectionMode : 0}
              
              setKey="set"
              layoutMode={this.props.layoutMode}
              isHeaderVisible={true}
              selection={this._selection}
              selectionPreservedOnEmptyClick={false}
              onItemInvoked={(item, index, ev) => fileActionsEnabled && this.props.headerSection !== "tags" ? this._onItemInvoked(item, index, ev) : undefined}
              onRenderRow={ (props: any, defaultRender: any) => (
                props.item.hasPermissions === false ?
                  <TooltipHost
                    content="You don't have access to this file anymore"
                    directionalHint={DirectionalHint.topLeftEdge}
                    delay={TooltipDelay.zero}
                    calloutProps={{ gapSpace: 0, isBeakVisible: false }}
                  >
                    <div className={"noFilePermissions " + props.item.id}>
                      {defaultRender({...props})}            
                    </div>
                  </TooltipHost>
                :
                  <div className={(props.item.clipboard ? 'clipboard' : '')}>
                    {defaultRender({...props})}            
                  </div>
              ) }
              onItemContextMenu={fileActionsEnabled ? this._onItemContextMenu : undefined}
              dragDropEvents={fileActionsEnabled ? this._dragDropEvents : undefined}
              //enableShimmer={this.props.enableShimmer && !this.props.blankLoading}
              ariaLabelForShimmer="Content is being fetched"
              enterModalSelectionOnTouch={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              shimmerLines={12}
              constrainMode={this.props.constrainMode}
              listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
            />
            </MarqueeSelection>
            { !this.props.isLoading && this.props.canLoadMore ?
              <div className="load-more text-center p-3 w-100">
                <PrimaryButton onClick={() => this.props.getCurrentContent()} text={i18n.t('app:loadMoreResults')} disabled={ this.props.isLoadingMoreResults } className="w-50"/>
                { this.props.isLoadingMoreResults ?
                  <Spinner size={SpinnerSize.xSmall} className="d-inline-block ml-2" />
                : null }
              </div>
            : null }
          </ScrollablePane>
          :<ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} style={{height: isIos() && isInStandaloneMode() ? 'calc(100% - 58px)' :'calc(100% - 48px)'}}>
            <ShimmeredDetailsList 
              componentRef={this._root}
              items={this.props.items}
              columns={columnSlice? columnSlice : undefined}
              onRenderDetailsHeader={onRenderDetailsHeader}
              selectionMode={this.state.selectMode || !detectMob() ? SelectionMode.multiple : SelectionMode.single}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              selection={this._selection}
              selectionPreservedOnEmptyClick={false}
              onItemInvoked={fileActionsEnabled ? (item, index, ev) => this._onItemInvoked(item, index, ev) : undefined}
              onItemContextMenu={detectMob() ? ()=> this.setState({selectMode: true}) : fileActionsEnabled ? this._onItemContextMenu : undefined}
              dragDropEvents={fileActionsEnabled ? this._dragDropEvents : undefined}
              //enableShimmer={this.props.enableShimmer}
              ariaLabelForShimmer="Content is being fetched"
              enterModalSelectionOnTouch={false}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              shimmerLines={12}
              constrainMode={this.props.constrainMode}
              listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
              checkboxVisibility={this.state.selectMode || !detectMob() ? CheckboxVisibility.always : CheckboxVisibility.hidden}
            />
            { !this.props.isLoading && this.props.canLoadMore ?
              <div className="load-more text-center p-3 w-100">
                <PrimaryButton onClick={() => this.props.getCurrentContent()} text={i18n.t('app:loadMoreActivity')} disabled={ this.props.isLoadingMoreResults } className="w-50"/>
                { this.props.isLoadingMoreResults ?
                  <Spinner size={SpinnerSize.xSmall} className="d-inline-block ml-2" />
                : null }
              </div>
            : null }
          </ScrollablePane>
        }

        { this.state.contextualFileExplorerMenuProps ?
          <ContextualMenu {...this.state.contextualFileExplorerMenuProps} />
        : null }
        
        {this.state.alertMessage ?
          <div id="alertMessage">
            <MessageBar
                messageBarType={this.state.alertMessage.type}
                isMultiline={true}
                dismissButtonAriaLabel="Close"
                onDismiss={() => this.setState({alertMessage: null})}
              >
                <div dangerouslySetInnerHTML={{__html: this.state.alertMessage.text}}></div>
              </MessageBar>
          </div>
        : null }

        <div style={{opacity: this.state.searchWord.length > 0 ? 1 : 0 ,position: 'absolute', bottom: '16px', width: '100%', transitionDuration: '.2s'}}>
          <div style={{display: this.state.searchWord.length > 0 ? 'flex' : 'none', width: '200px', height: '80px', margin: 'auto', padding:'10px', borderRadius: '2px', color:'white', fontSize: '20px', fontWeight: 600, backgroundColor: 'rgba(0,0,0,0.6)'}}>
            <div className='m-auto' style={{textAlign: 'center', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{this.state.searchWord.toLowerCase()}</div>
          </div>
        </div>

        { (this.props.items.length === 0 && !this.props.isLoading && !this.props.isLoadingMoreResults)
          || (this.props.enableShimmer && !this.props.blankLoading && this.props.headerSection !== "tags") ?
          <div className="empty-folder-msg p-3">
            {this.props.blankLoading || (this.props.enableShimmer) ?
              <Spinner size={SpinnerSize.small} className="mt-5 mx-auto" />
            :
              <p className="text-center text-secondary mt-5 mx-auto px-3">{this.props.emptyMessage}</p>
            }
          </div>
        : null }

        {contextualMenuProps ?
        window.innerWidth <= 980 && !isElectron() && detectMob() ?
          <Dialog
          hidden={false}
          modalProps= {{isBlocking:false, styles: {main: {display: 'none'}}}}
          >
            <ContextualMenu {...contextualMenuProps} />
          </Dialog>
        :<ContextualMenu {...contextualMenuProps} />
        : null
        }

        { openingFile ?
          <Dialog
            hidden={!openingFile}
            dialogContentProps={{
              type: DialogType.normal,
              title: i18n.t('app:openingFile')
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "opening-dialog"
            }}
            onDismiss={ () => this.setState({openingFile: false}) }
          >
            <div className="text-center w-100">
            { selFile ?
              <>
              <aside>
                { selFile.fileName }
              </aside>
              <span className="spinner ml-2"><Spinner size={SpinnerSize.xSmall} /></span>
              </>
            : null }
            </div>
          </Dialog>
        : null }

        { selFile && duplicatingDocument ?
            <Dialog
              hidden={!duplicatingDocument}
              dialogContentProps={{
                type: DialogType.normal,
                title: i18n.t('app:duplicatingFile')
              }}
              modalProps={{
                isBlocking: true,
                styles: { main: { maxWidth: 640 } },
                dragOptions: undefined,
                className: "opening-dialog"
              }}
            >
              <div className="text-center w-100">
                <aside>
                  { selFile.name + '.' + selFile.fileExtension }
                </aside>
                <span className="spinner ml-2"><Spinner size={SpinnerSize.xSmall} /></span>
              </div>
            </Dialog>
        : null }

        { selItem && showSelItemDialog ?
          <Dialog
            hidden={!showSelItemDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: i18n.t('app:openFile'),
              showCloseButton: true
            }}
            modalProps={{
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "opening-dialog"
            }}
            onDismiss={() => this.setState({showSelItemDialog: false})}
          >
            <div className="dialog-content-wrap my-3">
              <p className='pb-2 m-0' style={{fontWeight:600}}>{i18n.t('app:filename')}:</p>
              <span><Icon className='mr-1 mb-3' style={{writingMode:'vertical-rl', width: '24px', height: '20px', fontSize: '20px'}} {...getFileTypeIconProps({ extension: selItem.fileExtension, size: 20, imageFileType: 'svg' }) } /><span style={{verticalAlign:'top'}}>{selItem.fileName}</span></span>
            </div>
            <div>
              <p className='pb-2 m-0' style={{fontWeight:600}}>{i18n.t('app:openIn')}</p>
            </div>
            <div className="d-flex justify-content-center my-3">
              <PrimaryButton
                text={i18n.t('app:desktopApp')}
                onClick={() => this._openInDesktop(selItem)}
                split
                splitButtonAriaLabel="See link options"
                aria-roledescription="split button"
                menuProps={{
                  items: [
                    {
                      key: 'editInDesktop',
                      text: i18n.t('app:editInDesktop'),
                      title: i18n.t('app:editInDesktop'),
                      iconProps: { iconName: 'OpenFile' },
                      onClick: () => this._editInDesktop(selItem)
                    },
                    {
                      key: 'viewInDesktop',
                      text: i18n.t('app:viewInDesktop'),
                      title: i18n.t('app:viewInDesktop'),
                      iconProps: { iconName: 'ProtectedDocument' },
                      onClick: () => this._viewInDesktop(selItem),
                      hidden: !this._isOfficeCompatibilityDoc(selItem.fileExtension)
                    }
                  ]
                }}
              />

              <div className="d-inline-block ml-3">
                { this._isOfficeDoc(selItem.fileExtension)
                  || (selItem.fileExtension && selItem.fileExtension.toLowerCase() === "pdf") ? 
                  <PrimaryButton
                    text={i18n.t('app:hereInWeb')}
                    onClick={() => this.setState({showSelItemDialog: false})}
                    split
                    splitButtonAriaLabel="See link options"
                    aria-roledescription="split button"
                    menuProps={{
                      items: [
                        {
                          key: 'editInBrowser',
                          text: i18n.t('app:editInBrowser'),
                          title: i18n.t('app:editInBrowser'),
                          iconProps: { iconName: 'OpenFile' },
                          onClick: () => {
                            console.log("Open to edit", selItem)
                            this._openOfficeOnline(selItem.id, "edit", selItem.lock, selItem.wopiLock, "self")
                          },
                          hidden: selItem && !this._isOfficeOnlineDoc(selItem.fileExtension)
                        },
                        {
                          key: 'viewInBrowser',
                          text: i18n.t('app:viewInBrowser'),
                          title: i18n.t('app:viewInBrowser'),
                          iconProps: { iconName: 'ProtectedDocument' },
                          onClick: () => {
                            console.log("Open read only", selItem)
                            if(this._isOfficeDoc(selItem.fileExtension)) {
                              this._openOfficeOnline(selItem.id, "view", selItem.lock, selItem.wopiLock, "self")
                            } else if (selItem.fileExtension && selItem.fileExtension.toLowerCase() === "pdf") {
                              var revisionId = selItem.activeRevisionId;
                              if(selItem.draft) revisionId = selItem.id + "-draft";
                              this._openPdfSynergyReaderOnline(selItem.id, revisionId)
                            }
                          },
                          hidden: selItem && (!this._isOfficeOnlineDoc(selItem.fileExtension) && (selItem.fileExtension && selItem.fileExtension.toLowerCase() !== "pdf"))
                        }
                      ]
                    }}
                  />
                :
                  <PrimaryButton
                    text={i18n.t('app:hereInWeb')}
                    onClick={() => this.setState({showSelItemDialog: false})}
                  />
                }
              </div>
            </div>
          </Dialog>
        : null }

        { showPublicLinkDialog ?
          <Dialog
            hidden={!showPublicLinkDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: this.state.creatingPublicLink ? publicLinkDialogTitle : i18n.t('app:publicLinkCreated')
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "creating-public-link"
            }}
            onDismiss={() => this.setState({showPublicLinkDialog: false, enablePublicLinkNotification: true})}
          >
            {this.state.creatingPublicLink ?
              <>
                <div className="dialog-content-wrap">
                  <p>If you dismiss this dialog, you will receive a notification once your public link has been created.</p>
                </div>

                <DialogFooter>
                  <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
                  <DefaultButton onClick={ () => this.setState({showPublicLinkDialog: false, enablePublicLinkNotification: true}) } className="secondaryButton">
                    OK
                  </DefaultButton>
                </DialogFooter>
              </>
            :
              <>
                <div className="dialog-content-wrap">
                  <p>{i18n.t('app:copied')}</p>
                </div>

                <DialogFooter>
                  <PrimaryButton onClick={ () => this.setState({showPublicLinkDialog: false})} >
                    OK
                  </PrimaryButton>
                </DialogFooter>
              </>
            }
          </Dialog>
        : null}

        { showPasteDialog ?
          <Dialog
            hidden={!showPasteDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: pasteDialogTitle
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "pasting-items"
            }}
            onDismiss={() => this.setState({showPasteDialog: false, enablePasteNotification: true})}
          >
            <div className="dialog-content-wrap">
              <p>If you dismiss this dialog, you will receive a notification once the items have been copied.</p>
            </div>

            <DialogFooter>
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
                <DefaultButton onClick={ () => this.setState({showPasteDialog: false, enablePasteNotification: true}) } className="secondaryButton">
                  OK
                </DefaultButton>
              </DialogFooter>
          </Dialog>
        : null}

        { showZipDialog ?
          <Dialog
            hidden={!showZipDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: zipDialogTitle
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "zipping-items"
            }}
            onDismiss={() => this.setState({showZipDialog: false, enableZipNotificacion: true})}
          >
            <div className="dialog-content-wrap">
              <p>If you dismiss this dialog, you will receive a notification once the items have been zipped.</p>
            </div>

            <DialogFooter>
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
                <DefaultButton onClick={ () => this.setState({showZipDialog: false, enableZipNotificacion: true}) } className="secondaryButton">
                  OK
                </DefaultButton>
              </DialogFooter>
          </Dialog>
        : null}

        { showUnzipDialog ?
          <Dialog
            hidden={!showUnzipDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: unzipDialogTitle
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "unzipping-items"
            }}
            onDismiss={() => this.setState({showUnzipDialog: false, enableUnzipNotificacion: true})}
          >
            <div className="dialog-content-wrap">
              <p>If you dismiss this dialog, you will receive a notification once the file has been unzipped.</p>
            </div>

            <DialogFooter>
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
                <DefaultButton onClick={ () => this.setState({showUnzipDialog: false, enableUnzipNotificacion: true}) } className="secondaryButton">
                  OK
                </DefaultButton>
              </DialogFooter>
          </Dialog>
        : null}

        { attachedFiles && showAttachedFiles && currentAttachFile ?
          <Dialog
            hidden={!showAttachedFiles}
            onDismiss={ev => this.setState({attachedFiles: null, showAttachedFiles: false, currentAttachFile: null, attachedFileVersion: null})}
            dialogContentProps={{
              type: DialogType.close,
              title: attachedFiles[attachedFiles.length - 1].outFile ? i18n.t('app:readyToAttach') : i18n.t('app:processingFileWait')
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "attach-dialog"
            }}
          >
            <div className="text-center w-100">
                { attachedFiles[attachedFiles.length - 1].outFile ?
                  <>
                    <aside className="d-inline-block lead draggable px-5 py-3" onDragStart={this._onDragStart} draggable >
                      { attachedFiles.length === 1 ?
                        <>
                        <Icon {...getFileTypeIconProps({ extension: attachedFiles[0].icon, size: 48, imageFileType: 'svg' }) } className="svg mr-2"  onDragStart={ evt => evt.preventDefault() } />
                        <span>{ attachedFiles[0].fileName }</span>
                        </>
                      :
                        <>
                        <Icon iconName="Copy" className="multipleFiles mr-2"  onDragStart={ evt => evt.preventDefault() } />
                        <span>{ attachedFiles.length + " " + i18n.t('app:lowerFiles') }</span>
                        </>
                      }
                    </aside>
                    { os.name === "Windows"  ?
                      attachedFiles.length === 1 ?
                        <p className="tip text-secondary mt-2 mb-0">{i18n.t('app:pasteFileWindows')}</p>
                      :
                        <p className="tip text-secondary mt-2 mb-0">{i18n.t('app:pasteFilesWindows')}</p>
                    :
                      attachedFiles.length === 1 ?
                        <p className="tip text-secondary mt-2 mb-0">{i18n.t('app:pasteFileMac')}</p>
                      :
                        <p className="tip text-secondary mt-2 mb-0">{i18n.t('app:pasteFilesMac')}</p>
                    }

                    <ChoiceGroup
                      options={
                        attachedFiles.length === 1 ? [
                          { key: "fileName", text: i18n.t('app:filename') },
                          { key: "fileNameVersion", text: i18n.t('app:fileNameVersion') },
                          { key: "fileNameVersionDate", text: i18n.t('app:fileNameVersionDate') },
                          { key: "fileNameVersionDateCompany", text: i18n.t('app:fileNameVersionDateCompany'), hidden: !this.getCompanyName(attachedFiles[0]) },
                          { key: "fileNameCustom", text: i18n.t('app:customFilename') }
                        ] : [
                          { key: "fileName", text: i18n.t('app:filename') },
                          { key: "fileNameVersion", text: i18n.t('app:fileNameVersion') },
                          { key: "fileNameVersionDate", text: i18n.t('app:fileNameVersionDate') },
                          { key: "fileNameVersionDateCompany", text: i18n.t('app:fileNameVersionDateCompany'), hidden: !this.getCompanyName(attachedFiles[0]) },
                        ]
                      }
                      onChange={this._onChangeAttachName}
                      label={i18n.t('app:selectNameAttach')}
                      required={true}
                      //defaultSelectedKey={this.state.fileDefaultName}
                      selectedKey={this.state.attachNameOption}
                      className="mt-4 mb-2 text-left" />
                      

                    {attachedFiles.length === 1 && <>
                      <form className='d-flex' onSubmit={this._onChangeAttachCustomName}>
                        <TextField
                          className='mr-1 flex-grow-1'
                          disabled={this.state.attachNameOption !== 'fileNameCustom'}
                          value={this.state.attachName}
                          onChange={(e,v)=>this.setState({attachName: v || '', attachNameSuccess: false})}
                          suffix={'.'+this.state.attachedFiles[0].fileExtension}
                          errorMessage={this.state.attachNameError}
                          
                        />
                        <PrimaryButton
                          className='d-inline'
                          text={i18n.t('app:confirm')}
                          disabled={this.state.attachNameOption !== 'fileNameCustom'}
                          onClick={this._onChangeAttachCustomName}
                        />
                      </form>
                      {this.state.attachNameSuccess && <MessageBar
                          className='mt-1'
                          messageBarType={MessageBarType.success}
                          isMultiline={false}
                        >
                          {i18n.t('app:nameSuccessfullyChanged')}
                        </MessageBar>}
                    </>}
                    <p className="tip text-secondary">
                      <Icon iconName="Info" className="align-bottom mr-1" /> <span className="font-weight-bold">Keyboard shortcut:</span> you can attach by pressing <span className="keyStyles">{os.name === "Mac OS" ? "Command" : "Control"}</span> + <span className="keyStyles">C</span>.
                    </p>
                  </>
                :
                  <>
                    <aside className="d-inline-block lead disabled">
                      <Icon {...getFileTypeIconProps({ extension: currentAttachFile.icon, size: 48, imageFileType: 'svg' }) } className="svg mr-2" />
                      { currentAttachFile.fileName }
                    </aside>
                    <Spinner size={SpinnerSize.xSmall} className="d-inline-block ml-2" />
                  </>
                }
            </div>
          </Dialog>
        : null }

        { itemToEdit && renameItemDialog ?
          <Dialog
              hidden={!renameItemDialog}
              onDismiss={(ev:any) => {
                ev.stopPropagation();
                ev.preventDefault();
                this.setState({renameItemDialog: false});
                this._focusSelElements()
              }}
              dialogContentProps={{
                type: DialogType.normal,
                title:  <div className='d-flex align-items-center'>
                          { itemToEdit.fileType === "file" ?
                          <>
                            <Icon className='mr-2' {...getFileTypeIconProps({ extension: itemToEdit.fileExtension === 'email' ? 'eml' : itemToEdit.fileExtension, size: 32, imageFileType: 'svg' }) } />{i18n.t('app:renameItem') + itemToEdit.fileName}
                          </>
                          :<>
                            <Icon className='mr-2' {...getFileTypeIconProps({ type: FileIconType.folder, size: 32, imageFileType: 'svg' }) } />{i18n.t('app:renameItem') + itemToEdit.fileName}
                          </>
                          }
                        </div>,
                className: "edit-dialog"
              }}
              modalProps={{
                dragOptions: undefined,
                isBlocking: false,
                className: 'edit-name'
              }}
            >
            {itemToEdit.fileType === "file" ?
            <>
              <p className='m-0 pt-2' style={{fontWeight:500}}>{i18n.t('app:filename')}</p>
              <form className="pt-2" name="form" onSubmit={ this._renameItem } >
              <TextField underlined autoFocus placeholder={i18n.t('app:itemName')} name="newItemName" value={ newItemName } onChange={ this.handleItemNameChange } suffix={'.' + itemToEdit.fileExtension} disabled={ renamingItem } errorMessage={ renameWarning } required />
              </form>
            </>
            :
            <>
              <form name="form" onSubmit={ this._renameItem } >
              <TextField underlined autoFocus placeholder={i18n.t('app:itemName')} name="newItemName" value={ newItemName } onChange={ this.handleItemNameChange }  disabled={ renamingItem } errorMessage={ renameWarning } required />
              </form>
            </>
            }

            <p className="tip text-secondary">
              <Icon iconName="Info" className="align-bottom mr-1" /> <span className="font-weight-bold">Keyboard shortcut:</span> you can rename by pressing <span className="keyStyles">F2</span>.
            </p> 

            <DialogFooter>
              { renamingItem ?
                <Spinner size={SpinnerSize.xSmall} className="d-inline-block" />
              : null }
              <DefaultButton onClick={ev => {
                    this.setState({renameItemDialog: false});
                    this._focusSelElements()
                  }
                } text={i18n.t('app:cancel')} />
              <PrimaryButton onClick={this._renameItem} text={i18n.t('app:save')} disabled={ this._containsInvalidChars(this.state.newItemName) }/>      
            </DialogFooter>
          </Dialog>
        : null}

        { lockedDocument && showLockedDocDialog ?
          <Dialog
            hidden={!showLockedDocDialog}
            onDismiss={ev => this.setState({showLockedDocDialog: false})}
            dialogContentProps={{
              type: DialogType.close,
              title: <div className='d-flex align-items-left'>
                  <Icon className='mr-2' {...getFileTypeIconProps({ extension: lockedDocument.fileExtension === 'email' ? 'eml' : lockedDocument.fileExtension, size: 32, imageFileType: 'svg' }) } />{lockedDocument.fileName}
                </div>,
              className: "locked-doc-dialog"
            }}
            modalProps={{
              titleAriaId: "Locked document",
              dragOptions: undefined,
              isBlocking: false,
              className: "locked-doc-dialog"
            }}
          >
            { this.props.userData
              && lockedDocument.lock
              && this.props.userData.user.id === lockedDocument.lock.id
              && lockedDocument.lockInstanceId
              && userService.getInstanceId() !== lockedDocument.lockInstanceId ?
              <>
                <p>{i18n.t('app:lockedInOtherInstance')}</p>
                <p className="mb-4">{i18n.t('app:lockedInOtherInstanceCaution')}</p>
              </>
            :
              lockedDocument.lock ?
                <>
                  <div className="text-secondary">{i18n.t('app:lockedBy')}</div>
                  <div className="username font-weight-bold">
                    { lockedDocument.lock.name }
                  </div>
                  <div className="username mb-4">
                    <a href={ "mailto:" + lockedDocument.lock.email }>{ lockedDocument.lock.email }</a>
                  </div>
                </>
            : null }


            <DialogFooter>
            <DefaultButton onClick={() => this.setState({showLockedDocDialog: false})} text={i18n.t('app:cancel')} className="mr-1" />
              <PrimaryButton onClick={this._forceUnlockDocument.bind(this, lockedDocument.id)} text={i18n.t('app:forceUnlock')} className="mr-1" />
              { this.props.appPlatform === ("electron" || "openfin") ?
                <PrimaryButton onClick={() => {
                    this.setState({showLockedDocDialog: false});
                    this._openReadOnlyDocument(lockedDocument.id, lockedDocument.revisionId, lockedDocument.hash, lockedDocument.fileName, lockedDocument.fileExtension, lockedDocument.lock, lockedDocument.item)
                  }}
                  text={i18n.t('app:openReadOnly')}
                />
              : this._isOfficeDoc(lockedDocument.fileExtension) ?
                <PrimaryButton onClick={() => {
                    this.setState({showLockedDocDialog: false});
                    this._openOfficeOnline(lockedDocument.id, "view", lockedDocument.lock, lockedDocument.wopiLock)
                  }}
                  text={i18n.t('app:openReadOnly')}
                />
              : lockedDocument.fileExtension && lockedDocument.fileExtension.toLowerCase() === "pdf" ?
                <PrimaryButton onClick={() => {
                    this.setState({showLockedDocDialog: false});
                    this._openPdfSynergyReaderOnline(lockedDocument.id, lockedDocument.revisionId)
                  }}
                  text={i18n.t('app:openReadOnly')}
                />
              : null }
            </DialogFooter>
          </Dialog>
        : null }

        { movingItem && movingItemInfo ?
          <Dialog
            hidden={!movingItem}
            dialogContentProps={{
              type: DialogType.normal,
              title: 'Moving ' + movingItemInfo.moveDetails + ' to "' + movingItemInfo.destination + '"'
            }}
            modalProps={{
              isBlocking: true,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "moving-item"
            }}
          >
            <div className="mt-4 text-center w-100">
              <aside>
                {i18n.t('app:pleaseWait')}
              </aside>
              <span className="spinner ml-2"><Spinner size={SpinnerSize.xSmall} /></span>
            </div>
          </Dialog>
        : null }

        {
          imagePreviewDialog ?
            <Modal
              isOpen={imagePreviewDialog}
              onDismiss={(ev:any) => {
                ev.stopPropagation();
                ev.preventDefault();
                this.setState({imagePreviewDialog: false});
                this._focusSelElements()
              }}
            >
              
              { selFile && selFile.previewData && 
              <>
                <IconButton
                  iconProps={{ iconName: 'Cancel', style: {color: 'white'}}}
                  ariaLabel="Close popup modal"
                  onClick={(ev:any) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    this.setState({imagePreviewDialog: false});
                    this._focusSelElements()
                  }}
                  style={{position: 'absolute', right: 0, background: selFile.fileExtension === 'docx' ? '#2b579a' : selFile.fileExtension === 'pptx' ? '#b7472a' : selFile.fileExtension === 'xlsx' ? '#217346' : selFile.fileExtension === 'email' ? '#0078d4' : '#2c2c2c'}}
                />
                <div className="text-center">
                  <div style={{height: '32px', background: selFile.fileExtension === 'docx' ? '#2b579a' : selFile.fileExtension === 'pptx' ? '#b7472a' : selFile.fileExtension === 'xlsx' ? '#217346' : '#2c2c2c', display:'flex', alignItems:'center'}}>
                    <p className="mb-0 pb-0 mx-2" style={{color: 'white', fontWeight: 600, maxWidth:'80%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow:'hidden', textAlign:'left', marginRight: '30px'}}>{selFile.name + "." + selFile.fileExtension}</p>
                  </div>
                  <div className='py-5'>
                    <span>{i18n.t('app:cannotBePreviewed')}</span> 
                  </div>
                </div>
              </>}
          </Modal>
        : null }

        {
        <Panel isOpen={this.state.showMobileDetails} onDismiss={() => this.setState({showMobileDetails: false}) }  closeButtonAriaLabel="Close" className="import-panel mobile-details">
          { this._selection.count === 1 && selFile &&
            <div className="details-panel-mobile">
              <div className="tab-title text-center my-2 pb-2">
                <p className="lead font-weight-bold m-0">{i18n.t('app:details')}</p>
              </div>
              <div className="d-flex my-3">
                <div className="mr-3">
                  { selFile.fileType === "repo" ?
                    <Icon iconName="OfflineStorageSolid" className="file-type repoIcon" />
                  : selFile.fileType === "trash" ?
                    <Icon   iconName="Trash" className="file-type trash" />
                  : selFile.fileType === 'dir' ?
                    <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 48, imageFileType: 'svg' }) } aria-hidden="true" className="file-type"/>
                  : selFile.fileType === 'file' ?
                    <Icon {...getFileTypeIconProps({ extension: selFile.icon, size: 48, imageFileType: 'svg' }) } aria-hidden="true" className="file-type"/>
                  : null }
                </div>
                <div className="file-name">
                  <div className="lead d-inline-block font-weight-bold">
                    { selFile.fileName }                    
                  </div>
                </div>
              </div>

              <ul className="p-0 my-3">
                <li className="mb-2">
                  <div className="text-secondary mr-2">ID:</div>
                  <div className="username font-weight-bold">{ selFile.id }</div>
                </li>

                { selFile.lock && !selFile.wopiLock ?
                  <li className="mb-2">
                    <div className="text-secondary"><Icon iconName="Lock" className="mr-1" /> {i18n.t('app:lockedBy')}</div>
                    <div className="username font-weight-bold">
                      { selFile.lock.name }
                    </div>
                    <div className="username">
                      <a href={ "mailto:" + selFile.lock.email }>{ selFile.lock.email }</a>
                    </div>
                  </li>
                : selFile.wopiLock ?
                  <li className="mb-2">
                    <div className="text-secondary"><Icon iconName="OfficeLogo" className="mr-1" /> {i18n.t('app:coAuthoring')}</div>
                  </li>
                : null }

                { selFile.path ?
                  <li className="mb-2">
                    <div className="text-secondary">{i18n.t('app:path')}:</div>
                    <div className="username font-weight-bold">{ selFile.path }</div>
                  </li>
                : null }
                { selFile.fileType === 'file' ?
                  <li className="mb-2">
                    <div className="text-secondary mr-2">{i18n.t('app:size')}:</div>
                    <div className="username font-weight-bold">{ selFile.fileSize }</div>
                </li>
                : null }
                { selFile.createdBy ?
                  <li className="mb-2">
                    <div className="text-secondary">{i18n.t('app:createdBy')}</div>
                    <div className="username font-weight-bold">{ selFile.createdBy.name }</div>
                  </li>
                : null }
                  <li className="mb-2">
                  <div className="text-secondary">{i18n.t('app:dateCreated')}:</div>
                  <div className="font-weight-bold">{ Moment(selFile.dateCreated).format(i18n.t('app:dateFormat')) }</div>
                </li>
                { typeof selFile.modifiedBy === "object" ?
                  <li className="mb-2">
                    <div className="text-secondary">{i18n.t('app:modifiedBy')}:</div>
                    <div className="username font-weight-bold">{ selFile.modifiedBy.name }</div>
                  </li>
                : null }
                <li className="mb-2">
                  <div className="text-secondary">{i18n.t('app:dateModified')}</div>
                  <div className="font-weight-bold">{ Moment(selFile.dateModified).format(i18n.t('app:dateFormat')) }</div>
                </li>
              </ul>
            </div>
          }
        </Panel>
        }
      </div>
    );
  }

  private _onWindowResize = () => {
    if (window.innerWidth <= 980 && !isElectron() && detectMob()) {
      this.setState({mobileView: true})
    }
    else {
      this.setState({mobileView: false})
    }
  }

  private _onColumnResize = (column?: IColumn, newWidth?: number, columnIndex?: number) : void => {
    if(column) {
      var columnWidth = {
        key: column.key,
        width: newWidth
      }
      var dashboardColumnsWidth = JSON.parse(localStorage.getItem("dashboardColumnsWidth") || "[]") || []
      var columnSetting = dashboardColumnsWidth.filter(item => {
        return item.key === column.key
      })
      columnSetting = columnSetting[0]
      if(columnSetting) columnSetting.width = newWidth
      else dashboardColumnsWidth.push(columnWidth)
      localStorage.setItem("dashboardColumnsWidth", JSON.stringify(dashboardColumnsWidth))
    }
  };

  private _getItemCountForPage = (itemIndex: any, surfaceRect: any): number => {
    if (itemIndex === 0) {
      this._columnCount = Math.ceil(surfaceRect.width / MAX_ROW_HEIGHT);
      this._columnWidth = Math.floor(surfaceRect.width / this._columnCount);
      this._rowHeight = this._columnWidth;
    }

    return this._columnCount * ROWS_PER_PAGE;
  }

  private _getPageHeight = (itemIndex: any, surfaceRect: any): number => {
    return this._rowHeight * ROWS_PER_PAGE;
  }

  private getStoredData = (name: string) => {
    if(this.props.appPlatform=== "electron") {
        return localStorage.getItem(name)
    } else {
        var cookie = Cookies.get(name)
        return cookie
    }
  }

  private _onRenderCell = (item: any, index: number | undefined): JSX.Element => {
    let isSelected = () => {
      if (this._selection && index !== undefined) {
        return this._selection.isKeySelected(item.id);
      }
      return false
    };

    const synergyApi = process.env.REACT_APP_SYNERGY_API_URL
    var serverDomain = userService.getServerDomain()
    var thumbnailApi = synergyApi + serverDomain + process.env.REACT_APP_THUMBNAIL_API_URL;

    let token = this.getStoredData("token");
    var thumbnailUrl: any = null

    if(item.thumbnailCreated) {
      if(item.fileExtension) {
        thumbnailUrl = thumbnailApi + "/thumbnail/" + item.id + "?access_token=" + token
      }
    }

    return (      
      <div
        className={'listGridTitle' + (isSelected() ? " isSelected" : "") + (item.hasPermissions === false ? " noListFilePermissions " + item.id : "") + (item.clipboard ? " clipboard": "")}
        data-is-focusable={ true }
        style={ {
          height: '120px',
          width: (100 / this._columnCount) + '%',
          minWidth: '120px'
        } }
        data-selection-index={index}
        title={ this.props.fileExplorerType === "recentFiles" && item.hasPermissions === false ? "You don't have access to this file anymore" : item.fileName }       
        onDragEnter={ () => this._onListDragEnter(item) }
        onDragLeave={ () => this._onListDragLeave() }
        onDrop={ (ev) => this._onListDrop(item, ev) }
        onDragStart={ () => this._onListDragStart(item) }
        onDragEnd={ () => this._onListDragEnd() }
        draggable
      >
        <div className='listGridSizer'>
          <div className='listGridPadder'>
            <div className='item-wrapper'>
              <div className="selection-check" data-selection-toggle>
                <Check checked={isSelected()} />
              </div>
              <div className='listGridImageWrap'>
                {thumbnailUrl && item.fileExtension ?
                  <img style={{boxShadow: '0px 2px 6px 1px lightgrey'}} src={ thumbnailUrl } className='listGridImage' alt={item.fileName  + " thumbnail"} />
                :
                  item.kind === "dir" ?
                    <Icon className='listGridImage' style={{writingMode:'inherit'}} {...getFileTypeIconProps({ type: FileIconType.folder, size: 64, imageFileType: 'svg' }) } />
                  :
                    <Icon className='listGridImage' style={{writingMode:'inherit'}} {...getFileTypeIconProps({ extension: item.fileExtension, size: 64, imageFileType: 'svg' }) } />
                }
              </div>
            </div>
          </div>
          <div className='listGridLabel'>
            <div className="itemName">
              <Text block={true} nowrap={true}>{ item.fileName }</Text>
            </div>
              <div className="dateModified text-secondary">{Moment(item.dateModified).format('Y-MM-DD HH:mm')}</div>
          </div>
        </div>
      </div>
    );
  }

  private _newSocketMessage(info) {
    var message = info && info.message;
    var data = info && info.data;
    console.log("Message from socket in Node.js server:", message, data)
    if(message) {
      if(message === "updateUploadsQueue") {
        this.props.callbackFunction({ uploadsQueue: data })
      } else if(message === "updateOfflineFiles") {
        if(this.props.fileExplorerType === "offlineFiles") {
          this.props.getCurrentContent()
        }
       } else if(message === "updateCurrentContent") {
        if(this.props.searchType === "fullSearch") {
          this.props.refreshCurrentContent()
        } else {
          this.props.getCurrentContent()
        }
      } else if(message === "updatePendingDrafts") {
        if(this.props.storedType === "pendingDrafts") {
          this.props.getCurrentContent()
        }
      } else if(message === "offlineFileSaved") {
        //if(this.props.getOfflineFiles) this.props.getOfflineFiles()
      } else if(message === "offlineFileDeleted") {
        //if(this.props.getOfflineFiles) this.props.getOfflineFiles()
      } else if(message === "workingCopyChange") {
        let id = data.id;
        this.props.updateItem(id, "savingDraft", true)
      } else if (message === "unlockUnlinkedFile") {
        let id = data.id
        this.props.updateItem(id, "lockingDocument", false)
        this.props.updateItem(id, "lock", null)
        this.props.updateItem(id, "wopiLock", null)
      } else if (message === "getFileLock") {
        let id = data.id

        var viewingItem = this.props.items.filter(item => {
          return item.id === id
        })

        if(viewingItem.length > 0) {
          userService.getDocument(id).then((response: any) => {
            var item = response.data
            this.props.updateItem(id, "lockingDocument", false)
            this.props.updateItem(id, "lock", item.lock)
            this.props.updateItem(id, "wopiLock", item.wopiLock)
          }).catch(error => {
            console.log(error)
            this.props.updateItem(id, "lockingDocument", false)
          })
        }

      }
    }
  }

  private _newPusherMessage(message) {
    var id = message[0];
    var parentId = message[1];
    var author = message[2];
    var action = message[3];

    var instanceId = Cookies.get('instanceId') || localStorage.getItem("instanceId")

    if((message && this.props.fileExplorerType !== "offlineFiles")
      || (message && this.props.fileExplorerType === "offlineFiles" && (
        action === "uploaddraft"
          || action === "uploaddocumentrevision"
          || action === "deleterevision"
          || action === "replacedocumentrevision"
          || action === "renamefile"
          || action === "lock"
          || action === "unlock"
          || action === "wopiLock"
          || action === "lockingDocument"))) {

      var lastSelFile:any = this._selection.getSelection()[this._selection.getSelection().length - 1] || [];

      var viewingItem = this.props.items.filter(item => {
        return item && item.id === id
      })

      if(action === "pusherReconnect") this.props.getCurrentContent()

      if( id === "ERROR") {
        if (action === "createPublicLink" && author === this.props.userData.user.id) {          
          this._isMounted && this.setState({
            creatingPublicLink: false,
            showPublicLinkDialog: false
          }, () => {
            this.stopPublicLinkTimeout()
          })

          let alertMessageType = MessageBarType.error
          let alertMessageText = "ERROR: could not create public link. There was an error creating your public link. Please try again."

          this._isMounted && this.setState({
            alertMessage: {
              type: alertMessageType,
              text: alertMessageText
            }
          })

          setTimeout(() => {
            this._isMounted && this.setState({ alertMessage: null })
          }, 4000)
        } else if (action === "copycontent" && author === this.props.userData.user.id) {
          this._isMounted && this.setState({
            copyPastingItems: false,
            showPasteDialog: false
          }, () => {
            this.stopCopyPasteTimeout()
          })

          let alertMessageType = MessageBarType.error
          let alertMessageText = "ERROR: could not copy your items. Please try again."

          this._isMounted && this.setState({
            alertMessage: {
              type: alertMessageType,
              text: alertMessageText
            }
          })

          setTimeout(() => {
            this._isMounted && this.setState({ alertMessage: null })
          }, 4000)
        } else if (action === "zipFile" && author === this.props.userData.user.id) {
          this._isMounted && this.setState({
            zippingItems: false,
            showZipDialog: false
          }, () => {
            this.stopZipTimeout()
          })

          let alertMessageType = MessageBarType.error
          let alertMessageText = "ERROR: could not zip your items. Please try again."

          this._isMounted && this.setState({
            alertMessage: {
              type: alertMessageType,
              text: alertMessageText
            }
          })

          setTimeout(() => {
            this._isMounted && this.setState({ alertMessage: null })
          }, 4000)
        } else if ((action === "unzipFile" && author === this.props.userData.user.id)
          || (action === "unzipError" && author === this.props.userData.user.id)) {
          this._isMounted && this.setState({
            unZippingFile: false,
            showUnzipDialog: false
          }, () => {
            this.stopUnzipTimeout()
          })

          let alertMessageType = MessageBarType.error
          let alertMessageText = "ERROR: could not unzip your file. Please try again."

          this._isMounted && this.setState({
            alertMessage: {
              type: alertMessageType,
              text: alertMessageText
            }
          })

          setTimeout(() => {
            this._isMounted && this.setState({ alertMessage: null })
          }, 4000)
        } else if (action === "duplicatefolder" && author === this.props.userData.user.id) {
          this._isMounted && this.setState({duplicatingFolder: false}, () => {
            this.stopDuplicateTimeout()
          })

          let title = "ERROR: could not duplicate your folder"
          let body = "There was an error duplicating your folder. Please try again."
          let messageData = {
            action: "duplicateFolderError"
          };
          this.notifyMe(title, body, messageData)
        }
      } else if (action === "lock" && this.props.userData) {
        let userId = this.props.userData.user.id
        let groups = this.props.userData.groups;

        userService.checkItemPermissions(id, userId, groups).then(response => {
          var permissionsGranted = response.data;

          if(permissionsGranted) {
            userService.getDocument(id).then((response: any) => {
              var item = response.data
              this.props.updateItem(id, "lockingDocument", false)
              this.props.updateItem(id, "lock", item.lock)
              this.props.updateItem(id, "lockInstanceId", item.lockInstanceId)
              this.props.updateItem(id, "wopiLock", item.wopiLock)
            }).catch(error => {
              console.log(error)
              this.props.updateItem(id, "lockingDocument", false)
            })
          }
        }).catch(error => {
          console.log(error)
          this.props.updateItem(id, "lockingDocument", false)
        })
      } else if (action === "unlock") {
        this.props.updateItem(id, "lockingDocument", false)
          this.props.updateItem(id, "lock", null)
          this.props.updateItem(id, "wopiLock", null)
        } else if (action === "createPublicLink" && author === this.props.userData.user.id) {
        // Copy public link to clipboard
        //var url = userService.getPublicLinkUrl(id)
        //this.copyLink(url)

        this._isMounted && this.setState({creatingPublicLink: false, alertMessage: null}, () => {
          this.stopPublicLinkTimeout()
        })

        if(this.state.enablePublicLinkNotification) {
          let title = i18n.t('app:publicLinkCreated')
          let body = i18n.t('app:copied')
          let messageData = {
            action: "publicLinkCreated",
            token: id
          };
          this.notifyMe(title, body, messageData)
          this.setState({enableUnzipNotificacion: false})
        }
      } else if (action === "refreshtags") {
        if (window.location.href.indexOf("/tag") !== -1) this.props.sidebarRef && this.props.sidebarRef._getTags();

      } else if(id !== instanceId) {
        if(viewingItem.length > 0
          || parentId === this.props.folderId
          || (this.props.folderId && this.props.folderId.indexOf("-trash") !== -1)
          || action === "foldermove"
          ||  ((action === "adduser"
          || action === "removeuser"
          || action === "addgroup"
          || action === "removegroup"
          || action === "addexternaluser"
          || action === "removeexternaluser") && (id === this.props.folderId))) {

          if (this.props.fileExplorerType !== "bookmarks" && author === this.props.userData.user.id && (action === "addbookmark" || action === "deletebookmark")) {
            // Do not refresh current content
          } else if (this.props.fileExplorerType === "bookmarks" && author !== this.props.userData.user.id && (action === "addbookmark" || action === "deletebookmark")) {
            // Do not refresh current content
          } else {
            if(this.props.searchType === "fullSearch") {
              this.props.refreshCurrentContent()
            } else {
              this.props.getCurrentContent()
            }
          }
        } else {
          if (action === "movefiles") {
            var itemId = id.split("'")[0]
            let userId = this.props.userData.user.id
            let groups = this.props.userData.groups;

            userService.checkItemPermissions(itemId, userId, groups).then(response => {
              var permissionsGranted = response.data;

              if(permissionsGranted) {
                userService.getDocument(itemId).then((response: any) => {
                  var item = response.data
                  if(item.parent_id === this.props.folderId) this.props.getCurrentContent()
                }).catch(error => {
                  console.log(error)
                })
              }
            }).catch(error => {
              console.log(error)
              this.props.updateItem(id, "lockingDocument", false)
            })
          }
        }

        if (action === "copycontent" && author === this.props.userData.user.id) {
          this._isMounted && this.setState({showPasteDialog: false, copyPastingItems: false, alertMessage: null}, () => {
            this.stopCopyPasteTimeout()
          })
  
          if(this.state.enablePasteNotification) {
            let title = "Copy completed"
            let body = "Your items have been successfully copied."
            let messageData = {
              action: "copyPasteSuccess",
              parentId: parentId
            };
            this.notifyMe(title, body, messageData)
            this.setState({enablePasteNotification: false})
          }
        }

        if (action === "zipFile" && author === this.props.userData.user.id) {
          this._isMounted && this.setState({showZipDialog: false, zippingItems: false, alertMessage: null}, () => {
            this.stopZipTimeout()
          })
  
          if(this.state.enableUnzipNotificacion) {
            let title = "Zip completed"
            let body = "Your items have been successfully zipped."
            let messageData = {
              action: "zipSuccess",
              parentId: parentId
            };
            this.notifyMe(title, body, messageData)
            this.setState({enableUnzipNotificacion: false})
          }
        }

        if (action === "unzip" && author === this.props.userData.user.id) {
          this._isMounted && this.setState({showUnzipDialog: false, unZippingFile: false, alertMessage: null}, () => {
            this.stopUnzipTimeout()
          })
  
          if(this.state.enableUnzipNotificacion) {
            let title = "Unzip completed"
            let body = "Your file has been successfully unzipped."
            let messageData = {
              action: "unzipSuccess",
              parentId: parentId
            };
            this.notifyMe(title, body, messageData)
            this.setState({enableUnzipNotificacion: false})
          }
        }

        if (action === "duplicatefolder" && author === this.props.userData.user.id) {
          this._isMounted && this.setState({duplicatingFolder: false, alertMessage: null}, () => {
            this.stopDuplicateTimeout()
          })
  
          let title = "Duplicate folder completed"
          let body = "Your folder has been successfully duplicated."
          let messageData = {
            action: "duplicateFolderSuccess"
          };
          this.notifyMe(title, body, messageData)
        }
      }

      if (action === "renamefile" && author === this.props.userData.user.email) {
        setTimeout(()=>{this._focusSelElements()},600);
      }
      if (action === "updatefolder" && author === this.props.userData.user.id) {
        setTimeout(()=>{this._focusSelElements()},600);
      }
    }
  }

  private _focusSelElements() {
    var elements: any = document.getElementsByClassName('ms-DetailsRow is-selected')
    if (elements) {
      for (let i = 0; i < elements.length; i++) {
        let el = elements[i]
        el.focus()
      }
    }
  }

  private getPathInfo(id) {
    let repo = this.props.userData.repository
    let foldersData = this.props.foldersList

    if(repo && foldersData) {
 
      var folderId = id;

      var currentFolder = foldersData.filter(folder => {
        return folder.id === folderId
      })
      currentFolder = currentFolder[0]


      var breadcrumb: any = [];

      if(currentFolder && currentFolder.path_id) {
        
        breadcrumb.push({
            text: currentFolder.name,
            key: currentFolder.id
          })

          var getParentData = (parentId) => {
            var parentData;
  
            parentData = foldersData.filter(data => {
              return parentId === data.id
            })
  
            if(!parentData.length) {
              parentData = parentId === repo.id
            }
  
            if(parentData) {
              breadcrumb.unshift({
                text: parentData.name,
                key: parentData.id
              })
            }
  
            if(parentData && parentData.parent_id)
              getParentData(parentData.parent_id)
          }

        getParentData(currentFolder.parent_id)
      } else {
        // Check if root folder
        let folder = repo && repo.id === folderId ? repo : null

        if (folder)
          breadcrumb.unshift({
              text: folder.name,
              key: folder.id
            })
      }
    }

    return breadcrumb
  }

  private _handleKeyDown = (event) => {
    //console.log("HANDLE KEY DOWN", event)
    
    if (event.repeat) { return }

    var parser = new UAParser.UAParser();
    var os = parser.getResult().os;

    // Control or Command + r
    if ((os.name === "Windows" && event.ctrlKey && event.keyCode === 82)
      || (os.name === "Mac OS" && event.metaKey && event.keyCode === 82)) {
      if(this.props.appPlatform === ("electron" || "openfin")) {
        event.preventDefault();
        this.props.getCurrentContent()
      }

    // Alt or Option + v
    } else if ((os.name === "Windows" && event.altKey && event.keyCode === 86)
    || (os.name === "Mac OS" && event.altKey && event.keyCode === 86)) {
      if(this.props.fileExplorerType === "fileExplorer" && this.props.folderId) {
        
        this._clipboardPaste()

      }

    // Control or Alt + N
    } else if ((os.name === "Windows" && event.altKey && event.keyCode === 78)
    || (os.name === "Mac OS" && event.altKey && event.keyCode === 78)) {
      if(this.props.fileExplorerType === "fileExplorer" && this.props.folderId) {
        
        if(ActionsPermissions("createFolder", this.state.parentRole)) {
          event.preventDefault()
          event.stopPropagation()
          this.props.headerRef._showNewFolderDialog(this.props.folderId)
        }

      }
    } else {
      switch( event.keyCode ) {
        case 27: // Esc
          this._clipboardClear()
          break;
        default:
          break;
      }
    }
  }

  private _handleListKeyDown = (event) => {
    if (event.repeat) { return }
    
    var selFiles = this._selection.getSelection();

    var parser = new UAParser.UAParser();
    var os = parser.getResult().os;

    // Control or command + Shift + C
    if ((os.name === "Windows" && event.ctrlKey && event.keyCode === 67)
      || (os.name === "Mac OS" && event.metaKey && event.keyCode === 67)) {

      event.preventDefault();

      let offlineMode = this.props.fileExplorerType === "offlineFiles"

      let selectionCount: number = this._selection.getSelectedCount();

      var selection = this._selection.getSelection();
      var folders = selection.filter((item: any) => {
        return item.fileType === "dir"
      })
      var folderSelected = folders.length > 0;

      if(!folderSelected && selectionCount > 0
        && !offlineMode
        && !this.props.maintenance
        && ActionsPermissions("attachFile", this.props.headerRef.state.folderRole)
        && this.props.appPlatform === ("electron" || "openfin")) {
        if(this.props.headerRef.state.folderRole > 1) {
          if(selection.length === 1) this._attachFile()
        } else {
          this._attachFile()
        }
      }

    // Alt or Option + C
    } else if ((os.name === "Windows" && event.altKey && event.keyCode === 67)
      || (os.name === "Mac OS" && event.altKey && event.keyCode === 67)) {

      if(this.props.fileExplorerType !== "activity"
        && this.props.fileExplorerType !== "openFiles"
        && this.props.fileExplorerType !== "offlineFiles"
        && this.props.searchType !== "tag") {
          console.log(this.props.searchType)
        this._clipboardCopy()
      }
    
    // Alt or Option + X
    } else if ((os.name === "Windows" && event.altKey && event.keyCode === 88)
      || (os.name === "Mac OS" && event.altKey && event.keyCode === 88)) {
      
      if(this.props.fileExplorerType !== "activity"
        && this.props.fileExplorerType !== "openFiles"
        && this.props.fileExplorerType !== "offlineFiles"
        && this.props.searchType !== "tag") {
        this._clipboardCut()
      }

    // Alt or Option + P
    } else if ((os.name === "Windows" && event.altKey && event.keyCode === 80)
    || (os.name === "Mac OS" && event.altKey && event.keyCode === 80)) {
    
    if(this.props.fileExplorerType !== "activity"
      && this.props.fileExplorerType !== "openFiles"
      && this.props.fileExplorerType !== "offlineFiles"
      && this.props.searchType !== "tag") {
      this._copyPrivateShortcut(this._selection.getSelection()[0], "askToOpen")
    }

      // .
    } else if (event.keyCode === 190 && this.state.searchWord.length === 0) {
      let selectionCount: number = this._selection.getSelectedCount();
      let selFile:any = selFiles[0]
      let offlineMode = this.props.fileExplorerType === "offlineFiles"

      if(selFile && previewExtensions.includes(selFile.fileExtension) && selectionCount === 1 && !offlineMode && !this.props.maintenance) this._previewFile(selFile)
      else if (selFile && selFile.fileType === "file" && selectionCount === 1 && !offlineMode && !this.props.maintenance) {
        selFile.previewData = "nopreview";
        this._isMounted && this.setState({
          previewData: "nopreview",
          imagePreviewDialog: true
        });
      }

    } else {
      switch( event.keyCode ) {
        case 27: // Esc
          this._clipboardClear()
          break;
        case 46: // Supr
          if(event.target.className.indexOf("ms-DetailsRow") !== -1
            && selFiles.length > 0) {
              let selFile:any = selFiles[0]
              if (selFile.type === "tag") this.props.headerRef._deleteTags()
              else this.props.headerRef._deleteItems(selFiles);
          }
          break;
        case 113: // F2
          if(selFiles.length === 1) {
            var selFile:any = selFiles[0];

            if (selFile.type !== "tag") {
              if(!selFile.lock) {
                this.setState({
                  renameItemDialog: true,
                  itemToEdit: selFile,
                  newItemName: selFile.name,
                  newItemTags: selFile.tags ? selFile.tags.join('; ') : null,
                  renameWarning: ""
                });
              } else {
                alert(i18n.t('app:cantRenameLockedFiles'))
              }
            }else {
              this.props.headerRef._showEditSingleTagDialog()
            }
          }
          break;
        case 38: // Up Refocus index 0 after scroll
        var _this = this
        var stop = setInterval(function(){
          if (_this._selection.getSelectedIndices()[0] === 0) {
            setTimeout(() => {
              if(_this.props.items[0]) {
                _this._selection.setKeySelected(_this.props.items[0].key, true, true)
                var selIndex = _this._selection.getSelectedIndices()[0]
                _this._root.current && _this._root.current.focusIndex(selIndex);
                _this._settingSelItem = false
              }
            }, 1)
          }
        }, 60);
        window.addEventListener("keyup", function stopUp(){
          //stop the loop
          clearInterval(stop);
          //and remove the keyup listener
          window.removeEventListener("keyup", stopUp);
        })
        break;
        default: 
          if(!event.metaKey
            && !event.ctrlKey
            && !event.altKey
            && !event.shiftKey
            && event.returnValue ) {
              var key = event.key || "";
              this._handleListFocusOnKeyDown(key)
            }
          break;
      }
    }
  }

  private _clipboardCopy() {
    let content = this._selection.getSelection() 
    let copyData = JSON.stringify({
      synergyAction: "copy",
      content: content
    })
    this._isMounted && this.props.setClipboard(copyData)

    let contentIds = content.map((item: any) => { return item.id})

    let folders:any[] = [];
    let documents:any[] = [];
    this.props.updateCopiedItems(this.props.items, contentIds, "clipboard")
    for (let i=0; i<content.length; i++) {
      let item:any = content[i]
      if (item.kind === "dir") folders.push(item)
      else if (item.kind === "file") documents.push(item)
    }

    let alertMessageType = MessageBarType.success
    let alertMessageText = `<span className="mr-1">${i18n.t('app:clipboard')}:</span>
      <span>${folders.length === 1 ? folders.length + " "+i18n.t('app:lowerFolder') : folders.length > 1 ? folders.length + " "+i18n.t('app:lowerFolders') : ""}</span>
      
      <span>${folders.length > 0 && documents.length > 0 ? " " + i18n.t('app:clipboardAnd') + " " : ""}</span>

      <span>${documents.length === 1 ? documents.length + " "+i18n.t('app:lowerFile') : documents.length > 1 ? documents.length + " "+i18n.t('app:lowerFiles') : ""}</span>
      ${i18n.t('app:clipboardCopy')}. ${i18n.t('app:clipboardCopyExplanation')}`

      this._isMounted && this.setState({
      alertMessage: {
        type: alertMessageType,
        text: alertMessageText
      }
    })

    setTimeout(() => {
      this._isMounted && this.setState({ alertMessage: null })
    }, 4000)
  
  }

  private _clipboardCut() {
    let content = this._selection.getSelection() 
    let cutData = JSON.stringify({
      synergyAction: "cut",
      content: content
    })
    //clipboardy.write(cutData)
    this._isMounted && this.props.setClipboard(cutData)

    let contentIds = content.map((item: any) => { return item.id})
    for (let i=0; i<this.props.items.length; i++) {
      let item = this.props.items[i]
      if(item.clipboard && !contentIds.includes(item.id)) this.props.updateItem(this.props.items[i].id, "clipboard", false)
    }
    for (let i=0; i<content.length; i++) {
      this.props.updateItem(content[i].key, "clipboard", true)
    }

    let folders = content.filter((item: any) => {return item.kind === "dir"})
    let documents = content.filter((item: any) => {return item.kind === "file"})
    
    let alertMessageType = MessageBarType.success
    let alertMessageText = `<span className="mr-1">${i18n.t('app:clipboard')}:</span>
      <span>${folders.length === 1 ? folders.length + " "+i18n.t('app:lowerFolder') : folders.length > 1 ? folders.length + " "+i18n.t('app:lowerFolders') : ""}</span>
      
      <span>${folders.length > 0 && documents.length > 0 ? " " + i18n.t('app:clipboardAnd') + " " : ""}</span>

      <span>${documents.length === 1 ? documents.length + " "+i18n.t('app:lowerFile') : documents.length > 1 ? documents.length + " "+i18n.t('app:lowerFiles') : ""}</span>
      ${i18n.t('app:clipboardCut')}. ${i18n.t('app:clipboardCutExplanation')}`

    this._isMounted && this.setState({
      alertMessage: {
        type: alertMessageType,
        text: alertMessageText
      }
    })

    setTimeout(() => {
      this._isMounted && this.setState({ alertMessage: null })
    }, 4000)
  }

  private _clipboardPaste() {
    try {
      var parsedData: any = null
      if (this.props.clipboard ){
        if (this.props.appPlatform === ("electron" || "openfin")) {
          const { ipcRenderer } = window.require("electron");
          (async () => {
            var clipboardItem = await ipcRenderer.invoke('getClipboardItem')
            if(clipboardItem) {
              parsedData = JSON.parse(clipboardItem)
              if(parsedData) this._pasteItems(parsedData)
            } else {
              parsedData = JSON.parse(this.props.clipboard)
              if(parsedData) this._pasteItems(parsedData)
            }
          })();
        } else {
          parsedData = JSON.parse(this.props.clipboard)
          if(parsedData) this._pasteItems(parsedData)
        }
      }
    } catch (error) {
      console.log("No valid items to paste present")
    }
  }

  private _pasteItems(parsedData) {
    try {
      
      if (parsedData && parsedData.synergyAction && parsedData.synergyAction === "copy"
      && parsedData.content && parsedData.content.length > 0) {

        let folders = parsedData.content.filter((item: any) => {return item.kind === "dir"})
        folders = folders.map(folder => {return folder.id})
        let documents = parsedData.content.filter((item: any) => {return item.kind === "file"})
        documents = documents.map(document => {return document.id})

        this._copyPasteItems(folders, documents, this.props.folderId)
        
        if (this.props.appPlatform === ("electron" || "openfin")) {
          const { ipcRenderer } = window.require("electron");
          ipcRenderer.send("clearClipboardItem")
        }
        for (let i=0; i<parsedData.content.length; i++) {
          this.props.updateItem(parsedData.content[i].key, "clipboard", false)
        }

      } else if (parsedData && parsedData.synergyAction && parsedData.synergyAction === "cut"
      && parsedData.content && parsedData.content.length > 0) {
        var destinationFolder = this.props.foldersList.filter(folder => {
          return folder.id === this.props.folderId
        })[0]

        if(!destinationFolder) destinationFolder = {id: this.props.folderId}

        this._moveSelectedItems(parsedData.content, destinationFolder)

        this._isMounted && this.props.setClipboard("")
        if (this.props.appPlatform === ("electron" || "openfin")) {
          const { ipcRenderer } = window.require("electron");
          ipcRenderer.send("clearClipboardItem")
        }
        for (let i=0; i<parsedData.content.length; i++) {
          this.props.updateItem(parsedData.content[i].key, "clipboard", false)
        }

      }
    } catch (error) {
        console.log(error)
        // var alertMessageType = MessageBarType.error
        // var alertMessageText = i18n.t('app:clipboard') + ": " + i18n.t('app:clipboardPasteError')

        // this._isMounted && this.setState({
        //   alertMessage: {
        //     type: alertMessageType,
        //     text: alertMessageText
        //   }
        // })

        // this._isMounted && setTimeout(() => {
        //   this.setState({ alertMessage: null })
        // }, 4000)
    }
  }

  private _clipboardClear() {
    try {
      var parsedData = JSON.parse(this.props.clipboard)
      if (parsedData && parsedData.synergyAction
        && (parsedData.synergyAction === "copy"
          || parsedData.synergyAction === "cut")
        && parsedData.content && parsedData.content.length > 0) {

          this._isMounted && this.props.setClipboard("")
          if (this.props.appPlatform === ("electron" || "openfin")) {
            const { ipcRenderer } = window.require("electron");
            ipcRenderer.send("clearClipboardItem")
          }

          var alertMessageType = MessageBarType.warning
          var alertMessageText = i18n.t('app:clipboard') + ": " + i18n.t('app:clipboardCleared')

          this.props.updateCopiedItems(this.props.items, [], "clipboard", false)

          this._isMounted && this.setState({
            alertMessage: {
              type: alertMessageType,
              text: alertMessageText
            }
          })

          setTimeout(() => {
            this._isMounted && this.setState({ alertMessage: null })
          }, 4000)
        }
    } catch (error) {
        console.log("Clipboard has no data to be cleared.")
    }
  }

  private _setFocusFirstItem() {
    if(this._selection.count === 0)
    this._root.current && this._root.current.focusIndex(0);
  }

  private _setSelFirstItem() {
    if(this.props.items[0]) {
      this._settingSelItem = true;
      this._selection.setAllSelected(false)
      setTimeout(() => {
        if(this.props.items[0]) {
          this._selection.setKeySelected(this.props.items[0].key, true, true)
          var selIndex = this._selection.getSelectedIndices()[0]
          this._root.current && this._root.current.focusIndex(selIndex);
          this._settingSelItem = false
        }
      }, 200)
    }
  }

  private _setSelByIndexItem(index) {
    if(this.props.items[index]) {
      var currentKey = this.props.items[index].key;
      this._settingSelItem = true;
      setTimeout(() => {
        this._selection.setKeySelected(currentKey, true, true)
        var selIndex = this._selection.getSelectedIndices()[0]
        this._root.current && this._root.current.focusIndex(selIndex);
        this._settingSelItem = false
      }, 200)
    }
  }
  
  private _settingSelItem: boolean = false;

  private focusItem(selItem) {
    if(selItem !== null) {
      this._settingSelItem = true;
      this._selection.setAllSelected(false)
      setTimeout(() => {
        this._selection.setKeySelected(selItem, true, true)
        var selIndex = this._selection.getSelectedIndices()[0]
        this._root.current && this._root.current.focusIndex(selIndex);
        this._settingSelItem = false
      }, 200)
    }
  }

  private focusItems(selItems) {
    if(selItems !== null) {
      for (let i=0; i<selItems.length; i++) {
        this._settingSelItem = true;
        this._selection.setAllSelected(false)
        setTimeout(() => {
          this._selection.setKeySelected(selItems[i], true, true)
          var selIndex = this._selection.getSelectedIndices()[0]
          this._root.current && this._root.current.focusIndex(selIndex);
        }, 200)
      }
      this._settingSelItem = false
    }
  }
  
  private _setSelItem() {
    var selItem:any = null;
    var action: any = null;

    if(this.props.location) {
      selItem = new URLSearchParams(this.props.location.search).get("selItem")
      action = new URLSearchParams(this.props.location.search).get("action")
    }

    var selCount = this._selection.getSelectedCount()
    var currentSelItem: any = this._selection.getSelection()[0]

    if(selItem !== null && this.props.userData) {

      if(!this._settingSelItem && (selCount !== 1 || (selCount === 1 && (currentSelItem && currentSelItem.id) !== selItem))) {
        this._settingSelItem = true
        this._selection.setAllSelected(false)

        setTimeout(() => {
          this._selection.setKeySelected(selItem, true, true)
          var selIndex = this._selection.getSelectedIndices()[0]
          if(selIndex >= 0) {
            this._root.current && this._root.current.focusIndex(selIndex);
            setTimeout(() => {
              var selIndex = this._selection.getSelectedIndices()[this._selection.getSelectedIndices().length-1]
              this._selection.setIndexSelected(selIndex, true, true);
              this._root.current && this._root.current.focusIndex(selIndex);
              if(selIndex >= 0) {
                setTimeout(() => {
                  this._settingSelItem = false
                  history.push({
                    search: ''
                  })

                  var selItemData:any = this._selection.getSelection()[0]
                  console.log('selItemData', selItemData)
                  if(selItemData && selItemData.id) {
                    if(action === "askToOpen") action = localStorage.openFilePath || 'askToOpen'
                    console.log("Action", action)
                    if(action === "askToOpen") {
                      console.log("Ask to open", selItemData)
                      this.setState({
                        selItem: this._selection.getSelection()[0],
                        showSelItemDialog: true
                      })
                    } else if(action === "openToEdit") {
                      console.log("Open to edit", selItemData)
                      if(this.props.appPlatform === ("electron" || "openfin")) {
                        this._onItemInvoked(selItemData)
                      } else {
                        if(this._isOfficeDoc(selItemData.fileExtension)) this._openOfficeOnline(selItemData.id, "edit", selItemData.lock, selItemData.wopiLock, "self")
                      }
                    } else if(action === "openReadOnly") {
                      console.log("Open read only", selItemData)
                      if(this.props.appPlatform === ("electron" || "openfin")) {
                        var id = selItemData.id;
                        let revisionId = selItemData.activeRevisionId;
                        if(selItemData.draft) revisionId = id + "-draft";
                        var hash = selItemData.hash
                        var fileExtension = selItemData.fileExtension;
                        var lock = selItemData.lock;

                        this._openReadOnlyDocument(id, revisionId, hash, selItemData.fileName, fileExtension, lock, selItemData)
                      } else {
                        if(this._isOfficeDoc(selItemData.fileExtension)) {
                          this._openOfficeOnline(selItemData.id, "view", selItemData.lock, selItemData.wopiLock, "self")
                        } else if (selItemData.fileExtension && selItemData.fileExtension.toLowerCase() === "pdf") {
                          let revisionId = selItemData.activeRevisionId;
                          if(selItemData.draft) revisionId = selItemData.id + "-draft";
                          this._openPdfSynergyReaderOnline(selItemData.id, revisionId)
                        }
                      }
                    }
                  }
                }, 300);
              } else {
                this._settingSelItem = false
              }
            },200)
          } else if(selItem.startsWith('File-')) {
            console.log("Sel item not found in current folder, look for its parent")
            userService.getDocument(selItem).then(response => {
              var parentId = response.data.parent_id
              if(this.props.folderId !== parentId) {
                console.log("Sel item parent folder is not the current folder")
                let uri = "/files/" + parentId + "?selItem=" + selItem + (action ? "&action=" + action : "")
                this._settingSelItem = false
                history.replace(uri)
              } else {
                this._settingSelItem = false
              }
            }).catch(error => {
                console.log(error)
                this._settingSelItem = false
            })
          }
        }, 500)
      }
    } else {
      var route = history.location.pathname
      var storedLastFocusedItems = JSON.parse(localStorage.getItem("lastFocusedItems") || "[]")

      var lastFocusItem = storedLastFocusedItems.filter(item => {
        return item.route === route
      })[0]

      var userBackOrForwardNavigation = localStorage.getItem("backOrForwardNavigation")

      if(!this._settingSelItem && selCount === 0 && userBackOrForwardNavigation) {
        if(lastFocusItem) {
          this.focusItem(lastFocusItem.item)
          setTimeout(()=>this.focusItem(lastFocusItem.item),200);
          localStorage.removeItem("backOrForwardNavigation")
        }
      }
    }
  }

  private _pressedKey: any = undefined;
  private _focusItems: any = null;
  private _focusItemIndex: number = 0;

  private _handleListFocusOnKeyDown(key) {
    let currentWord:string;
    if(key.length === 1) {
      if (this._pressedKey === key && this._focusItems && this._focusItems.length > 0 && this.state.searchWord.length === 0) {
        currentWord = this.state.searchWord + key;
        this._isMounted && this.setState({searchWord: this.state.searchWord + key}, ()=> {
          this._focusItemIndex = this._focusItemIndex + 1
          var focusItem = this._focusItems[this._focusItemIndex]
          if(focusItem) {
            this.setListFocus(focusItem)
          } else {
            this._focusItemIndex = 0
            focusItem = this._focusItems[this._focusItemIndex]
            if(focusItem !== this._selection.getSelection()[0]) this.setListFocus(focusItem)
          }
        })
      } else {
        if (this.state.searchWord.length === 0) this._pressedKey = key;
        currentWord = this.state.searchWord + key;
        this._isMounted && this.setState({searchWord: this.state.searchWord + key}, ()=> {
          if (this.state.searchWord.length === 1)  {
            this._focusItems = this.props.items && this.props.items.filter(item => {
              return item.name.toLowerCase().startsWith(this.state.searchWord.toLowerCase())
            })
          }
          this._focusItemIndex = 0
    
          let focusItem = this.props.items && this.props.items.filter(item => {
            return item.name.toLowerCase().startsWith(this.state.searchWord.toLowerCase())
          })[this._focusItemIndex]
          if(focusItem !== this._selection.getSelection()[0]) this.setListFocus(focusItem)
        })
      }
      setTimeout(()=>{
        if (this.state.searchWord === currentWord) {
          this._isMounted && this.setState({searchWord: ''})
        }
      },1000)
    }
    else {
      this._isMounted && this.setState({searchWord: ''})
    }
    
  }

  private setListFocus(focusItem) {
    //Reselect multiple times to correctly select item in long lists
    if(focusItem) {
      setTimeout(() => {
        this._selection.setAllSelected(false)
        this._selection.setKeySelected(focusItem.key, true, true)  
        var selIndex = this._selection.getSelectedIndices()[this._selection.getSelectedIndices().length-1]
        this._selection.setIndexSelected(selIndex, true, true);
        this._root.current && this._root.current.focusIndex(selIndex);
        setTimeout(() => {
          var selIndex = this._selection.getSelectedIndices()[this._selection.getSelectedIndices().length-1]
          this._selection.setIndexSelected(selIndex, true, true);
          this._root.current && this._root.current.focusIndex(selIndex);
        },200)
      },1)
    }
  }

  private _askUserToDownloadItem = async (item) => {
    return await new Promise((resolve, reject) => {
      var showDialog = true;

      var uploadItem = () => {
        showDialog = false;
        renderDialog()
        resolve(true)
      }

      var skipItem = () => {
        showDialog = false;
        renderDialog()
        reject("Operation rejected by user");
      }

      var zipItem = () => {
        this._unzipFile(item.id, item.fileName)
        showDialog = false;
        renderDialog()
        reject("Unziped file");
      }

      var div = document.createElement('div');
      var renderDialog = () => {
        ReactDOM.render(
          <Dialog
              hidden={!showDialog}
              dialogContentProps={{
                type: DialogType.normal,
                title: "This file cannot be opened"
              }}
              modalProps={{
                isBlocking: true,
                styles: { main: { maxWidth: 640 } },
                dragOptions: undefined,
                className: "ask-download-item-dialog"
              }}
            >
              <div className="w-100">
                { item.kind === "dir" ?
                    <span><Icon className='mr-1 mb-1' style={{writingMode:'vertical-rl'}} {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } /><span style={{verticalAlign:'top'}}>{item.fileName}</span></span>
                  : <span><Icon className='mr-1 mb-1' style={{writingMode:'vertical-rl'}} {...getFileTypeIconProps({ extension: item.fileExtension, size: 20, imageFileType: 'svg' }) } /><span style={{verticalAlign:'top'}}>{item.fileName}</span></span>
                }
                <p className='p-0 m-0' style={{fontWeight:600}}>{i18n.t('app:chooseOptions')}</p>
                <ul className="m-0 p-0">
                  <li className="mt-2 p-2 bg-grey" onClick={uploadItem}>
                    <Icon iconName="Download" className="text-primary mr-2" />
                    { "Download " + item.fileSize }
                  </li>
                  {item.fileExtension === "zip" && <li className="mt-2 p-2 bg-grey" onClick={zipItem}>
                    <Icon iconName="ZipFolder" className="text-success mr-2" />
                    {i18n.t('app:unzipFile')}
                  </li>}
                  <li className="mt-2 p-2 bg-grey" onClick={skipItem}>
                    <Icon iconName="Cancel" className="text-danger mr-2" />
                    Cancel
                  </li>
                </ul>  
                { !['doc','docx','ppt','pptx','xls','xlsx','png','jpg','jpeg','svg','pdf','email'].includes(item.fileExtension) && 
                  <p className="tip text-secondary mt-3 mb-0 align-items-center">
                    <Icon iconName="Info" className="align-bottom mr-1" /><span>{i18n.t('app:cannotBePreviewed')}.</span>
                  </p>
                }
              </div>
            </Dialog>
          , div
        )
      }

      renderDialog();
    })
  }

  private _onItemInvoked(item_: any, index?, e?): void {
    let event:any = e as Event;
    console.log("Item invoked event", item_, index, event, this._selection.getSelection())

    if (!item_ && this._selection.getSelection().length === 0) return;
    var item:any = this._selection.getSelection()[0] || item_
    var kind = item.fileType;

    if (kind === "dir") {      
      if(item.accessGranted || item.isFolderInPath) {
        var parser = new UAParser.UAParser();
        var os = parser.getResult().os;

        // Control or Command + r
        if ((os.name === "Windows" && event.ctrlKey)
          || (os.name === "Mac OS" && event.metaKey)) {
          this._openFolderInNewWindow(item.key)
        } else {
          this._openFolder(item.key);
        }
      }
    } else if (item.hasPermissions !== false) {
      var id = item.id;
      var revisionId = item.activeRevisionId;
      if(item.draft) revisionId = id + "-draft";
      var hash = item.hash
      var fileName = item.fileName;
      var fileExtension = item.fileExtension;
      var lock = item.lock;

      if (fileExtension === 'email') {
        this._previewFile(item)
      }
      else if(this.props.appPlatform === ("electron" || "openfin")) {
        if(!item.savingDraft) {
          if (!item.wopiLock) {
            if(fileExtension.toLowerCase().indexOf("zip") !== -1
              || fileExtension.toLowerCase().indexOf("rar") !== -1) {

              if(ActionsPermissions("downloadItems", this.state.folderRole)) {
                this._askUserToDownloadItem(item).then((response:any) => {
                  this._downloadItems([item])
                }).catch(reject => {console.log(reject)})
              }
            } else {
              var sreader = false;

              if (navigator.onLine
                && fileExtension.toLowerCase() === "pdf"
                && this.props.appPlatform === "electron" && localStorage.pdfApplication !== 'Default' ) {
                sreader =  window.sreader.length > 0;
              }

              if(ActionsPermissions("editFile", this.state.folderRole) || this.props.fileExplorerType === "offlineFiles") {
                this._openDocument(id, revisionId, hash, fileName, fileExtension, lock, sreader, item, false)
              } else if(ActionsPermissions("openDocumentReadOnly", this.state.folderRole)) {
                this._openReadOnlyDocument(id, revisionId, hash, item.fileName, fileExtension, lock, item)
              } else if(ActionsPermissions("previewDocument", this.state.folderRole)) {
                if(previewExtensions.includes(item.fileExtension)) this._previewFile(item)
              }
            }
          } else {
            this._openOfficeOnline(id, "edit", item.lock, item.wopiLock)
          }
        }
      } else {
        if(this._containsInvalidChars(fileName)) {
          alert('The file "' + fileName + '" can not be opened because its name contains at least one the following invalid characters:\n\n' + invalidChars.join("   ") + '\n\nPlease, rename the file removing or replacing all the invalid characters and try again.')
        } else {
          if((!item.lock || item.wopiLock) && item.fileExtension.toLowerCase() !== "pdf") {
            if(this._isOfficeDoc(item.fileExtension)) {
              if(ActionsPermissions("openDocumentOfficeOnline", this.state.folderRole)) {
                this._openOfficeOnline(id, "edit", item.lock, item.wopiLock)
              } else if(ActionsPermissions("viewDocumentOfficeOnline", this.state.folderRole)) {
                this._openOfficeOnline(id, "view", item.lock, item.wopiLock)
              } else if(ActionsPermissions("previewDocument", this.state.folderRole)) {
                if(previewExtensions.includes(item.fileExtension)) this._previewFile(item)
              }
            } else {
              if(ActionsPermissions("downloadItems", this.state.folderRole)) {
                this._askUserToDownloadItem(item).then((response:any) => {
                  this._downloadItems([item])
                }).catch(reject => {console.log(reject)})
              }
            }
          } else if (fileExtension.toLowerCase() === "pdf") {
            if(ActionsPermissions("openPdfWithSynergyReaderOnline", this.state.folderRole)) {
              this._openPdfSynergyReaderOnline(id, revisionId);
            }
          }
        }
      }
    }
  }

  private _openFolder(id) {
    history.push('/files/' + id);
  }

  private _openFolderInNewWindow(id) {
    if (isElectron()) {
      const { ipcRenderer } = window.require("electron")
      ipcRenderer.send('open-folder-in-new-window', id);
    } else {
      window.open('/files/' + id)
    }
  }

  private _previewFile(item: any) {
    if (!item.previewData) {
      if ( item.fileExtension === "doc"
        || item.fileExtension === "docx"
        || item.fileExtension === "ppt"
        || item.fileExtension === "pptx"
        || item.fileExtension === "xls"
        || item.fileExtension === "xlsx" ) {
        userService.getOfficeOnlineUrl(item.id, 'embedview').then((response:any) => {
          item.previewData = response.data;
          if (isElectron()) {     
            const { ipcRenderer } = window.require("electron");
            var wopiUrl = '';
            wopiUrl += userService.getWopiDomain()!.replace('https://','').replace('.synergy.page','')
            let url = getPreviewURL(item.id, 'wopi', wopiUrl)
            ipcRenderer.send('open-preview', url, item.fileName, '', wopiUrl);
            }
          else {
            window.open(response.data, '_blank')
          }
        }).catch(error => {
          item.previewData = "nopreview";
          this._isMounted && this.setState({
            previewData: "nopreview",
            imagePreviewDialog: true
          });
          console.log(error)
        });
      }
      else { 
          if (item.fileExtension === "email") {
            if (isElectron()) {
              const synergyApi = process.env.REACT_APP_SYNERGY_API_URL || ''
              const serverDomain = localStorage.getItem("serverdomain") || localStorage.getItem("serverDomain") || ''
              const indexerApi = synergyApi + serverDomain + process.env.REACT_APP_INDEXER_API_URL;
              const { ipcRenderer } = window.require("electron")
              let url = getPreviewURL(item.id, item.fileExtension, indexerApi,item.activeRevisionId)
              ipcRenderer.send('open-preview', url, item.fileName, '', indexerApi, item.activeRevisionId);
              }
            else {
              const synergyApi = process.env.REACT_APP_SYNERGY_API_URL || ''
              const serverDomain = Cookies.get("serverdomain") || localStorage.getItem("serverDomain") || ''
              const indexerApi = synergyApi + serverDomain + process.env.REACT_APP_INDEXER_API_URL;
              window.open(process.env.REACT_APP_DRIVE_URL+"/preview.html?id="+item.id+"&revision="+item.activeRevisionId+"&type=email&api="+indexerApi.replace('https://',''))
            }
          }
          else {
            userService.downloadOnlineDocument(item.id, item.activeRevisionId, "PREVIEW").then((response: any) => {
            const byteArray = new Uint8Array(response.data);
            var blob: any;
              if ( item.fileExtension === "svg") {
                blob = new Blob([byteArray], {type: 'image/svg+xml'});
              }
              else if (item.fileExtension === "png" || item.fileExtension === "jpg" || item.fileExtension === "jpeg") {
                blob = new Blob([byteArray], {type: 'image/'+item.fileExtension});
              }
              else if (item.fileExtension === "pdf") {
                blob = new Blob([byteArray], { type: 'application/pdf' });
              }
              else if (item.fileExtension === "txt") {
                blob = new Blob([byteArray]);
              }
              const url = URL.createObjectURL( blob );
              item.previewData = url;
              if (isElectron()) {
                const { ipcRenderer } = window.require("electron")
                ipcRenderer.send('open-preview', url, item.fileName, item.fileExtension);
                }
              else {
                window.open(url, '_blank')
              }
            }).catch(error => {
              item.previewData = "nopreview";
              this._isMounted && this.setState({
                previewData: "nopreview",
                imagePreviewDialog: true
              });
              console.log(error)
            });
          }
      }
    }
    else {
      if ( item.fileExtension === "doc"
      || item.fileExtension === "docx"
      || item.fileExtension === "ppt"
      || item.fileExtension === "pptx"
      || item.fileExtension === "xls"
      || item.fileExtension === "xlsx" ) {
        if (isElectron()) {     
          const { ipcRenderer } = window.require("electron");    
          var wopiUrl = '';
          wopiUrl += userService.getWopiDomain()!.replace('https://','').replace('.synergy.page','')
          let url = getPreviewURL(item.id, 'wopi', wopiUrl)
          ipcRenderer.send('open-preview', url, item.fileName, '', wopiUrl);
        }
        else {
          window.open(item.previewData, '_blank')
        }
      }
      else if (item.fileExtension === "email") {
        if (isElectron()) {
          const synergyApi = process.env.REACT_APP_SYNERGY_API_URL || ''
          const serverDomain = localStorage.getItem("serverdomain") || ''
          const indexerApi = synergyApi + serverDomain + process.env.REACT_APP_INDEXER_API_URL;
          const { ipcRenderer } = window.require("electron")
          let url = getPreviewURL(item.id, item.fileExtension, indexerApi,item.activeRevisionId)
          ipcRenderer.send('open-preview', url, item.fileName, '', indexerApi, item.activeRevisionId);
        }
        else {
          const synergyApi = process.env.REACT_APP_SYNERGY_API_URL || ''
          const serverDomain = Cookies.get("serverdomain") || ''
          const indexerApi = synergyApi + serverDomain + process.env.REACT_APP_INDEXER_API_URL;
          window.open(process.env.REACT_APP_DRIVE_URL+"/preview.html?id="+item.id+"&revision="+item.activeRevisionId+"&type=email&api="+indexerApi.replace('https://',''))    
        }
      }
      else {
        if (isElectron()) {     
          const { ipcRenderer } = window.require("electron");
          ipcRenderer.send('open-preview', item.previewData, item.fileName, item.fileExtension);
        }
        else {
          window.open(item.previewData, '_blank')
        }
      }
    }
    
  }

  private _shareFolder = (folder) => {
    var showDialog = true;
    var loadingPossibleUsers: boolean = true;
    var possibleUsers: any = [];
    var selInternalUsers:any = [];
    var selExternalUsers:any = [];
    var cascade = true;
    var rolePermissions: string = "EDITOR"

    var _handleLinkClick = (item: any): void => {
      this.setState({
        activeShareTab: item.props.itemKey
      }, () => {
        renderDialog();
      })
    };
  
    var _getTabId = (itemKey: string): string => {
      return `${itemKey}`;
    };

    function getPossibleUsers() {
      userService.getPossibleUsers(folder.id).then((response: any) => {
        if(response.data.length > 0) {
          possibleUsers = response.data.map(item => {
            return({
              key: item.user.id,
              text: item.user.name + " "+ item.user.surname + ", " + item.user.email,
              type: "user"
            })
          });

          possibleUsers.sort((a,b) => {
            if(a.text.toLowerCase() < b.text.toLowerCase())
                return -1;
            if(b.text.toLowerCase() < a.text.toLowerCase())
                return 1;
    
            return 0;
          });
          
          possibleUsers.unshift({
            key: 'HeaderUsers',
            text: i18n.t('app:individuals'),
            itemType: SelectableOptionMenuItemType.Header
          })
          loadingPossibleUsers = false
          renderDialog();
        } else {
          loadingPossibleUsers = false
          renderDialog();
        }

        userService.getPossibleGroups(folder.id).then((response: any) => {
          if(response.data.length > 0) {
            var groups: any = response.data.map(item => {
              if(!item.name) item.name = "Name undefined"

              item.users.sort((a,b) => {
                if(a.name.toLowerCase() < b.name.toLowerCase())
                    return -1;
                if(b.name.toLowerCase() < a.name.toLowerCase())
                    return 1;
        
                return 0;
              });

              return({
                key: item.id,
                text: item.name + " (" + item.users.length + "p.)",
                users: item.users,
                type: "group"
              })
            }) || [];

            groups.sort((a,b) => {
              if(a.text.toLowerCase() < b.text.toLowerCase())
                  return -1;
              if(b.text.toLowerCase() < a.text.toLowerCase())
                  return 1;
      
              return 0;
            });
            
            if(groups.length > 0) {
              groups.unshift({
                key: 'HeaderGroups',
                text: i18n.t('app:groups'),
                itemType: SelectableOptionMenuItemType.Header
              })

              Array.prototype.push.apply(possibleUsers,groups);
              loadingPossibleUsers = false
              renderDialog();
            } else {
              loadingPossibleUsers = false
              renderDialog();
            }
          }
        }).catch(error => {
          console.log(error)
          loadingPossibleUsers = false
          renderDialog();
        })
      }).catch(error => {
        console.log(error)
        loadingPossibleUsers = false
        renderDialog();
      })
    }
    getPossibleUsers();

    function addInternalPermissions() {      
      var users = selInternalUsers.filter(item => {
        return item.type === "user"
      })
      var usersId = users.map(user => {
        return user.key;
      })

      var groups = selInternalUsers.filter(item => {
        return item.type === "group"
      })
      var groupsId = groups.map(user => {
        return user.key;
      })

      if(users.length > 0) {
        userService.addInternalPermissions(folder.id, usersId, cascade, rolePermissions)
          .then((response: any) => {
            showDialog = false;
            renderDialog();
          }).catch(error => {
            console.log(error)
            showDialog = false;
            renderDialog();
          })
      }
      
      if(groups.length > 0) {
        userService.addGroupPermissions(folder.id, groupsId, cascade, rolePermissions)
          .then((response: any) => {
            showDialog = false;
            renderDialog();
          }).catch(error => {
            console.log(error)
            showDialog = false;
            renderDialog();
          })
      }
    }

    var addInternalUser = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number) => {
      if(option) {
        selInternalUsers.push(option)
        renderDialog();
      }
    }

    var removeInternalUser = (user) => {
      selInternalUsers = selInternalUsers.filter(selUser => {
        return selUser.key !== user.key
      })
      renderDialog();
    }

    function addExternalPermissions() {
      console.log("Add external permissions")

      var usersId = selExternalUsers.map(user => {
        return user;
      })

      userService.addExternalPermissions(folder.id, usersId, cascade)
        .then((response: any) => {
          showDialog = false;
          renderDialog();
        }).catch(error => {
          console.log(error)
          showDialog = false;
          renderDialog();
          alert(i18n.t('app:usersDontExist'))
        })
    }

    var externalUser: string = "";
    var addExternallUser: any = (e) => {
      e.preventDefault();
      
      if(externalUser.length > 0) {
        selExternalUsers.push(externalUser)
        externalUser = ""
        renderDialog();
      }
    }

    var removeExternalUser = (user) => {
      selExternalUsers = selExternalUsers.filter(selUser => {
        return selUser !== user
      })
      renderDialog();
    }

    var handleRolePermissions = (ev: any, newValue?: any) => {
      rolePermissions = newValue.key;
    }

    var handleCascade = (ev: any, newValue?: any) => {
      cascade = (newValue.key === "true");
    }

    var div = document.createElement('div');
    var renderDialog = () => {

      ReactDOM.render(
        <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "permissions-dialog"
            }}
            onDismiss={() => {showDialog = false; renderDialog()}}
          >
          <div className="share-pivot">
            <Pivot selectedKey={this.state.activeShareTab} onLinkClick={ _handleLinkClick } headersOnly={true} getTabId={_getTabId} className="share-pivot">
              <PivotItem headerText={i18n.t('app:internal')} itemKey="internal"></PivotItem>
              <PivotItem headerText={i18n.t('app:external')} itemKey="external"></PivotItem>
            </Pivot>
          </div>

          <div className="dialog-content-wrap">
            <h1>{i18n.t('app:sharingOptions')+': ' + folder.name}</h1>
            
            <div
              aria-labelledby={_getTabId(this.state.activeShareTab)}
              role="tabpanel"
            >
              { this.state.activeShareTab === "internal" ?
                <>
                { loadingPossibleUsers ?
                  <>
                    <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
                    <p className="d-inline-block">{i18n.t('app:pleaseWait')}...</p>
                  </>
                : possibleUsers.length > 0 ?
                  <>
                  <ComboBox
                    label={i18n.t('app:invitePeople')}
                    key={'shareSuggestions'}
                    allowFreeform={true}
                    autoComplete={'on'}
                    options={possibleUsers}
                    onChange={ addInternalUser }
                    shouldRestoreFocus={true}
                    className="mb-2"
                    scrollSelectedToTop={true}
                    placeholder="Choose"
                  />
                  <ul className="my-3 mx-0 p-0">
                    {selInternalUsers.map((selUser: any) =>
                      <li key={selUser.key} className="selUser p-0 mx-0 my-2">
                        {selUser.text} <Icon iconName="Cancel" className="text-danger align-bottom ml-2" onClick={ () => removeInternalUser(selUser)} />
                        {selUser.users ?
                          <ul className="group-users mx-2 p-0">
                          { selUser.users.map((user: any) =>
                            <li key={user.id}>{user.name + " " + user.surname}</li>
                          )}
                          </ul>
                        : null }
                      </li>
                    )}
                  </ul>

                  <div className="row m-0 p-0">
                    <div className="col m-0 p-0">
                      <ChoiceGroup
                        options={[
                          { key: "EDITOR", text: i18n.t('app:editor') },
                          { key: "RESTRICTED_EDITOR", text: i18n.t('app:restrictedEditor') },
                          { key: "VIEWER", text: i18n.t('app:viewer') },
                          { key: "PREVIEWER", text: i18n.t('app:previewer') }
                        ]}
                        label={i18n.t('app:role')}
                        required={true}
                        defaultSelectedKey={ "EDITOR" }
                        className="saveVersionAs"
                        onChange={ handleRolePermissions }
                      />
                    </div>

                    <div className="col m-0 p-0">
                      <ChoiceGroup
                        options={[
                          { key: "true", text: i18n.t('app:thisFolderAndAllSubfolders') },
                          { key: "false", text: i18n.t('app:onlyThisFolder') }
                        ]}
                        label={i18n.t('app:inviteTo')}
                        required={true}
                        defaultSelectedKey={ cascade ? "true" : "false" }
                        className="saveVersionAs"
                        onChange={ handleCascade }
                      />
                    </div>
                  </div>
                  </>
                :
                  <p>{i18n.t('app:notAvailable')}</p>
                }
                <DialogFooter className="mt-4">
                  <DefaultButton onClick={() => {showDialog = false; renderDialog()}} text={i18n.t('app:cancel')} />
                  { possibleUsers.length > 0 ?
                    <PrimaryButton onClick={() => addInternalPermissions()} text={i18n.t('app:save')} disabled={selInternalUsers.length === 0} />
                  :
                    null
                  }
                </DialogFooter>
                </>
              : this.state.activeShareTab === "external" ?
                <>
                <form name="form" onSubmit={addExternallUser} >
                  <TextField
                    label={i18n.t('app:invitePeople')}
                    key={'externalUsersInput'}
                    className="mb-2"
                    placeholder={i18n.t('app:invitePeople')}
                    value={ externalUser }
                    onChange={ (ev: any, newValue?: string) => {externalUser = newValue || ""; renderDialog()}}
                  />
                </form>
                <ul className="my-3 mx-0 p-0">
                  {selExternalUsers.map((selUser: any) =>
                    <li key={selUser} className="selUser p-0 mx-0 my-2">
                      {selUser} <Icon iconName="Cancel" className="text-danger align-bottom ml-2" onClick={ () => removeExternalUser(selUser)} />
                    </li>
                  )}
                </ul>

                <div className="row m-0 p-0">
                  <div className="col m-0 p-0">
                    <ChoiceGroup
                      options={[
                        { key: "EDITOR", text: i18n.t('app:editor') },
                        { key: "RESTRICTED_EDITOR", text: i18n.t('app:restrictedEditor') },
                        { key: "VIEWER", text: i18n.t('app:viewer') },
                        { key: "PREVIEWER", text: i18n.t('app:previewer') }
                      ]}
                      label={i18n.t('app:role')}
                      required={true}
                      defaultSelectedKey={ "EDITOR" }
                      className="saveVersionAs"
                    />
                  </div>

                  <div className="col m-0 p-0">
                    <ChoiceGroup
                        options={[
                          { key: "true", text: i18n.t('app:thisFolderAndAllSubfolders') },
                          { key: "false", text: i18n.t('app:onlyThisFolder') }
                        ]}
                        label={i18n.t('app:inviteTo')}
                        required={true}
                        defaultSelectedKey={ cascade ? "true" : "false" }
                        className="saveVersionAs"
                        onChange={ handleCascade }
                      />
                  </div>
                </div>
                <DialogFooter className="mt-4">
                  <DefaultButton onClick={() => {showDialog = false; renderDialog()}} text={i18n.t('app:cancel')} />
                  <PrimaryButton onClick={() => addExternalPermissions()} text={i18n.t('app:save')} disabled={selExternalUsers.length === 0} />
                </DialogFooter>
                </>
              : null }
            </div>
          </div>
          </Dialog>
        , div
      )
    }

    renderDialog();
  }

  private _onFileExplorerContextMenu = async(ev: MouseEvent | any) => {
    ev.preventDefault();

    var clickOnBlankSpace = ev && ev.target && ev.target.className
    && ev.target.className.indexOf("ms-ScrollablePane--contentContainer") !== -1

    if(clickOnBlankSpace) {
      var parentRole:number = this.state.parentRole
      
      const contextualFileExplorerMenuProps: IContextualMenuProps = {
        target: {x: ev.clientX, y: ev.clientY},
        directionalHint: DirectionalHint.bottomLeftEdge,
        items: [
          {
            key: 'goBack',
            name: i18n.t('app:back'),
            onClick: () => this.props.headerRef._goBack(),
            iconProps: { iconName: 'Back' }
          },
          {
            key: 'divider_0',
            itemType: ContextualMenuItemType.Divider,
            hidden: this.props.fileExplorerType !== "fileExplorer"
          },
          {
            key: 'newFolder',
            name: i18n.t('app:newFolder'),
            onClick: this.props.headerRef._showNewFolderDialog && this.props.folderId && this.props.headerRef._showNewFolderDialog.bind(this, this.props.folderId),
            iconProps: { iconName: 'FabricNewFolder' },
            hidden: !ActionsPermissions("createFolder", parentRole) || this.props.fileExplorerType !== "fileExplorer"
          },
        ],
        onDismiss: () => this.setState({contextualFileExplorerMenuProps: undefined})
      }

      this.setState({contextualFileExplorerMenuProps: contextualFileExplorerMenuProps});
    } else {
      this.setState({contextualFileExplorerMenuProps: undefined});
    }
  }

  private _onItemContextMenu = (item: any | undefined, index: number | undefined, ev: MouseEvent | any): boolean => {

    var id = item.id;
    var revisionId = item.activeRevisionId;
    if(item.draft) revisionId = id + "-draft";

    var hash = item.hash;

    var parser = new UAParser.UAParser();
    var os = parser.getResult().os;

    const selectionCount: number = this._selection.getSelectedCount();

    const selection = this._selection.getSelection();
    var folders = selection.filter((item: any) => {
        return item.fileType === "dir"
      })
    const folderSelected = folders.length > 0;

    var locked = selection.filter((item: any) => {
        return item.lock
      })
    const lockedSelected = locked.length > 0;

    var noAccessGranted = selection.filter((item: any) => {
      return (item.fileType === "dir" && !item.accessGranted)
    })
    const accessGrantedSel = noAccessGranted.length === 0

    var offlineMode = this.props.fileExplorerType === "offlineFiles"

    if (!item.fileExtension) item.fileExtension = ''

    var sreader = false;
    if (navigator.onLine
      && item.fileExtension.toLowerCase() === "pdf"
      && this.props.appPlatform === "electron") {
      sreader =  window.sreader.length > 0;
    }

    var isPdf = item.fileExtension.toLowerCase() === "pdf"
    var isZip = item.fileExtension.toLowerCase() === "zip"

    var isOfficeCompatibilityDoc = this._isOfficeCompatibilityDoc(item.fileExtension)
    var isOfficeOnlineDoc = this._isOfficeOnlineDoc(item.fileExtension)

    var notWordDocs = selection.filter((item: any) => {
      if(item.fileExtension !== "doc"
        && item.fileExtension !== "docx") {

        return true;
      }
      return false
    })

    var notPowerPointDocs = selection.filter((item: any) => {
      if(item.fileExtension !== "ppt"
        && item.fileExtension !== "pptx") {

        return true;
      }
      return false
    })

    var saveAsPdfEnabled = notWordDocs.length === 0 || notPowerPointDocs.length === 0;
    var compareWordEnabled = notWordDocs.length === 0;

    var role: number = this.state.folderRole

    const contextualMenuProps: IContextualMenuProps = {
      target: ev.target as HTMLElement,
      directionalHint: DirectionalHint.bottomLeftEdge,
      items: [
        {
          key: 'openDocument',
          name: i18n.t('app:open'),
          onClick: this._openDocument.bind(this, id, revisionId, item.hash, item.fileName, item.fileExtension, item.lock, sreader, item, false),
          iconProps: { iconName: 'OpenFile' },
          disabled: item.wopiLock,
          hidden: !ActionsPermissions("editFile", role) || folderSelected || selectionCount > 1 || sreader || item.fileExtension === 'email' || this.props.appPlatform !== ("electron" || "openfin")
        },
        {
          key: 'openDocumentReadOnly',
          name: i18n.t('app:openDocumentReadOnly'),
          onClick: this._openReadOnlyDocument.bind(this, id, revisionId, hash, item.fileName, item.fileExtension, item.lock, item),
          iconProps: { iconName: 'PageLock' },
          hidden: !ActionsPermissions("openDocumentReadOnly", role) || folderSelected || selectionCount > 1 || !isOfficeCompatibilityDoc || this.props.appPlatform !== ("electron" || "openfin")
        },
        {
          key: 'openDocumentOfficeOnline',
          name: i18n.t('app:openDocumentOfficeOnline'),
          onClick: this._openOfficeOnline.bind(this, id, "edit", item.lock, item.wopiLock),
          iconProps: { iconName: 'OfficeLogo' },
          disabled: item.lock && !item.wopiLock,
          hidden: !ActionsPermissions("openDocumentOfficeOnline", role) || folderSelected || selectionCount > 1 || !isOfficeOnlineDoc || offlineMode || this.props.maintenance
        },
        {
          key: 'viewDocumentOfficeOnline',
          name: 'View in Office Online',
          onClick: this._openOfficeOnline.bind(this, id, "view", item.lock, item.wopiLock),
          iconProps: { iconName: 'PageLock' },
          hidden: !ActionsPermissions("viewDocumentOfficeOnline", role) || folderSelected || selectionCount > 1 || !isOfficeOnlineDoc || offlineMode || this.props.maintenance || this.props.appPlatform === ("electron" || "openfin")
        },
        {
          key: 'openPdfWithSynergyReader',
          name: i18n.t('app:openPdfWithSynergyReader'),
          onClick: this._openDocument.bind(this, id, revisionId, hash, item.fileName, item.fileExtension, item.lock, true, item, false),
          iconProps: { iconName: 'OpenFile' },
          hidden: folderSelected || selectionCount > 1 || !sreader || offlineMode || this.props.maintenance
        },
        {
          key: 'openPdfWithSynergyReaderOnline',
          name: i18n.t('app:openPdfWithSynergyReader'),
          onClick: this._openPdfSynergyReaderOnline.bind(this, id, revisionId),
          iconProps: { iconName: 'OpenFile' },
          hidden: !isPdf || folderSelected || selectionCount > 1 || offlineMode || this.props.maintenance || this.props.appPlatform === ("electron" || "openfin")
        },
        {
          key: 'openPdfWithDefaultApp',
          name: i18n.t('app:openPdfWithDefaultApp'),
          onClick: this._openDocument.bind(this, id, revisionId, item.hash, item.fileName, item.fileExtension, item.lock, false, item, false),
          iconProps: { iconName: 'PDF' },
          disabled: item.lock,
          hidden: folderSelected || selectionCount > 1 || !sreader
        },
        {
          key: 'openPdfWithWord',
          name: i18n.t('app:openPdfWithWord'),
          onClick: this._openDocument.bind(this, id, revisionId, item.hash, item.fileName, item.fileExtension, item.lock, false, item, true),
          iconProps: { iconName: 'WordDocument' },
          hidden: !isPdf || folderSelected || selectionCount > 1 || offlineMode || this.props.maintenance || this.props.appPlatform !== ("electron" || "openfin")
        },
        {
          key: 'openFolder',
          name: i18n.t('app:openFolder'),
          onClick: this._openFolder.bind(this, item.id),
          iconProps: { iconName: 'FabricOpenFolderHorizontal' },
          disabled: !accessGrantedSel && !item.isFolderInPath,
          hidden: !folderSelected || selectionCount > 1 || offlineMode || this.props.maintenance
        },
        {
          key: 'openFolderInNewWindow',
          name: i18n.t('app:openFolderInNewWindow'),
          onClick: this._openFolderInNewWindow.bind(this, item.id),
          iconProps: { iconName: 'OpenInNewWindow' },
          disabled: !accessGrantedSel && !item.isFolderInPath,
          hidden: !folderSelected || selectionCount > 1 || offlineMode || this.props.maintenance || this.props.appPlatform !== "electron"
        },
        {
          key: 'viewImage',
          name: i18n.t('app:preview'),
          onClick: this._previewFile.bind(this, item),
          iconProps: { iconName: 'Preview' },
          hidden: !previewExtensions.includes(item.fileExtension) || selectionCount > 1 || offlineMode || this.props.maintenance
        },
        {
          key: 'divider_0',
          itemType: ContextualMenuItemType.Divider,
        },
        {
          key: 'addBookmark',
          name: i18n.t('app:addBookmarks'),
          onClick: () => this.props.detailsPanelRef.addBookmark(item),
          iconProps: { iconName: 'FavoriteStar' },
          hidden: selectionCount > 1 || item.bookmark || item.type === "tag" || offlineMode || this.props.maintenance
        },
        {
          key: 'removeBookmark',
          name: i18n.t('app:removeBookmarks'),
          onClick: () => this.props.detailsPanelRef.removeBookmark(item),
          iconProps: { iconName: 'Unfavorite' },
          hidden: selectionCount > 1 || !item.bookmark || offlineMode || this.props.maintenance
        },
        {
          key: 'copyPrivateShortcut',
          name: i18n.t('app:copyPrivateShortcut'),
          onClick: this._copyPrivateShortcut.bind(this, item, "askToOpen"),
          iconProps: { iconName: 'Link' },
          hidden: selectionCount > 1 || offlineMode || item.type === "tag" || this.props.maintenance
        },
        {
          key: 'copyPrivateShortcutName',
          name: i18n.t('app:copyHyperlink'),
          onClick: this._copyPrivateShortcutName.bind(this, item),
          iconProps: { iconName: 'PageLink' },
          hidden: selectionCount > 1 || offlineMode || item.type === "tag" || this.props.maintenance
        },
        {
          key: 'goToFile',
          name: i18n.t('app:goToFile'),
          hidden: folderSelected || selectionCount > 1 || this.props.fileExplorerType === "fileExplorer" || this.props.fileExplorerType === "openFiles" || item.type === "tag" || (offlineMode && (!this.props.isOnline || this.props.maintenance)),
          onClick: this._goToFile.bind(this, item),
          iconProps: { iconName: 'FabricOpenFolderHorizontal' },
        },
        {
          key: 'findInFolderTree',
          name: i18n.t('app:findInFolderTree'),
          hidden: this.props.fileExplorerType !== "fileExplorer" || (offlineMode && (!this.props.isOnline || this.props.maintenance)),
          onClick: this._findInFolderTree.bind(this, item),
          iconProps: { iconName: 'BulletedTreeList' },
        },
        {
          key: 'divider_5',
          itemType: ContextualMenuItemType.Divider,
        },
        {
          key: 'cut',
          text: i18n.t('app:cut'),
          iconProps: { iconName: 'Cut' },
          hidden: this.props.searchType==="tag",
          disabled: this._isMounted && this.props.headerRef._checkClipboardCutEnabled(),
          onClick: () => this._clipboardCut()
        },
        {
          key: 'copy',
          text: i18n.t('app:copy'),
          iconProps: { iconName: 'Copy' },
          hidden: this.props.searchType==="tag",
          disabled: this._isMounted && this.props.headerRef._checkClipboardCopyEnabled(),
          onClick: () => this._clipboardCopy(),
        },
        {
          key: 'paste',
          text: i18n.t('app:paste'),
          iconProps: { iconName: 'Paste' },
          hidden: this.props.searchType==="tag",
          disabled: this._isMounted && this.props.headerRef._checkClipboardPasteEnabled(),
          onClick: () => this._clipboardPaste(),
        },
        {
          key: 'divider_1',
          itemType: ContextualMenuItemType.Divider,
        },
        {
          key: 'renameItem',
          name: i18n.t('app:rename'),
          disabled: lockedSelected || !accessGrantedSel,
          hidden: !ActionsPermissions("renameItem", role) || selectionCount > 1 || offlineMode || this.props.maintenance,
          onClick: () => this._showRenameDialog(item),
          iconProps: { iconName: 'Edit' }
        },
        {
          key: 'renameTag',
          name: i18n.t('app:renameTag'),
          disabled: lockedSelected || !accessGrantedSel,
          hidden: item.type !== "tag",
          onClick: () => {if(this.props.headerRef) this.props.headerRef._showEditSingleTagDialog()},
          iconProps: { iconName: 'Edit' }
        },
        {
          key: 'editTagCategory',
          name: i18n.t('app:editCategory'),
          disabled: lockedSelected || !accessGrantedSel || item.categoryTagId === item.repo + '-tagCategory',
          hidden: item.type !== "tag",
          onClick: () => {if(this.props.headerRef) this.props.headerRef._showEditTagDialog('')},
          iconProps: { iconName: 'Edit' }
        },
        {
          key: 'downloadItems',
          name: i18n.t('app:download') + (selectionCount === 1 ? '' : ' ' + selectionCount + i18n.t('app:items')),
          onClick: this._downloadItems.bind(this, this._selection.getSelection()),
          iconProps: { iconName: 'Download' },
          disabled: !accessGrantedSel,
          hidden: !ActionsPermissions("downloadItems", role) || (role > 1 && selectionCount > 20) || (role > 1 && folderSelected) || selectionCount === 0 || offlineMode || this.props.maintenance
        },
        {
          key: 'attachFile',
          name: i18n.t('app:attach') + (selectionCount === 1 ? '' : ' ' + selectionCount + i18n.t('app:items')),
          onClick: this._attachFile.bind(this, null),
          iconProps: { iconName: 'Attach' },
          hidden: !ActionsPermissions("attachFile", role) || (role > 1 && selectionCount > 20) || folderSelected || selectionCount < 1 ||offlineMode || this.props.maintenance || this.props.appPlatform !== ("electron" || "openfin")
        },
        {
          key: 'saveAsPdf',
          name: i18n.t('app:pdf'),
          hidden: selectionCount !== 1 || !saveAsPdfEnabled || os.name !== "Windows" || offlineMode || this.props.maintenance || this.props.appPlatform !== ("electron" || "openfin"),
          onClick: this._saveAsPdf.bind(this),
          iconProps: { iconName: 'PDF' }
        },
        {
          key: 'duplicateItem',
          name: i18n.t('app:duplicate') + (selectionCount === 1 ? '' : ' ' + selectionCount + i18n.t('app:items')),
          onClick: this._duplicateItem.bind(this),
          iconProps: { iconName: 'DuplicateRow' },
          hidden: !ActionsPermissions("duplicateItem", role) || selectionCount > 1 || offlineMode || this.props.maintenance
        },
        {
          key: 'append',
          name: i18n.t('app:append'),
          disabled: lockedSelected,
          hidden: !ActionsPermissions("append", role) || selectionCount > 2  || folderSelected || offlineMode || this.props.maintenance,
          onClick: () => this.props.headerRef._appendVersion(),
          iconProps: { iconName: 'BranchMerge' }
        },
        {
          key: 'zipItems',
          name: i18n.t('app:zip') + (selectionCount === 1 ? '' : ' ' + selectionCount + i18n.t('app:items')),
          onClick: this._showZipNameDialog.bind(this),
          iconProps: { iconName: 'ZipFolder' },
          hidden: isZip || selectionCount === 0 || offlineMode || this.props.maintenance
        },
        {
          key: 'unzipFile',
          name: i18n.t('app:unzipFile'),
          onClick: this._unzipFile.bind(this, id, item.fileName),
          iconProps: { iconName: 'ZipFolder' },
          hidden: !isZip || folderSelected || selectionCount > 1 || offlineMode || this.props.maintenance
        },
        {
          key: 'shareFolder',
          name: i18n.t('app:sharingOptions'),
          onClick: this._shareFolder.bind(this, item),
          iconProps: { iconName: 'Share' },
          disabled: !accessGrantedSel,
          hidden: !ActionsPermissions("sharingOptions", role) || !folderSelected || selectionCount > 1 || offlineMode || this.props.maintenance
        },
        {
          key: 'publicSharing',
          name: i18n.t('app:createPublicLink') + (selectionCount === 1 ? '' : i18n.t('app:for') + selectionCount + i18n.t('app:items')),
          onClick: this._createPublicLink.bind(this, this._selection.getSelection()),
          iconProps: { iconName: 'Globe' },
          disabled: folderSelected && !accessGrantedSel,
          //hidden: selectionCount === 0 || offlineMode || this.props.maintenance,
          hidden: true
        },
        {
          key: 'divider_2',
          itemType: ContextualMenuItemType.Divider,
        },
        {
          key: 'tagItem',
          name: i18n.t('app:tag') + (selectionCount === 1 ? '' : ' ' + selectionCount + i18n.t('app:items')),
          onClick: () => {this.props.headerRef.setState({ showTagBulkDialog: true, tagsList: null, isCascade: false, newTag: [], savingTag:false }); this.props.headerRef.getAllTags()},
          iconProps: { iconName: 'Tag' },
          hidden: !ActionsPermissions("tagItem", role) || offlineMode || this.props.maintenance
        },
        {
          key: 'versions',
          name: i18n.t('app:versions'),
          //hidden: selectionCount > 1 || folderSelected || offlineMode || this.props.maintenance,
          hidden: true,
          onClick: () => this._showVersionHistory(),
          iconProps: { iconName: 'FullHistory' }
        },
        {
          key: 'compare',
          name: i18n.t('app:compare'),
          hidden: !ActionsPermissions("compare", role) || (selectionCount !== 2 && selectionCount !== 1) || !compareWordEnabled || offlineMode || this.props.maintenance || this.props.appPlatform !== ("electron" || "openfin"),
          onClick: () => this.props.headerRef._confirmCompareWordDocs(),
          iconProps: { iconName: 'BranchCompare' }
        },
        {
          key: 'lockFile',
          name: i18n.t('app:lock'),
          onClick: this._lockDocument.bind(this, item.id),
          iconProps: { iconName: 'Lock' },
          hidden: !ActionsPermissions("lockFile", role) || item.wopiLock || item.lock || selectionCount > 1 || folderSelected || offlineMode || this.props.maintenance
        },
        {
          key: 'unlockFile',
          name: i18n.t('app:unlock'),
          onClick: this._unlockDocument.bind(this, item.id),
          iconProps: { iconName: 'Unlock' },
          hidden: !ActionsPermissions("unlockFile", role) || item.wopiLock || !item.lock || (item.lock && item.lock.id !== this.props.userData.user.id) || selectionCount > 1 || offlineMode || this.props.maintenance
        },
        {
          key: 'forceUnlockFile',
          name: i18n.t('app:forceUnlock'),
          onClick: this._forceUnlockDocument.bind(this, item.id),
          iconProps: { iconName: 'Unlock' },
          hidden: !ActionsPermissions("forceUnlockFile", role) || !item.lock || (!item.wopiLock && item.lock && item.lock.id === this.props.userData.user.id) || selectionCount > 1 || offlineMode || this.props.maintenance
        },
        {
          key: 'divider_3',
          itemType: ContextualMenuItemType.Divider,
          hidden: offlineMode || this.props.maintenance,
          itemProps: {
            styles: {
              divider: {
                display: folderSelected || this.props.appPlatform !== ("electron" || "openfin") ? "none" : "block"
              }
            }
          }
        },
        {
          key: 'divider_4',
          itemType: ContextualMenuItemType.Divider,
          hidden: offlineMode || this.props.maintenance,
          itemProps: {
            styles: {
              divider: {
                display: folderSelected ? "none" : "block"
              }
            }
          }
        },
        {
          key: 'deleteItem',
          name: i18n.t('app:delete') + (selectionCount === 1 ? '' : ' ' + selectionCount + i18n.t('app:items')),
          disabled: lockedSelected || !accessGrantedSel,
          onClick: () => this.props.headerRef._deleteItems(this._selection.getSelection()),
          hidden: !ActionsPermissions("deleteItem", role) || this.props.headerRef.state.isTrash || offlineMode || this.props.maintenance,
          iconProps: { iconName: 'Delete' }
        },
        {
          key: 'restoreItem',
          name: i18n.t('app:restore') + (selectionCount === 1 ? '' : ' ' + selectionCount + i18n.t('app:items')),
          disabled: lockedSelected,
          onClick: () => this.props.headerRef._restoreItems(),
          hidden: !this.props.headerRef.state.isTrash || offlineMode || this.props.maintenance,
          iconProps: { iconName: 'ArrangeSendToBack' }
        },
      ],
      onDismiss: () => {
        this.setState({
          contextualMenuProps: undefined
        });
      }
    };

    if (index !== undefined && index > -1) {
      this.setState({
        contextualMenuProps: contextualMenuProps
      });
    }

    return false;
  }

  private _onItemContextMenuMobile = (item: any | undefined, index: number | undefined): boolean => {
    
    var id = item.id;
    var revisionId = item.activeRevisionId;
    if(item.draft) revisionId = id + "-draft";

    var hash = item.hash;

    var parser = new UAParser.UAParser();
    var os = parser.getResult().os;

    const selectionCount: number = this._selection.getSelectedCount();

    const selection = this._selection.getSelection();
    var folders = selection.filter((item: any) => {
        return item.fileType === "dir"
      })
    const folderSelected = folders.length > 0;

    var locked = selection.filter((item: any) => {
        return item.lock
      })
    const lockedSelected = locked.length > 0;

    var noAccessGranted = selection.filter((item: any) => {
      return (item.fileType === "dir" && !item.accessGranted)
    })
    const accessGrantedSel = noAccessGranted.length === 0

    var offlineMode = this.props.fileExplorerType === "offlineFiles"

    var sreader = false;
    if (navigator.onLine
      && item.fileExtension.toLowerCase() === "pdf"
      && this.props.appPlatform === "electron") {
      sreader =  window.sreader.length > 0;
    }

    var isPdf = item.fileExtension.toLowerCase() === "pdf"

    var isOfficeCompatibilityDoc = this._isOfficeCompatibilityDoc(item.fileExtension)
    var isOfficeOnlineDoc = this._isOfficeOnlineDoc(item.fileExtension)

    var notWordDocs = selection.filter((item: any) => {
      if(item.fileExtension !== "doc"
        && item.fileExtension !== "docx") {

        return true;
      }
      return false
    })

    var notPowerPointDocs = selection.filter((item: any) => {
      if(item.fileExtension !== "ppt"
        && item.fileExtension !== "pptx") {

        return true;
      }
      return false
    })

    var saveAsPdfEnabled = notWordDocs.length === 0;
    var compareWordEnabled = notWordDocs.length === 0 && notPowerPointDocs.length === 0;

    var role: number = this.state.folderRole

    const contextualMenuProps: IContextualMenuProps = {
      directionalHint: DirectionalHint.bottomLeftEdge,
      styles: {root: {width: window.innerWidth}},
      items: [
        {
          key: 'openDocument',
          name: i18n.t('app:open'),
          onClick: this._openDocument.bind(this, id, revisionId, item.hash, item.fileName, item.fileExtension, item.lock, sreader, item, false),
          iconProps: { iconName: 'OpenFile' },
          disabled: item.wopiLock,
          hidden: !ActionsPermissions("editFile", role) || folderSelected || selectionCount > 1 || sreader || this.props.appPlatform !== ("electron" || "openfin")
        },
        {
          key: 'openDocumentReadOnly',
          name: i18n.t('app:openDocumentReadOnly'),
          onClick: this._openReadOnlyDocument.bind(this, id, revisionId, hash, item.fileName, item.fileExtension, item.lock, item),
          iconProps: { iconName: 'PageLock' },
          hidden: !ActionsPermissions("openDocumentReadOnly", role) || folderSelected || selectionCount > 1 || !isOfficeCompatibilityDoc || this.props.appPlatform !== ("electron" || "openfin")
        },
        {
          key: 'openDocumentOfficeOnline',
          name: i18n.t('app:openDocumentOfficeOnline'),
          onClick: this._openOfficeOnline.bind(this, id, "edit", item.lock, item.wopiLock),
          iconProps: { iconName: 'OfficeLogo' },
          disabled: item.lock && !item.wopiLock,
          hidden: !ActionsPermissions("openDocumentOfficeOnline", role) || folderSelected || selectionCount > 1 || !isOfficeOnlineDoc || offlineMode || this.props.maintenance
        },
        {
          key: 'viewDocumentOfficeOnline',
          name: 'View in Office Online',
          onClick: this._openOfficeOnline.bind(this, id, "view", item.lock, item.wopiLock),
          iconProps: { iconName: 'PageLock' },
          hidden: !ActionsPermissions("viewDocumentOfficeOnline", role) || folderSelected || selectionCount > 1 || !isOfficeOnlineDoc || offlineMode || this.props.maintenance || this.props.appPlatform === ("electron" || "openfin")
        },
        {
          key: 'openPdfWithSynergyReader',
          name: i18n.t('app:openPdfWithSynergyReader'),
          onClick: this._openDocument.bind(this, id, revisionId, hash, item.fileName, item.fileExtension, item.lock, true, item, false),
          iconProps: { iconName: 'OpenFile' },
          hidden: folderSelected || selectionCount > 1 || !sreader || offlineMode || this.props.maintenance
        },
        {
          key: 'openPdfWithSynergyReaderOnline',
          name: i18n.t('app:openPdfWithSynergyReader'),
          onClick: this._openPdfSynergyReaderOnline.bind(this, id, revisionId),
          iconProps: { iconName: 'OpenFile' },
          hidden: !isPdf || folderSelected || selectionCount > 1 || offlineMode || this.props.maintenance || this.props.appPlatform === ("electron" || "openfin")
        },
        {
          key: 'openPdfWithDefaultApp',
          name: i18n.t('app:openPdfWithDefaultApp'),
          onClick: this._openDocument.bind(this, id, revisionId, item.hash, item.fileName, item.fileExtension, item.lock, false, item, false),
          iconProps: { iconName: 'PDF' },
          disabled: item.lock,
          hidden: folderSelected || selectionCount > 1 || !sreader
        },
        {
          key: 'openFolder',
          name: i18n.t('app:openFolder'),
          onClick: this._openFolder.bind(this, item.id),
          iconProps: { iconName: 'FabricOpenFolderHorizontal' },
          disabled: !accessGrantedSel && !item.isFolderInPath,
          hidden: !folderSelected || selectionCount > 1 || offlineMode || this.props.maintenance
        },
        {
          key: 'goToFile',
          name: i18n.t('app:goToFile'),
          hidden: folderSelected || selectionCount > 1 || this.props.fileExplorerType === "fileExplorer" || this.props.fileExplorerType === "openFiles" || (offlineMode && (!this.props.isOnline || this.props.maintenance)),
          onClick: this._goToFile.bind(this, item),
          iconProps: { iconName: 'FabricOpenFolderHorizontal' },
        },
        {
          key: 'attachFile',
          name: i18n.t('app:attach') + (selectionCount === 1 ? '' : ' ' + selectionCount + i18n.t('app:items')),
          onClick: this._attachFile.bind(this, null),
          iconProps: { iconName: 'Attach' },
          hidden: !ActionsPermissions("attachFile", role) || folderSelected || selectionCount < 1 || offlineMode || this.props.maintenance || this.props.appPlatform !== ("electron" || "openfin")
        },
        {
          key: 'saveAsPdf',
          name: i18n.t('app:pdf'),
          hidden: selectionCount !== 1 || !saveAsPdfEnabled || os.name !== "Windows" || offlineMode || this.props.maintenance || this.props.appPlatform !== ("electron" || "openfin"),
          onClick: this._saveAsPdf.bind(this),
          iconProps: { iconName: 'PDF' }
        },
        {
          key: 'duplicateItem',
          name: i18n.t('app:duplicate') + (selectionCount === 1 ? '' : ' ' + selectionCount + i18n.t('app:items')),
          onClick: this._duplicateItem.bind(this),
          iconProps: { iconName: 'DuplicateRow' },
          hidden: !ActionsPermissions("duplicateItem", role) || selectionCount > 1 || offlineMode || this.props.maintenance
        },
        {
          key: 'shareFolder',
          name: i18n.t('app:sharingOptions'),
          onClick: this._shareFolder.bind(this, item),
          iconProps: { iconName: 'Share' },
          disabled: !accessGrantedSel,
          hidden: !ActionsPermissions("sharingOptions", role) || !folderSelected || selectionCount > 1 || offlineMode || this.props.maintenance
        },
        {
          key: 'copyPrivateShortcut',
          name: i18n.t('app:copyPrivateShortcutInternal'),
          onClick: this._copyPrivateShortcut.bind(this, item, "askToOpen"),
          iconProps: { iconName: 'Link' },
          hidden: selectionCount > 1 || offlineMode || this.props.maintenance
        },
        {
          key: 'copyPrivateShortcutName',
          name: i18n.t('app:copyHyperlinkname'),
          onClick: this._copyPrivateShortcutName.bind(this, item),
          iconProps: { iconName: 'PageLink' },
          hidden: selectionCount > 1 || offlineMode || item.type === "tag" || this.props.maintenance
        },
        {
          key: 'publicSharing',
          name: i18n.t('app:createPublicLink') + (selectionCount === 1 ? '' : i18n.t('app:for') + selectionCount + i18n.t('app:items')),
          onClick: this._createPublicLink.bind(this, this._selection.getSelection()),
          iconProps: { iconName: 'Globe' },
          disabled: folderSelected && !accessGrantedSel,
          hidden: selectionCount === 0 || offlineMode || this.props.maintenance
        },
        {
          key: 'divider_1',
          itemType: ContextualMenuItemType.Divider,
        },
        {
          key: 'tagItem',
          name: i18n.t('app:tag') + (selectionCount === 1 ? '' : ' ' + selectionCount + i18n.t('app:items')),
          onClick: () => {this.props.headerRef.setState({ showTagBulkDialog: true, tagsList: null, isCascade: false, newTag: [], savingTag:false }); this.props.headerRef.getAllTags()},
          iconProps: { iconName: 'Tag' },
          hidden: !ActionsPermissions("tagItem", role) || offlineMode || this.props.maintenance
        },
        {
          key: 'renameItem',
          name: i18n.t('app:rename'),
          disabled: lockedSelected || !accessGrantedSel,
          hidden: !ActionsPermissions("renameItem", role) || selectionCount > 1 || offlineMode || this.props.maintenance,
          onClick: () => this._showRenameDialog(item),
          iconProps: { iconName: 'Edit' }
        },
        {
          key: 'compare',
          name: i18n.t('app:compare'),
          hidden: !ActionsPermissions("compare", role) || selectionCount !== 2 || !compareWordEnabled || offlineMode || this.props.maintenance || this.props.appPlatform !== ("electron" || "openfin"),
          onClick: () => this.props.headerRef._confirmCompareWordDocs(),
          iconProps: { iconName: 'BranchCompare' }
        },
        {
          key: 'lockFile',
          name: i18n.t('app:lock'),
          onClick: this._lockDocument.bind(this, item.id),
          iconProps: { iconName: 'Lock' },
          hidden: !ActionsPermissions("lockFile", role) || item.wopiLock || item.lock || selectionCount > 1 || folderSelected || offlineMode || this.props.maintenance
        },
        {
          key: 'unlockFile',
          name: i18n.t('app:unlock'),
          onClick: this._unlockDocument.bind(this, item.id),
          iconProps: { iconName: 'Unlock' },
          hidden: !ActionsPermissions("unlockFile", role) || item.wopiLock || !item.lock || (item.lock && item.lock.id !== this.props.userData.user.id) || selectionCount > 1 || offlineMode || this.props.maintenance
        },
        {
          key: 'forceUnlockFile',
          name: i18n.t('app:forceUnlock'),
          onClick: this._forceUnlockDocument.bind(this, item.id),
          iconProps: { iconName: 'Unlock' },
          hidden: !ActionsPermissions("forceUnlockFile", role) || !item.lock || (!item.wopiLock && item.lock && item.lock.id === this.props.userData.user.id) || selectionCount > 1 || offlineMode || this.props.maintenance
        },
        {
          key: 'divider_2',
          itemType: ContextualMenuItemType.Divider,
          hidden: offlineMode || this.props.maintenance,
          itemProps: {
            styles: {
              divider: {
                display: folderSelected || this.props.appPlatform !== ("electron" || "openfin") ? "none" : "block"
              }
            }
          }
        },
        {
          key: 'divider_3',
          itemType: ContextualMenuItemType.Divider,
          hidden: offlineMode || this.props.maintenance,
          itemProps: {
            styles: {
              divider: {
                display: folderSelected ? "none" : "block"
              }
            }
          }
        },
        {
          key: 'deleteItem',
          name: i18n.t('app:delete') + (selectionCount === 1 ? '' : ' ' + selectionCount + i18n.t('app:items')),
          disabled: lockedSelected || !accessGrantedSel,
          onClick: () => this.props.headerRef._deleteItems(this._selection.getSelection()),
          hidden: !ActionsPermissions("deleteItem", role) || this.props.headerRef.state.isTrash || offlineMode || this.props.maintenance,
          iconProps: { iconName: 'Delete' }
        },
        {
          key: 'restoreItem',
          name: i18n.t('app:restore') + (selectionCount === 1 ? '' : ' ' + selectionCount + i18n.t('app:items')),
          disabled: lockedSelected,
          onClick: () => this.props.headerRef._restoreItems(),
          hidden: !this.props.headerRef.state.isTrash || offlineMode || this.props.maintenance,
          iconProps: { iconName: 'ArrangeSendToBack' }
        },
        {
          key: 'divider_4',
          itemType: ContextualMenuItemType.Divider,
          hidden: selectionCount > 1
        },
        {
          key: 'details',
          name: i18n.t('app:details'),
          onClick: () => this.setState({showMobileDetails: true}),
          iconProps: { iconName: 'Info' },
          hidden: selectionCount > 1
        },
      ],
      onDismiss: () => {
        this.setState({
          contextualMenuProps: undefined
        });
      }
    };

    if (index !== undefined && index > -1) {
      this.setState({
        contextualMenuProps: contextualMenuProps
      });
    }

    return false;
  }

  private _getDragDropEvents(): IDragDropEvents {
    return {
      canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => {
        return true;
      },
      canDragGroups: true,
      canDrag: (item?: any) => {
        return true;
      },
      onDragEnter: (item?: any, event?: DragEvent) => {
        if (this.state.draggedItem
          && item.fileType === "dir") {
          return "isBeingDragged";
        } else {
          return "";
        }
      },
      onDragLeave: (item?: any, event?: DragEvent) => {
        return;
      },
      onDrop: (item?: any, e?: DragEvent) => {
        if (this.state.draggedItem
          && item.fileType === "dir") {
          let event:any = e as Event;
          event.stopPropagation();
          event.preventDefault();
          
          var selItems = this._selection.getSelection();
          this._moveSelectedItems(selItems, item);
        } else if (this.props.appPlatform === ("electron" || "openfin")) {
          this.setState({draggedItem: null});
          this.props.onFileDrop(e)
        }
      },
      onDragStart: (item?: any, itemIndex?: number, selectedItems?: any[], event?: any) => {
        this.setState({draggedItem: item});
        if(this.props.fileExplorerType === "fileExplorer") {
          this.props.sidebarRef._draggedItem = this._selection.getSelection()

          if (this.props.appPlatform === "electron") {
            const { ipcRenderer } = window.require("electron");
            ipcRenderer.send('setDraggedItem', this._selection.getSelection())
          }
        }
      },
      onDragEnd: (item?: any, event?: DragEvent) => {
      }
    };
  }

  private _onListDragEnter = (item?: any) => {
    if (this.state.draggedItem
      && item.fileType === "dir") {
      return "isBeingDragged";
    } else {
      return "";
    }
  }

  private _onListDragLeave = () => {
    return
  }

  private _onListDrop = (item?: any, e?: any) => {

    if (this.state.draggedItem
      && item.fileType === "dir") {
      let event:any = e as Event;
      event.stopPropagation();
      event.preventDefault();

      var selItems = this._selection.getSelection();
      this._moveSelectedItems(selItems, item);
    } else if (this.props.appPlatform === ("electron" || "openfin")) {
      this.setState({draggedItem: null});
      this.props.onFileDrop(e)
    }
  }

  private _onListDragStart =  (item?: any) => {
    this.setState({draggedItem: item});
    if(this.props.fileExplorerType === "fileExplorer") {
      this.props.sidebarRef._draggedItem = this._selection.getSelection()

      if (this.props.appPlatform === "electron") {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.send('setDraggedItem', this._selection.getSelection())
      }
    }
  }

  private _onListDragEnd = (item?: any, event?: DragEvent) => {
  }

  private _isOfficeDoc(fileExtension) {
    var isOfficeDoc = officeExtensions.filter(ext => {
      return fileExtension === ext
    })

    return isOfficeDoc.length > 0;
  }

  private _isOfficeCompatibilityDoc(fileExtension) {
    var isOfficeCompatibilityDoc = officeCompatibilityExtensions.filter(ext => {
      return fileExtension === ext
    })

    return isOfficeCompatibilityDoc.length > 0;
  }

  private _isOfficeOnlineDoc(fileExtension) {
    var isOfficeOnlineDoc = officeOnlineExtensions.filter(ext => {
      return fileExtension === ext
    })

    return isOfficeOnlineDoc.length > 0;
  }

  private _openDocument(id, revisionId, hash, fileName, fileExtension, lock, sreader, item, openPdfWithWord) {

  }

  private _openReadOnlyDocument(id, revisionId, hash, fileName, fileExtension, lock, fileData) {

  }

  private _openOfficeOnline(fileId, action, lock, wopiLock, target?) {
    if(!lock && !wopiLock && action === "edit") this.props.updateItem(fileId, "lockingDocument", true)

    userService.getOfficeOnlineUrl(fileId, action).then((response) => {
      var url = response.data

      if (this.props.appPlatform === ("webapp" || "openfin")) {
        if(target === "self") window.open(url, "_self");
        else window.open(url, "_blank");
      } else {
        var electron = window.require('@electron/remote')
        electron.shell.openExternal(url)
        this.props.updateItem(fileId, "lockingDocument", false)
      }
    }).catch(error => {
      console.log(error)
      this.props.updateItem(fileId, "lockingDocument", false)
    })
  }

  private _openPdfSynergyReaderOnline(id, revisionId) {
    var url = process.env.REACT_APP_READER_URL + "/home/" + id + "/" + revisionId
    window.open(url, "_blank")
  }

  private _showZipNameDialog() {
    var showDialog = true;
    var selectionCount = this._selection.getSelectedCount();
    var fileName = Moment(new Date()).format('Y-MM-DD') + " Compressed folder";
    var createFileWarning = "";

    if(selectionCount === 1) {
      let items = this._selection.getSelection()
      console.log("Zip items", items)

      let folder: any = items.filter((item: any) => {return item.fileType === "dir"})[0]
      if(folder) fileName = folder.name
      let document: any = items.filter((item: any) => {return item.fileType === "file"})[0]
      if(document) fileName = document.name
    }

    var handleZipNameChange = (e, newValue) => {
      if(newValue.length <= 240) {
        createFileWarning = ""

        if(newValue && this._containsInvalidChars(newValue)) {
          createFileWarning= i18n.t('app:invalidChar') + " " + invalidChars.join("   ")
        }

        fileName = newValue
      } else if(newValue.length > 240) {
        createFileWarning = i18n.t('app:nameTooLong')
      }

      renderDialog();
    }

    var div = document.createElement('div');
    var renderDialog = () => {
      ReactDOM.render(
        <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: <div className='d-flex align-items-center'><Icon className='mr-2' {...getFileTypeIconProps({ extension: "zip", size: 32, imageFileType: 'svg' }) } />{'New zip file containing ' + (selectionCount === 1 ? "1 item" : selectionCount + " items")}</div>
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "creating-zip-file"
            }}
            onDismiss={() => {showDialog = false; renderDialog()}}
          >
            <div className="dialog-content-wrap">
              <p className='pb-2 m-0' style={{fontWeight:500}}>{i18n.t('app:parentFolder')}</p>
              <span><Icon className='mr-1' style={{writingMode:'vertical-rl'}} {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } /><span style={{verticalAlign:'top'}}>{this.props.headerRef.state.breadcrumbPath[this.props.headerRef.state.breadcrumbPath.length-1].text}</span></span>
              <p className='m-0 pt-2' style={{fontWeight:500}}>{i18n.t('app:filename')}</p>
              <form className="pt-2" name="form" onSubmit={ () => { this._zipItems(fileName); showDialog = false; renderDialog() }} >
                <TextField autoFocus underlined placeholder={i18n.t('app:filename')} name="newMessage" value={ fileName } onChange={ handleZipNameChange } suffix={".zip"} errorMessage={ createFileWarning } required />
              </form>

              <DialogFooter>
                <DefaultButton onClick={() => { showDialog = false; renderDialog();}} text="Cancel" />
                <PrimaryButton onClick={ () => {this._zipItems(fileName); showDialog = false; renderDialog() }} disabled={ !fileName.length || this._containsInvalidChars(fileName) }>
                  Create
                </PrimaryButton>
              </DialogFooter>
            </div>
          </Dialog>
        , div
      )
    }
    renderDialog()
  }

  private _zipItems(zipName) {
    if(!this.state.zippingItems) {
      this.startZipTimeout()

      let items = this._selection.getSelection()
      console.log("Zip items", items)

      let folders = items.filter((item: any) => {return item.fileType === "dir"})
      let documents = items.filter((item: any) => {return item.fileType === "file"})

      let foldersIds = folders.map((folder: any) => {return folder.id})
      let documentsIds = documents.map((document: any) => {return document.id})

      let zipDialogTitle = `Compressing items`

      this._isMounted && this.setState({
        zippingItems: true,
        showZipDialog: true,
        zipDialogTitle: zipDialogTitle
      })

      userService.zipItems(foldersIds, documentsIds, zipName).then((response) => {
        console.log("Zip items request success")
        
      }).catch(error => {
        console.log(error)

        this._isMounted && this.setState({zippingItems: false}, () => {
          this.stopZipTimeout()
        })

        if(error.status === 405) {
          this._isMounted && this.setState({
            zippingItems: false,
            showZipDialog: false,
            zipDialogTitle: ""
          })
        } else if(error.status === 406) {
          let alertMessageType = MessageBarType.error
          let alertMessageText = "ERROR: the selected items exceed the 5GB size limit. Please choose another items and try again."

          this._isMounted && this.setState({
            alertMessage: {
              type: alertMessageType,
              text: alertMessageText
            }
          })

          setTimeout(() => {
            this._isMounted && this.setState({ alertMessage: null })
          }, 4000)
        } else {
          let alertMessageType = MessageBarType.error
          let alertMessageText = "ERROR: could not zip your items. Please try again."

          this._isMounted && this.setState({
            alertMessage: {
              type: alertMessageType,
              text: alertMessageText
            }
          })

          setTimeout(() => {
            this._isMounted && this.setState({ alertMessage: null })
          }, 4000)
        }
      })
    } else {
      let alertMessageType = MessageBarType.warning
      let alertMessageText = "WARNING: already zipping items. Please wait for it to finish and try again."

      this._isMounted && this.setState({
        alertMessage: {
          type: alertMessageType,
          text: alertMessageText
        }
      })

      setTimeout(() => {
        this._isMounted && this.setState({ alertMessage: null })
      }, 4000)
    }
  }

  zippingItemsTimeout: any;

  private startZipTimeout = () => {
    this.zippingItemsTimeout = setTimeout(() => {
      if(this.state.zippingItems) {
        this.setState({zippingItems: false})

        let title = "ERROR: could not zip your items"
        let body = "There was an error zipping your items. Please try again."
        let messageData = {
          action: "zipError"
        };
        this.notifyMe(title, body, messageData)
      }
    }, 1000 * 60 * 60); // 1 hour
  }

  private stopZipTimeout() {
    clearTimeout(this.zippingItemsTimeout);
  }

  private _unzipFile(id, fileName) {
    if(!this.state.unZippingFile) {
      this.startUnzipTimeout()

      let unzipDialogTitle = `Unzipping file: ${fileName}`

      this._isMounted && this.setState({
        unZippingFile: true,
        showUnzipDialog: true,
        unzipDialogTitle: unzipDialogTitle
      })

      userService.unzipFile(id).then((response) => {
        console.log("Unzip file request success")
        
      }).catch(error => {
        console.log(error)

        this._isMounted && this.setState({unZippingFile: false}, () => {
          this.stopUnzipTimeout()
        })

        if(error.status === 405) {
          this._isMounted && this.setState({
            unZippingFile: false,
            showUnzipDialog: false,
            unzipDialogTitle: ""
          })
        } else if(error.status === 406) {
          let alertMessageType = MessageBarType.error
          let alertMessageText = "ERROR: your zip file exceeds the 5GB size limit. Please download it and unzip in your computer."

          this._isMounted && this.setState({
            alertMessage: {
              type: alertMessageType,
              text: alertMessageText
            }
          })

          setTimeout(() => {
            this._isMounted && this.setState({ alertMessage: null })
          }, 4000)
        } else {
          let alertMessageType = MessageBarType.error
          let alertMessageText = "ERROR: could not unzip your file. Please try again."

          this._isMounted && this.setState({
            alertMessage: {
              type: alertMessageType,
              text: alertMessageText
            }
          })

          setTimeout(() => {
            this._isMounted && this.setState({ alertMessage: null })
          }, 4000)
        }
      })
    } else {
      let alertMessageType = MessageBarType.warning
      let alertMessageText = "WARNING: already unzipping file. Please wait for it to finish and try again."

      this._isMounted && this.setState({
        alertMessage: {
          type: alertMessageType,
          text: alertMessageText
        }
      })

      setTimeout(() => {
        this._isMounted && this.setState({ alertMessage: null })
      }, 4000)
    }
  }

  unzippingFileTimeout: any;

  private startUnzipTimeout = () => {
    this.unzippingFileTimeout = setTimeout(() => {
      if(this.state.unZippingFile) {
        this.setState({unZippingFile: false})

        let title = "ERROR: could not unzip your file"
        let body = "There was an error unzipping your file. Please try again."
        let messageData = {
          action: "unzipError"
        };
        this.notifyMe(title, body, messageData)
      }
    }, 1000 * 60 * 60); // 1 hour
  }

  private stopUnzipTimeout() {
    clearTimeout(this.unzippingFileTimeout);
  }

  private _openInDesktop(item) {
    var url = process.env.REACT_APP_DRIVE_PROTOCOL + "://" + item.fileType + "?" + item.id;
    console.log("Open in Synergy Drive desktop", item)
    window.open(url, "_self")
  }

  private _editInDesktop(item) {
    var url = process.env.REACT_APP_DRIVE_PROTOCOL + "://" + item.fileType + "?" + item.id + "?openToEdit";
    console.log("Edit in Synergy Drive desktop", item)
    window.open(url, "_self")
  }

  private _viewInDesktop(item) {
    var url = process.env.REACT_APP_DRIVE_PROTOCOL + "://" + item.fileType + "?" + item.id + "?openReadOnly";
    console.log("View in Synergy Drive desktop", item)
    window.open(url, "_self")
  }

  private _lockDocument(id) {
    this.props.updateItem(id, "lockingDocument", true)
    userService.lockDocument(id).then(response => {
      this.setState({ showLockedDocDialog: false }, () => {
        this.props.updateItem(id, "lockingDocument", false)
      })
    }).catch(error => {
      this.props.updateItem(id, "lockingDocument", false)
      console.log(error)
    })
  }

  private _unlockDocument(id) {
    this.props.updateItem(id, "lockingDocument", true)
    userService.unlockDocument(id).then(response => {
      this.setState({ showLockedDocDialog: false }, () => {
        this.props.updateItem(id, "lockingDocument", false)
      });
    }).catch(error => {
      this.props.updateItem(id, "lockingDocument", false)

      if(error.status === 409) {
        userService.getDocument(id).then(response => {
          let item = response.data;
          if(this.props.userData
            && item.lock
            && this.props.userData.user.id === item.lock.id
            && item.lockInstanceId
            && userService.getInstanceId() !== item.lockInstanceId) {
              var revisionId = item.activeRevisionId;
              if(item.draft) revisionId = id + "-draft";

              var doc = {
                id: id,
                revisionId: revisionId,
                fileName: item.name,
                fileExtension: item.type,
                lock: item.lock,
                lockInstanceId: item.lockInstanceId,
                item: item
              }

              this.setState({
                lockedDocument: doc,
                showLockedDocDialog: true
              })
            }
        }).catch(error => {
            console.log(error)
        })
      } else {
        console.log(error)
      }
    })
  }

  private _forceUnlockDocument(id) {
    this.props.updateItem(id, "lockingDocument", true)
    userService.forceUnlockDocument(id).then(response => {
      this.setState({ showLockedDocDialog: false }, () => {
        this.props.updateItem(id, "lockingDocument", false)
      });
    }).catch(error => {
      this.setState({ showLockedDocDialog: false }, () => {
        this.props.updateItem(id, "lockingDocument", false)
      });
      console.log(error)
    })
  }

  private _downloadItems(selItems) {
    var folders:any = selItems.filter((item: any) => {
      return item.fileType === "dir"
    })

    if(folders.length === 0) this._downloadDocuments(selItems)
    else this._downloadAsZip(selItems)

  }

  private _downloadDocuments(selItems) {
    function uniq(a) {
        var prims = {"boolean":{}, "number":{}, "string":{}}, objs:any = [];
    
        return a.filter(function(item) {
            var type = typeof item;
            if(type in prims)
                return prims[type].hasOwnProperty(item) ? false : (prims[type][item] = true);
            else
                return objs.indexOf(item) >= 0 ? false : objs.push(item);
        });
    }

    var downloadsQueue = async (selItems) => {
      for (let selItem of Array.from(selItems).reverse()) {
        await new Promise((resolve) => {
          var item: any = selItem
          item.downloadStatus = "pending"

          var newList = [item].concat(this.state.downloadingFiles)
          newList = uniq(newList)

          this._isMounted && this.setState(prevState => ({
            downloadingFiles: newList
          }), () => {
            this.props.callbackFunction({ downloadingFiles: this.state.downloadingFiles })

            resolve(true);
          });
        });
      };
    }

    downloadsQueue(selItems).then(() => {

      var download = async (selItems) => {
        this.props.headerRef.showDownloadsCallout();

        for (var i=0; i<selItems.length; i++) {
          let selItem = selItems[i];

          let currentItem = this.state.downloadingFiles.filter(file => {
            return file.id === selItem.id
          })
          currentItem = currentItem[0]

          if(currentItem) currentItem.downloadStatus = "downloading";

          this._isMounted && this.setState({ downloadingFiles: this.state.downloadingFiles }, () => {
                this.props.callbackFunction({ downloadingFiles: this.state.downloadingFiles })
              })

          await new Promise((resolve) => {
            var id = selItem.id
            var revisionId = selItem.activeRevisionId
            if(selItem.draft) revisionId = selItem.id + "-draft";

            var currentVersion = selItem.revisions.filter(version => {
              return version.isActiveRevision
            })
            var selName = selItem.name + " v" + currentVersion[0].version + (selItem.draft ? " Draft": "") + "." + selItem.fileExtension

              userService.downloadOnlineDocument(id, revisionId)
                .then((response: any) => {
                  const byteArray = new Uint8Array(response.data);
                  const blob = new Blob([byteArray], {type: "application/octet-stream"});

                  var url = window.URL.createObjectURL(blob);

                  var anchorElem = document.createElement("a");
                  anchorElem.href = url;
                  anchorElem.download = selName;

                  document.body.appendChild(anchorElem);
                  anchorElem.click();

                  document.body.removeChild(anchorElem);

                  if(currentItem) currentItem.downloadStatus = "success";

                  this._isMounted && this.setState({ downloadingFiles: this.state.downloadingFiles }, () => {
                    this.props.callbackFunction({ downloadingFiles: this.state.downloadingFiles })
                  })

                  resolve(true);
                }).catch(error => {
                  console.log(error);
  
                  if(currentItem) currentItem.downloadStatus = "error";

                  this._isMounted && this.setState({ downloadingFiles: this.state.downloadingFiles }, () => {
                    this.props.callbackFunction({ downloadingFiles: this.state.downloadingFiles })
                  })

                  resolve(true);
                });
          });
        }
      };

      download(selItems)
    });
  }

  private _downloadAsZip = (selItems) => {
    var files:any = selItems.filter((item: any) => {
      return item.fileType === "file"
    })
    files = files.map((file: any) => {
      return file.id
    })
    files = Array.from(files)

    var folders:any = selItems.filter((item: any) => {
      return item.fileType === "dir"
    })
    folders = folders.map((folder: any) => {
      return folder.id
    })
    folders = Array.from(folders)

    function uniq(a) {
      var prims = {"boolean":{}, "number":{}, "string":{}}, objs:any = [];
      
      return a.filter(function(item) {
        var type = typeof item;
        if(type in prims)
        return prims[type].hasOwnProperty(item) ? false : (prims[type][item] = true);
        else
        return objs.indexOf(item) >= 0 ? false : objs.push(item);
      });
    }
    
    var currentDate = new Date();
    var timestamp = currentDate.getTime();
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    var fileDate = [year, month, day].join('-');
    var fileName = "Synergy Drive " + fileDate + ".zip";

    this.props.headerRef.showDownloadsCallout();
  
    var downloadItem:any = {
      id: timestamp,
      fileName: fileName,
      downloadStatus: "downloading"
    };

    var newList = [downloadItem].concat(this.state.downloadingFiles)
    newList = uniq(newList)

    this._isMounted && this.setState(prevState => ({
      downloadingFiles: newList
    }), () => {
      this.props.callbackFunction({ downloadingFiles: this.state.downloadingFiles })

      let currentItem = this.state.downloadingFiles.filter(item => {
        return item.id === downloadItem.id
      })[0]

      userService.downloadOnlineAsZip(files, folders)
      .then((response: any) => {
        const byteArray = new Uint8Array(response.data);
        const blob = new Blob([byteArray], {type: "application/octet-stream"});

        var url = window.URL.createObjectURL(blob);

        var anchorElem = document.createElement("a");
        anchorElem.href = url;
        anchorElem.download = fileName;

        document.body.appendChild(anchorElem);
        anchorElem.click();

        document.body.removeChild(anchorElem);

        if(currentItem) currentItem.downloadStatus = "success";

        this._isMounted && this.setState({ downloadingFiles: this.state.downloadingFiles }, () => {
          this.props.callbackFunction({ downloadingFiles: this.state.downloadingFiles })
        })
      }).catch(error => {
        console.log(error)

        if(currentItem) currentItem.downloadStatus = "error";

        this._isMounted && this.setState({ downloadingFiles: this.state.downloadingFiles }, () => {
          this.props.callbackFunction({ downloadingFiles: this.state.downloadingFiles })
        })
      })
    });
  }

  private _attachFile(revId?) {
    var selItems = this._selection.getSelection();

    this._isMounted && this.setState({
      showAttachedFiles: true,
      attachNameOption: localStorage.getItem("fileNamingPref") === "fileNameCustom" && selItems.length > 1 ?  'fileNameVersion' : localStorage.getItem("fileNamingPref") || 'fileNameVersion',
      attachName: '',
      attachNameError: '',
      attachNameSuccess: false
    })

    let filePaths: any = [];

    var download = async (files) => {
      for (var i=0; i<files.length; i++) {
        let selItem:any = files[i];
        selItem.outFile = null;

        if(this._containsInvalidChars(selItem.fileName)) {
          alert(i18n.t('app:theFile') + selItem.fileName + i18n.t('app:cannotBeAttached') +'\n\n' + invalidChars.join("   ") + '\n\n'+i18n.t('app:pleaseRename'))
          this._isMounted && this.setState({
            showAttachedFiles: false,
            attachedFiles: null,
            attachedFileVersion: null
          })
        } else {
          await new Promise((resolve, reject) => {
        
            this._isMounted && this.setState({
              attachedFiles: selItems,
              currentAttachFile: selItem,
              attachedFileVersion: revId
            })

            if(selItem && selItem.revisions) {
              var id = selItem.id;
              var revisionId = revId || selItem.activeRevisionId
              if (selItem.draft) revisionId = revId || selItem.id + "-draft";

              var selVersion = selItem.currentVersion;

              var currentVersion = selItem.revisions.filter(version => {
                return version.fileId === revisionId
              })[0]

              if(currentVersion && currentVersion.version !== "draft") {
                selVersion = currentVersion.version
              }

              var selName = selItem.name + " v" + selVersion + "." + selItem.fileExtension

              var savedPref = localStorage.getItem("fileNamingPref")
              if(savedPref) {
                var d = new Date(),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;

                var fileDate = [year, month, day].join('-');

                if (savedPref === "fileNameVersion") {
                  selName = selItem.name + " v" + selVersion + "." + selItem.fileExtension
                  this._isMounted && this.setState({fileDefaultName: savedPref})
                } if (savedPref === "fileName") {
                  selName = selItem.name + "." + selItem.fileExtension
                  this._isMounted && this.setState({fileDefaultName: savedPref})
                } else if (this.getCompanyName(selItem) && savedPref === "fileNameVersionDateCompany") {
                  selName = selItem.name + " v" + selVersion + " " + fileDate + " " + this.getCompanyName(selItem) + "." + selItem.fileExtension
                  this._isMounted && this.setState({fileDefaultName: savedPref})
                } else if (savedPref === "fileNameVersionDate") {
                  selName = selItem.name + " v" + selVersion + " " + fileDate + "." + selItem.fileExtension
                  this._isMounted && this.setState({fileDefaultName: savedPref})
                }
              }
            } else {
              this._isMounted && this.setState({
                showAttachedFiles: false
              })
            }

          })
        }
      }
    }
  }

  private _saveAsPdf() {
    this._isMounted && this.setState({
      showSaveAsPdf: true,
      attachNameOption: localStorage.getItem("fileNamingPref") || 'fileNameVersion',
      attachName: '',
      attachNameError: '',
      attachNameSuccess: false
    })

    var selItems = this._selection.getSelection();
    let filePaths: any = [];

    var download = async (files) => {
      for (var i=0; i<files.length; i++) {
        let selItem:any = files[i];

        if(this._containsInvalidChars(selItem.fileName)) {
          alert(i18n.t('app:theFile') + selItem.fileName + i18n.t('app:cannotBeSavedPDF') +'\n\n' + invalidChars.join("   ") + '\n\n'+i18n.t('app:pleaseRename'))
          this._isMounted && this.setState({
            showSaveAsPdf: false,
            saveAsPdfFile: null
          })
        } else {
          await new Promise((resolve, reject) => {
            selItem.outFile = null;
            selItem.name = selItem.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            this._isMounted && this.setState({
              saveAsPdfFile: selItem
            })

            if(selItem && selItem.revisions) {
              var id = selItem.id;
              var revisionId = selItem.activeRevisionId
              if(selItem.draft) revisionId = selItem.id + "-draft";

              var currentVersion = selItem.revisions.filter(version => {
                return version.isActiveRevision
              })
              var selName = selItem.name + " v" + currentVersion[0].version + (selItem.draft ? " Draft": "") + ".pdf"

              var savedPref = localStorage.getItem("fileNamingPref")
              if(savedPref) {
                var d = new Date(),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;

                var fileDate = [year, month, day].join('-');

                if (savedPref === "fileNameVersion") {
                  selName = selItem.name + " v" + currentVersion[0].version + (selItem.draft ? " Draft": "") + ".pdf"
                  this._isMounted && this.setState({fileDefaultName: savedPref})
                } if (savedPref === "fileName") {
                  selName = selItem.name + ".pdf"
                  this._isMounted && this.setState({fileDefaultName: savedPref})
                } else if (this.getCompanyName(selItem) && savedPref === "fileNameVersionDateCompany") {
                  selName = selItem.name + " v" + currentVersion[0].version + (selItem.draft ? " Draft": "") + " " + fileDate + " " + this.getCompanyName(selItem) + ".pdf"
                  this._isMounted && this.setState({fileDefaultName: savedPref})
                } else if (savedPref === "fileNameVersionDate") {
                  selName = selItem.name + " v" + currentVersion[0].version + (selItem.draft ? " Draft": "") + " " + fileDate + ".pdf"
                  this._isMounted && this.setState({fileDefaultName: savedPref})
                }
              }
            } else {
              this._isMounted && this.setState({
                showSaveAsPdf: false
              })
            }

          })
        }
      }
    }
  }

  private _onChangeAttachName = (ev:any, option: any) => {
    const { attachedFiles } = this.state;
    let filePaths: any = [];

    var rename = async (files) => {
      for (var i=0; i<files.length; i++) {

        let selItem:any = files[i];

        await new Promise((resolve, reject) => {
          var oldName = selItem.outFile;
          var fileName = oldName.replace(/^.*[\\/]/, '')

          var d = new Date(),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear();

          if (month.length < 2) 
              month = '0' + month;
          if (day.length < 2) 
              day = '0' + day;

          var fileDate = [year, month, day].join('-');

          var revisionId = this.state.attachedFileVersion || selItem.activeRevisionId
          if (selItem.draft) revisionId = this.state.attachedFileVersion || selItem.id + "-draft";
          var selVersion = selItem.currentVersion;

          var currentVersion = selItem.revisions.filter(version => {
            return version.fileId === revisionId
          })[0]

          if(currentVersion && currentVersion.version !== "draft") {
            selVersion = currentVersion.version
          }

          var selName = selItem.name + "." + selItem.fileExtension;
      
          if (option.key === "fileNameVersion") {
            selName = selItem.name + " v" + selVersion + "." + selItem.fileExtension
          } else if (option.key === "fileNameVersionDateCompany") {
            selName = selItem.name + " v" + selVersion + " " + fileDate + " " + this.getCompanyName(selItem) + "." + selItem.fileExtension
          } else if (option.key === "fileNameVersionDate") {
            selName = selItem.name + " v" + selVersion + " " + fileDate + "." + selItem.fileExtension
          }

          localStorage.setItem("fileNamingPref", option.key)
      
          var newName = oldName.replace(fileName, selName);

        })
      }
    }

    rename(attachedFiles).then(() => {
      /*userService.copyToClipboard(filePaths).then(() => {
        console.log("Copy to clipboard success")
      }).catch(error => console.log(error))*/
    })
  }

  private _onChangeAttachCustomName = (ev:any) => {
    ev.preventDefault();
    ev.stopPropagation();

    if(this.state.attachName.length === 0) {
      this._isMounted && this.setState({attachNameError: i18n.t('app:nameCannotBeEmpty'), attachNameSuccess: false})
      return;
    }
    else if (this._containsInvalidChars(this.state.attachName)) {
      this._isMounted && this.setState({attachNameError: i18n.t('app:nameContainsInvalidChars'), attachNameSuccess: false})
      return;
    }
    else this._isMounted && this.setState({attachNameError: '', attachNameSuccess: true})

    const { attachedFiles } = this.state;
    let filePaths: any = [];

    var rename = async (files) => {
      for (var i=0; i<files.length; i++) {

        let selItem:any = files[i];

        await new Promise((resolve, reject) => {
          
          var oldName = selItem.outFile;

          var selName = this.state.attachName;
          var parser = new UAParser.UAParser();
          var os = parser.getResult().os
          var oldNamePath = os.name === "Windows" ? oldName.split('\\') : oldName.split('/')
          var path = os.name === "Windows" ? oldNamePath.slice(0,oldNamePath.length-1).join('\\') : oldNamePath.slice(0,oldNamePath.length-1).join('/')
          var oldPath = this.state.attachName === '' ? oldName : path +'/'+ oldNamePath[oldNamePath.length-1]

          var newName = path +'/'+ selName

        })
      }
    }
    rename(attachedFiles).then(() => {
      /*userService.copyToClipboard(filePaths).then(() => {
        console.log("Copy to clipboard success")
      }).catch(error => console.log(error))*/
    })
  }

  private _onChangePdfCustomName = (ev:any) => {
    ev.preventDefault();
    ev.stopPropagation();

    if(this.state.attachName.length === 0) {
      this._isMounted && this.setState({attachNameError: i18n.t('app:nameCannotBeEmpty'), attachNameSuccess: false})
      return;
    }
    else if (this._containsInvalidChars(this.state.attachName)) {
      this._isMounted && this.setState({attachNameError: i18n.t('app:nameContainsInvalidChars'), attachNameSuccess: false})
      return;
    }
    else this._isMounted && this.setState({attachNameError: '', attachNameSuccess: true})

    const { saveAsPdfFile } = this.state;
    let filePaths: any = [];

    var rename = async (files) => {
      for (var i=0; i<files.length; i++) {

        let selItem:any = files[i];

        await new Promise((resolve, reject) => {
          
          var oldName = selItem.outFile;

          var selName = this.state.attachName;
          var parser = new UAParser.UAParser();
          var os = parser.getResult().os
          var oldNamePath = os.name === "Windows" ? oldName.split('\\') : oldName.split('/')
          var path = os.name === "Windows" ? oldNamePath.slice(0,oldNamePath.length-1).join('\\') : oldNamePath.slice(0,oldNamePath.length-1).join('/')
          var oldPath = this.state.attachName === '' ? oldName : path +'/'+ oldNamePath[oldNamePath.length-1]

          var newName = path +'/'+ selName
    

        })
      }
    }
    rename([saveAsPdfFile]).then(() => {
      /*userService.copyToClipboard(filePaths).then(() => {
        console.log("Copy to clipboard success")
      }).catch(error => console.log(error))*/
    })
  }

  private _onChangePdfName = (ev:any, option: any) => {
    const { saveAsPdfFile } = this.state;
    let filePaths: any = [];

    var rename = async (files) => {
      for (var i=0; i<files.length; i++) {

        let selItem:any = files[i];

        await new Promise((resolve, reject) => {
          var currentVersion = selItem.revisions.filter(version => {
            return version.isActiveRevision
          })
          
          var oldName = selItem.outFile;
          var fileName = oldName.replace(/^.*[\\/]/, '')

          var d = new Date(),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear();

          if (month.length < 2) 
              month = '0' + month;
          if (day.length < 2) 
              day = '0' + day;

          var fileDate = [year, month, day].join('-');
          
          var selName = selItem.name + ".pdf";
      
          if (option.key === "fileNameVersion") {
            selName = selItem.name + " v" + currentVersion[0].version + (selItem.draft ? " Draft": "") + ".pdf"
          } else if (option.key === "fileNameVersionDateCompany") {
            selName = selItem.name + " v" + currentVersion[0].version + (selItem.draft ? " Draft": "") + " " + fileDate + " " + this.getCompanyName(selItem) + ".pdf"
          } else if (option.key === "fileNameVersionDate") {
            selName = selItem.name + " v" + currentVersion[0].version + (selItem.draft ? " Draft": "") + " " + fileDate + ".pdf"
          }

          localStorage.setItem("fileNamingPref", option.key)
      
          var newName = oldName.replace(fileName, selName);

        })
      }
    }

    rename([saveAsPdfFile]).then(() => {
      /*userService.copyToClipboard(filePaths).then(() => {
        console.log("Copy to clipboard success")
      }).catch(error => console.log(error))*/
    })
  }

  private _onDragStart = (event) => {
    const { attachedFiles } = this.state;

    if (this.props.appPlatform === "electron") {
      const { ipcRenderer } = window.require("electron");

      var outFiles = attachedFiles.map(file => {
        return file.outFile
      })
      console.log(outFiles)

      if(event) {
        event.preventDefault();
        ipcRenderer.send('ondragstart', outFiles)
      }
    }
  }

  private _onDragPdfStart = (event) => {
    const { saveAsPdfFile } = this.state;

    if (this.props.appPlatform === "electron") {
      const { ipcRenderer } = window.require("electron");

      if(event) {
        event.preventDefault();
        ipcRenderer.send('ondragstart', [saveAsPdfFile.outFile])
      }
    }
  }

  private _duplicateItem = () => {
    var selItems = this._selection.getSelection();
    
    for (var i=0; i<selItems.length; i++) {
      let selItem:any = selItems[i];
      
      if(selItem.kind === "file") {
        this._isMounted && this.setState({
          duplicatingDocument: true
        })

        userService.duplicateFile(selItem.id)
          .then((response: any) => {
            this._isMounted && this.setState({
              duplicatingDocument: false
            })
          }).catch(error => {
            this._isMounted && this.setState({
              duplicatingDocument: false
            })
            console.error(error)
          });
        } else if(selItem.kind === "dir") {
          if(!this.state.copyPastingItems) {
            this._isMounted && this.setState({duplicatingFolder: true})

            let alertMessageType = MessageBarType.success
            let alertMessageText = "Duplicating folder. A new notification will be sent once the folder have been duplicated. You can continue using Synergy Drive in the meantime."

            this._isMounted && this.setState({
              alertMessage: {
                type: alertMessageType,
                text: alertMessageText
              }
            })

            setTimeout(() => {
              this._isMounted && this.setState({ alertMessage: null })
            }, 4000)

            this.startDuplicateTimeout()

            userService.duplicateFolder(selItem.id)
            .then((response: any) => {
              // Duplicating folder
            }).catch(error => {
              console.error(error)

              this._isMounted && this.setState({duplicatingFolder: false}, () => {
                this.stopDuplicateTimeout()
              })

              if(error.status !== 405) {
                let alertMessageType = MessageBarType.error
                let alertMessageText = "ERROR: could not duplicate your folder. Please try again."

                this._isMounted && this.setState({
                  alertMessage: {
                    type: alertMessageType,
                    text: alertMessageText
                  }
                })

                setTimeout(() => {
                  this._isMounted && this.setState({ alertMessage: null })
                }, 4000)
              }
            });
          }
        } else {
          let alertMessageType = MessageBarType.warning
          let alertMessageText = "WARNING: already duplicating folder. Please wait for it to finish and try again."

          this._isMounted && this.setState({
            alertMessage: {
              type: alertMessageType,
              text: alertMessageText
            }
          })

          setTimeout(() => {
            this._isMounted && this.setState({ alertMessage: null })
          }, 4000)
        }
    }
  }

  duplicatingFolderTimeout: any;

  private startDuplicateTimeout = () => {
    this.duplicatingFolderTimeout = setTimeout(() => {
      if(this.state.copyPastingItems) {
        this.setState({copyPastingItems: false})

        let title = "ERROR: could not duplicate your folder"
        let body = "There was an error duplicating your folder. Please try again."
        let messageData = {
          action: "duplicateFolderError"
        };
        this.notifyMe(title, body, messageData)
      }
    }, 1000 * 60 * 10); // 10 min
  }

  private stopDuplicateTimeout() {
    clearTimeout(this.duplicatingFolderTimeout);
  }

  private _copyPrivateShortcut = (selItem, action) => {
    var type: string = "",
      id: string = selItem.id

    if (selItem && selItem.fileType === "dir") {
      type = "folder"
    } else if (selItem && selItem.fileType === "file") {
      type = "file"
    }
    
    var link = ""

    if(type === "folder") {
      link = process.env.REACT_APP_DRIVE_URL+ "/files/" + id
    } else if(type === "file") {
      link = process.env.REACT_APP_DRIVE_URL+ "/path/" + id + (action !== "askToOpen" ? "/" + action : "")
    }

    this.copyLink(link)

    // let content = this._selection.getSelection()
    // let folders = content.filter((item: any) => {return item.kind === "dir"})
    // let documents = content.filter((item: any) => {return item.kind === "file"})

    let alertMessageType = MessageBarType.success
    let alertMessageText = `<span className="mr-1">${i18n.t('app:clipboard')}:</span>
      <span>${i18n.t('app:pathURLcopied')}</span>
    `

    this._isMounted && this.setState({
      alertMessage: {
        type: alertMessageType,
        text: alertMessageText
      }
    })

    setTimeout(() => {
      this._isMounted && this.setState({ alertMessage: null })
    }, 4000)
  }

  private _copyPrivateShortcutName = (selItem) => {
    var type: string = "",
      id: string = selItem.id

    if (selItem && selItem.fileType === "dir") {
      type = "folder"
    } else if (selItem && selItem.fileType === "file") {
      type = "file"
    }

    var link = ""

    if(type === "folder") {
      link = process.env.REACT_APP_DRIVE_URL+ "/files/" + id
    } else if(type === "file") {
      link = process.env.REACT_APP_DRIVE_URL+ "/path/" + id
    }

    var anchorElem = document.createElement("a");
    anchorElem.href = link;
    anchorElem.innerText = selItem.name + ' (' + (type === 'folder' ? i18n.t('app:lowerFolder') : selItem.fileExtension) + ')';
    anchorElem.style.fontFamily = '';
    anchorElem.style.fontSize = 'inherit';
    anchorElem.style.textDecorationLine = 'underline';
    document.body.appendChild(anchorElem);
    const linkElement = anchorElem;
    const range = document.createRange();
    range.selectNode(linkElement);
    const selection:any = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy')
    document.body.removeChild(anchorElem);
    //this.copyLink(link)

    // let content = this._selection.getSelection()
    // let folders = content.filter((item: any) => {return item.kind === "dir"})
    // let documents = content.filter((item: any) => {return item.kind === "file"})

    let alertMessageType = MessageBarType.success
    let alertMessageText = `<span className="mr-1">${i18n.t('app:clipboard')}:</span>
      <span>${i18n.t('app:pathURLcopiedName')}</span>
    `

    this._isMounted && this.setState({
      alertMessage: {
        type: alertMessageType,
        text: alertMessageText
      }
    })

    setTimeout(() => {
      this._isMounted && this.setState({ alertMessage: null })
    }, 4000)
  }

  private _createPublicLink = (selItems) => {
    if(!this.state.creatingPublicLink) {
      this.startPublicLinkTimeout()

      var files:any = selItems.filter((item: any) => {
        return item.fileType === "file"
      })
      files = files.map((file: any) => {
        return file.id
      })
      files = Array.from(files)

      var folders:any = selItems.filter((item: any) => {
        return item.fileType === "dir"
      })
      folders = folders.map((folder: any) => {
        return folder.id
      })
      folders = Array.from(folders)

      let publicLinkTitle = "Creating public link"

      this._isMounted && this.setState({
        creatingPublicLink: true,
        showPublicLinkDialog: true,
        publicLinkDialogTitle: publicLinkTitle
      })

      userService.createPublicLink(folders, files).then(response => {
        // Creating link
        console.log("Create public link request success")
      }).catch(error => {
        console.log(error)

        this._isMounted && this.setState({creatingPublicLink: false}, () => {
          this.stopPublicLinkTimeout()
        })

        if(error.status === 405) {
          this._isMounted && this.setState({
            creatingPublicLink: false,
            showPublicLinkDialog: false,
            publicLinkDialogTitle: ""
          })
        } else if(error.status === 406) {
          let alertMessageType = MessageBarType.error
          let alertMessageText = "ERROR: could not create public link. The selected folders and files exceed the 10GB limit. Please try again with another content."

          this._isMounted && this.setState({
            alertMessage: {
              type: alertMessageType,
              text: alertMessageText
            }
          })

          setTimeout(() => {
            this._isMounted && this.setState({ alertMessage: null })
          }, 4000)
        } else {
          let alertMessageType = MessageBarType.error
          let alertMessageText = "ERROR: could not create public link. There was an error creating your public link. Please try again."
          
          this._isMounted && this.setState({
            alertMessage: {
              type: alertMessageType,
              text: alertMessageText
            }
          })

          setTimeout(() => {
            this._isMounted && this.setState({ alertMessage: null })
          }, 4000)
        }
      })
    } else {
      let alertMessageType = MessageBarType.warning
      let alertMessageText = "WARNING: already creating public link. Please wait for it to finish and try again."

      this._isMounted && this.setState({
        alertMessage: {
          type: alertMessageType,
          text: alertMessageText
        }
      })

      setTimeout(() => {
        this._isMounted && this.setState({ alertMessage: null })
      }, 4000)
    }
  }

  creatingPublicLinkTimeout: any;

  private startPublicLinkTimeout = () => {
    this.creatingPublicLinkTimeout = setTimeout(() => {
      if(this.state.creatingPublicLink) {
        this.setState({creatingPublicLink: false})

        let title = "ERROR: could not create public link"
        let body = "There was an error creating your public link. Please try again."
        let messageData = {
          action: "publicLinkError"
        };
        this.notifyMe(title, body, messageData)
      }
    }, 1000 * 60 * 10); // 10 min
  }

  private stopPublicLinkTimeout() {
    clearTimeout(this.creatingPublicLinkTimeout);
  }

  private copyLink = (link) => {
    clipboardy.write(link)
  }

  private _askUserToInheritTags = async (folderId:string, functionType?:string, destination_?:string) => {
    var toggleTags = false;
    var tagsChoice = !folderId.includes(this.props.userData.repository.id) ? i18n.t('app:keepNewTags') : i18n.t('app:keepCurrentTags');
    var destination: string;
    if (destination_ && destination_.length > 0) destination = destination_
    else destination = this.props.headerRef.state.parentName.length > 0 ? this.props.headerRef.state.parentName : this.props.headerRef.state.breadcrumbPath[this.props.headerRef.state.breadcrumbPath.length-1].text;
    return await new Promise((resolve, reject) => {
      var showDialog = true;

      var inheritTags = () => {
        showDialog = false;
        renderDialog()
        resolve(true)
      }

      var cancelInherit = () => {
        showDialog = false;
        renderDialog()
        reject("Operation rejected by user");
        setTimeout(()=> this._focusSelElements(), 300)
      }

      var div = document.createElement('div');
      var renderDialog = () => {
        ReactDOM.render(
          <Dialog
              hidden={!showDialog}
              onDismiss={cancelInherit}
              dialogContentProps={{
                type: DialogType.normal,
                title: functionType === "copying" ? i18n.t('app:copyingItems') : functionType === "moving" ? i18n.t('app:movingItems') : i18n.t('app:uploadingItems')
              }}
              modalProps={{
                isBlocking: false,
                styles: { main: { maxWidth: 640 } },
                dragOptions: undefined,
                className: "ask-delete-item-dialog"
              }}
            >
              <div className="w-100">
              <p className='pb-2 m-0' style={{fontWeight:600}}>{i18n.t('app:destination')}:</p>
              <span><Icon className='mr-1' style={{writingMode:'vertical-rl'}} {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } /><span style={{verticalAlign:'top'}}>{destination}</span></span>
              <div className='d-flex mt-3 text-left'>
                <IconButton className='my-auto tags-chevron pl-0 w-100 h-auto text-left' style={{verticalAlign:'middle'}} iconProps={{iconName: toggleTags ? 'ChevronDown' : 'ChevronRight', color: 'black'}} onClick={()=>{toggleTags=!toggleTags;renderDialog()}}>
                  <span className='my-auto ml-0'>{tagsChoice +" ("+i18n.t('app:clickMoreOptions') + ")"}</span>
                </IconButton>
              </div>
              <div style={{transitionDuration: '250ms', maxHeight: toggleTags ? '1000px' : 0, overflow: 'hidden'}}>
              <ChoiceGroup defaultSelectedKey={!folderId.includes(this.props.userData.repository.id) ? "KEEP_NEW" : "KEEP_OLD"}
                  options={!folderId.includes(this.props.userData.repository.id) ?
                    [
                      { key: "KEEP_NEW", text: i18n.t('app:keepNewTags') },
                      { key: "KEEP_OLD", text: i18n.t('app:keepCurrentTags') },
                      { key: "NONE", text: i18n.t('app:noTags') }
                    ]:[
                      { key: "KEEP_OLD", text: i18n.t('app:keepCurrentTags') },
                      { key: "NONE", text: i18n.t('app:noTags') }
                    ]
                  }
                  label={i18n.t('app:tags')+":"}
                  required={true}
                  className="folder-permissions-choice mt-2"
                  onChange={ (e,v) => {this.setState({inheritTags: v!.key});tagsChoice=v!.text;renderDialog()} }
                  onKeyDown={(event)=> {
                    if (event.key === "Enter") {
                      inheritTags()
                    }
                  }}
                />
              </div>
                <p className="tip text-secondary mt-3">
                  <Icon iconName="Info" className="align-bottom mr-1" /> <span className='font-weight-bold'>{i18n.t('app:youCanPress')}</span> <span className='keyStyles'>Enter</span> {i18n.t('app:toConfirmSelection')}
                </p>
                
                <DialogFooter>
                  <DefaultButton onClick={cancelInherit} text={i18n.t('app:cancel')} />
                  <PrimaryButton autoFocus onClick={ inheritTags } text={functionType === "copying" ? i18n.t('app:paste') : functionType === "moving" ? i18n.t('app:move') : i18n.t('app:upload')} />
                </DialogFooter>
              </div>
            </Dialog>
          , div
        )
      }

      renderDialog();
    })
  }

  private _copyPasteItems(folders, documents, destination) {
    if(!this.state.copyPastingItems) {
      this._isMounted && this.setState({inheritTags: !destination.includes(this.props.userData.repository.id) ? "KEEP_NEW" : "KEEP_OLD"})
      this._askUserToInheritTags(destination, 'copying', destination.name).then(() => {
      this.startCopyPasteTimeout()

      let pasteDialogTitle = `Pasting: ${folders.length === 1 ? folders.length + " "+i18n.t('app:lowerFolder') : folders.length > 1 ? folders.length + " "+i18n.t('app:lowerFolders') : ""}  ${folders.length > 0 && documents.length > 0 ? " " + i18n.t('app:clipboardAnd') + " " : ""} ${documents.length === 1 ? documents.length + " "+i18n.t('app:lowerFile') : documents.length > 1 ? documents.length + " "+i18n.t('app:lowerFiles') : ""}`

      this._isMounted && this.setState({
        copyPastingItems: true,
        showPasteDialog: true,
        pasteDialogTitle: pasteDialogTitle
      })

      userService.copyPaste(folders, documents, destination, this.state.inheritTags).then(response => {
        // Copy pasting items
        clipboardy.write("")
      }).catch(error => {
        console.log(error)

        clipboardy.write("")

        this._isMounted && this.setState({copyPastingItems: false}, () => {
          this.stopCopyPasteTimeout()
        })

        if(error.status === 405) {
          this._isMounted && this.setState({
            copyPastingItems: false,
            showPasteDialog: false,
            pasteDialogTitle: ""
          })
        } else {
          let alertMessageType = MessageBarType.error
          let alertMessageText = "ERROR: could not copy your items. Please try again."

          this._isMounted && this.setState({
            alertMessage: {
              type: alertMessageType,
              text: alertMessageText
            }
          })

          setTimeout(() => {
            this._isMounted && this.setState({ alertMessage: null })
          }, 4000)
        }
      })
      }).catch(reject => {console.log(reject)})
    } else {
      let alertMessageType = MessageBarType.warning
      let alertMessageText = "WARNING: already copying items. Please wait for it to finish and try again."

      this._isMounted && this.setState({
        alertMessage: {
          type: alertMessageType,
          text: alertMessageText
        }
      })

      setTimeout(() => {
        this._isMounted && this.setState({ alertMessage: null })
      }, 4000)
    }
  }

  copyPastingItemsTimeout: any;

  private startCopyPasteTimeout = () => {
    this.copyPastingItemsTimeout = setTimeout(() => {
      if(this.state.copyPastingItems) {
        this.setState({copyPastingItems: false})

        let title = "ERROR: could not copy your items"
        let body = "There was an error copying your items. Please try again."
        let messageData = {
          action: "copyPasteError"
        };
        this.notifyMe(title, body, messageData)
      }
    }, 1000 * 60 * 10); // 10 min
  }

  private stopCopyPasteTimeout() {
    clearTimeout(this.copyPastingItemsTimeout);
  }

  private notifyMe(title, body, data) {
    if (Notification.permission !== 'granted')
      Notification.requestPermission();
    else {
      var notification: any = new Notification(title, {
          body: body,
          silent: true,
          icon: "./icons/png/512x512.png",
          data: data
      });

      console.log("Notification data", notification)

      notification.onclick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        console.log("Notification click", notification.data.action)

        if (notification.data && notification.data.action === "publicLinkCreated") {
          
          //var url = userService.getPublicLinkUrl(notification.data.token)

          //window.open(url, "_blank");
          
        }

        if (this.props.appPlatform === "electron") {
          console.log("Notification received in Electron app")

          var electron = window.require('@electron/remote')
          if(electron.getCurrentWindow().isVisible()) {
            electron.getCurrentWindow().focus()
          } else {
            electron.getCurrentWindow().show()
          }
        }

        if (notification.data && notification.data.action === "copyPasteSuccess") {
          history.push("/files/" + notification.data.parentId)
        }
      }

      if (notification.data && notification.data.action === "publicLinkCreated") {
        //var url = userService.getPublicLinkUrl(notification.data.token)
        //this.copyLink(url)
      }
    }
  }

  private _findInFolderTree = (item) => {
    this.props.sidebarRef.setState({selNav: "files"})

    var isRepo = this.props.userData.repositoryDetailsList.filter(repo => {
      console.log(repo.repository.id)
      return repo.repository.id === (item.parent_id || item.parentId)
    })[0]

    var folderTreeFocusId = item.fileType === "dir" ? item.id : item.parentId;

    if(isRepo) {
      this.props.sidebarRef._expandFolder(isRepo.id, 2, [isRepo.id], folderTreeFocusId)
    } else {
      var parentId = item.parent_id || item.parentId;
      userService.getPathFolders(parentId).then(response => {
        var pathFolders: any = []

        const sortPathFolders = (item) => {
          pathFolders.push(item)

          var child = response.data.filter(folder => {
            return folder.parentId === item.id
          })[0]

          if(child) {
            sortPathFolders(child)
          }
        }

        var repo = response.data.filter(item => !item.parentId)[0]
        if(repo) sortPathFolders(repo)
        
        var firstFolder = pathFolders[1]

        if(firstFolder) {
          var levels = pathFolders.length;
          var expandFolders = response.data.map(folder => { return folder.id })

          this.props.sidebarRef._expandFolder(firstFolder.id, levels, expandFolders, folderTreeFocusId)
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }

  private _goToFile = (item) => {
    var parentId = item.parent_id || item.parentId;
    history.push("/files/" + parentId + "?selItem=" + item.id)
  }
  
  private _showRenameDialog = (item) => {
    this._isMounted && this.setState({
      renameItemDialog: true,
      itemToEdit: item,
      newItemName: item.name,
      newItemTags: item.tags ? item.tags.join('; ') : null,
      renameWarning: ""
    })
  }

  private _containsInvalidChars = (str: string)  => {
    for(var i in invalidChars){
        var invalidChar = invalidChars[i];
        if (str.indexOf(invalidChar) > -1){
            return true;
        }

    }
    return false;
  }

  private  _renameItem = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { itemToEdit, newItemName } = this.state;

    if(this._containsInvalidChars(newItemName)) {
      // Invalid characters present
    } else if(newItemName.length > 0) {
      this._isMounted && this.setState({ renamingItem: true })
      if (itemToEdit.fileType === "file") {
        let id = itemToEdit.id;
          userService.renameDocument(id, newItemName).then((response:any) => {
            var file = this.props.selFile
            file.fileName = newItemName
            this.props.callbackFunction({selFile: file})
            this._isMounted && this.setState({
              itemToEdit: null,
              renamingItem: false
            });
          }).catch(error => {
            this._isMounted && this.setState({
              itemToEdit: null,
              renamingItem: false
            });
          });
      } else if (itemToEdit.fileType === "dir") {
        let id = itemToEdit.id;
        let newName = newItemName;
        // var file = this.props.selFile
        // file.fileName = newItemName
        userService.renameFolder(id, newName).then((response:any) => {
          this._isMounted && this.setState({
            itemToEdit: null,
            renamingItem: false
          });
        }).catch(error => {
          this._isMounted && this.setState({
            itemToEdit: null,
            renamingItem: false
          });
        });
      }
    }
  }

  private handleItemNameChange = (ev: any, newValue?: string) => {
    if (!newValue || newValue.length <= 240) {
      this.setState({ newItemName: newValue || ''}, () => {
        const { itemToEdit, newItemName } = this.state;
        const { items } = this.props;

        this.setState({ renameWarning: "" })

        if(newValue && this._containsInvalidChars(newValue)) {
          this.setState({ renameWarning: i18n.t('app:invalidChar') + " " + invalidChars.join("   ") })
        }

        var files = items.filter(item => {
          return item.fileType === "file";
        })

        for (let i = 0; i < files.length; i++) {
          if (files[i].fileName === newItemName + "." + itemToEdit.fileExtension && newItemName + "." + itemToEdit.fileExtension !== itemToEdit.fileName) {
            this.setState({ renameWarning: i18n.t('app:fileAlreadyExists') })
          }
        };

        var folders = items.filter(item => {
          return item.fileType === "dir";
        })

        for (let i = 0; i < folders.length; i++) {
          if (folders[i].name === newItemName && newItemName !== itemToEdit.name) {
            this.setState({ renameWarning: i18n.t('app:folderAlreadyExists') })
          }
        };
      });
    } else if (newValue.length > 240) {
      this.setState({ renameWarning: i18n.t('app:nameTooLong') })
    }
  }

  private _moveSelectedItems(items, destination) {
    this._isMounted && this.setState({inheritTags: !destination.id.includes(this.props.userData.repository.id) ? "KEEP_NEW" : "KEEP_OLD"})
    this._isMounted && this.setState({ movingItem: true });

    var destinationId = destination.id;
    var destinationName = destination.name;

    var documentList:any[] = items.filter((item: any) => {
      return item.fileType === "file"
    });

    var folderList:any[] = items.filter((item: any) => {
      return item.fileType === "dir"
    });

    var moveItems = async () => {

      // Delete documents
      if (documentList && documentList.length > 0) {
        await new Promise((resolve, reject) => {
          var documentsIds: any[] = [];
          for (let i = 0; i < documentList.length; i++) {
            let document = documentList[i];
            let id = document.id;
            documentsIds.push(id)
          };

          userService.moveDocuments(documentsIds, destinationId, this.state.inheritTags).then((response:any) => {
            resolve(true);
          }).catch(error => {
            reject(error)
          })

        });
      }
    
      // Delete folders
      for (let i = 0; i < folderList.length; i++) {
        await new Promise((resolve,reject) => {
          let folder = folderList[i];

          if(folder.id !== destinationId) {
            userService.moveFolders(folder.id, destinationId, this.state.inheritTags).then((response:any) => {
              resolve(true)
            }).catch(error => {
              reject(error)
            })
          } else {
            resolve(true)
          }
        });
      };
    }

    var moveDetails: string;
    if (folderList.length > 0 && documentList.length === 0) {
      moveDetails = folderList.length === 1 ? 'folder "' + folderList[0].name + '"' : folderList.length + ' folders';
    } else if (documentList.length > 0 && folderList.length === 0) {
      moveDetails = documentList.length === 1 ? 'file "' + documentList[0].fileName + '"' : documentList.length + ' files';
    } else if (folderList.length > 0 && documentList.length > 0) {
      moveDetails = folderList.length === 1 ? 'folder "' + folderList[0].name + '"' : folderList.length + ' folders';
      moveDetails = moveDetails  + ' and ' + (documentList.length === 1 ? 'file "' + documentList[0].fileName + '"' : documentList.length + ' files');
    } else {
      moveDetails = ""
    }


    let sameId = items.filter((item: any) => {
      return destination.id === item.id
    });

    if (this.props.appPlatform === ("electron" || "openfin")) {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.send('clearDraggedItem')
    }

    if (destination.id === items[0].parentId || sameId.length > 0) return;

    this._askUserToInheritTags(destination.id, 'moving', destination.name).then(() => {

      this._isMounted && this.setState({
        movingItemInfo: {
          destination: destinationName,
          moveDetails: moveDetails
        }
      })

      moveItems().then(() => {
        this._isMounted && this.setState({
          movingItem: false,
          movingItemInfo: null
        }, () => {
          this.setState({draggedItem: null});
          destination = null;
        });
      }).catch(error => {
        console.log(error)
        this._isMounted && this.setState({
          movingItem: false,
          movingItemInfo: null
        }, () => {
          this.setState({draggedItem: null});
          destination = null;
        });
        
        if (error.status === 409) {
          console.log(error)
          alert(error.data.message)
        }
      })
    }).catch(reject => {
      console.log(reject)
      this.setState({draggedItem: null, movingItem: false, movingItemInfo: null});
    })


  }

   private _showVersionHistory() {
    this.props.callbackFunction({ showRightPanel: true });
    
    this.props.detailsPanelRef.setState({
      activeTab: "versions"
    })  
  }
}

function onRenderDetailsHeader(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
      {defaultRender!({
        ...props
      })}
    </Sticky>
  );
}

function getPreviewURL(id:string, type:string, option:string, revision?:string) {
  const previewUrl = process.env.NODE_ENV === 'development' ? 
    'http://127.0.0.1:4300'
  :
    'https://synergy-drive-' + electronVersion + '.web.app'
  if (type === "wopi") {
    return (previewUrl + "/preview.html?id="+id+"&type="+type+"&wopi="+option)
  }
  else if (type === "email") {
    return(previewUrl + "/preview.html?id="+id+"&type="+type+"&api="+option.replace('https://','')+"&revision="+revision)
  }
  else {
    return(id)
  }
}