// ADMIN: 0
// EDITOR: 1
// RESTRICTED_EDITOR: 2
// VIEWER: 5
// PREVIEWER: 7
// "EXTERNAL": 10

export const ActionsPermissions = (action: string, role: number) => {
    //console.log("Check", '"' + action + '"', "action's permissions for role:", role)
    
    // Folder
    if(action === "createFolder" && role <= 2) return true
    if(action === "sharingOptions" && role <= 1) return true
    if(action === "downloadFolders" && role <= 1) return true

    // File
    if(action === "createOfficeDoc" && role <= 2) return true
    if(action === "editFile" && role <= 2) return true
    if(action === "saveVersionFromDraft" && role <= 2) return true
    if(action === "convertVersion" && role <= 2) return true
    if(action === "markAsExternalVersion" && role <= 2) return true
    if(action === "editVersionMessage" && role <= 2) return true
    if(action === "deleteDraft" && role <= 2) return true
    if(action === "deleteRevision" && role <= 2) return true
    if(action === "restoreRevision" && role <= 2) return true
    if(action === "append" && role <= 2) return true
    if(action === "openDocumentOfficeOnline" && role <= 2) return true
    if(action === "openDocumentReadOnly" && role <= 5) return true
    if(action === "viewDocumentOfficeOnline" && role <= 5) return true
    if(action === "previewDocument" && role <= 7) return true
    if(action === "openPdfWithSynergyReaderOnline" && role <= 7) return true
    if(action === "attachFile" && role <= 5) return true
    if(action === "saveAsPdf" && role <= 2) return true
    if(action === "compare" && role <= 5) return true
    if(action === "lockFile" && role <= 2) return true
    if(action === "unlockFile" && role <= 2) return true
    if(action === "forceUnlockFile" && role <= 2) return true
    if(action === "saveLocalFile" && role <= 2) return true
    if(action === "removeLocalFiles" && role <= 2) return true
    if(action === "viewFolderPermissions" && role <= 2) return true
    if(action === "viewFileDetails" && role <= 5) return true
    if(action === "viewFileVersions" && role <= 5) return true
    if(action === "viewFileActivity" && role <= 5) return true

    // Folders and files
    if(action === "upload" && role <= 2) return true
    if(action === "renameItem" && role <= 2) return true
    if(action === "duplicateItem" && role <= 2) return true
    if(action === "deleteItem" && role <= 2) return true
    if(action === "restore" && role <= 2) return true
    if(action === "createPublicLink" && role <= 1) return true
    if(action === "downloadItems" && role <= 2) return true
    if(action === "clipboard" && role <= 2) return true
    if(action === "viewTags" && role <= 5) return true
    if(action === "tagItem" && role <= 2) return true
    if(action === "removeTag" && role <= 2) return true
    if(action === "viewComments" && role <= 5) return true
    if(action === "addComment" && role <= 2) return true
    if(action === "removeComment" && role <= 2) return true

    return false;
}