import * as React from 'react';
import { history } from '../_helpers';
import Moment from 'moment';
import "moment/min/locales";
import { Resizable } from 're-resizable';
import { ShimmeredDetailsList } from '@fluentui/react/lib/ShimmeredDetailsList';
import { DetailsListLayoutMode, IDetailsHeaderProps, Selection, SelectionMode, IColumn, ConstrainMode } from '@fluentui/react/lib/DetailsList';
import { IRenderFunction } from '@fluentui/react/lib/Utilities';
import { TextField } from '@fluentui/react/lib/TextField';
import { ChoiceGroup } from '@fluentui/react/lib/ChoiceGroup';
import { Icon } from '@fluentui/react/lib/Icon';
import { IconButton } from '@fluentui/react/lib/Button';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { Stack } from '@fluentui/react/lib/Stack';
import { Sticky, StickyPositionType } from '@fluentui/react/lib/Sticky';
import { IPivotItemProps } from '@fluentui/react/lib/Pivot';
import { getFileTypeIconProps, FileIconType } from '@fluentui/react-file-type-icons';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { ActionButton, Checkbox, SearchBox, SelectableOptionMenuItemType, TooltipHost, IContextualMenuProps, ContextualMenu, DirectionalHint, Callout, SwatchColorPicker, Text } from '@fluentui/react';
import { FluentProvider, SelectTabEvent, Tab, TabList, webLightTheme } from '@fluentui/react-components';

import activityMessages from '../_data/activity-messages.json';
import { ActivityDetails } from '../_components/ActivityDetails';
import { userService } from '../_services';
import { FolderRole } from './FolderRole';
import { ActionsPermissions } from './ActionsPermissions';
import RecursiveProperty from './RecursiveProperty';

import i18n from "i18next";
import isElectron from 'is-electron';
import Cookies from 'js-cookie'
import { NumericFormat } from 'react-number-format';

const officeOnlineExtensions = [
  "docx",
  "xlsx",
  "pptx",
  "wopitest",
  "wopitestx"
]

const previewExtensions = [
  "docx",
  "doc",
  "pptx",
  "ppt",
  "xlsm",
  "xlsx",
  "xls",
  "pdf",
  "png",
  "jpg",
  "jpeg",
  "svg",
  "txt",
  "email"
]

function pickTextColorBasedOnBgColor(bgColor, lightColor, darkColor) {
  var color = bgColor.replace('rgb(', '').replace(')', '').split(',');
  var r = color[0]
  var g = color[1]
  var b = color[2]
  return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
    darkColor : lightColor;
}

export interface Props {
  match: {
    url: string
  }
}

export interface IDocument {
  fileId: string;
  name: string;
  fileExtension: string;
  value: string;
  icon: string;
  fileType: string;
  fullPath: string;
  repo: string;
  modifiedBy: any;
  dateModified: string;
  dateModifiedValue: number;
  fileSize: string;
  fileSizeRaw: number;
  lock: boolean;
  lockInstanceId: string;
  wopiLock: boolean;
  author: any;
  timestamp: number;
  version: any;
  major: string;
  minor: string;
  external: boolean;
  isActiveRevision: boolean;
  deleted: boolean;
  message: string;
  type: string;
}

export interface DetailsPanelState {
  repoData: any;
  showRightPanel: boolean;
  panelHeight: any;
  panelWidth: any;
  folderRole: number;
  roleName: string;
  selFile: any;
  settingItemDetails: boolean;
  searchInFileTerms: string;
  searchInFileInit: boolean;
  searchingInFile: boolean;
  setSearchInFileFocus: boolean;
  calculatingSize: boolean;
  showAdminsList: boolean;
  showUsersList: boolean;
  showExternalUsersList: boolean;
  showGroupsList: boolean;
  restoringRevision: boolean;
  convertingRevision: boolean;
  revertingVersion: boolean;
  appendingVersion: boolean;
  showSaveDraftDialog: boolean;
  saveDraftData: any;
  newSavedDraftMessage: string;
  savingDraft: boolean;
  deletingDraft: boolean;
  deletingRevision: boolean;
  undoingDeleteRevision: boolean;
  newTag: string;
  savingTag: boolean;
  savingBookmark: boolean;
  newComment: string;
  savingComment: boolean;
  activeTab: string;
  versionsColumns: IColumn[];
  activityColumns: IColumn[];
  editVersionMessage?: boolean;
  editVersionMessageId?: string;
  selVersion?: any;
  newVersionMessage?: string;
  savingVersionMessage: boolean;
  saveAsMajorVersion: string;
  downloadingFiles: boolean;
  compareCountEnabled: boolean;
  comparingDocs: boolean;
  compareDocs: any;
  showCompareDocs: boolean;
  mergedDoc: any;
  toolbarHeight: number;
  tagColor: any;
  showColorPick: boolean;
  isCascade: boolean;
  removeInCascadeDialog: boolean;
  tagIdToDelete: string;
  tagsList: any;
  contextualMenuProps?: IContextualMenuProps;
}

export class DetailsPanel extends React.Component<Props, DetailsPanelState> {
  private _isMounted: boolean;
  private _selection: Selection = new Selection({
    onSelectionChanged: () => {
      this._isMounted && this.setState({
        compareCountEnabled: this._selection.getSelectedCount() === 2
      });
    }
  });
  private _windowWidth: number;
  private canGetDetails: boolean;
  private getTags: string;
  private tagColors: any;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this._windowWidth = window.innerWidth;
    this.canGetDetails = true;
    this.getTags = '';

    this.props = props

    const versionsColumns: IColumn[] = [
      {
        key: 'date',
        name: i18n.t('app:versionsByDate'),
        fieldName: 'dateModifiedValue',
        isSorted: true,
        minWidth: 120,
        isResizable: false,
        isMultiline: true,
        onColumnClick: this._onVersionsColumnClick,
        data: 'number',
        onRender: (item: IDocument) => {
          return (
            <div>

              { item.version === "draft" ?
                <div className='d-flex align-items-center'>
                  <span className="badge badge-warning p-1">{i18n.t('app:draft')}</span> <span className="date ml-2">{ Moment(item.timestamp).format(i18n.t('app:dateFormat')) }</span>
                </div>
              : item.minor !== "00" && item.external?
                <div className="d-flex align-items-center mb-1">
                  <span className="badge badge-success-light p-1">{item.version}</span> <span className="date ml-2">{ Moment(item.timestamp).format(i18n.t('app:dateFormat')) }</span>
                </div>
            
              : item.minor === "00" && item.external?
                <div className="d-flex align-items-center mb-1">
                  <span className="badge badge-success p-1">{item.version}</span> <span className="date ml-2">{ Moment(item.timestamp).format(i18n.t('app:dateFormat')) }</span>
                </div>

              : item.minor !== "00" && !item.external?
                <div className="d-flex align-items-center mb-1">
                  <span className="badge badge-primary-light p-1">{item.version}</span> <span className="date ml-2">{ Moment(item.timestamp).format(i18n.t('app:dateFormat')) }</span>
                </div>
              
              :
                <div className="d-flex align-items-center mb-1">
                  <span className="badge badge-primary p-1">{item.version}</span> <span className="date ml-2">{ Moment(item.timestamp).format(i18n.t('app:dateFormat')) }</span>
                </div>
              }

              <p className="m-0 mt-1 p-0">
                <span className="font-weight-bold author">{item.author.name}</span>: <span className="ml-1">{ item.message }</span>
              </p>
            </div>
          )
        },
        isPadded: false
      }
    ];

    const activityColumns: IColumn[] = [
      {
        key: 'date',
        name: i18n.t('app:activityByDate'),
        fieldName: 'date',
        isSorted: true,
        isSortedDescending: true,
        minWidth: 120,
        isResizable: false,
        isMultiline: true,
        onColumnClick: this._onActivityColumnClick,
        data: 'number',
        onRender: (item: any) => {
          return (
            <div>
              <span className="date">{ Moment(item.date).format(i18n.t('app:dateFormat')) }</span>
              <p className="m-0 mt-1 p-0">
                <span className="font-weight-bold author">{item.user}</span>: <span className="ml-1">{ item.action }</span>
                <span className="ml-1"><ActivityDetails item={ item } /></span>
              </p>
            </div>
          )
        },
        isPadded: false
      }
    ];

   var panelWidth = "420px"
   if (typeof(localStorage.getItem("detailsPanelPrefs")) === "string") {
      var panelPrefs = JSON.parse(localStorage.getItem("detailsPanelPrefs") || "[]");
      panelWidth = panelPrefs.width
   }

    this.state = {
      repoData: props.repoData,
      showRightPanel: props.showRightPanel,
      panelHeight: 'auto',
      panelWidth: panelWidth,
      folderRole: 10,
      roleName: "",
      selFile: props.selFile,
      settingItemDetails: false,
      searchInFileTerms: "",
      searchInFileInit: false,
      searchingInFile: false,
      setSearchInFileFocus: false,
      calculatingSize: true,
      showAdminsList: true,
      showUsersList: true,
      showExternalUsersList: true,
      showGroupsList: true,
      restoringRevision: false,
      convertingRevision: false,
      revertingVersion: false,
      appendingVersion: false,
      showSaveDraftDialog: false,
      saveDraftData: null,
      newSavedDraftMessage: i18n.t('app:minorVersion'),
      savingDraft: false,
      deletingDraft: false,
      deletingRevision: false,
      undoingDeleteRevision: false,
      newTag: '',
      savingTag: false,
      savingBookmark: false,
      newComment: '',
      savingComment: false,
      activeTab: 'info',
      versionsColumns: versionsColumns,
      activityColumns: activityColumns,
      editVersionMessage: false,
      editVersionMessageId: "",
      selVersion: null,
      newVersionMessage: "",
      savingVersionMessage: false,
      saveAsMajorVersion: 'minor',
      downloadingFiles: false,
      compareCountEnabled: false,
      comparingDocs: false,
      compareDocs: null,
      showCompareDocs: false,
      mergedDoc: null,
      toolbarHeight: 89,
      tagColor: null,
      showColorPick: false,
      isCascade: false,
      removeInCascadeDialog: false,
      tagIdToDelete: '',
      tagsList: null,
      contextualMenuProps: undefined,
    };
  }

  public componentDidMount() {
    this._isMounted = true;

    if (this.props.userData && this.props.fileExplorerType === "fileExplorer" && this.props.folderId) {
      if(this.props.userData && this.props.foldersList) this.getFolderRole(this.props.folderId, this.props.userData, this.props.foldersList)
      this._setNavFolder(this.props.folderId)
    } else {
      this._isMounted && this.setState({
        selFile: null
      })
    }

    window.addEventListener('resize', this._onWindowResize);
  }

  public componentDidUpdate(prevProps, prevState) {
    if(this.props.repoData !== prevProps.repoData) {
      this._isMounted && this.setState({repoData: this.props.repoData})
    }

    if(this.props.repoUsers !== prevProps.repoUsers) {
      var selItem = this.state.selFile;

      if(selItem && selItem.fileType === "dir") {
        this._getFolderExtraDetails(selItem)
      }
    }

    if(this.state.selFile !== prevState.selFile) {      
      if(this.state.selFile && this.state.selFile.fileType === "dir" && !this.state.selFile.accessGranted) {
        this._isMounted && this.setState({ activeTab: "info" })
      }
    }

    if(this.state.activeTab !== prevState.activeTab
      && this.state.selFile === prevState.selFile) {    
      if (this.state.selFile && this.state.selFile.fileType === "file") {
        this._isMounted && this.setState({setSearchInFileFocus: false})
        this._getFileExtraDetails(this.state.selFile)
      } else if (this.state.selFile && (this.state.selFile.fileType === "dir" || this.state.selFile.fileType === "repo" || this.state.selFile.fileType === "trash")) {
        this._getFolderExtraDetails(this.state.selFile)
      }  
    }
      
    if(this.props.fileExplorerType !== prevProps.fileExplorerType) {
      if (this.props.fileExplorerType === "fileExplorer" && this.props.folderId) {
        if(this.props.userData && this.props.foldersList) this.getFolderRole(this.props.folderId, this.props.userData, this.props.foldersList)
        this._setNavFolder(this.props.folderId)
      } else {
        this._isMounted && this.setState({
          selFile: null
        })
      }
    }

    if(this.props.userData !== prevProps.userData) {
      if (this.props.fileExplorerType === "fileExplorer" && this.props.folderId) {
        if(this.props.userData && this.props.foldersList) this.getFolderRole(this.props.folderId, this.props.userData, this.props.foldersList)
        this._setNavFolder(this.props.folderId)
      }
    }

    if(this.props.userData && this.props.foldersList && !prevProps.foldersList) {
      if (this.props.fileExplorerType === "fileExplorer" && this.props.folderId) {
        this.getFolderRole(this.props.folderId, this.props.userData, this.props.foldersList)
        this._setNavFolder(this.props.folderId)
      }
    }

    if(this.props.folderId !== prevProps.folderId
      && this.props.fileExplorerType === "fileExplorer") {
      this.setState({selFile: null, activeTab: 'info'}, () => {
        if(this.props.userData && this.props.foldersList) this.getFolderRole(this.props.folderId, this.props.userData, this.props.foldersList)
        this._setNavFolder(this.props.folderId)
      })
    }

    if(this.props.newFolder !== prevProps.newFolder) {
      if(this.props.newFolder && this.props.newFolder.id === this.props.folderId) {
        if(this.props.userData && this.props.foldersList) this.getFolderRole(this.props.newFolder.id, this.props.userData, this.props.foldersList)
        this._setNavFolder(this.props.newFolder.id)
      }
    }

    if(this.props.selFile !== prevProps.selFile) {
      var savedActiveTab = localStorage.getItem("detailsPanelActiveTab")
      var activeTab = savedActiveTab ? savedActiveTab : "info"

      if(this.props.selFile && this.props.userData && this.props.searchType !== "tag") { 
        var permissionsId = this.props.selFile.fileType === "dir" ? this.props.selFile.id : this.props.selFile.parent_id || this.props.selFile.parentId
        this.getFolderRole(permissionsId, this.props.userData, this.props.foldersList || [])
      }

      if((this.props.selFile && prevProps.selFile
        && (this.props.selFile.fileType !== prevProps.selFile.fileType
        || (activeTab === "annotations" && this.props.selFile.fileExtension !== prevProps.selFile.fileExtension)
        || (activeTab === "share" && this.props.selFile.fileType !== "dir" && this.props.selFile.fileType !== "repo")))
        || (!this.props.selFile || !prevProps.selFile)
        ) {
        activeTab = "info"
        localStorage.removeItem("detailsPanelActiveTab")
      }

      var searchQuery = this.props.searchQuery;
      if(this.props.searchType === "fullSearch" && this.props.selFile && searchQuery) {
        const params = new URLSearchParams(searchQuery);
        var textQuery = params.get("text");
        if(textQuery) {
          activeTab = "coincidences"
          this._isMounted && this.setState({searchInFileInit: false})
        }
      }

      if(this.props.selFile && this.props.selFile.fileType === ("dir" || "repo")) this._isMounted && this.setState({ calculatingSize: true })

      this._isMounted && this.setState({
        activeTab: activeTab,
        selFile: this.props.selFile,
        tagColor: null
      })
      this._setSelItem();

      this._selection.setAllSelected(false)
    }

    if(this.props.showRightPanel !== prevProps.showRightPanel) {
      this._isMounted && this.setState({showRightPanel: this.props.showRightPanel})
    }

  }

  public componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this._onWindowResize, false);
  }

  private getStoredData = (name: string) => {
    if(this.props.appPlatform=== "electron") {
        return localStorage.getItem(name)
    } else {
        var cookie = Cookies.get(name)
        return cookie
    }
  }

  private _onWindowResize = () => {
    var toolbar : HTMLDivElement | null  = document.querySelector('.toolbar');
    this.setState({toolbarHeight: toolbar!.offsetHeight})
    if (window.innerWidth <= 980 && window.innerWidth < this._windowWidth) {
      this.setState({showRightPanel: false});
    }
    this._windowWidth = window.innerWidth;
  }

  private _getChildren(folderId) {
    var directFoldersCount = 0;
    var totalFoldersCount = 0;
    var directFilesCount = 0;
    var totalFilesCount = 0;
    var totalSize = 0;

    var documents = this.props.items.filter(item => {
      return item.kind === "file"
    })

    var folders = this.props.items.filter(item => {
      return item.kind === "dir"
    })

    let directChildrenFiles = documents.filter(item => {
      return (item.parentId === folderId && !item.trash)
    })
    directFilesCount = directFilesCount + directChildrenFiles.length;
    totalFilesCount = totalFilesCount + directChildrenFiles.length;

    for (let i = 0; i < directChildrenFiles.length; i++) {
      let child = directChildrenFiles[i];
      totalSize = totalSize + child.size;
    }

    let childrenFolders = folders.filter(item => {
      return item.parentId === folderId
    })
    if(childrenFolders && childrenFolders.length > 0) {
      for (let i = 0; i < childrenFolders.length; i++) {
        if(!childrenFolders[i].trash)
        directFoldersCount = directFoldersCount + 1;
      }
    }

    var getChildren = (id) => { 
      let childrenFolders = folders.filter(item => {
        return item.parentId === id
      })

      if(childrenFolders && childrenFolders.length > 0) {
        for (let i = 0; i < childrenFolders.length; i++) {
          if(!childrenFolders[i].trash)
          totalFoldersCount = totalFoldersCount + 1;

          let totalChildrenFiles = documents.filter(item => {
            return (item.parentId === childrenFolders[i].id && !item.trash)
          })
          totalFilesCount = totalFilesCount + totalChildrenFiles.length;

          for (let i = 0; i < totalChildrenFiles.length; i++) {
            let child = totalChildrenFiles[i];
            totalSize = totalSize + child.size;
          }

          getChildren(childrenFolders[i].id)
        }
      }

      if (id && id.indexOf("-trash") !== -1) {
        let trashFolders = folders.filter(item => {
          return item.trash
        })

        if(trashFolders && trashFolders.length > 0) {
          for (let i = 0; i < trashFolders.length; i++) {
            if(trashFolders[i].trash)
            directFoldersCount = directFoldersCount + 1;
          }
        }

        let trashFiles = documents.filter(item => {
          return item.trash
        })

        if(trashFiles && trashFiles.length > 0) {
          for (let i = 0; i < trashFiles.length; i++) {
            if(trashFiles[i].trash)
            directFilesCount = directFilesCount + 1;
          }
        }
      }

      return {
        directFoldersCount: directFoldersCount,
        totalFoldersCount: totalFoldersCount,
        directFilesCount: directFilesCount,
        totalFilesCount: totalFilesCount,
        totalSize: totalSize
      }
    }

    return getChildren(folderId)
  }

  private getFolderRole = async(folderId, userData, foldersList) => {
    var role = await FolderRole(folderId, userData, foldersList)

    var roleName: string = ""

    if(role === 0) roleName = i18n.t('app:admin')
    if(role === 1) roleName = i18n.t('app:editor')
    if(role === 2) roleName = i18n.t('app:restrictedEditor')
    if(role === 5) roleName = i18n.t('app:viewer')
    if(role === 7) roleName = i18n.t('app:previewer')
    if(role === 10) roleName = i18n.t('app:external')
    if(role === 99) roleName = i18n.t('app:folderInPathOnly')

    this._isMounted && this.setState({
      folderRole: role,
      roleName: roleName
    })
  }

  public render() {
    const { showRightPanel, selFile, settingItemDetails, searchInFileTerms, searchInFileInit, searchingInFile, setSearchInFileFocus, calculatingSize, showAdminsList, showUsersList, showExternalUsersList, showGroupsList, restoringRevision, convertingRevision, revertingVersion, appendingVersion, deletingRevision, undoingDeleteRevision, showSaveDraftDialog, saveDraftData, newSavedDraftMessage, savingDraft, deletingDraft, savingBookmark, newComment, savingComment, activeTab, versionsColumns, activityColumns, editVersionMessage, selVersion, newVersionMessage, savingVersionMessage, compareCountEnabled, contextualMenuProps } = this.state;

    var userData = this.props.userData;

    var selectionMode = SelectionMode.none;
    var compareWordDocsEnabled = false;

    if (i18n.language === 'de') {
      this.tagColors = require('../_data/tagColors_de.json')
    }
    else if (i18n.language === 'es') {
      this.tagColors = require('../_data/tagColors_es.json');
    }
    else {
      this.tagColors = require('../_data/tagColors.json');
    }

    if(selFile
      && selFile.fileExtension === "docx"
      && selFile.revisions
      && selFile.revisions.length > 1
      && this.props.appPlatform === ("electron" || "openfin")) {
      selectionMode = SelectionMode.multiple
      compareWordDocsEnabled = true;
    }

    var repoAdmin = selFile && selFile.fileType === "repo" && this.props.repoData.filter(repo => {
      return (repo.id === selFile.id && userData.user.role === "ADMIN")
    })
    
    var isRepoAdmin = repoAdmin && repoAdmin.length > 0 ? true : false

    function getReadableFileSizeString(fileSizeInBytes) {
      var i = -1;
      var byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
      do {
          fileSizeInBytes = fileSizeInBytes / 1024;
          i++;
      } while (fileSizeInBytes > 1024);

      return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    };

    const incrementMinorVersion = minor => {
      var [left, right = '0'] = minor.split(/(\d*$)/),
        length = right.length;

      return left + (+right + 1).toString().padStart(length, '0');
    }

    const CitationRender = (citation) => {
      var item = citation.data[0]

        let children: any = null;
        if (item.values && item.values.length) {
          children = (
            <ul>
              {item.values.map(i => (
                <CitationRender item={i} key={i.id} />
              ))}
            </ul>
          );
        }

        return (
          <li>
            {item.name}
            {children}
          </li>
        );
    }

    const linkActionsMenu: IContextualMenuProps = {
      items: [
        {
          key: 'copyPrivateShortcutToEdit',
          text: i18n.t('app:copyPrivateShortcutToEdit'),
          title: i18n.t('app:copyPrivateShortcutToEditName'),
          iconProps: { iconName: 'OpenFile' },
          onClick: () => this.props.fileExplorerRef._copyPrivateShortcut(selFile, "openToEdit"),
          hidden: selFile && !this._isOfficeOnlineDoc(selFile.fileExtension)
        },
        {
          key: 'copyPrivateShortcutToView',
          text: i18n.t('app:copyPrivateShortcutToView'),
          title: i18n.t('app:copyPrivateShortcutToViewName'),
          iconProps: { iconName: 'ProtectedDocument' },
          onClick: () => this.props.fileExplorerRef._copyPrivateShortcut(selFile, "openReadOnly"),
          hidden: selFile && (!this._isOfficeOnlineDoc(selFile.fileExtension) && (selFile.fileExtension && selFile.fileExtension.toLowerCase() !== "pdf"))
        }
      ]
    };

    var linkActionsMenuAvailable = linkActionsMenu.items.filter(link => {
      return link.hidden === false
    })

    var editors = selFile && selFile.usersData && selFile.usersData.filter(user => {return user.role === "EDITOR"})
    var restrictedEditors = selFile && selFile.usersData && selFile.usersData.filter(user => {return user.role === "RESTRICTED_EDITOR"})
    var viewers = selFile && selFile.usersData && selFile.usersData.filter(user => {return user.role === "VIEWER"})
    var previewers = selFile && selFile.usersData && selFile.usersData.filter(user => {return user.role === "PREVIEWER"})

    var groupEditors = selFile && selFile.groupsData && selFile.groupsData.filter(group => {return group.role === "EDITOR"})
    var groupRestrictedEditors = selFile && selFile.groupsData && selFile.groupsData.filter(group => {return group.role === "RESTRICTED_EDITOR"})
    var groupViewers = selFile && selFile.groupsData && selFile.groupsData.filter(group => {return group.role === "VIEWER"})
    var groupPreviewers = selFile && selFile.groupsData && selFile.groupsData.filter(group => {return group.role === "PREVIEWER"})

    var editorsLength = (editors && editors.length) + (groupEditors && groupEditors.length)
    var restrictedEditorsLength = (restrictedEditors && restrictedEditors.length) + (groupRestrictedEditors && groupRestrictedEditors.length)
    var viewersLength = (viewers && viewers.length) + (groupViewers && groupViewers.length)
    var previewersLength = (previewers && previewers.length) + (groupPreviewers && groupPreviewers.length)

    const synergyApi = process.env.REACT_APP_SYNERGY_API_URL
    var serverDomain = userService.getServerDomain()
    let token = this.getStoredData("token");
    var thumbnailApi = synergyApi + serverDomain + process.env.REACT_APP_THUMBNAIL_API_URL;

    return(
      <div className='d-flex'>
        { showRightPanel ? <Resizable
          size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
          onResize={() => {
            this.props.fileExplorerRef.props.fileExplorerView === "thumbnails" && this.props.fileExplorerRef && this.props.fileExplorerRef._listRoot.current.forceUpdate();
          }}
          onResizeStop={(e, direction, ref, d) => {
            var currentWidth = this.state.panelWidth.match(/\d+/)[0];
            this.setState({
              panelWidth: +currentWidth + +d.width + "px"
            });
            this.props.fileExplorerRef.props.fileExplorerView === "thumbnails" && this.props.fileExplorerRef && this.props.fileExplorerRef._listRoot.current.forceUpdate();
            this._saveResizeState();
          }}
          enable={{ top:false, right:false, bottom:false, left:true, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
          minWidth={313}
          maxWidth={440}
          handleStyles= {{
            left: {
              "left": "0px",
              "width": "6px"
            }
          }}
          handleClasses={{"left": "resize-handle"}}
          style={this.props.searchType === "fullSearch" && window.innerWidth < 1100 ? {position:'absolute', right: 0, zIndex: 9999} : {}}
        >
          <div className={"details-panel pt-0 px-3 " + this.props.appPlatform}>
          { (selFile !== null && this.props.fileExplorerRef._selection.getSelectedCount() === 1) 
            || (this.props.detailsPanelSection === "explorer" &&  selFile && this.props.fileExplorerRef._selection && this.props.fileExplorerRef._selection.getSelectedCount() === 0) ?
              <div>
                <div
                  aria-labelledby={this._getTabId(this.state.activeTab)}
                  role="tabpanel" className="position-relative"
                >
                  { activeTab === "coincidences" ?
                    <div>
                      <div className="text-left sticky-top" style={{padding: '11px 0', background: '#f2f2f2', borderBottom: '1px solid #bfbfbf'}}>
                        <p className="lead font-weight-bold m-0">{i18n.t('app:search')}</p>
                      </div>
                      <div className="form-wrap py-3 sticky-top">
                        <form name="form" onSubmit={this._searchInFile} >
                          <SearchBox id="searchInFile" placeholder="Search in this file" name="searchInFile" value={ searchInFileTerms } onChange={ this.handleSearchInFileTermsChange } autoFocus={setSearchInFileFocus} disabled={ searchingInFile } styles={{root: {borderColor: '#bfbfbf'}}} onClear={()=> {
                              var selFile = this.state.selFile
                              selFile.highlights = null

                              this.setState({
                                searchInFileInit: false,
                                selFile: selFile
                              })
                            }
                          }/>
                        </form>
                        { searchingInFile ?
                          <div className="mt-2">
                            <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-middle mr-2"/>
                            Searching...
                          </div>
                        : null }

                        {(!searchingInFile && searchInFileInit) || (!searchingInFile && selFile && selFile.highlights && selFile.highlights.length > 0)?
                            selFile.highlights && selFile.highlights.length === 0 ?
                              <p className="my-2">No results found.</p>
                            : selFile.highlights && selFile.highlights.length > 0 ?
                              <p className="my-2">{selFile.highlights.length} results found.</p>
                            : null
                        : null }
                      </div>
                      <div className="mb-3">
                        <ul className="coincidences p-0 pb-3 m-0">
                        {(!searchingInFile && searchInFileInit) || (!searchingInFile && selFile && selFile.highlights && selFile.highlights.length > 0)?
                            <>                            
                            { selFile.highlights ?
                              (selFile.highlights).map((item, key) => {
                                return (
                                  <li key={ key } className="mb-3 p-2 coincidence" >
                                    <p className="m-0" dangerouslySetInnerHTML={{__html: item}}></p>
                                  </li>
                                )
                              })
                            : null }
                            </>
                          : null }
                        </ul>
                      </div>
                    </div>
                  : activeTab === "info" ?
                    <div>
                      <div className="text-left sticky-top" style={{padding: '11px 0', background: '#f2f2f2', borderBottom: '1px solid #bfbfbf'}}>
                        <p className="lead font-weight-bold m-0">{i18n.t('app:info')}</p>
                      </div>
                      
                      { this.state.selFile.fileType === "repo" && localStorage.getItem('companyLogo1') && localStorage.getItem('companyLogo1') !== 'null' && localStorage.getItem('companyLogo1') !== '' ?
                        <div className="d-flex my-4 w-100">
                          <div className="text-center w-100">
                            <img src={'data:image/png;base64,'+localStorage.getItem('companyLogo1') } style={{maxWidth: '50%', maxHeight: '150px'}} className="img-fluid" alt="Company logo" />
                          </div>
                        </div>
                      :
                        <div className="d-flex mt-3 mb-2">
                          <div className={selFile.type !== 'tag' ? "mr-3" : ""}>
                            { selFile.fileType === "repo" ?
                              <Icon iconName="OfflineStorageSolid" className="file-type repoIcon" />
                            : selFile.fileType === "trash" ?
                              <Icon   iconName="Trash" className="file-type trash" />
                            : selFile && selFile.id && selFile.id.endsWith("-templates") ?
                              <Icon   {...getFileTypeIconProps({ type: FileIconType.docset, size: 20, imageFileType: 'svg' }) } className="file-type" />
                            : selFile.fileType === 'dir' ?
                              <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } aria-hidden="true" className="file-type"/>
                            : selFile.fileType === 'file' ?
                              <Icon {...getFileTypeIconProps({ extension: selFile.icon, size: 20, imageFileType: 'svg' }) } aria-hidden="true" className="file-type"/>
                            : null }
                          </div>
                          <div className="file-name">
                            <div className="lead d-inline-block font-weight-bold">
                            <span>{ selFile.fileName }</span>
                              { selFile.type === 'tag' && 
                              <div className='px-3' style={{backgroundColor: selFile.color || 'rgb(169, 169, 169,.7)', color: pickTextColorBasedOnBgColor(selFile.color || 'rgb(169, 169, 169,.7)', '#ffffff', '#000000') , borderRadius: '4px'}}>
                                <span>{selFile.name}</span>
                              </div>
                              }   
                            </div>
                          </div>
                        </div>
                      }
                      
                      <ul className="p-0 mb-3">
                        <li className='link-split-button bookmark-buttons mb-2'>
                          { selFile.fileType === "file"
                          || (selFile.fileType === "dir" && selFile.accessGranted) ?
                          <>
                          {savingBookmark ?
                            <ActionButton                          
                              iconProps={{iconName:'FavoriteStarFill'}}
                              onRenderIcon={()=>{return <div style={{margin: '0 6px'}}><Spinner size={SpinnerSize.xSmall} /></div>}}
                              disabled={true}
                              title='bookmarks'
                              text={i18n.t('app:bookmarks')}
                            />
                          : selFile.bookmark ?
                            <ActionButton                          
                              iconProps={{iconName:'FavoriteStarFill', color: 'red'}}
                              className='bookmark text-warning'
                              title='bookmarks'
                              text={i18n.t('app:bookmarks')}
                              onClick={this.removeBookmark.bind(this, selFile)}
                            />
                          :
                            <ActionButton                          
                              iconProps={{iconName:'FavoriteStar'}}
                              className='bookmark'
                              title='bookmarks'
                              text={i18n.t('app:bookmarks')}
                              onClick={this.addBookmark.bind(this, selFile)}
                            />
                          }
                          {
                            <ActionButton
                              iconProps={{iconName:'Subscribe'}}
                              title='subscribe'
                              text={i18n.t('app:subscribe')}
                              disabled={true}
                            />
                          }
                          </>
                          :
                            null
                          }
                        </li>


                        {selFile.id !== this.props.repoData[0].id && this.props.searchType !== "tag" && <li className='link-split-button mb-2'>
                          <ActionButton                          
                            iconProps={{iconName:'PageLink'}} 
                            title={i18n.t('app:hyperlink')} 
                            text={i18n.t('app:hyperlink')}
                            onClick={()=>{this.props.fileExplorerRef._copyPrivateShortcutName(selFile)}}
                            className=""
                          />
                          { linkActionsMenuAvailable.length > 0 ?
                            <ActionButton                   
                              iconProps={{iconName:'Link'}} 
                              title={i18n.t('app:path')} 
                              text={i18n.t('app:path')}
                              onClick={()=>{this.props.fileExplorerRef._copyPrivateShortcut(selFile, "askToOpen")}}
                              split
                              splitButtonAriaLabel="See link options"
                              aria-roledescription="split button"
                              menuProps={linkActionsMenu}
                            />
                          :
                            <DefaultButton                          
                              iconProps={{iconName:'Link'}} 
                              title={i18n.t('app:path')} 
                              text={i18n.t('app:path')}
                              onClick={()=>{this.props.fileExplorerRef._copyPrivateShortcut(selFile, "askToOpen")}}
                              splitButtonAriaLabel="See link options"
                              aria-roledescription="split button"
                            />
                          }
                          <ActionButton                          
                            iconProps={{iconName:'Copy'}}
                            title='ID'
                            text='ID'
                            onClick={()=>{this.props.fileExplorerRef._copyId(selFile.id)}}
                          />
                        </li> }

                        { selFile.lock && !selFile.wopiLock && selFile.lock.email !== 'SHAREPOINT_COAUTHORING_FILE_LOCK' ?
                          <li className="mb-2">
                            <div className="text-secondary"><Icon iconName="Lock" className="mr-1" /> {i18n.t('app:lockedBy')}</div>
                            <TooltipHost
                              closeDelay={500}
                              calloutProps={{ gapSpace: 0, target: '#persona-dialog' }}
                              content= {
                                <div className='p-1'>
                                  <Persona className='pb-2' text={selFile.lock.name} size={PersonaSize.size32}/>
                                  <a href={ "mailto:" + selFile.lock.email } style={{color: 'rgb(50,49,48)'}}><span className=" m-0 p-0">{ selFile.lock.email }</span></a>
                                </div>
                              }
                            >
                              <div className='d-flex align-items-center'>
                                <Persona className='mr-1' size={8} hidePersonaDetails text={selFile.lock.name}/>
                                <a id='persona-dialog' href={ "mailto:" + selFile.lock.email } style={{color: 'rgb(50,49,48)'}}><span className=" m-0 p-0 username font-weight-bold">{ selFile.lock.name }</span></a>
                              </div>
                            </TooltipHost>
                          </li>
                        : selFile.lock && !selFile.wopiLock && selFile.lock.email === 'SHAREPOINT_COAUTHORING_FILE_LOCK' ?
                          <li className="mb-2">
                            <div className="text-secondary">
                              <div className="text-secondary"><Icon iconName="Lock" className="mr-1" /> {i18n.t('app:lockedBy')}</div>
                            </div>
                            <div className="d-flex align-items-center username font-weight-bold">
                              <img
                                className="mr-1"
                                src="https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/svg/sharepoint_16x1.svg"
                                width="14"
                                height="14"
                                alt="Sharepoint icon"
                              />
                              { i18n.t('app:coAuthoringSharepoint') }
                              <ActionButton text={i18n.t('app:closeSession')} onClick={()=>this._closeCoauthorSession(selFile)} className='add-tag-button ml-1' style={{height: '24px'}} iconProps={{iconName: 'Cancel'}}/>
                            </div>
                          </li>
                        : selFile.wopiLock ?
                          <li className="mb-2">
                          <div className="text-secondary">
                            <div className="text-secondary"><Icon iconName="Lock" className="mr-1" /> {i18n.t('app:lockedBy')}</div>
                          </div>
                          <div className="d-flex align-items-center username font-weight-bold">
                            <img
                              className="mr-1"
                              src="https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/svg/office_16x1.svg"
                              width="14"
                              height="14"
                              alt="Sharepoint icon"
                            />
                            {i18n.t('app:coAuthoring')}
                          </div>
                        </li>
                        : null }

                        { this.state.selFile.thumbnailCreated && this.state.selFile.fileExtension && <>
                          <div className="text-secondary mr-2">{i18n.t('app:thumbnail')}:</div>
                          <div className="d-flex mt-1 mb-3 w-100">
                            <div className="text-center w-100">
                              <img src={ thumbnailApi + "/thumbnail/" + this.state.selFile.id + "?access_token=" + token } style={{maxWidth:'180px', maxHeight:'180px', boxShadow: '0px 2px 6px 1px lightgrey'}} alt="Thumbnail" />
                            </div>
                          </div>
                        </> }

                        <li className="mb-2">
                          <div className="text-secondary mr-2">{selFile.id && (i18n.t('app:id')+':')}</div>
                          <div className="username font-weight-bold">
                            { selFile.id }
                          </div>
                        </li>

                        { this.state.selFile.fileType === "repo"
                          || this.state.selFile.fileType === "file"
                          || (this.state.selFile.fileType === "dir" && this.state.selFile.accessGranted) ?
                          <li className="mb-2">
                            <div className="text-secondary mr-2">{i18n.t('app:role')}:</div>
                            <div className="username font-weight-bold">{ this.state.roleName }</div>
                          </li>
                        : null }

                        { this.state.selFile.fileType === "dir" && !this.state.selFile.accessGranted ?
                          <li className="mb-2">
                            <div className="text-secondary mr-2">{i18n.t('app:role')}:</div>
                            <div className="username font-weight-bold">{i18n.t('app:folderInPathOnly')}</div>
                          </li>
                        : null }

                        { this.state.selFile.fileType !== "repo" && selFile.type !== 'tag' &&
                          <li className="mb-2">
                            <div className='d-flex'>
                              <div className="text-secondary mr-1">{i18n.t('app:colorTag')}:</div>
                              {ActionsPermissions("tagItem", this.state.folderRole) && <TooltipHost content={i18n.t('app:editColorTag')} calloutProps={{ gapSpace: 0 }} >
                                <IconButton className='add-tag-button' id={'colorTagPicker'} iconProps={{iconName: 'Edit'}} styles={{icon:{fontSize: '12px'}}} style={{fontSize: '14px', width: '20px', height: '20px', marginLeft: 0}} onClick={()=>{this.showColorPicker()}}/>
                              </TooltipHost>}
                            </div>
                           { this.state.selFile.colorTag && this.state.selFile.colorTag.toString() !== '0' ? <div className='mt-1'>
                              { this.state.selFile.colorTag === 'loading' ?
                                <Spinner size={SpinnerSize.xSmall} style={{fontSize: '40%', paddingRight: '15px', marginLeft: '4px', paddingTop: '4px'}} />
                              : <div className='d-flex colorTag' id={'colorTagPicker'} onClick={()=>{this.showColorPicker()}}>
                                  <div ><Icon style={{fontSize: '60%', paddingTop: '5px', marginLeft: 0, marginRight: '4px', color: this.getColorTag(this.state.selFile.colorTag)}} iconName="CircleFill" className="offline-status small my-auto" /></div>
                                  <span style={{fontWeight: 700}}>{this.tagColors.colors.find((color)=>{return this.state.selFile.colorTag.toString() === color.id})!.label}</span>
                                </div>
                              }
                            </div> : null}
                          </li>
                        }

                        { ActionsPermissions("viewTags", this.state.folderRole) ?
                          selFile.id && selFile.sortedTags && this.state.tagsList ?
                            <li className="mb-2">
                              <ul className="tags p-0">
                                <div className='d-flex align-items-center'>
                                  <span className='font-weight-normal pb-0 mr-1' style={{color: '#6c757d'}}>{i18n.t('app:tags')}:</span>
                                  { ActionsPermissions("tagItem", this.state.folderRole) && <>
                                    <TooltipHost content={i18n.t('app:editTags')} calloutProps={{ gapSpace: 0 }} >
                                      <IconButton className='add-tag-button' id={'colorTagPicker'} iconProps={{iconName: 'Edit'}} styles={{icon:{fontSize: '12px'}}} style={{fontSize: '14px', width: '20px', height: '20px', marginLeft: 0}} onClick={()=>{this.props.headerRef.setState({showTagBulkDialog: true, tagsList: null, isCascade: false, newTag: [], selFile: selFile});this.props.headerRef.getAllTags();}}/>
                                    </TooltipHost>
                                  </>}
                                </div>
                                <div className=''>
                                  { selFile.sortedTags.length ?
                                    (selFile.sortedTags).map((tags, key) => {
                                        return (
                                          <div key={ key } className="mt-1 tag">
                                            <p className='mr-2 my-0 py-0'><Icon iconName='Tag' className='mr-1' />{tags[0].category}:</p>
                                            { tags.map((item, key_) => {
                                                return (
                                                  <li key={ key_ } className="tag">
                                                    <span style={{transitionDuration:'0s', backgroundColor: item.color || 'rgb(169, 169, 169,.7)', color: pickTextColorBasedOnBgColor(item.color || 'rgb(169, 169, 169,.7)', '#ffffff', '#000000')}} className="badge badge-secondary py-1 px-2 d-flex align-items-baseline">{ item.name } { ActionsPermissions("removeTag", this.state.folderRole) ? <Icon iconName="Cancel" className="delete ml-1" onClick={  evt => { selFile.kind !== "file" ? this.setState({tagIdToDelete:item.id, removeInCascadeDialog: true, isCascade: false}) : this.deleteTag(item.id, false) } } /> : null }</span>
                                                  </li>
                                                )
                                            })}
                                          </div>
                                        )
                                    })
                                  : null }
                                </div>
                                { this.state.removeInCascadeDialog ?
                                    <Dialog
                                      hidden={!this.state.removeInCascadeDialog}
                                      onDismiss={ev => this.setState({removeInCascadeDialog: false})}
                                      dialogContentProps={{
                                        type: DialogType.normal,
                                        title: i18n.t('app:removeTag')
                                      }}
                                      modalProps={{
                                        styles: { main: { maxWidth: 640, maxHeight: 100 } },
                                        dragOptions: undefined,
                                        className: "edit-message"
                                      }}
                                    >
                                    <Checkbox label={i18n.t('app:removeFromSubfolders')} checked={this.state.isCascade} onChange={()=>this.setState({isCascade: !this.state.isCascade})} />
                                    <DialogFooter className='mt-4'>
                                      { savingVersionMessage ?
                                        <Spinner size={SpinnerSize.xSmall} className="d-inline-block" />
                                      : null }
                                      <DefaultButton onClick={()=> {this.setState({removeInCascadeDialog: false})}} text={i18n.t('app:cancel')} />
                                      <PrimaryButton onClick={()=> {this.deleteTag(this.state.tagIdToDelete, this.state.isCascade);this.setState({removeInCascadeDialog: false})}} text={i18n.t('app:OK')} />                   
                                    </DialogFooter>
                                  </Dialog>
                                : null }
                              </ul>
                            </li>
                          : selFile.fileType === "file" ?
                            <p>{i18n.t('app:loadingTags')}</p>
                          : null
                        : null }
                        
                        {ActionsPermissions("viewComments", this.state.folderRole) ?
                          selFile.id && selFile.comments?
                            <li className="mb-2">
                              <ul className="comments p-0">
                                <li className="font-weight-bold">
                                  <form name="form" onSubmit={ this.addComment } >
                                    <TextField borderless placeholder={i18n.t('app:addComment')} name="newComment" value={ newComment } onChange={ this.handleCommentChange } disabled={ !ActionsPermissions("addComment", this.state.folderRole) || savingComment } label={i18n.t('app:comments')} />
                                  </form>
                                </li>
                                { savingComment ?
                                  <li className="d-inline-block mt-2"><Spinner size={SpinnerSize.xSmall} /></li>
                                : null }
                                { selFile.comments.length ?
                                  (selFile.comments).map((item, key) => {
                                    return (
                                      <li key={ key } className="comment rounded-lg p-2 pt-1">
                                        <div className="row m-0 p-0">
                                          <div className="col m-0 mr-auto p-0">
                                            <p className="text-secondary small mb-1">{i18n.t('app:upperBy')} <span className="font-weight-bold">{ item.author.name }</span> {i18n.t('app:onDateX')} <span className="font-weight-bold">{ Moment(item.timestamp).format(i18n.t('app:dateFormat')) }</span></p>
                                          </div>

                                          { ActionsPermissions("removeComment", this.state.folderRole) && item.author.id === userData.user.id ?
                                            <div>
                                              <Icon iconName="Cancel" className="delete align-top ml-1" onClick={ this.deleteComment.bind(this, item.id) } />
                                            </div>
                                          : null }
                                        </div>
                                        <p className="m-0">{ item.comment }</p>
                                      </li>
                                    )
                                  })
                                : null }
                              </ul>
                            </li>
                          : selFile.fileType === 'file' ?
                            <p>{i18n.t('app:loadingComments')}</p>
                          : null
                        : null }
                        { selFile.type === 'tag' &&
                          <ul className="p-0 my-3">
                            <li className="mb-2">
                              <div className="text-secondary mr-2">{i18n.t('app:category')}:</div>
                              <div className="username font-weight-bold">{ selFile.category }</div>
                            </li>
                            <li className="mb-2">
                              <div className="text-secondary mr-2">{i18n.t('app:categoryDescription')}:</div>
                              <div className="username font-weight-bold">{ selFile.description }</div>
                            </li>
                          </ul>
                        }
                      </ul>
                    </div>
                  : activeTab === "details" ?
                    <div>
                      <div className="text-left sticky-top" style={{padding: '11px 0', background: '#f2f2f2', borderBottom: '1px solid #bfbfbf'}}>
                        <p className="lead font-weight-bold m-0">{i18n.t('app:details')}</p>
                      </div>
                      <div className="d-flex my-3">
                        <div className="mr-3">
                          { selFile.fileType === "repo" ?
                            <Icon iconName="OfflineStorageSolid" className="file-type repoIcon" />
                          : selFile.fileType === "trash" ?
                            <Icon   iconName="Trash" className="file-type trash" />
                          : selFile.fileType === 'dir' ?
                            <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } aria-hidden="true" className="file-type"/>
                          : selFile.fileType === 'file' ?
                            <Icon {...getFileTypeIconProps({ extension: selFile.icon, size: 20, imageFileType: 'svg' }) } aria-hidden="true" className="file-type"/>
                          : null }
                        </div>
                        <div className="file-name">
                          <div className="lead d-inline-block font-weight-bold">
                          { selFile.fileType === "file"
                            || (selFile.fileType === "dir" && selFile.accessGranted) ?
                              savingBookmark ?
                                <span className="d-inline-block bookmark mr-2"><Spinner size={SpinnerSize.xSmall} /></span>
                              : selFile.bookmark ?
                                <Icon iconName="FavoriteStarFill" onClick={ this.removeBookmark.bind(this, selFile) } className="bookmark text-warning mr-2" />
                              :
                                <Icon iconName="FavoriteStar" onClick={ this.addBookmark.bind(this, selFile) } className="bookmark mr-2" />
                            :
                              null
                            }

                            { selFile.fileName }                    
                          </div>
                        </div>
                      </div>

                      <ul className="p-0 my-3">
                        <li className="mb-2">
                          <div className="text-secondary mr-2">ID:</div>
                          <div className="username font-weight-bold">{ selFile.id }</div>
                        </li>

                        { selFile.fileType === "dir"
                          || selFile.fileType === "repo" ?

                          calculatingSize ?
                            <li className="mb-2"><Spinner size={SpinnerSize.xSmall} className="d-inline-block align-middle mr-2"/>{i18n.t('app:calculating')}</li>
                          :
                            <>
                              <li className="mb-2">
                                  <div className="text-secondary mr-2">{i18n.t('app:contains')}</div>
                                  <div className="username font-weight-bold"><NumericFormat value={selFile.directFilesCount} displayType={'text'} thousandSeparator={true} renderText={value => <span>{value}</span>} /> {i18n.t('app:lowerFiles')}, <NumericFormat value={selFile.directFoldersCount} displayType={'text'} thousandSeparator={true} renderText={value => <span>{value}</span>} /> {i18n.t('app:lowerFolders')}</div>
                              </li>
                              <li className="mb-2">
                                  <div className="text-secondary mr-2">{i18n.t('app:includingSubFolders')}</div>
                                  <div className="username font-weight-bold">{selFile.totalFilesCount} {i18n.t('app:lowerFiles')}, {selFile.totalFoldersCount} {i18n.t('app:lowerFolders')}</div>
                              </li>
                              <li className="mb-2">
                                  <div className="text-secondary mr-2">{i18n.t('app:size')}:</div>
                                  <div className="username font-weight-bold">{getReadableFileSizeString(selFile.totalSize)}</div>
                              </li>
                            </>
                        :
                          null
                        }

                        { selFile.path ?
                          <li className="mb-2">
                            <div className="text-secondary">{i18n.t('app:path')}:</div>
                            <div className="username font-weight-bold">{ selFile.path }</div>
                          </li>
                        : null }
                        { selFile.fileType === 'file' ?
                          <li className="mb-2">
                            <div className="text-secondary mr-2">{i18n.t('app:size')}:</div>
                            <div className="username font-weight-bold">{ selFile.fileSize }</div>
                        </li>
                        : null }
                        { selFile.createdBy ?
                          <li className="mb-2">
                            <div className="text-secondary">{i18n.t('app:createdBy')}</div>
                            <div className="username font-weight-bold">{ selFile.createdBy.name }</div>
                          </li>
                        : null }
                        { this.state.selFile.fileType !== "repo" ?
                          <li className="mb-2">
                            <div className="text-secondary">{i18n.t('app:dateCreated')}:</div>
                            <div className="font-weight-bold">{ Moment(selFile.dateCreated).format(i18n.t('app:dateFormat')) }</div>
                          </li>
                        : null }
                        { typeof selFile.modifiedBy === "object" ?
                          <li className="mb-2">
                            <div className="text-secondary">{i18n.t('app:modifiedBy')}:</div>
                            <div className="username font-weight-bold">{ selFile.modifiedBy.name }</div>
                          </li>
                        : null }
                        { this.state.selFile.fileType !== "repo" ?
                          <li className="mb-2">
                            <div className="text-secondary">{i18n.t('app:dateModified')}</div>
                            <div className="font-weight-bold">{ Moment(selFile.dateModified).format(i18n.t('app:dateFormat')) }</div>
                          </li>
                        : null }

                        { selFile.fileType === 'file' && selFile.fileExtension !== undefined && selFile.fileExtension.toLowerCase() === 'pdf' ?
                          <div className="citation p-0">
                          
                              { selFile.citation && selFile.citation[0] ? 
                                <div>
                                  <ActionButton iconProps={{ iconName: 'Cancel' }} allowDisabledFocus onClick={ this._deleteCitation.bind(this, selFile.citation[0].id) }>
                                    {i18n.t('app:removeCitation')}
                                  </ActionButton> 
                                  <RecursiveProperty property={selFile.citation[0]} propertyName="" excludeBottomBorder={true} rootProperty={false}/>
                                </div>
                              :
                                <ActionButton iconProps={{ iconName: 'AddNotes' }} allowDisabledFocus onClick={ this._importCitation.bind(this, selFile.id) } className="d-none">
                                  {i18n.t('app:importCitation')}
                                </ActionButton> 
                              }
                          </div>
                        : null }
                      </ul>
                    </div>
                  : activeTab === "preview" ?
                    <div>
                      <div className="text-left sticky-top" style={{padding: '11px 0', background: '#f2f2f2', borderBottom: '1px solid #bfbfbf'}}>
                        <p className="lead font-weight-bold m-0">
                          {i18n.t('app:preview')}
                        </p>
                      </div>
                      <div id="file-preview" className="mt-3 text-center" style={{height:'calc(100vh - 235px)'}}>
                        { (selFile.fileExtension === "png" || selFile.fileExtension === "jpg" || selFile.fileExtension === "jpeg") && selFile.previewData ?
                          <img alt="Preview" style={{maxWidth: "100%", background: 'repeating-conic-gradient(#D0D0D0 0% 25%, transparent 0% 50%) 50% / 20px 20px'}} src={selFile.previewData}></img>
                        : selFile.fileExtension === "svg" && selFile.previewData ? 
                          <svg height="100%" width="100%" style={{background: 'repeating-conic-gradient(#D0D0D0 0% 25%, transparent 0% 50%) 50% / 20px 20px'}}>
                            <image width="100%" href={selFile.previewData}></image>
                          </svg>
                        : selFile.fileExtension === 'email' && selFile.previewData ?
                        <div className='text-left bg-white' style={{height:'100%'}}>
                          <div style={{height: '32px', background: '#0078d4', display:'flex', alignItems:'center', verticalAlign:'middle'}}>
                            <p className="mb-0 pb-0 mx-2" style={{color: 'white', fontWeight: 600, fontSize: '14px', maxWidth:'80%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow:'hidden', textAlign:'left', marginRight: '30px'}}><Icon className='mr-1' style={{verticalAlign:'bottom'}} {...getFileTypeIconProps({ extension: '.eml', size: 24, imageFileType: 'svg' }) } /><span>{selFile.name + "." + selFile.fileExtension}</span></p>
                          </div>
                          <div className='bg-white' style={{top:'70px', height:'100%', display: 'flex', flexFlow:'column'}}>
                          <div className='email-header pt-2 px-2 bg-white'>
                            <p className='email-subject mb-2'>{selFile.previewData.subject}</p>
                            <p className='email-date d-flex mb-1'><span className='font-weight-bold' style={{minWidth:'50px', textAlign: 'left', marginRight: '10px'}}>{i18n.t('app:date')+ ': '}</span>{new Date(selFile.previewData.dateTimeCreated).toLocaleString()}</p>
                            <div className='email-from d-flex'>
                              <span className='font-weight-bold' style={{minWidth:'50px', textAlign: 'left', marginRight: '10px'}}>{i18n.t('app:from')+ ': '}</span>
                              <TooltipHost
                                    key={selFile.previewData.from.emailAddress+'from'}
                                    content= {
                                      <div className='m-1'>
                                        <Persona className='mb-2' text={selFile.previewData.from.displayName} initialsColor="#7F7F7F" size={PersonaSize.size32}/>
                                        <span className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</span>
                                        <p className='p-0 m-0' style={{fontSize:'14px'}}>{selFile.previewData.from.emailAddress}</p>
                                      </div>
                                    }
                                    id={selFile.previewData.from.emailAddress+'from'}
                                    calloutProps={{ gapSpace: 0 }}
                                    //styles={hostStyles}
                                  >
                                    <span aria-describedby={selFile.previewData.from.emailAddress+'from'} className='mb-1 pr-1 email-to-badges'>{selFile.previewData.from.displayName}</span>
                                  </TooltipHost>
                            </div>
                            {selFile.previewData.to.length>0 && 
                            <div className='d-flex'>
                              <span className='font-weight-bold' style={{minWidth:'50px', textAlign: 'left', marginRight: '10px', fontSize: '14px'}}>{i18n.t('app:To')+':'}</span>
                              <div>
                                {selFile.previewData.to.map((item, i)=>{
                                  return (
                                    <TooltipHost
                                      key={item.emailAddress+'to'}
                                      content= {
                                        <div>
                                          <Persona className='mb-2' text={item.displayName} initialsColor="#7F7F7F" size={PersonaSize.size32}/>
                                          <span className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</span>
                                          <p className='p-0 m-0' style={{fontSize:'14px'}}>{item.emailAddress}</p>
                                        </div>
                                      }
                                      id={item.emailAddress+'to'}
                                      calloutProps={{ gapSpace: 0 }}
                                      //styles={hostStyles}
                                    >
                                      <span aria-describedby={item.emailAddress+'to'} className='mb-1 pr-1 email-to-badges' > {i === selFile.previewData.to.length-1 ? item.displayName : item.displayName+', ' }</span>
                                    </TooltipHost>
                                  )                        
                                })}
                              </div>
                            </div>}
                            {selFile.previewData.cc.length>0 && 
                            <div className='d-flex'>
                              <span className='font-weight-bold' style={{minWidth:'50px', textAlign: 'left', marginRight: '10px', fontSize: '14px'}}>Cc:</span>
                              {selFile.previewData.cc.map((item, i)=>{
                                return (
                                <TooltipHost
                                  key={item.emailAddress+'cc'}
                                  content= {
                                    <div>
                                      <Persona className='mb-2' text={item.displayName} initialsColor="#7F7F7F" size={PersonaSize.size32}/>
                                      <span className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</span>
                                      <p className='p-0 m-0' style={{fontSize:'14px'}}>{item.emailAddress}</p>
                                    </div>
                                  }
                                  id={item.emailAddress+'cc'}
                                  calloutProps={{ gapSpace: 0 }}
                                  //styles={hostStyles}
                                >
                                  <span aria-describedby={item.emailAddress+'cc'} className='mb-1 pr-1 email-to-badges' > {i === selFile.previewData.cc.length-1 ? item.displayName : item.displayName+', ' }</span>
                                </TooltipHost>
                                )
                              })}
                            </div>}
                            {selFile.previewData.bcc.length>0 && 
                            <div className='d-flex'>
                              <span className='font-weight-bold' style={{minWidth:'50px', textAlign: 'left', marginRight: '10px'}}>Bcc:</span>
                              {selFile.previewData.bcc.map((item, i)=>{
                                return (
                                <TooltipHost
                                  key={item.emailAddress+'bcc'}
                                  content= {
                                    <div>
                                      <Persona className='mb-2' text={item.displayName} initialsColor="#7F7F7F" size={PersonaSize.size32}/>
                                      <span className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</span>
                                      <p className='p-0 m-0' style={{fontSize:'14px'}}>{item.emailAddress}</p>
                                    </div>
                                  }
                                  id={item.emailAddress+'bcc'}
                                  calloutProps={{ gapSpace: 0 }}
                                  //styles={hostStyles}
                                >
                                  <span aria-describedby={item.emailAddress+'bcc'} className='mb-1 pr-1 email-to-badges' > {i === selFile.previewData.cc.length-1 ? item.displayName : item.displayName+', ' }</span>
                                </TooltipHost>
                                )
                                })}
                            </div>}
                            {selFile.previewData.attachmentsOutside.length>0 && 
                            <div className='row mx-1' style={{overflow: 'auto', maxHeight: '100px'}}>
                              {selFile.previewData.attachmentsOutside.map((item, i)=>{
                                let namesplit = item.name.split('.');
                                let filetype = namesplit[namesplit.length-1];
                                return (
                                <div key={item.name+i} className="d-flex py-1 px-2 mr-2 mb-2 align-items-center email-attachment bg-white">
                                  <Icon {...getFileTypeIconProps({ extension: filetype, size: 32, imageFileType: 'svg' }) } aria-hidden="true" className="file-type mr-2"/>
                                  <div>
                                    <p className='p-0 m-0' style={{fontSize: '14px'}}>{item.name}</p>
                                    <span className="file-size" >{item.size}</span>
                                  </div>
                                </div>
                                )
                                })}
                            </div>}
                          </div>
                          <iframe title="preview-email-iframe" className='bg-white px-2 flex-grow-1' srcDoc={selFile.previewData.body} style={{width:'100%', height:'100%', border: 'none'}}/>
                          </div>
                        </div>
                        : selFile.previewData ? selFile.previewData === "nopreview" ? 
                          <span>{i18n.t('app:cannotBePreviewed')}</span>
                        : <iframe id="preview-frame" onLoad={() => _sendWopiCookies()} title="preview-details" src={selFile.previewData}  frameBorder='0' style={{border:0, top:'0px', left:'0px', bottom:'0px', right:'0px', width:'100%', height:'calc(100vh - 235px)'}}></iframe> : <Spinner size={SpinnerSize.medium} />
                        }
                      </div>
                    </div>
                  : activeTab === "share" ?
                    <div>
                      <div className="text-left sticky-top" style={{padding: '11px 0', background: '#f2f2f2', borderBottom: '1px solid #bfbfbf'}}>
                      <p className="lead font-weight-bold m-0">
                        {i18n.t('app:sharingPermissions')}
                      </p>
                    </div>
                    <p className="share-section mt-3">{i18n.t('app:internal')}</p>
                    <ul className="share mt-3 p-0">
                      { selFile.adminsData ?
                        <li className="mb-2">
                          <div className="text-secondary">
                            { selFile.adminsData.length > 0 && showAdminsList ?
                              <Icon iconName="TriangleSolidDown12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showAdminsList: false}) } />
                            : selFile.adminsData.length > 0 && !showAdminsList ?
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showAdminsList: true}) } />
                            :
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" />
                            }
                            <Icon iconName="Admin" className="mr-1" />
                            <span className="permissions-title">{i18n.t('app:admins')}:</span>
                            { !settingItemDetails ?
                              <span className="font-weight-bold">
                                { selFile.adminsData.length }
                              </span>
                            :
                              <Spinner size={SpinnerSize.xSmall}                className="text-secondary" />
                            }
                          </div>
                          { showAdminsList && selFile.adminsData.length > 0?
                            <Stack tokens={{ childrenGap: 10 }}>
                              { selFile.adminsData.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(admin =>
                                admin ?
                                <Persona
                                  key={ admin.id }
                                  text={ admin.name + " " + admin.surname }
                                  secondaryText={ admin.email }
                                  tertiaryText={ admin.id }
                                  optionalText={ "admin" }
                                  onRenderPrimaryText={ this._onRenderInternalPersonaPrimaryText }
                                  size={PersonaSize.size24}
                                  className="persona my-1 mx-3"
                                />
                                : null
                              )}
                            </Stack>
                          : null }
                        </li>
                      : null }
                      { editors || groupEditors ?
                        <li className="mb-2">
                          <div className="text-secondary">
                            { editorsLength > 0 && showUsersList ?
                              <Icon iconName="TriangleSolidDown12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showUsersList: false}) } />
                            : editorsLength > 0 && !showUsersList ?
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showUsersList: true}) } />
                            :
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" />
                            }
                            <Icon iconName="Edit" className="mr-1" />
                            <span className="permissions-title">{i18n.t('app:editors')}:</span>
                            { !settingItemDetails ?
                              <span className="font-weight-bold">
                                { editorsLength }
                              </span>
                            :
                              <Spinner size={SpinnerSize.xSmall} className="text-secondary" />
                            }
                          </div>
                          { showUsersList && editors && editors.length > 0 ?
                            <Stack tokens={{ childrenGap: 10 }}>
                              { editors.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(user =>
                                user ?
                                  <Persona
                                    key={ user.id }
                                    text={ user.name + " " + user.surname }
                                    secondaryText={ user.email }
                                    tertiaryText={ user.id }
                                    optionalText={ "user" }
                                    onRenderPrimaryText={ this._onRenderInternalPersonaPrimaryText }
                                    size={PersonaSize.size24}
                                    className="persona my-1 mx-3"
                                  />
                                : null
                              )}
                            </Stack>
                          : null }

                          { showUsersList && groupEditors && groupEditors.length > 0  ?
                            <Stack tokens={{ childrenGap: 10 }}>
                              { groupEditors.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(group =>
                                <Persona
                                  key={ group.id }
                                  text={ group.name + " (" + group.users.length + "p.)" }
                                  secondaryText={ group.users.map(user => {return (user && user.name) + " " + (user && user.surname)}).join(", ") }
                                  showSecondaryText={true}
                                  tertiaryText={ group.id }
                                  optionalText={ "user" }
                                  onRenderPrimaryText={ this._onRenderGroupPersonaPrimaryText }
                                  size={PersonaSize.size24}
                                  className="persona my-1 mx-3"
                                />
                              )}
                            </Stack>
                          : null }
                        </li>
                      : null }

                      { restrictedEditors || groupRestrictedEditors ?
                        <li className="mb-2">
                          <div className="text-secondary">
                            { restrictedEditorsLength > 0 && showUsersList ?
                              <Icon iconName="TriangleSolidDown12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showUsersList: false}) } />
                            : restrictedEditorsLength > 0 && !showUsersList ?
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showUsersList: true}) } />
                            :
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" />
                            }
                            <Icon iconName="Edit" className="mr-1" />
                            <span className="permissions-title">{i18n.t('app:restrictedEditors')}:</span>
                            { !settingItemDetails ?
                              <span className="font-weight-bold">
                                { restrictedEditorsLength }
                              </span>
                            :
                              <Spinner size={SpinnerSize.xSmall} className="text-secondary" />
                            }
                          </div>
                          { showUsersList && restrictedEditors && restrictedEditors.length > 0 ?
                            <Stack tokens={{ childrenGap: 10 }}>
                              { restrictedEditors.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(user =>
                                user ?
                                  <Persona
                                    key={ user.id }
                                    text={ user.name + " " + user.surname }
                                    secondaryText={ user.email }
                                    tertiaryText={ user.id }
                                    optionalText={ "user" }
                                    onRenderPrimaryText={ this._onRenderInternalPersonaPrimaryText }
                                    size={PersonaSize.size24}
                                    className="persona my-1 mx-3"
                                  />
                                : null
                              )}
                            </Stack>
                          : null }

                          { showUsersList && groupRestrictedEditors && groupRestrictedEditors.length > 0  ?
                            <Stack tokens={{ childrenGap: 10 }}>
                              { groupRestrictedEditors.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(group =>
                                <Persona
                                  key={ group.id }
                                  text={ group.name + " (" + group.users.length + "p.)" }
                                  secondaryText={ group.users.map(user => {return (user && user.name) + " " + (user && user.surname)}).join(", ") }
                                  showSecondaryText={true}
                                  tertiaryText={ group.id }
                                  optionalText={ "user" }
                                  onRenderPrimaryText={ this._onRenderGroupPersonaPrimaryText }
                                  size={PersonaSize.size24}
                                  className="persona my-1 mx-3"
                                />
                              )}
                            </Stack>
                          : null }
                        </li>
                      : null }

                      { viewers || groupViewers ?
                        <li className="mb-2">
                          <div className="text-secondary">
                            { viewersLength > 0 && showUsersList ?
                              <Icon iconName="TriangleSolidDown12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showUsersList: false}) } />
                            : viewersLength > 0 && !showUsersList ?
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showUsersList: true}) } />
                            :
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" />
                            }
                            <Icon iconName="View" className="mr-1" />
                            <span className="permissions-title">{i18n.t('app:viewers')}:</span>
                            { !settingItemDetails ?
                              <span className="font-weight-bold">
                                { viewersLength }
                              </span>
                            :
                              <Spinner size={SpinnerSize.xSmall} className="text-secondary" />
                            }
                          </div>
                          { showUsersList && viewers && viewers.length > 0 ?
                            <Stack tokens={{ childrenGap: 10 }}>
                              { viewers.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(user =>
                                user ?
                                  <Persona
                                    key={ user.id }
                                    text={ user.name + " " + user.surname }
                                    secondaryText={ user.email }
                                    tertiaryText={ user.id }
                                    optionalText={ "user" }
                                    onRenderPrimaryText={ this._onRenderInternalPersonaPrimaryText }
                                    size={PersonaSize.size24}
                                    className="persona my-1 mx-3"
                                  />
                                : null
                              )}
                            </Stack>
                          : null }

                          { showUsersList && groupViewers && groupViewers.length > 0  ?
                            <Stack tokens={{ childrenGap: 10 }}>
                              { groupViewers.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(group =>
                                <Persona
                                  key={ group.id }
                                  text={ group.name + " (" + group.users.length + "p.)" }
                                  secondaryText={ group.users.map(user => {return (user && user.name) + " " + (user && user.surname)}).join(", ") }
                                  showSecondaryText={true}
                                  tertiaryText={ group.id }
                                  optionalText={ "user" }
                                  onRenderPrimaryText={ this._onRenderGroupPersonaPrimaryText }
                                  size={PersonaSize.size24}
                                  className="persona my-1 mx-3"
                                />
                              )}
                            </Stack>
                          : null }
                        </li>
                      : null }
                      { previewers || groupPreviewers?
                        <li className="mb-2">
                          <div className="text-secondary">
                            { previewersLength > 0 && showUsersList ?
                              <Icon iconName="TriangleSolidDown12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showUsersList: false}) } />
                            : previewersLength > 0 && !showUsersList ?
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showUsersList: true}) } />
                            :
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" />
                            }
                            <Icon iconName="Preview" className="mr-1" />
                            <span className="permissions-title">{i18n.t('app:previewers')}:</span>
                            { !settingItemDetails ?
                              <span className="font-weight-bold">
                                { previewersLength }
                              </span>
                            :
                              <Spinner size={SpinnerSize.xSmall} className="text-secondary" />
                            }
                          </div>
                          { showUsersList && previewers && previewers.length > 0 ?
                            <Stack tokens={{ childrenGap: 10 }}>
                              { previewers.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(user =>
                                user ?
                                  <Persona
                                    key={ user.id }
                                    text={ user.name + " " + user.surname }
                                    secondaryText={ user.email }
                                    tertiaryText={ user.id }
                                    optionalText={ "user" }
                                    onRenderPrimaryText={ this._onRenderInternalPersonaPrimaryText }
                                    size={PersonaSize.size24}
                                    className="persona my-1 mx-3"
                                  />
                                : null
                              )}
                            </Stack>
                          : null }

                          { showUsersList && groupPreviewers && groupPreviewers.length > 0  ?
                            <Stack tokens={{ childrenGap: 10 }}>
                              { groupPreviewers.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(group =>
                                <Persona
                                  key={ group.id }
                                  text={ group.name + " (" + group.users.length + "p.)" }
                                  secondaryText={ group.users.map(user => {return (user && user.name) + " " + (user && user.surname)}).join(", ") }
                                  showSecondaryText={true}
                                  tertiaryText={ group.id }
                                  optionalText={ "user" }
                                  onRenderPrimaryText={ this._onRenderGroupPersonaPrimaryText }
                                  size={PersonaSize.size24}
                                  className="persona my-1 mx-3"
                                />
                              )}
                            </Stack>
                          : null }
                        </li>
                      : null }
                      { selFile.groupsData ?
                        <li className="mb-2 d-none">
                          <div className="text-secondary">
                            { selFile.groupsData.length > 0 && showGroupsList ?
                              <Icon iconName="TriangleSolidDown12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showGroupsList: false}) } />
                            : selFile.groupsData.length > 0 && !showGroupsList ?
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showGroupsList: true}) } />
                            :
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" />
                            }
                            <Icon iconName="Group" className="mr-1" />
                            <span className="permissions-title">{i18n.t('app:groups2')}</span>
                            <span className="font-weight-bold">
                              { selFile.groupsData.length}
                            </span>
                          </div>
                          { showGroupsList ?
                            <Stack tokens={{ childrenGap: 10 }}>
                              { selFile.groupsData.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(group =>
                                <Persona
                                  key={ group.id }
                                  text={ group.name + " (" + group.users.length + "p.)" }
                                  secondaryText={ group.users.map(user => {return (user && user.name) + " " + (user && user.surname)}).join(", ") }
                                  showSecondaryText={true}
                                  tertiaryText={ group.id }
                                  optionalText={ "user" }
                                  onRenderPrimaryText={ this._onRenderGroupPersonaPrimaryText }
                                  size={PersonaSize.size24}
                                  className="persona my-1 mx-3"
                                />
                              )}
                            </Stack>
                          : null }
                        </li>
                      : null }
                    </ul>
                    <p className="share-section">{i18n.t('app:external')}</p>
                    <ul className="share mt-3 p-0">
                      { selFile.externalsData ?
                        <li className="mb-2 d-none">
                          <div className="text-secondary">
                            { selFile.externalsData.length > 0 && showExternalUsersList ?
                              <Icon iconName="TriangleSolidDown12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showExternalUsersList: false}) } />
                            : selFile.externalsData.length > 0 && !showExternalUsersList ?
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showExternalUsersList: true}) } />
                            :
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" />
                            }
                            <Icon iconName="Contact" className="mr-1" />
                            <span className="permissions-title">{i18n.t('app:users')}:</span>
                            { !settingItemDetails ?
                              <span className="font-weight-bold">
                                { selFile.externalsData.length }
                              </span>
                            :
                              <Spinner size={SpinnerSize.xSmall} className="text-secondary" />
                            }
                          </div>
                          { showExternalUsersList && selFile.externalsData.length > 0 ?
                            <Stack tokens={{ childrenGap: 10 }}>
                              { selFile.externalsData.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(user =>
                                user ?
                                  <Persona
                                    key={ user.id }
                                    text={ user.name + " " + user.surname }
                                    secondaryText={ user.email }
                                    tertiaryText={ user.id }
                                    optionalText={ "externalUser" }
                                    onRenderPrimaryText={ this._onRenderExternalPersonaPrimaryText }
                                    size={PersonaSize.size24}
                                    className="persona my-1 mx-3"
                                  />
                                : null
                              )}
                            </Stack>
                          : null }
                        </li>
                      : null }

                      <li className="mb-2">
                        <div className="text-secondary">
                          <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" />
                          <Icon iconName="Edit" className="mr-1" />
                          <span className="permissions-title">{i18n.t('app:editors')}:</span>
                          { !settingItemDetails ?
                            <span className="font-weight-bold">
                              0
                            </span>
                          :
                            <Spinner size={SpinnerSize.xSmall} className="text-secondary" />
                          }
                        </div>
                      </li>

                      <li className="mb-2">
                        <div className="text-secondary">
                          <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" />
                          <Icon iconName="View" className="mr-1" />
                          <span className="permissions-title">{i18n.t('app:viewers')}:</span>
                          { !settingItemDetails ?
                            <span className="font-weight-bold">
                              0
                            </span>
                          :
                            <Spinner size={SpinnerSize.xSmall} className="text-secondary" />
                          }
                        </div>
                      </li>

                      <li className="mb-2">
                        <div className="text-secondary">
                          <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" />
                          <Icon iconName="Preview" className="mr-1" />
                          <span className="permissions-title">{i18n.t('app:previewers')}:</span>
                          { !settingItemDetails ?
                            <span className="font-weight-bold">
                              0
                            </span>
                          :
                            <Spinner size={SpinnerSize.xSmall} className="text-secondary" />
                          }
                        </div>
                      </li>
                    </ul>
                    </div>

                  : activeTab === "directory" ?
                    <div>
                      <div className="text-left sticky-top" style={{padding: '11px 0', background: '#f2f2f2', borderBottom: '1px solid #bfbfbf'}}>
                      <p className="lead font-weight-bold m-0">
                        {i18n.t('app:directory')}
                      </p>
                    </div>
                    <p className="share-section">{i18n.t('app:internal')}</p>
                    <ul className="share mt-3 p-0">
                      { selFile.directoryAdmins ?
                        <li className="mb-2">
                          <div className="text-secondary">
                            { selFile.directoryAdmins.length > 0 && showAdminsList ?
                              <Icon iconName="TriangleSolidDown12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showAdminsList: false}) } />
                            : selFile.directoryAdmins.length > 0 && !showAdminsList ?
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showAdminsList: true}) } />
                            :
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" />
                            }
                            <Icon iconName="Admin" className="mr-1" />
                            <span className="permissions-title">{i18n.t('app:admins')}:</span>
                            <span className="font-weight-bold">
                              { selFile.directoryAdmins.length }
                            </span>
                          </div>
                          { showAdminsList && selFile.directoryAdmins.length > 0?
                            <Stack tokens={{ childrenGap: 10 }}>
                              { selFile.directoryAdmins.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(admin =>
                                admin ?
                                <Persona
                                  key={ admin.id }
                                  text={ admin.name + " " + admin.surname }
                                  secondaryText={ admin.email }
                                  tertiaryText={ admin.id }
                                  optionalText={ "admin" }
                                  onRenderPrimaryText={ this._onRenderInternalPersonaPrimaryText }
                                  size={PersonaSize.size24}
                                  className="persona my-1 mx-3"
                                />
                                : null
                              )}
                            </Stack>
                          : null }
                        </li>
                      : null }
                      { selFile.directoryUsers ?
                        <li className="mb-2">
                          <div className="text-secondary">
                            { selFile.directoryUsers.length > 0 && showUsersList ?
                              <Icon iconName="TriangleSolidDown12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showUsersList: false}) } />
                            : selFile.directoryUsers.length > 0 && !showUsersList ?
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showUsersList: true}) } />
                            :
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" />
                            }
                            <Icon iconName="Contact" className="mr-1" />
                            <span className="permissions-title">{i18n.t('app:users')}:</span>
                            <span className="font-weight-bold">
                              { selFile.directoryUsers.length }
                            </span>
                          </div>
                          { showUsersList && selFile.directoryUsers.length > 0 ?
                            <Stack tokens={{ childrenGap: 10 }}>
                              { selFile.directoryUsers.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(user =>
                                user ?
                                  <Persona
                                    key={ user.id }
                                    text={ user.name + " " + user.surname }
                                    secondaryText={ user.email }
                                    tertiaryText={ user.id }
                                    optionalText={ "user" }
                                    onRenderPrimaryText={ this._onRenderInternalPersonaPrimaryText }
                                    size={PersonaSize.size24}
                                    className="persona my-1 mx-3"
                                  />
                                : null
                              )}
                            </Stack>
                          : null }
                        </li>
                      : null }
                      { selFile.directoryGroups ?
                        <li className="mb-2">
                          <div className="text-secondary">
                            { selFile.directoryGroups.length > 0 && showGroupsList ?
                              <Icon iconName="TriangleSolidDown12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showGroupsList: false}) } />
                            : selFile.directoryGroups.length > 0 && !showGroupsList ?
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showGroupsList: true}) } />
                            :
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" />
                            }
                            <Icon iconName="Group" className="mr-1" />
                            <span className="permissions-title">{i18n.t('app:groups2')}</span>
                            <span className="font-weight-bold">
                              { selFile.directoryGroups.length}
                            </span>
                          </div>
                          { showGroupsList ?
                            <Stack tokens={{ childrenGap: 10 }}>
                              { selFile.directoryGroups.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(group =>
                                <Persona
                                  key={ group.id }
                                  text={ group.name + " (" + group.users.length + "p.)" }
                                  secondaryText={ group.users.map(user => {return (user && user.name) + " " + (user && user.surname)}).join(", ") }
                                  showSecondaryText={true}
                                  tertiaryText={ group.id }
                                  optionalText={ "user" }
                                  onRenderPrimaryText={ this._onRenderGroupPersonaPrimaryText }
                                  size={PersonaSize.size24}
                                  className="persona my-1 mx-3"
                                />
                              )}
                            </Stack>
                          : null }
                        </li>
                      : null }
                    </ul>
                    <p className="share-section">{i18n.t('app:external')}</p>
                    <ul className="share mt-3 p-0">
                      { selFile.directoryExternals ?
                        <li className="mb-2">
                          <div className="text-secondary">
                            { selFile.directoryExternals.length > 0 && showExternalUsersList ?
                              <Icon iconName="TriangleSolidDown12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showExternalUsersList: false}) } />
                            : selFile.directoryExternals.length > 0 && !showExternalUsersList ?
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" onClick={ () => this.setState({showExternalUsersList: true}) } />
                            :
                              <Icon iconName="TriangleSolidRight12" className="toggle-permissions-list align-baseline mr-2" />
                            }
                            <Icon iconName="Contact" className="mr-1" />
                            <span className="permissions-title">{i18n.t('app:users')}:</span>
                            <span className="font-weight-bold">
                              { selFile.directoryExternals.length }
                            </span>
                          </div>
                          { showExternalUsersList && selFile.directoryExternals.length > 0 ?
                            <Stack tokens={{ childrenGap: 10 }}>
                              { selFile.directoryExternals.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(user =>
                                user ?
                                  <Persona
                                    key={ user.id }
                                    text={ user.name + " " + user.surname }
                                    secondaryText={ user.email }
                                    tertiaryText={ user.id }
                                    optionalText={ "externalUser" }
                                    onRenderPrimaryText={ this._onRenderExternalPersonaPrimaryText }
                                    size={PersonaSize.size24}
                                    className="persona my-1 mx-3"
                                  />
                                : null
                              )}
                            </Stack>
                          : null }
                        </li>
                      : null }
                    </ul>
                    </div>

                  : activeTab === "annotations" ?
                    <div>
                      <div className="text-left sticky-top" style={{padding: '11px 0', background: '#f2f2f2', borderBottom: '1px solid #bfbfbf'}}>
                        <p className="lead font-weight-bold m-0">{i18n.t('app:annotations')}</p>
                      </div>
                      <ul className="annotations mt-3 p-0">
                        { selFile.annotations ?
                          <div>
                          { selFile.annotations.length > 0 ?
                            (selFile.annotations).map((item, key) => {
                              return (
                                <li key={ key } className="annotation mb-3 p-2" >
                                  <p className="text-secondary text-right small mb-1">By <span className="font-weight-bold">{ item.author.name }</span> on <span className="font-weight-bold">{ Moment(item.timestamp).format(i18n.t('app:dateFormat')) }</span></p>
                                  <p className="m-0" dangerouslySetInnerHTML={{__html: item.quote}}></p>
                                  <p className="text-secondary m-0 py-1 px-2">{item.text}</p>
                                </li>
                              )
                            })
                          : i18n.t('app:noAnnotations')}
                          </div>
                        : i18n.t('app:loadingAnnotations') }
                      </ul>
                    </div>

                  : activeTab === "versions" ?
                    <div>
                      <div className="text-left sticky-top" style={{padding: '11px 0', background: '#f2f2f2', borderBottom: '1px solid #bfbfbf'}}>
                        <p className="lead font-weight-bold m-0">{i18n.t('app:versions')}</p>
                      </div>
                      <div className="versions mt-3 p-0">
                        { selFile.revisions ?
                          <div>
                            <div className="w-100 text-right">
                            <ActionButton iconProps={{ iconName: 'PageAdd' }} allowDisabledFocus onClick={ this._appendFile.bind(this, selFile.id, selFile.fileName) } disabled={ selFile.draft }
                              styles={{flexContainer: { flexDirection: 'row-reverse'}}} className="d-none">
                              {i18n.t('app:appendVersion')}
                            </ActionButton>
                            </div>
                            { selFile.draft ?
                              <p className="small text-right mx-2 d-none">{i18n.t('app:saveDraft')}</p>
                            : null }

                            { compareWordDocsEnabled ?
                              <PrimaryButton onClick={() => this.props.headerRef._confirmCompareWordDocs(this._selection.getSelection(), this.state.selFile)} text={i18n.t('app:compare')} disabled={ !compareCountEnabled } className="mb-3 border-0" />
                            : null }

                            <ShimmeredDetailsList
                              items={selFile.revisions}
                              compact={true}
                              columns={versionsColumns}
                              onItemContextMenu={this._onItemContextMenu }
                              onRenderDetailsHeader={onRenderDetailsHeader}
                              selection={ this._selection }
                              selectionMode={selectionMode}
                              selectionPreservedOnEmptyClick={true}
                              setKey="set"
                              layoutMode={DetailsListLayoutMode.justified}
                              isHeaderVisible={true}
                              ariaLabelForShimmer="Content is being fetched"
                              enterModalSelectionOnTouch={true}
                              shimmerLines={12}
                              constrainMode={ConstrainMode.unconstrained}
                              listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
                              className="list-versions"
                            />
                          </div>
                          : i18n.t('app:noFileVersions')}
                        </div>
                        { contextualMenuProps && <ContextualMenu {...contextualMenuProps} /> }
                    </div>
                : activeTab === "activity" ?
                  <div>
                      <div className="text-left sticky-top" style={{padding: '11px 0', background: '#f2f2f2', borderBottom: '1px solid #bfbfbf'}}>
                      <p className="lead font-weight-bold m-0">{i18n.t('app:activity')}</p>
                    </div>
                    <div className="versions mt-3 p-0">
                      { selFile.activity ?
                        <div>
                          <ShimmeredDetailsList
                            items={selFile.activity}
                            compact={true}
                            columns={activityColumns}
                            onRenderDetailsHeader={onRenderDetailsHeader}
                            selection={ this._selection }
                            selectionMode={SelectionMode.none}
                            selectionPreservedOnEmptyClick={true}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            ariaLabelForShimmer="Content is being fetched"
                            enterModalSelectionOnTouch={true}
                            shimmerLines={12}
                            constrainMode={ConstrainMode.unconstrained}
                            listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
                            className="list-activity"
                          />
                        </div>
                        : i18n.t('app:noActivity')}
                      </div>
                  </div>
                  : null}
                </div>
              </div>
            : this.props.fileExplorerRef && this.props.fileExplorerRef._selection && this.props.fileExplorerRef._selection.getSelectedCount() > 1 ?
              <p className="lead font-weight-bold text-center my-2">{ this.props.fileExplorerRef._selection.getSelectedCount() } {i18n.t('app:itemsSelected')}</p>
            :
              <p className="lead font-weight-bold text-center my-2">{i18n.t('app:noItemSelected')}</p>
            }
          </div>

          { restoringRevision ?
            <Dialog
              hidden={!restoringRevision}
              dialogContentProps={{
                type: DialogType.normal
              }}
              modalProps={{
                isBlocking: true,
                styles: { main: { maxWidth: 640 } },
                dragOptions: undefined,
                className: "restoring-dialog"
              }}
            >
              <div className="text-center w-100">
                <h5>{i18n.t('app:restoringVersion')} <span className="spinner ml-2"><Spinner size={SpinnerSize.xSmall} /></span></h5>
              </div>
            </Dialog>
          : null }

          { convertingRevision ?
            <Dialog
              hidden={!convertingRevision}
              dialogContentProps={{
                type: DialogType.normal
              }}
              modalProps={{
                isBlocking: true,
                styles: { main: { maxWidth: 640 } },
                dragOptions: undefined,
                className: "converting-version-dialog"
              }}
            >
              <div className="text-center w-100">
                <h5>{i18n.t('app:convertingVersion')} <span className="spinner ml-2"><Spinner size={SpinnerSize.xSmall} /></span></h5>
              </div>
            </Dialog>
          : null }

          { revertingVersion ?
            <Dialog
              hidden={!revertingVersion}
              dialogContentProps={{
                type: DialogType.normal
              }}
              modalProps={{
                isBlocking: true,
                styles: { main: { maxWidth: 640 } },
                dragOptions: undefined,
                className: "converting-version-dialog"
              }}
            >
              <div className="text-center w-100">
                <h5>{i18n.t('app:revertingVersion')} <span className="spinner ml-2"><Spinner size={SpinnerSize.xSmall} /></span></h5>
              </div>
            </Dialog>
          : null }

          { appendingVersion ?
            <Dialog
              hidden={!appendingVersion}
              dialogContentProps={{
                type: DialogType.normal
              }}
              modalProps={{
                isBlocking: true,
                styles: { main: { maxWidth: 640 } },
                dragOptions: undefined,
                className: "restoring-dialog"
              }}
            >
              <div className="text-center w-100">
                <h5>{i18n.t('app:appendingVersion')} <span className="spinner ml-2"><Spinner size={SpinnerSize.xSmall} /></span></h5>
              </div>
            </Dialog>
          : null }

          { saveDraftData && showSaveDraftDialog ?
            <Dialog
              hidden={!showSaveDraftDialog}
              dialogContentProps={{
                type: DialogType.normal,
                //title: i18n.t('app:saveVersionFromDraft') +" "+ saveDraftData.fileName
              }}
              modalProps={{
                styles: { main: { maxWidth: 720 }, },
                dragOptions: undefined,
                className: "saveDraft"
              }}
            >
              <div className="ms-Grid" dir="ltr">
                <div className="row m-0">
                  <div className="col-7 p-0" style={{maxHeight: '540px'}}>
                    <div className="save-version">
                      <div className="header-section header-left">
                        <img src={ process.env.PUBLIC_URL + "/img/save-icon.svg" } className="mr-2" alt="Save version" style={{width:'16px', height: '16px'}} />{i18n.t('app:saveVersion')}
                      </div>
                      <div className='save-version-wrapper m-3 pb-2 d-flex' style={{backgroundColor: 'white', flexDirection: 'column'}}>
                      <div className='scrollable flex-grow-1 pt-3'>
                        <Text className='px-3' variant={'medium'} block style={{fontWeight: 600}}>
                            {i18n.t('app:filename')}
                        </Text>
                        <div className="px-3 my-2 d-flex align-items-center">
                          <Icon style={{height: '24px'}} className='mr-2' {...getFileTypeIconProps({ extension: selFile.fileExtension, size: 20, imageFileType: 'svg' }) } />
                          <Text variant={'medium'}>
                            {selFile.name}
                          </Text>
                        </div>
                          <ChoiceGroup defaultSelectedKey="minor"
                          options={[
                            { key: "overwrite", 
                              text: "",
                              onRenderField: (props: any, defaultRender: any) => {
                                return <>
                                  {defaultRender!({
                                    ...props
                                  })}
                                  {saveDraftData.activeMinor !== "00" ? 
                                    <>
                                      <span className={"badge version p-1 ml-1 mr-2"} style={{border: '2px solid #C7E0F4'}}>
                                        {saveDraftData.activeMajor + "." + saveDraftData.activeMinor}
                                      </span>{i18n.t('app:overwriteCurrentMinorVersion')}
                                    </>
                                    : 
                                    <>
                                      <span className={"badge version p-1 ml-1 mr-2"} style={{border: '2px solid #007bff'}}>
                                        {saveDraftData.activeMajor + ".00"}
                                      </span>{i18n.t('app:overwriteCurrentMajorVersion')}
                                    </>
                                  }
                                </>
                              }
                            },
                            {
                              key: "minor", 
                                text: "", 
                                onRenderField: (props: any, defaultRender: any) => {
                                  return <>
                                    {defaultRender!({
                                      ...props
                                    })}
                                    <span className={"badge version p-1 ml-1 mr-2 badge-primary-light"}>
                                      {(saveDraftData.activeMajor + "." + incrementMinorVersion(saveDraftData.activeMinor))}
                                    </span>{i18n.t('app:minorVersionInternal')}
                                  </>
                                }
                              },
                              { key: "major", 
                                text: "",
                                onRenderField: (props: any, defaultRender: any) => {
                                  return <>
                                    {defaultRender!({
                                      ...props
                                    })}
                                    <span className={"badge version p-1 ml-1 mr-2 badge-primary"}>
                                      {(saveDraftData.activeMajor + 1) + ".00"}
                                    </span>{i18n.t('app:majorVersionExternal')}
                                  </>
                                }
                              },
                          ]}
                          label={i18n.t('app:saveAs')}
                          style={{textAlign:'left'}}
                          className="saveVersionAs mb-2 px-3"
                          disabled={ savingDraft }
                          onChange={ this.handleMajorMinorChange } 
                        />
                        <form className='my-3 px-3' name="form" onSubmit={this._saveVersionFromDraft} >
                          <TextField resizable={false} autoFocus placeholder={i18n.t('app:versionMessage')} name="newMessage" value={ newSavedDraftMessage } onChange={ this.handleDraftVersionMessageChange } disabled={ savingDraft } multiline rows={5} />
                        </form>
                        </div>
                        <DialogFooter>
                          { savingDraft ?
                            <Spinner size={SpinnerSize.xSmall} className="d-inline-block" />
                          : null }
                          <DefaultButton className='mr-2' onClick={ev => this.setState({showSaveDraftDialog: false, newSavedDraftMessage: i18n.t('app:minorVersion')})} text={i18n.t('app:cancel')} />
                          <PrimaryButton className='mr-3' onClick={this._saveVersionFromDraft} text={i18n.t('app:save')} disabled={ savingDraft } />                   
                        </DialogFooter>
                      </div>

                    </div>
                  </div>
                  <div className="col-5 p-0" style={{maxHeight: '540px'}}>
                    <div className="versions-history">
                      <div className="header-section" style={{fontWeight: 600}}>
                          <img src={ process.env.PUBLIC_URL + "/img/versions-icon.svg" } style={{width:'16px', height: '16px'}} className="mr-2" alt="Versions history" /> Versions
                      </div>
                      <div className="versions-wrapper">
                        <ShimmeredDetailsList
                        items={selFile.revisions}
                        compact={true}
                        columns={versionsColumns}
                        onRenderDetailsHeader={onRenderDetailsHeader}
                        selectionMode={SelectionMode.none}
                        selectionPreservedOnEmptyClick={true}
                        setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        ariaLabelForShimmer="Content is being fetched"
                        enterModalSelectionOnTouch={true}
                        shimmerLines={12}
                        constrainMode={ConstrainMode.unconstrained}
                        listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
                        className="list-versions"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </Dialog>
          : null }

          { selVersion && editVersionMessage ?
            <Dialog
              hidden={!editVersionMessage}
              onDismiss={ev => this.setState({editVersionMessage: false})}
              dialogContentProps={{
                type: DialogType.normal,
                title: i18n.language==='de' ? i18n.t('app:editMessageVersion') + selVersion.version + i18n.t('app:editMessageVersion2') + ":" : i18n.t('app:editMessageVersion') + selVersion.version + ":"
              }}
              modalProps={{
                styles: { main: { maxWidth: 640 } },
                dragOptions: undefined,
                className: "edit-message"
              }}
            >
              <form name="form" onSubmit={this._editversionMessage} >
                <TextField autoFocus placeholder={i18n.t('app:versionMessage')} name="newMessage" value={ newVersionMessage } onChange={ this.handlVersionMessageChange } disabled={ savingVersionMessage } multiline rows={3} />
              </form>

              <DialogFooter>
                { savingVersionMessage ?
                  <Spinner size={SpinnerSize.xSmall} className="d-inline-block" />
                : null }
                <DefaultButton onClick={ev => this.setState({editVersionMessage: false})} text={i18n.t('app:cancel')} />
                <PrimaryButton onClick={this._editversionMessage} text={i18n.t('app:save')} disabled={ savingVersionMessage } />                   
              </DialogFooter>
            </Dialog>
          : null }

          { deletingRevision ?
            <Dialog
              hidden={!deletingRevision}
              dialogContentProps={{
                type: DialogType.normal
              }}
              modalProps={{
                isBlocking: true,
                styles: { main: { maxWidth: 640 } },
                dragOptions: undefined,
                className: "deleting-revision-dialog"
              }}
            >
              <div className="text-center w-100">
                <h5>{i18n.t('app:deletingVersion')} <span className="spinner ml-2"><Spinner size={SpinnerSize.xSmall} /></span></h5>
              </div>
            </Dialog>
          : null }
          
          { undoingDeleteRevision ?
            <Dialog
              hidden={!undoingDeleteRevision}
              dialogContentProps={{
                type: DialogType.normal
              }}
              modalProps={{
                isBlocking: true,
                styles: { main: { maxWidth: 640 } },
                dragOptions: undefined,
                className: "undoing-delete-revision-dialog"
              }}
            >
              <div className="text-center w-100">
                <h5>{i18n.t('app:restoringDeletedVersion')} <span className="spinner ml-2"><Spinner size={SpinnerSize.xSmall} /></span></h5>
              </div>
            </Dialog>
          : null }

          { deletingDraft ?
            <Dialog
              hidden={!deletingDraft}
              dialogContentProps={{
                type: DialogType.normal
              }}
              modalProps={{
                isBlocking: true,
                styles: { main: { maxWidth: 640 } },
                dragOptions: undefined,
                className: "deleting-draft-dialog"
              }}
            >
              <div className="text-center w-100">
                <h5>{i18n.t('app:deletingDraft')} <span className="spinner ml-2"><Spinner size={SpinnerSize.xSmall} /></span></h5>
              </div>
            </Dialog>
          : null }
          {this.state.showColorPick && 
            <Callout 
              onDismiss={()=>this._isMounted && this.setState({showColorPick: false})}
              target={'#colorTagPicker'}
            >
              <aside className='ml-2' style={{fontWeight: 600, padding: '5px 0px'}}>{i18n.t('app:colorTag')}:</aside>
              <SwatchColorPicker
                columnCount={5}
                cellShape={'circle'}
                cellHeight={24}
                cellWidth={24}
                cellBorderWidth={3}
                colorCells={this.tagColors.colors}
                onChange={(e,v)=>this._handleColorPickerChange(v)}
                styles={{root: {marginTop: 0}}}
                onRenderColorCell={(props:any, defaultRender:any)=>{
                  if(props.id === '0') {
                   return <div title={i18n.t('app:noColor')} className='w-100 h-100 d-flex'><Icon onClick={()=>this._handleColorPickerChange('0')} className='no-color m-auto' style={{fontSize: 24}} iconName='ErrorBadge'/></div>
                  } else {
                    return<>
                      {defaultRender!({
                        ...props
                      })}
                    </>
                  }
                }}
              />
            </Callout>
          }  
        </Resizable> : null }
        {selFile ? <FluentProvider theme={webLightTheme} className={'tab-list ' + this.props.appPlatform} style={{borderLeft: showRightPanel ? '1px solid #f2f2f2' : '1px solid rgb(237, 235, 233)'}}>
            <TabList selectedValue={activeTab} onTabSelect={this._handleLinkClick} vertical>
              <Tab icon={<Icon iconName='Info'/>} value="info" style={{height:'44px'}}></Tab>
              { selFile.fileType === 'file' ?
                <Tab icon={<Icon iconName='Preview'/>} value="preview" style={{height:'44px'}}></Tab>
              : null }

              { ActionsPermissions("viewFileVersions", this.state.folderRole) && selFile.fileType === 'file' ?
                <Tab icon={<Icon iconName='FullHistory'/>} value="versions" style={{height:'44px'}}></Tab>
              : null }

              { selFile.fileType === 'file' ?
                <Tab icon={<Icon iconName='Search'/>} value="coincidences" style={{height:'44px'}}></Tab>
              : null }

              { ActionsPermissions("viewFileActivity", this.state.folderRole) && selFile.fileType === 'file' ?
                <Tab icon={<Icon iconName='LineChart'/>} value="activity" style={{height:'44px'}}></Tab>
              : null }

              { ActionsPermissions("viewFolderPermissions", this.state.folderRole) && ((selFile.fileType === 'dir' && selFile.accessGranted)
                || (selFile.fileType === 'repo' && isRepoAdmin)) ?
                <Tab icon={<Icon iconName='FabricUserFolder'/>} value="share" style={{height:'44px'}}></Tab>
              : null }

              { /*selFile.fileType === 'repo' && isRepoAdmin ?
                  <Tab icon={<Icon iconName='OfflineStorage'/>} value="directory" style={{height:'44px'}}></Tab>
              : null */}

              { ActionsPermissions("viewFileDetails", this.state.folderRole) && (selFile.fileType === 'file' || (selFile.fileType === 'repo' && isRepoAdmin) || selFile.accessGranted) ?
                <Tab icon={<Icon iconName='DietPlanNotebook'/>} value="details" style={{height:'44px'}}></Tab>
              : null }
            </TabList>
          </FluentProvider> 
        : <FluentProvider theme={webLightTheme} className={'tab-list ' + this.props.appPlatform} style={{width: '47px', borderLeft: showRightPanel ? '1px solid #f2f2f2' : '1px solid rgb(237, 235, 233)'}}>
            <TabList selectedValue={activeTab} onTabSelect={this._handleLinkClick} vertical></TabList>
          </FluentProvider>
        }
      </div>
    )
  }

  private showColorPicker() {
    this._isMounted && this.setState({showColorPick: true})
  }

  private getColorTag(key:string = '0') {
    let color = this.tagColors.colors.find((item)=>{
      return item.id === key.toString();
    })
    return key.toString() === '0' ? 'transparent' : color ? color.color : 'transparent'
  }

  private _handleColorPickerChange(color: any) {
    let item = this.state.selFile;
    let items = this.props.items.slice(0);
    let oldColor = item.colorTag;
    item.colorTag = 'loading'
    for (let i=0; i< items.length; i++) {
      if (items[i].key === item.key) {items[i] = item;}
    }
    let key = item.key || item.id
    this.props.callbackFunction({items: items})
    this._isMounted && this.setState({showColorPick: false});
    if (item.fileType === 'dir') {
      userService.updateColorTagFolders([key], color).then((response)=> {
        this.props.getCurrentContent()
        item.colorTag = color
        for (let i=0; i< items.length; i++) {
          if (items[i].key === item.key) {items[i] = item;}
        }
        this.props.callbackFunction({items: items})
      }).catch((error)=>{
        console.log(error)
        item.colorTag = oldColor
        for (let i=0; i< items.length; i++) {
          if (items[i].key === item.key) {items[i] = item;}
        }
        this.props.callbackFunction({items: items})
      })
    } else {
      userService.updateColorTagDocuments([key], color).then((response)=> {
        this.props.getCurrentContent()
        item.colorTag = color
        for (let i=0; i< items.length; i++) {
          if (items[i].key === item.key) {items[i] = item;}
        }
        this.props.callbackFunction({items: items})
      }).catch((error)=>{
        console.log(error)
        item.colorTag = oldColor
        for (let i=0; i< items.length; i++) {
          if (items[i].key === item.key) {items[i] = item;}
        }
        this.props.callbackFunction({items: items})      
      })
    }
  }

  private _customPivotRenderer(props: any, defaultRender?: IRenderFunction<IPivotItemProps>): JSX.Element {
    const { selFile } = this.state;
    var key = props.itemKey;

    return (
      <span className="align-middle">
        {defaultRender!({
          ...props
        })}
        { key === "coincidences" ?
          <span className="ml-1">
            { selFile.annotations ?
              <span className="badge badge-primary">{ selFile.highlights.length }</span>
            :
              <span><Spinner size={SpinnerSize.xSmall} /></span>
            }
          </span>
        : key === "annotations" ?
          <span className="ml-1">
            { selFile.annotations ?
              <span className="badge badge-primary">{ selFile.annotations.length }</span>
            :
              <span><Spinner size={SpinnerSize.xSmall} /></span>
            }
          </span>
        : key === "versions" ?
          <span className="ml-1">
            { selFile.revisions ?
              <span className="badge badge-primary">{ selFile.revisionsCount }</span>
            :
              <Spinner size={SpinnerSize.xSmall} />
            }
          </span>
        : null }
      </span>
    );
  }

  private _saveResizeState() {
    const { panelHeight, panelWidth } = this.state;
    
    var prefs = {
      height: panelHeight,
      width: panelWidth
    }

    localStorage.setItem("detailsPanelPrefs", JSON.stringify(prefs))
  }

  private _onVersionsColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { versionsColumns, selFile } = this.state;
    const newColumns: IColumn[] = versionsColumns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    var newItems = _copyAndSort(selFile.revisions, currColumn.fieldName!, currColumn.isSortedDescending);

    var newSelFile = this.state.selFile;
    newSelFile.revisions = newItems

    this.setState({
      versionsColumns: newColumns,
      selFile: newSelFile
    });
  };

  private _onActivityColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { activityColumns, selFile } = this.state;
    const newColumns: IColumn[] = activityColumns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    var newItems = _copyAndSort(selFile.activity, currColumn.fieldName!, currColumn.isSortedDescending);

    var newSelFile = this.state.selFile;
    newSelFile.activity = newItems

    this.setState({
      activityColumns: newColumns,
      selFile: newSelFile
    });
  };

  private _getFolderCount(folder) {
    if(this.state.activeTab === "details" && this.state.selFile && (this.state.selFile.fileType !== "trash" && (this.state.selFile.accessGranted || this.state.selFile.fileType === "repo"))) {
      this._isMounted && this.setState({ calculatingSize: true })
      
      userService.getFolderCount(folder).then(response => {
        let folderData:any = response.data
        var selFolder: any = this.state.selFile;

        selFolder.directFoldersCount = folderData.numberOfImmediateFolders;
        selFolder.totalFoldersCount = folderData.numberOfFolders;
        selFolder.directFilesCount = folderData.numberOfImmediateDocuments;
        selFolder.totalFilesCount = folderData.numberOfDocuments;
        selFolder.totalSize = folderData.size;
        selFolder.countRequested = true;

        this._isMounted && this.setState({
          calculatingSize: false,
          selFile: selFolder
        })
      }).catch(error => {
        console.log(error)
        this._isMounted && this.setState({ calculatingSize: false })
      })
    }
  }

  private _removeInternalPermissions(folderId, user) {
    userService.removeInternalPermissions(folderId, [user], true).then(response => {
    }).catch(error => {
      console.log(error)
    })
  }

  private _removeGroupPermissions(folderId, group) {
    userService.removeGroupPermissions(folderId, [group], true).then(response => {
    }).catch(error => {
      console.log(error)
    })
  }

  private _removeExternalPermissions(folderId, user) {
    userService.removeExternalPermissions(folderId, [user], true).then(response => {
    }).catch(error => {
      console.log(error)
    })
  }

  private _importCitation(id) {
    var input = document.createElement('input');
    input.type = 'file';

    input.onchange = e => {
      let event:any = e as Event;
      event.stopPropagation();
      event.preventDefault();

      const file = event.target.files[0]

      let dropFormData = new FormData();
      dropFormData.append('file', file, file.name);

      userService.importCitation(dropFormData).then((response: any) => {
        var citationJson = response

        userService.citationToCsl(citationJson).then((response: any) => {
          var citation = JSON.parse(response)[0]
          userService.saveCitation(id, citation).then((response: any) => {
              // Save citation success
            }).catch(error => {
              console.log(error)
            });
        }).catch(error => {
          console.log(error)
        });

      }).catch(error => {
        alert(error.responseText)
        console.log(error)
      });
    }
    input.click();
  }

  private _deleteCitation(id) {
    userService.deleteCitation(id).then((response: any) => {
      // Delete citation success
      }).catch(error => {
        console.log(error)
      });
  }

  private _appendFile(id, fileName) {
    var input = document.createElement('input');
    input.type = 'file';

    input.onchange = e => {
      let event:any = e as Event;
      event.stopPropagation();
      event.preventDefault();

      this._isMounted && this.setState({appendingVersion: true})

      const file = event.target.files[0]
      let dropFormData = new FormData();
      dropFormData.append('file', file, fileName);

      var message = "Appended version"
      var major = true

      userService.saveVersion(dropFormData, id, message, major, false).then((response: any) => {
        this._isMounted && this.setState({appendingVersion: false})

        var revision = response.data
        revision.version = revision.major + "." + revision.minor;

        this.setState({
          editVersionMessage: true,
          editVersionMessageId: id,
          selVersion: revision,
          newVersionMessage: message
        })
      });

    }

    input.click();
  }

  private _saveVersionFromDraft = (e) => {
    e.stopPropagation();
    e.preventDefault();
    
    const { saveDraftData, newSavedDraftMessage, saveAsMajorVersion } = this.state;

    this._isMounted && this.setState({ savingDraft: true })

    var id = saveDraftData.id;

    if (saveAsMajorVersion === 'overwrite') {
      userService.overwriteVersionFromDraft(id, newSavedDraftMessage).then(response => {
        this._isMounted && this.setState({
          savingDraft: false,
          showSaveDraftDialog: false,
          newSavedDraftMessage: i18n.t('app:minorVersion')
        });
      }).catch(error => {
        console.log(error)
        this._isMounted && this.setState({
          savingDraft: false,
          showSaveDraftDialog: false,
          newSavedDraftMessage: i18n.t('app:minorVersion')
        });
      })
    } else {
      userService.saveVersionFromDraft(id, newSavedDraftMessage, saveAsMajorVersion === 'major').then(response => {
        this._isMounted && this.setState({
          savingDraft: false,
          showSaveDraftDialog: false,
          newSavedDraftMessage: i18n.t('app:minorVersion')
        });
      }).catch(error => {
        console.log(error)
        this._isMounted && this.setState({
          savingDraft: false,
          showSaveDraftDialog: false,
          newSavedDraftMessage: i18n.t('app:minorVersion')
        });
      })
    }
  }

  private handleDraftVersionMessageChange = (ev: any, newValue?: string) => {
    this.setState({ newSavedDraftMessage: newValue || ''});
  }

  private _editversionMessage = (e) => {
    e.stopPropagation();
    e.preventDefault();
    
    const { editVersionMessageId, selVersion, newVersionMessage } = this.state;

    this._isMounted && this.setState({ savingVersionMessage: true })

    var id = editVersionMessageId || "";
    var revisionId = selVersion.fileId;

    userService.editVersionMessage(id, revisionId, newVersionMessage || "").then(response => {
      this._isMounted && this.setState({
        savingVersionMessage: false,
        editVersionMessage: false,
        editVersionMessageId: "",
        newVersionMessage: ""
      });

      if(this.props.fileExplorerType === "recentFiles") {
        history.push({
          search: '?selItem=' + id
        })
      }
    })
  }
  private handlVersionMessageChange = (ev: any, newValue?: string) => {
    this.setState({ newVersionMessage: newValue || ''});
  }

  private handleMajorMinorChange = (ev: any, newValue?: any) => {
    this.setState({ saveAsMajorVersion: newValue.key });

    var minorVersionDefaultMsg = i18n.t('app:minorVersion');
    var majorVersionDefaultMsg = i18n.t('app:majorVersion');
    var overwriteMsgDefault = i18n.t('app:overwriteVersion')

    if(newValue.key === "major" && (this.state.newSavedDraftMessage === minorVersionDefaultMsg || this.state.newSavedDraftMessage === overwriteMsgDefault)) {
      this.setState({
        newSavedDraftMessage: majorVersionDefaultMsg
      })
    } else if (newValue.key === "minor" && (this.state.newSavedDraftMessage === majorVersionDefaultMsg || this.state.newSavedDraftMessage === overwriteMsgDefault)) {
      this.setState({
        newSavedDraftMessage: minorVersionDefaultMsg
      })
    } else if (newValue.key === "overwrite" && (this.state.newSavedDraftMessage === majorVersionDefaultMsg || this.state.newSavedDraftMessage === minorVersionDefaultMsg)) {
      this.setState({
        newSavedDraftMessage: overwriteMsgDefault
      })
    }
  }

  private _openUrl(url) {
    if(this.props.appPlatform === "electron") {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.send("open-browser-url", url)
    } else {
      window.open(url, "_blank");
    }
  }

  private _handleLinkClick = (e:SelectTabEvent, data: any): void => {
    this._isMounted && this.setState({
      activeTab: data.value
    });
    localStorage.setItem("detailsPanelActiveTab", data.value)
    if (this._isMounted && this.state.activeTab === data.value) this.props.callbackFunction({showRightPanel: !this.state.showRightPanel})
    else if(this._isMounted && this.state.activeTab !== data.value) this.props.callbackFunction({showRightPanel: true})
  };

  private _getTabId = (itemKey: string): string => {
    return `${itemKey}`;
  };

  private _isOfficeOnlineDoc(fileExtension) {
    var isOfficeOnlineDoc = officeOnlineExtensions.filter(ext => {
      return fileExtension === ext
    })

    return isOfficeOnlineDoc.length > 0;
  }

  private _searchInFile = (e) => {
    if(e) {
      e.preventDefault()
    }
    
    const { selFile, searchInFileTerms} = this.state

    if(searchInFileTerms.length > 0) {

      this.setState({
        searchInFileInit: true,
        searchingInFile: true
      })
      userService.getCoincidences(selFile.id, searchInFileTerms)
        .then((response: any) => {
          selFile.highlights = response.data;
          
          var currentSelFile = this.props.fileExplorerRef._selection.getSelection()[this.props.fileExplorerRef._selection.getSelection().length - 1];
          this._isMounted && this.state.selFile && currentSelFile.key === selFile.key && this.props.fileExplorerRef._selection.getSelectedCount() === 1 && this.setState({
            searchingInFile: false,
            selFile: selFile
          });
        }).catch(error => {
          console.log(error)
          this.setState({searchingInFile: false})
        });
    }
  }

  private handleSearchInFileTermsChange = (ev: any, newValue?: string) => {
    this.setState({ searchInFileTerms: newValue || ''});
  }

  private addBookmark(item) {
    this._isMounted && this.setState({ savingBookmark: true });

    let ids: any[] = [];
    let id = item.id;
    ids.push(id)
    var isFolder = item.fileType === "dir"

    userService.addBookmark(item.repo, item.id, isFolder)
    .then((response: any) => {
      this._isMounted && this.setState({
        savingBookmark: false
      });
    }).catch(error => {
      console.log(error)
    });
  }

  private removeBookmark(item) {
    this._isMounted && this.setState({ savingBookmark: true });

    var isFolder = item.fileType === "dir" 

    userService.removeBookmark(item.repo, item.id, isFolder)
    .then((response: any) => {
      this._isMounted && this.setState({
        savingBookmark: false
      });
    }).catch(error => {
      console.log(error)
    });
  }

  private handleTagChange = (ev: any, option?: any, index?: number, value?: string) => {
    let key:string = '';
    if (option) key = option.key;
    if (!option && value) {
      key = value;
    }
    this._isMounted && this.setState({newTag: key})
  }

  private addTag = (e) => {
    e.preventDefault();

    this.setState({ savingTag: true })
    const { newTag, selFile } = this.state;

    if (newTag) {
        if(!selFile.tags) {
          selFile.tags = [];
        }

        let ids: any[] = [];
        let id = selFile.id;
        ids.push(id)

        /*userService.addTag(ids, newTag)
        .then((response: any) => {
          this.getItemDetails(selFile);

          this.setState({
            savingTag: false,
            newTag: ''
          });
        }).catch(error => {
          this.setState({ savingTag: false })
          console.log(error)
        });*/
    }
  }

  private deleteTag(tagId, cascade) {

    const { selFile } = this.state;

    var folders:any = [];
    var files:any = [];

    if (selFile.kind !== "file") folders.push(selFile.id)
    else files.push(selFile.id)

    userService.removeTag(folders, files, tagId, cascade)
      .then((response) => {
        if (this.state.selFile) this._getTags(this.state.selFile)
      }).catch(error => {
        console.log(error)
      });
  }

  private goToTag(e, tag, repo) {
    e.stopPropagation();

    const params = new URLSearchParams();
    params.set("text", "");
    params.set("tags", tag);
    params.set("name", "");
    params.set("parent", repo);

    history.push("/tag/?" + params.toString());
  }

  private handleCommentChange = (ev: any, newValue?: string) => {
    this.setState({ newComment: newValue || ''});
  }

  private addComment = (e) => {
    e.preventDefault();

    this.setState({ savingComment: true })
    const { newComment, selFile } = this.state;
    const { userData } = this.props;
    
    var author = {
      id: userData.user.id,
      email: userData.user.email,
      name: userData.name + " " + userData.user.surname

    }

    if (newComment) {
        if(!selFile.comments) {
          selFile.comments = [];
        }
        
        userService.addComment(selFile.id, newComment, author)
        .then((response: any) => {
          this.setState({
            savingComment: false,
            newComment: ''
          });
          this._getSelectionDetails();
        }).catch(error => {
          this.setState({ savingComment: false })
          console.log(error)
        });
    }
  }

  private deleteComment(commentId) {

    userService.deleteComment(commentId)
    .then((response: any) => {
      this._getSelectionDetails();
    }).catch(error => {
      console.log(error)
    });
  }

  private handleColorChange = (event:any, value:any) => {
    this._isMounted && this.setState({tagColor: value});
  }

  private _setSelItem(): string {
    const selectionCount = this.props.fileExplorerRef._selection.getSelectedCount();
    var lastSelFile:any = this.props.fileExplorerRef._selection.getSelection()[this.props.fileExplorerRef._selection.getSelection().length - 1] || [];

    switch (selectionCount) {
      case 0:
        if (this.props.fileExplorerType === "fileExplorer" && this.props.folderId) {
          if(!this.state.selFile || (this.state.selFile && this.state.selFile.id !== this.props.folderId)) {
            if(this.props.userData && this.props.foldersList) this.getFolderRole(this.props.folderId, this.props.userData, this.props.foldersList)
            this._setNavFolder(this.props.folderId)
          }
        }
        return i18n.t('app:noItemsSelected');
      case 1:
        if (lastSelFile.fileType === "file") {
          setTimeout(() => {
            if (this.props.fileExplorerRef._selection.getSelection()[0] && (lastSelFile.id === this.props.fileExplorerRef._selection.getSelection()[0].id)) {
              this._getFileExtraDetails(lastSelFile)
            }
          }, 600);
        } else {
          lastSelFile.annotations = [];
          this._isMounted && this.setState({settingItemDetails: true})
          setTimeout(() => {
            if ((this.props.fileExplorerRef._selection.getSelection()[0] && (lastSelFile.id === this.props.fileExplorerRef._selection.getSelection()[0].id)) || (lastSelFile && this.state.selFile && lastSelFile.id ===  this.state.selFile.id)) {
              this._getFolderExtraDetails(lastSelFile)
              this._isMounted && this.setState({settingItemDetails: false})
            }
          }, 600);
        }

        this._isMounted && this.props.fileExplorerRef._selection.getSelectedCount() === 1 && this.setState({
          selFile: lastSelFile
        })
        return i18n.t('app:itemSelected') + (this.props.fileExplorerRef._selection.getSelection()[0] as IDocument).name;
      default:
        this._isMounted && this.setState({
          selFile: null
        });
        return `${selectionCount} `+i18n.t('app:itemsSelected');
    }
  }

  private _getSelectionDetails(getLockOnly?: boolean): string {
    const selectionCount = this.props.fileExplorerRef._selection.getSelectedCount();
    var lastSelFile:any = this.state.selFile || [];

    switch (selectionCount) {
      case 0:
        return i18n.t('app:noItemsSelected');
      case 1:
        if (lastSelFile.fileType === "file") {
          this.getItemDetails(lastSelFile, getLockOnly)
        } else {
          lastSelFile.accessGranted && this.getFolderDetails(lastSelFile);
        }

        this._isMounted && this.props.fileExplorerRef._selection.getSelectedCount() === 1 && this.setState({
          selFile: lastSelFile
        })
        return i18n.t('app:itemSelected') + (this.props.fileExplorerRef._selection.getSelection()[0] as IDocument).name;
      default:
        this._isMounted && this.setState({
          selFile: this.props.fileExplorerRef._selection.getSelection()
        });
        return `${selectionCount} `+i18n.t('app:itemsSelected');
    }
  }

  private async _setNavFolder(folderId) {
    var navFolder = this.props.foldersList && this.props.foldersList.filter(folder => {
      return  folderId === folder.id
    })[0]
    var repoUsers = this.props.repoUsers;
    if (!navFolder && this.props.foldersList && this.props.repoData && folderId !== this.props.repoData[0].id) {
      let userId = this.props.userData.user.id
      let groups = this.props.userData.groups.map(userGroup => {
          return userGroup.id
      })
      await userService.checkItemPermissions(folderId, userId, groups).then(async response => {
        var permissionsGranted = response.data;
        if(permissionsGranted) {
          await userService.getFolderDetails(folderId).then((response)=>{
            let folder = response.data;
            this.props.addItemsToFoldersList([folder]);
            navFolder = folder;
          })
        }
      })
      
    }

    if (navFolder && !navFolder.isRepo) {
      navFolder.fileName = navFolder.name
      var fileType = "dir"
      navFolder.fileType = fileType

      var accessGranted: boolean = false;
      var userGroups = this.props.userData.groups.map(userGroup => {
        return userGroup.id
      })

      var isUser = navFolder.users.filter(user => {
        return user.id === this.props.userData.user.id
      })

      var isAdmin = navFolder.admins.filter(admin => {
        return admin === this.props.userData.user.id
      })

      var groupAccess = navFolder.groups.filter(group => {
        return userGroups.includes(group.id)
      })

      var isExternal = navFolder.externals.filter(externalUser => {
        return externalUser === this.props.userData.user.id
      })

      if(isAdmin.length > 0
        || isUser.length > 0
        || groupAccess.length > 0
        || isExternal.length > 0)
        accessGranted = true
      
      navFolder.accessGranted = accessGranted

      let adminsData: any = [];
      let usersData: any = [];
      let groupsData: any = [];
      let externalsData: any = [];

      navFolder.admins && navFolder.admins.forEach(admin => {
        var adminData = repoUsers.filter(repoUser => {
          return repoUser.id === admin
        })[0]
        if(adminData) adminsData.push(adminData)
      })

      navFolder.users && navFolder.users.forEach(user => {
        var userData = repoUsers.filter(repoUser => {
          return repoUser.id === user.id
        })[0]
        if(userData) {
          userData.role = user.role
          usersData.push(userData)
        }
      })

      navFolder.groups && navFolder.groups.forEach(group => {
        var groupData = repoUsers.groups.filter(repoGroup => {
          return repoGroup.id === group.id
        })[0]
        if(groupData) {
          groupData.role = group.role
          groupsData.push(groupData)
        }
      })

      navFolder.externals && navFolder.externals.forEach(user => {
        var userData = repoUsers.filter(repoUser => {
          return repoUser.id === user.id
        })[0]
        if(userData) externalsData.push(userData)
      })

      navFolder.adminsData = adminsData
      navFolder.usersData = usersData
      navFolder.groupsData = groupsData
      navFolder.externalsData = externalsData

      var bookmark: any = navFolder.bookmarks && navFolder.bookmarks.filter(bookmarkData => {
        return bookmarkData.user_id === this.props.userData.user.id
      })[0] !== undefined;

      navFolder.bookmark = bookmark

      this._isMounted && this.setState({
        selFile: navFolder
      }, async () => {
        //this.getFolderDetails(navFolder)
        this._getFolderCount(navFolder.id)
        let role = await FolderRole(navFolder.id, this.props.userData, this.props.foldersList)
        if ( role < 10 && this.getTags !== navFolder.id){
          if (!navFolder.sortedTags) this.getTags = navFolder.id
          this._getTags(this.state.selFile)
        }
      });
    } else {
      var isRepo = this.props.userData && this.props.userData.repository.id === folderId
      if(isRepo) navFolder = this.props.userData.repository

      if (navFolder) {
        navFolder.fileType = "repo"
        navFolder.fileName = navFolder.name

        let currentRepoUsers: any = [];

        repoUsers.forEach(repoUser => {
          var matchRepo = repoUser.repository
          
          if (matchRepo) {
            var user = repoUser
            user.role = matchRepo.role;

            currentRepoUsers.push(user)
          }
        })
        
        navFolder.directoryAdmins = this.props.userData.user.role === "ADMIN"
        navFolder.directoryUsers = this.props.userData.user.role ==="INTERNAL"
        navFolder.directoryExternals = this.props.userData.user.role === "EXTERNAL"

        navFolder.directoryGroups = repoUsers.groups;

        let rootFolderAccess = repoUsers.rootFolderAccess;
        let adminsData: any = [];
        let usersData: any = [];
        let groupsData: any = [];
        let externalsData: any = [];

        rootFolderAccess.admins && rootFolderAccess.admins.forEach(admin => {
          var adminData = repoUsers.filter(repoUser => {
            return repoUser.id === admin
          })[0]
          if(adminData) adminsData.push(adminData)
        })

        rootFolderAccess.users && rootFolderAccess.users.forEach(user => {
          var userData = repoUsers.filter(repoUser => {
            return repoUser.id === user.id
          })[0]
          if(userData) {
            userData.role = user.role
            usersData.push(userData)
          }
        })

        rootFolderAccess.groups && rootFolderAccess.groups.forEach(group => {
          var groupData = repoUsers.groups.filter(repoGroup => {
            return repoGroup.id === group.id
          })[0]
          if(groupData) {
            groupData.role = group.role
            groupsData.push(groupData)
          }
        })

        rootFolderAccess.externals && rootFolderAccess.externals.forEach(user => {
          var userData = repoUsers.filter(repoUser => {
            return repoUser.id === user.id
          })[0]
          if(userData) externalsData.push(userData)
        })

        navFolder.adminsData = adminsData
        navFolder.usersData = usersData
        navFolder.groupsData = groupsData
        navFolder.externalsData = externalsData

        this._isMounted && this.setState({
          selFile: navFolder,
          calculatingSize: false
        }, () => {
          //this.getFolderDetails(navFolder)
          this._getFolderCount(navFolder.id)
        });
      } else {
        if(folderId && folderId.indexOf("-templates") !== -1) {
          navFolder = []
          navFolder.id = folderId
          navFolder.fileName = "Templates";
          navFolder.fileType = "templates"

          this._isMounted && this.setState({
            activeTab: "info",
            selFile: navFolder,
            calculatingSize: false
          })
        }

        if(folderId && folderId.indexOf("-trash") !== -1) {
          navFolder = []
          navFolder.id = folderId
          navFolder.fileName = "Trash";
          navFolder.fileType = "trash"

          this._isMounted && this.setState({
            activeTab: "info",
            selFile: navFolder,
            calculatingSize: false
          })
        }
      }
    }
  }

  private getItemDetails(item, getLockOnly?: boolean) {
    userService.getDocument(item.id)
    .then((response: any) => {
      var data = response.data;

      var pathInfo = this.getPathInfo(data.parent_id)
      if(pathInfo) {
        var fullPath = pathInfo.map(item => {
          return item.text
        })
        fullPath = fullPath.join("/")
        item.path = fullPath
      }

      item.repo = data.repo

      var dateModified = new Date(data.modified_at).toString();
      item.dateModified = new Date(dateModified);
      item.modifiedBy = data.modified_by;

      item.fileName = data.name

      var bookmark: any = data.bookmarks && data.bookmarks.filter(bookmarkData => {
        return bookmarkData.user_id === this.props.userData.user.id
      })[0] !== undefined;

      item.bookmark = bookmark

      data.revisions.map(revision => {
        return revision.version = revision.major + "." + revision.minor
      })

      var revisions = data.revisions.filter(revision => {
        return !revision.deleted
      });

      revisions.sort((a, b) => {
          if(a.timestamp > b.timestamp) { return -1; }
          if(a.timestamp < b.timestamp) { return 1; }
          return 0;
      })

      item.activeRevisionId = data.activeRevision;
      item.activeMajor = data.activeMajor;
      item.activeMinor = data.activeMinor;

      for (let i = 0; i < revisions.length; i++) {
        var version = revisions[i];
        version.isActiveRevision = version.fileId === data.activeRevision;
      }

      item.draft = data.draft;

      if (item.draft) {
        revisions.unshift({
          author: data.modified_by,
          fileId: data.id + "-draft",
          message: "Draft",
          previousVersion: null,
          size: data.size,
          timestamp: data.modified_at,
          version: "draft"
        })
      }

      var deletedRevision = data.revisions.filter(revision => {
        return revision.deleted
      });
      if (deletedRevision.length > 0) {
        revisions.push(deletedRevision[0])
      }

      item.revisions = revisions
      item.revisionsCount = data.revisionsCount

      item.lock = data.lock
      item.wopiLock = data.wopiLock
      item.lockInstanceId = data.lockInstanceId

      var currentSelFile = this.props.fileExplorerRef._selection.getSelection()[this.props.fileExplorerRef._selection.getSelection().length - 1];
      this._isMounted && this.state.selFile && currentSelFile && item && currentSelFile.key === item.key && this.props.fileExplorerRef._selection.getSelectedCount() === 1 && this.setState({
        selFile: item
      });
    }).catch(error => {
      console.log(error)
    });
    
    if(!getLockOnly) {
      this._getFileExtraDetails(item)
    }
  }

  private _getTags(item) {
    if(this.state.activeTab === "info" && (item.accessGranted || item.fileType === "file") && !item.id.startsWith("Repo-")) {
      if (!this.state.tagsList) this.getAllTags();
      userService.getFileTags(item.id, item.kind !== "file")
      .then((response: any) => {
        var tags: any = response.data;
        var sortedTags: any = {};

        for (let i=0; i<tags.length; i++) {
          if (!sortedTags[tags[i].tagCategory.id]) {
            tags[i].tag[0].category = tags[i].tagCategory.name;
            sortedTags[tags[i].tagCategory.id] = [tags[i].tag[0]]
          }
          else {
            tags[i].tag[0].category = tags[i].tagCategory.name;
            sortedTags[tags[i].tagCategory.id].push(tags[i].tag[0])
          }
        }
        var tagArray:any = [];
        Object.keys(sortedTags).forEach(function(key, index) {
          tagArray[index] = sortedTags[key]
        });

        function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
          const key = columnKey as keyof T;
          return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[0][key].toLowerCase() < b[0][key].toLowerCase() : a[0][key].toLowerCase() > b[0][key].toLowerCase()) ? 1 : -1));
        }

        let sortedTagItems = _copyAndSort(tagArray, 'category')

        item.sortedTags = sortedTagItems;
        
        
        var currentSelFile = this.props.fileExplorerRef._selection.getSelection()[this.props.fileExplorerRef._selection.getSelection().length - 1];
        this._isMounted && this.state.selFile && currentSelFile && currentSelFile.key === item.key && this.props.fileExplorerRef._selection.getSelectedCount() === 1 && this.setState({
          selFile: item
        });
        
      }).catch(error => {
        console.log(error)
      });
    }
  }

  private _getFileExtraDetails(item) {
    if(this.state.activeTab === "info") {
      if (this.canGetDetails) {
        this.canGetDetails = false
        if (!item.sortedTags) this._getTags(item);
        userService.getComments(item.id)
        .then((response: any) => {
          setTimeout(()=>{this.canGetDetails = true},600)
          var comments = response.data;
          comments.sort((a,b) => {
            if(a.timestamp < b.timestamp)
                return 1;
            if(b.timestamp < a.timestamp)
                return - 1;
            return 0;
          });
          item.comments = comments;
          
          var currentSelFile = this.props.fileExplorerRef._selection.getSelection()[this.props.fileExplorerRef._selection.getSelection().length - 1];
          this._isMounted && this.state.selFile && currentSelFile && currentSelFile.key === item.key && this.props.fileExplorerRef._selection.getSelectedCount() === 1 && this.setState({
            selFile: item
          });
        }).catch(error => {
          console.log(error)
        });
      }
      
    }

    if(this.state.activeTab === "details") {
      if(this.props.fileExplorerType === "fileExplorer") {
        var breadcrumbPath = this.props.headerRef && this.props.headerRef.state && this.props.headerRef.state.breadcrumbPath
        if (breadcrumbPath) {
          item.path = breadcrumbPath.map(folder => {
            return folder.text
          }).join("/")
          item.path = "/" + item.path

          let currentSelFile = this.props.fileExplorerRef._selection.getSelection()[this.props.fileExplorerRef._selection.getSelection().length - 1];
            this._isMounted && this.state.selFile && currentSelFile && item && currentSelFile.key === item.key && this.props.fileExplorerRef._selection.getSelectedCount() === 1 && this.setState({
              selFile: item
            });
        }
      }
    }

    if (this.state.activeTab === "preview") {
      if (!previewExtensions.includes(item.fileExtension)) {
        item.previewData = "nopreview"
        let currentSelFile = this.props.fileExplorerRef._selection.getSelection()[this.props.fileExplorerRef._selection.getSelection().length - 1];
        this._isMounted && this.state.selFile && currentSelFile && currentSelFile.key === item.key && this.props.fileExplorerRef._selection.getSelectedCount() === 1 && this.setState({
          selFile: item
        });
      }
      else if (!item.previewData) {
        if ( item.fileExtension === "doc"
          || item.fileExtension === "docx"
          || item.fileExtension === "ppt"
          || item.fileExtension === "pptx"
          || item.fileExtension === "xls"
          || item.fileExtension === "xlsm"
          || item.fileExtension === "xlsx" ) {
          userService.getOfficeOnlineUrl(item.id, 'embedview').then((response:any) => {
            item.previewData = response.data
            let currentSelFile = this.props.fileExplorerRef._selection.getSelection()[this.props.fileExplorerRef._selection.getSelection().length - 1];
            this._isMounted && this.state.selFile && currentSelFile && currentSelFile.key === item.key && this.props.fileExplorerRef._selection.getSelectedCount() === 1 && this.setState({
              selFile: item
            });
          }).catch(error => {
            item.previewData = "nopreview";
            let currentSelFile = this.props.fileExplorerRef._selection.getSelection()[this.props.fileExplorerRef._selection.getSelection().length - 1];
            this._isMounted && this.state.selFile && currentSelFile && currentSelFile.key === item.key && this.props.fileExplorerRef._selection.getSelectedCount() === 1 && this.setState({
              selFile: item
            });
            console.log(error)
          });
        }
        else {
          userService.downloadOnlineDocument(item.id, item.activeRevisionId, "PREVIEW")
          .then((response: any) => {
            function getReadableFileSizeString(fileSizeInBytes) {
              var i = -1;
              var byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
              do {
                  fileSizeInBytes = fileSizeInBytes / 1024;
                  i++;
              } while (fileSizeInBytes > 1024);
        
              return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
            };
            const byteArray = new Uint8Array(response.data);
            var blob: any;
            if (item.fileExtension === "email") {
              blob = new Blob([byteArray]).text().then(text => {
                var previewData = JSON.parse(text);
                previewData.attachmentsOutside = [];
                if (previewData.attachments.length > 0) {
                  for (let i=0; i < previewData.attachments.length; i++) {
                    if (previewData.attachments[i].isInline) {
                      const type = previewData.attachments[i].name.split('.')[1]
                      var replace = "src=\"cid:"+previewData.attachments[i].name+".*?\"";
                      var re = new RegExp(replace,"g");
                      previewData.body = previewData.body.replace(re, 'src="data:image/'+type+';base64,'+previewData.attachments[i].content + '"')
                    }
                    else {
                      previewData.attachmentsOutside.push({name: previewData.attachments[i].name, size: getReadableFileSizeString(previewData.attachments[i].size)})
                    }
                  }
                }
                item.previewData = previewData
                
                let currentSelFile = this.props.fileExplorerRef._selection.getSelection()[this.props.fileExplorerRef._selection.getSelection().length - 1];
                this._isMounted && this.state.selFile && currentSelFile && currentSelFile.key === item.key && this.props.fileExplorerRef._selection.getSelectedCount() === 1 && this.setState({
                  selFile: item
                });
              });
            }
            else {
              if ( item.fileExtension === "svg") {
                blob = new Blob([byteArray], {type: 'image/svg+xml'});
              }
              else if (item.fileExtension === "png" || item.fileExtension === "jpg" || item.fileExtension === "jpeg") {
                blob = new Blob([byteArray], {type: 'image/'+item.fileExtension});
              }
              else if (item.fileExtension === "pdf") {
                blob = new Blob([byteArray], { type: 'application/pdf' });
              }
              else if (item.fileExtension === "txt" ) {
                blob = new Blob([byteArray]);
              }
              const url = URL.createObjectURL( blob );
              item.previewData = url;
              let currentSelFile = this.props.fileExplorerRef._selection.getSelection()[this.props.fileExplorerRef._selection.getSelection().length - 1];
              this._isMounted && this.state.selFile && currentSelFile && currentSelFile.key === item.key && this.props.fileExplorerRef._selection.getSelectedCount() === 1 && this.setState({
                selFile: item
              });
            }
          }).catch(error => {
            item.previewData = "nopreview";
            let currentSelFile = this.props.fileExplorerRef._selection.getSelection()[this.props.fileExplorerRef._selection.getSelection().length - 1];
            this._isMounted && this.state.selFile && currentSelFile && currentSelFile.key === item.key && this.props.fileExplorerRef._selection.getSelectedCount() === 1 && this.setState({
              selFile: item
            });
            console.log(error)
          });
        }
      }
    }

    if(this.state.activeTab === "activity") {
      userService.getFileActivity(item.id)
      .then((response: any) => {
          var activity = response.data;

          var activityItems = activity.map((data:any) => {
          var modelType = data.model_type;
          var operationType = data.operation_type;
          var activityDescription = data.activity_description
          var messages:any;
          if (i18n.language === 'de') {
            messages = require('../locales/de/activity-messages_de.json')
          }
          else if (i18n.language === 'es') {
            messages = activityMessages;
          }
          else {
            messages = activityMessages;
          }
          var message = (messages[modelType] && messages[modelType][operationType] && messages[modelType][operationType].action) || activityDescription;

          return ({
            id: data.id,
            date: data.date,
            dateValue: data.date,
            user: data.author.name,
            action: message,
            item: data.model_objects,
            type: modelType,
            operation: operationType,
            message: data.message,
            model_objects: data.model_objects,
            old_2nd_model_objects: data.old_2nd_model_objects,
            searchQuery: data.search_query_map
          })
        })

        activityItems = activityItems.sort((a:any,b:any) => {
          if(a.dateValue < b.dateValue)
              return 1;
          if(b.dateValue < a.dateValue)
              return -1;

          return 0;
        });

        item.activity = activityItems;
      
        var currentSelFile = this.props.fileExplorerRef._selection.getSelection()[this.props.fileExplorerRef._selection.getSelection().length - 1];
        this._isMounted && this.state.selFile && currentSelFile && item && currentSelFile.key === item.key && this.props.fileExplorerRef._selection.getSelectedCount() === 1 && this.setState({
          selFile: item
        });
      }).catch(error => {
        console.log(error)
      });
    }

    if (this.state.activeTab === "tag") {
      this._isMounted && this.setState({tagColor: null}) // Get tag color in the future
    }

    if(item.fileExtension.toLowerCase() === "pdf" && this.state.activeTab === "annotations") {
      userService.getAnnotations(item.id)
      .then((response: any) => {
        item.annotations = response.data.rows;
        
        var currentSelFile = this.props.fileExplorerRef._selection.getSelection()[this.props.fileExplorerRef._selection.getSelection().length - 1];
        this._isMounted && this.state.selFile && currentSelFile.key === item.key && this.props.fileExplorerRef._selection.getSelectedCount() === 1 && this.setState({
          selFile: item
        });
      }).catch(error => {
        console.log(error)
      });

      userService.getCitationbyDocument(item.id)
      .then((response: any) => {
        var citation:any = []
        citation[0] = response.data
        item.citation = citation;
        
        var currentSelFile = this.props.fileExplorerRef._selection.getSelection()[this.props.fileExplorerRef._selection.getSelection().length - 1];
        this._isMounted && this.state.selFile && currentSelFile.key === item.key && this.props.fileExplorerRef._selection.getSelectedCount() === 1 && this.setState({
          selFile: item
        });
      }).catch(error => {
        console.error(error)
      });
    }

    var searchQuery = this.props.searchQuery;
    if(item.highlights && item.highlights.length === 0 && this.state.activeTab === "coincidences") {
      const params = new URLSearchParams(searchQuery);
      var textQuery = params.get("text");
      if(textQuery) {
        this._isMounted && this.setState({
          searchInFileTerms: textQuery
        }, () => {
          this._searchInFile(null)
        })
      }
    } else {
      this._isMounted && this.setState({searchingInFile: false})
    }
  }

  private getFolderDetails(item) {
    this._isMounted && this.setState({ calculatingSize: true })
    let userId = this.props.userData.user.id
    let groups = this.props.userData.groups.map(userGroup => {
        return userGroup.id
    })

    userService.checkItemPermissions(item.id, userId, groups).then(response => {
      var permissionsGranted = response.data;
      if(permissionsGranted) {
        userService.getFolderDetails(item.id)
        .then((response: any) => {
          var data = response.data;

          var tags = data.tags;

          var pathInfo = this.getPathInfo(data.parent_id)
          var fullPath = pathInfo && pathInfo.map(item => {
            return item.text
          })
          fullPath = fullPath && fullPath.join("/")
          item.path = fullPath

          var dateModified = new Date(data.modified_at).toString();
          item.dateModified = new Date(dateModified);
          item.modifiedBy = data.modified_by;

          item.fileName = data.name

          if (tags) {
            tags.sort(function(a, b){
                if(a.toLowerCase() < b.toLowerCase()) { return -1; }
                if(a.toLowerCase() > b.toLowerCase()) { return 1; }
                return 0;
            })

            item.tags = tags;
          } else {
            item.tags = [];
          }

          var bookmark: any = data.bookmarks && data.bookmarks.filter(bookmarkData => {
            return bookmarkData.user_id === this.props.userData.user.id
          })[0] !== undefined;

          item.bookmark = bookmark

          item.revisions = [];

          item.users = data.users;
          item.admins = data.admins;
          item.externals = data.externals;
          item.groups = data.groups;

          var accessGranted: boolean = false;
          var userGroups = this.props.userData.groups.map(userGroup => {
            return userGroup.id
          })

          var isUser = item.users.filter(user => {
            return user.id === this.props.userData.user.id
          })

          var isAdmin = item.admins.filter(admin => {
            return admin === this.props.userData.user.id
          })

          var groupAccess = item.groups.filter(group => {
            return userGroups.includes(group.id)
          })

          var isExternal = item.externals.filter(externalUser => {
            return externalUser === this.props.userData.user.id
          })

          if(isAdmin.length > 0
            || isUser.length > 0
            || groupAccess.length > 0
            || isExternal.length > 0)
            accessGranted = true
          
          item.accessGranted = accessGranted
          
          var currentSelFile = this.props.fileExplorerRef._selection.getSelection()[this.props.fileExplorerRef._selection.getSelection().length - 1] || this.state.selFile;
          this._isMounted && this.state.selFile && currentSelFile && (currentSelFile.id === item.id) && this.props.fileExplorerRef._selection.getSelectedCount() <= 1 && this.setState({
            selFile: item,
            calculatingSize: false
          }, () => {
            this._getFolderExtraDetails(item)
            this._getFolderCount(item.id)
          });
        }).catch(error => {
          console.log(error)
        });
      }

    }).catch(error => {
      console.log(error)
    });
  }

  private _getFolderExtraDetails(item) {
    var repoUsers = this.props.repoUsers;
    let adminsData: any = [];
    let usersData: any = [];
    let groupsData: any = [];
    let externalsData: any = [];

    this._getFolderCount(item.id);

    if(item.fileType === "dir") {
      if (!item.sortedTags && ActionsPermissions("viewTags", this.state.folderRole)) {
        this._getTags(item)
      }
      if(this.props.fileExplorerType === "fileExplorer") {
        var breadcrumbPath = this.props.headerRef && this.props.headerRef.state && this.props.headerRef.state.breadcrumbPath
        if (breadcrumbPath) {
          item.path = breadcrumbPath.map(folder => {
            return folder.text
          }).join("/")
          item.path = "/" + item.path
        }
      }

      item.admins && item.admins.forEach(admin => {
        var adminData = repoUsers.filter(repoUser => {
          return repoUser.id === admin
        })[0]
        if(adminData) adminsData.push(adminData)
      })

      item.users && item.users.forEach(user => {
        var userData = repoUsers.filter(repoUser => {
          return repoUser.id === user.id
        })[0]
        if(userData) {
          userData.role = user.role
          usersData.push(userData)
        }
      })

      item.groups && item.groups.forEach(group => {
        var groupData = repoUsers.groups.filter(repoGroup => {
          return repoGroup.id === group.id
        })[0]
        if(groupData) {
          groupData.role = group.role
          groupsData.push(groupData)
        }
      })

      item.externals && item.externals.forEach(user => {
        var userData = repoUsers.filter(repoUser => {
          return repoUser.id === user.id
        })[0]
        if(userData) externalsData.push(userData)
      })

      item.adminsData = adminsData
      item.usersData = usersData
      item.groupsData = groupsData
      item.externalsData = externalsData
    }

    var currentSelFile = this.props.fileExplorerRef._selection.getSelection()[this.props.fileExplorerRef._selection.getSelection().length - 1] || this.state.selFile;
    this._isMounted && this.state.selFile && currentSelFile && (currentSelFile.key === item.key) && this.props.fileExplorerRef._selection.getSelectedCount() <= 1 && this.setState({
      selFile: item
    })
  }

  private getPathInfo(id) {
    var folders = this.props.foldersList;

    if(folders) {

      var folderId = id;
      let foldersData = folders
      let repos = this.props.repoData

      var selFolder = foldersData.filter(folder => {
        return folder.id === folderId
      })
      selFolder = selFolder[0]


      var breadcrumb: any = [];

      if(selFolder && selFolder.path_id) {
        
        breadcrumb.push({
            text: selFolder.name,
            key: selFolder.id
          })

        var getParentData = (parentId) => {
          var parentData;

          parentData = foldersData.filter(data => {
            return parentId === data.id
          })

          if(!parentData.length) {
            parentData = repos.filter(data => {
              return parentId === data.id
            })
          }

          parentData = parentData[0]

          if(parentData) {
            breadcrumb.unshift({
              text: parentData.name,
              key: parentData.id
            })
          }

          if(parentData && parentData.parent_id)
            getParentData(parentData.parent_id)
        }

        getParentData(selFolder.parent_id)
      } else {
        // Check if root folder
        let folder = repos.filter(data => {
          return data.id === folderId
        })[0]

        if (folder)
          breadcrumb.unshift({
              text: folder.name,
              key: folder.id
            })
      }
    }

    return breadcrumb
  }

  private _onRenderInternalPersonaPrimaryText = (props: any): JSX.Element  => {
    if(props.optionalText !== "admin" && this.props.userData.user.id !== props.tertiaryText && ActionsPermissions("sharingOptions", this.state.folderRole)) {
      return (
        <>
          <a href={ "mailto:" + props.secondaryText}>{ props.text }</a>
          <Icon iconName="Cancel" className="remove-permissions text-danger align-baseline ml-1" onClick={ this._removeInternalPermissions.bind(this, this.state.selFile.id, props.tertiaryText) } />
        </>
      );
    } else {
      return <a href={ "mailto:" + props.secondaryText}>{ props.text }</a>
    }
  }

  private _onRenderGroupPersonaPrimaryText = (props: any): JSX.Element  => {
    if(props.optionalText !== "admin" && this.props.userData.user.id !== props.tertiaryText && ActionsPermissions("sharingOptions", this.state.folderRole)) {
      return (
        <>
          <p className="m-0" title={props.secondaryText}><span className="text-secondary">{i18n.t('app:group')}:</span> { props.text } <Icon iconName="Cancel" className="remove-permissions text-danger align-baseline ml-1" onClick={ this._removeGroupPermissions.bind(this, this.state.selFile.id, props.tertiaryText) } /></p>
        </>
      );
    } else {
      return <p className="m-0" title={props.secondaryText}>{ props.text } <Icon iconName="Cancel" className="remove-permissions text-danger align-baseline ml-1" onClick={ this._removeGroupPermissions.bind(this, this.state.selFile.id, props.tertiaryText) } /></p>
    }
  }

  private _onRenderExternalPersonaPrimaryText = (props: any): JSX.Element  => {
    if(props.optionalText === "admin" && this.props.userData.user.id !== props.tertiaryText) {
      return (
        <>
          <a href={ "mailto:" + props.secondaryText}>{ props.text }</a>
          <Icon iconName="Cancel" className="remove-permissions text-danger align-baseline ml-1" onClick={ this._removeExternalPermissions.bind(this, this.state.selFile.id, props.tertiaryText) } />
        </>
      );
    } else {
      return <a href={ "mailto:" + props.secondaryText}>{ props.text }</a>
    }
  }

  private getAllTags() {
    var list: any= [];
    userService.getAllTags(this.props.repoData[0].id).then((response:any)=>{
      for (let i=0; i<response.data.length; i++) {
        list.push({key: response.data[i].tagCategory.id, text: response.data[i].tagCategory.name, itemType: SelectableOptionMenuItemType.Header});
        for (let j=0; j<response.data[i].tag.length; j++) {
          let tag = response.data[i].tag[j]
          tag.text = tag.name
          tag.key = tag.id
          list.push(tag)
        }
      }
      this._isMounted && this.setState({tagsList: list})
    }).catch((error: any)=>{
      console.log(error)
      this._isMounted && this.setState({tagsList: list})
    })
  }

  private _onDragStart = (event, extension) => {
    const { mergedDoc } = this.state;

    if (this.props.appPlatform === "electron") {
      const { ipcRenderer } = window.require("electron");
      if(event) {
        event.preventDefault();
        ipcRenderer.send('ondragstart', [mergedDoc.absolutePath +'.'+ extension])
      }
    }
  }

  private getOverflowItems = (item) => {
    const { selFile } = this.state;

    var actionsMenu;

    var activeRevisions = selFile.revisions.filter(version => {
      return !version.deleted
    })

    var isOfficeOnlineDoc = item.type ? this._isOfficeOnlineDoc(item.type) : this._isOfficeOnlineDoc(selFile.fileExtension)
    var isPdf = item.type ? item.type.toLowerCase() === "pdf" : selFile.fileExtension.toLowerCase() === "pdf"


    if (item.isActiveRevision) {
      actionsMenu = [
          {
            key: 'openCurrentRevision',
            name: i18n.t('app:open'),
            iconProps: { iconName: 'OpenFile' },
            className: 'versions-menu-link',
            disabled: selFile.draft || selFile.wopiLock,
            hidden: !ActionsPermissions("editFile", this.state.folderRole) || this.props.appPlatform !== ("electron" || "openfin"),
            onClick: () => {
                const { selFile } = this.state;

                var id = selFile.id;
                var revisionId = item.fileId;
                var hash = item.hash;
                var fileName = selFile.fileName;
                var fileExtension = item.type;
                var lock = selFile.lock;
                var sreader = false;
                if (fileExtension.toLowerCase() === "pdf"
                  && this.props.appPlatform === "electron") {
                  sreader =  window.sreader.length > 0;
                }

                this.props.fileExplorerRef._openDocument(id, revisionId, hash, fileName, fileExtension, lock, sreader, selFile, false)
              }
          },
          {
            key: 'openDocumentReadOnly',
            name: i18n.t('app:openReadOnly'),
            iconProps: { iconName: 'PageLock' },
            className: 'versions-menu-link',
            onClick: () => {
              var id = selFile.id;
              var revisionId = item.fileId;
              var fileName = selFile.name + " v" + item.version + "." + item.type;
              var fileExtension = item.type;
              var lock = selFile.lock;

              this.props.fileExplorerRef._openReadOnlyDocument(id, revisionId, item.hash, fileName, fileExtension, lock, selFile)
            },
            hidden: !ActionsPermissions("openDocumentReadOnly", this.state.folderRole) || item.type === 'zip' || this.props.appPlatform !== ("electron" || "openfin")
          },
          {
            key: 'openDocumentOfficeOnline',
            name: i18n.t('app:openDocumentOfficeOnline'),
            iconProps: { iconName: 'OfficeLogo' },
            className: 'versions-menu-link',
            disabled: selFile.draft || (selFile.lock && !selFile.wopiLock),
            hidden: !ActionsPermissions("openDocumentOfficeOnline", this.state.folderRole) || !isOfficeOnlineDoc || this.props.appPlatform === ("electron" || "openfin"),
            onClick: () => {
              var revisionId = item.fileId;
              var lock = selFile.lock;
              var wopiLock = selFile.wopiLock;

              this.props.fileExplorerRef._openOfficeOnline(revisionId, "edit", lock, wopiLock)
            }
          },
          {
            key: 'viewDocumentOfficeOnline',
            name: i18n.t('app:viewDocumentOfficeOnline'),
            iconProps: { iconName: 'PageLock' },
            className: 'versions-menu-link',
            hidden: !ActionsPermissions("viewDocumentOfficeOnline", this.state.folderRole) || !isOfficeOnlineDoc || item.type === "pdf" || this.props.appPlatform === ("electron" || "openfin"),
            onClick: () => {
              var revisionId = item.fileId;
              var lock = selFile.lock;
              var wopiLock = selFile.wopiLock;

              this.props.fileExplorerRef._openOfficeOnline(revisionId, "view", lock, wopiLock)
            }
          },
          {
            key: 'openPdfWithSynergyReaderOnline',
            name: i18n.t('app:openPdfWithSynergyReader'),
            iconProps: { iconName: 'OpenFile' },
            className: 'versions-menu-link',
            hidden: !ActionsPermissions("openPdfWithSynergyReaderOnline", this.state.folderRole) || !isPdf || this.props.appPlatform === ("electron" || "openfin"),
            onClick: () => {
              var id = selFile.id;
              var revisionId = item.fileId;

              this.props.fileExplorerRef._openPdfSynergyReaderOnline(id, revisionId)
            }
          },
          {
            key: 'convertVersion',
            name: parseInt(item.minor) === 0 ? i18n.t('app:demote') : i18n.t('app:promote'),
            iconProps: { iconName: parseInt(item.minor) === 0 ? 'ArrowTallDownRight' :  'ArrowTallUpRight'},
            className: 'versions-menu-link',
            onClick: () => {
              var isMajor = parseInt(item.minor) === 0
              this._isMounted && this.setState({convertingRevision: true})
              userService.convertVersion(selFile.id, !isMajor).then(response => {
                this._isMounted && this.setState({convertingRevision: false})
              }).catch(error => {
                console.log(error)
                this._isMounted && this.setState({convertingRevision: false})
              })
            },
            disabled: (item.author.id !== this.props.userData.user.id) || selFile.draft || (item.major === 0 && item.minor === "00"),
            hidden: !ActionsPermissions("convertVersion", this.state.folderRole)
          },
          {
            key: 'revertVersion',
            name: i18n.t('app:revertVersion'),
            iconProps: {iconName: 'Undo'},
            className: 'versions-menu-link',
            onClick: () => {
              this._isMounted && this.setState({revertingVersion: true})
              userService.revertVersion(selFile.id).then(response => {
                this._isMounted && this.setState({revertingVersion: false})
              }).catch(error => {
                console.log(error)
                this._isMounted && this.setState({revertingVersion: false})
              })
            },
            disabled: (item.author.id !== this.props.userData.user.id),
            hidden: !ActionsPermissions("convertVersion", this.state.folderRole) || !item.isActiveRevision || selFile.draft || selFile.revisions.length < 2
          },
          {
            key: 'markAsExternalVersion',
            name: item.external ? "Unmark as external version" : "Mark as external version",
            iconProps: { iconName: 'NavigateExternalInline'},
            className: 'versions-menu-link',
            onClick: () => {
              console.log(item)
              userService.markAsExternalVersion(selFile.id, item.fileId)
                .then(response => response)
                .catch(error => console.log(error))
            },
            disabled: (item.author.id !== this.props.userData.user.id) || selFile.draft,
            hidden: !ActionsPermissions("markAsExternalVersion", this.state.folderRole)
          },
          {
            key: 'editVersionMessage',
            name: i18n.t('app:editMessage'),
            iconProps: { iconName: 'Edit' },
            className: 'versions-menu-link',
            disabled: item.author.id !== this.props.userData.user.id,
            onClick: () => {
                this.setState({
                  editVersionMessage: true,
                  editVersionMessageId: selFile.id,
                  selVersion: item,
                  newVersionMessage: item.message
                })
              },
            hidden: !ActionsPermissions("editVersionMessage", this.state.folderRole)
          },
          {
            key: 'duplicateRevision',
            name: i18n.t('app:saveAsNewFile'),
            iconProps: { iconName: 'Save' },
            className: 'versions-menu-link',
            onClick: () => {
                let fileId = this.state.selFile.id;
                let revisionId = item.fileId;
                userService.duplicateFile(fileId,revisionId)
              },
            hidden: !ActionsPermissions("downloadItems", this.state.folderRole)
          },
          {
            key: 'downloadRevision',
            name: i18n.t('app:download'),
            iconProps: { iconName: 'Download' },
            className: 'versions-menu-link',
            onClick: () => {
                const { selFile } = this.state;
                var revisionId = item.fileId;

                this.props.fileExplorerRef._downloadDocument(selFile, revisionId, item.type)
              },
            hidden: !ActionsPermissions("downloadItems", this.state.folderRole)
          },
          {
            key: 'attachRevision',
            name: i18n.t('app:attach'),
            iconProps: { iconName: 'Attach' },
            className: 'versions-menu-link',
            onClick: () => {
                var revisionId = item.fileId;
                this.props.fileExplorerRef._attachFile(revisionId)
              },
            hidden: !ActionsPermissions("downloadItems", this.state.folderRole) || this.props.appPlatform !== ("electron" || "openfin")
          },
          {
            key: 'deleteRevision',
            name: i18n.t('app:delete'),
            iconProps: { iconName: selFile.lock ? 'Lock' : 'Delete' },
            className: 'versions-menu-link',
            disabled: this.state.selFile.draft || this.state.selFile.lock,
            hidden: !ActionsPermissions("deleteRevision", this.state.folderRole) || activeRevisions.length < 2 || (this.state.selFile.draft && activeRevisions.length < 3),
            onClick: () => {
                const { selFile } = this.state;

                this._isMounted && this.setState({
                  deletingRevision: true
                })

                var id = selFile.id;

                userService.deleteRevision(id).then( () => {
                  this._isMounted && this.setState({
                    deletingRevision: false
                  })
                })
              }
          }
        ]
    } else if (item.version === "draft") {
      actionsMenu = [
          {
            key: 'openDraft',
            name: i18n.t('app:open'),
            iconProps: { iconName: 'OpenFile' },
            className: 'versions-menu-link',
            disabled: selFile.wopiLock,
            hidden: !ActionsPermissions("editFile", this.state.folderRole) || this.props.appPlatform !== ("electron" || "openfin"),
            onClick: () => {
                const { selFile } = this.state;

                var id = selFile.id;
                var revisionId = item.fileId;
                var hash = item.hash;
                var fileName = selFile.fileName;
                var fileExtension = selFile.fileExtension;
                var lock = selFile.lock;
                var sreader = false;
                if (fileExtension.toLowerCase() === "pdf"
                  && this.props.appPlatform === "electron") {
                  sreader =  window.sreader.length > 0;
                }

                this.props.fileExplorerRef._openDocument(id, revisionId, hash, fileName, fileExtension, lock, sreader, selFile, false)
              }
          },
          {
            key: 'openDocumentReadOnly',
            name: i18n.t('app:openReadOnly'),
            iconProps: { iconName: 'PageLock' },
            className: 'versions-menu-link',
            onClick: () => {
              var id = selFile.id;
              var revisionId = item.fileId;
              var currentVersion = selFile.revisions.filter(version => {
                return version.isActiveRevision
              })
              currentVersion = currentVersion[0].version
              var fileName = selFile.name + " v"+i18n.t('app:draftPost')+"-" + currentVersion + "." + selFile.fileExtension;
              var fileExtension = selFile.fileExtension;
              var lock = selFile.lock;

              this.props.fileExplorerRef._openReadOnlyDocument(id, revisionId, item.hash, fileName, fileExtension, lock, selFile)
            },
            hidden: !ActionsPermissions("openDocumentReadOnly", this.state.folderRole) || item.type === 'zip' || this.props.appPlatform !== ("electron" || "openfin")
          },
          {
            key: 'openDocumentOfficeOnline',
            name: i18n.t('app:openDocumentOfficeOnline'),
            iconProps: { iconName: 'OfficeLogo' },
            className: 'versions-menu-link',
            disabled: selFile.lock && !selFile.wopiLock,
            hidden: !ActionsPermissions("openDocumentOfficeOnline", this.state.folderRole) || !isOfficeOnlineDoc || this.props.appPlatform === ("electron" || "openfin"),
            onClick: () => {
              var revisionId = selFile.id;
              var lock = selFile.lock;
              var wopiLock = selFile.wopiLock;

              this.props.fileExplorerRef._openOfficeOnline(revisionId, "edit", lock, wopiLock)
            }
          },
          {
            key: 'viewDocumentOfficeOnline',
            name: i18n.t('app:viewDocumentOfficeOnline'),
            iconProps: { iconName: 'PageLock' },
            className: 'versions-menu-link',
            hidden: !ActionsPermissions("viewDocumentOfficeOnline", this.state.folderRole) || !isOfficeOnlineDoc || item.type === "pdf" || this.props.appPlatform === ("electron" || "openfin"),
            onClick: () => {
              var revisionId = selFile.id;
              var lock = selFile.lock;
              var wopiLock = selFile.wopiLock;

              this.props.fileExplorerRef._openOfficeOnline(revisionId, "view", lock, wopiLock)
            }
          },
          {
            key: 'openPdfWithSynergyReaderOnline',
            name: i18n.t('app:openPdfWithSynergyReader'),
            iconProps: { iconName: 'OpenFile' },
            className: 'versions-menu-link',
            hidden: !ActionsPermissions("openPdfWithSynergyReaderOnline", this.state.folderRole) || !isPdf || this.props.appPlatform === ("electron" || "openfin"),
            onClick: () => {
              var id = selFile.id;
              var revisionId = item.fileId;

              this.props.fileExplorerRef._openPdfSynergyReaderOnline(id, revisionId)
            }
          },
          {
            key: 'duplicateRevision',
            name: i18n.t('app:saveAsNewFile'),
            iconProps: { iconName: 'Save' },
            className: 'versions-menu-link',
            onClick: () => {
                let fileId = this.state.selFile.id;
                let revisionId = item.fileId;
                userService.duplicateFile(fileId,revisionId)
              },
            hidden: !ActionsPermissions("downloadItems", this.state.folderRole)
          },
          {
            key: 'downloadDraft',
            name: i18n.t('app:download'),
            iconProps: { iconName: 'Download' },
            className: 'versions-menu-link',
            onClick: () => {
                const { selFile } = this.state;
                var revisionId = item.fileId;

                this.props.fileExplorerRef._downloadDocument(selFile, revisionId)
              },
            hidden: !ActionsPermissions("downloadItems", this.state.folderRole)
          },
          {
            key: 'attachRevision',
            name: i18n.t('app:attach'),
            iconProps: { iconName: 'Attach' },
            className: 'versions-menu-link',
            onClick: () => {
                var revisionId = item.fileId;
                this.props.fileExplorerRef._attachFile(revisionId)
              },
            hidden: !ActionsPermissions("downloadItems", this.state.folderRole)|| this.props.appPlatform !== ("electron" || "openfin")
          },
          {
            key: 'saveVersionFromDraft',
            name: i18n.t('app:saveVersion'),
            iconProps: { iconName: selFile.lock ? 'Lock' : 'Save' },
            className: 'versions-menu-link',
            disabled: selFile.lock,
            onClick: () => {
                var draft = {
                  id: selFile.id,
                  fileName: selFile.fileName,
                  activeMajor: selFile.activeMajor,
                  activeMinor: selFile.activeMinor
                };

                this.setState({
                  showSaveDraftDialog: true,
                  saveDraftData: draft,
                  saveAsMajorVersion: 'minor'
                })
              },
            hidden: !ActionsPermissions("saveVersionFromDraft", this.state.folderRole)
          },
          {
            key: 'deleteDraft',
            name: i18n.t('app:delete'),
            iconProps: { iconName: selFile.lock ? 'Lock' : 'Delete' },
            className: 'versions-menu-link',
            disabled: selFile.lock,
            onClick: () => {
                const { selFile } = this.state;

                this._isMounted && this.setState({ deletingDraft: true })

                var id = selFile.id;

                userService.deleteDraft(id).then(response => {

                  this._isMounted && this.setState({ deletingDraft: false })
                })
              },
            hidden: !ActionsPermissions("deleteDraft", this.state.folderRole)
          }
        ]
    } else if (item.deleted) {
        actionsMenu = [
          {
            key: 'restoreRevision',
            name: i18n.t('app:restoreVersion'),
            iconProps: { iconName: selFile.lock ? 'Lock' : 'Undo' },
            className: 'versions-menu-link',
            disabled: this.state.selFile.draft || this.state.selFile.lock,
            onClick: () => {
                const { selFile } = this.state;

                this._isMounted && this.setState({
                  undoingDeleteRevision: true
                })

                var id = selFile.id;

                userService.undoDeleteRevision(id).then( () => {
                  this._isMounted && this.setState({
                    undoingDeleteRevision: false
                  })
                })
              },
            hidden: !ActionsPermissions("restoreRevision", this.state.folderRole)
          }
        ]
    } else {
        actionsMenu = [
          {
            key: 'openDocumentReadOnly',
            name: i18n.t('app:openReadOnly'),
            iconProps: { iconName: 'PageLock' },
            className: 'versions-menu-link',
            onClick: () => {
              var id = selFile.id;
              var revisionId = item.fileId;
              var fileName = selFile.name + " v" + item.version + "." + item.type;
              var fileExtension = item.type;
              var lock = selFile.lock;

              this.props.fileExplorerRef._openReadOnlyDocument(id, revisionId, item.hash, fileName, fileExtension, lock, selFile)
            },
            hidden: !ActionsPermissions("openDocumentReadOnly", this.state.folderRole) || item.type === 'zip' || this.props.appPlatform !== ("electron" || "openfin")
          },
          {
            key: 'viewDocumentOfficeOnline',
            name: i18n.t('app:viewDocumentOfficeOnline'),
            iconProps: { iconName: 'PageLock' },
            className: 'versions-menu-link',
            hidden: !ActionsPermissions("viewDocumentOfficeOnline", this.state.folderRole) || !isOfficeOnlineDoc || item.type === "pdf" || this.props.appPlatform === ("electron" || "openfin"),
            onClick: () => {
              var revisionId = item.fileId;
              var lock = selFile.lock;
              var wopiLock = selFile.wopiLock;

              this.props.fileExplorerRef._openOfficeOnline(revisionId, "view", lock, wopiLock)
            }
          },
          {
            key: 'openPdfWithSynergyReaderOnline',
            name: i18n.t('app:openPdfWithSynergyReader'),
            iconProps: { iconName: 'OpenFile' },
            className: 'versions-menu-link',
            hidden: !ActionsPermissions("openPdfWithSynergyReaderOnline", this.state.folderRole) || !isPdf || this.props.appPlatform === ("electron" || "openfin"),
            onClick: () => {
              var id = selFile.id;
              var revisionId = item.fileId;

              this.props.fileExplorerRef._openPdfSynergyReaderOnline(id, revisionId)
            }
          },
          {
            key: 'markAsExternalVersion',
            name: item.external ? "Unmark as external version" : "Mark as external version",
            iconProps: { iconName: 'NavigateExternalInline'},
            className: 'versions-menu-link',
            onClick: () => {
              userService.markAsExternalVersion(selFile.id, item.fileId)
                .then(response => response)
                .catch(error => console.log(error))
            },
            disabled: (item.author.id !== this.props.userData.user.id) || selFile.draft,
            hidden: !ActionsPermissions("markAsExternalVersion", this.state.folderRole)
          },
          {
            key: 'restoreRevision',
            name: i18n.t('app:restore'),
            iconProps: { iconName: selFile.lock ? 'Lock' : 'ChromeRestore' },
            className: 'versions-menu-link',
            disabled: selFile.lock || selFile.draft,
            onClick: () => {
                const { selFile } = this.state;

                this._isMounted && this.setState({ restoringRevision: true })

                var id = selFile.id;
                var revisionId = item.fileId;

                userService.restoreRevision(id, revisionId).then(response => {
                  this._isMounted && this.setState({ restoringRevision: false })
                })
              },
            hidden: !ActionsPermissions("restoreRevision", this.state.folderRole)
          },
          {
            key: 'editVersionMessage',
            name: i18n.t('app:editMessage'),
            iconProps: { iconName: 'Edit' },
            className: 'versions-menu-link',
            disabled: item.author.id !== this.props.userData.user.id,
            onClick: () => {
                this.setState({
                  editVersionMessage: true,
                  editVersionMessageId: selFile.id,
                  selVersion: item,
                  newVersionMessage: item.message
                })
              },
            hidden: !ActionsPermissions("editVersionMessage", this.state.folderRole)
          },
          {
            key: 'duplicateRevision',
            name: i18n.t('app:saveAsNewFile'),
            iconProps: { iconName: 'Save' },
            className: 'versions-menu-link',
            onClick: () => {
                let fileId = this.state.selFile.id;
                let revisionId = item.fileId;
                userService.duplicateFile(fileId,revisionId)
              },
            hidden: !ActionsPermissions("downloadItems", this.state.folderRole)
          },
          {
            key: 'downloadRevision',
            name: i18n.t('app:download'),
            iconProps: { iconName: 'Download' },
            className: 'versions-menu-link',
            onClick: () => {
                const { selFile } = this.state;
                var revisionId = item.fileId;

                this.props.fileExplorerRef._downloadDocument(selFile, revisionId, item.type)
              },
            hidden: !ActionsPermissions("downloadItems", this.state.folderRole)
          },
          {
            key: 'attachRevision',
            name: i18n.t('app:attach'),
            iconProps: { iconName: 'Attach' },
            className: 'versions-menu-link',
            onClick: () => {
                var revisionId = item.fileId;
                this.props.fileExplorerRef._attachFile(revisionId)
              },
            hidden: !ActionsPermissions("downloadItems", this.state.folderRole)|| this.props.appPlatform !== ("electron" || "openfin")
          },
        ]
    }    
    return actionsMenu
  }

  private _onItemContextMenu = (item: any | undefined, index: number | undefined, ev: MouseEvent | any): boolean => {
    const contextualMenuProps: IContextualMenuProps = {
      target: ev.target as HTMLElement,
      directionalHint: DirectionalHint.bottomLeftEdge,
      items: this.getOverflowItems(item),
      onDismiss: () => {
        this.setState({
          contextualMenuProps: undefined
        });
      }
    };

    if (index !== undefined && index > -1) {
      this.setState({
        contextualMenuProps: contextualMenuProps
      });
    }

    return false;
  }

  private _closeCoauthorSession(item:any) {
    this.props.confirmDialog().then(()=>{
      this.props.updateItem(item.id, "lockingDocument", true)
      if (item.lock && item.lock.email === 'SHAREPOINT_COAUTHORING_FILE_LOCK') {
        userService.deleteSharepointLink(item.id).then(response => {
          this.props.updateItem(item.id, "lockingDocument", false)
        }).catch(error => {
          this.props.updateItem(item.id, "lockingDocument", false)
          console.log(error)
        })
      }
    }).catch(reject => {console.log(reject)})

  }
}

function onRenderDetailsHeader(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
      {defaultRender!({
        ...props
      })}
    </Sticky>
  );
}

function _sendWopiCookies() {
  if (isElectron()) {
    var frame: any = document.getElementById("preview-frame");
    var frameWindow = frame.contentWindow;
    frameWindow.postMessage({type: 'wopiCookies', token: localStorage.token, tokenttl: localStorage.tokenttl}, "*")
  }
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}