import * as React from 'react';
import "moment/min/locales";
import { DetailsListLayoutMode, Selection, SelectionMode, IColumn, ConstrainMode } from '@fluentui/react/lib/DetailsList';
import { Sidebar } from '../_components/Sidebar';
import { Header } from '../_components/Header';
import { ListExplorer } from '../_components/ListExplorer';
import { DetailsPanel } from '../_components/DetailsPanel';
import companies from './companies.json';
import { Persona, PersonaSize, Text } from '@fluentui/react';
import i18n from "i18next";
import { history } from '../_helpers';


var repo = 'Repo-bbfea307-ad84-4436-928d-40e148880981';
var fakeItems = [
  {id: 'Project-ashdgya123', key: 'Project-ashdgya123', name: 'Project Alpha: Merger', owner:'Jaime Rivas', company: '3M', fileType: 'dir', parent_id: repo, milestone: 'Done', amount: 15000},
  {id: 'Project-asdasdasd6', key: 'Project-asdasdasd6', name: 'Project Omega: M&A', owner:'Jaime Rivas', company: 'Boeing', fileType: 'dir', parent_id: repo , milestone: 'Done', amount: 35000},
  {id: 'Project-9923i94j8g', key: 'Project-9923i94j8g', name: 'Project Bravo: Divesture', owner:'Francisco Fontinha', company: 'Caterpillar', fileType: 'dir', parent_id: repo , milestone: 'Pending', amount: 20000},
  {id: 'Project-89asud8asu', key: 'Project-89asud8asu', name: 'Project Darwin: Pitch', owner:'Manuel Marcos', company: 'Intel', fileType: 'dir', parent_id: repo , milestone: 'In progress', amount: 25000},
  {id: 'Project-ashdgyaysd', key: 'Project-ashdgyaysd', name: 'Project Galactica: Buybacks', owner:'Xavier Campillo', company: 'Cisco', fileType: 'dir', parent_id: repo , milestone: 'Pending', amount: 45000},
  {id: 'Project-jio56jiosa', key: 'Project-jio56jiosa', name: 'Project Apollo: M&A opportunities', owner:'Michael Bommers', company: 'Nike', fileType: 'dir', parent_id: repo , milestone: 'Done', amount: 50000},
  {id: 'Project-n345cv86bv', key: 'Project-n345cv86bv', name: 'Project Eagle: Merger', owner:'Michael Bommers', company: 'Pfizer', fileType: 'dir', parent_id: repo , milestone: 'Done', amount: 30000},
  {id: 'Project-xc7v67xc6v', key: 'Project-xc7v67xc6v', name: 'Project Hydra: Pitch', owner:'Jaime Rivas', company: 'Procter & Gamble', fileType: 'dir', parent_id: repo , milestone: 'In progress', amount: 40000},
]

declare global {
  interface Window {
      require: any;
      sreader: any;
  }
}

export interface Props {
  appPlatform: string,
  database: any,
  isOnline: boolean,
  maintenance: boolean,
  socket: any,
  pusher: any,
  page: string,
  userData: any,
  foldersList: any,
  newFolder: any,
  repoUsers: any
}

export interface IDetailsListDashboardState {
  userData?: any;
  repoData?: any;
  goBack: number;
  isLoading: boolean;
  blankLoading: boolean;
  savingTag: boolean;
  savingComment: boolean;
  syncStatus: any;
  downloadingFiles: any;
  creatingFoldersStructure: boolean;
  acceptedFiles: any;
  acceptedFolders: any;
  breadcrumbPath: any[];
  columns: IColumn[];
  fullItemsResponse: any;
  showFilters: boolean;
  filterByNameText: string;
  fullItemsResponseByName: any;
  filterByTypeText: string;
  fullItemsResponseByType: any;
  storedFiles: any;
  draggedItem: any;
  selItem: any;
  showRightPanel: boolean;
  selectionDetails: string;
  isModalSelection: boolean;
  isCompactMode: boolean;
  newTag: string;
  newComment: string;
  newFolder: string;
  savingFolder: boolean;
  showTagBulkDialog: boolean;
  hideDialog: boolean;
  activeTab: string;
  language: string;
  filterToggle: boolean;
  toolbarHeight: number;
  searchQuery: string;
  companies: any[];
}

export class CompaniesPage extends React.Component<Props, IDetailsListDashboardState> {
  private _isMounted: boolean;
  private _selection: Selection;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();
  private listExplorerRef:any = React.createRef();
  private detailsPanelRef:any = React.createRef();

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this._selection = new Selection({
      onSelectionChanged: () => {
        this._isMounted && this.setState({
          selItem: this._selection.getSelection()[this._selection.getSelection().length - 1] || []
        });
      }
    });

    this.state = {
      userData: null,
      repoData: null,
      goBack: 0,
      isLoading: true,
      blankLoading: false,
      savingTag: false,
      savingComment: false,
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      downloadingFiles: [],
      creatingFoldersStructure: false,
      acceptedFiles: [],
      acceptedFolders: [],
      breadcrumbPath: [{
        text: i18n.t('app:companies'),
        key: 'companies',
        onClick: ()=>{history.push('/companies')}
      }],
      showFilters: false,
      fullItemsResponse: [],
      filterByNameText: "",
      fullItemsResponseByName: [],
      filterByTypeText: "",
      fullItemsResponseByType: [],
      storedFiles: [],
      draggedItem: null,
      columns: [],
      selItem: null,
      showRightPanel: localStorage.showRightPanel === 'true',
      selectionDetails: '',
      isModalSelection: false,
      isCompactMode: true,
      newTag: '',
      newComment: '',
      newFolder: '',
      savingFolder: false,
      showTagBulkDialog: false,
      hideDialog: true,
      activeTab: 'details',
      language: i18n.language,
      filterToggle: true,
      toolbarHeight: 89,
      searchQuery: '',
      companies: companies
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this._isMounted && this._getColumns();
    this._isMounted && this.props.userData && this._getRepoData();

    if (this.props.page === "companiesExplorer") {
      var newItems = _copyAndSort(this.state.companies, 'Company name', false);
      this._isMounted && this.setState({
        companies: newItems
      });
    }
  }

  public componentDidUpdate(prevProps: any) {

    if(this.state.language !== i18n.language) {
      this.setState({language: i18n.language});
    }

    if(this.props.userData !== prevProps.userData) {
      this.props.userData && this._getRepoData();
    }

    if (this.props.page !== prevProps.page && this.props.page === "companiesExplorer") {
      this._isMounted && this._getColumns();
      var newItems = _copyAndSort(this.state.companies, 'Company name', false);
      this._isMounted && this.setState({
        companies: newItems
      });
    }

  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  public render() {

    const { columns, companies, isCompactMode, searchQuery, showRightPanel } = this.state;

    return (
      <div>
            <Header
              ref={instance => { this.headerRef = instance; }}
              userData={ this.props.userData }
              listExplorerRef={ this.listExplorerRef }
              detailsPanelRef={ this.detailsPanelRef }
              page={this.props.page}
              sidebarRef={ this.sidebarRef }
              breadcrumbPath= {this.state.breadcrumbPath}
              syncStatus = {this.state.syncStatus}
              items={companies}
              callbackFunction={this.callbackFunction}
              showRightPanel={showRightPanel}
            />
          <div className="content-wrap d-flex flex-row">
            <Sidebar
              ref={(instance:any) => { this.sidebarRef = instance; }}
              headerRef={ this.headerRef }
              listExplorerRef={ this.listExplorerRef }
              detailsPanelRef={ this.detailsPanelRef }
              userData={ this.props.userData }
              page={this.props.page}
              items={companies}
              searchQuery={searchQuery}
              callbackFunction={this.callbackFunction}
            /> 

            <div className="list mr-auto flex-grow-1" >
              <ListExplorer
                ref={instance => { this.listExplorerRef = instance; }}
                appPlatform={ this.props.appPlatform }
                isOnline={ this.props.isOnline }
                maintenance={ this.props.maintenance }
                socket={this.props.socket}
                pusher={this.props.pusher}
                userData={this.props.userData}
                foldersList={ this.props.foldersList }
                items={companies}
                selItem={ this.state.selItem }
                isCompactMode={isCompactMode}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                //enableShimmer={isLoading}
                constrainMode={ConstrainMode.unconstrained}
                //onFileDrop={this.onFileDrop.bind(this)}
                //getCurrentContent={console.log('Get Content')}
                //getOfflineFiles={this._getOfflineFiles.bind(this)}
                //isLoading={isLoading}
                //blankLoading={blankLoading}
                //emptyMessage={emptyMessage}
                headerRef={ this.headerRef }
                detailsPanelRef={ this.detailsPanelRef }
                callbackFunction={ this.callbackFunction }
                page={this.props.page}
                //updateItem={ this.updateItem }
                sidebarRef={ this.sidebarRef }
              />      
            </div>
            <DetailsPanel
              ref={instance => { this.detailsPanelRef = instance; }}
              appPlatform={ this.props.appPlatform }
              userData={ this.props.userData }
              repoData={ this.state.repoData }
              foldersList={ this.props.foldersList }
              repoUsers={this.props.repoUsers}
              newFolder={ this.props.newFolder }
              items={ companies }
              showRightPanel={ this.state.showRightPanel }
              selection={ this._selection }
              selItem={ this.state.selItem }
              //getCurrentContent={(this._getCurrentContent.bind(this))}
              headerRef={ this.headerRef }
              listExplorerRef={ this.listExplorerRef }
              page={ this.props.page }
              detailsPanelSection="explorer"
              callbackFunction={ this.callbackFunction }
            />
          </div>  
      </div>
    );
  }

  private _getColumns() {
    var columns: any = []
    if (this.props.page === "companiesExplorer") {
      columns = [
        {
          key: 'icon',
          name: '#',
          fieldName: 'icon',
          minWidth: 1,
          isRowHeader: true,
          data: 'string',
          isPadded: true,
          onRender: (item: any) => {
            return (
              <Persona className='company-icon' size={PersonaSize.size24} text={item['Company name']}/>
            )
          }
        },
        {
          key: 'Company name',
          name: 'Company name',
          fieldName: 'Company name',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <span title={ item['Company name'] }>{ item['Company name'] }</span>
            )
          }
        },
        {
          key: 'Company Domain Name',
          name: 'Domain',
          fieldName: 'Company Domain Name',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <a style={{fontWeight: 400}} target={'_blank'} href={'https://'+item['Company Domain Name']} title={ item['Company Domain Name'] }>{ item['Company Domain Name'] }</a>
            )
          }
        },
        {
          key: 'Company owner',
          name: 'Company owner',
          fieldName: 'Company owner',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <Text title={ item['Company owner'] }>{ item['Company owner'] || "Jaime Rivas" }</Text>
            )
          }
        },
        {
          key: 'Industry',
          name: 'Industry',
          fieldName: 'Industry',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <Text title={ item['Industry'] }>{ item['Industry'] }</Text>
            )
          }
        },
        {
          key: 'City',
          name: 'City',
          fieldName: 'City',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <Text title={ item['City'] }>{ item['City'] }</Text>
            )
          }
        },
        {
          key: 'State/Region',
          name: 'State/Region',
          fieldName: 'State/Region',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <Text title={ item['State/Region'] }>{ item['State/Region'] }</Text>
            )
          }
        }
      ]
    }
    this._isMounted && this.setState({
      columns: columns
    })
  }

  onDragOver = (e) => {
    e.dataTransfer.dropEffect = 'copy'
    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onDragEnter = (e) => {
    e.dataTransfer.dropEffect = 'copy'
    if(this.listExplorerRef
      && !this.listExplorerRef.state.draggedItem) {
        
      setTimeout(() => {
        var el: any = document.getElementsByClassName("ms-ScrollablePane")
        for(var i = 0; i < el.length; i++) {
          if (!el[i].classList.contains("dragOverList")) {
            el[i].classList.add('dragOverList');
          }
        }
      })
    }

    let event:any = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onDragLeave = (e) => {
    if (e.target.className.indexOf("ms-ScrollablePane") !== -1) {
      var el: any = document.getElementsByClassName("ms-ScrollablePane")
      for(var i = 0; i < el.length; i++) {
        el[i].classList.remove('dragOverList');
      }
    }

    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onFileDrop = (e) => {
    var el: any = document.getElementsByClassName("ms-ScrollablePane")
    for(var i = 0; i < el.length; i++) {
      el[i].classList.remove('dragOverList');
    }
    
    let event:any = e as Event;
    event.stopPropagation();
    event.preventDefault();

    if(!this.state.draggedItem) {
      var sourceItems = event.dataTransfer.items
      var sourceFiles = event.dataTransfer.files

      /*this.getAllFileEntries(sourceItems).then(response => {
        this._setItemsToUpload(response, sourceFiles)
      });*/
    }

  }

  private _getRepoData() {
    var userData = this.props.userData
    var repo = userData && userData.repository;
    var repoData: any = [];

    repoData.push({
      id: repo.id,
      name: repo.name,
      role: userData.role
    })

    this._isMounted && this.setState({
      repoData: repoData
    }, () => {
      //this._getCurrentContent();
    })
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, companies } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(companies, currColumn.fieldName!, currColumn.isSortedDescending);
    this._isMounted && this.setState({
      columns: newColumns,
      companies: newItems,
    });
  };


}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  return items.slice(0).sort((a,b) => {
    if (!isSortedDescending) {
      return (a[columnKey]).localeCompare(b[columnKey]);
    } else {
      return (b[columnKey]).localeCompare(a[columnKey]);
    }
  });
}