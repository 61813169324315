import * as React from 'react';
import "moment/min/locales";
import { DetailsListLayoutMode, Selection, SelectionMode, IColumn, ConstrainMode } from '@fluentui/react/lib/DetailsList';
import i18n from "i18next";

import { Sidebar } from '../_components/Sidebar';
import { Header } from '../_components/Header';
import { ListExplorer } from '../_components/ListExplorer';
import { DetailsPanel } from '../_components/DetailsPanel';
import contacts from './contacts.json';
import { Persona, PersonaSize, Text } from '@fluentui/react';
import { history } from '../_helpers';

declare global {
  interface Window {
      require: any;
      sreader: any;
  }
}

export interface Props {
  appPlatform: string,
  database: any,
  isOnline: boolean,
  maintenance: boolean,
  socket: any,
  pusher: any,
  page: string,
  userData: any,
  foldersList: any,
  newFolder: any,
  repoUsers: any
}

export interface State {
  userData?: any;
  repoData?: any;
  goBack: number;
  isLoading: boolean;
  blankLoading: boolean;
  savingTag: boolean;
  savingComment: boolean;
  syncStatus: any;
  columns: IColumn[];
  items: any;
  fullItemsResponse: any;
  showFilters: boolean;
  filterByNameText: string;
  fullItemsResponseByName: any;
  filterByTypeText: string;
  fullItemsResponseByType: any;
  draggedItem: any;
  selItem: any;
  showRightPanel: boolean;
  isCompactMode: boolean;
  language: string;
  searchQuery: string;
  breadcrumbPath: any[];
}

export class ContactsPage extends React.Component<Props, State> {
  private _isMounted: boolean;
  private _selection: Selection;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();
  private listExplorerRef:any = React.createRef();
  private detailsPanelRef:any = React.createRef();
  private contacts: any = contacts

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this._selection = new Selection({
      onSelectionChanged: () => {
        this._isMounted && this.setState({
          selItem: this._selection.getSelection()[this._selection.getSelection().length - 1] || []
        });
      }
    });

    this.state = {
      userData: null,
      repoData: null,
      goBack: 0,
      isLoading: true,
      blankLoading: false,
      savingTag: false,
      savingComment: false,
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      items: this.contacts.filter((item)=>{return(item['First Name'])}),
      showFilters: false,
      fullItemsResponse: [],
      filterByNameText: "",
      fullItemsResponseByName: [],
      filterByTypeText: "",
      fullItemsResponseByType: [],
      draggedItem: null,
      columns: [],
      selItem: null,
      showRightPanel: localStorage.showRightPanel === 'true',
      isCompactMode: true,
      language: i18n.language,
      searchQuery: '',
      breadcrumbPath: [{
        text: i18n.t('app:contacts'),
        key: 'contacts',
        onClick: ()=>{history.push('/contacts')}
      }],
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this._isMounted && this._getColumns();
    this._isMounted && this.props.userData && this._getRepoData();

    var newItems = _copyAndSort(this.state.items, 'First Name', false);
      this._isMounted && this.setState({
        items: newItems
      });
  }

  public componentDidUpdate(prevProps: any) {
    if(this.state.language !== i18n.language) {
      this.setState({language: i18n.language});
    }

    if(this.props.userData !== prevProps.userData) {
      this.props.userData && this._getRepoData();
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  public render() {

    const { columns, items, isCompactMode, searchQuery, showRightPanel } = this.state;

    return (
      <div>
            <Header
              ref={instance => { this.headerRef = instance; }}
              userData={ this.props.userData }
              listExplorerRef={ this.listExplorerRef }
              detailsPanelRef={ this.detailsPanelRef }
              page={this.props.page}
              sidebarRef={ this.sidebarRef }
              syncStatus = {this.state.syncStatus}
              items={items}
              callbackFunction={this.callbackFunction}
              showRightPanel={showRightPanel}
              breadcrumbPath={this.state.breadcrumbPath}
            />
          <div className="content-wrap d-flex flex-row" >
            <Sidebar
              ref={(instance:any) => { this.sidebarRef = instance; }}
              headerRef={ this.headerRef }
              listExplorerRef={ this.listExplorerRef }
              detailsPanelRef={ this.detailsPanelRef }
              userData={ this.props.userData }
              page={this.props.page}
              items={items}
              searchQuery={searchQuery}
              callbackFunction={this.callbackFunction}
            /> 

            <div className="list mr-auto flex-grow-1">
              <ListExplorer
                ref={instance => { this.listExplorerRef = instance; }}
                appPlatform={ this.props.appPlatform }
                isOnline={ this.props.isOnline }
                maintenance={ this.props.maintenance }
                socket={this.props.socket}
                pusher={this.props.pusher}
                userData={this.props.userData}
                foldersList={ this.props.foldersList }
                items={items}
                selItem={ this.state.selItem }
                isCompactMode={isCompactMode}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                //enableShimmer={isLoading}
                constrainMode={ConstrainMode.unconstrained}
                //onFileDrop={this.onFileDrop.bind(this)}
                //getCurrentContent={console.log('Get Content')}
                //getOfflineFiles={this._getOfflineFiles.bind(this)}
                //isLoading={isLoading}
                //blankLoading={blankLoading}
                //emptyMessage={emptyMessage}
                headerRef={ this.headerRef }
                detailsPanelRef={ this.detailsPanelRef }
                callbackFunction={ this.callbackFunction }
                page={this.props.page}
                //updateItem={ this.updateItem }
                sidebarRef={ this.sidebarRef }
              />      
            </div>
            <DetailsPanel
              ref={instance => { this.detailsPanelRef = instance; }}
              appPlatform={ this.props.appPlatform }
              userData={ this.props.userData }
              repoData={ this.state.repoData }
              foldersList={ this.props.foldersList }
              repoUsers={this.props.repoUsers}
              newFolder={ this.props.newFolder }
              items={ items }
              showRightPanel={ this.state.showRightPanel }
              selection={ this._selection }
              selItem={ this.state.selItem }
              headerRef={ this.headerRef }
              listExplorerRef={ this.listExplorerRef }
              page={ this.props.page }
              detailsPanelSection="explorer"
              callbackFunction={ this.callbackFunction }
            />
          </div>  
      </div>
    );
  }

  private _getColumns() {
    var columns: any = []
    if (this.props.page === "contactsExplorer") {
      columns = [
        {
          key: 'icon',
          name: '#',
          fieldName: 'icon',
          minWidth: 1,
          isRowHeader: true,
          data: 'string',
          isPadded: true,
          //onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <Persona size={PersonaSize.size24}  text={item['First Name'] + " " + item['Last Name']}/>
            )
          }
        },
        {
          key: 'First Name',
          name: 'First Name',
          fieldName: 'First Name',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <span title={ item['First Name'] }>{ item['First Name'] }</span>
            )
          }
        },
        {
          key: 'Last Name',
          name: 'Last Name',
          fieldName: 'Last Name',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <Text title={ item['Last Name'] }>{ item['Last Name'] }</Text>
            )
          }
        },
        {
          key: 'Email',
          name: 'Email',
          fieldName: 'Email',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <a href={'mailto:'+item['Email']} title={ item['Email'] }>{ item['Email'] }</a>
            )
          }
        },
        {
          key: 'Phone Number',
          name: 'Mobile',
          fieldName: 'Phone Number',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <Text title={ item['PhPhone Numberone'] }>{ item['Phone Number'] }</Text>
            )
          }
        },
        {
          key: 'Associated Company',
          name: 'Company',
          fieldName: 'Associated Company',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <Text title={ item['Associated Company'] }>{ item['Associated Company'] }</Text>
            )
          }
        },
        {
          key: 'Last Activity Date',
          name: 'Last Activity Date',
          fieldName: 'Last Activity Date',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <Text title={ item['Last Activity Date'] }>{ item['Last Activity Date'] }</Text>
            )
          }
        },
        {
          key: 'Create Date',
          name: 'Create Date',
          fieldName: 'Create Date',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <Text title={ item['Create Date'] }>{ item['Create Date'] }</Text>
            )
          }
        }
      ]
    }
    this._isMounted && this.setState({
      columns: columns
    })
  }

  onDragOver = (e) => {
    e.dataTransfer.dropEffect = 'copy'
    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onDragEnter = (e) => {
    e.dataTransfer.dropEffect = 'copy'
    if(this.listExplorerRef
      && !this.listExplorerRef.state.draggedItem) {
        
      setTimeout(() => {
        var el: any = document.getElementsByClassName("ms-ScrollablePane")
        for(var i = 0; i < el.length; i++) {
          if (!el[i].classList.contains("dragOverList")) {
            el[i].classList.add('dragOverList');
          }
        }
      })
    }

    let event:any = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onDragLeave = (e) => {
    if (e.target.className.indexOf("ms-ScrollablePane") !== -1) {
      var el: any = document.getElementsByClassName("ms-ScrollablePane")
      for(var i = 0; i < el.length; i++) {
        el[i].classList.remove('dragOverList');
      }
    }

    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onFileDrop = (e) => {
    var el: any = document.getElementsByClassName("ms-ScrollablePane")
    for(var i = 0; i < el.length; i++) {
      el[i].classList.remove('dragOverList');
    }
    
    let event:any = e as Event;
    event.stopPropagation();
    event.preventDefault();

    if(!this.state.draggedItem) {
      var sourceItems = event.dataTransfer.items
      var sourceFiles = event.dataTransfer.files

      /*this.getAllFileEntries(sourceItems).then(response => {
        this._setItemsToUpload(response, sourceFiles)
      });*/
    }

  }

  private _getRepoData() {
    var userData = this.props.userData
    var repo = userData && userData.repository;
    var repoData: any = [];

    repoData.push({
      id: repo.id,
      name: repo.name,
      role: userData.role
    })

    this._isMounted && this.setState({
      repoData: repoData
    }, () => {
      //this._getCurrentContent();
    })
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this._isMounted && this.setState({
      columns: newColumns,
      items: newItems,
    });
  };


}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  return items.slice(0).sort((a,b) => {
    if (!isSortedDescending) {
      return (a[columnKey]).localeCompare(b[columnKey]);
    } else {
      return (b[columnKey]).localeCompare(a[columnKey]);
    }
  });
}