import { Selection, CheckboxVisibility, DefaultButton, DetailsList, IconButton, PrimaryButton, SearchBox, SelectionMode } from '@fluentui/react';
import { Resizable } from 're-resizable';
import * as React from 'react';

export interface Props {
  type: string;
  items: any;
  save:any;
  callback: any;
}

export interface States {
  panelWidth: any;
  panelHeight: any;
  items: any;
  itemsFull: any;
  itemsChecked: any;
  items2: any;
}

export class FilterColumn extends React.Component<Props, States> {
  private _isMounted: boolean;
  private _selection: Selection;
  private _selection2: Selection;
  
  private fakeOwners = [
    {
      "id": "Tag-534a199b-5447-4450-984a-f4419f50adce",
      "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
      "tag": "Francisco Fontinha",
      "author": {
          "id": "U6da36385-3ed4-4654-96ef-691c54e2fe80",
          "email": "jaime.rivas@synergy.page",
          "name": "Jaime Rivas"
      },
      "description": null,
      "color": null
    },
    {
      "id": "Tag-534a199b-5447-4450-984a-f4419f50adcf",
      "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
      "tag": "Jaime Rivas",
      "author": {
          "id": "U6da36385-3ed4-4654-96ef-691c54e2fe80",
          "email": "jaime.rivas@synergy.page",
          "name": "Jaime Rivas"
      },
      "description": null,
      "color": null
    },
    {
      "id": "Tag-534a199b-5447-4450-984a-f4419f50adci",
      "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
      "tag": "Manuel Marcos",
      "author": {
          "id": "U6da36385-3ed4-4654-96ef-691c54e2fe80",
          "email": "jaime.rivas@synergy.page",
          "name": "Jaime Rivas"
      },
      "description": null,
      "color": null
    },
    {
      "id": "Tag-534a199b-5447-4450-984a-f4419f50adcj",
      "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
      "tag": "Michael Bommers",
      "author": {
          "id": "U6da36385-3ed4-4654-96ef-691c54e2fe80",
          "email": "jaime.rivas@synergy.page",
          "name": "Jaime Rivas"
      },
      "description": null,
      "color": null
    },
    {
      "id": "Tag-534a199b-5447-4450-984a-f4419f50adck",
      "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
      "tag": "Xavier Campillo",
      "author": {
          "id": "U6da36385-3ed4-4654-96ef-691c54e2fe80",
          "email": "jaime.rivas@synergy.page",
          "name": "Jaime Rivas"
      },
      "description": null,
      "color": null
    }
  ]
  
  private fakeCompanies = [
    {
      "id": "Tag-534a199b-5447-4450-984a-f4419f50adcg",
      "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
      "tag": "3M",
      "author": {
          "id": "U6da36385-3ed4-4654-96ef-691c54e2fe80",
          "email": "jaime.rivas@synergy.page",
          "name": "Jaime Rivas"
      },
      "description": null,
      "color": null
    },
    {
      "id": "Tag-534a199b-5447-4450-984a-f4419f50adch",
      "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
      "tag": "Boeing",
      "author": {
          "id": "U6da36385-3ed4-4654-96ef-691c54e2fe80",
          "email": "jaime.rivas@synergy.page",
          "name": "Jaime Rivas"
      },
      "description": null,
      "color": null
    },
    {
      "id": "Tag-534a199b-5447-4450-984a-f4419f50adcl",
      "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
      "tag": "Caterpillar",
      "author": {
          "id": "U6da36385-3ed4-4654-96ef-691c54e2fe80",
          "email": "jaime.rivas@synergy.page",
          "name": "Jaime Rivas"
      },
      "description": null,
      "color": null
    },
    {
      "id": "Tag-534a199b-5447-4450-984a-f4419f50adcm",
      "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
      "tag": "Cisco",
      "author": {
          "id": "U6da36385-3ed4-4654-96ef-691c54e2fe80",
          "email": "jaime.rivas@synergy.page",
          "name": "Jaime Rivas"
      },
      "description": null,
      "color": null
    },
    {
      "id": "Tag-534a299b-5447-4450-984a-f4419f50adcm",
      "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
      "tag": "Intel",
      "author": {
          "id": "U6da36385-3ed4-4654-96ef-691c54e2ff80",
          "email": "jaime.rivas@synergy.page",
          "name": "Jaime Rivas"
      },
      "description": null,
      "color": null
    },
    {
      "id": "Tag-534a199b-5447-4450-984a-f4419f60adcm",
      "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
      "tag": "Nike",
      "author": {
          "id": "U6da36385-3ed4-4654-96ef-691c54e2ge80",
          "email": "jaime.rivas@synergy.page",
          "name": "Jaime Rivas"
      },
      "description": null,
      "color": null
    },
    {
      "id": "Tag-534a199b-5447-4450-984a-f4419f52adcm",
      "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
      "tag": "Pfizer",
      "author": {
          "id": "U6da36385-3ed4-4654-96ef-691c54e2he80",
          "email": "jaime.rivas@synergy.page",
          "name": "Jaime Rivas"
      },
      "description": null,
      "color": null
    },
    {
      "id": "Tag-534a199b-5447-4450-984a-f4414f50adcm",
      "repo": "Repo-bbfea307-ad84-4436-928d-40e148880981",
      "tag": "Procter & Gamble",
      "author": {
          "id": "U6da36385-3ed4-4654-96ef-681c54e2fe80",
          "email": "jaime.rivas@synergy.page",
          "name": "Jaime Rivas"
      },
      "description": null,
      "color": null
    }
  ]

  private fakePipelines = [
    {
      name: "Investments",
      tag: 'Investments',
      milestones: [
        {name: 'Qualified', color: 'rgb(196, 196, 196)'},
        {name: 'Piched', color: 'blue'},
        {name: 'Proposal', color: 'rgb(254, 192, 110)'},
        {name: 'Execution', color: 'yellow'},
        {name: 'Diligence', color: 'limegreen'},
        {name: 'Closing', color: 'green'}
      ]
    },
    {
      name: "Divestments",
      tag: 'Divestments',
      milestones: [
        {name: 'Pre-Marketing', color: 'rgb(196, 196, 196)'},
        {name: 'Marketing', color: 'lightblue'},
        {name: 'First Bids', color: 'blue'},
        {name: 'Due Diligence', color: 'yellow'},
        {name: 'Final Bids', color: 'rgb(254, 192, 110)'},
        {name: 'Closing', color: 'rgb(7, 200, 115)'}
      ]
    }
  ]

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props;

    this._selection = new Selection({
      onSelectionChanged: () => {
        if (this._selection.getSelection().length > 0 ) {
          this._selection2.setAllSelected(false);
        } else if (this._selection.getSelection().length === 0 && this._selection2.getSelection().length === 0) {
          this._selection2.setIndexSelected(0,true,false);
        }
      }
    });

    this._selection2 = new Selection({
      onSelectionChanged: () => {
        setTimeout(()=>{
          if (this._selection2.getSelection().length === 1 ) {
            this._selection.setAllSelected(false);
          }
          else if (this._selection.getSelection().length === 0 && this._selection2.getSelection().length === 0) {
            this._selection2.setIndexSelected(0,true,false);
          }
        },1)
      }
    });

    var panelWidth = "240px"

    this.state = {
      panelHeight: 'auto',
      panelWidth: panelWidth,
      items: this.props.items,
      itemsFull: null,
      itemsChecked: [],
      items2: [
        { key: 'noFilter', text: 'No filter' },
        { key: 'all', text: 'All' },
        { key: 'none', text: 'None'}
      ]
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this._getItemsByType(this.props.type)
    var params = new URLSearchParams(document.location.search);
    let field:string = params.get(this.props.type.toLowerCase()) || "";
    let fields:string[] = field.split(';');
    if (field === 'all') {
      this._selection2.setAllSelected(false);this._selection2.setIndexSelected(1, true,false)
    } else if (field === 'none') {
      this._selection2.setAllSelected(false);this._selection2.setIndexSelected(2, true,false)
    } else if (fields && fields.length > 0 && field.length > 0) {
      setTimeout(()=>{
        let items:any = this._selection.getItems();
        for (let i=0; i < items.length; i++) {
          if (fields.includes(items[i].tag.replace(this.props.type+':',''))) {
            this._selection.setIndexSelected(i, true, false)
          }
        }
      },1)
    } else {
      this._selection2.setAllSelected(false);this._selection2.setIndexSelected(0, true,false)
    }
  }
  
  public componentDidUpdate(prevProps:any) {
    if (prevProps.type !== this.props.type) {
      this._getItemsByType(this.props.type)
      var params = new URLSearchParams(document.location.search);
      let field:string = params.get(this.props.type.toLowerCase()) || "";
      let fields:string[] = field.split(';');
      if (field === 'all') {
        this._selection2.setAllSelected(false);this._selection2.setIndexSelected(1, true,false)
      } else if (field === 'none') {
        this._selection2.setAllSelected(false);this._selection2.setIndexSelected(2, true,false)
      } else if (fields && fields.length > 0 && field.length > 0) {
        setTimeout(()=>{
          let items:any = this._selection.getItems();
          for (let i=0; i < items.length; i++) {
            if (fields.includes(items[i].tag.replace(this.props.type+':',''))) {
              this._selection.setIndexSelected(i, true, false)
            }
          }
        },1)
      } else {
        this._selection2.setAllSelected(false);this._selection2.setIndexSelected(0, true,false)
      }
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private _saveResizeState() {
    const { panelHeight, panelWidth } = this.state;
    
    var prefs = {
      height: panelHeight,
      width: panelWidth
    }

    localStorage.setItem("sidebarPrefs", JSON.stringify(prefs))
  }

  private _getItemsByType(type:string) {
    if (type === 'Pipeline') this._isMounted && this.setState({itemsFull: this.fakePipelines.slice(0), items: this.fakePipelines.slice(0)})
    else if (type === 'Company') this._isMounted && this.setState({itemsFull: this.fakeCompanies.slice(0), items: this.fakeCompanies.slice(0)})
    else if (type === 'Owner') this._isMounted && this.setState({itemsFull: this.fakeOwners.slice(0), items: this.fakeOwners.slice(0)})
  }

  private _filterList(value:string) {
    if (value === '') {
      this._isMounted && this.setState({items: this.state.itemsFull})
    } else {
      var items = this.state.itemsFull.filter((item)=> {
        return item.tag.replace(this.props.type+':','').toLowerCase().includes(value.toLowerCase());
      })
      this._isMounted && this.setState({items: items})
    }
    
  }

  _selectionToFilters = () => {
    const selection:any = this._selection.getSelection();
    let filters:any = [];
    for (let i=0; i<selection.length; i++) {
      filters.push({
          key: 'tag' + i,
          id: 'tag' + i,
          name: selection[i].tag.replace(this.props.type+':',''),
          type: 'tag' + this.props.type,
          category: this.props.type,
          iconName: 'Documentation'
      })
    }
    return filters;
  }

  public render() {
    return(
      <Resizable
        size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
        onResizeStop={(e, direction, ref, d) => {
          var currentWidth = this.state.panelWidth.match(/\d+/)[0];
          this._isMounted && this.setState({
            panelWidth: +currentWidth + +d.width + "px"
          });
          this._saveResizeState();
        }}
        enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
        minWidth={240}
        maxWidth={400}
        handleStyles= {{
          right: {
            "right": "-6px",
            "width": "6px",
            "zIndex": 1
          }
        }}
        handleClasses={{"right": "resize-handle"}}
      >
        <nav className="tree second" style={{background: '#f2f2f2'}}>
          <div className='mx-2 h-100 d-flex ' style={{background: 'white', flexDirection: 'column'}}>
            <p className="ml-1 mb-0" style={{position: 'absolute'}}>Filter by {this.props.type}</p>
            <div className='text-right pt-2' style={{height: '46px', borderBottom: '1px solid #f2f2f2'}}>
              <IconButton className='close-button mr-2' iconProps={{iconName: 'Cancel'}} onClick={()=>this.props.callback(false)}/>
            </div>
            <SearchBox iconProps={{iconName: 'Filter'}} className='searchbox-card mt-2 mx-3 mb-2' onChange={(e,v)=>{this._filterList(v || '')}}/>
            <div className='flex-grow-1' style={{overflowY: 'auto', borderTop: '1px solid #f2f2f2'}}>
            <p className='mx-1 pt-3' style={{height: '20px', fontWeight: 600}}>Select in bulk:</p>
            <div className='pb-2'>
              <DetailsList
                selection={this._selection2}
                className='list-sidebar mt-1'
                checkboxVisibility={CheckboxVisibility.always}
                compact={true}
                isHeaderVisible={false}
                selectionMode={SelectionMode.single}
                items={this.state.items2}
                selectionPreservedOnEmptyClick={true}
                columns={[ {key: 'text', name: 'Text', minWidth: 0, onRender: (item)=>{return  <span style={{fontSize: 14}}>{item.text}</span>} } ]}   
              />
            </div>
            <hr style={{borderColor: '#f2f2f2', marginTop: '8px'}}/>
            <span className='mx-3' style={{height: '20px', fontWeight: 600}}>Select individually:</span>
            <div className='flex-grow-1'>
              { this.state.items && this.state.items.length > 0 ?
                <DetailsList
                  selection={this._selection}
                  className='list-sidebar mt-1'
                  checkboxVisibility={CheckboxVisibility.always}
                  compact={true}
                  isHeaderVisible={false}
                  items={this.state.items}
                  columns={[ {key: 'name', name: 'Name', minWidth: 0, onRender: (item)=>{return  <span style={{fontSize: 14}}>{item.tag.replace(this.props.type+':','')}</span>} } ]}   
                />
                : <p className='mt-1 text-center' style={{opacity:.7, fontWeight: 400}}>Empty</p>
              }
            </div>
            </div>
            <div className='px-3 py-2 d-flex align-items-center' style={{borderTop: '1px solid rgb(237, 235, 233)'}}>
              <DefaultButton className='ml-auto' text='Close' onClick={()=>this.props.callback(false)}/>
              <PrimaryButton className='ml-3' text='Apply' onClick={()=> {this.props.save(this.props.type, this._selectionToFilters(), this._selection2.getSelection().length === 1 && this._selection2.getSelection()[0].key || '')} }/>
            </div>
          </div>
        </nav>
      </Resizable>
    )
  }

}
