import * as React from 'react';
import { history } from '../_helpers';
import UAParser from 'ua-parser-js';
import { CompanyNewForm } from './FormCompanyNew';
import { FormCustomFields } from './FormCustomFields';
import ReactDOM from 'react-dom';
import { AlertDialog } from './AlertDialog';
import { Breadcrumb, IconButton, Spinner, SpinnerSize, CommandBar, ICommandBarItemProps, Icon, IIconProps, Link } from '@fluentui/react';
import i18n from "i18next";
import { FormEditProject } from './FormEditProject';
import { Titlebar } from './Titlebar';

const detectMob = () => {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}

export interface Props {
  match: {
    url: string
  }
}

export interface HeaderState {
  breadcrumbPath: any,
  syncStatus: any,
  showRightPanel: boolean,
  errorMessage: string,
  searchOptions: string,
  isTrash: boolean,
  showNewCompanyForm: boolean,
  showCustomFieldsForm: boolean,
  showEditProjectForm: boolean
}

export class Header extends React.Component<Props, HeaderState> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this.props = props
    this.state = {
      breadcrumbPath: props.breadcrumbPath,
      syncStatus: props.syncStatus,
      showRightPanel: props.showRightPanel,
      errorMessage: "",
      searchOptions: "filesByName",
      isTrash: false,
      showNewCompanyForm: false,
      showCustomFieldsForm: false,
      showEditProjectForm: false,
    }

  }

  public componentDidMount() {
    this._isMounted = true;
  }

  public componentDidUpdate(prevProps) {
    if(this.props.breadcrumbPath !== prevProps.breadcrumbPath) {
      this._isMounted && this.setState({breadcrumbPath: this.props.breadcrumbPath})
    }

    if(this.props.showRightPanel !== prevProps.showRightPanel) {
      this._isMounted && this.setState({showRightPanel: this.props.showRightPanel})
    }
  }

  private _showNewCompanyForm() {
    this._isMounted && this.setState({showNewCompanyForm: true})
  }

  private _showCustomFieldsForm() {
    this._isMounted && this.setState({showCustomFieldsForm: true})
  }

  private callbackFunction(childData) {
    this._isMounted && this.setState(childData)
  }

  private async showAlertDialog() {
    return await new Promise((resolve, reject) => {
      var showDialog = true;

      var close = () => {
        showDialog = false;
        renderDialog()
        resolve(true)
      }

      var div = document.createElement('div');
      var renderDialog = () => {
        showDialog && ReactDOM.render(
          <AlertDialog showDialog callback={close}/>
          , div
        )
      }

      renderDialog();
    })
  }

  private _showEditProjectForm() {
    this._isMounted && this.setState({showEditProjectForm: true})
  }

  private _showAlert() {
    this.showAlertDialog();
  }

  public render() {
    const { showRightPanel, showNewCompanyForm, showCustomFieldsForm, showEditProjectForm, syncStatus } = this.state;

    const docActionItems: ICommandBarItemProps[] = [
      {
        key: 'showLeftPanel',
        title: i18n.t('app:toggleLeftPanel'), 
        iconProps: {
          iconName: 'SidePanel',
        },
        className: 'sidePanel-button',
        onClick: () => this._toggleLeftPanel(),
      },
      {
        key: 'createCompany',
        text: i18n.t('Create company'),
        iconProps: { iconName: 'Add' },
        className: "newFolderIcon",
        onClick: this._showNewCompanyForm.bind(this),
        hidden: this.props.page !== "companiesExplorer"
      },
      {
        key: 'customField',
        text: i18n.t('Create custom field'),
        iconProps: { iconName: 'Add' },
        onClick: this._showCustomFieldsForm.bind(this),
        hidden: this.props.page !== "companiesExplorer"
      },
      {
        key: 'alertTest',
        text: i18n.t('Alert test'),
        iconProps: { iconName: 'AlertSolid' },
        onClick: this._showAlert.bind(this),
        hidden: true
      },
      {
        key: 'editProject',
        text: i18n.t('Edit project'),
        iconProps: { iconName: 'Edit' },
        onClick: this._showEditProjectForm.bind(this),
        hidden: this.props.page !== "projectData"
      },
    ]

    const farItems: ICommandBarItemProps[] = [
      {
        key: 'showRightPanel',
        title: i18n.t('app:toggleRightPanel'), 
        iconProps: { iconName: 'SidePanelMirrored' },
        className: 'sidePanel-button',
        onClick: () => { this.props.callbackFunction({showRightPanel: !showRightPanel}) },
        hidden: this.props.page === "home"
      }
    ]

    const backIcon: IIconProps = { iconName: 'Back' };
    const forwardIcon: IIconProps = { iconName: 'Forward' };
    const refreshIcon: IIconProps = { iconName: 'Refresh' };

    return(
      <div className="toolbar">
        <Titlebar disabledSearch={true}/>
        <div className="d-flex flex-row">
          <div className={ "history-actions" + (this.props.page !== "projectsExplorer" ? " mr-auto" : "")}>
            <IconButton iconProps={backIcon} title={i18n.t('app:back')} ariaLabel="Back" onClick={ this._goBack.bind(this) }  />
            <IconButton iconProps={forwardIcon} title={i18n.t('app:forward')} ariaLabel="Forward" onClick={ this._goForward.bind(this) } />
          </div>
          {(window.innerWidth > 980 || !detectMob() || this.props.appPlatform === ("electron" || "openfin")) && <div className="refresh-action">
            {syncStatus.message === "Synced" ?
              <IconButton iconProps={refreshIcon} title={i18n.t('app:refresh')} ariaLabel="Refresh" onClick={ this.props.getCurrentContent ? () => { this.props.getCurrentContent() } : ()=> null } />
            :
              <div className="ms-Spinner-wrap">
                <Spinner size={SpinnerSize.small} />
              </div>
            }
          </div>}
          <div className={"folders-breadcrumb flex-grow-1" + ((this.props.isOnline && !this.props.maintenance) ? "" : " mr-3") } style={{minWidth:'60px'}}>
            <div className="row m-0 p-0">
              <div className="breadcrumb-icon">
                { this.props.page === "projectsExplorer" ?
                  <Icon iconName="Work" />
                : this.props.page === "milestonesExplorer" ?
                  <Icon iconName="Work" />
                : this.props.page === "clientsExplorer" ?
                  <Icon iconName="Work" />
                : this.props.page === "boardExplorer" ?
                  <Icon iconName="Work" />
                : this.props.page === "pipelinesExplorer" ?
                  <Icon iconName="Work" />
                : this.props.page === "tasksExplorer" ?
                  <Icon iconName="TaskLogo" />
                : this.props.page === "projectData" ?
                  <Icon iconName="Work" />
                : this.props.page === "companiesExplorer" ?
                  <Icon iconName="CityNext2" />
                : this.props.page === "companyData" ?
                  <Icon iconName="CityNext2" />
                : this.props.page === "contactsExplorer" ?
                  <Icon iconName="Contact" />
                : this.props.page === "contactData" ?
                  <Icon iconName="Contact" />
                : this.props.page === "home" ?
                  <Icon iconName="Home" />
                : this.props.page === "timeTracking" ?
                  <Icon iconName="Clock" />
                :
                  <Icon iconName="FabricFolder" />
                }
              </div>

              <div className="col m-0 p-0" style={{minWidth:'30px'}}>
                <Breadcrumb
                  items={ this.state.breadcrumbPath }
                  ariaLabel="Breadcrumb"
                  overflowAriaLabel="More folders"
                  overflowIndex={ 0 }
                  //onRenderItem={ this._onRenderBreadcrumbItem }
                />
              </div>
            </div>
          </div>
        </div>
        <CommandBar
          items={ window.innerWidth > 980 || !detectMob() || this.props.appPlatform === ("electron" || "openfin") ? docActionItems : docActionItems }
          farItems={ window.innerWidth > 980 || !detectMob() || this.props.appPlatform === ("electron" || "openfin") ? farItems : undefined }
          ariaLabel="Use left and right arrow keys to navigate between commands"
          className="commandBar"
        />

        { showNewCompanyForm && <CompanyNewForm callback={this.callbackFunction.bind(this)} page={this.props.page} /> }
        { showCustomFieldsForm && <FormCustomFields callback={this.callbackFunction.bind(this)} page={this.props.page} /> }
        { showEditProjectForm && <FormEditProject callback={this.callbackFunction.bind(this)} page={this.props.page} /> }
      </div>
    )
  }

  private _goBack() {
      history.back()
  }

  private _goForward() {
    history.forward()
  }

  private _toggleLeftPanel() {
    var nav = this.props.page;
    if (this.props.page === "projectsExplorer") nav = "projects"
    if (this.props.page === "clientsExplorer") nav = "projects"
    if (this.props.page === "milestonesExplorer") nav = "projects"
    if (this.props.page === "boardExplorer") nav = "projects"
    if (this.props.page === "projectData") nav = "projects"
    if (this.props.page === "companiesExplorer") nav = "companies"
    if (this.props.page === "companyData") nav = "companies"
    if (this.props.page === "contactsExplorer") nav = "contacts"
    if (this.props.page === "contactData") nav = "contacts"
    if (this.props.page === "tasks") nav = "tasks"
    if (this.props.page === "pipelines") nav = "pipelines"
    if (this.props.page === "timeSheet") nav = "timeTracking"
    if (this.props.page === "timeTracker") nav = "timeTracking"
    if (this.props.page === "home") nav = "home"
    if(nav) this.props.sidebarRef.toggleNav(nav)
  }
  
}