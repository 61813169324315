import * as React from 'react';
import { useParams } from 'react-router-dom';
import i18n from "i18next";
import { DataPanel } from '../_components/DataPanel';
import { Header } from '../_components/Header';
import { Sidebar } from '../_components/Sidebar';
import { history } from '../_helpers';
import { Icon, SearchBox, TooltipHost, Selection, DetailsList, IconButton, ActionButton, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { EmailViewer } from '../_components/_cards/EmailViewer';
import { Resizable } from 're-resizable';
import { CreateCard } from '../_components/_cards/CreateCard';
import { MoveCard } from '../_components/_cards/MoveCard';

var repo = 'Repo-bbfea307-ad84-4436-928d-40e148880981';
const projects = [
  {id: 'Project-ashdgya123', key: 'Project-ashdgya123', name: 'Project Alpha: Merger', owner:'Jaime Rivas', company: '3M', fileType: 'dir', parent_id: repo, milestone: 'Qualified', milestoneIndex: '1', amount: '15,000', pipeline: 'Investments'},
  {id: 'Project-asdasdasd6', key: 'Project-asdasdasd6', name: 'Project Omega: M&A', owner:'Jaime Rivas', company: 'Boeing', fileType: 'dir', parent_id: repo , milestone: 'Piched', milestoneIndex: '2', amount: '35,000', pipeline: 'Investments'},
  {id: 'Project-9923i94j8g', key: 'Project-9923i94j8g', name: 'Project Bravo: Divesture', owner:'Francisco Fontinha', company: 'Caterpillar', fileType: 'dir', parent_id: repo , milestone: 'Proposal', milestoneIndex: '3', amount: '20,000', pipeline: 'Investments'},
  {id: 'Project-89asud8asu', key: 'Project-89asud8asu', name: 'Project Darwin: Pitch', owner:'Manuel Marcos', company: 'Intel', fileType: 'dir', parent_id: repo , milestone: 'Execution', milestoneIndex: '4', amount: '25,000', pipeline: 'Investments'},
  {id: 'Project-ashdgyaysd', key: 'Project-ashdgyaysd', name: 'Project Galactica: Buybacks', owner:'Xavier Campillo', company: 'Cisco', fileType: 'dir', parent_id: repo , milestone: 'Diligence', milestoneIndex: '5', amount: '45,000', pipeline: 'Investments'},
  {id: 'Project-jio56jiosa', key: 'Project-jio56jiosa', name: 'Project Apollo: M&A opportunities', owner:'Michael Bommers', company: 'Nike', fileType: 'dir', parent_id: repo , milestone: 'Closing', milestoneIndex: '6', amount: '50,000', pipeline: 'Investments'},
  {id: 'Project-n345cv86bv', key: 'Project-n345cv86bv', name: 'Project Eagle: Merger', owner:'Michael Bommers', company: 'Pfizer', fileType: 'dir', parent_id: repo , milestone: 'Piched', milestoneIndex: '2', amount: '30,000', pipeline: 'Investments'},
  {id: 'Project-xc7v67xc6v', key: 'Project-xc7v67xc6v', name: 'Project Hydra: Pitch', owner:'Jaime Rivas', company: 'Procter & Gamble', fileType: 'dir', parent_id: repo , milestone: 'Execution', milestoneIndex: '4', amount: '40,000', pipeline: 'Investments'},
  {id: 'Project-zc7v67xc61', key: 'Project-zc7v67xc61', name: 'Project Charlie: Merger', owner:'Jaime Rivas', company: 'Caterpillar Inc', milestone: 'Pre-Marketing', milestoneIndex: '1', amount: '12,000', pipeline: 'Divestments'},
  {id: 'Project-zc7v67xc62', key: 'Project-zc7v67xc62', name: 'Project Delta: M&A', owner:'Jaime Rivas', company: 'United Technologies', milestone: 'Marketing', milestoneIndex: '2', amount: '25,000', pipeline: 'Divestments'},
  {id: 'Project-zc7v67xc63', key: 'Project-zc7v67xc63', name: 'Project Echo: Pitch', owner:'Jaime Rivas', company: 'Intel', milestone: 'First Bids', milestoneIndex: '3', amount: '60,000', pipeline: 'Divestments'},
  {id: 'Project-zc7v67xc64', key: 'Project-zc7v67xc64', name: 'Project Foxtrot: Merger', owner:'Jaime Rivas', company: 'IBM', milestone: 'Due Diligence', milestoneIndex: '4', amount: '125,000', pipeline: 'Divestments'},
  {id: 'Project-zc7v67xc65', key: 'Project-zc7v67xc65', name: 'Project Kilo: Buybacks', owner:'Jaime Rivas', company: 'Microsoft', milestone: 'Final Bids', milestoneIndex: '5', amount: '115,000', pipeline: 'Divestments'},
  {id: 'Project-zc7v67xc66', key: 'Project-zc7v67xc66', name: 'Project Lima: Divesture', owner:'Jaime Rivas', company: 'Boeing', milestone: 'Closing', milestoneIndex: '6', amount: '45,000', pipeline: 'Divestments'},
  {id: 'Project-zc7v67xc67', key: 'Project-zc7v67xc67', name: 'Project Golf: Pitch', owner:'Jaime Rivas', company: 'Walmart', milestone: 'Closing', milestoneIndex: '6', amount: '35,000', pipeline: 'Divestments'},
]

const fakePipelines = [
  {
    name: "Investments", 
    milestones: [
      {name: 'Qualified', color: 'rgb(196, 196, 196)'},
      {name: 'Piched', color: 'blue'},
      {name: 'Proposal', color: 'rgb(254, 192, 110)'},
      {name: 'Execution', color: 'yellow'},
      {name: 'Diligence', color: 'limegreen'},
      {name: 'Closing', color: 'green'}
    ]
  },
  {
    name: "Divestments", 
    milestones: [
      {name: 'Pre-Marketing', color: 'rgb(196, 196, 196)'},
      {name: 'Marketing', color: 'lightblue'},
      {name: 'First Bids', color: 'blue'},
      {name: 'Due Diligence', color: 'yellow'},
      {name: 'Final Bids', color: 'rgb(254, 192, 110)'},
      {name: 'Closing', color: 'rgb(7, 200, 115)'}
    ]
  }
]

const fakeActivity = [
  {key: '3', type: 'Emails', icon: 'email', title: 'Kickoff', content: {authorId: '6951', authorName: 'Francisco Fontinha', date: 1648567890000, from: '', to: '', body: ''}},
  {key: '2', type: 'Activities', subtype: 'move', icon: 'Move', title: 'Moved deal to MQL/Referals', content: {authorId: '6951', authorName: 'Manuel Marcos', date: 1646967890000, description: "moved deal to 'MQL / Referrals' stage." }},
  {key: '1', type: 'Activities', subtype: 'create', icon: 'AddWork', title: 'Created project', content: {authorId: '6951', authorName: 'Manuel Marcos', date: 1646067890000, description: "Project created by" }},
]


export interface Props {
  routeProps: any
}

export interface States {
  projectId: string;
  showRightPanel: boolean;
  item: any;
  syncStatus: any;
  breadcrumbPath: any[];
  updated: boolean;
  query: string;
  selItem: any;
  panelHeight: any;
  panelWidth: any;
  activitiesFull:any;
  activities: any;
  showLast20: boolean;
  newToOld: boolean;
  allCardsCollapsed: boolean;
  cardsSortedBy: string;
  showLastItems: any[];
  searchQuery: string;
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class ProjectPage extends React.Component<Props, States> {
  private _isMounted: boolean;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();
  private _selection: Selection;
  private _selectionHeader: Selection;


  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this._selection = new Selection({
      onSelectionChanged: () => {
        let selItem = this._selection.getSelection().length === 1 ? this._selection.getSelection()[this._selection.getSelection().length - 1] : null;
        if (selItem) {
          this._selectionHeader.setAllSelected(false);
          this._isMounted && this.setState({
            selItem: this._selection.getSelection()[this._selection.getSelection().length - 1],
            showLast20: false
          });
        } else {
          this._isMounted && this.setState({
            selItem: null,
            //showLast20: false
          });
        }
      }
    });
    this._selectionHeader = new Selection({
      onSelectionChanged: () => {
        if (this._selectionHeader.getSelection().length > 0) {
          this._isMounted && this.setState({
            selItem: null,
            showLast20: true
          });
        } else {
          this._isMounted && this.setState({
            selItem: null,
            showLast20: false
          });
        }
      }
    });

    var panelWidth = "240px"
    if (typeof(localStorage.getItem("sidebar3Prefs")) === "string") {
      var panelPrefs = JSON.parse(localStorage.getItem("sidebar3Prefs") || "[]");
      panelWidth = panelPrefs.width
    }

    this.state = {
      projectId: this.props.params.id,
      showRightPanel: true,
      item: null,
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      breadcrumbPath: [],
      updated: false,
      query: 'all',
      selItem: null,
      panelHeight: 'auto',
      panelWidth: panelWidth,
      activities: null,
      activitiesFull: null,
      showLast20: true,
      newToOld: true,
      allCardsCollapsed: false,
      cardsSortedBy: 'By date',
      showLastItems: [
        {key: 'allActivities', title: 'Show all in Main view'}
      ],
      searchQuery: ''
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this.getProjectById(this.props.params.id).then((response)=>{
      let project = response;
      if (project) {
        this.getActivities(this.props.params.id).then((response)=>{
          this._isMounted && this.setState({
            activitiesFull: response,
            activities: response,
            updated: true,
            item: project,
            breadcrumbPath: [
              {
              text: i18n.t('app:projects'),
              key: 'projects',
              onClick: ()=>{history.push('/projects')}
              },
              {
              text: project!.name,
              key: 'project',
              onClick: ()=>{history.push('/projects/'+this.props.params.id)}
              }
            ],
          })
        })
      } else history.push("/projects/list")
    })
  }
  
  public componentDidUpdate(prevProps:any, prevState:any) {
    if (this.state.showLast20) {
      this._selectionHeader.setAllSelected(true)
      this._selection.setAllSelected(false)
    }

    if (this.state.activitiesFull && prevState.searchQuery !== document.location.search) {
      let items:any = this.state.activitiesFull;

      const params = new URLSearchParams(document.location.search);
      params.forEach((value, key)=>{
        let field:string = params.get(key) || "";
        if (field && field.length > 0) {
          items = items.filter((item:any)=>{
            return (item[key] && item[key].toLowerCase() === field.toLowerCase())
          })
        }
      })
      this._isMounted && this.setState({
        activities: items,
        searchQuery: document.location.search
      });
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }
  
  private async getProjectById(id) {
    return projects.find((item)=>{
      return (item.id === id) 
    })
  }

  private async getActivities(id) {
    return fakeActivity
  }

  private sortItems(field:string) {
    const { newToOld, activities } = this.state;
    let sorted = _copyAndSort(activities, field, newToOld)
    this._isMounted && this.setState({
      newToOld: !newToOld,
      activities: sorted,
      cardsSortedBy: field === 'date' ? 'By date' : field === 'title' ? 'By title' : 'By date'
    })
  }

  private toggleAllCardsCollapse() {
    const { allCardsCollapsed } = this.state;
    this._isMounted && this.setState({allCardsCollapsed: !allCardsCollapsed});

  }

  public render() {

    const { projectId, item, breadcrumbPath, query, activities, selItem, showLast20, allCardsCollapsed, cardsSortedBy, showLastItems } = this.state

    return(
      item && <div>
        <Header
          ref={instance => { this.headerRef = instance; }}
          userData={ this.props.userData }
          page={this.props.page}
          items={[]}
          callbackFunction={this.callbackFunction}
          showRightPanel={ this.state.showRightPanel }
          syncStatus={this.state.syncStatus}
          breadcrumbPath={breadcrumbPath}
          sidebarRef={ this.sidebarRef }
        />
        <div className="content-wrap d-flex flex-row" >
          <Sidebar
            ref={(instance:any) => { this.sidebarRef = instance; }}
            headerRef={ this.headerRef }
            userData={ this.props.userData }
            page={this.props.page}
            callbackFunction={this.callbackFunction}
            item={item}
            pipeline={fakePipelines.find((pipeline)=>{
              return item.pipeline === pipeline.name;
            })}
            query={query}
          />
          <div className='d-flex flex-grow-1' style={{overflowX: 'auto', overflowY: 'hidden', height: 'calc(100vh - 129px)'}}>
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this._isMounted && this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                });
                this._saveResizeState();
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={240}
              //maxWidth={400}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree second">
                <div className='header'>
                <DetailsList
                  items={showLastItems}
                  styles={{root: {overflow: 'hidden'}}}
                  columns={[
                    {
                      key: 'title',
                      name: 'Title',
                      fieldName: 'title',
                      minWidth: 1,
                      isRowHeader: true,
                      data: 'string',
                      isPadded: true,
                      onRender: (item: any) => {
                        return (
                          <>
                            <div className='d-flex align-items-center' style={{height:'25px'}}>
                              <Icon iconName='BulletedList' style={{fontSize: '20px'}}/>
                              <span className='px-3 span-title'>{item.title}</span>
                            </div>
                          </>
                        )
                      }
                    },
                  ]}
                  isHeaderVisible={false}
                  selection={ this._selectionHeader }
                />
                </div>
                <SearchBox
                  className='searchbox'
                />
                { activities && activities.length > 0 ?
                <ScrollablePane style={{marginTop: '5.5rem', width: 'calc(100% - 1px)'}} scrollbarVisibility={ScrollbarVisibility.auto}>
                <DetailsList
                  className='list-sidebar'
                  items={activities}
                  columns={[
                    {
                      key: 'title',
                      name: 'Title',
                      fieldName: 'title',
                      minWidth: 1,
                      isRowHeader: true,
                      data: 'string',
                      isPadded: true,
                      onRender: (item: any) => {
                        return (
                          <>
                            <div className='d-flex align-items-center' style={{height:'20px'}}>
                              {item.icon === 'email' ?
                              <Icon iconName={'Mail'} style={{fontSize: '20px'}}/>//<Icon className='mb-1' style={{verticalAlign:'bottom'}} {...getFileTypeIconProps({ extension: '.eml', size: 20, imageFileType: 'svg' }) } />
                              :
                              <Icon iconName={item.icon} style={{fontSize: '20px'}}/>}
                              <span className='px-3 span-title'>{item.title}</span>
                            </div>
                            <div className='d-flex align-items-center mt-1'>
                              <span className='mr-2' style={{fontSize:12, marginLeft: '36px', color: 'rgb(96, 94, 92)'}}>{item.content.authorName}</span>
                              <span className='mr-3 ml-auto' style={{fontSize:12, color: 'rgb(96, 94, 92)'}}>{new Date(item.content.date).toLocaleDateString()}</span>
                            </div>
                          </>
                        )
                      },
                      onRenderHeader: (item: any)=>{
                        return(
                          <div className='d-flex align-items-center w-100'>
                            <span style={{marginRight: '28px'}}> 
                            #
                            </span>
                            <span> 
                            Title
                            </span>
                            <ActionButton 
                              className='ml-auto mr-3 px-0' 
                              style={{color: 'rgb(50, 49, 48)'}} 
                              iconProps={{iconName:'SortLines'}}
                              text={cardsSortedBy} 
                              menuProps={{items: [
                                {
                                  key: 'byDate',
                                  text: 'By date',
                                  iconProps: {iconName:'Calendar'},
                                  onClick:()=>this.sortItems('date')
                                },
                                {
                                  key: 'byName',
                                  text: 'By title',
                                  iconProps: {iconName:'HalfAlpha'},
                                  onClick:()=>this.sortItems('title')
                                }
                              ]}}
                            />
                          </div>
                      )},

                    },
                  ]}
                  selection={ this._selection }
                /></ScrollablePane> : <div className='mt-2 text-center'>No activities</div> }
              </nav>
            </Resizable>
            <div className="list mr-auto flex-grow-1" style={{background: '#faf9f8', minWidth: '420px'}}>
              <div style={{overflowX: 'auto', minWidth: '300px', height: '100%'}}>
              <div className='d-flex header-card py-2'>
                <span className='d-flex flex-grow-1 align-items-center ml-1'>
                  Main view
                </span>
                
                <TooltipHost
                  key={'collapse/expand'}
                  content="Collapse/Expand cards"
                  id={'collapse/expand'}
                  calloutProps={{ gapSpace: 0 }}
                  styles={{root: {marginTop: '4px', marginLeft: 'auto', marginRight:'1.2rem'}}}
                >
                  <IconButton
                    disabled={(!showLast20 && !(this._selection && this._selection.getSelection().length === 1))}
                    onClick={()=>this.toggleAllCardsCollapse()} 
                    className='card-header-icon' 
                    iconProps={{iconName: allCardsCollapsed ? 'ExploreContent' : 'CollapseContent', styles: {root: {fontSize: 18}}}} 
                  />
                </TooltipHost>
              </div>
              <SearchBox
                className='ml-3 mr-3 mt-2 searchbox-card'
              />
              <div className='pl-2' style={{marginTop: '.5rem', overflowY: 'scroll', maxHeight: 'calc(100% - 96px)'}}>
                { showLast20 ? activities.map((item)=> {
                    return (
                      <div key={item.key}>
                        {this.activityCard(item)}
                      </div>
                    )
                  })
                : this._selection && this._selection.getSelection().length === 1 ?
                  <div key={this._selection.getSelection()[0].key}>
                    {this.activityCard(this._selection.getSelection()[0])}
                  </div>
                : this._selection && this._selection.getSelection().length > 1 ?
                  <div className='text-center'> 
                    <p style={{fontWeight:600}}>{this._selection.getSelection().length} activites selected</p>
                  </div>
                : <div className='text-center'> 
                    <p style={{fontWeight:600}}>No activity selected</p>
                  </div> 
                }
                </div>
              </div>
            </div>
          </div>
          <DataPanel
            appPlatform={ this.props.appPlatform }
            userData={ this.props.userData }
            showRightPanel={ this.state.showRightPanel }
            callbackFunction={this.callbackFunction}
            page={this.props.page}
            id={projectId}
            item={selItem || item}
          />
        </div>
      </div>
    )
  }

  private _saveResizeState() {
    const { panelHeight, panelWidth } = this.state;
    
    var prefs = {
      height: panelHeight,
      width: panelWidth
    }

    localStorage.setItem("sidebar3Prefs", JSON.stringify(prefs))
  }

  private activityCard(item) {
    if (item.type === 'Emails') {
      return (
        <div className={'info-card' + (this.state.selItem === item ? ' selected' : '') } onClick={()=>{this._isMounted && this.setState({selItem: item})}}>
          <EmailViewer
            allCardsCollapsed={this.state.allCardsCollapsed}
            subject={item.title} 
            from={{name: 'Francisco Fontinha', email: 'francisco.fontinha@synergy.page'}} 
            to={{name: 'Manuel Marcos', email: 'manuel.marcos@synergy.page'}}
            cc={[{name: 'Jaime Rivas', email: 'jaime.rivas@synergy.page'}]}
            date={item.content.date}
          />
        </div>
      )
    } else if (item.type === 'Activities' && item.subtype === 'move') {
      return (
        <div className={'info-card' + (this.state.selItem === item ? ' selected' : '') } onClick={()=>{this._isMounted && this.setState({selItem: item})}}>
          <MoveCard allCardsCollapsed={this.state.allCardsCollapsed} item={item} />
        </div>
      )
    } else if (item.type === 'Activities' && item.subtype === 'create') {
      return (
        <div className={'info-card' + (this.state.selItem === item ? ' selected' : '') } onClick={()=>{this._isMounted && this.setState({selItem: item})}}>
          <CreateCard allCardsCollapsed={this.state.allCardsCollapsed} item={item}/>
        </div>
      )
    }
  }
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean, secondColumnKey?: string): T[] {
  const key = columnKey as keyof any;
  const key2 = secondColumnKey as keyof any;
  if (key === 'amount') {
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? parseFloat(a[key].split(' ')[0]) < parseFloat(b[key].split(' ')[0]) : parseFloat(a[key].split(' ')[0]) > parseFloat(b[key].split(' ')[0])) ? 1 : -1));
  } else {
    if (key2 && key2 !== key) return items.slice(0).sort((a: T, b: T) => {
      if (a[key2] < b[key2]) return -1;
      if (a[key2] > b[key2]) return 1;
      else if (a[key] < b[key]) return isSortedDescending ? 1 : -1;
      else if (a[key] > b[key]) return isSortedDescending ? -1 : 1;
      else return 0;
    })
    else return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
  }
}

export default withParams(ProjectPage);
